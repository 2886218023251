import { HeightArrayResponse } from '@design/api/height-array';
import { QuotesDetailsReadParams } from '@design/api/quotes-details-api';
import { LeadDetailsReadParams } from '@design/api/lead-api';
import { ProjectDetails } from '@design/models/project-details.model';
import { Quote } from '@design/models/quote.model';
import { createAction, props } from '@ngrx/store';
import { MetaDataState } from './meta-data.state';
import { SelectedInvertersResponse } from '@design/api/selected-inverters';
import { IQuoteInverter, SelectedInverter } from '@design/models/inverter.model';
import { Battery } from '@design/models/battery.model';
import { PreferencesParams } from '@design/api/preferences-api';
import { Preferences } from '@design/models/preferences.model';
import { QuoteHardware } from '@design/models/quote-hardware';
import { QuoteDesign } from '@design/models/quote-design.model';
import { Matrix4Tuple, Vector3Tuple } from 'three';

export namespace fromMetaDataActions {
  export const MetaDataFetch = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote details',
    props<QuotesDetailsReadParams>()
  );

  export const QuoteDetailsFetchSuccess = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote details Success',
    props<{ quote: Quote }>()
  );

  export const QuoteDetailsFetchFailure = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote details Failure',
    props<{ error: any }>()
  );

  export const QuoteDesignFetchSuccess = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote design Success',
    props<{ design: QuoteDesign }>()
  );

  export const QuoteDesignFetchFailure = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote design Failure',
    props<{ error: any }>()
  );
  export const UpdateQuoteMap = createAction(
    '[QuoteDetails] update quote map from quote',
    props<{ quoteSelectedMap: number }>()
  );
  export const QuoteHardwareFetchSuccess = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote hardware Success',
    props<{ hardware: QuoteHardware }>()
  );

  export const QuoteHardwareFetchFailure = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch quote hardware Failure',
    props<{ error: any }>()
  );
  export const HeightArrayFetchSuccess = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch height array details Success',
    props<{ heightArray: HeightArrayResponse }>()
  );

  export const HeightArrayFetchFailure = createAction(
    '[SystemDesignGuard/QuotesDetailsApi] fetch height array details Failure',
    props<{ error: any }>()
  );

  export const MetaDataConsumerDetailsFetch = createAction(
    '[SystemDesignGuard/ConsumerDetails] fetch ConsumerDetails details',
    props<LeadDetailsReadParams>()
  );

  export const ConsumerDetailsFetchSuccess = createAction(
    '[SystemDesignGuard/ConsumerDetails] fetch Consumer details Success',
    props<{ lead: any }>()
  );
  export const UpdateDefaultMap = createAction(
    '[ConsumerDetails] update default map from consumer',
    props<{ lead: any }>()
  );
  export const ConsumerDetailsFetchFailure = createAction(
    '[SystemDesignGuard/ConsumerDetails] fetch Consumer details Failure',
    props<{ error: any }>()
  );

  export const SelectedInvertersFetchSuccess = createAction(
    '[SystemDesignGuard/SelectedInvertersApi] fetch selected inverters array Success',
    props<{ selectedInverters: SelectedInverter[] }>()
  );

  export const SelectedInvertersFetchFailure = createAction(
    '[SystemDesignGuard/SelectedInvertersApi] fetch selected inverters array Failure',
    props<{ error: any }>()
  );

  export const SelectedBatteriesFetchSuccess = createAction(
    '[SystemDesignGuard/SelectedBatteriesApi] fetch selected batteries array Success',
    props<{ selectedBatteries: Battery[] }>()
  );

  export const SelectedBatteriesFetchFailure = createAction(
    '[SystemDesignGuard/SelectedBatteriesApi] fetch selected batteries array Failure',
    props<{ error: any }>()
  );

  export const MetaDataPreferencesFetch = createAction(
    '[SystemDesignGuard/Preferences] fetch Preferences',
    props<PreferencesParams>()
  );

  export const MetaDataPreferencesFetchSuccess = createAction(
    '[SystemDesignGuard/Preferences] fetch Preferences Success',
    props<{ preferences: Preferences }>()
  );

  export const MetaDataPreferencesFetchFailure = createAction(
    '[SystemDesignGuard/Preferences] fetch Preferences Failure',
    props<{ error: any }>()
  );

  export const SaveInvertersSuccess = createAction(
    '[Inverter Configuration Meta] save inverter data to quote Success',
    props<{ inverter: IQuoteInverter }>()
  );

  export const SaveInvertersFailure = createAction(
    '[Inverter Configuration] save inverter data to quote failure',
    props<{ error: any  }>()
  );

  export const SaveBatterySuccess = createAction(
    '[Battery Configuration] save battery data to quote Success',
    props<{ battery: any }>()
  );

  export const SaveBatteryFailure = createAction(
    '[Battery Configuration] save battery data to quote failure',
    props<{ error: any  }>()
  );

  export const StoreDefaultModule = createAction(
    '[Panel Configuration] default module for quote',
    props<{ data: any  }>()
  );
  export const StoreAllModules = createAction(
    '[Panel Configuration] all modules for quote',
    props<{ modules: any  }>()  
  );
  export const StoreArrayTypes = createAction(
    '[Panel Configuration] get array types',
    props<{ arrayTypes: any  }>()  
  );
  export const StorePanelTypes = createAction(
    '[Panel Configuration] get panel types',
    props<{ panelTypes: any  }>()  
  );
  export const StoreAllRacking = createAction(
    '[Panel Configuration] get racking list',
    props<{ rackingList: any  }>()  
  );
  export const StoreRackingTypes = createAction(
    '[Panel Configuration] get racking types',
    props<{ rackingTypes: any  }>()  
  ); 
    export const SaveDesignSuccess = createAction(
      '[Roof Section] save roof data to quote Success',
      
    );
    export const SavePanelArraysHardwareSuccess = createAction(
      '[Panel Array Configuration] save panel array to hardware Success',
      
    );
    export const SaveSimulationDataSuccess = createAction(
      '[Simulation Results] save data to quote success',
      props<{ quote: Quote }>()
  
    );
    export const SaveSimulationDataFailure = createAction(
      '[Simulation Results] save data to quote failure',
      props<{ error: any }>()
  
    ); 
    export const SaveObstacleSuccess = createAction(
      '[Obstacles] save data to quote success'
  
    );
    export const SaveObstacleFailure = createAction(
      '[Obstacles] save data to quote failure',
      props<{ error: any }>()
  
    );
    export const setChangeFieldName = createAction(
      '[configuration] change detection for field',
      props<{ prevState:any}>()
    );
    
    export const SaveEnergySuccess = createAction(
      '[Obstacles] save energy data to quote success'
  
    );
    export const SaveEnergyFailure = createAction(
      '[Obstacles] save energy data to quote failure',
      props<{ error: any }>()
  
    );   
    export const SaveMonitoringSysToHardwareSuccess = createAction(
      '[Additional tools] save monitoring system to quote success',
      props<{ monitoringSystem: any }>()
    );
    export const SaveMonitoringSysToHardwareFailure = createAction(
      '[Additional tools] save monitoring system data to quote failure',
      props<{ error: any }>()
  
    ); 
    export const saveSelectedMapInfo = createAction('[UI state] save map info and load', props<{ map: any }>());
    export const saveMapInfo = createAction('[UI state] save camera info of map',props<{ visibleMap:{
      matrix: Matrix4Tuple;
      zoom: number;
      target: Vector3Tuple;
      selected: number | any;
    }}>());
    export const saveMapInfoSuccess = createAction('[3D action] save camera info of map success');
    export const saveMapInfofailure = createAction('[3D action] save camera info of map failure');
    export const Save3DModelToS3 = createAction('[UI save scene data]',props<{ model: any }>());
    export const Save3dModelSuccess = createAction('[3D action] save scene success');
    export const Save3dModelfailure = createAction('[3D action] save failure failure');
    export const PresignedLinkSuccess = createAction('[3D action] get upload link success',props<{ data:any}>());
    export const SaveLink = createAction('[UI save s3 link]',props<{ s3url:any}>());
    export const GetModelFromS3 = createAction('[3D model] get object from s3');
    export const GettingModelSuccess = createAction('[3D model] get object from s3 success',props<{ s3url:any}>());

    export const ClearMetaData = createAction('[meta data]clear meta data');

    export const SaveBatteryUpdateFailure = createAction(
      '[Storage config] save battery after update failure',
      props<{ error: any }>()
  
    ); 

    export const SaveBatteryUpdateSuccess = createAction(
      '[Storage config] save battery after update success'
      
  
    ); 
    export const UpdateAutoSave3DSelector = createAction('[UI change selector value]',props<{ autoSave3Dmodel: boolean }>());

    export const UpdateleadDetails = createAction(
      '[UpdateleadDetails] Update LeadDetails',
      props<{ lead: any, triggerUndoRedoSnapshot?: boolean }>()
  
    ); 
    export const WeatherStationLatLongFetched = createAction(
      '[UpdateleadDetails] fetching weather lat long success',
      props<{ data: any }>()
  
    );  
    export const WeatherStationLatLongFetchFailure = createAction(
      '[UpdateleadDetails] fetching weather lat long success',
      props<{ error: any }>()
  
    ); 

    export const markSceneStatus = createAction('[3D] Set when move out of design page or a design change occur, that quote have a scene',
      props<{ isNewQuote: boolean}>()
    );
   
    export const RetryCreateStore = createAction(
      '[Create Store] Retry data init'
    );

    export const createStoreDataSuccess = createAction(
      '[Create Store] Create store data success'
    ); 
}
