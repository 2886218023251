import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { Utils } from '../utils';
import { LoginDesignService } from 'src/app/modules/login/external/login.design.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
   rubiconURL:string=environment.rubiconURL;
  constructor(public auth: AuthService, public router: Router, public utils:Utils, private loginToDesignService: LoginDesignService) { }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // if (this.auth.isLoggedIn()) {
    //   return true;
    // } else {
    //   // this.router.navigate(['login']);
    //   window.open(environment.authURL, '_self')
    //   return false;
    // }

    if (this.auth.isLoggedIn()) {
      if(this.loginToDesignService.isLoggedInToDesign() === null){
        this.auth.moveToUnauthorized();
        return false;
      }
      if(this.loginToDesignService.isLoggedInToDesign() && state.url != '/design'){
        this.router.navigate(['/design']);
        return false;
      }
      if (state.url == '/' || state.url == '/login') {
        this.router.navigate(['/projects']);
        return false;
      }
      return true;
    }
    else {
      if (state.url == '/' || state.url == '/unauthorized')
        return true;
      localStorage.setItem('redirectURL', window.location.origin); // window.location.origin
      localStorage.clear();
      if(window.location.origin.indexOf(this.rubiconURL)>=0){
        const url = this.utils.loginRubiconURL();
        window.open(url, '_self')
      }else{
        window.open(`${environment.redirect_uri}?redirectURL=${window.location.origin}`, '_self')
      }

      return false;
    }
  }





}
