import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromFrameActions } from './frame.action';
import { FrameState } from './frame.state';
import { Frame } from '@design/models/frame.model';

export const adapter: EntityAdapter<Frame> = createEntityAdapter<Frame>({ });

export const FrameInitialState: FrameState = adapter.getInitialState();

export const { selectAll } = adapter.getSelectors();

export const frameReducer: ActionReducer<FrameState> = createReducer(
  FrameInitialState,
  on(fromFrameActions.AddMany, (state, data) => {
    return adapter.addMany(data.frames, state);
  }),
  on(fromFrameActions.AddOne,(state,data) =>{
    return adapter.addOne(data.frame,state);
  }),
  on(fromFrameActions.DeleteMany, (state, { frameIds }) => {
    return adapter.removeMany(frameIds, state);
  }),
  on(fromFrameActions.DeleteOne, (state, { frameId }) => {
    return adapter.removeOne(frameId, state);
  }),
  on(fromFrameActions.UpsertMany, (state, { frames }) => {
    return adapter.upsertMany(frames, state);
  }),
  on(fromFrameActions.UpsertOne, (state, data) => {
    return adapter.upsertOne(data.frame, state);
  }),
  on(fromFrameActions.MarkStructuresAsModified, (state, { frameIds, isDefaultStructure }) => {
    const updates = frameIds.map(id => ({
      id,
      changes: { isDefaultStructure }
    }));
    return adapter.updateMany(updates, state);
  })
);
