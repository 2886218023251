import { Type } from '@angular/core';
import { RoofSection } from './roof-section.model';
import { Nullable } from './utils.model';
import { CircleObstacleConfigurationComponent } from '@design/containers/right-panel/circle-obstacle-configuration/circle-obstacle-configuration.component';
import { CircleObstacleSuggestionComponent } from '@design/containers/right-panel/circle-obstacle-suggestion/circle-obstacle-suggestion.component';
import { ConicalTreeConfigurationComponent } from '@design/containers/right-panel/conical-tree-configuration/conical-tree-configuration.component';
import { ConicalTreeSuggestionComponent } from '@design/containers/right-panel/conical-tree-suggestion/conical-tree-suggestion.component';
import { DrawToFillPanelSuggestionComponent } from '@design/containers/right-panel/draw-to-fill-panel-suggestion/draw-to-fill-panel-suggestion.component';
import { FreeformObstacleConfigurationComponent } from '@design/containers/right-panel/freeform-obstacle-configuration/freeform-obstacle-configuration.component';
import { FreeformObstacleSuggestionComponent } from '@design/containers/right-panel/freeform-obstacle-suggestion/freeform-obstacle-suggestion.component';
import { GeneralSuggestionsComponent } from '@design/containers/right-panel/general-suggestions/general-suggestions.component';
import { InverterConfigurationComponent } from '@design/containers/right-panel/inverter-configuration/inverter-configuration.component';
import { PanelArraySuggestionComponent } from '@design/containers/right-panel/fill-roof-panel-suggestion/fill-roof-panel-suggestion.component';
import { PanelConfigurationComponent } from '@design/containers/right-panel/panel-configuration/panel-configuration.component';
import { RoofSectionConfigurationComponent } from '@design/containers/right-panel/roof-section-configuration/roof-section-configuration.component';
import { RoofSectionSuggestionComponent } from '@design/containers/right-panel/roof-section-suggestion/roof-section-suggestion.component';
import { SinglePanelConfigurationComponent } from '@design/containers/right-panel/single-panel-configuration/single-panel-configuration.component';
import { SinglePanelSuggestionComponent } from '@design/containers/right-panel/single-panel-suggestion/single-panel-suggestion.component';
import { SphericalTreeConfigurationComponent } from '@design/containers/right-panel/spherical-tree-configuration/spherical-tree-configuration.component';
import { SphericalTreeSuggestionComponent } from '@design/containers/right-panel/spherical-tree-suggestion/spherical-tree-suggestion.component';
import { WalkwayObstacleConfigurationComponent } from '@design/containers/right-panel/walkway-obstacle-configuration/walkway-obstacle-configuration.component';
import { WalkwayObstacleSuggestionComponent } from '@design/containers/right-panel/walkway-obstacle-suggestion/walkway-obstacle-suggestion.component';
import { BatteryConfigurationComponent } from '@design/containers/right-panel/battery-configuration/battery-configuration.component';
import { EnergyAdditionalConfigurationComponent } from '@design/containers/right-panel/energy-additional-configuration/energy-additional-configuration.component';
import { SimulationResultsComponent } from '@design/containers/right-panel/simulation-results/simulation-results.component';
import { ShadingComponent } from '@design/containers/right-panel/shading/shading.component';
import { EdgeConfigurationComponent } from '@design/containers/right-panel/edge-configuration/edge-configuration.component';
import { MultiSelectSuggestionComponent } from '@design/containers/right-panel/multi-select-suggestion/multi-select-suggestion.component';
export type RightPanelSuggestionsComponents =
  | CircleObstacleSuggestionComponent
  | MultiSelectSuggestionComponent
  | ConicalTreeSuggestionComponent
  | DrawToFillPanelSuggestionComponent
  | FreeformObstacleSuggestionComponent
  | GeneralSuggestionsComponent
  | PanelArraySuggestionComponent
  | RoofSectionSuggestionComponent
  | ShadingComponent
  | SimulationResultsComponent
  | SinglePanelSuggestionComponent
  | SphericalTreeSuggestionComponent
  | WalkwayObstacleSuggestionComponent;
export type RightPanelConfigurationComponents =
  | CircleObstacleConfigurationComponent
  | ConicalTreeConfigurationComponent
  | FreeformObstacleConfigurationComponent
  | InverterConfigurationComponent
  | PanelConfigurationComponent
  | RoofSectionConfigurationComponent
  | SinglePanelConfigurationComponent
  | SphericalTreeConfigurationComponent
  | EdgeConfigurationComponent
  | WalkwayObstacleConfigurationComponent
  | BatteryConfigurationComponent
  | EnergyAdditionalConfigurationComponent;
export type RightPanelComponents =
  | RightPanelSuggestionsComponents
  | RightPanelConfigurationComponents;
export type RightPanelComponentsTypes = Type<
  RightPanelSuggestionsComponents | RightPanelConfigurationComponents
>;
export enum ToolSelectionIds {
  RoofSection = 'roof-section-selection',
  ObstacleFreeForm = 'obstacle-free-form-selection',
  ObstacleCircle = 'obstacle-circle-selection',
  ObstacleRectangle = 'obstacle-rectangle-selection',
  ObstacleWalkway = 'obstacle-walkway-selection',
  TreeSpherical = 'spherical-tree-selection',
  TreeConical = 'conical-tree-selection',
  SinglePanel = 'single-panel-selection',
  PanelArray = 'panel-array-selection',
  ImagePanelArray = 'image-panel-array-selection',
  DrawToFillPanel = 'draw-to-fill-panel-selection',
  Simulation = 'simulation-selection',
  UploadImage = 'upload-image-selection',
  Shading = 'shading-selection',
  EdgeSection = 'edge-selection',
  MultiPanelSelection = 'multi-panel-selection',
}
export type DrawMode = `${ToolSelectionIds}`;
export enum ConfigurationIds {
  Edge = 'edge-configuration',
  RoofSection = 'roof-section-configuration',
  FreeFormObstacle = 'free-form-obstacle-configuration',
  RectangleObstacle = 'rectangle-obstacle-configuration',
  CircleObstacle = 'circle-obstacle-configuration',
  WalkwaysObstacle = 'walkways-obstacle-configuration',
  TreeConical = 'conical-tree-configuration',
  TreeSpherical = 'spherical-tree-configuration',
  SinglePanel = 'single-panel-configuration',
  PanelArray = 'panel-array-configuration',
  ConfPanelArray = 'panel-array-configuration',
  Inverter = 'inverter-configuration',
  Battery = 'battery-configuration',
  Energy = 'energy-configuration',
}
export enum ToolNames {
  FreeFormRoof = 'Free Form Roof',
  ObstacleFreeForm = 'Free Form Obstacle',
  ObstacleCircle = 'Circular Obstacle',
  ObstacleRectangle = 'Rectangular obstacle',
  ObstacleWalkway = 'Walkway',
  TreeSpherical = 'Spherical Tree',
  TreeConical = 'Conical Tree',
  SinglePanel = 'Single Panel',
  TolPanelArray = 'Panel Array',
  FillRoof = 'Fill Roof',
  DrawToFillPanel = 'Draw to Fill',
  Inverter = 'Inverter',
  Battery = 'Battery',
  Energy = 'Energy',
  Simulate = 'Simulate',
  UploadPhoto = 'Upload Photo',
  Shading = 'Shading',
  MultiPanelSelection = 'Multiselect',
}

export enum ActionNames {
  Selected = 'selected',
  Copied = 'copied',
  mode = 'mode',
  none = ''
}

export enum Maps {
  GoogleSolar = "Google Solar",
  NearMap = "NearMap",
  GoogleMap = "Google Map",
  CustomImage = "Upload Image",
  None='none'
}
export const mappingMapsValue = [ 
  { name: "NearMap", value: -1 },
  { name: "Google Map", value: 1 },
  { name: "Google Solar", value: 4 },
  { name: "Upload Image", value: 2 }
];
export enum MapType {
  GOOGLEMAPS = 'GOOGLEMAPS',
  GOOGLESOLAR = 'GOOGLEMAPS',
  NEARMAPS = 'NEARMAPS',
}

export const upperCaseMaps = [
  {value: MapType.GOOGLEMAPS, viewValue: 'Google Map'},
  {value: MapType.GOOGLESOLAR, viewValue: 'Google Solar'},
  {value: MapType.NEARMAPS, viewValue: 'NearMap'}
];

export type SelectedObjectId = Nullable<RoofSection['id']>;

