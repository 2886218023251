import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../helpers/auth.service';
import { MeterUpdate } from './project-details/interfaces/updatemeter';
import { Utils } from 'src/app/helpers/utils';
import { ProjectListApiResponse, RequestPayload, toProjectList } from 'src/app/models/sub-graph.model';
import { map, catchError } from 'rxjs/operators';
import { Status } from 'src/app/models/simple.types';
import { APIResponse } from '@api/models/api-response.model';
import { QuoteList, QuoteListAPIRequest } from '@api/models/quote-list.model';
import { RecentQuoteAPIRequest } from '@api/models/quote-recent.model';
import { QuoteService } from '@api/services/quote.service';
import { openNotificationService } from '@lib-ui/lib/atomic/enact-alert-snack-bar/openNotification.service';
import { ServicesOfferedService } from '@api/services/services-offered.service';
import { LeadService } from '@api/services/lead.service';
import { PanelConfigurationService } from '@api/services/panel-configuration.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  porjectWorkList: any = '';
  workFlowList: any = "";
  workFlowList1: any = "";
  workFlowObj: any;
  previousWorkflow: any;
  workFlowNameWith: any
  workFlowNameWithAccountId = "";
  workflowNameWhenCloseDropdown: any;
  workFlowLable: any;
  workFlowData: any
  userWorkFlowList: any = ''
  useMock = environment.useMock;
  workflowHeading = new BehaviorSubject<boolean>(false);

  updateLeadUi = new BehaviorSubject<boolean>(false);
  checked: boolean = true;
  onPieChartClick$ = new Subject<string>();
  onCancelClick$ = new Subject<any>();
  defaultAccountCount: any[] = [];
  selectedWorkflow: string = 'All_Workflows';

  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    private quoteService : QuoteService,
    private notificationService: openNotificationService,
    private servicesOfferedService: ServicesOfferedService,
    private leadService: LeadService,
    private panelConfigurationService: PanelConfigurationService,
  ) {
  }

  showNotifyBar(msg: string, type: string) {
    this.notificationService.sendMessage({
      message: msg,
      type: type,
      positionClass: "toast-bottom-left",
      timeOut:5000
    })
  }

  getClosedProject() {
    // https://java11api.enact-systems.com/lead/close
    return this.http.get(`${environment.newApiUrl}lead/close`);
  }
  getListOfProjects(reqBody: any): Observable<any> {
    // return this.http.get(`assets/mockdata/projects.json`);
    return this.http.post(`${environment.newApiUrl}projects`, reqBody);
  }
  getColumnPreferences(params: any): Observable<any> {
    // return this.http.get(`assets/mockdata/project_columns.json`);
    return this.http.get(`${environment.newApiUrl}user/project-column-references`, { params });
  }
  updateOwner(reqBody: any): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/assign`, reqBody);
  }
  updateColumnPreferences(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}user/project-column-references`, reqBody);
  }
  createProject(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}projects/create`, reqBody);
  }
  deleteProjects(reqBody: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}projects`, options);
  }
  getLeadSources(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id')
    };
    return this.http.get(`${environment.newApiUrl}account/lead-sources`, { params });
  }

  // Obtain team members from the server first time.
  // Returns the initial value after that
  // To obtain the value from the server, set param force to true.
  getTeamMembers(force = false): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      departmentKey: 'sales'
    };
    return this.http.get(`${environment.newApiUrl}account/members/hierarchy`, { params });
  }

  getCustomerTypes(): Observable<any> {
    return this.http.get(`${environment.newApiUrl}account/customer-type`);
  }
  getProjectTypes(): Observable<any> {
    return this.http.get(`${environment.newApiUrl}account/project-type`);
  }
  getWorkflows(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('role')
    };
    return this.http.get(`${environment.newApiUrl}account/workflow`, { params });
  }

  saveWorkflow(reqBody: any = []) {
    return this.http.put(`${environment.newApiUrl}workflow`, reqBody)
  }

  getDownloadableData(reqBody: any): Observable<any> {
    const apiUrl = `${environment.newApiUrl}projects/export-v1`;
    return this.http.post(apiUrl, reqBody);
  }
  // getListOfQuotes(reqData: any): Observable<any> {
  //   return this.http.post(`${environment.newApiUrl}quotes/fetch`, reqData);
  // }
 
  downloadFile(fileUrl: string): Observable<any> {

    return this.http.get(`${fileUrl}`, { responseType: 'blob', headers: { Accept: 'application/octet-stream', 'x-aws-enact-key': '5A5C7D4736176IOP497347SUAY72656174XTZ', } });
  }
  downloadFileTemplate(): Observable<any> {
    return this.http.get(`${environment.newApiUrl}lead/bill/load-profile/template`, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  getProjectBasicInfo(): Observable<any> {
    return this.http.get(`assets/mockdata/project_info.json`);
    // return this.http.get(`${environment.newApiUrl}access`,  {params: requestBody});
  }
  getGraphForTierData(reqBody: any): Observable<any> {
    this.clearLeadInfoCache();
    const apiUrl = environment.newApiUrl + 'lead/bill/tier-data';
    return this.http.post(apiUrl, reqBody);
  }
  getGraphForDailyAverage(reqBody: any): Observable<any> {
    this.clearLeadInfoCache();
    const apiUrl = environment.newApiUrl + 'lead/bill/daily-average';
    return this.http.post(apiUrl, reqBody);
  }

  getGraphForAllAverages(reqBody: any, headers?: HttpHeaders) {
    this.clearLeadInfoCache();
    let averageType = ['month', 'quarter', 'year']
    let apiArr: any[] = [];
    let req;
    averageType.forEach((el: any) => {
      apiArr.push(this.http.post(`${environment.newApiUrl}lead/bill/daily-average`, req = {
        crn: reqBody.crn,
        meterNumber: reqBody.meterNumber,
        duration: el
      }, { headers }))
    })


    return forkJoin(apiArr);
  }

  getLeadInfo(crn: string): Observable<any> {
    return this.leadService.getLeadInfo(crn);
  }
  clearLeadInfoCache():void{
    this.leadService.clearCache();
  }
  getUtilityProviders(reqBody: any, headers?: HttpHeaders): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill/utilityinfo`, reqBody, {headers});
  }
  getBillTypes(): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.get(`${environment.newApiUrl}lead/bill/type`);
  }
  updateQuote(reqBody: any): Observable<any> {
    return this.http.put(`${environment.newApiUrl}quotes`, reqBody);
  }

  updateQuoteNote(payload: any): Observable<any> {
    return this.quoteService.updateQuoteNote(payload);
  }

  updateFinanceNote(payload: any): Observable<any> {
    return this.quoteService.updateFinanceNote(payload);
  }

  updateBasicInfo(reqBody: any): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.put(`${environment.newApiUrl}lead`, reqBody);
  }

  deleteQuote(reqBody: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}quotes`, options);
  }
  
  getSalesPriceDetails(): Observable<any> {
    return this.http.get(`assets/mockdata/sales_price_details.json`);
  }
  getSalesDetails(val: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      Profile_name: val
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(`${environment.newApiUrl}pricing`, { params });
  }
  getSalePriceCostinColumn() {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get(`${environment.newApiUrl}sale-price-costing-column`, { params });

  }
  getRackingTypes() {
    const params = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.panelConfigurationService.getRackingType(params)
  }
  
  getIncentiveDetails(val: any) {

    return this.http.get(`${environment.newApiUrl}rebate/${val}` + `?account_id=` + this.authService.getItem("account_id") +
      `&email_id=` + this.authService.getItem("email_id") + `&parent=` + this.authService.getItem("parent"));


  }
  getLoanDetails(val: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id')
    };
    return this.http.get(`${environment.newApiUrl}loan/${encodeURIComponent(val)}`, { params });

  }
  getQuoteFinanceDetails(req: any) {
    return this.http.post(`${environment.newApiUrl}quotes/finance`, req);
  }
  getFinOptionDetails() {
    return this.http.get(`assets/mockdata/fin_option_details.json`);
  }

  fetchCustomTemplate(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem('role'),
    };
    return this.http.get(`${environment.newApiUrl}custom-template`, { params });
  }
  fetchWorkfolwList(): Observable<any> {
    const params: any = {

      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('role'),
      fieldsToFind: [
        "account_id",
        "workflowName",
        "activeInd",
        "primaryProfile"

      ],
      workSetup: true,
      activeInd: true
    };

    return this.http.post(`${environment.newApiUrl}workflow`, params);
  }


  updateConsumerAnnualBill(reqBody: any) {
    this.clearLeadInfoCache();
    const apiUrl = environment.newApiUrl + 'lead/bill/annual';
    return this.http.post(apiUrl, reqBody);
  }
  getCustomExtraTags(template: any, account_id: any): Observable<any> {
    const params: any = {
      account_id: account_id,
      template_name: template
    };
    return this.http.get(`${environment.newApiUrl}custom-template/tags`, { params });
  }
  getEmailTemplate() {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('role'),
    };
    return this.http.get(`${environment.newApiUrl}email-template`, { params });
  }
  generateCustomPDF(quoteData: any) {
    return this.http.post(`${Utils.getBaseurl()}quoteProposal/generateCustomePDF`, quoteData);
  }

  updateQuoteNotes(reqBody: any): Observable<any> {
    const qrn = this.authService.getItem('qrn', 'localStrorage');
    return this.http.put(`${environment.newApiUrl}quotes/details/${qrn}/notes`, reqBody);
  }
  updateQuoteProposalImage(formData: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}quotes/custom-proposal-images`, formData);
  }
  getActivities(crn: any, headers?: HttpHeaders) {

    return this.http.get(`${environment.newApiUrl}lead/log?crn=${crn}`, { headers });
  }
  
  getUserInfo(account_id: any, email_id: any) {

    return this.http.get(`${environment.newApiUrl}user?account_id=${account_id}&email_id=${email_id}`);
  }
  getServicesOffered(account_id: any, email_id: any, parent: any) {
    return this.servicesOfferedService.getServicesOffered({account_id, email_id, parent}); 
  }
  suspendUser(data: any) {
    return this.http.put(`${environment.newApiUrl}user`, data);

  }

  removeUser(data: any) {

    return this.http.delete(`${environment.newApiUrl}user?account_id=${data.account_id}&email_id=${data.email_id}&role=${data.role}&parent=${data.parent}`);
  }

  updateMeterWiseUtility(updateData: MeterUpdate) {
    this.clearLeadInfoCache();
    const url = `${environment.newApiUrl}lead/bill/utilityinfo`;
    return this.http.put(url, updateData);
  }


  splitAnnual(url: any, data: any) {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill/${url}`, data);
  }

  updateBill(req: any) {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill`, req);
  }
  fetchProfileData(val: any) {
    this.clearLeadInfoCache();
    let req = {
      "nameId": val,
      "locale": this.authService.getItem('localeId')
    }
    return this.http.post(`${environment.newApiUrl}lead/standard-load-profile`, req);
  }

  fetchProfileData1(val: any, headers: HttpHeaders) {
    this.clearLeadInfoCache();
    let apiArr: any[] = [];
    let req;
    val.forEach((el: any) => {
      apiArr.push(this.http.post(`${environment.newApiUrl}lead/standard-load-profile`, req = {
        "nameId": el,
        "locale": this.authService.getItem('localeId')
      }, { headers }))
    })

    return forkJoin(apiArr);
  }
  
  getLoadProfileData(loadProfile: any): Observable<any> {
    const req= {
      "nameId": loadProfile,
      "locale": this.authService.getItem('localeId')
    }
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/standard-load-profile`, req)
  }

  updateConsumerIntervalBill(obj: any) {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill/interval`, obj)
  }

  uploadTemplate(formData: any): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill/load-profile`, formData);
  }

  addNewMeter(body: any): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.post(`${environment.newApiUrl}lead/bill/add-new-meter`, body);
  }
  activateUser(data: any) {
    return this.http.put(`${environment.newApiUrl}user`, data);
  }

  addNewUser(data: any) {
    return this.http.post(`${environment.newApiUrl}user/add`, data);
  }

  updateUser(data: any) {
    return this.http.put(`${environment.newApiUrl}user`, data);
  }
  getAllServices(account_id: any, role: any) {

    return this.http.get(`${environment.newApiUrl}user/services?account_id=${account_id}&role=${role}`);
  }


  getDepartmentList() {
    return this.http.get(`${environment.newApiUrl}departments/all`);
  }

  addDealer(data: any) {
    return this.http.post(`${environment.newApiUrl}account`, data);
  }

  readImage(formData: any) {

    return this.http.get(`${environment.newApiUrl}account?account_id=${formData.account_id}`);
  }
  getLogo(imageId: any): any {
    return this.http.get(`${environment.newApiUrl}campaign-images/` + imageId, { responseType: 'text' });
  }
  updateData(data: any, parent: any) {
    let req =
    {
      "parent": parent,
      "update_service": data
    }
    return this.http.put(`${environment.newApiUrl}user/services`, req);
  }
  updateUtilityProviders(reqBody: any): Observable<any> {
    this.clearLeadInfoCache();
    return this.http.put(`${environment.newApiUrl}lead/bill/utilityinfo`, reqBody);
  }

  uploadImage(formData: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}campaign`, formData);
  }

  // to remove the meter
  removeMeter(index: any): Observable<any> {
    this.clearLeadInfoCache();
    const url = `${environment.newApiUrl}${'lead/bill/delete-meter'}`
    return this.http.post(url, index)
  }

  getAllDocuments(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      crn: localStorage.getItem('crn'),
      user_type: this.authService.getItem('user_type'),
    };
    return this.http.get(`${environment.newApiUrl}document?account_id=${params.account_id}&user_type=${params.user_type}&crn=${params.crn}`);
  }

  uploadDocuments(formData: any): Observable<any> {
    return this.http.post(`${Utils.getBackendBaseURL()}documents`, formData, { headers: { 'x-aws-enact-key': '5A5C7D4736176IOP497347SUAY72656174XTZ' } });
  }

  deleteDocuments(doc_id: any): Observable<any> {
    return this.http.delete(`${environment.newApiUrl}document/${doc_id}`);
  }


  // fetchCompletQuotes(): Observable<any> {
  //   const params: any = {
  //     crn: localStorage.getItem('crn'),
  //   };
  //   return this.http.get(`${environment.newApiUrl}quotes/completed?crn=${params.crn}`);
  // }
  contractWithUSerInput(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}document/contract-with-user-inputs`, reqBody);
  }

  updateNameAndEmailForDocusign(reqBody: any): Observable<any> {
    return this.http.put(`${environment.newApiUrl}docusign`, reqBody);
  }

  getContractDetails(quoteId: any, fileName: any, revNo: any): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      crn: localStorage.getItem('crn'),
      user_type: this.authService.getItem('user_type'),
      email_id: this.authService.getItem('email_id'),
      localeId: this.authService.getItem('localeId'),
      revision_number: Number(this.authService.getItem('revision_number')),
    };
    return this.http.get(`${environment.newApiUrl}document/contract?account_id=${params.account_id}&email_id=${params.email_id}&crn=${params.crn}&quote_id=${quoteId}&revision_number=${revNo}&localeId=${params.localeId}&filename=${fileName}`);
  }

  // completedQuotes(obj: any) {
  //   return this.http.get(`${environment.newApiUrl}quotes/completed?crn=${obj.crn}`)
  // }

  
  
  getWorkflow(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}dashboard/workflow-profiles`, reqBody)
  }
  getRecentQuotes() {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      user_type: this.authService.getItem('user_type'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}quotes/recent?email_id=${params.email_id}&account_id=${params.account_id}&role=${params.user_type}&count=5`)
  }

  getRecentQuotesV2() {
    const payload: RecentQuoteAPIRequest = {
      accountId: this.authService.getItem('account_id'),
      role: this.authService.getItem('user_type'),
      count: 5
    };
    return this.quoteService.getRecentQuotes(payload);
  }


  getRecentStatus(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}dashboard/status-sources`, reqBody)
  }
  getRecentStatusForGraph(reqBody: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}dashboard/status-sources-area`, reqBody)
  }

  getDocuSignDone(docSignObj: any, crn: string = '') {
    docSignObj.account_id = this.authService.getItem('account_id'),
      docSignObj.email_id = this.authService.getItem('email_id'),
      docSignObj.server_site = `${environment.apiUrl}nact-client/enactUI/partners/ajax/corporate.html#page/service_partner/sp_lead_details_inner_content1?crn=${crn}`
    return this.http.post(`${environment.newApiUrl}docusign`, docSignObj)
  }

  getDocuSignStatus(crn: string = '', envelopeId: any) {

    const docSignObj: any = {
      account_id: this.authService.getItem('account_id'),
      user_type: this.authService.getItem('user_type'),
      crn: crn,
      envelopeId: envelopeId,
    };

    return this.http.get(`${environment.newApiUrl}docusign/status?account_id=${docSignObj.account_id}&email_id=${docSignObj.email_id}&crn=${docSignObj.crn}&server_site=server_site&envelopeId=${envelopeId}`);
  }

  getDocuSignDownload(crn: string = '', envelopeId: any) {

    const docSignObjj = {
      account_id: this.authService.getItem('account_id'),
      user_type: this.authService.getItem('user_type'),
      crn: crn,
      server_site: 'server_site',
      envelopeId: envelopeId,
    }

    return this.http.get(`${environment.newApiUrl}docusign/download`, { params: docSignObjj })
  }

  getProjectList(payload: RequestPayload): Observable<ProjectListApiResponse> {
    return this.http.post(`${environment.newApiUrl}dashboard/sub-graph-table-data`, payload)
      .pipe(
        map(toProjectList),
        catchError((error) => of({ data: [], status: Status.Error, error: error } as ProjectListApiResponse))
      )
  }

  detectCancelEvent(): Observable<any> {
    return this.onCancelClick$.asObservable();
  }

  workflowUpdateCanceled(taskName: string) {
    this.onCancelClick$.next({ cancel: true, taskName: taskName });
  }
  postActivity(crn: any, activity_name: any, activity_description: any, headers?: HttpHeaders) {
    this.clearLeadInfoCache();
    let data = {
      "crn": crn,
      "activity_name": activity_name,
      "activity_description": activity_description,
      "user_id": this.authService.getItem('tenant_id')
    }
    return this.http.post(`${environment.newApiUrl}lead/log`, data, { headers });
  }
  getQuoteListV2(payload: any): Observable<APIResponse<QuoteList>> {
    return this.quoteService.getQuoteList(payload);
  }
  
  deleteQuotev2(payload: any): Observable<any> {
    return this.quoteService.deleteQuote(payload);
  }
  getCompletedQuoteList(payload: QuoteListAPIRequest) {
    return this.quoteService.getCompletedQuoteList(payload);
  }

  checkHeightData(headers?: HttpHeaders, mapOption?: string): Observable<any> {
    let crn = localStorage.getItem('crn');
    return this.http.get(`${environment.newSiteDataApiEndpoint}site/dsm-status?crn=${crn}&map_option=${mapOption}`, { headers })
      .pipe(map((res: any) => res));
  }
  
}


