<div class="nova-dialog">
  <div mat-dialog-title class="dialog-header">
    <div class="icon-dialog">
      <div *ngIf="data?.iconText" class="icon-container"
        [ngClass]="{'delete-icon-popup': data?.isDeleteConfirmationDialog, 'isArchive-border': data?.isArchive || data?.isRestore}">
        <ng-container *ngIf="data?.isArchive || data?.isRestore; else other_content">
          <div [ngClass]="{'isRestore': data?.isRestore, 'isArchive': data?.isArchive}"></div>
        </ng-container>
        <ng-template #other_content>
          <mat-icon class="dialog-icon" [inline]="true" *ngIf="data?.iconText">{{data?.iconText}}</mat-icon>
        </ng-template>
      </div>
      <div class="title-text"> {{data?.title}}</div>
    </div>
    <h2 *ngIf="!data?.iconText">
      {{data?.title}}
    </h2>
  </div>

  <div mat-dialog-content class="dialog-message">
    <p>{{data?.message}}</p>
  </div>

  <mat-dialog-actions align="center" class="dialog-actions">

    <button mat-raised-button [mat-dialog-close]="data.closeButtonLabel || 'Cancel'" cdkFocusInitial>
      {{ 'confirmDialogueBtns.'+ (data.closeButtonLabel || 'Cancel') | translate }}
    </button>

    <button mat-raised-button *ngFor="let button of data.actionButtons; let i = index" [mat-dialog-close]="button"
      [ngClass]="{'delete-btn': ['delete','remove'].includes(button.toLowerCase()), 'primary-btn': data?.primaryButtonIndex === i}">
      {{ 'confirmDialogueBtns.'+ (button || 'Ok') | translate }}
    </button>
  </mat-dialog-actions>
</div>