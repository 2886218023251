<div class="enact-colorpicker">
  <span class="color-picker-label">
    <ng-content></ng-content>
  </span>
  <div class="input-wrapper">
    <input
      matInput
      (click)="onColorPopUp($event)"
      [value]="selectedColor.name"
      readonly
    />
    <div
      class="colorBox"
      [ngStyle]="{ 'background-color': selectedColor.value }"
    ></div>
  </div>
  <div class="overlay" *ngIf="enabledPopUp">
    <div class="popup">
      <label class="lableClass" *ngIf="presetLabelName">{{
        presetLabelName
      }}</label>
      <div class="flex-box">
        <div
          class="colorselection"
          *ngFor="let color of enactPresetColors"
          [ngStyle]="{ 'background-color': color.value }"
          (click)="onChangeColor(color)"
        >
          <div
            [ngClass]="
              selectedColor.name == color.name ? 'checked' : 'unchecked'
            "
          >
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
