import { Vector2, Vector3 } from "three";
import Util, { constants } from "./Utility";
import CollisionUtil from "../utils/collision.util";

export default class PolygonOffsetter {

    constructor() {

    }

    offset(polygon: Vector2[], distances: number[], roofPoints2D: Vector3[], outerOffset: boolean = true) {

        if (polygon.length != distances.length) {
            console.log("polygon lines and distances are not same size.")
        }

        let offsetPoly: Vector3[] = [];
        let NUM_POINTS = polygon.length;
        let prevInd = NUM_POINTS - 1;
        let nextInd = 0;

        for (let i = 0; i < NUM_POINTS; ++i) {
            nextInd = (i + 1) % NUM_POINTS;

            let prevOffset = distances[prevInd];
            let curOffset = distances[i];

            let prevPoint = polygon[prevInd].clone();
            let curPoint = polygon[i].clone();
            let nextPoint = polygon[nextInd].clone();


            const midPerpsList0 = this.computePerpendicular(prevPoint, curPoint);
            const midPerpsList1 = this.computePerpendicular(curPoint, nextPoint);

            const midPerps0 = midPerpsList0.filter((perpInfo) => {
                let isInside = CollisionUtil.isPointInPolygon(polygon, perpInfo.point.clone());
                return outerOffset ? !isInside : isInside;
            });

            const midPerps1 = midPerpsList1.filter((perpInfo) => {
                let isInside = CollisionUtil.isPointInPolygon(polygon, perpInfo.point.clone());
                return outerOffset ? !isInside : isInside;

            });

            if (midPerps0.length == 0 || midPerps1.length == 0) {
                throw new Error("Unable to create setback - polygon too narrow to have a setback");
            }

            let [intersection, mid0Off, mid1Off] = this.computeOffsettedMid(prevPoint, curPoint, nextPoint, midPerps0[0], midPerps1[0], prevOffset, curOffset);


            if (intersection && mid0Off && mid1Off) {
                if (CollisionUtil.isPointInPolygon(polygon, intersection.clone())) {
                    //  in some cases where 2 adjacent lines are paralle to each other and one of the lines is a gutter, then intersection of the offsetted lines of these 2 lines is outside the polygon
                    const point3D = new Vector3(intersection.x, intersection.y, roofPoints2D[i].z);
                    offsetPoly.push(point3D);
                } else {
                    const mid0Off3D = new Vector3(mid0Off.x, mid0Off.y, roofPoints2D[i].z);
                    const mid1Off3D = new Vector3(mid1Off.x, mid1Off.y, roofPoints2D[i].z);
                    offsetPoly.push(mid0Off3D, mid1Off3D)
                }

            }
            prevInd = i;
        }
        return offsetPoly;
    }

    computePerpendicular(start: Vector2, end: Vector2) {
        const direction = end.clone().sub(start);
        const midpoint = start.clone().add(direction.clone().multiplyScalar(0.5));
        const perpVec = new Vector2(-direction.y, direction.x);
        perpVec.normalize();
        let SCALE = constants.DefaultEdgeThickness;

        const pointInPerp = midpoint.clone().add(perpVec.clone().multiplyScalar(SCALE));

        let otherPerpVec = perpVec.clone().negate();
        const otherPointInPerp = midpoint.clone().add(otherPerpVec.clone().multiplyScalar(SCALE));

        const ptInPerp: Perpendicular = { point: pointInPerp, midpoint: midpoint, dir: perpVec }
        const otherPtInPerp: Perpendicular = { point: otherPointInPerp, midpoint: midpoint, dir: otherPerpVec }
        return [ptInPerp, otherPtInPerp];
    }

    computeOffsettedMid(start: Vector2, mid: Vector2, end: Vector2, perpInfo0: Perpendicular, perpInfo1: Perpendicular, prevOffset: number, curOffset: number) {
        let startOff = start.clone().add(perpInfo0.dir.clone().multiplyScalar(prevOffset));
        let mid0Off = mid.clone().add(perpInfo0.dir.clone().multiplyScalar(prevOffset));

        let mid1Off = mid.clone().add(perpInfo1.dir.clone().multiplyScalar(curOffset));
        let endOff = end.clone().add(perpInfo1.dir.clone().multiplyScalar(curOffset));

        //let intersection = math.intersect(this.toArray(startOff), this.toArray(mid0Off),
        //this.toArray(mid1Off), this.toArray(endOff));

        let intersection = this.intersectLines(startOff, mid0Off, mid1Off, endOff);

        if (!intersection) {
            intersection = mid1Off;


            console.warn(" Unable to find intersection, something went wrong");
        }

        return [intersection, mid0Off, mid1Off];
    }

    intersectLines(seg1Start: Vector2, seg1End: Vector2, seg2Start: Vector2, seg2End: Vector2) {
        const seg1Direction = seg1End.clone().sub(seg1Start);
        const seg2Direction = seg2End.clone().sub(seg2Start);

        const determinant = seg1Direction.x * seg2Direction.y - seg1Direction.y * seg2Direction.x;

        if (Math.abs(determinant) < 1e-6) {
            return null; // Lines are parallel, no intersection
        }

        const startDiff = seg2Start.clone().sub(seg1Start);

        const t = (startDiff.x * seg2Direction.y - startDiff.y * seg2Direction.x) / determinant;
        const u = (startDiff.x * seg1Direction.y - startDiff.y * seg1Direction.x) / determinant;

        // Check if the intersection point is within the segments
        //if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        // Calculate the intersection point
        const intersection = seg1Start.clone().add(seg1Direction.clone().multiplyScalar(t));
        return intersection;
        // } else {
        //   return null; // Intersection point is outside the segments
        // }
    }
}

interface Perpendicular {
    point: Vector2,
    midpoint: Vector2,
    dir: Vector2,
}
