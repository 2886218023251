import { AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { hasTreeViewChanged, uniquesList, Utils } from 'src/app/helpers/utils';
import { AuthService } from '../../../../helpers/auth.service';
import { ProjectsService } from '../../../projects/projects.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';


declare var Highcharts: any;

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})

export class PieChartComponent implements AfterViewInit, OnDestroy {
  margin = 40;
  width: number;
  height: number;
  radius: number;
  arc: any;
  labelArc: any;
  labelPer: any;
  pie: any;
  color: any;
  svg: any;
  svg1: any;
  teamMembers: any;
  allAccount: any = [];
  dataStatus: any = [];
  dataReferal: any = [];
  dataSource: any = [];
  dataSubStatus: any = [];
  svg2: any;
  svg3: any;
  startDate: any;
  enddate: any;
  @Input() byWay:any;
  @Input()  dates:any;
  @Input() workflow:any;
  countData: any=[];
  amountData: any=[];
  leadcountData: any=[];
  leadamountData: any=[];
  refcountData: any=[];
  refamountData: any=[];
  sourcecountData: any=[];
  sourceamountData: any=[];
  @Inject(LOCALE_ID) private locale: string | any
  selectedBy: any;
  selectedProjectStatus = 'Lead';
  subscriptions: Subscription = new Subscription();
  userLocale: any;
  constructor(
    private translate: TranslateService,
    private projectsService: ProjectsService,
    public utils: Utils,
    private authService: AuthService,
    
  ) {
    
    this.width = 450;
    this.height = 350;
    this.radius = Math.min(this.width, this.height) / 2 - this.margin;
    this.userLocale = localStorage.getItem('countryCurr');
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.startDate=new Date(this.dates.value['start']).getTime();
    this.enddate=new Date(this.dates.value['end']).getTime();
    this.getTeamMembers();
  }

 
  getTeamMembers() {
    this.subscriptions.add(this.projectsService.getTeamMembers().subscribe(
      (resp: any) => {
        this.teamMembers = resp;
        this.teamMembers.forEach((element: any) => {
          element.show = true;
          this.allAccount.push({
            accountId: element.account_id,
            emailId: element.email_id,
          });
          element.ChildrenItems.forEach((child: any) => {
            this.allAccount.push({
              accountId: child.account_id,
              emailId: child.email_id,
            });
            child.selected = false;
          });
        });
        this.getStatusAndSource(
          uniquesList(this.allAccount, 'accountId', 'emailId'),
          this.byWay,
          this.startDate,
          this.enddate
        );
      },
      (err: any) => {
        console.log(err);
      }
    ));
  }

  getStatusAndSource(
    account: any,
    name: any,
    startDate: any,
    endDate: any
  ) {
    this.selectedBy=name;
    this.countData=[];
    this.amountData=[]
    const reqBody: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('user_type'),
      email_id: this.authService.getItem('email_id'),
      status: '',
      startTime: startDate,
      endTime: endDate,
      workFlowChanged: this.projectsService.selectedWorkflow !== 'All_Workflows',
      selectedWorkFlow: this.projectsService.selectedWorkflow,
      treeViewChanged: hasTreeViewChanged(account, this.projectsService.defaultAccountCount),
      accountsAndEmailFromTreeView: account,
    };
    this.dataStatus = [];this.dataReferal = [];this.dataSource = [];this.dataSubStatus = []
    this.subscriptions.add(this.projectsService.getRecentStatus(reqBody).subscribe((data: any) => {
      this.dataStatus = data.statuses;
      this.dataReferal = data.referals;
      this.dataSource = data.sources;
      this.dataSubStatus = data.subStatuses;
      if(this.dataStatus){
        this.dataStatus.forEach((element: any) => {
          if (! this.countData.find((o: { name: string; }) => o.name === element.name)){
           this.countData.push({'name': element.name,'y': element.count})
          }
          if (! this.amountData.find((o: { name: string; }) => o.name === element.name)){
          
             this.amountData.push({'name': element.name,'y':element.amount})
          }  
        });
      }
       
      if(this.dataSubStatus?.Lead){
        this.dataSubStatus.Lead.forEach((element: any) => {
          if (! this.leadcountData.find((o: { name: string; }) => o.name === element.name)){
            this.leadcountData.push({'name': element.name,'y': element.count})
           }
           if (! this.leadamountData.find((o: { name: string; }) => o.name === element.name)){
            this.leadamountData.push({'name': element.name,'y': element.amount})
           }  
         });
      } else {
        this.leadamountData = [];
        this.leadcountData = [];
      }
      if(this.dataReferal){
        this.dataReferal.forEach((element: any) => {
          if (! this.leadcountData.find((o: { name: string; }) => o.name === element.name)){
            this.refcountData.push({'name': element.name,'y': element.count})
           }
           if (! this.leadamountData.find((o: { name: string; }) => o.name === element.name)){
            this.refamountData.push({'name': element.name,'y': element.amount})
           }  
         });
      }
      if(!!this.dataSource?.length){
        this.dataSource.forEach((element: any) => {
          if (! this.sourcecountData.find((o: { name: string; }) => o.name === element.name)){
            this.sourcecountData.push({'name': element.name,'y': element.count})
           }
           if (! this.sourceamountData.find((o: { name: string; }) => o.name === element.name)){
            this.sourceamountData.push({'name': element.name,'y': element.amount})
           }  
         });
      } else {
        this.sourceamountData = [];
        this.sourcecountData = [];
      }
      
       this.createHighChart(this.selectedBy);
       this.createHighChartLead(this.selectedBy,"Lead");
       this.createHighChartRefrral(this.selectedBy);
       this.createHighChartSource(this.selectedBy);
    }));
  }

  PieClick(name:any){
    this.selectedProjectStatus = name;
    console.log(this.dataSubStatus)
    this.leadcountData=[];
    this.leadamountData=[]
    for (let x in this.dataSubStatus) {
         if(x==name){
           this.dataSubStatus[x].forEach((element: any) => {
            if (! this.leadcountData.find((o: { name: string; }) => o.name === element.name)){
              this.leadcountData.push({'name': element.name,'y': element.count})
             }
             if (! this.leadamountData.find((o: { name: string; }) => o.name === element.name)){
              this.leadamountData.push({'name': element.name,'y': element.amount})
             }  
           });
         }
    }
    this.createHighChartLead(this.selectedBy,name);
  }

  createHighChart(val:any) {
   
    if(val=='count'){
      Highcharts.chart('container', {
        chart: {
          type: 'pie',
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        title: {
          text: this.translateMsg('PROJECT STATUS'),
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}',
            },
            point: {
              events: {
                click:  (evt:any) =>{
                   this.PieClick(evt.point.name)
                }
              }
            },
          },
        },
  
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>{point.y}</b> <br/>',
        },
  
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.countData
          },
        ],
      });
    }
    if(val=='amount'){
   
      Highcharts.chart('container', {
        chart: {
          type: 'pie',
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        title: {
          text: this.translateMsg('PROJECT STATUS'),
        },
       
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valuePrefix: '$',
   
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: '+ this.userLocale +'{point.y:,.2f}'              
            },
            point: {
              events: {
                click:  (evt:any) =>{
                   this.PieClick(evt.point.name)
                }
              }
            },
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>'+ this.userLocale + '{point.y}</b> <br/>',
          },
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.amountData
          },
        ],
      });
      Highcharts.setOptions({
        lang: {
          thousandsSep: ','
        }
    });
    }
 }

  createHighChartLead(val:any,name:any) {
  
    let that = this;
    if(val=='count'){
      Highcharts.chart('container1', {
        chart: {
          type: 'pie',
        },
        title: {
          text: name,
          style: {
            textTransform: "uppercase",
        }
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}',
            },
          },
        },
      tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>{point.y}</b> <br/>',
        },
      series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.leadcountData,
            point: {
              events: {
                  click: function() {
                    const selectedPie = `${that.selectedProjectStatus}: ${this['name']}`;
                    that.projectsService.onPieChartClick$.next(selectedPie);
                  }
              }
           }
          },
        ],
     });
    }
    if(val=='amount'){
   
      Highcharts.chart('container1', {
        chart: {
          type: 'pie',
        },
        title: {
          text: name,
          style: {
            textTransform: "uppercase",
        }
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: '+ this.userLocale +'{point.y:,.2f}',
            },
          },
        },
      tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>'+ this.userLocale +'{point.y}</b> <br/>',
        },
      series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.leadamountData,
            point: {
              events: {
                  click: function() {
                    const selectedPie = `${that.selectedProjectStatus}: ${this['name']}`;
                    that.projectsService.onPieChartClick$.next(selectedPie);
                  }
              }
           }
          },
        ],
     });
    }
  
  }

  createHighChartSource(val:any) {
    if(val=='count'){
      Highcharts.chart('container2', {
        chart: {
          type: 'pie',
        },
        title: {
          text: this.translateMsg('LEAD SOURCES'),
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}',
            },
          },
        },
  
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>{point.y}</b> <br/>',
        },
  
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.sourcecountData
          },
        ],
       
      });
    }
    if(val=='amount'){
      Highcharts.chart('container2', {
        chart: {
          type: 'pie',
        },
        title: {
          text: this.translateMsg('LEAD SOURCES'),
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: '+ this.userLocale +'{point.y:,.2f}',
            },
          },
        },
  
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>'+ this.userLocale +'{point.y}</b> <br/>',
        },
  
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.sourceamountData
          },
        ],
       
      });
    }
    
  }
  createHighChartRefrral(val:any) {
   
    if(val=='count'){
      Highcharts.chart('container3', {
        chart: {
          type: 'pie',
        },
        title: {
          text: this.translateMsg('REFERRAL'),
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y}',
            },
          },
        },
  
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>{point.y}</b> <br/>',
        },
  
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.refcountData
          },
        ],
       
      });
    }
    if(val=='amount'){
      Highcharts.chart('container3', {
        chart: {
          type: 'pie',
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

        title: {
          text: this.translateMsg('REFERRAL'),
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
          point: {
            valueSuffix: '',
          },
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: '+ this.userLocale +'{point.y:,.2f}',
            },
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{point.name}</span><br>',
          pointFormat:
            '{point.name}: <b>'+ this.userLocale +'{point.y}</b> <br/>',
        },
        series: [
          {
            name: 'Browsers',
            colorByPoint: true,
            data: this.refamountData
          },
        ],
      });
    }
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get(val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

}
