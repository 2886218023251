import { RoofSection } from '@design/models/roof-section.model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DesignState } from '../design.reducer';
import { DESIGN_STORE_KEY, selectDesign } from '../design.selector';
import { adapter } from './roof-section.reducer';
import { RoofSectionState } from './roof-section.state';
import { fromVertexSelectors } from '../vertex';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

type SelectedEntitiesByIDProps = {
  id: RoofSection['id'];
};

export namespace fromRoofSectionSelectors {
  export const roofSectionStateSelector = createSelector(
    selectDesign,
    (state) => state.roofSection
  );

  export const allRoofSections = createSelector(
    roofSectionStateSelector,
    selectAll
  );

  export const selectRoofById = (ids: string[]) =>
    createSelector(roofSectionStateSelector, (state: RoofSectionState) => {
      const entities = selectEntities(state);
      return ids.map((id: RoofSection['id']) => entities[id]);
    });

  export const selectedRoofSection = createSelector(selectDesign, (state) => {
    const selectedId = state.ui.selectedObjectId;
    if (!selectedId) {
      return null;
    }
    const entities = state.roofSection.entities;
    return entities[selectedId];
  });

  export const selectedEntitiesByID = createSelector(
    roofSectionStateSelector,
    (entities: RoofSectionState, props: SelectedEntitiesByIDProps) => {
      return entities.entities[props.id];
    }
  );

  export const dragEventSelector = createSelector(
    selectDesign,
    (state: DesignState) => state.roofSection.showDragPopup
  );

  export const safeDeleteObjects = (roof: RoofSection) =>
    createSelector(
      fromVertexSelectors.findSafeToDeleteVertices(roof.vertexIds),
      // fromVertexSelectors.findSafeToDeleteVertices(roof.vertexIds),
      (vertices) => {
        return { vertices };
      }
    );
}
