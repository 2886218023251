import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService, LoaderState } from './loader.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  getUrl = false;
  private subscription: Subscription = new Subscription();
  constructor(private loaderService: LoaderService, private router: Router) { }
  ngOnInit(): void {
    this.subscription.add(this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.show = state.show;
      }
    ));

    this.subscription.add(this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const currentUrl = val.urlAfterRedirects;
        this.getUrl = currentUrl.includes('/design');
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
