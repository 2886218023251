import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { MatTable } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription, timer } from 'rxjs';
import { AuthService } from '../../../helpers/auth.service';
import { DecimalFraction, Utils } from '../../../helpers/utils';
import { SettingsService } from '../settings.service';
import { NovaDialogConfig } from 'src/app/helpers/interfaces/nova-dialog-config.interface';
import { ConfirmDialogService } from 'src/app/helpers/confirm-dialog/confirm-dialog.service';
import { isSuccess } from 'src/app/models/simple.types';
import { CostingProfile } from 'src/app/models/archive-costing-profile.model';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { NumberLocaleFormatPipe } from 'src/app/helpers/pipes/number-format.pipe';

@Component({
  selector: 'app-costing-setup',
  templateUrl: './costing-setup.component.html',
  styleUrls: ['./costing-setup.component.scss'],
  providers: [NumberLocaleFormatPipe]
})

export class CostingSetupComponent implements OnInit, OnDestroy {
  SALES_MARGIN = 'SalesMargin';
  MAX_RANGE = 100000;
  defaultCostItems = [
    'Module',
    'Inverter',
    'O&M Cost',
    'O&M Escalator',
    'O&M Year',
    'Balance of System',
    'Labor',
    'Permits',
    'Design',
    'Administration Cost',
  ];
  RACKING_POSITION = 5;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isSnackbarAlertVisible: any;
  costProfileRaw: any = {};
  costingProfiles: Array<any> = [];
  selectAll: boolean = true;
  costData: any = [];
  applyLocaleVar: any = this.utils.countryCurr;
  selectedProfile: string = '';
  isSelectedDefault = false;
  ranges: Array<any> = [
    {
      name: 'r1',
      index: 'r1',
    },
  ];
  displayedColumns: string[] = [
    'delete',
    'item',
    'select',
    'description',
    'type',
    'minMarkup',
    'r1',
  ];
  rackingTypes: Array<any> = [];
  hasSaleMargin = false;
  minimumMargin = 0;
  profileOwner: any = '';
  isOAndM = { "value": false, "displayOnCostingSetup": false };
  visibleToDealer = true;
  hideSalePriceBreakdown = false;
  hideCalualtedValue = false;
  hideMargin = false;
  @ViewChild('costtable') table: MatTable<any> | any;
  @ViewChild('rangetable') rangeTable: MatTable<any> | any;
  @ViewChild('costingSetupForm') costingSetupForm!: NgForm;
  //Creating new profile
  createProfileStarted = false;
  newProfileName = '';
  rangeTableData: Array<any> = [];
  rangeTableDisplayColumns: Array<String> = [
    'range',
    'minimum',
    'maximum',
    'add',
  ];
  setupRange = false;
  salesTaxSetup = { value: 0, type: '' };
  copyProfile = false;
  subscriptions = new Subscription();
  isDealer$ = new BehaviorSubject(false);
  isDealerEmployee$ = new BehaviorSubject(false);
  showArchive$ = new Subject();
  archiveList$= new Subject<any>();
  locale = 'en_US';
  subscription: Subscription = new Subscription();

  constructor(
    private settingsService: SettingsService,
    public utils: Utils,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private toastr: ToastrService,
    private confirmationService: ConfirmDialogService,
    private translate : TranslateService,
    private numberPipe: NumberLocaleFormatPipe

  ) { 
    this.locale = this.authService.getItem('localeId');
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.isSnackbarAlertVisible = false;
    const that = this;
    this.subscriptions.add(
      this.settingsService
        .getServicesOffered(['services_offered'])
        .subscribe((res: Array<any> | any) => {
          if (res) {
            const index = res[0].indexOf(that.SALES_MARGIN);
            if (index >= 0) {
              that.hasSaleMargin = true;
            }
          }
        })
    );
    this.getCostProfiles();
    this.getSalePriceAndCostingState();
    this.updateRole();
  }

  updateRole() {
    this.isDealer$.next(this.utils.isDealerAdmin());
    this.isDealerEmployee$.next(this.utils.isDealerEmployee());
  }
  getSalePriceAndCostingState() {
    this.subscriptions.add(
      this.settingsService
        .getSalePriceAndCostingColumn()
        .subscribe((res: any) => {
          if (res.hideSaleBreakdown) {
            this.hideSalePriceBreakdown = res.hideSaleBreakdown;
          }
          if (res.hideCostingSetupColumn) {
            this.hideCalualtedValue = res.hideCostingSetupColumn;
          }
          if (res.salesTax) {
            this.salesTaxSetup = res.salesTax;
          }
          if (res.hideBaseMargin) {
            this.hideMargin = res.hideBaseMargin;
          }
          this.isOAndM = res?.onmEditableDuringSales;
        })
    );
  }
  getCostProfiles(selectedProfilename = '') {
    const that = this;
    this.subscriptions.add(
      this.settingsService.costProfileList().subscribe((res: any) => {
        that.costingProfiles = res;
        this.subscriptions.add(
          that.settingsService.getRackingType().subscribe((res: any) => {
            that.rackingTypes = res;
            that.selectedProfile = !!selectedProfilename ? selectedProfilename : that.costingProfiles[0].Profile_name;
            if(!!selectedProfilename) this.showArchive$.next(false)
            that.getCostProfile(that.selectedProfile);
          })
        );
      })
    );
  }
  getCostProfile(profileName: string) {
    const that = this;
    this.subscriptions.add(
      this.settingsService.getCostingData(profileName).subscribe((res: any) => {
        if (res.length > 0) {
          that.createCostProfileTable(res[0]);
        }
      })
    );
  }
  createCostProfileTable(profile: any) {
    this.costData = [];
    this.costProfileRaw = profile;
    this.visibleToDealer = this.costProfileRaw.dealerVisibility !== 'false';
    if (this.hasSaleMargin == true) {
      if (profile['minimumMargin'] != undefined) {
        this.minimumMargin = profile['minimumMargin'];
      } else {
        this.minimumMargin = 0;
      }
    }
    this.convertProfileToDataSource(profile);
    this.displayedColumns = [
      'delete',
      'item',
      'select',
      'description',
      'type',
      'minMarkup',
    ];
    for (var j = 0; j < this.ranges.length; j++) {
      const index = `r${j + 1}`;
      this.displayedColumns.push(index);
    }
  }
  convertProfileToDataSource(rawCostProfile: any) {
    const that = this;

    this.subscriptions.add(this.settingsService.getServicesOfferedByUser(['variableCosting']).subscribe((res: Array<any> | any) => {
      that.isSelectedDefault = that.selectedProfile == res;
    },));
    const ranges = rawCostProfile.ranges;
    this.ranges = [];
    let startRange = 0;
    for (var j = 0; j < ranges.length; j++) {
      const index = `r${j + 1}`;
      const endIndex = ranges[j];
      const name = `${this.utils.numberFormat(startRange)}-${this.utils.numberFormat(endIndex)}`;
      startRange = Number(endIndex) + 0.1;
      this.ranges.push({ index: index, name: name });
    }
    const costing = rawCostProfile.values;
    for (let i = 0; i < costing.length; i++) {
      const cost = costing[i];
      const deletable = this.isDeletable(cost[0], i);
      const select = cost[1] == 'yes' ? true : false;
      if (select == false) {
        this.selectAll = false;
      }
      const items = this.getTypeItems(cost[0], j);
      const markerup =
        (cost[0] == 'O&M Escalator' || cost[0] == 'O&M Year') ? 'N/A' : cost[4];
      let data :any= {
        id: i,
        delete: deletable,
        name: cost[0],
        select: select,
        description: cost[2],
        type: items[0],
        selectItem: items,
        minMarkup: markerup,
      };
      if (!!cost[3] && cost[3].includes('Fixed')) {
        const type = `${cost[3].split(' ')[0]} ${this.applyLocaleVar}`;
        data = { ...data, type: type }
      } else if (!!cost[3] && (cost[3].includes('Watt') || cost[3].includes('Unit'))) {
        const type = `${this.applyLocaleVar}/${cost[3].split('/')[1]}`;
        data = { ...data, type: type }
      }
      let startIndex = 5;
      for (var j = 0; j < this.ranges.length; j++) {
        const index = `r${j + 1}`;
        data[index] = this.utils.numberFormat(cost[j + startIndex]);
      }
      this.costData.push(data);
    }
  }
  isDeletable(name: string, index: number) {
    const exclusion = [
      'Module',
      'Inverter',
      'O&M Cost',
      'O&M Escalator',
      'O&M Year',
    ];
    for (var i = 0; i < this.rackingTypes.length; i++) {
      exclusion.push(this.rackingTypes[i].name);
    }
    if (index >= exclusion.length) {
      return true;
    }
    const present = exclusion.includes(name);
    return !present;
  }
  getTypeItems(name: string, index: number) {
    const exclusion = ['O&M Cost', 'O&M Escalator', 'O&M Year'];
    let dd: Array<any> = [];
    const fixed = `Fixed ${this.applyLocaleVar}`;
    const watt = `${this.applyLocaleVar}/Watt`;
    const unit = `${this.applyLocaleVar}/Unit`;

    //if(index<=fixedTableIndex){
    switch (name) {
      case exclusion[0]:
        dd = [fixed, watt];
        break;
      case exclusion[1]:
        dd = ['Percent'];
        break;
      case exclusion[2]:
        dd = ['Year'];
        break;
      default:
        dd = [fixed, watt, unit];
        break;
    }
    return dd;
  }
  changeProfile(event: any) {
    const selectedProfile = event.value;
    this.getCostProfile(selectedProfile);
  }
  updateVisbleToDealer(value: any) {
    const data = {
      Profile_name: this.costProfileRaw.Profile_name,
      account_id: this.costProfileRaw.account_id,
      dealerVisibility: value,
      email_id: this.costProfileRaw.email_id,
    };
    this.subscriptions.add(
      this.settingsService.updateCostProfile(data).subscribe((res) => { })
    );
  }
  onOAndMChange(event: any) {
    const payload = { account_id: this.authService.getItem('account_id'), "key": "onmEditableDuringSales.value", "value": event };
    this.subscriptions.add(
      this.settingsService.updateOAndMEditableDuringSales(payload).subscribe((res) => { })
    );
  }
  updateHideCalualteValue(value: any) {
    this.subscriptions.add(
      this.settingsService.updateHideCostTable(value).subscribe((res) => { })
    );
  }
  updateHideSalePriceBreakdown(value: any) {
    this.subscriptions.add(
      this.settingsService
        .updateHideSalePriceBreakDown(value)
        .subscribe((res) => { })
    );
  }
  updateHideMargin(value: any) {
    this.subscriptions.add(
      this.settingsService
        .updateHideSalePriceBreakDown(value)
        .subscribe((res) => { })
    );
  }
  setAsDefault() {
    this.subscriptions.add(this.settingsService.setDefaultCostProfile(this.selectedProfile).subscribe((res) => {
      console.log('Profile set to default');
      this.isSelectedDefault = true;
    })
    );
  }
  archiveCurrentProfile() {
    const that = this;
    if (this.isSelectedDefault) {
      this.translate.get('toastrMsgs.defaultPrfCantArchive').subscribe((res: any) => {
        this.toastr.error(res, undefined, { positionClass: 'toast-top-right' });
      })
      return;
    } else {
      const dialogConfig: NovaDialogConfig = {
        title: this.translateMsg('archiveCostPrf'),
        message: this.translateMsg('sureAchiveCostPrf'),
        actionButtons: ['Yes'],
        closeButtonLabel: 'No',
        isDeleteConfirmationDialog: false,
        iconText: 'archive',
        isArchive: true
      }
      const dialogRef1 = this.confirmationService.novaDialog(dialogConfig);
      this.subscriptions.add(dialogRef1.afterClosed().subscribe(result => {
        if (result === 'Yes') {
          const payload: any = {
            account_id: this.authService.getItem('account_id'),
            profile_name: this.selectedProfile,
            is_archived: true
          };
          this.subscriptions.add(this.settingsService.archiveCostProfile(payload).subscribe((res) => {
            this.translate.get('toastrMsgs.costingaPfArchived').subscribe((res: any) => {
              this.toastr.success(res, undefined, { positionClass: 'toast-bottom-left', closeButton: true });
            })
            that.getCostProfiles();
          }, (error: any) => {
            this.translate.get('toastrMsgs.somethingWentWrongTryAgain').subscribe((res: any) => {
              this.toastr.error(res, undefined, { positionClass: 'toast-top-right', closeButton: true });
            })
          }));
        }
      }));
    }
  }

  viewArchive() {
    this.subscriptions.add(
      this.settingsService.getArchiveProfileList()
        .subscribe((response: any) =>
          isSuccess(response.status) && response?.data ? (this.archiveList$.next(response.data), this.showArchive$.next(true)) : 
            this.translate.get('toastrMsgs.somethingWentWrongTryAgain').subscribe((res: any) => {
              this.toastr.error(res, undefined, { positionClass: 'toast-top-right', closeButton: true });
            })))
  }

  onActionChange(action: CostingProfile) {
    const payload: any = {
      account_id: this.authService.getItem('account_id'),
      profile_name: action.Profile_name,
      is_archived: false
    };
    this.settingsService.restoreCostProfile(payload)
      .subscribe((res) => {
        this.translate.get('toastrMsgs.costingPfRestoredSuccess').subscribe((res: any) => {
          this.toastr.success(res, undefined, { positionClass: 'toast-bottom-left', closeButton: true });
        })
        this.getCostProfiles(action.Profile_name);
      }, (error: any) => {
        this.translate.get('toastrMsgs.somethingWentWrongTryAgain').subscribe((res: any) => {
          this.toastr.error(res, undefined, { positionClass: 'toast-top-right', closeButton: true });
        })
      })
  }

  copyAsNewProfile() {
    this.copyProfile = true;
  }
  saveCurrentProfile() {

    const accountId = this.costProfileRaw['account_id'],
      profileName = this.costProfileRaw['Profile_name'],
      email = this.costProfileRaw['email_id'],
      minMargin = this.minimumMargin + '',
      ranges = this.costProfileRaw['ranges'];
    const updatedProfile = this.convertDataSourceToDBObj(
      profileName,
      accountId,
      email,
      minMargin,
      true,
      ranges,
      this.costData
    );

    this.subscriptions.add(this.settingsService.updateCostProfile(updatedProfile).subscribe((res) => {
      this.translate.get('toastrMsgs.pfUpdatedSuccess').subscribe((res: any) => {
        this.toastr.success(res, undefined, { positionClass: 'toast-top-right' })
      })
    }));
  }
  convertDataSourceToDBObj(
    profileName: any,
    accountId: any,
    emailid: any,
    margin: any,
    visbletoDealer: any,
    ranges: any,
    costData: any
  ) {
    const updatedProfile: any = {};
    (updatedProfile['account_id'] = accountId),
      (updatedProfile['Profile_name'] = profileName),
      (updatedProfile['email_id'] = emailid),
      (updatedProfile['minimumMargin'] = margin + '');
    updatedProfile['ranges'] = ranges;
    updatedProfile['dealerVisibility'] = visbletoDealer;
    const values: Array<any> = [];
    //converting datasource to db object
    costData.forEach((item: any) => {
      const itemsarray = [];
      itemsarray.push(item.name);
      const select = item.select == true ? 'yes' : 'no';
      itemsarray.push(select);
      itemsarray.push(item.description);
      itemsarray.push(item.type);
      itemsarray.push(this.utils.removeCommasCurrency(item.minMarkup));
      for (var j = 0; j < this.ranges.length; j++) {
        const index = `r${j + 1}`;
        itemsarray.push(this.utils.removeCommasCurrency(item[index]));
      }
      values.push(itemsarray);
    });
    updatedProfile['values'] = values;
    return updatedProfile;
  }
  updateMinimumMargin() {
    const data = {
      Profile_name: this.costProfileRaw.Profile_name,
      account_id: this.costProfileRaw.account_id,
      minimumMargin: this.minimumMargin,
      email_id: this.costProfileRaw.email_id,
    };
    this.subscriptions.add(
      this.settingsService.updateCostProfile(data).subscribe((res) => { })
      );
    this.minimumMargin = this.utils.numberFormat(this.minimumMargin);
  }

  selectAllAdders(e: any) {
    this.costData.forEach((item: any) => {
      if (item.delete) item.select = this.selectAll;
    });
  }
  setAdderSelected(ev: any, element: any) { }
  setRowType(ev: any, element: any) { }
  descriptionChange(ev: any, element: any) { }

  minMarkupChange(ev: any, element: any) {
    let newValue = JSON.parse(JSON.stringify(ev.target.value));
    newValue = this.utils.removeCommasCurrency(newValue);
    this.subscription.add(timer(200).subscribe(() => {
      element.minMarkup = this.numberPipe.transform(newValue, DecimalFraction.THREE, DecimalFraction.ZERO);
    }));
   }

  changeRangeValue(ev: any, element: any, index: any) {
    let newValue = JSON.parse(JSON.stringify(ev.target.value));
    newValue = this.utils.removeCommasCurrency(newValue);
    this.subscription.add(timer(200).subscribe(() => {
      element[index] = this.utils.numberFormat(newValue);
    }));
  }

  nameChange(ev: any, element: any) { }

  addCostAdder() {
    const id = this.costData[this.costData.length - 1].id + 1;
    const selectItems = this.getTypeItems('Adder', 0);
    const data: any = {
      id: id,
      delete: true,
      name: '',
      placeholder: 'Adder',
      select: true,
      description: '',
      type: selectItems[0],
      selectItem: selectItems,
      minMarkup: 0,
    };
    for (var j = 0; j < this.ranges.length; j++) {
      const index = `r${j + 1}`;
      data[index] = 0;
    }
    this.costData.push(data);
    this.table.renderRows();
  }
  deleteRow(element: any) {
    const id = element.id;
    const index = this.costData.findIndex((item: any) => item.id == id);
    if (index > 0) {
      this.costData.splice(index, 1);
      this.table.renderRows();
    }
  }
  createNewProfile() {
    this.newProfileName = '';
    this.createProfileStarted = true;
    this.setupRange = false;
  }
  setNewCostProileName() {
    if (this.createProfileStarted == true) {
      if (this.newProfileName.trim() == '') {
        this.showBootboxAlert(this.translateMsg('prfNameCantEmpty'));
        return;
      }
      const index = this.costingProfiles.findIndex((item: any) => {
        item.Profile_name == this.newProfileName;
      });
      if (index >= 0) {
        this.showBootboxAlert(this.translateMsg('pfNameExist'));
        return;
      }
      this.setupRange = true;
      const tableElement = {
        id: 1,
        range: 'R1',
        min: 0,
        max: this.MAX_RANGE,
        last: true,
      };
      this.rangeTableData = [];
      this.rangeTableData.push(tableElement);
      this.rangeTable?.renderRows();
    } else if (this.copyProfile == true) {
      if (this.newProfileName.trim() == '') {
        this.showBootboxAlert(this.translateMsg('prfNameCantEmpty'));
        return;
      }
      const index = this.costingProfiles.findIndex((item: any) => {
        item.Profile_name == this.newProfileName;
      });
      if (index >= 0) {
        this.showBootboxAlert(this.translateMsg('pfName'));
        return;
      }
      this.copyToNewProfile(this.newProfileName.trim());
    }
  }
  copyToNewProfile(profileName: any) {
    const that = this;
    const accountid = this.authService.getItem('account_id'),
      email = this.authService.getItem('email_id'),
      minMarg = this.minimumMargin + '',
      ranges = this.costProfileRaw['ranges'],
      visbletoDealer = this.visibleToDealer;
    const profileData = this.convertDataSourceToDBObj(
      profileName,
      accountid,
      email,
      minMarg,
      visbletoDealer,
      ranges,
      this.costData
    );
    this.settingsService.createCostProfile(profileData).subscribe((res) => {
      this.costingProfiles.push({ Profile_name: this.newProfileName });
      this.selectedProfile = this.newProfileName;
      this.costProfileRaw = profileData;
      this.createCostProfileTable(profileData);
      this.copyProfile = false;
    }, (error: any) => {
      this.copyProfile = true;
      if(error.error.message === 'DuplicateCostingProfileMsg'){
        this.translate.get('settings.costingSetup.duplicateErrorMsg').subscribe((res: any) => {
          this.toastr.error(res, undefined, { positionClass: 'toast-top-right', closeButton: true });
        })
      }else{
        this.toastr.error(error.error.message, undefined, { positionClass: 'toast-top-right', closeButton: true })
      }
    });
  }
  cancelCreateProfile() {
    this.copyProfile = false;
    this.createProfileStarted = false;
    this.setupRange = false;
    this.newProfileName = '';
  }
  addRangeRow() {
    //R3 Max is 100000. So no more range distribution. Check R3
    const lastELement = this.rangeTableData[this.rangeTableData.length - 1];
    let secondlastElement = null;
    if (this.rangeTableData.length > 1) {
      secondlastElement = this.rangeTableData[this.rangeTableData.length - 2];
    }
    if (lastELement.max >= this.MAX_RANGE) {
      const tostMessage = `${lastELement.range} Max is ${this.MAX_RANGE}. ${this.translateMsg('soNoMoreDistribution')} ${lastELement.range}`;
      this.showBootboxAlert(tostMessage);
      this.rangeTableData[this.rangeTableData.length - 1].max = this.MAX_RANGE;
    } else if (
      secondlastElement != null &&
      lastELement.max <= lastELement.min
    ) {
      //R2 Maximum cannot be less than Minimum. Check R2
      const tostMessage = `${lastELement.range} ${this.translateMsg('maxCantBeLessthanMin')} ${lastELement.range}`;
      this.showBootboxAlert(tostMessage);
      this.rangeTableData[this.rangeTableData.length - 1].max = this.MAX_RANGE;
    } else {
      this.rangeTableData[this.rangeTableData.length - 1].last = false;
      const id = lastELement.id + 1;
      const min = +lastELement.max + 0.01;
      const tableElement = {
        id: id,
        range: `R${id}`,
        min: min,
        max: this.MAX_RANGE,
        last: true,
      };
      this.rangeTableData.push(tableElement);
      this.rangeTable.renderRows();
    }
  }
  createToast() { }

  showBootboxAlert(message: any) {
    const that = this;
    if (this.isSnackbarAlertVisible) {
      this.snackBar.dismiss();
      this.isSnackbarAlertVisible = false;
    } else {
      this.isSnackbarAlertVisible = true;
    }
    const action = 'OK';
    let snack = this.snackBar.open(message, action, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: ['light'],
    });
    snack.afterDismissed().subscribe(() => {
      this.isSnackbarAlertVisible = false;
    });

    snack.onAction().subscribe(() => {
      this.isSnackbarAlertVisible = false;
    });
  }
  resetRangeTable() {
    this.setNewCostProileName();
  }
  applyRangeTable() {
    //checking if last element is less than max then add row
    if (this.newProfileName.trim() == '') {
      this.showBootboxAlert(this.translateMsg('prfNameCantEmpty'));
      return;
    }
    if (
      this.rangeTableData[this.rangeTableData.length - 1].max < this.MAX_RANGE
    ) {
      this.addRangeRow();
      return;
    }
    const profile: any = {};
    const range: Array<any> = [];
    this.rangeTableData.forEach((item) => {
      range.push(item.max);
    });
    profile['ranges'] = range;
    const costItems = [];
    const additionalAdders = [
      'Balance of System',
      'Labor',
      'Permits',
      'Design',
      'Administration Cost',
    ];
    const type = `${this.applyLocaleVar}/Watt`;
    //adding module and inverter
    const module = this.createDefaultAdder('Module', range, type);
    costItems.push(module);
    const inverter = this.createDefaultAdder('Inverter', range, type);
    costItems.push(inverter);
    this.rackingTypes.forEach((item: any) => {
      const lineItem = this.createDefaultAdder(item.name, range, type);
      costItems.push(lineItem);
    });
    const onmcost = this.createDefaultAdder('O&M Cost', range, type);
    costItems.push(onmcost);
    const onmEsc = this.createDefaultAdder('O&M Escalator', range, 'Percent');
    costItems.push(onmEsc);
    const onmyear = this.createDefaultAdder('O&M Year', range, 'Year');
    costItems.push(onmyear);
    additionalAdders.forEach((item: string) => {
      const lineItem = this.createDefaultAdder(item, range, type);
      costItems.push(lineItem);
    });
    profile['values'] = costItems;
    profile['Profile_name'] = this.newProfileName.trim();
    profile['account_id'] = this.authService.getItem('account_id');
    profile['email_id'] = this.authService.getItem('email_id');
    profile['dealerVisibility'] = this.visibleToDealer;
    if (this.hasSaleMargin == true) {
      profile['minimumMargin'] = this.minimumMargin;
    }
    this.costProfileRaw = profile;
    this.subscriptions.add(
      this.settingsService
        .createCostProfile(this.costProfileRaw)
        .subscribe((res) => {
          this.costingProfiles.push({ Profile_name: this.newProfileName });
          this.createCostProfileTable(profile);
          this.selectedProfile = this.newProfileName;
          this.createProfileStarted = false;
          this.setupRange = false;
        }, (error: any) => {
          this.createProfileStarted = true;
          if(error.error.message === 'DuplicateCostingProfileMsg'){
            this.translate.get('settings.costingSetup.duplicateErrorMsg').subscribe((res: any) => {
              this.toastr.error(res, undefined, { positionClass: 'toast-top-right', closeButton: true });
            })
          }else{
            this.toastr.error(error.error.message, undefined, { positionClass: 'toast-top-right', closeButton: true })
          }
        })
    );
  }

  createDefaultAdder(name: any, range: Array<any>, type: string) {
    const addrer = [name, 'yes', '', type, '0'];
    range.forEach((item) => {
      addrer.push('0');
    });
    return addrer;
  }
  saveSalesTaxSetup() {
    this.subscriptions.add(
      this.settingsService
        .updateSalesTax(this.salesTaxSetup)
        .subscribe((res: any) => { })
    );
  }

  numberOnly(event: any) {
    let regex: RegExp = new RegExp(/^\d*\.?\d{0,20}$/g);

    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
    let value = event.target.value;
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = value;
    const position = event.target.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    console.log(current, next, event.key)
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }
  
  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

  formNumberLocaleFormat() {
    let newValue = JSON.parse(JSON.stringify(this.salesTaxSetup.value));
    newValue = this.utils.removeCommasCurrency(newValue);
    this.subscription.add(timer(200).subscribe(() => {
      this.salesTaxSetup.value = this.utils.numberFormat(newValue);
    }));
  }
}
