import { ChangeTypes } from '@design/utils/constants';
import { Edge } from './edge.model';
import { SystemDesignObject } from './system-design-object.abstract.model';
import { ConfigurationIds } from './ui-state.model';
import { Vertex } from './vertex.model';
import { constants } from '@design/containers/draw-area/threejs/helpers/Utility';
// import { THREE } from 'three.js'

export enum ObstacleType{
    freeForm="free-form",
    rectangle="rectangle",
    circle="circle",
    walkways="walkways"
}

interface IObstacle {
  id: string;
  name: string;
  roofId:string;
  type: ObstacleType;
  defaultHeight: number;
  height: number;
  length: number;
  width: number;
  rotation:number;
  diameter?: number;
  edgeIds?: Array<Edge['id']>;
  vertexIds: Array<Vertex['id']>;
  changeType?: ChangeTypes;
  roofAssociation?:Array<string>;
}

export class Obstacle extends SystemDesignObject implements IObstacle {
  selectConfigId?: boolean | undefined;
  id: string;
  name: string;
  configurationId = ConfigurationIds.FreeFormObstacle;
  roofId:string;
  type: ObstacleType;
  height: number;
  defaultHeight: number;
  length: number;
  width: number;
  rotation:number;
  diameter?: number;
  edgeIds?: Array<Edge['id']>;
  vertexIds: Array<Vertex['id']>;
  changeType?: ChangeTypes;
  roofAssociation?:Array<string>;
  setback: number;
  setbackVisible: boolean = false;

  // obstacleIds: Array<Obstacle['id']>;

  constructor(data: Omit<Obstacle, 'configurationId'>) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.roofId = data.roofId;
    this.type = data.type;
    this.length = data.length;
    this.width = data.width;
    this.height = data.height;
    this.defaultHeight = data.defaultHeight;
    this.diameter = data.diameter;
    this.rotation = data.rotation;
    this.edgeIds = data.edgeIds ?? [];
    this.vertexIds = data.vertexIds ?? [];
    this.changeType = data.changeType;
    this.roofAssociation = data.roofAssociation ?? [];
    this.setback = data.setback;
    this.setbackVisible = data.setbackVisible ?? false;
    // this.configurationId = data.configurationId
    
  }
}
