import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromPanelActions } from './panel.action';
import { PanelState } from './panel.state';
import { Panel } from '@design/models/panel.model';

export const adapter: EntityAdapter<Panel> = createEntityAdapter<Panel>({ });

export const PanelInitialState: PanelState = adapter.getInitialState();

export const { selectAll } = adapter.getSelectors();

export const panelReducer: ActionReducer<PanelState> = createReducer(
  PanelInitialState,
  on(fromPanelActions.AddMany, (state, data) => {
    return adapter.addMany(data.panels, state);
  }),
  on(fromPanelActions.AddOne,(state,data) =>{
    return adapter.addOne(data.panel,state);
  }),
  on(fromPanelActions.DeleteMany, (state, { panelIds }) => {
    // const safeToDeleteIds: Panel['id'][] = [];  // this logic is to check if panel is safe to delete
    // for (const id of panelIds) {
    //   const panel = state.entities[id]
    //   if(panel && panel.association.length === 0)
    //     safeToDeleteIds.push(id)
    // }
    return adapter.removeMany(panelIds, state);
  }),
  on(fromPanelActions.DeleteOne, (state, { panelId }) => {
    return adapter.removeOne(panelId, state);
  }),
  on(fromPanelActions.UpsertMany, (state, { panels }) => {
    return adapter.upsertMany(panels, state);
  }),
  on(fromPanelActions.UpsertOne, (state, data) => {
    return adapter.upsertOne(data.panel, state);
  })
);
