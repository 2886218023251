<div class="component-wrapper" [ngClass]="{ 'disable-counter': disabled }" style="position: relative;">
  <div *ngIf="title" class="text tooltip-ellipses">
    <div class="word-ellips">{{ title }}</div>
    <span class="tooltiptext tooltip-bottom">{{ title }}</span>
  </div>
  <div class="input-wrapper">
    <button mat-icon-button [disabled]="value === minValue" color="primary" (click)="handleInputChange('-')">
      <mat-icon
        [inline]="smallerVersion"
        *ngIf="!disabled"
        [ngClass]="smallerVersion ? 'smaller-icon' : 'size-16'"
        [ngStyle]="disableTiltDecrement?{'visibility': 'hidden'} : {'visibility': 'visible'}"
        class="btn-action decrement"
        >remove</mat-icon
      >
    </button>
    
    
    <ng-container *ngIf="applyWholeNumberOnly">
      <input
          #enactTextCounterInputBox
          matInput
          numbersOnly
          pattern="[0-9]*"
          type="text"
          [readonly]="disabled"
          class="input"
          [ngClass]="{'smaller-icon': smallerVersion}"
          [ngStyle]="customStylesObj ? customStylesObj: ''"
          [value]="value"
          (change)="handleValueChange($event)"
          (keyup.enter)="handleKeyPress()"
        />
    </ng-container>
    
    <ng-container *ngIf="!applyWholeNumberOnly"> 
      <input
          #enactTextCounterInputBox
          matInput
          pattern="[0-9]*"
          type="text"
          [readonly]="disabled"
          class="input"
          [ngClass]="{'smaller-icon': smallerVersion}"
          [ngStyle]="customStylesObj ? customStylesObj: ''"
          [value]="value"
          (change)="handleValueChange($event)"
          (keyup.enter)="handleKeyPress()"
          (keypress)="restrictValue($event)"

        />
    </ng-container>
    
    <button mat-icon-button [disabled]="value === maxValue" color="primary" (click)="handleInputChange('+')">
      <mat-icon
        *ngIf="!disabled && !disableIncrement"
        [inline]="smallerVersion"
        class="btn-action increment"
        [ngClass]="smallerVersion ? 'smaller-icon' : 'size-16'"
        [ngStyle]="disableIncrement?{'visibility': 'hidden'} : {'visibility': 'visible'}"
        >add</mat-icon
      >
    </button>
    
  </div>
  <div *ngIf="unit" class="text">{{ 'design.leftPanel.adtnlTool.' + unit | translate}}</div>
</div>
