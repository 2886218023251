import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { datadogRum } from '@datadog/browser-rum';
import * as LogRocket from 'logrocket';
import { H } from 'highlight.run';
import { CookieService } from 'ngx-cookie-service';
import { Subscription, timer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './helpers/auth.service';
import { LocaleService } from './helpers/locale.service';
import { Utils, isUrlAcceptingSpecialHeaders } from './helpers/utils';
import { ProfileService } from './modules/shared/profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from './modules/shared/loader/loader.service';
import { DEFAULT_INTERRUPTSOURCES, DocumentInterruptSource, EventTargetInterruptSource, Idle, InterruptSource } from '@ng-idle/core';
import { LoginDesignService } from './modules/login/external/login.design.service';
declare const pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'enact-angular';
  countriesList: any[] = [];
  showHeaderFooter = true;
  loadLoggersTimer: any;
  subscriptions = new Subscription();
  timedOut = false;
  lastPing?: Date;

  showFooter: boolean = true;
  percentage = 1;
  hideDesignLoader = true;
  initializeLoader: any;
  eventName = 'Design'
  showHeaderFlag = false;
  showFooterFlag = false;

  constructor(
    private localeService: LocaleService,
    public router: Router,
    public authService: AuthService,
    private profileService: ProfileService,
    public titleService: Title,
    public cookieService: CookieService,
    private utils: Utils,
    private idle: Idle,
    private loaderService: LoaderService,
    public translate: TranslateService,
    private loginToDesignService: LoginDesignService

  ) {
    this.getAllCountriesList();
    this.subscriptions.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.showHideHeaderFooter(this.router.url);
          if (['/design', '/design/finance'].includes(this.router.url)) {
            this.hideDesignLoader = false;
            this.percentage = 0;
            this.updateDesignLoader();
          }
          else {
            if (this.percentage > 0 && this.percentage < 100)
              this.stopLoader();
          }

          const currentUrl = val.urlAfterRedirects;

          // To show or hide header
          if (currentUrl.includes('login') ||
            currentUrl.includes('account') ||
            currentUrl.includes('web/reports')) {
            this.showHeaderFooter = false;
            this.showHeaderFlag = false;
          } else {
            this.showHeaderFooter = true;
            if(this.loginToDesignService.isLoggedInToDesign()){
              this.showHeaderFlag = false;
            } else this.showHeaderFlag = true;
            
          }

          //To show or hide footer
          if (currentUrl.includes('design')) {
            this.showFooter = false;
            this.showFooterFlag = false;
          } else {
            this.showFooter = true;
            this.showFooterFlag = true;
          }
        }
      })
    );
    this.initIdleState();
  }



  ngOnInit(): void {
    // Set locale currency symbol and Name
    this.utils.checkForRole(); // This is to handle setter for countryCurr and countryCurrName in Utils only on reload
    this.updateDesignLoader();
    this.authService.enableDesignLoader.subscribe((show: any) => {
      if (show.val) {
        this.hideDesignLoader = false;
        if (show.action == 'start') {
          if (this.percentage != 0 && this.percentage < 100) return
          this.percentage = 0;
          this.eventName = show?.fromEvent;
          this.updateDesignLoader();
          this.authService.loaderStopped = 'start'

        }
        if (show.action == 'stop') {
          this.authService.loaderStopped = 'stop'
          this.stopLoader();
        }
      }
    })

  }

  stopLoader() {
    let remainingVal = this.percentage;
    for (let i = remainingVal; i <= 100; i++) {
      this.subscriptions.add(timer(300).subscribe(() => {
        this.percentage = i;
      }))
      if (this.percentage >= 100) {
        this.hideDesignLoader = true;
        clearInterval(this.initializeLoader);
      }
    }
  }

  ngAfterViewInit(): void {
    // Logging and monitoring
    this.initializeLogRocket();
    this.authService.setUserLoggedIn(true);
  }

  initializeLogRocket() {
    const isProduction = true; //environment.production;
    const that = this;
    this.loadLoggersTimer = setInterval(() => {
      if (
        this.authService.getItem('email_id') &&
        this.authService.getItem('account_id')
      ) {
        clearInterval(that.loadLoggersTimer);
        if (isProduction) {
          LogRocket.init('mmvmoe/supernova-eawfo');
          LogRocket.identify(this.authService.getItem('account_id'), {
            email: this.authService.getItem('email_id'),
          });
        }
        if (environment.highlightSession) {
          H.init('3ejyz9ep', {
            environment: 'production',
            version: 'commit:abcdefg12345',
            networkRecording: {
              enabled: true,
              recordHeadersAndBody: true,
              urlBlocklist: [
                "https://www.googleapis.com/identitytoolkit",
                "https://securetoken.googleapis.com",
              ],
            },
            enableCanvasRecording: true,        // enable canvas recording
            samplingStrategy: {
              canvas: 2,                        // snapshot at 2 fps
              canvasMaxSnapshotDimension: 720,  // snapshot at a max 720p resolution
            },
          });

          H.identify(this.authService.getItem('email_id'), {
            account: this.authService.getItem('account_id')
          });
        }

        if (environment.datadog.shouldInit) {
          datadogRum.init({
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'us5.datadoghq.com',
            env: environment.datadog.env,
            applicationId: environment.datadog.applicationId,
            clientToken: environment.datadog.clientToken,
            service: environment.datadog.service,
            // Specify a version number to identify the deployed version of your application in Datadog
            version: environment.datadog.version,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
            // Do not send tracing headers to other hosts, we will get cors errors.
            allowedTracingUrls: [isUrlAcceptingSpecialHeaders]
          });

          datadogRum.setUser({
            name: '' + this.authService.getItem('fn') + ' ' + this.authService.getItem('ln'),
            accountId: this.authService.getItem('account_id'),
            userType: this.authService.getItem('user_type'),
            email: this.authService.getItem('email_id'),
            createTime: this.authService.getItem('createTime'),
            localeId: this.authService.getItem('localeId'),
          });
        }

        pendo.initialize({
          visitor: {
            id: this.authService.getItem('email_id'),
            width: window.screen.width,
            height: window.screen.height,
          },
          account: {
            id: this.authService.getItem('account_id'),
          },
        });
      } else {
        //console.log('account and email not found in cookies');
      }
    }, 200);
  }

  showHideHeaderFooter(url: string): void {
    if (url === '/login') {
      this.showHeaderFooter = false;
    } else {
      this.showHeaderFooter = true;
    }
  }
  getAllCountriesList(): void {
    this.subscriptions.add(
      this.profileService.getListOfCountries().subscribe((resp: any) => {
        this.countriesList = [];
        this.countriesList = resp;
        this.onCountriesFetched();
      })
    );
  }

  onCountriesFetched(): void {
    let localeId = 'en_US';
    let langStored = this.authService.getItem('localeLangId')
    if (langStored)
      localeId = langStored;
    const selectedCountry = this.countriesList.find(
      (country: any) => country.country_locale === localeId
    );
    this.localeService.registerLanguage(localeId); // setting language code to en-US for now
    this.localeService.registerCulture(localeId);

  }
  @HostListener('window:message', ['$event']) changeTitle(
    $event: MessageEvent
  ): any {
    if ($event.data.name === 'changeTitle') {
      this.changeTitleOfPage($event.data.data);
    }
  }
  changeTitleOfPage(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  updateDesignLoader() {
    this.initializeLoader = setInterval(() => {
      if (this.percentage <= 90) {
        this.percentage++;
      } if (this.percentage == 100) {
        this.hideDesignLoader = true;
        clearInterval(this.initializeLoader);
        this.initializeLoader = null;
      }
    }, 200);
  }


  initIdleState() {
    // sets an idle timeout of 5 minute, for testing purposes.
    this.idle.setIdle(environment.idleTimeout);

    this.idle.setTimeout(300);

    let interrupt_sources:[any] = [new EventTargetInterruptSource(document, 'mousemove')]
    let def_interrupts = DEFAULT_INTERRUPTSOURCES
    
    for(let i = 0; i < def_interrupts.length; i++){
      interrupt_sources.push(def_interrupts[i])
    }
    this.idle.setInterrupts(interrupt_sources);

    this.subscriptions.add(this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    }));

    this.subscriptions.add(this.idle.onTimeout.subscribe(() => {
      this.authService.idleState = 'Timed out!';
      this.timedOut = true;
      console.log(this.authService.idleState);
      this.logout();
    }));

    this.subscriptions.add(this.idle.onIdleStart.subscribe(() => {
      this.authService.idleState = 'You\'ve gone idle!'
      console.log(this.authService.idleState);
    }));

    this.subscriptions.add(this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.authService.idleState = 'You will time out in ' + countdown + ' seconds!'
      console.log(this.authService.idleState);
    }));

    this.subscriptions.add(this.authService.getUserLoggedIn().subscribe((userLoggedIn: any) => {
      if (userLoggedIn) {
        this.idle.watch()
        this.timedOut = false;
      } else {
        this.idle.stop();
      }
    }))

  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  logout() {
    this.authService.setUserLoggedIn(false);
    this.authService.logout()
    // alert('logged out');
  }

  ngOnDestroy(): void {
    this.loadLoggersTimer = null;
    this.subscriptions.unsubscribe();
  }

}
