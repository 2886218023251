import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, of } from "rxjs";
import { Box3, DoubleSide, Group, InstancedMesh, LinearFilter, Mesh, NearestFilter, Object3D, OrthographicCamera, Sprite, SpriteMaterial, SRGBColorSpace, Texture, Vector3, WebGLRenderTarget } from "three";
import { EngineService } from "../threejs.service";
import Util, { meshTypeEnum, renderOrders, TreeObjects } from "../helpers/Utility";
import { Shader } from "../helpers/shader";
import { fromSimulationActions } from "@design/store/simulation";
import { Store } from "@ngrx/store";
import { DesignState } from "@design/store/design.reducer";
import { PanelGeometry } from "../helpers/panelGeometry";
import { switchMap, take } from "rxjs/operators";
import { fromPanelArraySelectors } from "@design/store/panel-array/panel-array.selector";
import { SubscriptionManager } from "./subscription-manager.service";
import { fromShadingSelectors } from "@design/store/shading";
import { fromMetaDataSelectors } from "@design/store/meta-data";
import { fromAdditionalToolsActions } from "@design/store/additional-tools";
import { SaveStatus } from "@design/models/save-status.model";
import { fromVertexSelectors } from "@design/store/vertex";

@Injectable({ providedIn: 'root' })
export class ImageCapturer {
    panelNumbers: Sprite[] = [];
    public isSubscribe$: Subject<boolean> = new Subject<boolean>;
    subscription!: Subscription;
    allCapturedImages$ = new BehaviorSubject<any[]>([]);
    updateSelectedIndexTab$ = new BehaviorSubject<number>(1);
    public orthoCameraIsometric!: OrthographicCamera;

    private isometricWidth = 1000;
    private isometricHeight = 1000;
    public isometricTarget!: WebGLRenderTarget;
    public allVertices: any[]=[];

    setAllCapturedImages(value: any) {
        const currentImages = this.allCapturedImages$.getValue();
        const existingIndex = currentImages.findIndex(image => image.imageType === value.imageType);
        if (existingIndex !== -1) {
            currentImages[existingIndex] = value;
        } else {
            currentImages.push(value);
        }
        this.allCapturedImages$.next([...currentImages]);
    }

    getAllCapturedImages(): Observable<any>{
        return this.allCapturedImages$.asObservable();
    }

    constructor(private engServ: EngineService,
        private shader: Shader,
        private store: Store<DesignState>,
        private panelGeometry: PanelGeometry,
        private subscriptionManager: SubscriptionManager,
    ) {
            this.subscriptionManager.getSubscription().subscribe((subscribe: any)=>{
                if(subscribe){
                    this.subscription = new Subscription();
                }else{
                    this.subscription.unsubscribe();
                }
            })
       
        }

    init() {
        this.setupRenderTarget();
        this.setupOrtho();
        this.subscription.add(this.getAllVerticesSubscription());
    }
    
    callImageCapture() {
        this.initProposalImageCapturing();
        this.initBackgroundImageCapture();
    }
    
    initProposalImageCapturing(){
        this.store.select(fromMetaDataSelectors.getSelectedMap).pipe(take(1)).subscribe(selectedMap=>{
            if(selectedMap !== 2) {
                this.store.dispatch(fromAdditionalToolsActions.setHouseViewImageAPIStatus({ houseViewImageAPIStatus: SaveStatus.INPROGRESS }));
                this.captureAllImages();
            }
        })
    }

    initBackgroundImageCapture() {
        this.captureImages('top');
    }

    captureImages(view: string, withShade: Boolean = false) {
        let image = null;
        let group = new Group();
        let group2 = new Group();
        let haveObjects = false;
        let initialShadeCondition = this.shader.isRadianceVisible;
        if (withShade && !initialShadeCondition) {
            this.shader.toggleRadiance(true);
        } else if (!withShade && initialShadeCondition) {
            this.shader.toggleRadiance(false);
        }
        for (let child of this.engServ.scene.children) {
            if (child && (child.name.includes('roof') || child.name.includes('Tree'))) {
                haveObjects = true
                group.add(child.clone())
            } else if (child && (child.name.includes(meshTypeEnum.MapPlane))) {
                group2.add(child.clone())
            }
        }
        var center = new Vector3,
            size = new Vector3,
            box = haveObjects ? new Box3().setFromObject(group) : new Box3().setFromObject(group2);
        box.getCenter(center);
        box.getSize(size);
        let maxsize = Math.max(size.x, size.y, size.z);
        let visibleVerticesAndSetbacks = this.hideAllVertexesAndSetbacks();
        let hypotneus = Math.sqrt(Math.pow(size.x, 2) + Math.pow(size.y, 2) + Math.pow(size.z, 2));
        let positions:any = {
            top: new Vector3(0, 0, maxsize),
            northEast: new Vector3(maxsize, maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            southWest: new Vector3(-maxsize, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            westNorth: new Vector3(-maxsize, maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            eastSouth: new Vector3(maxsize, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            isometric: new Vector3(0, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(70))),
        }

        this.orthoCameraIsometric.position.copy(center).add(positions[view]);
        this.orthoCameraIsometric.lookAt(center);
        if (view == "top") {
            this.orthoCameraIsometric.rotateZ(Math.PI / 2)
        } else {
            this.orthoCameraIsometric.rotateZ(Math.PI)
        }
        this.orthoCameraIsometric.zoom = Math.min(this.isometricTarget.height / hypotneus, this.isometricTarget.width / hypotneus);
        this.orthoCameraIsometric.updateProjectionMatrix();
        this.orthoCameraIsometric.updateMatrixWorld();
        if (withShade) {
            this.addNumberSpriteToPanels(hypotneus);
        }
        this.engServ.renderer.setRenderTarget(this.isometricTarget);
        this.engServ.renderer.render(this.engServ.scene, this.orthoCameraIsometric);
        let actualBuffer = new Uint8ClampedArray(this.isometricTarget.width * this.isometricTarget.height * 4);

        this.engServ.renderer.readRenderTargetPixels(this.isometricTarget, 0, 0, this.isometricTarget.width, this.isometricTarget.height, actualBuffer);
        actualBuffer = this.mirrorImage(actualBuffer, this.isometricTarget.height, this.isometricTarget.width)

        if (withShade) {
            this.removeSpriteFromPanels();
        }
        let imgData = new ImageData(actualBuffer, this.isometricTarget.width, this.isometricTarget.height);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = imgData.width;
            canvas.height = imgData.height;
            if (ctx) {
                ctx.putImageData(imgData, 0, 0);
                const base64Image = canvas.toDataURL();
                this.store.dispatch(fromSimulationActions.SaveSiteImage({ imageData: base64Image }))
            }else{
                console.error('>>>ctx is null');
            }
        this.engServ.renderer.setRenderTarget(null);
        visibleVerticesAndSetbacks.forEach(v => {
            v.visible = true;
        })
        if (withShade && !initialShadeCondition) {
            this.shader.toggleRadiance(false);
        } else if (!withShade && initialShadeCondition) {
            this.shader.toggleRadiance(true);
        }
    }

    captureAllImages() {
        let images: any = {};
        let group = new Group();
        let group2 = new Group();
        let haveObjects = false;
        let initialShadeCondition = this.shader.isRadianceVisible;
        this.hideHighlightedPanel(true);
        for (let child of this.engServ.scene.children) {
            if (child && (child.name.includes('roof') || child.name.includes('Tree'))) {
                haveObjects = true
                group.add(child.clone())
            } else if (child && (child.name.includes(meshTypeEnum.MapPlane))) {
                group2.add(child.clone())
            }
        }
        var center = new Vector3,
            size = new Vector3,
            box = haveObjects ? new Box3().setFromObject(group) : new Box3().setFromObject(group2);
        box.getCenter(center);
        box.getSize(size);
        let maxsize = Math.max(size.x, size.y, size.z);

        let visibleVerticesAndSetbacks = this.hideAllVertexesAndSetbacks();
        let hypotneus = Math.sqrt(Math.pow(size.x, 2) + Math.pow(size.y, 2) + Math.pow(size.z, 2));
        let positions: any = {
            topShade: new Vector3(0, 0, maxsize),
            top: new Vector3(0, 0, maxsize),
            northEast: new Vector3(maxsize, maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            southWest: new Vector3(-maxsize, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            northWest: new Vector3(-maxsize, maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            southEast: new Vector3(maxsize, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(40))),
            isometric: new Vector3(0, -maxsize, maxsize * Math.tan(Util.convertDegreeToRadian(70))),
        }

        Object.keys(positions).forEach((view: any) => {
            if (view == 'topShade' && this.shader.isRadianceVisible == false) { this.shader.toggleRadiance(true) }
            if (view != 'topShade' && this.shader.isRadianceVisible) this.shader.toggleRadiance(false);
            this.orthoCameraIsometric.position.copy(center).add(positions[view]);
            this.orthoCameraIsometric.lookAt(center);
            if (view == "topShade") {
                this.addNumberSpriteToPanels(hypotneus);
            }
            if (view == "top" || view == "topShade") {
                this.orthoCameraIsometric.rotateZ(Math.PI / 2)
            } else {
                this.orthoCameraIsometric.rotateZ(Math.PI)
            }
            this.orthoCameraIsometric.zoom = Math.min(this.isometricTarget.height / hypotneus, this.isometricTarget.width / hypotneus);
            this.orthoCameraIsometric.updateProjectionMatrix();
            this.orthoCameraIsometric.updateMatrixWorld();
            this.engServ.renderer.setRenderTarget(this.isometricTarget);
            this.engServ.renderer.render(this.engServ.scene, this.orthoCameraIsometric);
            let actualBuffer = new Uint8ClampedArray(this.isometricTarget.width * this.isometricTarget.height * 4);
            this.engServ.renderer.readRenderTargetPixels(this.isometricTarget, 0, 0, this.isometricTarget.width, this.isometricTarget.height, actualBuffer);
            actualBuffer = this.mirrorImage(actualBuffer, this.isometricTarget.height, this.isometricTarget.width);
            if (view == "topShade") {
                this.removeSpriteFromPanels();
            }
            let imgData = new ImageData(actualBuffer, this.isometricTarget.width, this.isometricTarget.height);
            this.saveImages({ imgData, view });

            this.engServ.renderer.setRenderTarget(null);
        });
        if (initialShadeCondition != this.shader.isRadianceVisible) {
            this.shader.toggleRadiance(initialShadeCondition);
        }
        visibleVerticesAndSetbacks.forEach(v => {
            v.visible = true;
        });
         this.hideHighlightedPanel(false)
        // return of(images);
    }

    private hideAllVertexesAndSetbacks() {
        let visibleVerticesAndSetbacks: Object3D[] = [];
        // this.store.select(fromVertexSelectors.allVertices).pipe(take(1)).subscribe((allStoreVerts: any[]) => {
        this.allVertices.forEach((v, i) => {
            const vertexMesh = this.engServ.scene.getObjectByName(v.id) as Mesh;
            if (!vertexMesh) return;
            if (vertexMesh.visible) {
                visibleVerticesAndSetbacks.push(vertexMesh)
                vertexMesh.visible = false
            }
        })
        // });
        this.engServ.scene.getObjectsByProperty('name', TreeObjects.TREEVERTEX).forEach(v => {
            if (v.visible) {
                visibleVerticesAndSetbacks.push(v)
                v.visible = false;
            }
        })

        let obsSetbacks = this.engServ.scene?.getObjectsByProperty('name', meshTypeEnum.obstacleSetbackMesh);
        let roofSetbacks = this.engServ.scene?.getObjectsByProperty('name', meshTypeEnum.roofSetbackMesh);
        [...obsSetbacks, ...roofSetbacks].forEach(setback => {
            if (setback?.visible) {
                visibleVerticesAndSetbacks.push(setback);
                setback.visible = false;
            }
        })


        return visibleVerticesAndSetbacks;

    }

    private mirrorImage(buffer: Uint8ClampedArray, columns: number, rows: number) {
        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < Math.floor(columns / 2); j++) {
                let temp = [buffer[(i * columns + j) * 4], buffer[(i * columns + j) * 4 + 1], buffer[(i * columns + j) * 4 + 2], buffer[(i * columns + j) * 4 + 3]]
                buffer[(i * columns + j) * 4] = buffer[(i * columns + columns - j) * 4];
                buffer[(i * columns + j) * 4 + 1] = buffer[(i * columns + columns - j) * 4 + 1];
                buffer[(i * columns + j) * 4 + 2] = buffer[(i * columns + columns - j) * 4 + 2];
                buffer[(i * columns + j) * 4 + 3] = buffer[(i * columns + columns - j) * 4 + 3];
                buffer[(i * columns + columns - j) * 4] = temp[0];
                buffer[(i * columns + columns - j) * 4 + 1] = temp[1]
                buffer[(i * columns + columns - j) * 4 + 2] = temp[2]
                buffer[(i * columns + columns - j) * 4 + 3] = temp[3]
            }
        }
        return buffer;
    }

    private removeSpriteFromPanels() {
        this.panelNumbers.forEach(pN => {
            this.engServ.scene.remove(pN)
        })
        this.panelNumbers = []
    }

    private addNumberSpriteToPanels(length: number) {
        this.store.select(fromPanelArraySelectors.allPanelArrays)
            .pipe(take(1))
            .subscribe(storePanelArrays => {
                let number = 1;
                if (storePanelArrays) {
                    this.panelNumbers = [];
                    storePanelArrays.forEach(storePanelArray => {
                        let panelArr = this.engServ.scene.getObjectByName(storePanelArray.id);
                        let instanceMesh = panelArr?.children[0] as InstancedMesh;
                        if(instanceMesh){
                          let center = Util.getCenterPoint(instanceMesh);
                          center.add(instanceMesh.position);
                          const textLabel = this.makeTextSprite(String(number), length);
                          textLabel.position.copy(center.add(new Vector3(0, 0, 50)));
                          textLabel.name = storePanelArray.id + 'number';
                          this.engServ.scene.add(textLabel);
                          this.panelNumbers.push(textLabel);
                          number++;
                        }
                    })

                }
            })
    }

    makeTextSprite(message: string, length: number) {
        var fontface = "sans-regular";
        var fontsize = 50;

        var canvas = document.createElement('canvas');
        canvas.height = 80;
        canvas.width = 80;
        var context = canvas.getContext('2d')!;
        context.font = "Bold " + fontsize + "px " + fontface;

        context.translate(canvas.width, canvas.height);
        context.scale(-1, -1);
        context.beginPath();
        context.arc(40, 40, 40, 0, 2 * Math.PI);
        context.fillStyle = "black";
        context.fill();
        context.fillStyle = 'white';
        if (message.length == 1) context.fillText(message, 25, 60);
        else context.fillText(message, 8, 60);
        var texture = new Texture(canvas)
        texture.needsUpdate = true;
        texture.minFilter = LinearFilter;
        texture.magFilter = NearestFilter;
        texture.colorSpace = SRGBColorSpace;
        var sprite = new Sprite(new SpriteMaterial({ map: texture, side: DoubleSide, transparent: true }));
        sprite.renderOrder = renderOrders.sprite
        let scale = length * 0.04
        sprite.scale.set(scale, scale, 1);
        return sprite;
    }

    saveImages(data: { imgData: any, view: any }) {
        let imgData = data.imgData;
        let view = data.view;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = imgData.width;
        canvas.height = imgData.height;
        if (ctx) {
            ctx.putImageData(imgData, 0, 0);
            const base64Image = canvas.toDataURL();
            if (view.includes('north') || view.includes('south')) {
                this.store.dispatch(fromSimulationActions.Save3DIsometricImage({ imageData: base64Image, imageType: view + 'Image' }));
 
            }
            this.setAllCapturedImages({ backgroundImage: base64Image, imageType: view })
        } else {
            console.error('>>>ctx is null');
        }
    }

    hideHighlightedPanel(hide: Boolean) {
        this.store.select(fromShadingSelectors.selectShadingState).pipe(
            take(1),
            switchMap(isEnabled => {
                if (isEnabled) {
                    return forkJoin({
                        threshold: this.store.select(fromShadingSelectors.selectSolarThreshold).pipe(take(1)),
                        yearlyData: this.store.select(fromShadingSelectors.selectPanelsYearlyData).pipe(take(1))
                    });
                } else {
                    return of(null);
                }
            })
        ).subscribe(result => {
            if (result) {
                const { threshold, yearlyData } = result;
                const highlightedPanels = yearlyData
                    .filter(data => data.solarData < threshold)
                    .map(data => data.panelId);
    
                hide ? this.panelGeometry.restorePanels(highlightedPanels, true) : this.panelGeometry.highlightPanels(highlightedPanels);
            }
        });
    }

    private setupOrtho() {
        if (!this.orthoCameraIsometric) {
            this.orthoCameraIsometric = new OrthographicCamera(-this.isometricWidth / 2, this.isometricWidth / 2, this.isometricHeight / 2, -this.isometricHeight / 2, 0, 50000);
            this.engServ.scene.add(this.orthoCameraIsometric);
            // const helper = new CameraHelper(this.orthoCamera);
            // this.orthoCamera.add(helper);
            this.orthoCameraIsometric.position.set(0, 0, 10);
        }
    }

    private setupRenderTarget() {
        
        if (this.isometricTarget) this.isometricTarget.dispose();
        // const format = parseFloat(String(DepthFormat));
        // const type = parseFloat(String(UnsignedShortType));
        let pixelSize = 2;
        let rows = Math.floor(this.shader.height / pixelSize);
        let columns = Math.floor(this.shader.width / pixelSize);
        this.isometricTarget = new WebGLRenderTarget(this.isometricWidth, this.isometricHeight);
        this.isometricTarget.texture.colorSpace = SRGBColorSpace;
        this.isometricTarget.texture.minFilter = NearestFilter;
        this.isometricTarget.texture.magFilter = NearestFilter;
        this.isometricTarget.stencilBuffer = false;
    }

    private getAllVerticesSubscription(): Subscription{
        return this.store.select(fromVertexSelectors.allVertices).subscribe((allStoreVerts: any[]) => {
            this.allVertices = allStoreVerts;
        });
    }

}