import {Injectable, inject} from '@angular/core';
import { FeatureFlags, UserContext } from '@api/models/feature-flag.model';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from 'src/app/helpers/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class FeatureFlagService {
  private launchDarklyClient?: LDClient.LDClient;
  private multiContext!: LDClient.LDContext;
  private allFlags?: FeatureFlags;
  private isFeatureFlagsReady = new BehaviorSubject<boolean>(false);

  public isFeatureFlagsReady$ = this.isFeatureFlagsReady.asObservable();
  private authService = inject(AuthService);

  async getFlagsFromServer() {
    const userState = {
      locale: this.authService.getItem('localeId'),
      account: this.authService.getItem('account_id'),
      userType: this.authService.getItem('user_type') ?? '',
      email: this.authService.getItem('email_id'),
      country: this.authService.getItem('country') ?? '',
      state: this.authService.getItem('state'),
      crn: this.authService.getItem('crn'),
      qrn: this.authService.getItem('crn'),
    }
    this.setMultiContext(userState);
    await this.initializeLaunchDarkly();
    this.getAllFeatureFlags();
  }

  private setMultiContext(user : UserContext) {
    this.multiContext= {
      kind: 'multi',
      Crn: {
        kind: 'Crn',
        key: 'installer-context',
        name: user?.crn?.toLowerCase(),
      },
      Qrn: {
        kind: 'Qrn',
        key: 'installer-context',
        name: user?.qrn?.toLowerCase(),
      },
      Account: {
        kind: 'Account',
        key: 'installer-context',
        name: user?.account?.toLowerCase(),
      },
      State: {
        kind: 'State',
        key: 'installer-context',
        name: user?.state?.toLowerCase(),
      },
      Country: {
        kind: 'Country',
        key: 'installer-context',
        name: user?.country?.toLowerCase(),
      },
      Locale: {
        kind: 'Locale',
        key: 'installer-context',
        name: user?.locale?.toLowerCase(),
      },
 
    };
    return this.multiContext;
  }

  private initializeLaunchDarkly() {
    if (this.launchDarklyClient) {
      // / if launchDarklyClient is already initialized. And if the context is changed then we need use identify instead of initialize
      return this.launchDarklyClient.identify(this.multiContext);
    }

    this.launchDarklyClient = LDClient.initialize(environment.launchDarklyClientKey, this.multiContext);
    return this.launchDarklyClient.waitUntilReady();
  }

  private getAllFeatureFlags() {
    if (this.launchDarklyClient) {
      this.allFlags = this.launchDarklyClient.allFlags() as FeatureFlags;
      this.isFeatureFlagsReady.next(true);
    }
  }

  getFlag<T extends keyof FeatureFlags>(flagName: T):FeatureFlags[T] {
    return this.allFlags?.[flagName] as FeatureFlags[T];
  }

  getAllFlags() {
    return this.allFlags;
  }
}
