<form [formGroup]="dateForm">
  <div class="date-container">
    <div>
      <span class="col-form-label date-style">{{'design.toolBar.simulate.dayWith' | translate }}
        <p-dropdown [options]="days" (onChange)="onOptionChange($event)" optionLabel="label" formControlName="dayWith"
          placeholder="{{'selectDay' | translate }}">
          <ng-template let-day pTemplate="item">
            <div class="day-item date-style">
              <div class="date-style" customToolTip [text]="day.value?.help_text" [positions]="tooltipPosition">
                {{day.label}}
                <label *ngIf="day.label !== 'Custom'" style="float: right;color: #A9ABAE;">{{day.value?.value}}</label>
              </div>
            </div>
          </ng-template>
        </p-dropdown>
      </span>
    </div>
    <div>
      <span class="col-form-label date-style">{{'design.toolBar.simulate.date' | translate }}</span>
      <span class="date-style" [ngClass]="{'left-aligned': isDisabled, 'left-align-0': !isDisabled}">
        <ng-container *ngIf="isDisabled">
          <span class="not-applicable">N/A</span>
        </ng-container>
        <p-calendar #calendar (onSelect)="onDateChange($event)" formControlName="date" dateFormat="d M"
          [readonlyInput]="true" appendTo="body" [showIcon]="true" dataType="localdate" [disabled]="isDisabled"
          [minDate]="minDate" [maxDate]="maxDate">
        </p-calendar>
      </span>
    </div>
  </div>
</form>