import { ConfigurationIds } from './ui-state.model';

export enum SystemDesignObjectType {
  RoofSection = 'roof-section',
  SphericalTree = 'spherical-tree',
  CuboidalObstacle = 'cuboidal-obstacle',
  RectangleObstacle = 'rectangle-obstacle',
  PanelArray = 'panel-array',
  Inverter = 'inverter',
  Panel = 'panel',
}

export abstract class SystemDesignObject {
  abstract id: string;
  abstract configurationId?: ConfigurationIds;
  abstract selectConfigId?: boolean;
}
