import { Injectable } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {Utils} from '../../../helpers/utils';


@Injectable({
  providedIn: 'root'
})
export class RoofService {
  static httpClientlocal:any;
  constructor(private cookieService: CookieService, private http: HttpClient,private utils:Utils) { 
    RoofService.httpClientlocal = http;
  }

   public static getPVWattsPanelHourly(queryData:any){
    const apiUrl = `${Utils.getBaseurl()}pvwattsPanels/hourly`;
    return RoofService.httpClientlocal.post(apiUrl, queryData);
  } 
}
