import { Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LOCATION_INITIALIZED } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthService } from './helpers/auth.service';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function ApplicationInitializerFactory(
  translate: TranslateService, injector: Injector) {
  return async () => {
    await injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    let localeId = 'en_US';
    let langStored = localStorage.getItem('localeLangId')
    if (langStored)
      localeId = langStored;
    translate.setDefaultLang(localeId);
    try {
      await translate.use(localeId).toPromise();
    } catch (err) {
      console.log(err);
    }
  };
}
