// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    clientName: 'Enact',
    googleMapUrl: 'https://maps.googleapis.com/maps/api/js?v=3&libraries=geometry,drawing,places&key=',
    googleMapKey: 'AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc',
    nearmapKey: 'MTdhZDgwNDgtNTc2ZC00ZTk5LTkzYTUtMzA5NzJhMjZiY2M4',
    apiUrl: 'https://beta.enact-systems.com/',
    newApiKeyStaging: 'G9i6ptUeCX5E7xzOp5VZh8m2nZI2BPbI3MDzIQxv', //staging x-api-key added because of api failed issue
    //apiUrlStaging:'https://wgage3hkwa.execute-api.us-west-2.amazonaws.com', //staging url added because of api failed issue
    //apiUrlStaging2:'https://wgage3hkwa.execute-api.us-west-2.amazonaws.com', //staging url added because of api failed issue
    newApiUrl: 'https://beta-api.enact-systems.com/',
    newApiKey: 'xyzbetabrokerkeywithoutuserpoiu',
    baseUrl: 'https://beta.enact-systems.com/',
    nactUiBaseUrl: 'https://betaapi.enact-systems.com/nact-client/',
    nactDocsUrl: 'https://beta-docs.enact-systems.com/',
    useMock: true,
    commaDecimalSeparatorLocales: ['af_ZA', 'de_DE', 'fr_FR', 'it_IT', 'ru_RU', 'es_ES'],
    // Refer to this to modify https://en.wikipedia.org/wiki/Decimal_separator
    oldUIUrl: 'nact-client/enactUI/partners/ajax/',
    engage_apigateway_url: 'https://testapi.enact-systems.com/api',
    engage_apigateway_key: 'iOAcSgxCTN8lIfT3v8pOV9da2morIbN34PGokcWz',
    // cognito urls todo change for server specific things
    authURL: 'https://java11-test.auth.us-west-2.amazoncognito.com/',
    cognitoClientId: '7f09bkt6u5ddblol07pkad4463',
    logoutUrl: 'https://test-auth.enact-systems.com/login',
    redirect_uri: 'https://test-auth.enact-systems.com/login',
    authApiUrl: 'https://testauthapi.enact-systems.com/auth',
    //rubiconurl
    idleTimeout: 7200,
    rubiconURL: 'j11design.enact-systems.com',
    urls: {
      cognito_base_url: 'https://java11-test.auth.us-west-2.amazoncognito.com/oauth2/authorize?',
      cognito_client_id: '7f09bkt6u5ddblol07pkad4463',
      cognito_identity_provider: 'rubicon-oauth-oidc',
    },
    idleTimeoutInMinutes: 1,
    newQuoteAPIEndpoint: 'https://beta-quote-api.enact-systems.com/beta/',
    s3baseurl : 'https://beta-design-api.enact-systems.com/',
    s3ApiKey: 'xyzbetabrokerkeywithoutuserpoiu',
    newSiteDataApiEndpoint: 'https://beta-design-api.enact-systems.com/',
    showFPSMeter: false,
    highlightSession: true,
    loginNewQuoteRedirectURL: 'https://beta.enact-systems.com/login',
    datadog: {
      shouldInit: true,
      applicationId: '97e08cc3-ebb4-4acb-bb01-1e81b096464c',
      clientToken: 'pubf6e380f9e4e69533e22dc4cc368501eb',
      service: 'nact-ui-supernova-beta',
      version: 'v-20240703',
      env: 'beta',
    },
    webhookApi: 'https://hdwbx0lbvl.execute-api.us-west-2.amazonaws.com/dev/',
    launchDarklyClientKey: '6656cd40964d1a0fcae3c165',
  };
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
