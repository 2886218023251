import { Edge } from '@design/models/edge.model';
import { RoofSection } from '@design/models/roof-section.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export namespace fromEdgeActions {
  export const AddOne = createAction(
    '[Edge] add one edge',
    props<{ roof: Edge }>()
  );

  export const AddMany = createAction(
    '[Edge] add many edge section',
    props<{ edges: Edge[] }>()
  );

  export const AddManyIfNonExistent = createAction(
    '[Edge] add many edge section',
    props<{ edges: Edge[] }>()
  );

  export const EdgeConfigurationChange = createAction(
    '[Edge Configuration] Update edge configuration',
    props<{ edge: Edge }>()
  );
    
  export const UpdateMany = createAction(
    '[Edge] update many edge section',
    props<{ edges: Update<Edge>[] }>()
  );

  export const UpsertMany = createAction(
    '[Edge] upsert many edges',
    props<{ edges: Edge[] }>()
  );

  export const DeleteMany = createAction(
    '[Edge] delete many edge section',
    props<{ edgeIds: Edge['id'][] }>()
  );
}
