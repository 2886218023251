import { Obstacle } from '@design/models/obstacle.model';
import { createSelector } from '@ngrx/store';
import { selectDesign } from '../design.selector';
import { fromVertexSelectors } from '../vertex';
import { adapter } from './obstacle.reducer';
import { ObstacleState } from './obstacle.state';
import { RoofSection } from '@design/models/roof-section.model';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

type SelectedEntitiesByIDProps = {
  id: Obstacle['id'];
};

export namespace fromObstacleSelectors {
  export const obstacleStateSelector = createSelector(
    selectDesign,
    (state) => state.obstacle
  );

  export const allObstacles = createSelector(
    obstacleStateSelector,
    selectAll
  );

  export const selectObstacleById = (id: Obstacle['id']) => createSelector(
    obstacleStateSelector,
    (state: ObstacleState) => {
      const entities = selectEntities(state);
      return entities[id];
    })

  export const selectObstaclesByRoofId = (id: RoofSection['id']) => createSelector(
    obstacleStateSelector,
    (state: ObstacleState,) => {
      const entities = selectEntities(state);
      return Object.values(entities).filter((o) => o?.roofId == id);
    }
  );

  export const selectObstaclesByAssociatedRoofId = (id: RoofSection['id']) => createSelector(
    obstacleStateSelector,
    (state: ObstacleState,) => {
      const entities = selectEntities(state);
      return Object.values(entities).filter((o) => o?.roofAssociation?.includes(id));
    }
  );

  export const selectedObstacle = createSelector(selectDesign, (state) => {
    const selectedId = state.ui.selectedObjectId;
    if (!selectedId) {
      return null;
    }
    const entities = state.obstacle.entities;
    return entities[selectedId];
  });

  export const selectedEntitiesByID = createSelector(
    obstacleStateSelector,
    (entities: ObstacleState, props: SelectedEntitiesByIDProps) => {
      return entities.entities[props.id];
    }
  );

  export const safeDeleteObjects = (obstacle: Obstacle) =>
    createSelector(
      fromVertexSelectors.findSafeToDeleteVertices(obstacle.vertexIds),
      // fromVertexSelectors.findSafeToDeleteVertices(roof.vertexIds),
      (vertices) => {
        return { vertices };
      }
    );
}
