import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';
import { AuthService } from '../../helpers/auth.service';
import { LoginService } from '../login/login.service';
import { LocaleService } from 'src/app/helpers/locale.service';
import { LoginDesignService } from './external/login.design.service';
import { environment } from 'src/environments/environment';
interface loginObj {
  email_id?: string,
  account?: string,
  password?: string
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  signInForm: UntypedFormGroup = new UntypedFormGroup({});
  resetPwdForm: UntypedFormGroup = new UntypedFormGroup({});
  showLoginPanel = true;
  customerSupportNumber = '(855) 503-6228';
  showResetPwdError = false;
  passwordResetSuccess = false;
  submitted = false;
  emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+(\.\w{2,50})+$/;
  passwordPattern = '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$';
  wrongCredentials: any = null;
  accounts: string | any[] = [];
  show: boolean = false;
  inputtype: any = 'password';
  subscriptions = new Subscription();
  paramsObj?: loginObj
  tempToken?: string | any;
  refreshTempToken?: string;
  externalLoginParams:any = {};
  
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private utils: Utils,
    private route: ActivatedRoute,
    private localeService : LocaleService,
    private loginDesignService: LoginDesignService
  ) {
    this.authService.removeAllItems();
    this.authService.removeAllItems('localStorage');
    this.subscriptions.add(this.route.queryParams.subscribe((params) => {
      let prm: any = params
      if (params?.access_key){
        this.externalLoginParams = params;
      }
      else {
        this.loginToEnact(params);
        this.authService.setItem('design_only', 'dshytocm');
    }
    }))


  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loginToEnact(params: any, navigateToNexPage: boolean = true){
    let prm: any = params
    this.paramsObj = prm
  // Redirect to quote envionment :: setting token to local storage
    this.tempToken = params.token;
    if (Object.keys(prm).length) {
      // this.paramsObj!.password = 'dummy'
      let formData = {
        accountId: this.paramsObj?.account,
        emailId: this.paramsObj?.email_id,
        password: 'c?XRV&$&P6zW!+k_Q',
      }
      this.refreshTempToken = localStorage.getItem('refreshToken')!;
      this.tempToken = localStorage.getItem('token')!;
      const timerValue = Object.keys(this.externalLoginParams).length != 0 ? 1 : 100;
      this.subscriptions.add(timer(timerValue).subscribe(()=>{
        if (params?.origin) {
          localStorage.setItem('redirectURL', params.origin); 
        }
        localStorage.setItem('token', this.tempToken ? this.tempToken : params.token);
        localStorage.setItem('refreshToken', this.refreshTempToken!);
        localStorage.setItem('localeLangId', params.prefLang);
        this.localeService.registerLanguage(params.prefLang); // setting language code to en-US for now
        this.doLoginNow(formData, navigateToNexPage)
      }))
      
    }

  }

  async ngOnInit(){
    if(Object.keys(this.externalLoginParams).length != 0){
      this.authService.setUserLoggedIn(true);
     
      let valid_user = await this.loginDesignService.validateExternalLoginParams(this.externalLoginParams)
      
      
      if(valid_user == 'InvalidData'){
        document.body.innerHTML='<html>Invalid input parameters passed</html>'
      }
      else if(valid_user == 'Unauthorized'){
          document.body.innerHTML='<html>Unauthorized</html>'
      } else if('email_id' in valid_user){
        let params = this.createLoginParams(valid_user)
        this.authService.removeAllItems('localStorage');
        this.loginToEnact(params, false);
        this.authService.setItem('design_only', 'qkshodgh');
        this.authService.setItem('backToCrmUrl', this.externalLoginParams['redirect_url'])
        this.loginDesignService.loginToDesign(this.externalLoginParams)
      }
    }else if (this.authService.isLoggedIn()) {
      this.navigateToNextPage();
      return;
    }
    this.initSignInForm();
    this.initResetPwdForm();
  }
  initSignInForm(): void {
    this.signInForm = this.fb.group({
      accountId: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ['', [Validators.required]],
    });
  }
  initResetPwdForm(): void {
    this.resetPwdForm = this.fb.group({
      accountId: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
    });
  }
  resetSignInForm(): void {
    this.signInForm.reset();
  }
  resetResetPwdForm(): void {
    this.resetPwdForm.reset();
  }
  get f(): any { return this.signInForm.controls; }
  get fs(): any { return this.resetPwdForm.controls; }

  onSubmit(): void {
    this.showLoginPanel = true;
    this.submitted = true;
    const formData = this.signInForm.value;
    if (this.signInForm.invalid) {
      // this.wrongCredentials = 'Please enter valid values';
      return;
    }
    console.warn(formData)
    this.doLoginNow(formData)

  }

  doLoginNow(formData: any, navigateToNexPage: boolean = true) {
    this.subscriptions.add(this.loginService.authenticateUser(formData).subscribe(response => {
      if (response.message) {
        this.wrongCredentials = response.message;
        return;
      } else {
        this.localeService.setCountryLocale(response?.session?.localeNumberFormate);
        this.authService.setAllRequiredInfo(response);
        this.authService.setItem('sessionId', response.session.sessionId);
        this.authService.setItem('username', response.session.fn + " " + response.session.ln);
        this.authService.setBaseAndDocUrl();
        this.getAccessDetails(response, navigateToNexPage);
        this.utils.countryCurr = this.utils.checkForRole()
        localStorage.setItem('countryCurr', this.utils.checkForRole())
        localStorage.setItem('createtime', response.session.createTime);
        //startUserLoggedIn for idle 
        this.authService.setUserLoggedIn(true);
      }
    }, error => {
      this.wrongCredentials = error.error.message;
    }));
  }
  getAccessDetails(res: any, navigateToNexPage: boolean): void {
    const reqBody = { session_id: res.session.sessionId };
    this.subscriptions.add(this.loginService.getAccessDetails(reqBody).subscribe(response => {
      if (response) {
        this.authService.setItem('accessObj', response, 'localStorage');
        if(navigateToNexPage) this.navigateToNextPage();
      }
    }, error => {
      this.wrongCredentials = error.error.message;
    }));
  }


  navigateToNextPage() {
    this.router.navigateByUrl('projects');
  }

  onResetPwd(): void {
    this.submitted = true;
    const formData = this.resetPwdForm.value;
    if (this.resetPwdForm.invalid) {
      // this.wrongCredentials = 'Please enter valid values';
      return;
    }
    this.subscriptions.add(this.loginService.resetPassword(formData)
      .subscribe(
        response => {
          if (response?.message == "password_creation_failed") {
            this.showResetPwdError = true;
          } else {
            this.showResetPwdError = false;
            this.passwordResetSuccess = true;
          }
        }, error => {
          this.showResetPwdError = true;
        }));
  }

  toggleEyeIcon() {
    this.show = !this.show;
    if (this.show)
      this.inputtype = "text";
    else
      this.inputtype = "password";
  }

  createLoginParams(params: any){
    let origin = `${environment.baseUrl}`
    let token = 'abc'
    let prefLang='en_US'
    let email =  params.email_id
    let account = params.account_id
    let params1 = {"origin": origin, "prefLang": prefLang, "token": token, "email_id": email, "account": account};
    return params1;
}
}
