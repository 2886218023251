
import { Obstacle } from '@design/models/obstacle.model';
import { createAction, props } from '@ngrx/store';

export namespace fromObstacleActions {

  export const DrawObstacleComplete = createAction('[RoofSection] initialize data');

  export const AddObstacle = createAction(
    '[Draw Area] add one obstacle',
    props<{ obstacle: Obstacle}>()
  );

  export const AddManyObstacles = createAction(
    '[Draw Area] add many obstacles',
    props<{ obstacles: Obstacle[] }>()
  );

  export const SelectObstacle = createAction(
    '[Draw Area] select obstacle',
    props<{ obstacle: Obstacle | undefined }>()
  );

  export const ObstacleBoundChange = createAction(
    '[Draw Area] Update obstacle bounds',
    props<{ obstacle: Obstacle }>()
  );

  export const ObstacleConfigurationChange = createAction(
    '[Obstacle Configuration] Change obstacle configuration',
    props<{ obstacle: Obstacle}>()
  );

  //Updating an obstacle from scene, call this function
  export const UpdateObstacle = createAction(
    '[Draw Area] update one obstacle',
    props<{ obstacle: Obstacle, triggerURSnapshot?:boolean }>()
  );

  export const UpdateObstacleSetbackVisibility = createAction(
    '[Obstacle Configuration] update obstacle setback visibility',
    props<{ obstacle: Obstacle }>()
  );

  export const DeleteObstacle = createAction(
    '[Obstacle Configuration] Delete an obstacle',
    props<{ obstacle: Obstacle}>()
  );

  export const DeleteObstacleFromStore = createAction(
    '[Obstacle Configuration] Delete an obstacle from store',
    props<{ obstacle: Obstacle}>()
  );

  export const DeleteObstacleSuccess = createAction(
    '[Obstacle Configuration] Delete an obstacle success',
    props<{ obstacle: Obstacle }>()
  );

  export const CopyObstacle = createAction(
    '[Obstacle Configuration] Copy an obstacle',
    props<{ obstacle: Obstacle }>()
  );
  export const SaveObstacle = createAction(
    '[Save obstacle] Save Obstacle data to quote api',
  );
}
