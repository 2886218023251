import { Action, createAction, props } from '@ngrx/store';

export namespace fromUndoRedoActions {
  export const SceneLoadedFirstTime = createAction(
    '[UNDO REDO] Scene Loaded First Time'
  );

  export const SnapshotCreated = createAction('[UNDO REDO] snapshot created');

  export const UpdateHardwareDetailsApi = createAction(
    '[UNDO REDO] update hardware details on undo or redo'
  );

  export const UpdateHardwareDetailsApiSuccess = createAction(
    '[UNDO REDO] update hardware details on undo or redo success'
  );

  export const UpdateHardwareDetailsApiFailure = createAction(
    '[UNDO REDO] update hardware details on undo or redo failure',
    props<{ error: any }>()
  );

  export const UpdateInverterDetails = createAction(
    '[UNDO REDO] Create snapshot on inverter details updated'
  );

  export const UpdateShadingThreshold = createAction(
    '[UNDO REDO] Create snapshot on update shading threshold'
  );

  export const Shading = createAction(
    '[UNDO REDO] shading click'
  );
  export const ClearAllStacks = createAction('[UNDO REDO] Clear All Snapshot');

  export const TriggerURSnapshot = createAction('[UNDO REDO] Trigger Snapshot', props<{action: Action, notification: string, isFreeformActive?: boolean }>());
  export const SuccessURAction = createAction('[UNDO REDO] snapshot creation successful');
  export const SuccessURSnapshotNotCreated = createAction('[UNDO REDO] snapshot not created');

  export const AddRoofVertex = createAction('[UNDO REDO] add roof vertex');
  export const AddFreformVertex = createAction('[UNDO REDO] add freeform vertex');
}
