export class NotificationMessage {
    message!: string;
    type!: string;
    positionClass!: string;
    timeOut?:number;
    disableTimeOut?:boolean;
}

export enum NotificationType {
    success = 'success',
    warning = 'warning',
    error = 'system',
    info = 'info',
    danger = 'danger',
}