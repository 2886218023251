import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as d3 from 'd3';
import { GraphImage } from 'src/app/modules/system-design/sysresults/interfaces/graph-image';
import { SystemDesignService } from 'src/app/modules/system-design/system-design.service';
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../../../../helpers/auth.service';
import * as $ from 'jquery';


@Component({
  selector: 'app-area-chart',
  templateUrl: './area-chart.component.html',
  styleUrls: ['./area-chart.component.scss'],
})

export class AreaChartComponent implements OnInit, OnDestroy {
  @Input() data: ChartDataSets[] | any;
  @Input() yLabel: any;
  @Input() header: any;
  @Input() text: any;
  @Input() meterNumber: any;
  @Input() selectedGraph: any;
  stackKeys: any = ['Consumption', 'Generation'];
  @ViewChild('chartContainer') myChartContainer: ElementRef | any;
  private saved = false;
  private w: number = screen.width - (screen.width * 10) / 100;
  private h: number = 400;
  private margin = { top: 10, right: 30, bottom: 100, left: 80 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;
  private divWidth = 0;
  private divHeight = 0;
  private x: any;
  private y: any;
  private z: any;
  private svg: any;
  private chart: any;
  private xAxis: any;
  private yAxis: any;
  numberTicksY: any = 9;
  private colors: any = [];
  xAxisLabels: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  formattedData: any = [
    { id: 'consumption', values: [] },
    { id: 'generation', values: [] },
  ];
  areasChart: any;
  area: any;
  meterData: any;
  subscriptions: Subscription = new Subscription();

  intervalMax = 50;
  timerId: any;

  constructor(
    private container: ElementRef,
    private systemDesignService: SystemDesignService,
    private renderer: Renderer2,
    private authService: AuthService,
  ) { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if(this.timerId){
      clearInterval(this.timerId);
    }
  }

  ngOnInit() {
    this.initData().then((data: any) => {
      this.meterData = data;
      data.consumption.forEach((element: any, index: any) => {
        //    this.formattedData[0].values.push({ month: 0, kWh: 0 })
        this.formattedData[0].values.push({
          month: this.xAxisLabels[index],
          kWh: element,
        });
      });
      data.generation.forEach((element: any, index: any) => {
        //      this.formattedData[1].values.push({ month: 0, kWh: 0 })
        this.formattedData[1].values.push({
          month: this.xAxisLabels[index],
          kWh: element,
        });
      });
      // this.colors = ["#428bca", "#FF9747"]
      this.colors = ['rgba(175,176,178, 0.7)', 'rgba(255,214,29, 0.65)'];

      this.initSvg();
    });
  }

  initData(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.data) {
        resolve(this.data);
      } else {

        this.timerId = setInterval(() => {
          if (this.data) {
            if (this.timerId) {
              clearInterval(this.timerId);
              resolve(this.data);
            }
          }
        }, 300);
      }
    });
  }

  private initSvg() {
    this.svg = d3
      .select(this.container.nativeElement)
      .select('.chart-container-area')
      .append('svg')
      .attr('preserveAspectRatio', 'xMinYMin meet')
      .attr('class', 'area-chart')
      .attr('width', this.w)
      .attr('height', this.h)
      .attr('viewBox', '0 0 600 400');

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );

    let data = this.formattedData;
    this.createStack(data);
  }

  private async createStack(stackData: any) {
     this.drawChart(stackData);

    // if (this.saved == false) {
    // this.saved = true;
    const imageString = this.exportSVGGraphsFromId();
    this.subscriptions.add(timer(3000).subscribe(()=>{
      console.log("call create area chart save");
      this.buildImageUrl(imageString);
    }));
    // }
  }


  exportSVGGraphsFromId() {
    // this.chart=svg

    $('.mouse-over-effects').attr('display', 'none');
    const SVGElem: any = window.document.querySelector('.area-chart');
    const svg: any = SVGElem.firstElementChild;
    const oDOM: any = svg.cloneNode(true);
    const g: any = oDOM.querySelector('g');
    var width = $('.area-chart').width();
    var height = $('.area-chart').height();
    const svgAsXML: any = new XMLSerializer().serializeToString(oDOM);
    const image64: any = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`;

    $('.mouse-over-effects').attr('display', 'block');
    return image64;
  }


  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(this.numberTicksY);
  }

  buildImageUrl(imageString?: any) {
    const that = this;
    // var canvas:any;    // canvas element
    var domUrl: any; // object url
    var image: any; // chart image
    var imageUrl: any; // chart image url
    var svg: any; // svg element

    // add svg namespace to chart
    svg = document.getElementsByClassName('area-chart')[0];
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    // create image url from svg
    domUrl = window.URL || window.webkitURL || window;
    imageUrl = domUrl.createObjectURL(
      new Blob([svg.outerHTML], { type: 'image/svg+xml' })
    );


    // create chart image
    image = new Image();
    image.onload = function () {
      // add image to canvas
      console.log("saving areachart:");
      if(!that.selectedGraph){
        console.log("saving areachart selected graph is null:",that.data);
      }
      const canvas: any = document.createElement('canvas');
      canvas.width = that.w>=100?that.w:800;
      canvas.height = that.h>=100?that.h:425;
      canvas.getContext('2d').drawImage(image, 0, 0);
      console.log("saving areachart:",canvas.toDataURL('image/png').length);
      that.callSaveImg(canvas);
      domUrl.revokeObjectURL(imageUrl);


    };
    image.src = imageUrl;

  }


  callSaveImg(canvas: any) {
    // setTimeout(() => {
    // download image
    // this.downloadImage(canvas?.toDataURL('image/png'));

    // let interVal: any;
    // interVal = setInterval(() => {
    //   if (canvas.toDataURL('image/png')?.length > 50 && this.intervalMax >= 50) {
    //     clearInterval(interVal);

    //     this.svgString2API(canvas.toDataURL('image/png'), '.png', 'ConProdArea', this.meterNumber, this.systemDesignService);
    //     console.log('Interval cleared>>')
    //   }
    //   else {
    //     this.buildImageUrl();
    //     console.log('Build image function revoked>>')

    //   }
    // }, 2000)
    // }, 500);


    if (canvas.toDataURL('image/png')?.length > 50) {
      //this.svgString2API(canvas.toDataURL('image/png'), '.png', 'ConProdArea', this.meterNumber, this.systemDesignService);
    }

  }

  downloadImage(imageUrl: any) {
    var downloadLink; // anchor dom element to trigger download
    var fileName; // donwload file name

    // determine browser, download the image
    fileName = 'ConProdArea.png';

    // create anchor dom element to image uri, raise click event
    downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = fileName;
    this.raiseEvent(downloadLink, 'click');
  }

  raiseEvent(element: any, eventType: any) {
    var eventRaised; // event object

    // determine how and create the event
    if (document.createEvent) {
      eventRaised = document.createEvent('MouseEvents');
      eventRaised.initEvent(eventType, true, false);
      element.dispatchEvent(eventRaised);
    }
  }

  private svgString2API(
    svgString: string,
    format: any,
    name: any,
    meter: any,
    systemDesignService: SystemDesignService
  ) {
    const that = this;

    const imageDetails = {} as GraphImage;

    imageDetails.meter_number = meter;
    imageDetails.image_type = name;
    imageDetails.quote_id = that.authService.getItem('quote_id', 'localStorage');
    imageDetails.revision_number = parseInt(
      that.authService.getItem('revision_number', 'localStorage')
    );
    imageDetails.image_string = svgString.replace(
      new RegExp(`^data:image\\/(png|jpg);base64,`),
      ''
    );

    //this.systemDesignService.numberOFGraphsNeeded -= 1;
    // systemDesignService.uploadGraphImages(imageDetails).subscribe((response: any) => {
    //   const res = response;
    // }, (error: any) => {
    //   console.log(
    //     'Something went wrong while fetching shadow for the keepout'
    //   );
    // }
    // );
    this.systemDesignService.addImageToArray(imageDetails);

  }

  ngAfterViewChecked() {
    if (this.myChartContainer.nativeElement.offsetHeight != this.divHeight || this.divWidth != this.myChartContainer.nativeElement.offsetWidth) {
      this.divWidth = this.myChartContainer.nativeElement.offsetWidth;
      this.divHeight = this.myChartContainer.nativeElement.offsetHeight;
      let tempHeight = this.divHeight;
      if (this.divHeight < 400) {
        tempHeight = 400;
      }
      this.w = this.divWidth;
      this.h = tempHeight;

      this.width = this.w - this.margin.left - this.margin.right;
      this.height = this.h - this.margin.top - this.margin.bottom;
      this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
      this.initSvg();
    }
  }

  private async drawChart(data: any) {
    this.x = d3.scalePoint().range([0, this.width]);
    d3.scaleBand();
    this.y = d3.scaleLinear().range([this.height, 0]);
    var color = d3
      .scaleOrdinal()
      .domain(['Consumption', 'Generation'])
      .range(this.colors);

    this.z = d3
      .scaleOrdinal()
      .domain(['Consumption', 'Generation'])
      .range(this.colors);;
    let that = this;
    var area1 = d3
      .area()
      .curve(d3.curveMonotoneX)
      .x(function (d: any) {
        return that.x(d.month);
      })
      .y0(that.y(0))
      .y1(function (d: any) {
        return that.y(d.kWh);
      });

    this.x.domain(
      this.xAxisLabels.map((d: any) => {
        return d;
      })
    );
    this.y.domain([
      0,
      d3.max(data, function (c: any) {
        return d3.max(c.values, function (d: any) {
          return d.kWh;
        });
      }),
    ]);
    this.z.domain(
      data.map(function (c: any) {
        return c.id;
      })
    );

    const yAxisGrid = d3
      .axisLeft(this.y)
      .ticks(this.numberTicksY)
      .tickFormat((d: any) => {
        if (d <= 999) return d;
        else if (d > 999 && d < 1000000)
          return d / 1000 + 'K';
        else if (d >= 1000000) return d / 1000000 + 'M';
        else d
      })
      .tickSizeOuter(0);
    this.yAxis = this.chart
      .append('g')
      .classed('gridLine', true)
      .attr('class', 'grid-line')
      .attr('transform', 'translate(0,0)')
      .style('font-size', '14px')
      .call(yAxisGrid);

    this.chart
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 50)
      .attr('x', 0 - this.height / 2 - 5)
      .style('text-anchor', 'middle')
      .style('stroke', 'none')
      .classed('axis-title', true)
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .text(this.yLabel);

    this.xAxis = this.chart
      .append('g')
      .classed('x-axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .call(d3.axisBottom(this.x).ticks(12));

    this.chart
      .append('text')
      .attr('y', this.height + 43)
      .attr('x', this.width / 2)
      .classed('axis-title', true)
      .style('text-anchor', 'middle')
      .style('stroke', 'none')
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .text('Jan - Dec');

    // Prep the tooltip bits, initial display is hidden
    var tooltip = this.svg
      .append('g')
      .attr('class', 'text')
      .style('display', 'none');

    tooltip.append('rect').attr('fill', 'green').style('opacity', 0.5);
    tooltip
      .append('text')
      .attr('x', 15)
      .attr('dy', '1.2em')
      .style('text-anchor', 'middle')
      .attr('font-size', '14px')
      .attr('font-family', 'Roboto')
      .attr('font-weight', 'bold')
      .attr('color', 'blue');

    var colors = this.colors;
    const newcanvas = document.createElement('canvas');
    const ctx: any = newcanvas.getContext('2d');
    ctx.font = '16px Roboto';
    const spacing = ['Consumption', 'Generation'].map(item => ctx.measureText(item).width + 40)
    this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${this.width / 2 + this.margin.left},${this.height + 65})`
      )
      .call(this.colorLegend, {
        colorScale: color,
        circleRadius: 14,
        spacing: spacing,
        textOffset: 20,
        lineOffset: 25,
      });


    this.chart.on('mousemove', function (event: any, d: any, i: any) {
      let id = event.target.id;
      let condition =
        id == 'Generationlegend' ||
        id == 'Consumptionlegend' ||
        id == 'GenerationlegendLabel' ||
        id == 'ConsumptionlegendLabel';
      if (!condition) {
        var coords = d3.pointer(event);
        var xPosition = coords[0];
        var yPosition = coords[1] - 20;

        var xPos = coords[0];
        var domain = that.x.domain();
        var range = that.x.range();
        var rangePoints = d3.range(range[0], range[1], that.x.step());
        var yPos = domain[d3.bisect(rangePoints, xPos) - 1];
        let tooltipValue = that.getTooltipValue(yPos);
        let consumption =
          'Consumption - ' + tooltipValue.consumption + 'kWh , ';
        let generation = 'Generation - ' + tooltipValue.generation + 'kWh';
        const newXPos = (yPos.toLowerCase() == 'jan' || yPos.toLowerCase() == 'feb') ? xPosition + 200 : yPos.toLowerCase() == 'nov' ? xPosition - 100 : xPosition;
        tooltip.attr(
          'transform',
          'translate(' + newXPos + ',' + yPosition + ')'
        );
        let text = yPos + ' : ' + consumption + generation;
        tooltip.select('text').text(text);
      }
    });
    this.chart.on('mouseover', function (event: any) {
      let id = event.target.id;
      let condition =
        id == 'Generationlegend' ||
        id == 'Consumptionlegend' ||
        id == 'GenerationlegendLabel' ||
        id == 'ConsumptionlegendLabel';
      if (!condition) {
        tooltip.style('display', 'block');
      } else {
        tooltip.style('block', 'none');
      }
    });
    this.chart.on('mouseout', function (event: any) {
      let id = event.target.id;
      let condition =
        id == 'Generationlegend' ||
        id == 'Consumptionlegend' ||
        id == 'GenerationlegendLabel' ||
        id == 'ConsumptionlegendLabel';
      if (!condition) {
        tooltip.style('block', 'none');
      } else {
        tooltip.style('block', 'none');
      }
    });

    this.areasChart = this.chart.append('g').classed('area', true);

    this.area = this.areasChart
      .selectAll('.area')
      .data(data)
      .enter()
      .append('g')
      .append('path')
      .classed('area', true)
      .style('fill', (d: any, i: any) => {
        return this.z(d.id);
      })
      .style('fill-opacity', 1)
      .style('stroke-opacity', 1)
      // .attr('opacity', 0.15)
      .transition() // <---- Here is the transition
      .duration(2000) // 2 seconds
      .attr('d', function (d: any) {
        return area1(d.values);
      });
    //.attr('opacity', 1)

    $(document).ready(function () {
      $('.area-chart').on('touchstart', function (e) {
        console.log("area chart");
      }).trigger("touchstart");
    })

  }

  getTooltipValue(val: any) {
    let obj = { consumption: 0, generation: 0 };
    this.formattedData[0].values.forEach((element: any) => {
      if (element.month == val) {
        obj.consumption = element.kWh;
      }
    });
    this.formattedData[1].values.forEach((element: any) => {
      if (element.month == val) {
        obj.generation = element.kWh;
      }
    });
    return obj;
  }

  legendXPositionText(
    data: any,
    position: any,
    textOffset: any,
    avgFontWidth: any
  ) {
    return this.legendXPosition(data, position, avgFontWidth) + textOffset;
  }

  legendXPosition(data: any, position: any, avgFontWidth: any) {
    if (position == 0) {
      return 0;
    } else {
      var xPostiion = 0;
      for (let i = 0; i < position; i++) {
        xPostiion += data[i].length * avgFontWidth + 80;
      }
      return xPostiion;
    }
  }

  colorLegend(selection: any, props: any) {
    const { colorScale, circleRadius, spacing, textOffset, lineOffset } = props;
    var totalSpace = 0;
    spacing.forEach((item: any) => totalSpace += item);
    const stX = -(totalSpace / 2);
    var stY = 0;
    stY = lineOffset / 2;
    const groups = selection.selectAll('g').data(colorScale.domain());
    const groupEnter = groups.enter().append('g').attr('class', 'tick');
    groupEnter.merge(groups).attr('transform', (d: any, i: any) => {
      let xpos = 0;
      for (let j = 0; j < i; j++) {
        xpos += spacing[j];
      }
      return `translate(${stX + xpos},0)`;
    });
    groups.exit().remove();
    groupEnter
      .append('rect')
      .merge(groups.select('rect'))
      //.attr('r',circleRadius)
      .attr('width', circleRadius)
      .attr('height', circleRadius)
      .attr('id', (d: any) => d + 'l')
      .attr('fill', (d: any) => colorScale(d))
      .on('click', function (d: any) {
        const currentOpacity: any = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
      });

    groupEnter
      .append('text')
      .merge(groups.select('text'))
      .attr('id', (d: any) => d + 'lt')
      .text((d: any) => d)
      .attr('dy', '0.1em')
      .attr('x', textOffset)
      .attr('y', (circleRadius / 4) * 3)
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .on('click', function (d: any) {
        const currentOpacity: any = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
      });
  }

  findWidtText(text: any) {
    var width = 0;

    var canvas = document.createElement('canvas');
    let context: any = canvas.getContext('2d');
    context.font = '1em Roboto;';
    let metrics = context.measureText(text);
    width = metrics.width;
    return width;
  }
}
