import { RoofSection } from '@design/models/roof-section.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DESIGN_STORE_KEY, selectDesign } from '../design.selector';
import { Vertex } from '@design/models/vertex.model';
import { isNil } from 'lodash';
import { Panel } from '@design/models/panel.model';
import { FrameState } from './frame.state';
import { adapter } from './frame.reducer';
import { Frame } from '@design/models/frame.model';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export namespace fromFrameSelectors {
  const frameStateSelector = createSelector(
    selectDesign,
    (state) => state.frame
  );
  export const allFrames = createSelector(frameStateSelector, selectAll);


  export const selectFramesByIds = (ids: Frame['id'][]) =>
    createSelector(frameStateSelector, (state: FrameState) => {
      const entities = selectEntities(state);
      return ids.map((id: Frame['id']) => entities[id]);
    });
  export const selectUpdatedFrames = (ids: Frame['id'][]) =>
    createSelector(frameStateSelector, (state: FrameState) => {
      const entities = selectEntities(state);
      return ids.map((id: Frame['id']) => {
        const frame = entities[id];
        return frame && frame.isDefaultStructure === false ? frame : undefined;
      }).filter((frame) => frame !== null);
    });
  export const selectFrameWithMaxPillarId = (ids: Frame['id'][]) =>
    createSelector(frameStateSelector, (state: FrameState) => {
      const entities = selectEntities(state);
      let maxPillarId: number = -1;
      ids.forEach((id: Frame['id']) => {
        const frame = entities[id];
        if (frame && (Math.max(...frame.pillarIds) > maxPillarId)) {
          maxPillarId = Math.max(...frame.pillarIds);
        }
      });
      return maxPillarId;
    });
}
