<div>
    <div class="top-flex">
       <div class="top-flex-1">
          <figure class="highcharts-figure">
             <div id="container"></div>
          </figure>
       </div>
       <div class="top-flex-2">
          <figure class="highcharts-figure1">
             <div id="container1"></div>
          </figure>
       </div>
    </div>
    <div class="top-flex">
       <div class="top-flex-1">
          <figure class="highcharts-figure2">
             <div id="container2"></div>
          </figure>
       </div>
       <div class="top-flex-2">
          <figure class="highcharts-figure3">
             <div id="container3"></div>
          </figure>
       </div>
    </div>
 </div>