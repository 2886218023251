<button type="button" class="close" aria-label="Close" (click)="dismiss()">
  <span aria-hidden="true">&times;</span>
</button>
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer align-right">
  <button color="primary" type="button" (click)="decline()" class="mat-raised-button">{{ btnCancelText }}</button>
  <button color="primary" type="button" ngbAutofocus="true" (click)="accept()" class="mat-raised-button marginleft10">{{
    btnOkText }}</button>
</div>
