import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SaveStatus } from '@design/models/save-status.model';
import { forkJoin, Observable, Subject, Subscription, timer } from 'rxjs';
import { takeUntil, retry, map, finalize, take } from 'rxjs/operators';
import { AdditionalToolsApi } from '@design/api/additional-tools-api';
import { QuotesDetailsApi } from '@design/api/quotes-details-api';
import { AuthService } from 'src/app/helpers/auth.service';
import { environment } from 'src/environments/environment';
import { Action, Store } from '@ngrx/store';
import { DesignState } from '@design/store/design.reducer';
import { fromAdditionalToolsResultsSelectors } from '@design/store/additional-tools';
import { fromMetaDataActions } from '@design/store/meta-data';


/// additional tool api
/// 1.saveQuote3DIsometricViewImage => quotes/house-view-image
/// 2.saveQuoteBackgroundImage => nact-backend/quotes/saveBackgroundImage
/// Quote details api
/// 3.updateQuoteHardware =>quote/hardware
/// 4.updateQuoteDesign =>quote/design
/// 5.saveDataToS3 => response api url
/// 6.getUploadLink => site/3d-object
/// 7.getPVWattsHourly => getPVWattsHourly

@Injectable({
    providedIn: 'root',
})
export class BatchAutoSaveService {
    private cancelInprogressRequests$ = new Subject<void>();
    private cancelTimer$ = new Subject<void>();
    private currentVersion = 0;
    private apiCalls: {[name: string]:Observable<any>} = {};
    private versionHistory: { v: number, status: 'success' | 'failed' }[] = [];
    private saveStatus: SaveStatus = SaveStatus.SAVED;
    private $batchSaveStatus: Subject<SaveStatus> = new Subject<SaveStatus>;
    private tempApiCallsObservabled: any;
    private WAIT_TIME_TO_OVERRIDE = 10000; /// 10 seconds


    private design3dModel = new Subject<Action>();
    design3dModel$ = this.design3dModel.asObservable();

    quoteDesignData: any;
    getUploadedLink$?: Subscription;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private quotesDetailsApi: QuotesDetailsApi,
        private store: Store<DesignState>,
    ) { }
    private setBatchAutoSaveStatus(value: SaveStatus){
        this.saveStatus = value;
        this.$batchSaveStatus.next(value);
    }

    public batchAutoSaveStatus(){
        return this.$batchSaveStatus.asObservable();
    }

    /**
     * NOTES(dk): updateDesign is used to ensure data consistency between quote design data and
     * 3D model data.
     * 1. updateDesign is called wherever quote design data is updated.
     * 2. The update will then retrieve the 3D model from the three-js component using the design3dModel subject.
     * 3. It gets an upload link for S3 after receiving the 3D model data.
     * 4. It makes a save quote design call and saves 3D model data in parallel after receiving the upload link.
     */
    public updateDesign(designData: any, action: Action) {
        this.store.select(fromAdditionalToolsResultsSelectors.sceneRestoreStatus).pipe(take(1)).subscribe(x => {
            if (x === SaveStatus.SAVED || x === SaveStatus.FAILED) {
                this.quoteDesignData = designData;
                this.get3dModelData(action);
            } else {
                console.info('auto save stopped from updateDesign as design not restored yet.');
            }
        })
    }

    public onReceiving3dModelData(design3dData:any){
        if(this.getUploadedLink$){ /// cancel current call
            this.getUploadedLink$.unsubscribe();
            this.getUploadedLink$ = undefined;
        }

        this.getUploadedLink$ = this.getUploadedLink()
            .subscribe((res:any)=>{
            const data = {...res.data};
            data['model']=design3dData;
            const apiCalls = [
                this.quotesDetailsApi.updateQuoteDesign(this.quoteDesignData),
                this.quotesDetailsApi.saveDataToS3(data),
            ];
            console.info('Scene data to be saved in S3 : ', data);
            this.batchApiSave(apiCalls);
            this.quoteDesignData = undefined; /// clear quote design data;
        });
    }

    private get3dModelData(action: Action) {
        this.design3dModel.next(action); /// this will call onReceiving3dModelData from three js component
    }


    private getUploadedLink(){
        let qrn={ qrn:this.authService.getItem('qrn', 'localStorage') };
        return this.http.post(`${environment.s3baseurl}site/3d-object`,qrn)
    }

    private batchApiSave(apiCallsObservables: Observable<any>[]) {
        const isSaveBatchApiCallsInprogress = this.saveStatus === SaveStatus.INPROGRESS;
        if(isSaveBatchApiCallsInprogress){
            this.tempApiCallsObservabled = apiCallsObservables;
            this.cancelTimer();
            timer(this.WAIT_TIME_TO_OVERRIDE).pipe(
                takeUntil(this.cancelTimer$),
                map(()=>{
                    this.makeBatchApiCalls(this.tempApiCallsObservabled)
                    this.tempApiCallsObservabled = undefined;
                }),
            ).subscribe();
            return;
        }

        this.makeBatchApiCalls(apiCallsObservables);

    }

    private makeBatchApiCalls(services: Observable<any>[] = []) {

        const oldCancel = this.cancelInprogressRequests$;
        this.cancelInprogressRequests$ = new Subject();
        oldCancel.next();
        oldCancel.complete();

        this.setBatchAutoSaveStatus(SaveStatus.INPROGRESS);

        forkJoin(services)
            .pipe(
                takeUntil(this.cancelInprogressRequests$),
                finalize(() =>{
                    this.setBatchAutoSaveStatus(SaveStatus.CANCELLED);
                }),
                retry(1) //if any api fails. it will retry all the api thrice
            ).subscribe({
                next: (savedData) =>{
                    this.setBatchAutoSaveStatus(SaveStatus.SAVED);
                    this.store.dispatch(fromMetaDataActions.markSceneStatus({isNewQuote: false}));
                    this.saveVersionData({ v: this.currentVersion, status: 'success' }, savedData);
                    this.checkForTriggerApiCalls();
                },
                error: (error) =>{
                    this.saveVersionData({ v: this.currentVersion, status: 'failed' }, error);
                    this.setBatchAutoSaveStatus(SaveStatus.FAILED);
                }
            })
    }

    private checkForTriggerApiCalls(){
        const hasPendingBatchRequest = this.tempApiCallsObservabled;
        if(hasPendingBatchRequest){
            this.cancelTimer();
            this.batchApiSave(this.tempApiCallsObservabled);
            this.tempApiCallsObservabled = undefined;
        }
    }

    private cancelTimer(){
        this.cancelTimer$.next();
        this.cancelTimer$.complete();
        this.cancelTimer$ = new Subject();
    }

    // private cancelInprogressRequests(){
    //     this.cancelInprogressRequests$.next();
    //     this.cancelInprogressRequests$.complete();
    //     this.cancelInprogressRequests$ = new Subject();
    // }

    private saveVersionData(versionData: { v: number, status: 'success' | 'failed' }, data?: any) {
        this.versionHistory.push(versionData);
        console.log(versionData, data); /// Needs to be removed after testing
    }

    // private increaseCurrentVersion(isMinorVersion = false){
    //     if(isMinorVersion){
    //         this.currentVersion += 0.1
    //     }else{
    //         this.currentVersion++;
    //     }
    // }

}

