<form [formGroup]="profileForm" autocomplete="form" name="projectForm">
  <div fxFlex="30">
    <mat-form-field class="form-field width100" appearance="outline" appAmfaAccess [moduleName]="this.moduleName" functionName="CHANGE_LANGUAGE">
      <mat-label>Choose Language</mat-label>
      <mat-select formControlName="locale" placeholder="Choose Language">
        <mat-option *ngFor="let country of countriesList" [value]="country.country_locale">
          {{country.name}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="f.locale.touched && f.locale.invalid">
        <span *ngIf="f.locale.errors?.required">This field is mandatory</span>
      </mat-error>
    </mat-form-field>
  </div>
  <button mat-button color="primary" (click)="onLocaleSelection()">Yes</button>
</form>

