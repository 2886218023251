import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SubscriptionManager {
    public isSubscribe$: Subject<boolean> = new Subject<boolean>;

    constructor(){}
    
    getSubscription(): Observable<any>{
        return this.isSubscribe$.asObservable();
    }

    startSubscriptions(){
        console.log('SubscriptionManager: start subscription');
        this.isSubscribe$.next(true);
    }

    endSubscriptions(){
        console.log('SubscriptionManager: end subscription');
        this.isSubscribe$.next(false);
    }
}