import { Component, Input, ViewChild, OnChanges } from "@angular/core";
import { ColumnOption, DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZE_OPTIONS } from "src/app/modules/shared/table/table.model";
import { MatTable, _MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnChanges {
  @Input() columns: ColumnOption[] = [];
  @Input() data: any[] = [];
  @Input() pageSizeOptions: number[] = TABLE_PAGE_SIZE_OPTIONS;
  @Input() pageSize: number = DEFAULT_PAGE_SIZE;
  @Input() isPagination = true;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatTable, { static: true }) matTable!: MatTable<any>;

  displayedColumns: string[] = [];
  dataSource: any;

  ngOnChanges() {
    this.init();
  }
  init() {
    this.displayedColumns = this.columns.map(c => c.columnDef);
    this.dataSource = new _MatTableDataSource(this.data);
    if(this.isPagination) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;
  }
}