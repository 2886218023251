import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoaderService } from '../../modules/shared/loader/loader.service';

export function getHideLoaderHeaders() {
  return new HttpHeaders().append('ui-loader', 'hide');
}

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  hideLoaderUrl = []
  constructor(private loaderService: LoaderService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const showLoader = (req.headers.get('ui-loader') ?? '') !== 'hide'; // get loader data from req headers
    if (req.headers.has('ui-loader')) {
      req = req.clone({
        headers: req.headers.delete('ui-loader')
      });
    }
    // Hide loader for financers savings(check/uncheck sales breakdown)
    if (showLoader && (!req.url.includes('financers/savings')
      && !req.url.includes('quotes/saveBackgroundImage')
      && !req.url.includes('quotes/saveHouseViewImage')
      && !window.location.href.includes('/design'))) {
      this.showLoader();
      return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      }, (err: any) => {
        this.onEnd();
      }));
    }
    else {
      return next.handle(req)
    }

  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
