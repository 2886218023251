import { Injectable } from "@angular/core";
import { IndexedDBService, KeyType } from "./indexed-db.service";
import { share, switchMap, take, tap } from "rxjs/operators";
import { MapType, Maps } from "@design/models/ui-state.model";
import Util from "@design/containers/draw-area/threejs/helpers/Utility";
import { EngineService } from "@design/containers/draw-area/threejs/threejs.service";
import { arrayBufferToBase64 } from "src/app/helpers/utils";
import { BehaviorSubject, timer } from "rxjs";
import { ProjectsService } from "src/app/modules/projects/projects.service";
import { Store } from "@ngrx/store";
import { DesignState } from "@design/store/design.reducer";
import { fromMetaDataSelectors } from "@design/store/meta-data";
import { AuthService } from "src/app/helpers/auth.service";
import { getHideLoaderHeaders } from "src/app/helpers/interceptors/loader.interceptor";
import { openDialog } from "@lib-ui/lib/common/utils/openDialog";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { fromAdditionalToolsActions } from "@design/store/additional-tools";
import { SaveStatus } from "@design/models/save-status.model";

@Injectable({
  providedIn: 'root'
})
export class HeightImageService {
  mapDataWorker: Worker;
  siteWidthInMeter: number = 0;
  private siteConfig: any = { meterPerPixel: 110 / 2000 };
  mapUrl: BehaviorSubject<string> = new BehaviorSubject('');
  crn: string = '';
  selectedMap!: string;
  checkHasDSM: any;

  updateMapUrl(value: string) {
    this.mapUrl.next(value);
  }

  getMapUrl() {
    return this.mapUrl.asObservable();
  }
  constructor(private store: Store<DesignState>,
    private engServ: EngineService,
    private indexedDBService: IndexedDBService,
    private projectsService: ProjectsService,
    private authService: AuthService,
    private dialog: MatDialog,
    private translate: TranslateService,) {
    this.mapDataWorker = new Worker(new URL('@design/workers/map-data-download.worker', import.meta.url), { type: 'module' });
    this.getHeighDataFromWorker();
  }

  getSiteData(maps: any) {
    this.store.select(fromMetaDataSelectors.getSiteData).pipe(take(1)).subscribe(
      (data: any) => {
        if (data) {
          console.info('getSiteData with data:', data);
          
          const isGoogleSolar = maps?.replace(/ /g, '').toLowerCase().includes('googlesolar');
          const isGoogleMap = maps?.replace(/ /g, '').toLowerCase().includes('googlemap');
          
          const radius = isGoogleSolar ? data.radius : Math.min(data.radius, 100);
          const mpp = isGoogleMap ? (data.height * data?.meterPerPixel) / 2000 : (radius * 2) / 2000;
          
          this.siteWidthInMeter = data.height * data?.meterPerPixel;
          this.siteConfig = { ...data, meterPerPixel: mpp };
          Util.siteConfig = this.siteConfig;
        }
      },
      (error: any) => console.error('get site data error:', error)
    );
  
    if (maps !== 'none') {
      const checkHasDSM = !maps?.replace(/ /g, '').toLowerCase().includes('googlemap');
      this.selectedMap = maps;
      this.store.dispatch(fromAdditionalToolsActions.setMapLoadingStatus({ mapLoadingStatus: SaveStatus.OUTDATED }));
  
      const mapType = this.getMapTypeFromMaps(maps);
      if (mapType) {
        this.isHeightDataPresentInIndexedDB(mapType, checkHasDSM);
      }
    }
  }
  
  getMapTypeFromMaps(maps: string): KeyType | null {
    switch (maps) {
      case Maps.GoogleMap:
      case MapType.GOOGLESOLAR:
      case Maps.GoogleSolar:
        return maps?.replace(/ /g, '').toLowerCase().includes('googlemap') ? KeyType.GoogleMap : KeyType.GoogleSolar;
      case Maps.NearMap:
      case MapType.NEARMAPS:
        return KeyType.NearMap;
      default:
        return null;
    }
  }
  


  // crn :: getHeight data from indexedDB
  getHeightDataFromIndexedDb(
    crn: string,
    mapType: string,
    currentSelectedMap: string,
    fromProjectDetails: boolean = false
  ) {
    console.info('getHeightDataFromIndexedDb:', crn, mapType, currentSelectedMap, fromProjectDetails);
  
    this.indexedDBService.getItem(crn).pipe(take(1)).subscribe(indexData => {
      const parsedData = indexData ? JSON.parse(indexData) : null;
  
      const handleMapData = (image: string, height: any = undefined) => {
        if (!fromProjectDetails) {
          Util.heightsArray = height;
          Util.nonDsm = mapType === KeyType.GoogleMap;
          this.updateMapUrl(image);
          console.info('set height data for mapType:', mapType);
        }
      };
  
      if (parsedData && this.indexedDBService.hasMapData(crn, parsedData, mapType, KeyType.Height) && mapType !== KeyType.GoogleMap) {
        handleMapData(parsedData[mapType].image, parsedData[mapType].height);
      } else if (parsedData && this.indexedDBService.hasMapData(crn, parsedData, mapType, KeyType.Image) && mapType === KeyType.GoogleMap) {
        handleMapData(parsedData[mapType].image);
      } else {
        const DSMval = currentSelectedMap.toLowerCase() === Maps.GoogleMap.toLowerCase()
          ? false
          : currentSelectedMap.toLowerCase() === Maps.GoogleSolar.toLowerCase();
  
        const defaultGMapOption = currentSelectedMap.replace(/ /g, '').toLowerCase().includes('google')
          ? 'GOOGLEMAPS'
          : '';
  
        console.info('getHeightDataFromIndexedDb getMapHeight:', DSMval, defaultGMapOption);
        this.engServ.getMapHeight(this.mapDataWorker, DSMval.toString(), defaultGMapOption);
      }
    });
  }
  

  isHeightDataPresentInIndexedDB(currentSelectedMap: string, checkHasDSM: any, fromProjectDetails: boolean = false) { // project details
    if (!this.crn) {
      this.crn = localStorage.getItem('crn') as string;
    }
    let mapType = this.getMapType(currentSelectedMap, checkHasDSM);
    this.indexedDBService.getItem(this.crn).pipe(take(1)).subscribe(indexData => {
      if (indexData && this.indexedDBService.hasMapData(this.crn, JSON.parse(indexData), mapType, KeyType.Height) && this.indexedDBService.hasMapData(this.crn, JSON.parse(indexData), mapType, KeyType.Image) && mapType != KeyType.GoogleMap) {
        console.info('Height data is present in indexed db', mapType, currentSelectedMap, fromProjectDetails);
        this.getHeightDataFromIndexedDb(this.crn, mapType, currentSelectedMap, fromProjectDetails);
      } else if (indexData && this.indexedDBService.hasMapData(this.crn, JSON.parse(indexData), mapType, KeyType.Image) && mapType == KeyType.GoogleMap) {
        console.info('Image data is present in indexed db', mapType, currentSelectedMap, fromProjectDetails);
        this.getHeightDataFromIndexedDb(this.crn, mapType, currentSelectedMap, fromProjectDetails);
      } else {
        console.info('Height data is not present in indexed db', currentSelectedMap, checkHasDSM);
        this.checkHeightDataStatus(currentSelectedMap, checkHasDSM);
      }
    });
  }

  getMapType(map: string, checkHasDSM: any){
    let mapType = map?.replace(/ /g, '').toLowerCase()?.includes('nearmap') ? KeyType.NearMap : ((map?.replace(/ /g, '').toLowerCase()?.includes('googlemap') && (!checkHasDSM || checkHasDSM.toString() == 'false')) ? KeyType.GoogleMap : KeyType.GoogleSolar);
    return mapType;
  }

  checkHeightDataStatus(
    currentSelectedMap: string,
    checkHasDSM: any
  ) {
    checkHasDSM = (checkHasDSM == undefined || checkHasDSM == null) ? false : checkHasDSM;

    const headers = getHideLoaderHeaders();
    console.info('checkHeightDataStatus called with:', currentSelectedMap);

    const mapOption = currentSelectedMap?.replace(/ /g, '').toLowerCase().includes('near')
      ? 'NEARMAPS'
      : 'GOOGLEMAPS';

    console.info('checkHeightDataStatus mapOption:', mapOption);

    if (checkHasDSM && checkHasDSM !== 'false') {
      const subscription = timer(0, 2000)
        .pipe(
          switchMap(() => this.projectsService.checkHeightData(headers, mapOption)),
          tap((res: any) => {
            if (res.isDsmDataDownloaded && (mapOption === 'NEARMAPS' || mapOption === 'GOOGLEMAPS')) {
              this.callHeightApi(checkHasDSM, mapOption);
            }
          }),
          share()
        )
        .subscribe((res) => {
          if (res.isDsmDataDownloaded) subscription.unsubscribe();
        });
    } else {
      Util.nonDsm = checkHasDSM.toString() === 'true';
      Util.heightsArray = undefined;
      this.engServ.getGoogleMap(this.mapDataWorker, false);
    }
  }

  callHeightApi(hasDsm: any = '', currentSelectedMap: string = '') {
    console.info('callHeightApi called with :', hasDsm, currentSelectedMap);
  
    Util.nonDsm = hasDsm.toString() === 'true';
    this.selectedMap = this.getMapType(currentSelectedMap, hasDsm);
  
    console.info(
      'callHeightApi called getMapHeight/getGoogleMap :',
      hasDsm,
      currentSelectedMap,
      Util.nonDsm
    );
  
    if (Util.nonDsm) {
      this.engServ.getMapHeight(this.mapDataWorker, hasDsm, currentSelectedMap);
    } else {
      Util.heightsArray = undefined;
      this.engServ.getGoogleMap(this.mapDataWorker, false);
    }
  }

  getHeighDataFromWorker(): void {
    this.mapDataWorker.onmessage = ({ data }) => {
      let mapType = this.getMapType(this.selectedMap, Util.nonDsm);
      console.info('getHeighDataFromWorker onmessage called with :', mapType, this.selectedMap);
      if (data.action === 'download-height-data-completed') {
        if (this.crn && this.selectedMap) {
          this.indexedDBService.getItem(this.crn).pipe(take(1)).subscribe(indexData => {
            if (indexData && this.indexedDBService.hasMapData(this.crn, JSON.parse(indexData), mapType, KeyType.Height)) {
              console.info('cache data found for :', mapType)
              this.getHeightDataFromIndexedDb(this.crn, mapType, this.selectedMap as string)
              if (mapType == KeyType.NearMap) {
                this.engServ.getNearMap(this.mapDataWorker);
              } else if (mapType === KeyType.GoogleMap) {
                this.engServ.getGoogleMap(this.mapDataWorker, false);
              } else {
                this.engServ.getGoogleMap(this.mapDataWorker, true);
              }
            } else {
              console.info('set cache data for :', mapType)
              this.indexedDBService.setMapData(this.crn, indexData, mapType, KeyType.Height, data.response);
              Util.heightsArray = data.response;
              Util.nonDsm = false;
              if (mapType == KeyType.NearMap) {
                this.engServ.getNearMap(this.mapDataWorker);
              } else if (mapType === KeyType.GoogleMap) {
                this.engServ.getGoogleMap(this.mapDataWorker, false);
              } else {
                this.engServ.getGoogleMap(this.mapDataWorker, true);
              }
            }
          })
        }
      } else if (data.action === 'download-height-data-error') {
        this.errorDialog(data.error);
        this.authService.enableDesignLoader.next({ val: true, action: 'stop' });
        console.error('Found error to get DSM data. Please reload');
      } else if (['download-google-map-image-completed', 'download-google-map-image-error', 'download-near-map-image-completed', 'download-near-map-image-error'].includes(data.action)) {
        if (this.crn && this.selectedMap) {
          this.indexedDBService.getItem(this.crn).pipe(take(1)).subscribe(indexData => {
            console.info('download image for map :', mapType)//, indexData);
            if (indexData && this.indexedDBService.hasMapData(this.crn, JSON.parse(indexData), mapType, KeyType.Image)) {
              this.setDataForDesignPage(JSON.parse(indexData)[mapType].image);
            } else if (['download-google-map-image-completed', 'download-near-map-image-completed'].includes(data.action)) {
              const imageBase64 = arrayBufferToBase64(data.response);
              this.indexedDBService.setMapData(this.crn, indexData, mapType, KeyType.Image, imageBase64);
              this.setDataForDesignPage(data, imageBase64, mapType == KeyType.GoogleMap ? false : true);
            } else if (data.action === 'download-google-map-image-error') {
              console.error('get google map image URL error :', data.error);
            } else if (data.action === 'download-near-map-image-error') {
              console.error('get near map image URL error :', data.error);
            }
          })
        }
      }
    };
  }

  setDataForDesignPage(data: any, base64Image?: string, checkHasDSM = true) {
    console.info('setDataForDesignPage image :', checkHasDSM)//base64Image;
    if (data.action === 'download-google-map-image-completed') {
      if (!checkHasDSM) { // Google Maps
        Util.heightsArray = undefined;
        Util.nonDsm = true;
      }
      console.log('updating google map :', checkHasDSM)
      this.updateMapUrl(base64Image ? base64Image : data); // This will be shared with threejs component
    } else if (data.action === 'download-google-map-image-error') {
      console.error('get google map image URL error :', data.error);
      this.authService.enableDesignLoader.next({ val: true, action: 'stop' })
    } else if (data.action === 'download-near-map-image-completed') {
      console.log('updating near map')
      this.updateMapUrl(base64Image ? base64Image : data); // This will be shared with threejs component
    } else if (data.action === 'download-near-map-image-error') {
      console.error('get near map image URL error :', data.error);
      this.authService.enableDesignLoader.next({ val: true, action: 'stop' })
    }
  }

  errorDialog(err: string) {
    const data = {
      showYesBtn: false,
      showNoBtn: false,
      title: this.translateMsg("unblToLoadHeight"),
      message: this.translateMsg("unblToLoadHeightError"),
    };
    openDialog(this.dialog, data);
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

}
