import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/helpers/auth.service';
import { SettingsService } from '../settings.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss'],
})

export class UploadTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('labelImport')
  labelImport!: ElementRef;
  input: any;
  uploadForm: UntypedFormGroup;
  file!: string;
  tempName = new UntypedFormControl('');
  uploadFiles: any;
  fileToUpload: any;
  ImageUpload: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  subscriptions: Subscription = new Subscription();

  constructor(
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<UploadTemplateComponent>,
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private settingService: SettingsService,
    private cookieService: CookieService,
    private translate : TranslateService
  ) {
    this.uploadForm = this.fb.group({
      description: [''],
      doc_category: [''],
    });
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {}

  fileChanged1(files: any) {}

  handleChange(files: any) {
    if (files && files.length) {
      this.file = files.target.files[0].name;
    }
  }

  uploadFile(event: any) {
    if (this.tempName.value == '') {
      return;
    }
    const files = event.target.files;
    const file = files[0];
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('customTempName', this.tempName.value);
    formData.append('templateName', this.tempName.value);
    formData.append('account_id', this.authService.getItem('account_id'));
    formData.append('session_id', this.authService.getItem('sessionId'));
    formData.append('role', this.authService.getItem('role'));
    formData.append('localeId', this.authService.getItem('localeId'));
    formData.append('email_id', this.authService.getItem('email_id'));
    this.subscriptions.add(
      this.settingService.uploadTemplate(formData).subscribe(
        (resp: any) => {
          const message = this.translateMsg('uploadSuccess');
          const action = 'OK';
          this.snackBar.open(message, action, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: ['light'],
          });
          this.dialogRef.close();
        },
        (err: any) => {
          this.translate.get('toastrMsgs.errorWhileUpload').pipe(take(1)).subscribe((res: any) => {
            this.toastr.error(res);
          })
        }
      )
    );
  }

  upload(ev: any) {
    console.log(ev.target.files);
    this.uploadFiles = ev.target.files[0];
  }

  get f(): any {
    return this.uploadForm.controls;
  }

  uploadDoc() {
    this.ImageUpload = false;
    this.uploadForm.get('description')?.markAsTouched();
    this.uploadForm.get('doc_category')?.markAsTouched();
    if (this.uploadForm.invalid) {
      return;
    } else {
      if (this.uploadFiles) {
        const formData = new FormData();
        formData.append('doc_category', 'Contract');
        formData.append(
          'doc_description',
          this.uploadForm.value['description']
        );
        formData.append('account_id', this.authService.getItem('account_id'));
        formData.append('email_id', this.authService.getItem('email_id'));
        formData.append('uploadInput', this.uploadFiles);
        formData.append('size', this.uploadFiles.size);
        this.subscriptions.add(
          this.settingService.uploadDocuments(formData).subscribe(
            (response: any) => {
              if (response['msg'] == 'DATA_RECEIVED') {
                this.translate.get('toastrMsgs.docUploadedSuccess').pipe(take(1)).subscribe((res: any) => {
                  this.toastr.success(res);
                })
                this.dialogRef.close('Successful');
              }
            },
            (error) => {
              console.log('errorMessage', error);
              this.toastr.error(error.error.message);
            }
          )
        );
      } else {
        this.ImageUpload = true;
      }
    }
  }
  cancel() {}

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName
  }
}
