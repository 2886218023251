<section class="archive-container">
  <div class="archive-header">
    <h2 class="width100 padding20 marginbotton0 archive-heading">{{'settings.costingSetup.archiveCostingPf' | translate }}
      <div class="col search-input-div" [ngClass]="{'border-btm': !!searchText?.length}" *ngIf="!!archiveList?.length">
        <mat-icon (click)="searchOnFocus = !this.searchOnFocus" class="search-icon primary" matTooltip="{{'buttons.search' | translate | titlecase }}">search
        </mat-icon>
        <input autofocus *ngIf="searchOnFocus" [(ngModel)]="searchText" class="search-input" matInput
          (ngModelChange)="onArchiveSearchChange($event)" placeholder="{{'settings.docSetup.searchKey' | translate }}">
        <span id="removeSerchText" *ngIf="!!searchText && searchOnFocus"
          (click)="searchText='';onArchiveSearchChange(searchText)">
          <mat-icon>close</mat-icon>
        </span>
      </div>
    </h2>
    <div class="view-costing" (click)="closeArchiveView.emit(true)">{{'settings.costingSetup.viewCostSetup' | translate }}</div>
  </div>
  <section class="empty-list-container" *ngIf="!archiveList?.length">
    <div class="table-col">
      <ng-container *ngFor="let item of columns">
        <div>{{item.name | translate }}</div>
      </ng-container>
    </div>
    <div class="empty-content">
      <div class="inner-box">
        <div class="isArchive-border">
          <div class="isArchive"></div>
          <div class="empty-msg font-roboto-16 ">{{'settings.costingSetup.costPr' | translate }} {{'settings.costingSetup.archiveIs' | translate }}</div>
          <div class="empty-text font-roboto-16 ">{{'buttons.empty' | translate }}</div>
        </div>
      </div>
    </div>
  </section>
  <section *ngIf="!!archiveList?.length" style="height: 500px;  overflow-y: auto !important;">
    <table mat-table [dataSource]="dataSource" matSort class="width100">
      <!-- Profile name Column -->
      <ng-container matColumnDef="Profile_name">
        <th class="table-col-font" mat-header-cell *matHeaderCellDef mat-sort-header>{{'settings.finSetup.PrName' | translate }} </th>
        <td class="profile-name" mat-cell *matCellDef="let row"> {{row.Profile_name}} </td>
      </ng-container>

      <!-- Created on Column -->
      <ng-container matColumnDef="create">
        <th class="table-col-font" mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.docSetup.createdOn' | translate }} </th>
        <td class="table-other-cell" mat-cell *matCellDef="let row"> {{row.create | localizedDate : 'enableTime'}} </td>
      </ng-container>

      <!-- Archived on Column -->
      <ng-container matColumnDef="archived_on">
        <th class="table-col-font" mat-header-cell *matHeaderCellDef mat-sort-header> {{'settings.costingSetup.archiveOn' | translate }} </th>
        <td class="table-other-cell" mat-cell *matCellDef="let row"> {{row.archived_on | localizedDate : 'enableTime'}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th class="table-col-font" mat-header-cell *matHeaderCellDef> {{'project.prjTab.action' | translate | titlecase }} </th>
        <td class="table-other-cell" mat-cell *matCellDef="let row">
          <div class="restore-icon" (click)="onActionClick(row)"><span style="padding-left: 1.8rem">{{'settings.costingSetup.restore' | translate }}</span></div>
        </td>
      </ng-container>

      <tr class="archive-header-style" mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator class="archive-view" [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" showFirstLastButtons>
    </mat-paginator>
  </section>
</section>