import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthsTimeGridComponent } from './months-time-grid.component';
import { LibUISharedModule } from '@lib-ui/lib/lib-ui-shared.module';
import { MatIconModule } from '@angular/material/icon';
import { EnactIconModule } from '../enact-icon/enact-icon.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    MonthsTimeGridComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    EnactIconModule,
    LibUISharedModule,
    TranslateModule
  ],
  exports: [
    MonthsTimeGridComponent
  ]
})
export class MonthsTimeGridModule { }