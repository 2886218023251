import { ConnectedPosition } from "@angular/cdk/overlay";
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { getDay } from "src/app/helpers/utils";
import { MonthToNumber } from "src/app/models/dynamic-date-chart.model";
@Component({
  selector: "app-dynamic-date-picker",
  templateUrl: "./dynamic-date-picker.component.html",
  styleUrls: ["./dynamic-date-picker.component.scss"]
})
export class DynamicDatePickerComponent implements OnInit, OnChanges {
  @ViewChild('calendar') calendar: any;
  @Input() days: any[] = [];
  @Input() selectedId = 0;
  @Output() dateChange = new EventEmitter();
  dateForm: UntypedFormGroup = this._fb.group({ dayWith: [''], date: ['', Validators.required] });
  minDate = getDay(0, 1);
  maxDate = getDay(11, 31);
  isDisabled = false;
  tooltipPosition: ConnectedPosition[] = [{ originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top', offsetX: -5, offsetY: 5 }];
  constructor(private _fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initForm();
  }
  initForm() {
    if (!!this.days) {
      if(this.days[this.selectedId]?.label === 'Typical Day') {
        this.isDisabled = true;
        this.dateForm.get('dayWith')?.patchValue(this.days[this.selectedId]);
        this.dateForm.get('date')?.patchValue('N/A')
      } else {
        this.isDisabled = false;
        if(this.days[this.selectedId]?.value)  {
          const date = new Date(this.getDate(this.days[this.selectedId].value));
          this.dateForm = this._fb.group({ dayWith: [this.days[this.selectedId]], date: [date, Validators.required] });
        } else {
          this.dateForm.get('dayWith')?.patchValue(this.days[this.selectedId])
        }
      }
    } 
  }
  onOptionChange(item: any) {
    if(item?.value?.label === 'Custom' && !item?.value?.value) {
      this.isDisabled = false;
      this.dateForm.get('date')?.patchValue(item?.value?.value)
      this.calendar.showOverlay();
    } else if(item?.value?.label === 'Typical Day') {
      this.isDisabled = true;
      this.dateForm.get('date')?.patchValue(null)
      this.dateChange.emit(this.dateForm.value);
    } else {
      this.isDisabled = false;
      this.dateForm.get('date')?.patchValue(!!item?.value?.value ? new Date(this.getDate(item.value?.value)) : item?.value?.value)
      if (!!item?.value?.value) this.dateChange.emit(this.dateForm.value);
    }
  }
  onDateChange(item: any) {
    const date = new Date(item);
    const selectedDay = `${date.getDate()} ${date.toLocaleString('en-US', { month: 'short' })}`
    const selectedItem = this.days.find(item => item.value === selectedDay);
    this.dateForm.get('date')?.patchValue(date);
    this.dateForm.get('dayWith')?.patchValue(!!selectedItem ? selectedItem : this.days[this.days?.length - 1]);
    this.dateChange.emit(this.dateForm.value);
  }

  getDate(selectedDate: any) {
    const [day, month] = selectedDate.split(' ');
    return +new Date(+new Date().getFullYear(), +MonthToNumber[month] - 1, +day);
  }
}