import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';

import localeAfrica from '@angular/common/locales/af';
import localeGermany from '@angular/common/locales/de';
import localeUS from '@angular/common/locales/en';
import localeSpanish from '@angular/common/locales/es';
import localeFrench from '@angular/common/locales/fr';
import localeRussia from '@angular/common/locales/ru';
import localeThai from '@angular/common/locales/th';
import localeChina from '@angular/common/locales/zh';
import localeUAE from '@angular/common/locales/ar-AE';
import localeSaudi from '@angular/common/locales/ar-SA';
import localeIndia from '@angular/common/locales/en-IN';
import localePakistan from '@angular/common/locales/en-PK';
import localePhilippines from '@angular/common/locales/en-PH';
import localeCanada from '@angular/common/locales/en-CA';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { Utils } from './utils';
import { Title } from '@angular/platform-browser';
import { FeatureFlagService } from '@api/services/feature-flags.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  // Chosse Locale From This Link
  // https://github.com/angular/angular/tree/master/packages/common/locales
  constructor(
    private translate: TranslateService,
    private authService: AuthService,
    private utils: Utils,
    private titleService : Title,
    private featureFlagService: FeatureFlagService
  ) {
    registerLocaleData(localeAfrica, 'af_ZA');
    registerLocaleData(localeGermany, 'de_DE');
    registerLocaleData(localeUS, 'en_US');
    registerLocaleData(localeSpanish, 'es_ES');
    registerLocaleData(localeFrench, 'fr_FR');
    registerLocaleData(localeRussia, 'ru_RU');
    registerLocaleData(localeThai, 'th_TH');
    registerLocaleData(localeChina, 'zh_CN');
    registerLocaleData(localeUAE, 'ar_AE');
    registerLocaleData(localeSaudi, 'ar_SA');
    registerLocaleData(localeIndia, 'en_IN');
    registerLocaleData(localePakistan, 'en_PK');
    registerLocaleData(localePhilippines, 'en_PH');
    registerLocaleData(localeCanada, 'en_CA');
  }
  // tslint:disable-next-line:variable-name
  private _locale = '';
  private currentLang = new BehaviorSubject<string>('');
  usersLang = this.currentLang.asObservable();

  set locale(value: string) {
    this._locale = value;
  }
  get locale(): string {
    return this._locale || 'en-US';
  }

  public registerCulture(culture: string): void {
    if (!culture) {
      return;
    }
    this._locale = culture;
  }
  public registerLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.currentLang.next(lang);
    this.translate.get('app_title').pipe(take(1)).subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
  }

  onLocaleSelectionChanged(countryLocale: string): void {
    // Found duplication in cookie values.
    // Before set any cookie value remove it first
    this.authService.removeItem('localeId');

    this.authService.setItem('localeId', countryLocale);
    // this.registerLanguage(countryLocale);
    this.registerCulture(countryLocale);
    this.utils.countryCurr = this.utils.checkForRole();
    localStorage.setItem('countryCurr', this.utils.countryCurr);
    
  }

  setCountryLocale(countryLocale: string) {
    if (!!countryLocale) {
      let numberLocale = countryLocale;
      if (numberLocale == 'es_ES')
        numberLocale = 'de_DE'; // setting german locale since spanish locale is not working for four digit number/
      this.authService.setItem('localeNumberFormate', numberLocale);
    }
    else
      this.authService.removeItem('localeNumberFormate', 'localStorage')
  }

}
