import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/helpers/auth.service';
import { ProjectsService } from '../../projects/projects.service';
import { UploadTemplateComponent } from '../upload-template/upload-template.component';
import * as $ from 'jquery';
import { ConfirmDialogService } from '../../../helpers/confirm-dialog/confirm-dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from '../settings.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-proposal-modal',
  templateUrl: './proposal-modal.component.html',
  styleUrls: ['./proposal-modal.component.scss'],
})

export class ProposalModalComponent implements OnInit, OnDestroy {
  showSystem = false;
  showCustom = true;
  templatesObj: any = [];
  custom_template = new UntypedFormControl();
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  displayType: any = 'Settings';
  settingsType: any = 'lead';
  previewSrc: any;
  customTemplate: any;
  previewSrc1: any;
  subscriptions: Subscription = new Subscription();

  constructor(
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private settingService: SettingsService,
    private confirmDialogService: ConfirmDialogService,
    private fb: UntypedFormBuilder,
    private projectsService: ProjectsService,
    private dialog: MatDialog,
    public authService: AuthService,
    private translate : TranslateService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.getCustomTemplate();
  }

  onTemplateChange(event: any) {
    console.log(event.value);
    this.customTemplate = event.value;
  }
  getCustomTemplate(): void {
    this.subscriptions.add(this.projectsService.fetchCustomTemplate().subscribe((resp: any) => {
      this.custom_template.patchValue(resp?.default_template);
      this.customTemplate = resp?.default_template;
      this.templatesObj = resp.template;
    }));
  }
  changeButton(val: any) {
    if (val == 'System') {
      this.showSystem = true;
      this.showCustom = false;
    } else {
      this.showSystem = false;
      this.showCustom = true;
    }
  }
  deleteTemplate() {
    let name = '';
    this.templatesObj.forEach((element: any) => {
      if (element.value == this.custom_template.value) {
        name = element.name;
      }
    });
    this.subscriptions.add(this.settingService.deleteTemplate(name).subscribe(
      (resp: any) => {
        const message = this.translateMsg('deleteSuccess');
        const action = 'OK';
        this.snackBar.open(message, action, {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['light'],
        });
        this.getCustomTemplate();
      },
      (err: any) => {
        this.translate.get('toastrMsgs.errorWhileUpload').pipe(take(1)).subscribe((res: any) => {
          this.toastr.error(res);
        })
      }
    ));
  }

  uploadModal() {
    const dialogRef = this.dialog.open(UploadTemplateComponent, {
      width: '55%',
      panelClass: 'upload-modal',
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.getCustomTemplate();
      }
    }));
  }
  delete() {
    this.subscriptions.add(this.confirmDialogService
      .confirmObs(
        'Confirmation',
        `Do you wish to delete template ${this.custom_template.value} ?`
      )
      .subscribe(
        (confirmed: any) => {
          if (confirmed === true) {
            this.deleteTemplate();
          } else {
          }
        },
        (error: any) => {
          console.log(
            'User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'
          );
        }
      ));
  }

  downloadFile() {
    var templateName = this.custom_template.value;
    var docUrl, dataArray;

    if (window.location.origin.indexOf('local.') > -1) {
      docUrl = 'http://local.enact-systems.com:8080/nact-client/';
    } else {
      docUrl = window.location.origin + '/nact-client/';
    }
    window.open(
      docUrl +
        'CustomTemplate/' +
        this.authService.getItem('account_id') +
        '/' +
        templateName +
        '.docx'
    );
  }

  setTemplate() {
    this.subscriptions.add(this.settingService.setTemplate(this.custom_template.value).subscribe(
      (resp: any) => {
        const message = this.translateMsg('tempSet');
        const action = 'OK';
        this.snackBar.open(message, action, {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: ['light'],
        });
        this.subscriptions.add(this.settingService.saveTemplate().subscribe((resp: any) => {
          this.getCustomTemplate();
        }));
      },
      (err: any) => {}
    ));
  }

  setType(val: any) {
    this.displayType = val;
    this.previewSrc1 = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://java11-docs.enact-systems.com/CustomTemplate/' +
        this.customTemplate +
        'Out.pdf'
    );
    this.previewSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://java11-docs.enact-systems.com/CustomTemplate/' +
        this.customTemplate +
        '.pdf'
    );
  }
  setSettings(val: any) {
    this.settingsType = val;
  }
  prev() {
    let elem: any, elem2: any;
    elem = $('#myCarousel');
    elem2 = $('#myCarouse2');
    elem.carousel('prev');
    elem2.carousel('prev');
  }
  next() {
    let elem: any, elem2: any;
    elem = $('#myCarousel');
    elem2 = $('#myCarouse2');
    elem.carousel('next');
    elem2.carousel('next');
  }
  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName
  }
}
