<div class="outer-container" style="display: none;">
  <div class="container" fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center center">
    <div class="signin-panel" *ngIf="showLoginPanel === false">
      <form [formGroup]="signInForm" autocomplete="form" name="loginFrom" (ngSubmit)="onSubmit()">
        <div fxLayout="row">
          <div fxFlex="100">
            <a href="http://www.enact-systems.com/" target="_blank">
              <div class="companyLogo"></div>
            </a>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="100">
            <mat-form-field class="form-field width100" appearance="outline">
              <mat-label>Account ID</mat-label>
              <input matInput formControlName="accountId" type="text" placeholder="" class="paddingleft56"
                autocomplete="accountId">
              <mat-error *ngIf="f.accountId.touched && f.accountId.invalid">
                <span *ngIf="f.accountId.errors?.required">This field is mandatory</span>
                <span *ngIf="f.accountId.errors?.pattern">AccountId is invalid</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" class="margintop10">
          <div fxFlex="100">
            <mat-form-field class="form-field width100" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="emailId" type="email" placeholder="" class="paddingleft56"
                autocomplete="email">
              <mat-error *ngIf="f.emailId.touched && f.emailId.invalid">
                <span *ngIf="f.emailId.errors?.required">This field is mandatory</span>
                <span *ngIf="f.emailId.errors?.pattern">Email is invalid</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" class="margintop10">
          <div fxFlex="100">
            <mat-form-field class="form-field width100" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput formControlName="password" type={{inputtype}} placeholder="" class="paddingleft56 display-inline-block"
                autocomplete="setpwd">
                <mat-icon color="primary" *ngIf="this.show" (click)="toggleEyeIcon()" class="position-eye-icon">visibility</mat-icon>
                <mat-icon color="primary" *ngIf="!this.show" (click)="toggleEyeIcon()" class="position-eye-icon">visibility_off</mat-icon>
              <mat-error *ngIf="f.password?.touched && f.password?.invalid">
                <span *ngIf="f.password.errors?.required">This field is mandatory</span>
                <span *ngIf="f.password.errors?.pattern">Password must include at least one numeric digit, one alphabet and one special character and should range between 8-16 characters</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" class="margintop10">
          <div fxFlex="100" class="aligncenter">
            <div *ngIf="wrongCredentials !== ''" class="error">{{wrongCredentials}}</div>
            <button mat-raised-button type="submit" class="width100 thin-font" color="primary">LOGIN</button>
          </div>
        </div>
        <div fxLayout="row" class="marginbottom10 aligncenter">
          <div fxFlex="100">
            <div>
              <hr class="separator" />
              <span class="add-space">Having login trouble?</span>
              <br />
              <a class="reset" (click)="showLoginPanel = false;">Reset Password </a> or <a href="mailto:helpdesk@enact-systems.com" class="contact_support"> Contact Support</a>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="reset-pwd-panel" *ngIf="showLoginPanel === false">
      <form class="resetPwdForm" [formGroup]="resetPwdForm" autocomplete="form" name="resetPwdForm" (ngSubmit)="onResetPwd()">
        <div fxLayout="row">
          <div fxFlex="100">
            <a href="http://www.enact-systems.com/" target="_blank">
              <div class="companyLogo"></div>
            </a>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="100">
            <mat-form-field class="form-field width100" appearance="outline">
              <mat-label>Account ID</mat-label>
              <input matInput formControlName="accountId" type="text" placeholder="" class="paddingleft56"
                autocomplete="accountId">
              <mat-error *ngIf="fs.accountId.touched && fs.accountId.invalid">
                <span *ngIf="f.accountId.errors?.required">This field is mandatory</span>
                <span *ngIf="f.accountId.errors?.pattern">AccountId is invalid</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" class="margintop10">
          <div fxFlex="100">
            <mat-form-field class="form-field width100" appearance="outline">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="emailId" type="email" placeholder="" class="paddingleft56"
                     autocomplete="email">
              <mat-error *ngIf="fs.emailId.touched && fs.emailId.invalid">
                <span *ngIf="fs.emailId.errors?.required">This field is mandatory</span>
                <span *ngIf="fs.emailId.errors?.pattern">Email is invalid</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group loginBtnWrapper">
          <div *ngIf="showResetPwdError === true" class="resetPwdText resetPwdError error">Contact Support {{customerSupportNumber}}</div>
          <div *ngIf="passwordResetSuccess === true" class="resetPwdText resetPwdSuccess">A link to reset your password has been emailed to the address you provided.</div>
          <button mat-raised-button type="button" (click)="showLoginPanel = true;" class="width100 thin-font" color="primary">BACK TO LOGIN</button>
          <button mat-raised-button type="submit" class="width100 thin-font margintop10" color="primary">RESET PASSWORD</button>
        </div>
      </form>
    </div>
    <!-- <span class="trademark">EnerCloud &trade;</span> -->
  </div>
</div>
