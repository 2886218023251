import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2, AfterViewChecked, OnDestroy } from '@angular/core';
import * as d3 from 'd3';
import * as $ from 'jquery';
import { Subject, Subscription } from 'rxjs';
import { ChartConsumption, CustomDatePayload, SolorToColor, SolorToId, SolorToNewSolar } from 'src/app/models/dynamic-date-chart.model';
import { isSuccess } from 'src/app/models/simple.types';
import { SysresultsService } from 'src/app/modules/system-design/sysresults/sysresults.service';
import { SystemDesignService } from 'src/app/modules/system-design/system-design.service';
import { AuthService } from '../../../../helpers/auth.service';
import Canvg from 'canvg';
import { Utils } from 'src/app/helpers/utils';
@Component({
  selector: 'app-mixed-chart',
  templateUrl: './mixed-chart.component.html',
  styleUrls: ['./mixed-chart.component.scss'],
})

export class MixedChartComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('chartContainer') myChartContainer: ElementRef | any;
  @Input() public data: any;
  @Input() yLabel: any;
  @Input() header: any;
  @Input() text: any;
  @Input() meterNumber: any;
  @Input() parentElement: any;
  chartLegend: ChartConsumption[] = [] as ChartConsumption[];
  subscriptions: Subscription = new Subscription();
  daysData: any;
  selectedId = 0;
  private w: number = screen.width - (screen.width * 11) / 100;
  private h: number = 260;
  private margin = { top: 10, right: 50, bottom: 100, left: 60 };
  private width = this.w - this.margin.left - this.margin.right;
  private height = this.h - this.margin.top - this.margin.bottom;
  private divWidth = 0;
  private divHeight = 0;
  numberTicksY: any = 7;
  xAxisLabels: any;
  formattedData: any = [];
  areasChart: any;
  area: any;
  finalData: any = [];
  selectedIndex = 2;
  activeIndex: any = this.selectedIndex;
  url: any;
  fileName: string = '';
  svg: any;
  id: any;
  errorMessage$ = new Subject<boolean>();
  isDefaultChange = false;
  constructor(private container: ElementRef,
    private renderer: Renderer2,
    private systemDesignService: SystemDesignService,
    private authService: AuthService,
    private sysresultsService: SysresultsService) { }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
    this.initData().then((data: any) => {
      this.daysData = [...Object.values(this.data?.dayAverage), { label: "Custom", value: null }];
      // for (let index = 0; index < Object.values(data?.dayAverage).length; index++) {
      //   this.drawChart(index)
      // }
    });
  }

  ngAfterViewChecked() {
    this.initChart()
  }

  initChart() {
    if ((this.myChartContainer.nativeElement.offsetHeight != this.divHeight || this.divWidth != this.myChartContainer.nativeElement.offsetWidth)) {
      this.divWidth = this.myChartContainer.nativeElement.offsetWidth;
      this.divHeight = this.myChartContainer.nativeElement.offsetHeight;
      let tempHeight = this.divHeight;
      if (this.divHeight < 400) {
        tempHeight = 400;
      }
      const parentHeight = this.parentElement.offsetHeight;
      if (parentHeight * .92 < tempHeight) {
        tempHeight = parentHeight * .92;
      }
      this.w = this.divWidth;
      this.h = tempHeight;
      this.width = this.w - this.margin.left - this.margin.right;
      this.height = this.h - this.margin.top - this.margin.bottom;
      /* Median Solar Generation as a default option */
      if(!this.isDefaultChange) this.selectedId = this.daysData?.findIndex((item: any) => item?.label?.toLowerCase() === 'median solar generation');
      this.selectedId === this.daysData.length - 1 ? this.drawCustomDateChart(this.selectedId) : this.drawChart(this.selectedId);
    }
  }

  initData(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.data) {
        resolve(this.data);
      } else {
        let timer: any = null;

        timer = setInterval(() => {
          if (this.data) {
            if (timer) {
              clearInterval(timer);
              resolve(this.data);
            }
          }
        }, 300);
      }
    });
  }

  drawCustomDateChart(val: number) {
    this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
    this.d3DayAverageRender(this.daysData[val]?.chartData);
    this.id = `${this.data.id}-custom`;
  }

  drawChart(val: number) {
    this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
    if (this.data?.dayAverage) {
      const date = this.daysData[val]?.value === 'N/A' ? 'typical day' : this.daysData[val]?.value;
      this.fileName = `${this.data.id}_Load Analysis_${this.daysData[val]?.label}`;
      this.d3DayAverageRender(this.daysData[val]?.chartData);
      this.id = `${this.data.id}-${date.replace(/\s/g, '')}`;
    }
  }

  dateChange(selectedDate: any) {
    this.errorMessage$.next(false);
    this.selectedId = this.daysData.findIndex((d: any) => d.label === selectedDate.dayWith?.label);
    this.isDefaultChange = true;
    if ((!!selectedDate?.dayWith?.value || selectedDate?.dayWith?.value === 'N/A')) {
      this.drawChart(this.selectedId);
    } else {
      // custom date change
      const payload = {
        meter: `${this.meterNumber}`, date: +new Date(selectedDate?.date),
        crn: localStorage.getItem('crn'), qrn: localStorage.getItem('qrn')
      } as CustomDatePayload;
      this.subscriptions.add(
        this.sysresultsService.getCustomDateData(payload)
          .subscribe((response: any) => {
            if (isSuccess(response.status) && response?.data?.length) {
              this.daysData[this.selectedId] = {
                ...this.daysData[this.selectedId],
                chartData: response?.data
              }
              this.daysData = [...this.daysData];
              this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
              this.fileName = `${this.data.id}_Load Analysis_Custom`;
              this.d3DayAverageRender(response?.data);
              this.id = `${this.data.id}-${this.daysData[this.selectedId]?.label?.toLowerCase()}`;
            } else {
              this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
              console.error('Custom date: ', response.error)
              this.errorMessage$.next(true);
            }
          }))
    }
  }

  d3DayAverageRender(data: any) {
    const that = this;
    this.chartLegend = [...data.map((item: any) => ({
      name: SolorToNewSolar[item.id],
      value: `${item?.total.toFixed(1)} kWh`,
      color: SolorToColor[item.id],
      order: SolorToId[item.id]
    } as ChartConsumption)).sort((a: any, b: any) => a.order - b.order)];
    d3.selectAll('.nodes').on('mouseout', null);
    d3.selectAll('.nodes').on('mouseover', null);
    d3.selectAll('.nodes').on('mousemove', null);

    // $('.multiline-tooltip5').remove();
    // $('.multiline-tooltip3').remove();
    // var tooltip = d3.select("body").append("div").attr('class', this.chartLegend.length === 5 ? 'multiline-tooltip5' : 'multiline-tooltip3');

    //  var margin = this.margin,
    let width = +this.w;
    let height = +this.h;
    this.width = width
    this.height = height;
    const tempHeight = Number(height);// - Number(this.margin.top) -Number(this.margin.bottom)
    const svg = d3
      .select(this.container.nativeElement)
      .select('.chart-container-mixed')
      .append('svg')
      .attr('width', width + 20)
      .attr('height', tempHeight + 10);
    width = +svg.attr('width') - (+this.margin.left) - (+this.margin.right),
      height = (+svg.attr('height') - 20) - (+this.margin.top) - (+this.margin.bottom);
    const g = svg
      .append('g')
      .attr('class', 'first')
      .attr('transform', `translate(${+this.margin.left},${this.margin.top})`);
    const color = d3
      .scaleOrdinal()
      .domain([
        'Pre-Solar',
        'Solar Generation',
        'Charge',
        'Discharge',
        'Post-Solar',
      ])
      .range([
        'rgba(175,176,178, 0.7)',
        'rgba(255,214,29, 0.65)',
        'rgba(0,169,196, 0.55)',
        'rgba(74,207,144, 0.60)',
        'rgba(0,0,0, 0.0)',
      ]);
    const colorForLine = d3
      .scaleOrdinal()
      .domain([
        'Pre-Solar',
        'Solar Generation',
        'Charge',
        'Discharge',
        'Post-Solar',
      ])
      .range([
        'rgba(175,176,178, 0)',
        'rgba(255,214,29, 0)',
        'rgba(0,169,196, 0)',
        'rgba(74,207,144, 0)',
        'rgba(0, 0, 0, 1)',
      ]);
    const colorForLegend = d3
      .scaleOrdinal()
      .domain([
        'Pre-Solar',
        'Solar Generation',
        'Charge',
        'Discharge',
        'Post-Solar',
      ])
      .range([
        'rgba(175,176,178, 0.7)',
        'rgba(255,214,29, 0.65)',
        'rgba(0,169,196, 0.55)',
        'rgba(74,207,144, 0.60)',
        'rgba(0, 0, 0, 1)',
      ]);
    const yValue = (d: any) => d['consumption'];
    let values: any = [];
    let sources: any = data;
    sources.forEach(function (item: any) {
      values = values.concat(item.value);
    });
    let ymin: any = d3.min(values, (d) => yValue(d));
    ymin = ymin > 0 ? Math.ceil(ymin) : Math.floor(ymin);
    // ymin = ymin % 2==0? ymin : ymin - 1;
    let ymax: any = d3.max(values, (d) => yValue(d));
    ymax = ymax > 0 ? Math.ceil(ymax) : Math.floor(ymax);
    // ymax = ymax % 2==0? ymax : ymax + 1;
    const zeroPosition = (height / (ymax - ymin)) * ymax;
    const x = d3.scaleLinear().range([0, width]).domain([0, 23]),
      y = d3.scaleLinear().range([height, 0]).domain([ymin, ymax]),
      z = color,
      lineZ = colorForLine,
      lagendColor = colorForLegend;
    const area = d3
      .area()
      .curve(d3.curveBasis)
      .x1(function (d) {
        return x(d['time']);
      })
      .y1(function (d) {
        return y(d['consumption']);
      })
      .x0(function (d) {
        return x(d['time']);
      })
      .y0(y(0));
    //z.domain(sources.map(function(c) { return c.id; }));
    //lineZ.domain(sources.map(function(c) { return c.id; }));
    //lagendColor.domain(sources.map(function(c) { return c.id; }));

    const lineGenerator = d3
      .line()
      .y(function (d) {
        return y(d['consumption']);
      })
      .x(function (d) {
        return x(d['time']);
      })
      .curve(d3.curveBasis);
    const xAxis = d3.axisBottom(x).ticks(24);
    const xAxisg = g
      .append('g')
      .attr('class', 'axis axis--x')
      .style('font-size', '14px')
      .style('font-family', 'Roboto')
      .attr('transform', `translate(0, ${zeroPosition})`)
      .call(xAxis);
    xAxisg
      .append('text')
      .attr('dy', '1.5em')
      .attr('y', 22)
      .attr('x', width / 2)
      .style('font-family', 'Roboto')
      .style('font-weight', 500)
      .attr('fill', '#525C76')
      .text(this.yLabel);

    // legendHeader.append("svg:image")
    //   .attr('x', 80)
    //   .attr('y', 14)
    //   .attr('width', 16)
    //   .attr('height', 16)
    //   .style('cursor', 'pointer')
    //   .attr("xlink:href", "assets/images/common/info.svg")
    //   .on("mouseover", (d) => tooltip.style("display", "block").text('Sample tooltip'))
    //   .on("mouseout", () => tooltip.style("display", "none"));

    const yAxis = d3.axisLeft(y);
    g.append('g')
      .attr('class', 'axis axis--y')
      .style('font-size', '14px')
      .style('font-family', 'Roboto')
      .call(yAxis)
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', -50)
      .attr('x', -height / 2)
      .attr('text-anchor', 'middle')
      .attr('dy', '.71em')
      .style('font-size', '14px')
      .classed('axis-title', true)
      .style('text-anchor', 'middle')
      .style(
        'font-family',
        'Roboto'
      )
      .style('fill', 'rgb(0, 0, 0)')
      .text('kW');
    const tickLabels = xAxisg.select('.tick text').remove(); // reomve first tick in xaxis
    const source = g
      .selectAll('.area')
      .data(sources)
      .enter()
      .append('g')
      .attr('class', function (d: any) {
        return `area ${d['id']}`;
      })
      .attr('id', function (d: any) {
        return `area_${d['id']}`;
      });

    source
      .append('path')
      .attr('d', function (d: any) {
        return area(d['value']);
      })
      .style('fill', function (d: any) {
        return z(d['id']) + '';
      });

    const path = g
      .selectAll('.line-path')
      .data(sources)
      .enter()
      .append('path')
      .attr('class', 'line-path')
      .attr('class', `line-path-${this.id}`)
      .attr('id', (d: any) => d['id'])
      .style('fill', 'none')
      //.style('opacity','0.0')
      .attr('d', (d: any) => lineGenerator(d['value']))
      .style('stroke-width', 4)
      .style('stroke', function (d: any) {
        return lineZ(d['id']) + '';
      });

    const newcanvas = document.createElement('canvas');
    const ctx: any = newcanvas.getContext('2d');
    ctx.font = '16px Arial,Helvetica,sans-serif';
    const lagendData = this.chartLegend.map(item => item.name);
    const spacing = lagendData.map(item => ctx.measureText(item).width + 50)
    const chartLegend = that.chartLegend;
    svg
      .append('g')
      .attr('class', 'third')
      .attr(
        'transform',
        `translate(${width / 2 + this.margin.left},${this.h - 35})`
      )
      .call(that.colorLegend, {
        lagendData,
        circleRadius: 5,
        spacing,
        textOffset: 15,
        chartLegend,
      });
    svg
      .append('text')
      .attr(
        'transform',
        `translate(${chartLegend?.length === 5 ? (width / 2 + this.margin.left) - 609 : (width / 2 + this.margin.left) - 439},${this.h - 33})`
      )
      .attr('dy', '1em')
      .attr('x', 0)
      .attr('y', 0)
      .style('font-family', 'Roboto')
      .style('font-weight', 500)
      .style('color', '#525C76')
      .style('font-style', 'normal')
      .style('font-size', '13.0614px')
      .text('Day Totals');
    svg.append('svg:rect')
      .attr(
        'transform',
        `translate(${width / 2 + this.margin.left},${this.h - 18})`
      )
      .attr('width', this.chartLegend?.length === 5 ? 1000 : 700)
      .attr('x', this.chartLegend?.length === 5 ? -520.5 : -350.5)
      .attr('height', 48)
      .attr('y', "-30")
      .attr('rx', "5")
      .attr('fill', 'transparent')
      .attr('pointer-events', 'all')
      .attr('stroke', '#D1D1D1')
      .attr('stroke-width', 1)
      .style('border', '1.12676px solid #E2E4E8')
    // this.createDefs(svg.append('svg:defs'));
    const mouseG = svg
      .append('g')
      .attr('transform', `translate(${this.margin.left},${this.margin.top})`)
      .attr('class', 'mouse-over-effects');
    mouseG
      .append('path') // this is the black vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', 'black')
      .style('stroke-width', '1px')
      .style('opacity', '0');
    const lines = document.getElementsByClassName(
     `line-path-${this.id}`
    ) as HTMLCollectionOf<SVGPathElement>;
    const mousePerLine = mouseG
      .selectAll('.mouse-per-line')
      .data(sources)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');
    mousePerLine
      .append('circle')
      .attr('r', 7)
      .style('stroke', function (d: any) {
        return lagendColor(d['id']) + '';
      })
      .style('fill', function (d: any) {
        return lagendColor(d['id']) + '';
      })
      .style('stroke-width', '1px')
      .style('opacity', '0')
    //.attr('filter', 'url(#dropShadow)');
    mousePerLine
      .append('path')
      .attr('fill', 'white')
      .attr('stroke', function (d: any) {
        return lagendColor(d['id']) + '';
      })
      .style('stroke-width', '2px')
      .attr('class', 'tooltip')
      //.attr("width", 80)
      //.attr("height", 30)
      .attr('d', `M2 0 H92 v30 h-90 v-10 l-5 -5l5 -5z`)
      .attr('transform', 'translate(10,-15)');
    //.attr("x", 5)
    //.attr("y", -15)
    //.attr("rx", 4)
    //.attr("ry", 4);
    let xprevepos = 0;
    mousePerLine
      .append('text')
      .attr('class', 'tooltip-date')
      .attr('transform', 'translate(15,5)');
    mouseG
      .append('svg:rect') // append a rect to catch mouse movements on canvas
      .attr('width', width > 0 ? width : 1200) // can't catch mouse events on a g element
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', function () {
        // on mouse out hide line, circles and text
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-line').style('opacity', '0');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line circle').style('opacity', '0');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line path').style('opacity', '0');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line text').style('opacity', '0');
        xprevepos = -1;
      })
      .on('mouseover', function () {
        // on mouse in show line, circles and text
        xprevepos = -1;
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-line').style('opacity', '1');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line circle').style('opacity', '1');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line path').style('opacity', '1');
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line text').style('opacity', '1');
      })
      .on('mousemove', function (event: any) {
        // mouse moving over canvas
        const invervalDiff = x(1);
        const mouse = d3.pointer(event);
        const diff = Math.abs(xprevepos - mouse[0]);
        let position = mouse[0] / invervalDiff;
        position = Math.round(position) < 0 ? 0 : Math.round(position);
        mouse[0] = x(position);
        xprevepos = mouse[0];
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-line')
          .transition()
          .ease(d3.easeQuad)
          .duration(200)
          .attr('d', function () {
            let d = 'M' + mouse[0] + ',' + height;
            d += ' ' + mouse[0] + ',' + 0;
            return d;
          });
        const ypos: any = [];
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line')
          .transition()
          .ease(d3.easeQuad)
          .duration(200)
          .attr('transform', function (d: any, i: any) {
            const xDate = x.invert(mouse[0]),
              bisect = d3.bisector(function (d: any) {
                return d['time'];
              }).right,
              idx = bisect(d['value'], xDate);
            let beginning = 0,
              end = lines[i].getTotalLength(),
              target = null,
              pos = null;
            while (true) {
              target = Math.floor((beginning + end) / 2);
              pos = lines[i].getPointAtLength(target);
              if (
                (target === end || target === beginning) &&
                pos.x !== mouse[0]
              ) {
                break;
              }
              if (pos.x > mouse[0]) {
                end = target;
              } else if (pos.x < mouse[0]) {
                beginning = target;
              } else {
                break;
              }
            }
            d3.select(this)
              .select('text')
              .text(y.invert(pos.y).toFixed(2) + ' kW');
            if (pos.x + 85 > width) {
              d3.select(this)
                .select('path')
                .attr('d', `M-112 0 H-22 v10 l5 5l-5 5 v10 h-90 z`);
              d3.select(this).select('text').attr('dx', '-6.5em');
            } else {
              d3.select(this)
                .select('path')
                .attr('d', `M2 0 H92 v30 h-90 v-10 l-5 -5l5 -5z`);
              d3.select(this).select('text').attr('dx', '0em');
            }
            ypos.push({ ind: i, y: pos.y, off: 0 });
            return `translate(${mouse[0]},${pos.y})`;
          })
          .call(function (sel) {
            ypos.sort(function (a: any, b: any) {
              return a.y - b.y;
            });
            ypos.forEach(function (p: any, i: any) {
              if (i > 0) {
                const last = ypos[i - 1].y;
                ypos[i].off = Math.max(0, last + 32 - ypos[i].y); //  Math.max(0, last + 32 - ypos[i].y)
                ypos[i].y += ypos[i].off;
              }
            });
            ypos.sort(function (a: any, b: any) {
              return a.ind - b.ind;
            });
          })
          // Use the offset to move the tip text from it's g element
          // don't want to move the circle too
          .select('text')
          .attr('transform', function (d: any, i: any) {
            return `translate (${15},${6 + ypos[i].off})`;
          });
        d3.select(`.chart-container-${that.id}`).selectAll('.mouse-per-line path').attr(
          'transform',
          function (d: any, i: any) {
            return `translate (${10},${ypos[i].off - 15})`;
          }
        );
      });

    //  this.svg=svg
  }

  colorLegend(selection: any, props: any) {
    const { lagendData, circleRadius, spacing, textOffset, chartLegend } = props;
    var totalSpace = 0;
    spacing.forEach(function (item: any) {
      totalSpace += item;
    });
    var stX = -(totalSpace / 2);
    const groups = selection.selectAll('g').data(chartLegend);
    const groupEnter = groups.enter().append('g').attr('class', 'tick');
    groupEnter.merge(groups).attr('transform', (d: any, i: any) => {
      let xpos = 0;
      for (let j = 0; j < i; j++) {
        xpos += spacing[j];
      }
      return `translate(${stX + xpos},0)`;
    });
    groups.exit().remove();
    groupEnter
      .append('circle')
      .merge(groups.select('circle'))
      .attr('r', circleRadius)
      .attr('width', 5)
      .attr('height', 5)
      .attr('id', (d: any) => d + 'l')
      .attr('fill', (d: any) => d.color)
      .on('click', function (d: any) {
        const currentOpacity = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', Number(currentOpacity) > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
      });
    groupEnter
      .append('text')
      .merge(groups.select('text'))
      .attr("x", 20)
      .attr("y", 20)
      .attr('id', (d: any) => d.order + 'lt')
      .text((d: any) => d.name)
      .attr('x', textOffset)
      .attr('y', 6)
      .style('font-size', '13.1319px')
      .style('color', '#6E6B7B')
      .style('font-family', 'Roboto')
      .style('font-style', 'normal')
      .style('font-weight', 400)
      .on('click', function (d: any) {
        const currentOpacity = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
      });

    groupEnter
      .append('text')
      .merge(groups.select('text'))
      .attr("x", 20)
      .attr("y", 40)
      .attr('id', (d: any) => d.order + 'lt1')
      .text((d: any) => d.value)
      .attr('x', textOffset)
      .attr('y', 28)
      .style('font-size', '14px')
      .style('color', '#0F1D40')
      .style('font-family', 'Roboto')
      .style('font-style', 'normal')
      .style('font-weight', 600)
      .on('click', function (d: any) {
        const currentOpacity = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', +currentOpacity > 0 ? 0.6 : 1);
      });
  }

  createDefs(defs: any) {
    const dropShadowFilter = defs
      .append('svg:filter')
      .attr('id', 'dropShadow')
      .attr('filterUnits', 'userSpaceOnUse')
      .attr('width', '250%')
      .attr('height', '250%');
    dropShadowFilter
      .append('svg:feGaussianBlur')
      .attr('in', 'SourceGraphic')
      .attr('stdDeviation', 2)
      .attr('result', 'blur-out');
    dropShadowFilter
      .append('svg:feColorMatrix')
      .attr('in', 'blur-out')
      .attr('type', 'hueRotate')
      .attr('values', 180)
      .attr('result', 'color-out');
    dropShadowFilter
      .append('svg:feOffset')
      .attr('in', 'color-out')
      .attr('dx', 0)
      .attr('dy', 0)
      .attr('result', 'the-shadow');
    dropShadowFilter
      .append('svg:feBlend')
      .attr('in', 'SourceGraphic')
      .attr('in2', 'the-shadow')
      .attr('mode', 'normal');
  }

  async downloadSVG() {
    const crn = this.authService.getItem('crn','localStorage');
    const dwnloadLink = `${Utils.getBaseurl()}consumers/getPVOffsetData?crn=${crn}_${this.meterNumber}_`;
    var a = document.createElement('a');
    a.download =  `${crn}_postSolarConData.csv`;
    a.href = dwnloadLink;
    a.click();
    /* const imageString = await this.exportSVGGraphsFromId();
      this.svgString2Image(
        imageString,
        this.width,
        this.height,
        'png',
        this.fileName
      );*/
  }

  async exportSVGGraphsFromId() {
    $('.mouse-over-effects').attr('display', 'none');
    const SVGElem: any = window.document.querySelector(
      `.chart-container-${this.id}`
    );
    const svg: any = SVGElem?.firstElementChild;
    let oDOM: any = svg?.cloneNode(true).outerHTML;
    var canvas = document.createElement('canvas');
    let context: any = canvas.getContext('2d');
    let renderedCanvas = await Canvg.from(context, oDOM);
    renderedCanvas.start();
    $('.mouse-over-effects').attr('display', 'block');
    return canvas.toDataURL('image/png');
  }

  svgString2Image(
    svgString: any,
    width: any,
    height: any,
    format: any,
    name: any
  ) {
    var format = format ? format : 'png';

    var imgsrc = svgString; //'data:image/svg+xml;base64,'+ btoa( unescape( encodeURIComponent( svgString ) ) ); // Convert SVG string to data URL

    var canvas = document.createElement('canvas');
    var context: any = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    var image = new Image();
    image.onload = function () {
      context.clearRect(0, 0, width, height);
      context.beginPath();
      context.rect(0, 0, width, height);
      context.fillStyle = 'White';
      context.fill();
      context.drawImage(image, 0, 0, width, height - 50);

      var canvasdata = canvas.toDataURL('image/png');
      var a = document.createElement('a');
      a.download = name + '.png';
      a.href = canvasdata;
      a.click();
    };

    image.src = imgsrc;
  }
}
