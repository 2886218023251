<div class="rootdiv">
    <div class="progress-bar__wrapper">
        <div class="progress-bar__engergy-info">
            <div class="progress-bar__icon me-2">
                <enact-icon [selected]="!overLimit" [exceded]="overLimit" [icon]="progressBarData.icon"></enact-icon>
            </div>
            <div class="progress-bar__right-content">
                <div class="progress-bar__right-content-text mb-2" style="position: relative;">
                    <div class="progress-bar__left-label tooltip-ellipses">
                        <div class="word-ellips">{{'design.leftPanel.inverter.'+ progressBarData.addedCapacityLabel | translate }}</div>
                        <span class="tooltiptext tooltip-top">{{'design.leftPanel.inverter.'+ progressBarData.addedCapacityLabel | translate }}</span>
                        <span class="progress-bar__bold-text" [ngClass]="overLimit ? 'over-limit__color' : 'normal__color'">
                            {{progressBarData.addedCapacity | numberLocaleFormat : 2 :0}}
                            {{progressBarData.capacityUnit }}
                        </span>
                    </div>
                    <div class="progress-bar__right-label tooltip-ellipses">
                        <div class="word-ellips">{{'design.leftPanel.inverter.'+ progressBarData.targetCapacityLabel | translate }}</div>
                        <span class="tooltiptext tooltip-top">{{'design.leftPanel.inverter.'+ progressBarData.targetCapacityLabel |
                            translate }}</span>
                        <!-- <br/> -->
                        <span class="progress-bar__bold-text">
                            {{progressBarData.targetCapacity | numberLocaleFormat : 2 : 0 }}
                            {{progressBarData.capacityUnit}}
                        </span>
                    </div>
                </div>
                <div class="progress-bar__chart mb-2">
                    <div class="progress-bar__bar">
                        <div class="progress-bar__progress progress-bar__bold-text" [ngClass]="overLimit ? 'over-limit__bg-color' : 'normal__bg-color'"
                        [ngStyle]="{'width':barProgressLength+'%'}"></div>
                    </div>
                    <div class="progress-bar__line" *ngIf="!progressBarData.hideIndicator" [ngStyle]="{'left':lineLength+'%'}"></div>
                </div>
                <div class="progress-bar__capacity-diff"  *ngIf="!progressBarData.hideDiffrenceText">
                    <span class="progress-bar__bold-text">{{capacityDiff | numberLocaleFormat : 2 : 0 }} {{progressBarData.capacityUnit}}</span> {{'design.leftPanel.inverter.' + barStatus | translate }}
                </div>
            </div>
        </div>
    </div>
</div>