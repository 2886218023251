import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { RouterModule } from '@angular/router';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularTreeGridModule } from 'angular-tree-grid';
import { AgmCoreModule } from '@agm/core';
import { CustomCurrencyPipe } from '../../helpers/pipes/custom-currency.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomDatePipe } from '../../helpers/pipes/custom-date.pipe';
import { ProfileComponent } from './profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { AccessControlDirective } from '../../helpers/directives/access-control.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSortModule } from '@angular/material/sort';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { StackedChartComponent } from './charts/stacked-chart/stacked-chart.component';
import { ChartsModule } from 'ng2-charts';
import { SystemResultGraphsComponent } from '../system-design/sysresults/graphs/system-result-graphs/system-result-graphs.component';
import { AreaChartComponent } from './charts/area-chart/area-chart.component';
import { MixedChartComponent } from './charts/mixed-chart/mixed-chart.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { ConfigureModalComponent } from './header/configure-modal/configure-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PieChartComponent } from './charts/pie-chart/pie-chart.component';

import { DashboardLineChartComponent } from './charts/dashboard-line-chart/dashboard-line-chart.component';
import { CalculateYearTax } from 'src/app/helpers/pipes/calculate.pipe';
import { ProjectListDialogComponent } from './project-list-dialog/project-list-dialog-component';
import { TableComponent } from './table/table.component';
import { DynamicDatePickerComponent } from './dynamic-date/dynamic-date-picker.component';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultilineChartComponent } from './charts/multiline-chart/multiline-chart.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';
import { StackedGraphV2Component } from '../projects/project-details/stacked-graph-v2/stacked-graph-v2.component';
import { NoRightClickDirective } from 'src/app/helpers/directives/no-right-click.directive';
import { NumberDirective } from './directives/numbers-only.directive';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TranslateModule } from '@ngx-translate/core';
import { NumberLocaleFormatPipe } from 'src/app/helpers/pipes/number-format.pipe';
import { LocalizedDatePipe } from 'src/app/helpers/pipes/localized-date.pipe';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TeamMembersComponent,
    CustomCurrencyPipe,
    CustomDatePipe,
    ProfileComponent,
    LoaderComponent,
    AccessControlDirective,
    ConfirmationDialogComponent,
    LineChartComponent,
    StackedChartComponent,
    SystemResultGraphsComponent,
    AreaChartComponent,
    MixedChartComponent,
    ConfigureModalComponent,
    PieChartComponent,
    DashboardLineChartComponent,
    CalculateYearTax,
    ProjectListDialogComponent,
    TableComponent,
    DynamicDatePickerComponent,
    MultilineChartComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    StackedGraphV2Component,
    NoRightClickDirective,
    NumberDirective,
    AutocompleteComponent,
    NumberLocaleFormatPipe,
    LocalizedDatePipe
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    TeamMembersComponent,
    LoaderComponent,
    MatTreeModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSnackBarModule,
    AngularTreeGridModule,
    AgmCoreModule,
    CustomCurrencyPipe,
    CustomDatePipe,
    DragDropModule,
    AccessControlDirective,
    LineChartComponent,
    StackedChartComponent,
    AreaChartComponent,
    MatButtonToggleModule,
    ChartsModule,
    PieChartComponent,
    DashboardLineChartComponent,
    CalculateYearTax,
    ProjectListDialogComponent,
    TableComponent,
    DynamicDatePickerComponent,
    MultilineChartComponent,
    CustomTooltipComponent,
    CustomTooltipDirective,
    StackedGraphV2Component,
    NoRightClickDirective,
    NumberDirective,
    AutocompleteComponent,
    NumberLocaleFormatPipe,
    LocalizedDatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTreeModule,
    MatIconModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTabsModule,
    MatSnackBarModule,
    DragDropModule,
    MatButtonToggleModule,
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB-Ah8gbg2YgiQRD5SKMExUIE-FFjqzmnc',
      libraries: ['places'],
    }),
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    DropdownModule,
    CalendarModule,
    PortalModule,
    TranslateModule
  ],
  providers: [PieChartComponent, DashboardLineChartComponent],
})
export class SharedModule {}

