<ng-container *ngIf="{
  errorMessage: errorMessage$ | async
} as data">

  <div style="margin-left: 20px;">
    <div class="day-container">
      <div>
        <app-dynamic-date-picker [days]="daysData" (dateChange)="dateChange($event)" [selectedId]="selectedId">
        </app-dynamic-date-picker>
      </div>
      <div class="download-style" *ngIf="!data?.errorMessage">
        <span class="heading-style">Download<span class="download-btn" (click)="downloadSVG()"></span></span>
      </div>
    </div>
    <ng-container *ngIf="!data?.errorMessage">
      <p style="margin: 0 0 0px 15px;">{{text}} </p><span style="margin:auto; display:table;">{{header}}</span>
    </ng-container>
    <div #chartContainer class="chart-container-mixed chart-container-{{id}}" style="width: inherit; max-height: 30em;">
    </div>
    <div class="error-container" *ngIf="data?.errorMessage">
      <div class="vector-style">
        <span class="vector-icon"></span>
      </div>
      <div class="message">
        An error occurred when trying to load the graph. Please <span class="date-change"> change the date </span> and try again.</div>
    </div>
  </div>
</ng-container>
<!-- <div class="chartjs-container" *ngIf="lineChartData">
  <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
    [colors]="lineChartColors" [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
  </canvas>
</div> -->
