import { createAction, props } from '@ngrx/store';
import { Panel } from '@design/models/panel.model';

export namespace fromPanelActions {
  export const AddOne = createAction(
    '[Draw Area] add one panel',
    props<{ panel: Panel }>()
  );

  export const AddMany = createAction(
    '[Draw Area] add many panels',
    props<{ panels: Panel[] }>()
  );
  export const UpsertOne = createAction(
    '[Draw Area] upsert one panel',
    props<{ panel: Panel }>()
  );
  export const DeleteMany = createAction(
    '[Draw Area] delete many panels',
    props<{ panelIds: Panel['id'][] }>()
  );
  export const DeleteOne = createAction(
    '[Draw Area] delete one panel',
    props<{ panelId: Panel['id'] }>()
  );

  export const UpsertMany = createAction(
    '[Draw Area] upsert many Panels',
    props<{ panels: Panel[] }>()
  );

  export const MultiSelectDelete = createAction(
    '[Draw Area] Multi select delete many panels',
    props<{ panelIds: Panel['id'][] }>()
  );
  
}
