import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'enact-confirmation-box',
  templateUrl: './enact-confirmation-box.component.html',
  styleUrls: ['./enact-confirmation-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EnactConfirmationBoxComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EnactConfirmationBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onClosebtn(): void {
    this.dialogRef.close(false);
  }
}
