import { APIResponse, Status } from './api-response.model';

export interface QuoteApiRequest {
  crn: string;
  quoteType?: QuoteType;
  accountId?: string;
}
export type QuoteType = 'C' | 'R' | 'N';

export type QuoteStatus = 'Started' | 'Inprogress' | 'Completed' | 'Archived';

export enum QuoteStatuses {
  STARTED = 'Started',
  INPROGRESS = 'Inprogress',
  COMPLETED = 'Completed',
  ARCHIVED = 'Archived',
}
// Create & Update completed Quote
export interface QuoteAPIResponse {
  _id: string;
  qrn: string;
  revisionNumber?: string;
  quoteId?: string;
  crn?: string;
  quoteType?: QuoteType;
  accountId?: string;
  quoteStatus?: QuoteStatus;
  createdAt?: string;
  updatedAt?: string;
}

export const toQuote = (res: any): APIResponse<QuoteAPIResponse> => ({
  data: {
    _id: !!res?._id ? res._id : null,
    qrn: !!res?.qrn ? res.qrn : null,
    revisionNumber: !!res?.revisionNumber ? res.revisionNumber : null,
    quoteId: !!res?.quoteId ? res.quoteId : null,
    crn: !!res?.crn ? res.crn : null,
    quoteType: !!res?.quoteType ? res.quoteType : null,
    accountId: !!res?.accountId ? res.accountId : null,
    quoteStatus: !!res?.quoteStatus ? res.quoteStatus : null,
    createdAt: !!res?.createdAt ? res.createdAt : null,
    updatedAt: !!res?.updatedAt ? res.updatedAt : null,
  },
  status: Status.Success,
});

export interface DeleteQuoteAPIResponse {
  result: string;
}

export interface EditCompletedQuoteAPIRequest {
  qrn: string;
  isEditCompletedQuote: boolean;
}
