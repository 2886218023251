
import { createSelector } from '@ngrx/store';
import {  selectDesign } from '../design.selector';
import { adapter } from './edge.reducer';
import { Edge } from '@design/models/edge.model';
import { EdgeState } from './edge.state';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export namespace fromEdgeSelectors {
  export const edgeStateSelector = createSelector(
    selectDesign,
    (state) => state.edge
  );

  export const selectEdgesByIds =  (ids: Edge['id'][]) => createSelector(
    edgeStateSelector,
    (state: EdgeState,  ) => {
      const entities = selectEntities(state);
      return ids.map((id: Edge['id']) => entities[id]);
    }
  );

  export const selectedEdge = createSelector(selectDesign, (state) => {
    const selectedId = state.ui.selectedObjectId;
    if (!selectedId) {
      return null;
    }
    const entities = state.edge.entities;
    return entities[selectedId];
  });

  export const selectEdgesByStartEndVertex = (v1: Edge['startVertexId'], v2: Edge['endVertexId']) => createSelector(
    edgeStateSelector,
    (state: EdgeState,) => {
      const entities = selectEntities(state);
      let data: any = [];
      data = entities;

      for (const [key, value] of Object.entries(entities)) {
        // console.log(key, value);
        if (
          value &&
          ((value.startVertexId === v1 && value.endVertexId === v2) ||
            (value.startVertexId === v2 && value.endVertexId === v1))
        ) {
          return value;
        }
      }

      return undefined;
    }
  );

  export const allEdges = createSelector(edgeStateSelector, selectAll);

    /**
   * Assuming this method is called using roof vertexIds as parameter.
   * Return ids that do not have any associations.
   */
    export const findSafeToDeleteEdges = (ids: Edge['id'][]) =>
    createSelector(selectDesign, (state) => {
      const safeToDeleteIds: Edge['id'][] = [];
      for (const id of ids) {
        const edge = state.vertex.entities[id]
        if(edge && edge.association.length === 0)
          safeToDeleteIds.push(id)
      }
      return safeToDeleteIds;
    });
}
