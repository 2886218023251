import { RoofSection } from '@design/models/roof-section.model';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DesignState } from '../design.reducer';
import { DESIGN_STORE_KEY, selectDesign } from '../design.selector';
import { PanelArrayState } from './panel-array.state';
import { PanelArray } from '@design/models/panel-array.model';
import { adapter } from './panel-array.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

type SelectedEntitiesByIDProps = {
  id: PanelArray['id'];
  roofId:PanelArray['roofId'];
};

export namespace fromPanelArraySelectors {

  export const panelArrayStateSelector = createSelector(
    selectDesign,
    (state) => state.panelArray
  );
  export const allPanelArrays = createSelector(panelArrayStateSelector, selectAll);

 

  export const selectedPanelArray = createSelector(selectDesign, (state) => {
    const selectedId = state.ui.selectedObjectId;
    if (!selectedId) {
      return null;
    }
    const entities = state.panelArray.entities;
    return entities[selectedId];
  });

  export const selectPanelArraysByIds = (ids: PanelArray['id'][]) =>
    createSelector(panelArrayStateSelector, (state: PanelArrayState) => {
      const entities = selectEntities(state);
      return ids.map((id: PanelArray['id']) => entities[id]);
    });

  export const selectedEntitiesByID = createSelector(
    panelArrayStateSelector,
    (entities: PanelArrayState, props: SelectedEntitiesByIDProps) => {
      return entities.entities[props.id];
    }
  );
  // export const selectedPanelArrayByRoofId = createSelector(
  //   panelArrayStateSelector,
  //   (entities: PanelArrayState, props: SelectedEntitiesByIDProps) => {
  //     return entities.entities[props.roofId];
  //   }
  // );
  export const selectedPanelArrayByRoofId = (roofId: string) =>
  createSelector(allPanelArrays, (panelArray: PanelArray[]) => 
  panelArray.find(p=> p.roofId === roofId) );

  export const selectPanelArraysByRoofId = (roofId: string) =>
  createSelector(allPanelArrays, (panelArray: PanelArray[]) => 
  panelArray.filter(p=> p.roofId === roofId) );

  // allPanelArrays :: totalNumberOfPanels
  export const getTotalPanels = createSelector(
    allPanelArrays,  state => state.reduce(((acc, next) => +acc + +(next.noOfPanelEast + (next.noOfPanelWest ? next.noOfPanelWest : 0))), 0)
  );
 

}
