import { RoofSection } from '@design/models/roof-section.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DESIGN_STORE_KEY, selectDesign } from '../design.selector';
import { adapter } from './vertex.reducer';
import { VertexState } from './vertex.state';
import { Vertex } from '@design/models/vertex.model';
import { isNil } from 'lodash';
import { Vector3 } from 'three';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export namespace fromVertexSelectors {
  const edgeStateSelector = createSelector(
    selectDesign,
    (state) => state.vertex
  );

  export const allVertices = createSelector(edgeStateSelector, selectAll);

  const selectVertices = createSelector(edgeStateSelector, selectEntities);

  export const selectVerticesByIds = (ids: Vertex['id'][]) =>
    createSelector(edgeStateSelector, (state: VertexState) => {
      const entities = selectEntities(state);
      const vertices: Vertex[] = []
      ids.forEach((id: Vertex['id']) => {
        const vertex = entities[id];
        if (vertex) {
          vertices.push(vertex)
        };
      });
      return vertices;
    });

  export const selectVertexByPositon = (pos: Vector3) =>
    createSelector(edgeStateSelector, (state: VertexState) => {
      const entities = selectEntities(state);

      for (const [key, value] of Object.entries(entities)) {
        // console.log(key, value);
        if (
          value?.x === pos.x &&
          value?.y === pos.y &&
          value?.z === pos.z
        ) {
          return value;
        }
      }
      return undefined;
    });

  /**
   * Assuming this method is called using roof vertexIds as parameter.
   * Return ids that do not have any associations.
   */
  export const findSafeToDeleteVertices = (ids: Vertex['id'][]) =>
    createSelector(selectDesign, (state) => {
      const safeToDeleteIds: Vertex['id'][] = [];
      for (const id of ids) {
        const vertex = state.vertex.entities[id]
        if(vertex && vertex.association.length === 0)
          safeToDeleteIds.push(id)
      }
      return safeToDeleteIds;
    });
}
