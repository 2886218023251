export enum ShadingStatus {
    Running = 'Running',
    Complete_Success = 'Complete-Success',
    Outdated = 'Outdated'
}

export enum SimulationStatus {
    Running = 'RUNNING',
    Success = 'SUCCESS',
    Outdated = 'OUTDATED',
    Failed = 'FAILED',
    Not_Started = 'NOT-STARTED',
    //Outdated = 'Outdated'
}

export class ShadingResults {
    shadingStatus!: string;
    defaultMinThreshold!: number;
    solarAccessThreshold!: number;
    allPanelsData!: any;
    hoverPanelId!: string;
    hoveredPanelData!: any;
    panelsYearlyData!: YearlyData[];
    heatMapData!: any;
}

export interface YearlyData {
    panelId: string;
    solarData: any;
    roofId: string;
}