import { PanelArray } from "@design/models/panel-array.model";
import { Panel } from "@design/models/panel.model";
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export namespace fromPanelArrayActions {

  export const AddPanelArray = createAction(
    '[Draw Area] add panel array section',
    props<{ panelArray: PanelArray, triggerURSnapshot?: boolean }>()
  );

  export const AddManyPanelArrays = createAction(
    '[Panel Array] add panel arrays in store',
    props<{ panelArray: PanelArray[] }>()
  );
  export const UpsertMany = createAction(
    '[Panel Array] upsert many panel arrays',
    props<{ panelArray: PanelArray[] }>()
  );
  export const UpsertManyModule = createAction(
    '[Panel Array] upsert many panel arrays for module',
    props<{ panelArray: PanelArray[], triggerURSnapshot?: boolean}>()
  );
  export const UpsertManyTry = createAction(
    '[Panel Array] upsert many panel arrays Try',
    props<{ panelArray: PanelArray[], triggerURSnapshot?: boolean }>()
  );
  export const UpsertOne = createAction(
    '[Panel Array] upsert one panel array',
    props<{ panelArray: PanelArray }>()
  );

  export const SelectPanelArray = createAction(
    '[Draw Area] select panel array',
    props<{ panelArray: PanelArray }>()
  );
  // Used to send information to threejs only
  export const PanelArrayConfigurationChangeTry = createAction(
    '[Panel Array Configuration] Update panel section configuration Try',
    props<{ panelArray: PanelArray, triggerURSnapshot?: boolean }>()
  );

  export const PanelArrayConfigurationChangeSuccess = createAction(
    '[Panel Array Configuration] Update panel section configuration success',
    props<{ panelArray: PanelArray }>()
  );

  export const PanelArrayConfigurationChangeFailure = createAction(
    '[PanelArray Section Configuration] Update panel section  configuration failed',
  );

  export const PanelArrayDelete = createAction(
    '[Panel Array Configuration] Delete panel array configuration',
    props<{ panelArray: PanelArray}>()
  );

  export const PanelArrayDeleteTry = createAction(
    '[Panel Array Configuration] Delete panel array configuration Try',
    props<{ panelArray: PanelArray}>()
  );

  export const SavePanelArrays = createAction(
    '[Panel Array Configuration] Save panel array configuration');

  export const SaveHardwareDetailsOnly = createAction(
    '[Panel Array Configuration] save hardware details only',
  );

  export const SaveHardwareDetailsOnlySuccess = createAction(
    '[Panel Array Configuration] save hardware details only Success',
  );

  export const SavePanelArraysDesignSuccess = createAction(
    '[Panel Array Configuration] save panels to design Success',

  );

  export const SavePanelArraysDesignFailure = createAction(
    '[Panel Array Configuration] save panels to design failure',
    props<{ error: any }>()
  );



  export const SavePanelArraysHardwareFailure = createAction(
    '[Panel Array Configuration] save panel array to hardware failure',
    props<{ error: any }>()
  );

  export const pvWattsRequestParamChange = createAction(
    '[Panel Array Configuration] parameter for pv watts call changed'

  );
  export const DeleteAll = createAction(
    '[Panel Array Configuration] Delete all panel array configuration',

  );
  export const UpsertDegradation = createAction(
    '[Panel Array Configuration]Update all panel array degradation',

  );


}
