import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { UserPreferences } from "@api/models/user-preference.model";
import { Observable, isObservable, of, throwError } from "rxjs";
import { catchError, finalize, shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class UserPreferenceService implements OnDestroy{

  private cache = new Map<string, Observable<any>>();
  private ongoingRequests = new Map<string, Observable<any>>();
  private http = inject(HttpClient);

  getUserPreference(data:{account_id: string, email_id: string, role: string}, options: {disableCache:boolean} = {disableCache:false}): Observable<UserPreferences> {
    const urlObject = new URL(`${environment.newApiUrl}account/preference`);
    urlObject.searchParams.append('account_id', data.account_id);
    urlObject.searchParams.append('email_id', data.email_id);
    urlObject.searchParams.append('role', data.role);
    const url = urlObject.toString();
    
    const cacheKey =  url;
    const disableCache = options.disableCache || false;
    
    const cached = this.cache.get(cacheKey);
    if (!disableCache && cached && isObservable(cached)) {
      return cached as Observable<UserPreferences>;
    }

    // Check if the request is already ongoing
    const ongoing = this.ongoingRequests.get(cacheKey);
    if (ongoing && isObservable(ongoing)) {
      return this.ongoingRequests.get(cacheKey) as Observable<UserPreferences>;
    }
    
    const request = this.http.get<UserPreferences>(url).pipe(
      shareReplay(1),
      catchError((error) => {
        this.ongoingRequests.delete(url);
        return throwError(error);
      }),
      finalize(() => {this.ongoingRequests.delete(cacheKey);})
    );

    this.ongoingRequests.set(cacheKey, request);
    request.subscribe(response => {
      this.cache.set(cacheKey, of(response));
    });

    return request;  
  }

  clearCache(){
    this.cache.clear();
  }

  ngOnDestroy(){
    this.clearCache();
  }
}