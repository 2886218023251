<div class="outer-container" *ngIf="isAccountGreaterThanOne">
    <div class="" fxLayout="row" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center center">
        <div class="reset-pwd-panel">
            <div fxLayout="row">
                <div fxFlex="100" class="header-content">
                    <div class="main-logo">
                        <a href="http://www.enact-systems.com/" target="_blank">
                            <img src="assets/images/login/enact-logo-new.png" class="companyLogo" alt="logo">
                        </a>
                    </div>
                    <div class="subhead">
                        <div class="rightHead">
                            <a href="mailto:helpdesk@enact-systems.com">
                                <img src="assets/images/login/Help.svg" class="input-img" alt="">
                                <div class="support-txt">{{'account.needSupport' | translate }}?</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div> 

            <form class="resetPwdForm1" [formGroup]="accountsForm" name="updateCognitoPwdForm"
                (ngSubmit)="onSelectAcc()">

                <div fxLayout="row">
                    <div fxFlex="100" class="header-div">
                        <div class="sign-in">{{'account.yourAccnt' | translate }}</div>
                        <div class="welcom-div">{{'account.lookLikesMultipleAcc' | translate }}</div>
                    </div>
                </div>
                <div class="lower-content">
                    <div class="marginTop">
                        <label for="email" class="select-dd">{{'account.selectAnAcc' | translate }}</label>
                        <div class="form-group form-cust">
                            <mat-form-field appearance="outline">
                                <mat-select [disableOptionCentering]="true" #select1  placeholder="{{'selAcc' | translate }}" formControlName="accountId"  panelClass="testClass"
                                [ngClass]="{ 'is-invalid': submittedAcc && ac.accountId.errors }" (selectionChange)="onAccountChange($event)">
                                    <mat-select-filter placeholder="{{('buttons.search' | translate) + '...' }}" *ngIf="select1.focused" [array]="allAccounts" [displayMember]="'account_id'" (filteredReturn)="allAccountsVars =$event"></mat-select-filter>
                                    <mat-option value="">{{'account.selectAcc' | translate }}</mat-option>
                                    <mat-option *ngFor="let item of allAccountsVars" [value]="item">
                                        {{item.account_id}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="submittedAcc && ac.accountId.errors" class="invalid-feedback">
                                <span *ngIf="ac.accountId.errors?.required">{{'project.inputValid.fieldsMandatory' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group loginBtnWrapper">
                        <button type="submit" class="full-login-ct" color="primary" [disabled]="!accountsForm.get('accountId')?.value?.account_id?.trim()"
                        [class.enablebtn]="accountsForm.get('accountId')?.value?.account_id?.trim()">
                            <img src="assets/images/login/right-arrow.svg" class="input-img-ct" alt="" >
                            {{'account.continue' | translate }}
                        </button>

                        <button type="button" class="back-sign-in" color="primary" (click)="backTosignIn()">
                            {{'account.backToSignIn' | translate }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>