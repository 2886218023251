import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/index';
import { QuotesDetailsApiPut } from 'src/app/services/api/quotes/details/_qrn.put';
import { AuthService } from '../../../helpers/auth.service';
import { KeepoutVO } from './inetrfaces/keepout-vo';
import { PanelConfigVO } from './inetrfaces/panel-config-vo';
import { PanelSizeVO } from './inetrfaces/panel-size-vo';
import { PanelTypeVO } from './inetrfaces/panel-type-vo';
import { environment } from 'src/environments/environment';
import { PanelConfigurationService } from '@api/services/panel-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PanelconfigService {

  constructor(private http: HttpClient, private authService: AuthService,
    private quoteApi: QuotesDetailsApiPut, private panelConfigurationService: PanelConfigurationService
  ) { }

  getSavedModules(accountId: any, emailId: any): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/module/selected-v1`, { params });
  }
  getLocaleToTiltAngels(): Observable<Array<PanelTypeVO>> {
    return this.http.get<Array<PanelTypeVO>>('assets/json/localeToSolarTiltAngel.json');
  }
  getPanelTypes(): Observable<Array<PanelTypeVO>> {
    return this.http.get<Array<PanelTypeVO>>('assets/json/panelTypes.json');
  }
 
  getRoofTiltInchSizes(): Observable<Array<any>> {
    return this.http.get<Array<any>>('assets/json/roofTilt.json');
  }
  getPanelSizes(): Observable<Array<PanelSizeVO>> {
    return this.http.get<Array<PanelSizeVO>>('assets/json/panelSizes.json');
  }
  getDrawnPanels(): Observable<Array<PanelConfigVO>> {
    const apiUrl = `${environment.newApiUrl}panelgroups`;
    return this.http.get<Array<PanelConfigVO>>(apiUrl);
  }
  getDrawnKeepouts(): Observable<Array<KeepoutVO>> {
    const apiUrl = `${environment.newApiUrl}keepouts`;
    return this.http.get<Array<KeepoutVO>>(apiUrl);
  }
  getShading(obj: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = JSON.stringify(obj), path = 'nact-shading/shading/getShading';
    let apiUrl = `${environment.apiUrl}${path}`;
    //uncomment following line to runshading on local
    //return this.http.post('http://newdemo.enact-systems.com/nact-shading/shading/getShading', body, { headers });
    //comment this line to run on local server
    return this.http.post(apiUrl, body, { headers });

  }
  updateQuoteV2(quoteData: any) {
    return this.quoteApi.updateAll(quoteData, false, true, true);

  }
  getRackingTypes(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/racking/selected`, { params });
  }
  getMonitoringSystems(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/monitoring/selected`, { params });
  }
  getRackingSystem() {
    const params = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.panelConfigurationService.getRackingType(params);
  }
}
