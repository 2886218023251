import { round } from 'lodash';
import { APIResponse, Status } from './api-response.model';
import { QuoteStatus } from './quote.model';

export interface QuoteList {
  total: number;
  quotes: QuoteDetail[];
}

export interface QuoteDetail {
  revisionNumber: number;
  qrn: string;
  crn: string;
  updatedAt: string;
  quoteStatus: QuoteStatus;
  quoteId: string;
  solarGenerationAnnual: string;
  utilityBillReplacement: string;
  inverter: InverterConfig[];
  quantity: string;
  name: string;
  modelNumber: string;
  numberOFBattery: number;
  manufacturerName: string;
  batteryModelNumber: string;
  profile?: string;
  notes?: string;
  accountId?: string;
  cashPurchaseAmount?: string;
  pvSysytemSizeDc?: string;
  status?: string;
}

export interface InverterConfig {
  model: string;
  manufacturer: string;
  quantity: number;
}

export const toQuoteList = (res: any): APIResponse<QuoteList> => ({
  data: {
    total: res?.total,
    quotes: res?.quotes?.length
      ? res.quotes.map((d: any) => ({
        revisionNumber: d?.revisionNumber,
        qrn: d?.qrn,
        crn: d?.crn,
        updatedAt: d?.updatedAt,
        quoteStatus: d?.quoteStatus,
        quoteId: d?.quoteId,
        solarGenerationAnnual: !d?.systemResults?.solarGenerationAnnual ? d?.systemResults?.solarGenerationAnnual : round(d?.systemResults?.solarGenerationAnnual),
        utilityBillReplacement: d?.systemResults?.utilityBillReplacement,
        inverter: d?.hardware?.inverter?.configured?.length
          ? d?.hardware?.inverter?.configured
          : [],
        quantity: d?.hardware?.panel?.quantity,
        name: d?.hardware?.panel?.name,
        modelNumber: d?.hardware?.panel?.modelNumber,
        numberOFBattery: d?.hardware?.battery?.numberOFBattery,
        manufacturerName: d?.hardware?.battery?.manufacturerName,
        batteryModelNumber: d?.hardware?.battery?.batteryModelnumber,
        profile: d?.finance?.costData?.profile,
        accountId: d?.accountId,
        notes: d?.notes,
        cashPurchaseAmount: d?.finance?.capitalCost?.systemCost?.total,
        pvSysytemSizeDc: d?.systemResults?.pvSystemSizeDC,
        status: d?.status,
      }))
      : [],
  },
  status: Status.Success,
});

export interface QuoteListAPIRequest {
  crn: string;
  page?: number;
  size?: number;
  localeId?: string;
  quoteStatus: QuoteStatus;
}
