<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
  integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
<nav class="navbar navbar-light"></nav>
<div class="container">
  <div class="row">
    <div class="col-md-6 col-sm-6">
      <h3 class="modal-title" style="font-weight: 200">{{'project.engageAdm.engSetting' | translate }}</h3>
    </div>
    <div class="col-md-6 col-sm-6">
      <button id="backToLeadPage" style="float: right" class="btn btn-primary pull-right btn-xs" (click)="back()"
        class="btn btn-primary">
        <i class="fa fa-arrow-left"> </i>&nbsp;{{'buttons.back' | translate | titlecase }}
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12">
      <form [formGroup]="projectForm" (ngSubmit)="onFormSubmit()">
        <div class="" formGroupName="project" style="border: 1px solid #c8c8c8;background-color: white;height: 30vh;">
          <section class="project-panel">
            <div style="background-color: #f4f4f4;border-bottom: 1px solid #c8c8c8;height: 45px;">
              <div>
                <span class="projectDetails">{{'project.engageAdm.prDet' | translate }}</span>
                <button type="button" class="btn btn-primary" id="seeDetails" data-toggle="modal"
                  data-target="#quoteModal" [disabled]="(selectedQuote | json) === ({} | json)"
                  style="margin-top: 3px; margin-right: 20px; float: right">
                  {{'project.engageAdm.showDets' | translate }}
                </button>
              </div>
            </div>
            <span id="currency" data-i18n="CURRENCY_SYMBOL" style="display: none"></span>
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="row">
                  <div class="col-md-4 col-sm-4 pt-3 pl-4">
                    <strong>{{'project.engageAdm.siteName' | translate }} :</strong>
                  </div>
                  <div class="col-md-8 col-sm-8 pt-2">
                    <input type="text" id="siteName" name="siteName" placeholder="{{'project.engageAdm.siteName' | translate }}" readonly
                      class="form-control" formControlName="siteName"
                      [ngClass]="{'is-invalid':submitted &&projectForm.get('project.siteName').errors}" />

                    <div *ngIf="submitted && projectForm.get('project.siteName').errors" class="invalid-feedback">
                      <div *ngIf="
                          projectForm.get('project.siteName').errors.required
                        ">
                        {{'project.engageAdm.siteNameReq' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="row">
                  <div class="col-md-4 col-sm-4 pt-3">
                    <strong>{{'project.engageAdm.sysType' | translate }} :</strong>
                  </div>
                  <div class="col-md-8 col-sm-8 pt-2 pr-4">
                    <!-- <p-dropdown
                      name="systemType"
                      [options]="systemTypes"
                      optionLabel="label"
                      class="dropDownContent"
                      [style]="{ width: '100%' }"
                      placeholder="Select type"
                      formControlName="systemType"
                      (onChange)="selectSystemType()"
                      [ngClass]="{
                        'is-invalid':
                          submitted &&
                          projectForm.get('project.systemType').errors
                      }"
                    >
                    </p-dropdown> -->
                    <select name="" id="" formControlName="systemType"
                      [ngClass]="{ 'is-invalid': submitted && projectForm.get('project.systemType').errors }"
                      (change)="selectSystemType()" class="dropDownContent form-control" [style]="{ width: '100%' }"
                      [(ngModel)]="selectedSystemType">
                      <option value="select">{{'project.engageAdm.selectSysType' | translate }}</option>
                      <option *ngFor="let item of systemTypes" value="{{ item.label }}">
                        {{ item.label }}
                      </option>
                    </select>
                    <i class="fa fa-chevron-down"></i>  <!-- chev icon  -->
                    <div *ngIf=" submitted && projectForm.get('project.systemType')?.errors " class="invalid-feedback">
                      <div *ngIf="projectForm.get('project.systemType')?.errors.required">
                        {{'project.engageAdm.sysTypeReq' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="row" style="height: auto">
                  <div class="col-md-4 col-sm-4 pt-3 pl-4">
                    <strong>{{'project.engageAdm.sysStartDt' | translate }} :</strong>
                  </div>
                  <div class="col-md-8 pt-2">
                    <div (click)="picker.open()"style="position: relative;">
                      <input [max]="maxDate" matInput readonly [matDatepicker]="picker" formControlName="siteStartDate"
                        class="date" id="siteStartDate" name="siteStartDate" [ngClass]="{ 'is-invalid': submitted && projectForm.get('project.siteStartDate')?.errors }" placeholder="{{'project.engageAdm.selectDt' | translate }}" />
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      
                    </div>

                    <div *ngIf=" submitted && projectForm.get('project.siteStartDate')?.errors " class="invalid-feedback">
                      <div *ngIf="projectForm.get('project.siteStartDate')?.errors.required">
                        {{'project.engageAdm.siteDtReq' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-sm-6">
                <div class="row">
                  <div class="col-md-4 col-sm-4 pt-3">
                    <strong>{{'project.engageAdm.asBuiltConfig' | translate }} :</strong>
                  </div>
                  <div class="col-md-8 col-sm-8 pr-4 pt-2">
                    
                    <select class="form-control" placeholder="Select quote" formControlName="qrn"
                      [ngClass]="{'is-invalid':submitted && projectForm.get('project.qrn')?.errors}"
                      (change)="selectQuote()">
                      <option value="">{{'project.engageAdm.selectQuote' | translate }}</option>
                      <option class="quoteOptionList" *ngFor="let item of selectedQuotes" value="{{item.label}}">
                        {{item?.label}}</option>
                    </select>
                    <i class="fa fa-chevron-down"></i>  <!-- chev icon  -->
                    <div *ngIf="submitted && projectForm.get('project.qrn')?.errors" class="invalid-feedback">
                      <div *ngIf="projectForm.get('project.qrn')?.errors.required">
                        {{'project.engageAdm.asBCReq' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-top10">
              <div class="col-md-6 col-sm-6">
                <div class="row">
                  <div class="col-md-4 col-sm-4 pt-3 pl-4">
                    <strong>{{'project.engageAdm.latLng' | translate }} :</strong>
                  </div>
                  <div class="col-md-4 col-sm-4 pt-2">
                    <input style="width: 150px" type="number" id="latitude" name="latitude" placeholder="{{'project.lat' | translate }}"
                      class="form-control" formControlName="latitude" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          projectForm.get('project.latitude')?.errors
                      }" />
                    <div *ngIf="
                        submitted && projectForm.get('project.latitude')?.errors
                      " class="invalid-feedback">
                      <div *ngIf="
                          projectForm.get('project.latitude')?.errors.required
                        ">
                        {{'project.engageAdm.latReq' | translate }}
                      </div>
                      <div *ngIf="
                          projectForm.get('project.latitude')?.errors.pattern
                        ">
                        {{'project.engageAdm.latInvalid' | translate }}
                      </div>
                      <div *ngIf="
                          projectForm.get('project.latitude')?.errors.maxlength
                        ">
                        {{'project.engageAdm.latTooLarge' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-4 pt-2">
                    <input style="width: 150px; margin-left: 6%" type="number" id="longitude" name="longitude"
                      placeholder="{{'project.long' | translate }}" class="form-control" formControlName="longitude" [ngClass]="{
                        'is-invalid':
                          submitted &&
                          projectForm.get('project.longitude')?.errors
                      }" />
                    <div *ngIf="
                        submitted &&
                        projectForm.get('project.longitude')?.errors
                      " class="invalid-feedback">
                      <div *ngIf="
                          projectForm.get('project.longitude')?.errors.required
                        ">
                        {{'project.engageAdm.longReq' | translate }}
                      </div>
                      <div *ngIf="
                          projectForm.get('project.longitude')?.errors.pattern
                        ">
                        {{'project.engageAdm.longInvalid' | translate }}
                      </div>
                      <div *ngIf="
                          projectForm.get('project.longitude')?.errors.maxlength
                        ">
                        {{'project.engageAdm.longTooLarge' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class="mt-4" formGroupName="users" style="
            border: 1px solid #c8c8c8;
            background-color: white;
            width: 100%;
            min-height: 16vh;
          ">
          <section>
            <div class="head" style="
                background-color: #f4f4f4;
                border-bottom: 1px solid #c8c8c8;
                height: 45px;
                padding-top: 1%;
                padding-left: 1%;
              ">
              <div>
                <div style="font-weight: 700; height: fit-content">{{'project.engageAdm.users' | translate }}</div>
              </div>
            </div>
            <form [formGroup]="emailForm" #formDirective="ngForm" (ngSubmit)="addUsers(formDirective)">
              <div class="row margin-top10">
                <div class="col-md-5 col-sm-5" *ngFor="let fields of identityObj?.fields">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 pt-3 pl-4">
                      <strong>{{fields.label | translate }} :</strong>
                    </div>
                    <div class="col-md-8 col-sm-8 pt-2" *ngIf="fields?.type != 'Enum'">
                      <input type="{{ fields?.type }}=='email'?email:text" id="{{ fields?.name }}"
                        name="{{ fields?.name }}" placeholder="{{ fields?.label | translate }}" (focusout)="trimSpaces()" class="form-control"
                        formControlName="{{ fields?.name }}"
                        [ngClass]="{'is-invalid':emailSubmitted &&emailForm.get(fields?.name)?.errors &&fields?.type == 'email'}" />
                      <div *ngIf="
                          emailSubmitted &&
                          emailForm.get(fields?.name)?.errors &&
                          fields?.type == 'email'
                        " class="invalid-feedback">
                        <div *ngIf="emailForm.get(fields?.name)?.errors.required">
                          {{'project.engageAdm.emailReq' | translate }}
                        </div>
                        <div *ngIf="emailForm.get(fields?.name)?.errors.pattern">
                         {{'project.engageAdm.emailInvalid' | translate }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-8 pt-2" *ngIf="fields?.type == 'Enum'">
                      <select id="" name="{{ fields?.name }}" optionLabel="label" [style]="{ width: '100%' }"
                        placeholder=" Select type" formControlName="{{ fields?.name }}" [ngClass]="{ 'is-invalid': emailSubmitted && emailForm.get(fields?.name)?.errors }" class="selectType form-control">
                        <option value="">{{'project.engageAdm.selectUsType' | translate }}</option>
                        <option value="{{item.value}}" *ngFor="let item of fields?.values">
                          {{ item.label }}
                        </option>
                      </select>
                      <i class="fa fa-chevron-down"></i>  <!-- chev icon  -->

                      <div *ngIf="
                          emailSubmitted && emailForm.get(fields?.name)?.errors
                        " class="invalid-feedback">
                        <div *ngIf="emailForm.get(fields?.name)?.errors.required">
                          {{'project.engageAdm.userTypeReq' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-5 col-sm-5" *ngIf="userIdentityObj?.label == 'Email Id'">
                  <div class="row">
                    <div class="col-md-4 col-sm-4 pt-3">
                      <strong>{{'project.engageAdm.usType' | translate }} :</strong>
                    </div>
                    <div class="col-md-8 col-sm-8">
                      
                      <select id="" optionLabel="label" [style]="{ width: '100%' }" placeholder="Select type"
                        formControlName="userType" [ngClass]="{
                          'is-invalid':
                            emailSubmitted && emailForm.get('userType')?.errors
                        }" class="selectType" name="userType">
                        <option style="display: none">{{'project.engageAdm.selectUsType' | translate }}</option>
                        <option value="item" *ngFor="let item of userTypes">
                          {{ item }}
                        </option>
                      </select>

                      <div *ngIf="
                          emailSubmitted && emailForm.get('userType')?.errors
                        " class="invalid-feedback">
                        <div *ngIf="emailForm.get('userType')?.errors.required">
                          {{'project.engageAdm.userTypeReq' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-sm-2">
                  <button id="btnAddUser" class="btn btn-primary pull-right bold"
                    style="margin-right: 15%; margin-top: 6%">
                    {{'buttons.add' | translate }}
                  </button>
                </div>
              </div>
            </form>

            <div class="row mt-4" *ngIf="projectForm?.value?.users.length > 0">
              <div class="col-md-12 col-sm-12">
                <table class="table table-striped mt-2" style="max-width: auto; margin-bottom: 0%;">
                  <thead>
                    <ng-container *ngIf="identityObj?.title != 'CA Number'" else other>
                      <th *ngFor="let cols of cols">
                        {{ cols?.header }}
                      </th>
                    </ng-container>
                    <ng-template #other>
                      <th *ngFor="let cols of otherCols">
                        {{ cols?.header }}
                      </th>
                    </ng-template>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="
                        let userEmail of projectForm?.value?.users;
                        let i = index
                      ">
                      <tr *ngIf="userEmail?.archived != true">
                        <td>{{ userEmail?.userName }}</td>
                        <td *ngIf="
                            userEmail?.userType != null;
                            else emptyUserType
                          ">
                          {{ userEmail?.userType.label }}
                        </td>
                        <ng-template #emptyUserType>
                          <td></td>
                        </ng-template>
                        <td style="padding-left: 2%;">
                          <a (click)="removeUser(i)" title="{{'settings.userSetup.removeUs' | translate }}" class="itemDelete"><i
                              class="fa fa-trash icon-middle" style="font-size: 20px;"></i></a>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>

        <div class="mt-4" formArrayName="installers" style="border: 1px solid #c8c8c8; background-color: white">
          <section>
            <div class="head" style="
                background-color: #f4f4f4;
                border-bottom: 1px solid #c8c8c8;
                height: 45px;
                padding-top: 1%;
                padding-left: 1%;
              ">
              <div style="font-weight: 700">{{'project.engageAdm.integrationDets' | translate }}</div>
            </div>

            <div *ngFor="let emp of installers?.controls; let i = index" [formGroupName]="i">
              <div *ngIf="installers.controls[i].get('archived')?.value == false">
                <div class="row margin-top10">
                  <div class="col-md-6 col-sm-6 pt-2 pl-4">
                    <div class="row">
                      <div class="col-md-3 col-sm-3 pt-2">
                        <strong>{{'project.engageAdm.components' | translate }} :</strong>
                      </div>
                      <div class="col-md-3 col-sm-3 pt-2 check"
                        *ngFor="let component of installers.controls[i].get('componentsPerSiteType')?.value;let index = index">
                        <input *ngIf="component?.label" type="checkbox" name="component" [value]="component?.name"
                          (change)="selectIntegrationFor($event, i)" [id]="component?.name"
                          [checked]="setChecked(installers.controls[i].get('selectedComponentIds')?.value,installers.controls[i].get('componentsPerSiteType')?.value[index])" />&nbsp;
                        <label [for]="component?.name" class="permission-text">&nbsp;{{ component?.label }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-6 pt-2">
                    <div class="row">
                      <div class="col-md-4 col-sm-4 pt-2">
                        <strong>{{'project.engageAdm.intSrc' | translate }} :</strong>
                      </div>
                      <div class="col-md-8 col-sm-8 pr-4">
                        

                        <select name="integrationSource" class="form-control" formControlName="integrationSource"
                          optionLabel="label"
                          (change)="selectIntegrationSource(projectDetailModel.integrationSources[i],i)"
                          [style]="{ width: '100%' }" placeholder="Select integration source"
                          [ngClass]="{'is-invalid':submitted && installers.controls[i].get('integrationSource')?.errors}">
                          <option value="">{{'project.engageAdm.selectIntSrc' | translate }}</option>
                          <option value="{{ item.label }}"
                            *ngFor="let item of projectDetailModel.integrationSources[i]">
                            {{ item.label }}
                          </option>
                        </select>
                        <i class="fa fa-chevron-down"></i>  <!-- chev icon  -->

                        <div *ngIf="submitted &&installers.controls[i].get('integrationSource')?.errors?.required"
                          class="invalid-feedback d-block">
                          <div *ngIf="installers.controls[i].get('integrationSource')?.errors?.required">
                            {{'project.engageAdm.intSrcReq' | translate }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-sm-6 margin-top10"
                    *ngFor="let component of projectDetailModel?.fields[i];let index = index">
                    <div class="row">
                      <div class="col-md-4 col-sm-4 pt-3 pl-4">
                        <strong>{{ component?.label }} :</strong>
                      </div>
                      <div class="col-md-8 col-sm-8 pt-2 pr-4">
                        <input type="text" [id]="component?.name" [name]="component?.name"
                          [placeholder]="component?.label" class="form-control" [formControlName]="component?.name"
                          [ngClass]="{'is-invalid':submitted &&installers.controls[i].get(component?.name)?.errors}" />
                        <div *ngIf="submitted &&installers.controls[i].get(component?.name)?.errors?.required"
                          class="invalid-feedback d-block">
                          <div *ngIf="installers.controls[i].get(component?.name)?.errors?.required">
                            {{ component?.label }} is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row deleteIcon" *ngIf="i != 0">
                  <div class="col-sm-12">
                    <i class="fa fa-trash pull-right cursor-pointer deleteIcon" aria-hidden="true"
                      (click)="removeIntegration(i)" title="Delete Integration Source"></i>
                  </div>
                </div>
                <hr style="height: 0px" />
              </div>
            </div>
            <div class="row" style="margin-top: 0px;">
              <div class="col-md-12 col-sm-12">
                <button type="button" class="btn btn-primary bold" id="addMore" (click)="addMore()"
                  style="margin-right: 1%; margin-bottom: 1%">
                  {{'buttons.addMore' | translate }} +
                </button>
              </div>
            </div>
          </section>
        </div>

        <div class="mt-4" style="border: 1px solid #c8c8c8; background-color: white">
          <section>
            <div class="head" style="
                background-color: #f4f4f4;
                border-bottom: 1px solid #c8c8c8;
                height: 45px;
                padding-top: 1%;
                padding-left: 1%;
              ">
              <div style="font-weight: 700">{{'project.engageAdm.feature' | translate }}</div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-3 pt-2 pl-4"
                *ngFor="let features of projectForm?.value?.features;let i = index">
                <input type="checkbox" [name]="features?.name" [value]="features" (change)="selectFeatures(i)"
                  inputId="component" [checked]="features.selected" style="width: 15px; height: 15px" />
                <label for="ShareDocuments" class="permission-text">&nbsp;{{ features.label | translate}}</label>
              </div>
            </div>
          </section>
        </div>
        <div class="mt-3 mb-3 text-center">
          <button id="btnSubmitUser" class="btn btn-primary bold">
            {{'buttons.submit' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>