import { Injectable } from '@angular/core';
import { LRUCache } from '@design/utils/LRUCache';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export const enum KeyType {
  Height = 'height',
  NearMap = 'nearmaps',
  GoogleSolar = 'googlesolar',
  GoogleMap = 'googlemaps',
  Image = 'image',
}

export interface heightDataKeys {
  crn: string,
  //timesUsed: any
  order: any
}
@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  cache: LRUCache;
  map: any;
  queue: any = null;
  cacheSize: number = 15;
  constructor(private storage: StorageMap) {
    let mapStr = localStorage.getItem('heightCacheMap');
    let dq = localStorage.getItem('heightCacheQueue');
    if(dq){
      this.queue = JSON.parse(dq);
    }
    if(mapStr){
      this.map = JSON.parse(mapStr);
    }
    this.getCRNsFromIndexDB();
    this.cache = new LRUCache(this.cacheSize, this.queue, this.map);
  }

  getCRNsFromIndexDB() {
    this.getAllKeys().pipe(take(1)).subscribe(crn => {
      if (crn) {
        this.clearIndexDBForOlderQuotes(crn); // clear index DB for older quotes with outdated structure of indexDB data before 5th June, 2024
        if(!this.queue?.includes(crn)){
          if(this.queue?.length < this.cacheSize) {
            this.queue.push(crn);
            this.map.push([crn, 0]);
            this.cache = new LRUCache(this.cacheSize, this.queue, this.map);
          }else{
            this.deleteItem(crn);
          }
        }
      }
    });
  }

  setItem(key: string, value: any) {
    this.storage.set(key, JSON.stringify(value)).pipe(take(1)).subscribe();
  }

  getItem(key: string): Observable<any> {
    return this.storage.get(key);
  }

  hasKey(key: string): Observable<boolean> {
    return this.storage.has(key);
  }

  deleteItem(key: string) {
    this.storage.delete(key).pipe(take(1)).subscribe(() => { });
  }

  removeItem(key: string): Observable<unknown> {
    return this.storage.delete(key);
  }

  clear() {
    return this.storage.clear().pipe(take(1)).subscribe();
  }

  getAllKeys() {
    return this.storage.keys();
  }

  hasMapData(crn: string, data: any, mapType: string, key2: KeyType) {
    if (data) {
      if (data[mapType] && data[mapType][key2]) {
        this.cache.refer(crn);
        if(key2 == KeyType.Image){ // For logging purposes only
          this.cache.display();
        }
        return data[mapType][key2];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async setMapData(crn: string, data: any, mapType: string, key2: KeyType, dataToSet: any) {
    this.getCRNsFromIndexDB();
    let newDataObj: any;
    if (data) {
      newDataObj = JSON.parse(data);
      if (key2 == KeyType.Image) {
        newDataObj[mapType] = {};
        newDataObj[mapType] = { height: JSON.parse(data)[mapType]?.['height'] ? JSON.parse(data)[mapType]?.['height'] : '', image: dataToSet };
      } else {
        newDataObj[mapType] = {};
        newDataObj[mapType] = { height: dataToSet, image: JSON.parse(data)[mapType]?.['image'] ? JSON.parse(data)[mapType]?.['image'] : '' };
      }
    } else {
      newDataObj = {};
      newDataObj[mapType] = {};
      newDataObj[mapType][key2] = dataToSet;
    }
    let toDelete = this.cache.refer(crn);
    if (toDelete) {
      this.deleteItem(toDelete);
    }
    this.setItem(crn, newDataObj);
    this.cache.display(); // To log what CRNs are in indexDB
  }

  clearIndexDBForOlderQuotes(key: string) {
    this.getItem(key).pipe(take(1)).subscribe(item => {
      let parsedItem = JSON.parse(item);
      if ((item && !(KeyType.GoogleMap in parsedItem || KeyType.GoogleSolar in parsedItem || KeyType.NearMap in parsedItem || 'metadata' in parsedItem))) {
        this.clear();
        this.cache.dq = [];
        this.cache.map = new Map();
        localStorage.removeItem('heightCacheMap');
        localStorage.removeItem('heightCacheQueue');
        //localStorage.setItem('heightCacheMap', JSON.stringify(Array.from(this.cache.map.entries())))
        //localStorage.setItem('heightCacheQueue', JSON.stringify(this.cache.dq))
        console.info('IndexDB is cleared for old project with previous format of height data and no image urls')
      }
    })
  }
}
