import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '../../helpers/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { GraphImage } from './sysresults/interfaces/graph-image';
import { AuthService } from '../../helpers/auth.service';
import * as $ from 'jquery';
import { EditCompletedQuoteAPIRequest, QuoteApiRequest } from '@api/models/quote.model';
import { QuoteService } from '@api/services/quote.service';
import { PanelArrayTypeVO } from './panelconfig/inetrfaces/panel-array-type-vo';
import { QuotesDetailsApiPut, ReadParams } from 'src/app/services/api/quotes/details/_qrn.put';
import { QuotesDetailsAPI } from 'src/app/services/api/quotes/details';
import { QuotesFinanceAPI } from 'src/app/services/api/quotes/finance';
import { getHideLoaderHeaders } from 'src/app/helpers/interceptors/loader.interceptor';
import { LeadService } from '@api/services/lead.service';
import { UserPreferenceService } from '@api/services/user-preference.service';

@Injectable({
  providedIn: 'root'
})
export class SystemDesignService {
  INTERVAL_STRING = 'interval';
  ADDI_CHOICE_GOOD = 0;
  ADDI_CHOICE_CUSTOM_GEN_NOT_UPLOADED = 1;
  QUOTE_IMAGE_TYPE_BACKGROUND = 'backgroundImage';
  QUOTE_IMAGE_TYPE_HOUSE = 'quote_house_image';
  QUOTE_IMAGE_TYPE_HOUSE_UPLOAD = "quote_house_image_upload";
  private selectedBillType = '';
  private totalSystemSize = new BehaviorSubject(0);
  currentTotalSystemSize = this.totalSystemSize.asObservable();
  private static consumerObject = {};
  private static quoteObject: any = {};
  public static tanentObject: any = {};
  private activeTab = new Subject();
  currentActiveTab = this.activeTab.asObservable();
  private activePage = new BehaviorSubject('create');
  currentActivePage = this.activePage.asObservable();
  private tabToDelete = new BehaviorSubject('');
  currentTabtoDelete = this.tabToDelete.asObservable();
  private threeDData = new BehaviorSubject('');
  currentthreeDData = this.threeDData.asObservable();
  private threeDShadeData = new BehaviorSubject('');
  currentthreeDShadeData = this.threeDShadeData.asObservable();
  private createShade = new BehaviorSubject('no data');
  createShadeEvent = this.createShade.asObservable();
  private quoteHouseImg = '';
  private imageArray: Array<GraphImage> = [];
  private meteringInformation = {};
  moduleEnableState = {};
  proposalImageRequired = true;
  panelConfigUpdateDone = true;
  additionalChoiceUpdateDone = true;
  inverterUpdateDone = true;
  storageUpdateDone = true;
  numberOFGraphsNeeded: any;
  isAddChoiceLoaded = new BehaviorSubject(this.ADDI_CHOICE_GOOD);
  quoteObj: any = {};
  constructor(private cookieService: CookieService, private http: HttpClient, private utils: Utils, private authService: AuthService,
    private quoteService:QuoteService,private quoteApi: QuotesDetailsApiPut,private quotesDetailsAPI: QuotesDetailsAPI,private quotesFinanceAPI: QuotesFinanceAPI,
    private leadService: LeadService,
    private userPreferenceService: UserPreferenceService,
  ) { }
  isStorageEnabled(): any {
    if (this.selectedBillType || SystemDesignService.consumerObject['meters']) {
      if (this.selectedBillType === this.INTERVAL_STRING || SystemDesignService.consumerObject['meters']['1']['bill_type'] === 'interval') {
        return true;
      } else {
        return false;
      }
    }
  }
  setBillType(type: any): any {
    this.selectedBillType = type;
  }
  getUserPreferences(): Observable<any> {
    const params = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem('role')
    };
    return this.userPreferenceService.getUserPreference(params);
  }
  changeTabToDelete(tabtodelete: string): any {
    this.tabToDelete.next(tabtodelete);
  }
  changeActiveTab(activeTab: string): any {
    this.activeTab.next(activeTab);
  }
  set3dData(data: any) {
    this.threeDData.next(data);
  }
  set3dshadeData(data: any) {
    this.threeDShadeData.next(data);
  }
  setCreateShade(config: any) {
    this.createShade.next(config);
  }
  changeActivePage(activePage: string): any {
    this.activePage.next(activePage);
  }
  getActivePage(): any {
    return this.activePage;
  }
  changeTotalSystemSize(size: number): any {
    this.totalSystemSize.next(size);
  }

  public proposalSetupSubscription: Subscription = new Subscription();
  
  public static getGlobalConsumerObject(): any {
    return SystemDesignService.consumerObject;
  }
  public static updateGlobalConsumerObject(consumerObject: any): any {
    const that = this;
    Object.keys(consumerObject).map((key) => {
      const value = consumerObject[key];
      SystemDesignService.consumerObject[key] = value;
    });
  }
  updateMeterInGlobalConsumerObject(meterAllocationObj: any): any {
    if (!meterAllocationObj['metersAllocation'] || meterAllocationObj['metersAllocation'].length <= 0) {
      return;
    }
    const meterAllocation = meterAllocationObj['metersAllocation'];
    const rateNameArray = meterAllocationObj['postPvNameArray'];
    const rateIdArray = meterAllocationObj['postPvArray'];
    let meterAllocString = '[';
    const meterObj = SystemDesignService.consumerObject['meters'];
    for (let index = 0; index < rateNameArray.length; index++) {
      const meterId = index + 1;
      meterAllocString += meterAllocation[index];
      if (index < (rateNameArray.length - 1)) {
        meterAllocString += ',';
      }
      (SystemDesignService.consumerObject['meters'][meterId])['postPvNameArray'] = rateNameArray[index];
      (SystemDesignService.consumerObject['meters'][meterId])['postPvRate'] = rateIdArray[index];

    }
    meterAllocString += ']';
    SystemDesignService.consumerObject['metersAllocation'] = meterAllocString;

  }
  public static getGlobalQuoteObject(): any {
    return SystemDesignService.quoteObject;
  }
  checkIfInvertersAdded(): any {
    let present = false;
    if (SystemDesignService.quoteObject.inverterDetails) {
      if (SystemDesignService.quoteObject.inverterDetails.length > 0) {
        present = true;
      }
    }
    return present;
  }
  updateGlobalQuoteObject(newQuoteObject: any): any {
    const that = this;
    Object.keys(newQuoteObject).map((key) => {
      const value = newQuoteObject[key];
      SystemDesignService.quoteObject[key] = value;
    });
    this.quoteObj = { ...SystemDesignService.quoteObject }
  }
  public static setGlobalQuoteObject(quoteObject: any): any {
    SystemDesignService.quoteObject = quoteObject;
  }
  getImageArray(): any {
    return this.imageArray;
  }
  setImageArray(images: any): any {
    this.imageArray = images;
  }
  addImageToArray(images: GraphImage) {
    console.log("adding system design graph:", images.image_type)
    const index = this.imageArray.findIndex(idx => images.image_type == idx.image_type && images.meter_number == idx.meter_number)

    if (index < 0) {
      this.numberOFGraphsNeeded -= 1;
      this.imageArray.push(images);
    } else {
      const prevImg = this.imageArray[index];
      const prevImgLength = prevImg.image_string ? prevImg.image_string.length : -1;
      const currentImg = images.image_string ? images.image_string.length : -1;
      if (prevImgLength <= currentImg) {
        this.imageArray.splice(index, 1, images);
      }

    }
  }
  getMeterInformation(): any {
    return this.meteringInformation;
  }
  setMeterInformation(meterInformation: any): any {
    this.meteringInformation = meterInformation;
  }
  getTenantData(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id')
    };
    return this.http.get(`${environment.newApiUrl}account`, { params });
  }
  getConsumerData(crn: any): Observable<object> {
    return this.leadService.getLeadInfo(crn);
  }
  getQuoteHouseImage(): any {
    return this.quoteHouseImg;
  }
  public static setGlobalConsumerObject(consumerObj: any): any {
    SystemDesignService.consumerObject = consumerObj;
  }
  setQuoteHouseImage(imgDataUrl: any): any {
    this.quoteHouseImg = imgDataUrl;
  }
  updateMeter(meterAllocation: any, headers?: HttpHeaders): any {
    return this.http.post(`${environment.newApiUrl}lead/post-pv-rate`, meterAllocation, {headers});
  }
  createQuote(quoteData: any): Observable<any> {
    return this.http.post(`${environment.newApiUrl}quotes`, quoteData);
  }
  createQuoteV2(payload: QuoteApiRequest, headers?:HttpHeaders ): Observable<any> {
    return this.quoteService.createQuote(payload, headers);
  }
  fetchAndEditQuote(quoteObj: any, headers?: HttpHeaders): Observable<object> {
    const crn: any = this.authService.getItem('crn');
    return this.leadService.getLeadInfo(crn, headers);
  }
  editCompletedQuote(quoteObj: any): any {
    const quoteData: any = {
      quote_id: quoteObj.quote_id,
      revision_number: quoteObj.revision_number,
      crn: quoteObj.crn,
      account_id: quoteObj.account_id
    }
    const headers = getHideLoaderHeaders();
    return this.http.put(`${environment.newApiUrl}quotes/completed`, quoteData, {headers});
  }

  getTotalnumberOfPanels(panelDetal: any): any {
    let totalNumberOfpanels = 0;
    panelDetal.forEach((panelDetail: any) => {
      const numberOfPanel = Number(panelDetail.split(',')[0].replace('{', ''));
      totalNumberOfpanels += numberOfPanel;
    });
    return totalNumberOfpanels;
  }
  updateQuote(quoteData: any): any {
    // const qrn = this.authService.getItem('qrn', 'localStorage');
    return this.quoteApi.updateAll(quoteData, true, false, false);
    // return this.http.put(`${environment.newApiUrl}quotes/details/` + qrn, quoteData);
  }
  updateQuoteV2(quoteData: any): any {
    const qrn = this.authService.getItem('qrn', 'localStorage');
    return this.http.put(`${environment.newApiUrl}quotes/details/` + qrn, quoteData);
  }
 
  updateQuoteBackgroundImage(quoteData: any) {
    const path = `quotes/saveBackgroundImage/${quoteData.qrn}`;
    const apiUrl = `${Utils.getBaseurl()}${path}`;
    return this.http.put(apiUrl, quoteData);
  }
  updateQuoteStatus(quoteData: any): any {
    const path = `quotes/updateQuoteStatus`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.put(apiUrl, quoteData);
  }
  // @todo Create V2 function and consume in system-design components
  getSingleQuote(crn: any, quoteId: any, revsionNumber: any, qrn: string): any {
    const params: any = {
      crn: crn,
      quote_id: quoteId,
      revision_number: revsionNumber
    };
    const payload: ReadParams = { qrn }
    return this.quotesDetailsAPI.read(payload)
  }

  getSingleQuoteV2(crn: any, quoteId: any, revisionNumber: any, qrn: string): any {
    return this.quotesFinanceAPI.readSingleQuote({ crn, quoteId, revisionNumber, qrn })
  }
  uploadGraphImages(reqBody: any): any {
    return this.http.post(`${environment.newApiUrl}quotes/graph`, reqBody);
  }

  getLogo(imageId: any): any {
    return this.http.get(`${environment.newApiUrl}campaign-images/` + imageId, { responseType: 'text' });
  }
  getSavedInverters(accountId: any, emailId: any): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/inverter/selected-v1`, { params });
  }
  checkForNearMap(addressObj: any): any {
    const apiUrl = `https://api.nearmap.com/coverage/v2/point/${addressObj.lng},${addressObj.lat}?apikey=${environment.nearmapKey}&limit=1`;
    return this.http.get<any>(apiUrl);
  }
  getLicenceType(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id')
    };
    return this.http.get(`${environment.newApiUrl}account/license`, { params });
  }
  updateChargableMap(mapName: any): Observable<any> {
    const crn = this.authService.getItem('crn');
    const updateObj: any = {};
    updateObj['crn'] = crn;
    updateObj['map'] = mapName;
    //updateObj['sessionId'] = this.authService.getItem('sessionId');
    const path = `lead/update-chargable-map`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.post<any>(apiUrl, updateObj);
  }
  getShade(data: any): Observable<any> {
    const crn = this.authService.getItem('crn');
    data['sessionId'] = this.authService.getItem('sessionId');
    const path = `shading/`;
    const apiUrl = `${Utils.getBackendBaseURL()}${path}`;
    return this.http.post<any>(apiUrl, data);
  }
  generateShadeReport(data: any): Observable<any> {
    const crn = this.authService.getItem('crn');
    data['sessionId'] = this.authService.getItem('sessionId');
    const path = `shading/shadeReport`;
    const apiUrl = `${Utils.getBackendBaseURL()}${path}`;
    return this.http.post<any>(apiUrl, data);
  }
  // this get the service of parent-account along with childs
  getServiceAvailable(): any {
    let obj: any = {};
    obj = this.utils.callFormDataLeadQwner(obj);
    const params: any = {
      account_id: obj['account_id'],
      email_id: this.authService.getItem('email_id'),
      list: 'services_offered',
      parent: this.authService.getItem('parent')
    };
    return this.http.get(`${environment.newApiUrl}service`, { params });
  }
  getQuoteBackgroundImage(imageId: any, accountId: any, quoteid: any, revNo: any, crn: any, type: any, headers?: HttpHeaders) {
    const params: any = {
      account_id: accountId,
      backgroundImageId: imageId,//imageId.quote_id  + '_' + imageId.revision_number + '_background.png',
      quote_id: quoteid,
      revision_number: revNo,
      crn: crn,
      type: type
    };
    return this.http.get(`${Utils.getBackendBaseURL()}quotes/getBackgroundImage`, { params, headers });
  }
  saveQuoteBackgroundImage(quoteData: any): any {
    const options = {
      headers: new HttpHeaders({

        'x-aws-enact-key': '5A5C7D4736176IOP497347SUAY72656174XTZ',

      }),
    };

    return this.http.put(`${Utils.getBackendBaseURL()}quotes/saveBackgroundImage`, quoteData, options);
  }
  // editcompletedquote(quoteObj: any): Observable<object> {
  //   return this.http.post(`${environment.newApiUrl}quotes/completed/edit`, quoteObj);
  // }
  
  getPVWattsPanelHourly(queryData: any) {
    const apiUrl = `${Utils.getBaseurl()}pvwattsPanels/hourly`;
    return this.http.post(apiUrl, queryData);
  }
  getIrridaance(queryData: any) {
    const apiUrl = `${Utils.getBackendBaseURL()}pvwattsPanels/irridance`;
    return this.http.post(apiUrl, queryData);
  }
  downloadShadeReposrt(fileUrl: string): Observable<any> {
    return this.http.get(`${fileUrl}`, { responseType: 'blob', headers: { Accept: 'application/octet-stream', 'x-aws-enact-key': '5A5C7D4736176IOP497347SUAY72656174XTZ', } });
  }

  syncProposalAsContract(object: any): Observable<object> {
    return this.http.post(`${environment.newApiUrl}quotes/sync-proposal-as-contract`, object);
  }

  

  getPanelArrayTypes(): Observable<Array<PanelArrayTypeVO>> {
    return this.http.get<Array<PanelArrayTypeVO>>('assets/json/panelArrayTypes.json');
  }

  getPanelArrayTypesNova(): Observable<Array<PanelArrayTypeVO>> {
    return this.http.get<Array<PanelArrayTypeVO>>('assets/json/panelArrayTypes_nova.json');
  }
  
  editcompletedquoteV2(payload: EditCompletedQuoteAPIRequest, headers?: HttpHeaders): Observable<object> {
    return this.quoteService.updateCompletedQuote(payload, headers);
  }
  addDetailNotes(payload: any) {
    return this.http.post(`${environment.newApiUrl}lead/notes`, payload);
  }
}

