import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnactCompositeButtonComponent } from './enact-composite-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { EnactIconModule } from '../enact-icon/enact-icon.module';
import { HelpModule } from '../help/help.module';

@NgModule({
  declarations: [
    EnactCompositeButtonComponent
  ],
  imports: [
    EnactIconModule,
    MatIconModule,
    MatRippleModule,
    CommonModule,
    HelpModule
  ],
  exports: [
    EnactCompositeButtonComponent
  ]
})
export class EnactCompositeButtonModule { }
