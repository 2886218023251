import { SystemDesignObject } from './system-design-object.abstract.model';
import { ConfigurationIds } from './ui-state.model';
import { Vertex } from './vertex.model';

export enum TreesType {
    spherical = "spherical",
    conical = "conical",
    trunk = "trunk"
}

export interface IPosition{
    x?: number;
    y?: number;
    z?: number;
}
interface ITree {
    id: string;
    name: string;
    configurationId?: ConfigurationIds | undefined;
    type: TreesType;
    position: IPosition;
    diameter: number;
    vertexIds?: Array<Vertex['id']>;
    defaultHeight?: number;
    trunk?: ITree;
}

export class Tree extends SystemDesignObject implements ITree {

    selectConfigId?: boolean | undefined;
    id: string;
    name: string;
    configurationId?: ConfigurationIds | undefined;
    type: TreesType;
    position: IPosition;
    diameter: number;
    vertexIds?: string[];
    defaultHeight?: number;
    trunk?: ITree;

    constructor(data: Tree) {
        super();
        this.id = data.id;
        this.name = data.name;
        this.type = data.type;
        this.position = data.position;
        this.diameter = data.diameter;
        this.vertexIds = data.vertexIds;
        this.trunk = data.trunk;
        this.configurationId = data.configurationId;
        this.defaultHeight = data.defaultHeight;
    }
}
