import * as THREE from 'three';

import Util, { constants, meshTypeEnum, threeColors } from './Utility';
import { RoofSection } from '@design/models/roof-section.model';
import { Edge } from '@design/models/edge.model';
import { OrbitControls } from './OrbitControls';
import { EngineService } from '../threejs.service';
export class Edges {
  public cylinderMesh: THREE.Mesh;
  constructor() {}

  public draw(startPoint: THREE.Vector3, endPoint: THREE.Vector3, color: THREE.Color): THREE.Mesh {
    // Calculate the cylinder's height
    const radius = constants.DefaultEdgeThickness;
    const height = endPoint.distanceTo(startPoint);

    // Create the cylinder geometry
    const cylinderGeometry = new THREE.CylinderGeometry(radius, radius, height, 6, 4);
    const basicMaterial = new THREE.MeshBasicMaterial({ color: color });

    // Create the cylinder mesh
    let cylinderMesh = new THREE.Mesh(cylinderGeometry, basicMaterial);
    cylinderMesh.name = 'Edge';

    // Set position to the midpoint between start and end points
    cylinderMesh.position.set(
      (startPoint.x + endPoint.x) / 2,
      (startPoint.y + endPoint.y) / 2,
      (startPoint.z + endPoint.z) / 2
    );

    // Set rotation to point from start to end
    cylinderMesh.lookAt(endPoint);

    // Rotate the cylinder so that it aligns with the line
    cylinderMesh.rotateX(Math.PI / 2);
    this.cylinderMesh = cylinderMesh.clone();
    return this.cylinderMesh;
  }

  public updatePosition(newStartPoint: THREE.Vector3, newEndPoint: THREE.Vector3, cylinderMesh: THREE.Mesh = this.cylinderMesh): void {
    let startPoint  = newStartPoint.clone();
    let endPoint = newEndPoint.clone();

    // Recalculate the cylinder's height
    const radius = 1;
    const height = endPoint.distanceTo(startPoint);

    // Update the cylinder geometry
    const cylinderGeometry = new THREE.CylinderGeometry(radius, radius, height, 6, 4);
    cylinderMesh.geometry.dispose();
    cylinderMesh.geometry = cylinderGeometry;

    // Update the position to the midpoint between start and end points
    cylinderMesh.position.set(
      (startPoint.x + endPoint.x) / 2,
      (startPoint.y + endPoint.y) / 2,
      (startPoint.z + endPoint.z) / 2
    );

    // Update rotation to point from start to end
    cylinderMesh.lookAt(endPoint);

    // Rotate the cylinder so that it aligns with the line
    cylinderMesh.rotateX(Math.PI / 2);
    this.cylinderMesh = cylinderMesh;
  }
}
export class RoofEdges extends Edges {
  orbitControls: OrbitControls;
  constructor(private engServ: EngineService, orbitControls: OrbitControls) {
    super();
    this.orbitControls = orbitControls;
  }
  /**
   * this function changes the color of selected edge
   * @param meshId 
   */
  public toggleEdge(meshId: string, select: Boolean, edgeColor: THREE.Color) {
    let cylinderMesh: THREE.Mesh = this.engServ.scene.getObjectByName(meshId) as THREE.Mesh;
    if (!cylinderMesh) return
    cylinderMesh.material = new THREE.MeshBasicMaterial({ color: select ? threeColors.selectedEdgeColor : edgeColor });
  }

  public selectedRoofEdges(roofSection: RoofSection, isSelected: boolean) {
    if (roofSection && roofSection.edgeIds && roofSection.edgeIds.length) {
      roofSection.edgeIds.forEach(id => {
        let cylinderMesh = this.engServ.scene.getObjectByName(id) as THREE.Mesh;
        if (!cylinderMesh) return;
        let scale = Util.calcScaleAcctoZoom(this.orbitControls);
        if (isSelected) {
          cylinderMesh.material = new THREE.MeshBasicMaterial({ color: new THREE.Color(roofSection.color) });
          cylinderMesh?.scale.set(2 * scale, 1, 2 * scale);
          return;
        }
        cylinderMesh.material = new THREE.MeshBasicMaterial({ color: new THREE.Color(roofSection.color) });
        cylinderMesh?.scale.set(scale, 1, scale);
      })
    }
  }

  public scaleRoofEdges(roof: THREE.Object3D, scale: number) {
    let edgeGroup = roof.getObjectByName(meshTypeEnum.edgeGroup);
    edgeGroup?.children.forEach(e => {
      e.scale.set(scale, 1, scale);
    })
  }
}