export enum Status {
  Success = 'Success',
  Error = 'Error',
}

export interface APIResponse<T> {
  data: T;
  status: Status;
  error?: string;
}
