import { Component, OnInit, Input } from '@angular/core';
import { EnactIcons } from '../../common/types/enactIcons';


@Component({
  selector: 'enact-icon',
  templateUrl: './enact-icon.component.html',
  styleUrls: ['./enact-icon.component.scss']
})
export class EnactIconComponent implements OnInit {

  @Input() icon!: EnactIcons;
  @Input() selected!: boolean;
  @Input() hovered!: boolean;
  @Input() exceded!: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
