<div class="toaster" [class]="options.titleClass">
    <div class="toast-icon" [class]="options.toastClass">
        <mat-icon aria-hidden="false" aria-label="notification icon" svgIcon="notification-icon"></mat-icon>
    </div>
    <div class="toast-details">
        <div>
            <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
                {{ title }}
            </div>
            <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="custom-message"
                [class]="options.messageClass" [innerHTML]="message">
            </div>
            <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
                [class]="options.messageClass" [attr.aria-label]="message">
                {{ message }}
            </div>
        </div>
    </div>
    <div class="close-icon">
        <mat-icon aria-hidden="false" *ngIf="options.closeButton" (click)="remove()"  aria-label="close icon">close</mat-icon>
    </div>
</div>
