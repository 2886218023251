import {
  Component,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { ProjectsService } from '../../../projects/projects.service';
import { hasTreeViewChanged, uniquesList, Utils } from 'src/app/helpers/utils';
import { AuthService } from '../../../../helpers/auth.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
declare var Highcharts: any;
import * as $ from 'jquery';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard-line-chart',
  templateUrl: './dashboard-line-chart.component.html',
  styleUrls: ['./dashboard-line-chart.component.scss'],
})

export class DashboardLineChartComponent implements OnInit, OnDestroy {
  declare Highcharts: any;
  title = 'Line Chart';
  @Input() public data: { hour: any; consumption: any }[] | any;
  xAxisLabels: any = [];
  finalData: any = [];
  chart: any;
  teamMembers: any;
  allAccount: any = [];
  dataStatus: any = [];
  dataReferal: any = [];
  dataSource: any = [];
  dataSubStatus: any = [];
  startDate: any;
  enddate: any;
  countData: any=[];
  timePeriod: any=[];
  amountData: any=[];
  countData1: any=[];
  amountData1: any=[];
  chart1: any;
  userLocale: any;

  @Input() set lineChartLoad(val: any) {

    if (val) {
      this.createHighChart(this.byWay);
      this.createHighChart1(this.byWay)
    }
  }
  @Input() byWay:any;
  @Input()  dates:any;
  @Input() workflow:any;

  subscriptions: Subscription = new Subscription();

  constructor(
    private projectsService: ProjectsService,
    public utils: Utils,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.userLocale = localStorage.getItem('countryCurr');
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit() {
       this.getTeamMembers();
       this.startDate=new Date(this.dates.value['start']).getTime();
       this.enddate=new Date(this.dates.value['end']).getTime();
       this.getTimeSeries()
  }

  getTeamMembers() {
    this.subscriptions.add(this.projectsService.getTeamMembers().subscribe(
      (resp: any) => {
        this.teamMembers = resp;
        this.teamMembers.forEach((element: any) => {
          element.show = true;
          this.allAccount.push({
            accountId: element.account_id,
            emailId: element.email_id,
          });
          element.ChildrenItems.forEach((child: any) => {
            this.allAccount.push({
              accountId: child.account_id,
              emailId: child.email_id,
            });
            child.selected = false;
          });
        });
        this.getStatusAndSource(
          uniquesList(this.allAccount, 'accountId', 'emailId'),
          this.byWay,
          this.startDate,
          this.enddate
        );
      },
      (err: any) => {
        console.log(err);
      }
    ));
  }

  getStatusAndSource(
    account: any,
    name: any,
    startDate: any,
    endDate: any
  ) {
   
    const reqBody: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('user_type'),
      email_id: this.authService.getItem('email_id'),
      status: '',
      startTime: startDate,
      endTime: endDate,
      workFlowChanged: this.projectsService.selectedWorkflow !== 'All_Workflows',
      selectedWorkFlow: this.projectsService.selectedWorkflow,
      treeViewChanged: hasTreeViewChanged(account, this.projectsService.defaultAccountCount),
      accountsAndEmailFromTreeView: account,
    };
    this.dataStatus = [], this.dataSource = [];
    this.subscriptions.add(this.projectsService
      .getRecentStatusForGraph(reqBody)
      .subscribe((data: any) => {
        this.dataStatus = data.statusSeries;
        this.dataSource=data.sources;
        if(!!this.dataStatus?.length){
          this.dataStatus.forEach((element: any) => {
            if (! this.countData.find((o: { name: string; }) => o.name === element.name)){
             this.countData.push({'name': this.translateMsg('project.workFlow.'+ element.name),'data': element.countData})
            }
            if (! this.amountData.find((o: { name: string; }) => o.name === element.name)){
             this.amountData.push({'name': this.translateMsg('project.workFlow.'+ element.name),'data': element.amountData})
            }  
          });
        } else {
          this.countData = [], this.amountData = [];
        }
        if(!!this.dataSource?.length){
          this.dataSource.forEach((element: any) => {
            if (! this.countData1.find((o: { name: string; }) => o.name === element.name)){
               this.countData1.push({'name': this.translateMsg('project.workFlow.'+ element.name),'data': element.countData})
            }
            if (! this.amountData1.find((o: { name: string; }) => o.name === element.name)){
               this.amountData1.push({'name': this.translateMsg('project.workFlow.'+ element.name),'data': element.amountData})
            }  
          });
        } else {
          this.countData1 = [], this.amountData1 = [];
        }
        this.startDate=startDate;
        this.enddate=endDate;
        this.getTimeSeries();
        this.createHighChart(name)
        this.createHighChart1(name)
        
      }));
  }

  createHighChart(val:any) {
    if (val == 'count') {
      let highchart = '';
      highchart =  Highcharts.chart('dashborad-line', {
        chart: {
          type: 'area',

        },
        title: {
          text: this.translateMsg('Project Status'),
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767', '#2920d4', '#408396', '#5BBF8B', '#FCD715'],
        xAxis: {
          categories: this.timePeriod,
          tickmarkPlacement: 'on',
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: this.translateMsg('count'),
          },
          labels: {},
        },
        tooltip: {
          split: true,
          valuePrefix: '',
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666',
            },
          },
        },

        series: this.countData
      });
    }
    if (val == 'amount') {
      let chart = '';
      chart =  Highcharts.chart('dashborad-line', {
        chart: {
          type: 'area',

        },
        title: {
          text: this.translateMsg('Project Status'),
        },
        colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767', '#2920d4', '#408396', '#5BBF8B', '#FCD715'],
        xAxis: {
          categories: this.timePeriod,
          tickmarkPlacement: 'on',
          title: {
            enabled: false,
          },
        },
        yAxis: {
          title: {
            text: 'Amount',
          },
          labels: {},
        },
        tooltip: {
          split: true,
          valuePrefix: this.userLocale,
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          area: {
            stacking: 'normal',
            lineColor: '#666666',
            lineWidth: 1,
            marker: {
              lineWidth: 1,
              lineColor: '#666666',
            },
          },
        },

        series: this.amountData
      });
      // console.log(chart)
    }
  }
  createHighChart1(val:any) {
    if(val=='amount'){
      let chart;
      chart = Highcharts.chart('dashborad-line-source', {
          chart: {
            type: 'area',
           
          },
          title: {
            text: this.translateMsg('Project Source'),
          },
          colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],

          credits: {
            enabled: false
          },
          xAxis: {
            categories: this.timePeriod,
            tickmarkPlacement: 'on',
            title: {
              enabled: false,
            },
          },
          yAxis: {
            title: {
              text: 'Amount',
            },
            labels: {},
          },
          tooltip: {
            split: true,
            valuePrefix: this.userLocale,
          },
          plotOptions: {
            area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: '#666666',
              },
            },
          },
        
          series:this.amountData1
        });

    }
    if(val=='count'){
      let chart;
      chart =  Highcharts.chart('dashborad-line-source', {
          chart: {
            type: 'area',
           
          },
          title: {
            text: this.translateMsg('Project Source'),
          },
          colors: ['#00A9C5', '#4AD091', '#FFC860', '#FCD615', '#FF9747', '#F96767','#2920d4','#408396','#5BBF8B','#FCD715'],
          credits: {
            enabled: false
          },
          xAxis: {
            categories: this.timePeriod,
            tickmarkPlacement: 'on',
            title: {
              enabled: false,
            },
          },
          yAxis: {
            title: {
              text: this.translateMsg('count'),
            },
            labels: {},
          },
          tooltip: {
            split: true,
            valueSuffix: '',
          },
          plotOptions: {
            area: {
              stacking: 'normal',
              lineColor: '#666666',
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: '#666666',
              },
            },
          },
          series:this.countData1
        });}
    }

  getTimeSeries() {
    let finDate:any = ((new Date(this.enddate).getTime()) - (new Date(this.startDate).getTime()));
    let diviDate:any = (finDate / 7);
    let TotalFindate:any = new Date(this.startDate).getTime();
    let pushArray = [];
    let  startDay:any=new Date(parseInt(TotalFindate)).toString();
    startDay=new Date(startDay)
    pushArray.push(startDay);
    for (let i = 0; i < 7; i++) {
      TotalFindate += diviDate;
      let  startDay:any=new Date(parseInt(TotalFindate)).toString();
      startDay=new Date(startDay)
      pushArray.push(startDay);
    }
    pushArray.forEach(element => {
       this.timePeriod.push((element.getMonth()+1)+"/"+element.getDate()+"/"+element.getFullYear())
    });
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get(val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }
  
}
