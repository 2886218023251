import { Injectable } from '@angular/core';
import { Finance, Financing, Tiers, Year1 } from '@api/models/finance.model';
import { QuoteService } from '@api/services/quote.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';
import { Hardware } from '@api/models/hardware.model';
import { Design } from '@api/models/design.model';
import { QuotesDetailsAPI } from './details';
import { QuoteV1 } from '@api/models/qoute.v1.model';
import { SingleQuote } from '@api/models/single-quote.model';
import { APIResponse } from '@api/models/api-response.model';
import { CostData } from '@api/models/finance.model';
import { has } from 'lodash';

interface ReadParams {
  qrn: string;
  quoteId?: string;
  revisionNumber?: number;
  crn?: string;
}

@Injectable()
export class QuotesFinanceAPI {
  constructor(
    private readonly quote: QuoteService,
    private quotesDetailsAPI: QuotesDetailsAPI,
    public utils: Utils
  ) { }

  readSingleQuote(params: ReadParams) {
    return this.quote
      .getCompleteQuote(params)
      .pipe(
        map((response: APIResponse<SingleQuote>) =>
          this.mapDesignHardwareAndFinanceToDetails(
            params,
            response.data,
            response.data?.design,
            response.data?.hardware,
            response.data?.finance
          )
        )
      );
  }

  read(params: ReadParams) {
    return forkJoin([
      this.quote.getQuote(params.qrn),
      this.quote.getQuoteDesign(params.qrn),
      this.quote.getQuoteHardware(params.qrn),
      this.quote.getQuoteFinance(params.qrn),
    ]).pipe(
      map(([quote, design, hardware, finance]) =>
        this.mapDesignHardwareAndFinanceToDetails(
          params,
          quote.data,
          design.data,
          hardware.data,
          finance.data
        )
      )
    );
  }

  update(payload: Finance) {
    return this.quote.updateQuoteFinance(payload);
  }

  mapDesignHardwareAndFinanceToDetails(
    params: ReadParams,
    quote: QuoteV1 | any,
    design: Design,
    hardware: Hardware,
    finance: Finance
  ) {
    return {
      ...this.quotesDetailsAPI.mapDesignAndHardwareToDetails(
        params,
        quote,
        design,
        hardware
      ),
      operationAndMaintenanceDetails: {
        profileName: finance?.costData?.profile,
        itemDetails: !!finance?.costData?.onm
          ? [
            {
              name: finance?.costData?.onm?.year1?.description,
              description: finance?.costData?.onm?.year1?.description,
              priceType: finance?.costData?.onm?.year1?.priceType,
              selected: finance?.costData?.onm?.year1?.selected,
              price: finance?.costData?.onm?.year1?.price,
              quantity: finance?.costData?.onm?.year1?.quantity,
              totalCost: finance?.costData?.onm?.year1?.totalCost,
              escalator: finance?.costData?.onm?.year1?.escalator,
              term: finance?.costData?.onm?.year1?.term,
            },
          ]
          : [],
      },
      marginDetails: {
        totalCost: finance?.costData?.marginDetails?.totalCost,
        description: finance?.costData?.marginDetails?.description,
        price: finance?.costData?.marginDetails?.price,
        priceType: finance?.costData?.marginDetails?.priceType,
        isChecked: finance?.costData?.marginDetails?.selected,
        name: finance?.costData?.marginDetails?.name,
        quantity: finance?.costData?.marginDetails?.quantity,
      },
      batteryCostAdderDetails: finance?.costData?.salesPriceBreakdown?.length
        ? finance?.costData?.salesPriceBreakdown.find(
          (key) => key?.name?.toLowerCase() === 'storage'
        )
        : {},
      costAdderDetails: {
        profileName: finance?.costData?.profile,
        itemDetails: finance?.costData?.salesPriceBreakdown?.length
          ? finance?.costData?.salesPriceBreakdown.filter(
            (key) => key?.name?.toLowerCase() !== 'storage'
          )
          : [],
      },
      finCost: {
        maxSavings: null, //not found
        costData: {
          profile: finance?.costData?.profile,
          premium :finance?.costData?.premium
        },
        capitalCost: {
          calculatedSystemCost: finance?.capitalCost?.systemCost?.calculated,
          cashDiscount: {
            type: finance?.capitalCost?.cashDiscount?.type,
            value: finance?.capitalCost?.cashDiscount?.value,
          },
          effectivePrice: finance?.capitalCost?.effectivePrice,
          salesTaxPercentage: finance?.capitalCost?.stateSales?.tax,
          stateSalesTax: finance?.capitalCost?.stateSales?.applied,
          stateSalesTaxAmount: finance?.capitalCost?.stateSales?.amount,
          systemCost: finance?.capitalCost?.systemCost?.cost,
          taxRate: finance?.capitalCost?.taxRate,
          totalSystemCost: finance?.capitalCost?.systemCost?.total,
        },
        financing: {
          financingRequired: {
            compareToCashOnly:
              finance?.financing?.financingRequired?.fundOptionC, //not found
            fundOptionA: finance?.financing?.financingRequired?.fundOptionA, //not found
            fundOptionB: finance?.financing?.financingRequired?.fundOptionB, //not found
          },
          fundOptionA: {
            ITC: finance?.financing?.fundOptionA?.itc?.enabled,
            ITCAmount: finance?.financing?.fundOptionA?.itc?.amount,
            ITCPercentage: {
              includeFee:
                finance?.financing?.fundOptionA?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionA?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionA?.itc?.percentage?.value,
            },
            PersonalMortgageInterestDeduction:
              finance?.financing?.fundOptionA
                ?.personalMortgageInterestDeduction,
            addnDownpay: finance?.financing?.fundOptionA?.addnDownpay,
            adjustedMonthlyPayment:
              finance?.financing?.fundOptionA?.adjustedMonthlyPayment,
            bank: {
              isAvailable: finance?.financing?.fundOptionA?.bank?.isAvailable,
              name: finance?.financing?.fundOptionA?.bank?.name,
              planID: finance?.financing?.fundOptionA?.bank?.planID,
            },
            bankOptions: this.mapNewToOldBankOptions('fundOptionA', finance),
            combo: {
              amount: finance?.financing?.fundOptionA?.combo?.amount,
              termInMonth: finance?.financing?.fundOptionA?.combo?.termInMonth,
              usrCombo: finance?.financing?.fundOptionA?.combo?.usrCombo,
            },
            comboFinance: finance?.financing?.fundOptionA?.comboFinance,
            depSavings: finance?.financing?.fundOptionA?.depSavings,
            dollarPerKWh: finance?.financing?.fundOptionA?.dollarPerKWh,
            downPayment: finance?.financing?.fundOptionA?.downPayment,
            finAmount: finance?.financing?.fundOptionA?.finAmount,
            initialMonthlyPayment:
              finance?.financing?.fundOptionA?.initialMonthlyPayment,
            intrSavings: finance?.financing?.fundOptionA?.intrSavings,
            itc: finance?.financing?.fundOptionA?.itc?.enabled,
            itcamount: finance?.financing?.fundOptionA?.itc?.amount,
            itcpercentage: {
              includeFee:
                finance?.financing?.fundOptionA?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionA?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionA?.itc?.percentage?.value,
            },
            lcoe: +finance?.financing?.fundOptionA?.lcoe,
            monthlyPayments: finance?.financing?.fundOptionA?.monthlyPayments,
            payDownPercentage: finance?.financing?.fundOptionA?.totalPaydown,
            personalMortgageInterestDeduction:
              finance?.financing?.fundOptionA
                ?.personalMortgageInterestDeduction,
            showAddnlBonusOrDepreciation:
              finance?.financing?.fundOptionA?.showAddnlBonusOrDepreciation,
            showAddnlTaxSvgsOrDepreciation:
              finance?.financing?.fundOptionA?.showAddnlTaxSvgsOrDepreciation,
            splitTerm: finance?.financing?.fundOptionA?.splitTerm,
            srec: {
              amount: finance?.financing?.fundOptionA?.srec?.amount,
              name: finance?.financing?.fundOptionA?.srec?.name,
              period: finance?.financing?.fundOptionA?.srec?.period,
            },
            totalPaydown: finance?.financing?.fundOptionA?.totalPaydown,
            utilityRateEscalator:
              finance?.financing?.fundOptionA?.utilityRateEscalator,
          },
          fundOptionB: {
            ITC: finance?.financing?.fundOptionB?.itc?.enabled,
            ITCAmount: finance?.financing?.fundOptionB?.itc?.amount,
            ITCPercentage: {
              includeFee:
                finance?.financing?.fundOptionB?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionB?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionB?.itc?.percentage?.value,
            },
            PersonalMortgageInterestDeduction:
              finance?.financing?.fundOptionB
                ?.personalMortgageInterestDeduction,
            addnDownpay: finance?.financing?.fundOptionB?.addnDownpay,
            adjustedMonthlyPayment:
              finance?.financing?.fundOptionB?.adjustedMonthlyPayment,
            bank: {
              isAvailable: finance?.financing?.fundOptionB?.bank?.isAvailable,
              name: finance?.financing?.fundOptionB?.bank?.name,
              planID: finance?.financing?.fundOptionB?.bank?.planID,
            },
            bankOptions: this.mapNewToOldBankOptions('fundOptionB', finance),
            combo: {
              amount: finance?.financing?.fundOptionB?.combo?.amount,
              termInMonth: finance?.financing?.fundOptionB?.combo?.termInMonth,
              usrCombo: finance?.financing?.fundOptionB?.combo?.usrCombo,
            },
            comboFinance: finance?.financing?.fundOptionB?.comboFinance,
            depSavings: finance?.financing?.fundOptionB?.depSavings,
            dollarPerKWh: finance?.financing?.fundOptionB?.dollarPerKWh,
            downPayment: finance?.financing?.fundOptionB?.downPayment,
            finAmount: finance?.financing?.fundOptionB?.finAmount,
            initialMonthlyPayment:
              finance?.financing?.fundOptionB?.initialMonthlyPayment,
            intrSavings: finance?.financing?.fundOptionB?.intrSavings,
            itc: finance?.financing?.fundOptionB?.itc?.enabled,
            itcamount: finance?.financing?.fundOptionB?.itc?.amount,
            itcpercentage: {
              includeFee:
                finance?.financing?.fundOptionB?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionB?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionB?.itc?.percentage?.value,
            },
            lcoe: +finance?.financing?.fundOptionB?.lcoe,
            monthlyPayments: finance?.financing?.fundOptionB?.monthlyPayments,
            payDownPercentage: finance?.financing?.fundOptionB?.totalPaydown,
            personalMortgageInterestDeduction:
              finance?.financing?.fundOptionB
                ?.personalMortgageInterestDeduction,
            showAddnlBonusOrDepreciation:
              finance?.financing?.fundOptionB?.showAddnlBonusOrDepreciation,
            showAddnlTaxSvgsOrDepreciation:
              finance?.financing?.fundOptionB?.showAddnlTaxSvgsOrDepreciation,
            splitTerm: finance?.financing?.fundOptionB?.splitTerm,
            srec: {
              amount: finance?.financing?.fundOptionB?.srec?.amount,
              name: finance?.financing?.fundOptionB?.srec?.name,
              period: finance?.financing?.fundOptionB?.srec?.period,
            },
            totalPaydown: finance?.financing?.fundOptionB?.totalPaydown,
            utilityRateEscalator:
              finance?.financing?.fundOptionB?.utilityRateEscalator,
          },
          fundOptionC: {
            ITC: finance?.financing?.fundOptionC?.itc?.enabled,
            ITCAmount: finance?.financing?.fundOptionC?.itc?.amount,
            ITCPercentage: {
              includeFee:
                finance?.financing?.fundOptionC?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionC?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionC?.itc?.percentage?.value,
            },
            PersonalMortgageInterestDeduction:
              finance?.financing?.fundOptionC
                ?.personalMortgageInterestDeduction,
            addnDownpay: finance?.financing?.fundOptionC?.addnDownpay,
            adjustedMonthlyPayment:
              finance?.financing?.fundOptionC?.adjustedMonthlyPayment,
            capitalCost: {
              calculatedSystemCost:
                finance?.capitalCost?.systemCost?.calculated,
              cashDiscount: {
                type: finance?.capitalCost?.cashDiscount?.type,
                value: finance?.capitalCost?.cashDiscount?.value,
              },
              effectivePrice: finance?.capitalCost?.effectivePrice,
              salesTaxPercentage: finance?.capitalCost?.stateSales?.tax,
              stateSalesTax: finance?.capitalCost?.stateSales?.applied,
              stateSalesTaxAmount: finance?.capitalCost?.stateSales?.amount,
              systemCost: finance?.capitalCost?.systemCost?.cost,
              taxRate: finance?.capitalCost?.taxRate,
              totalSystemCost: finance?.capitalCost?.systemCost?.total,
            },
            comboFinance: finance?.financing?.fundOptionC?.comboFinance,
            depSavings: finance?.financing?.fundOptionC?.depSavings,
            dollarPerKWh: finance?.financing?.fundOptionC?.dollarPerKWh,
            downPayment: finance?.financing?.fundOptionC?.downPayment,
            finAmount: finance?.financing?.fundOptionC?.finAmount,
            initialMonthlyPayment:
              finance?.financing?.fundOptionC?.initialMonthlyPayment,
            intrSavings: finance?.financing?.fundOptionC?.intrSavings,
            itc: finance?.financing?.fundOptionC?.itc?.enabled,
            itcamount: finance?.financing?.fundOptionC?.itc?.amount,
            itcpercentage: {
              includeFee:
                finance?.financing?.fundOptionC?.itc?.percentage?.includeFee,
              termInYear:
                finance?.financing?.fundOptionC?.itc?.percentage?.termInYear,
              value: finance?.financing?.fundOptionC?.itc?.percentage?.value,
            },
            lcoe: +finance?.financing?.fundOptionC?.lcoe,
            monthlyPayments: finance?.financing?.fundOptionC?.monthlyPayments,
            payDownPercentage: finance?.financing?.fundOptionC?.totalPaydown,
            showAddnlBonusOrDepreciation:
              finance?.financing?.fundOptionC?.showAddnlBonusOrDepreciation,
            showAddnlTaxSvgsOrDepreciation:
              finance?.financing?.fundOptionC?.showAddnlTaxSvgsOrDepreciation,
            splitTerm: finance?.financing?.fundOptionC?.splitTerm,
            srec: {
              amount: finance?.financing?.fundOptionC?.srec?.amount,
              name: finance?.financing?.fundOptionC?.srec?.name,
              period: finance?.financing?.fundOptionC?.srec?.period,
            },
            totalPaydown: finance?.financing?.fundOptionC?.totalPaydown,
            utilityRateEscalator:
              finance?.financing?.fundOptionC?.utilityRateEscalator,
          },
        },
        incentiveAndStateTaxCredit: {
          maxSavings: 0, //not used
          profileName: finance?.incentiveAndCredit?.profile, //not used
          selectedPreFinance: {
            pre_finance_dc: finance?.incentiveAndCredit?.selectedPreFinance?.preFinanceDc, //not found
            pre_finance_fixed_amount: finance?.incentiveAndCredit?.selectedPreFinance?.preFinanceFixedAmount, //not found
            pre_finance_other_rebate: finance?.incentiveAndCredit?.selectedPreFinance?.preFinanceOtherRebate, //not found
            pre_finance_total_price: finance?.incentiveAndCredit?.selectedPreFinance?.preFinanceTotalPrice, //not found
          },
          selectedCredits: {
            PBIRebateAmount: finance?.incentiveAndCredit?.pbi?.total, //not found
            account_id: null, //not found
            dc_rebate_amount: finance?.incentiveAndCredit?.dollarPerWatt?.total,
            dc_rebate_cap_amount:
              finance?.incentiveAndCredit?.dollarPerWatt?.cap?.amount,
            dc_rebate_cap_type:
              finance?.incentiveAndCredit?.dollarPerWatt?.cap?.type,
            dc_rebate_name: finance?.incentiveAndCredit?.dollarPerWatt?.name,
            dc_rebate_term: finance?.incentiveAndCredit?.dollarPerWatt?.term,
            dc_rebate_tiers: [
              {
                amount:
                  +finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier1
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier1
                    ?.maxValue,
                tierName: 'tier1', //not found
              },
              {
                amount:
                  +finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier2
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier2
                    ?.maxValue,
                tierName: 'tier2', //not found
              },
              {
                amount:
                  +finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier3
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.dollarPerWatt?.tiers?.tier3
                    ?.maxValue,
                tierName: 'tier3', //not found
              },
            ],
            dollarPerWattYearly:
              finance?.incentiveAndCredit?.dollarPerWatt?.yearWiseDivision,
            fa_rebate_amount:
              finance?.incentiveAndCredit?.fixedAmount?.tiers?.tier1?.amount,
            fa_rebate_cap_amount:
              finance?.incentiveAndCredit?.fixedAmount?.cap?.amount,
            fa_rebate_name: finance?.incentiveAndCredit?.fixedAmount?.name,
            fa_rebate_term: finance?.incentiveAndCredit?.fixedAmount?.term,
            feedTariffRebateAmount: 0, //not found
            feedTariffYearly:
              finance?.incentiveAndCredit?.feedInTariff?.yearWiseDivision,
            feed_tariff_cap_amount:
              finance?.incentiveAndCredit?.feedInTariff?.cap?.amount,
            feed_tariff_name: finance?.incentiveAndCredit?.feedInTariff?.name,
            feed_tariff_term: finance?.incentiveAndCredit?.feedInTariff?.term,
            feed_tariff_tiers: [
              {
                amount:
                  +finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier1
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier1
                    ?.maxValue,
                tierName: 'tier1', //not found
              },
              {
                amount:
                  +finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier2
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier2
                    ?.maxValue,
                tierName: 'tier2', //not found
              },
              {
                amount:
                  +finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier3
                    ?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.feedInTariff?.tiers?.tier3
                    ?.maxValue,
                tierName: 'tier3', //not found
              },
            ],
            fit_rebate_annual: '0', //not found
            fit_rebate_cap_amount:
              finance?.incentiveAndCredit?.pbi?.cap?.amount,
            fit_rebate_name: finance?.incentiveAndCredit?.pbi?.name,
            fit_rebate_term: finance?.incentiveAndCredit?.pbi?.term,
            fit_rebate_tiers: [
              {
                amount: +finance?.incentiveAndCredit?.pbi?.tiers?.tier1?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.pbi?.tiers?.tier1?.maxValue,
                tierName: 'tier1', //not found
              },
              {
                amount: +finance?.incentiveAndCredit?.pbi?.tiers?.tier2?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.pbi?.tiers?.tier2?.maxValue,
                tierName: 'tier2', //not found
              },
              {
                amount: +finance?.incentiveAndCredit?.pbi?.tiers?.tier3?.amount,
                maxValue:
                  finance?.incentiveAndCredit?.pbi?.tiers?.tier3?.maxValue,
                tierName: 'tier3', //not found
              },
            ],
            fourthRebateYearly:
              finance?.incentiveAndCredit?.fourthRebate?.yearWiseDivision, //not found
            fourth_rebate_amount:
              finance?.incentiveAndCredit?.fourthRebate?.tiers?.tier1?.amount, //not found
            fourth_rebate_name: finance?.incentiveAndCredit?.fourthRebate?.name, //not found
            fourth_rebate_term: finance?.incentiveAndCredit?.fourthRebate?.term, //not found
            other_rebate_amount:
              +finance?.incentiveAndCredit?.other?.tiers?.tier1?.amount,
            other_rebate_name: finance?.incentiveAndCredit?.other?.name,
            other_rebate_term: finance?.incentiveAndCredit?.fourthRebate?.term, //not found
            pbiYearly: finance?.incentiveAndCredit?.pbi?.yearWiseDivision,
            pbirebateAmount: finance?.incentiveAndCredit?.pbi?.total, //not found
            rebateAcOrDcType: finance?.incentiveAndCredit?.dollarPerWatt?.type, //not found
            rebateId: finance?.incentiveAndCredit?.rebateId, //not found
            salesTaxCapType:
              finance?.incentiveAndCredit?.salesTaxCredit?.cap?.type, //not found
            salesTaxType: finance?.incentiveAndCredit?.salesTaxCredit?.type, //not found
            sales_tax_annual_cap:
              finance?.incentiveAndCredit?.salesTaxCredit?.tiers?.tier1
                ?.maxValue, //not found
            sales_tax_cap_value:
              finance?.incentiveAndCredit?.salesTaxCredit?.cap?.amount, //not found
            sales_tax_fixed_amount:
              finance?.incentiveAndCredit?.salesTaxCredit?.tiers?.tier1?.amount, //not found
            sales_tax_fixed_name:
              finance?.incentiveAndCredit?.salesTaxCredit?.name, //not found
            sales_tax_fixed_term:
              finance?.incentiveAndCredit?.salesTaxCredit?.term, //not found
            sales_tax_fixed_year:
              finance?.incentiveAndCredit?.salesTaxCredit?.term, //not found => same as sales_tax_fixed_term
            stateTaxCredit: finance?.incentiveAndCredit?.salesTaxCredit?.total, //not found
            stateTaxYearly:
              finance?.incentiveAndCredit?.salesTaxCredit?.yearWiseDivision, //not found
            totalDollarPerWatt:
              finance?.incentiveAndCredit?.dollarPerWatt?.total, //not found
            totalFeedTariff: finance?.incentiveAndCredit?.feedInTariff?.total, //not found
            totalFixedAmount: finance?.incentiveAndCredit?.fixedAmount?.total, //not found
            totalFourthRebate: finance?.incentiveAndCredit?.fourthRebate?.total, //not found
            totalPBI: finance?.incentiveAndCredit?.pbi?.total,
            _id: null, //not found
          },
        },
      },
      proposal_info: {
        custom_proposal_name: finance?.proposalInfo?.customProposalName,
        custom_proposal_tags: finance?.proposalInfo?.customProposalTags,
        proposal_type: finance?.proposalInfo?.proposalType,
        notes: finance?.proposalInfo?.notes,
      },
    };
  }

  mapNewToOldBankOptions(bankOptionType: string, finance: any): any {
    if (finance?.financing && finance?.financing[bankOptionType]) {
      let finOption = finance?.financing[bankOptionType]?.bank?.options;
      const bankOption = {
        APR: finOption?.apr,
        Loanmaxlimit: finOption?.loanMaxLimit,
        Loanminlimit: finOption?.loanMinLimit,
        PPAEscalator: finOption?.PPA?.escalator,
        PPAPrice: finOption?.PPA?.price,
        apr: finOption?.apr,
        contractorFees: {
          type: finOption?.contractorFees?.type,
          value: +finOption?.contractorFees?.value,
        },
        customerFees: {
          type: finOption?.customerFees?.type,
          value: finOption?.customerFees?.value,
        },
        interestOnly: {
          amount: finOption?.interestOnly?.amount,
          terms: finOption?.interestOnly?.terms,
        },
        leaseFees: {
          type: finOption?.leaseFees?.type,
          value: +finOption?.leaseFees?.value,
        },
        loanmaxlimit: finOption?.loanMaxLimit,
        loanminlimit: finOption?.loanMinLimit,
        ppaescalator: finOption?.PPA?.escalator,
        ppaprice: finOption?.PPA?.price,
        term: finOption?.term,
        yearlyPayments: finOption?.yearlyPayments,
      };
      if (has(finOption, 'minLoanTerm')) {
        bankOption['MinLoanTerm'] = finOption?.minLoanTerm?.toString();
      }
      if (has(finOption, 'maxLoanterm')) {
        bankOption['MaxLoanterm'] = finOption?.maxLoanterm?.toString();
      }
      if (has(finOption, 'bankname')) {
        bankOption['Bankname'] = finOption?.bankname;
      }
      if (has(finOption, 'projectType')) {
        bankOption['ProjectType'] = finOption?.projectType;
      }
      if (has(finOption, 'loantype')) {
        bankOption['Loantype'] = finOption?.loantype;
      }
      if (has(finOption, 'fico')) {
        bankOption['FICOcheck'] = finOption?.fico?.check;
        bankOption['MinFICO'] = finOption?.fico?.min?.toString();
        bankOption['MaxFICO'] = finOption?.fico?.max?.toString();
      }
      if (has(finOption, 'feeType')) {
        bankOption['FeeType'] = finOption?.feeType;
      }
      if (has(finOption, 'fee')) {
        bankOption['Fee'] = finOption?.fee;
      }
      if (has(finOption, 'isCombo')) {
        bankOption['isCombo'] = finOption?.isCombo;
      }
      if (has(finOption, 'type')) {
        bankOption['Type'] = finOption?.type;
      }
      if (has(finOption, 'active')) {
        bankOption['active'] = finOption?.active;
      }
      return bankOption
    }
  }

  mapOldToNewBankOptions(bankOptionType: string, finance: any): any {
    if (finance?.finCost?.financing && finance?.finCost?.financing[bankOptionType]) {
      let finOption = finance?.finCost?.financing[bankOptionType];
      const bankOption: any = {
        name: finOption?.bank?.name,
        options: {
          contractorFees: {
            type: finOption?.bankOptions?.contractorFees?.type,
            value: finOption?.bankOptions?.contractorFees?.value,
          },
          customerFees: {
            type: finOption?.bankOptions?.customerFees?.type,
            value: finOption?.bankOptions?.customerFees?.value,
          },
          PPA: {
            price: +finOption?.bankOptions?.PPAPrice,
            escalator: +finOption?.bankOptions?.PPAEscalator,
          },
          interestOnly: {
            amount: +finOption?.bankOptions?.interestOnly?.amount,
            terms: finOption?.bankOptions?.interestOnly?.terms,
          },
          loanMaxLimit: +finOption?.bankOptions?.Loanmaxlimit,
          leaseFees: {
            type: finOption?.bankOptions?.leaseFees?.type,
            value: +finOption?.bankOptions?.leaseFees?.value,
          },
          loanMinLimit: +finOption?.bankOptions?.Loanminlimit,
          term: +finOption?.bankOptions?.term,
          yearlyPayments: finOption?.bankOptions?.yearlyPayments,
          apr: +finOption?.bankOptions?.APR,
          finLeasePaymentArray: finOption?.bankOptions?.finLeasePaymentArray,
          financePaymentPPAArr: finOption?.bankOptions?.financePaymentPPAArr,
          finalLeaseAmount: +finOption?.bankOptions?.finalLeaseAmount,
        },
        isAvailable: finOption?.bank?.isAvailable,
        planID: finOption?.bank?.planID,
      }
      if (has(finOption?.bankOptions, 'MinLoanTerm')) {
        bankOption.options['minLoanTerm'] = +finOption?.bankOptions?.MinLoanTerm;
      }
      if (has(finOption?.bankOptions, 'MaxLoanterm')) {
        bankOption.options['maxLoanterm'] = +finOption?.bankOptions?.MaxLoanterm;
      }
      if (has(finOption?.bankOptions, 'Bankname')) {
        bankOption.options['bankname'] = finOption?.bankOptions?.Bankname;
      }
      if (has(finOption?.bankOptions, 'ProjectType')) {
        bankOption.options['projectType'] = finOption?.bankOptions?.ProjectType;
      }
      if (has(finOption?.bankOptions, 'Loantype')) {
        bankOption.options['loantype'] = finOption?.bankOptions?.Loantype;
      }
      if (has(finOption?.bankOptions, 'FICOcheck')) {
        bankOption.options = {
          ... bankOption.options,
          fico: {
            check: finOption?.bankOptions['FICOcheck'],
            min: +finOption?.bankOptions['MinFICO'],
            max: +finOption?.bankOptions['MaxFICO']
          }
        }
      }
      if (has(finOption?.bankOptions, 'FeeType')) {
        bankOption.options['feeType'] = finOption?.bankOptions?.FeeType;
      }
      if (has(finOption?.bankOptions, 'Fee')) {
        bankOption.options['fee'] = +finOption?.bankOptions?.Fee;
      }
      if (has(finOption?.bankOptions, 'isCombo')) {
        bankOption.options['isCombo'] = finOption?.bankOptions?.isCombo;
      }
      if (has(finOption?.bankOptions, 'Type')) {
        bankOption.options['type'] = finOption?.bankOptions?.Type;
      }
      if (has(finOption?.bankOptions, 'active')) {
        bankOption.options['active'] = finOption?.bankOptions?.active;
      }
      return bankOption;
    }
  }

  mapOldToNewFinanceQuote(finance: any): Finance {
    return {
      qrn: finance?.qrn,
      capitalCost: {
        cashDiscount: {
          type: finance?.finCost?.capitalCost?.cashDiscount?.type,
          value: finance?.finCost?.capitalCost?.cashDiscount?.value,
        },
        systemCost: {
          total: +finance?.finCost?.capitalCost?.totalSystemCost,
          calculated: +finance?.finCost?.capitalCost?.calculatedSystemCost,
          cost: +finance?.finCost?.capitalCost?.systemCost,
        },
        taxRate: +finance?.finCost?.capitalCost?.taxRate,
        taxRateType: finance?.finCost?.capitalCost?.taxRateType,
        effectivePrice: +finance?.finCost?.capitalCost?.effectivePrice,
        salesTax: 0,
        stateSales: {
          applied: finance?.finCost?.capitalCost?.stateSalesTax,
          amount: +finance?.finCost?.capitalCost?.stateSalesTaxAmount,
          tax: +finance?.finCost?.capitalCost?.salesTaxPercentage,
        },
      },
      taxRate: +finance?.finCost?.capitalCost?.taxRate * 100,
      costData: {
        ...this.mapOldToNewCostData(finance)
      },
      financing: {
        fundOptionA: {
          comboFinance: finance?.finCost?.financing?.fundOptionA?.comboFinance,
          depSavings: +finance?.finCost?.financing?.fundOptionA?.depSavings,
          dollarPerKWh: +finance?.finCost?.financing?.fundOptionA?.dollarPerKWh,
          downPayment: +finance?.finCost?.financing?.fundOptionA?.downPayment,
          finAmount: +finance?.finCost?.financing?.fundOptionA?.finAmount,
          intrSavings: +finance?.finCost?.financing?.fundOptionA?.intrSavings,
          itc: {
            amount: +finance?.finCost?.financing?.fundOptionA?.ITCAmount,
            percentage: {
              value:
                +finance?.finCost?.financing?.fundOptionA?.ITCPercentage?.value,
              includeFee:
                finance?.finCost?.financing?.fundOptionA?.ITCPercentage
                  ?.includeFee,
              termInYear:
                finance?.finCost?.financing?.fundOptionA?.ITCPercentage
                  ?.termInYear,
            },
            enabled: finance?.finCost?.financing?.fundOptionA?.ITC,
          },
          lcoe: +finance?.finCost?.financing?.fundOptionA?.lcoe,
          monthlyPayments:
            +finance?.finCost?.financing?.fundOptionA?.monthlyPayments,
          personalMortgageInterestDeduction:
            finance?.finCost?.financing?.fundOptionA
              ?.PersonalMortgageInterestDeduction,
          showAddnlBonusOrDepreciation:
            finance?.finCost?.financing?.fundOptionA
              ?.showAddnlBonusOrDepreciation,
          showAddnlTaxSvgsOrDepreciation:
            finance?.finCost?.financing?.fundOptionA
              ?.showAddnlTaxSvgsOrDepreciation,
          srec: {
            amount: +finance?.finCost?.financing?.fundOptionA?.srec?.amount,
            period: +finance?.finCost?.financing?.fundOptionA?.srec?.period,
            name: finance?.finCost?.financing?.fundOptionA?.srec?.name,
          },
          utilityRateEscalator:
            finance?.finCost?.financing?.fundOptionA?.utilityRateEscalator,
          addnDownpay: +finance?.finCost?.financing?.fundOptionA?.addnDownpay,
          adjustedMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionA?.adjustedMonthlyPayment,
          initialMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionA?.initialMonthlyPayment,
          payDown: +finance?.finCost?.financing?.fundOptionA?.payDown,
          splitTerm: +finance?.finCost?.financing?.fundOptionA?.splitTerm,
          totalPaydown: +finance?.finCost?.financing?.fundOptionA?.totalPaydown,
          bank: this.mapOldToNewBankOptions('fundOptionA', finance),
          combo: {
            amount: +finance?.finCost?.financing?.fundOptionA?.combo?.amount,
            usrCombo: finance?.finCost?.financing?.fundOptionA?.combo?.usrCombo,
            termInMonth:
              +finance?.finCost?.financing?.fundOptionA?.combo?.termInMonth,
          },
        },
        fundOptionC: {
          comboFinance: finance?.finCost?.financing?.fundOptionC?.comboFinance,
          depSavings: +finance?.finCost?.financing?.fundOptionC?.depSavings,
          dollarPerKWh: +finance?.finCost?.financing?.fundOptionC?.dollarPerKWh,
          downPayment: +finance?.finCost?.financing?.fundOptionC?.downPayment,
          finAmount: +finance?.finCost?.financing?.fundOptionC?.finAmount,
          intrSavings: +finance?.finCost?.financing?.fundOptionC?.intrSavings,
          itc: {
            amount: +finance?.finCost?.financing?.fundOptionC?.ITCAmount,
            percentage: {
              value:
                +finance?.finCost?.financing?.fundOptionC?.ITCPercentage?.value,
              includeFee:
                finance?.finCost?.financing?.fundOptionC?.ITCPercentage
                  ?.includeFee,
              termInYear:
                finance?.finCost?.financing?.fundOptionC?.ITCPercentage
                  ?.termInYear,
            },
            enabled: finance?.finCost?.financing?.fundOptionC?.ITC,
          },
          lcoe: +finance?.finCost?.financing?.fundOptionC?.lcoe,
          monthlyPayments:
            +finance?.finCost?.financing?.fundOptionC?.monthlyPayments,
          personalMortgageInterestDeduction:
            finance?.finCost?.financing?.fundOptionC
              ?.PersonalMortgageInterestDeduction,
          showAddnlBonusOrDepreciation:
            finance?.finCost?.financing?.fundOptionC
              ?.showAddnlBonusOrDepreciation,
          showAddnlTaxSvgsOrDepreciation:
            finance?.finCost?.financing?.fundOptionC
              ?.showAddnlTaxSvgsOrDepreciation,
          srec: {
            amount: +finance?.finCost?.financing?.fundOptionC?.srec?.amount,
            period: +finance?.finCost?.financing?.fundOptionC?.srec?.period,
            name: finance?.finCost?.financing?.fundOptionC?.srec?.name,
          },
          utilityRateEscalator:
            finance?.finCost?.financing?.fundOptionC?.utilityRateEscalator,
          addnDownpay: +finance?.finCost?.financing?.fundOptionC?.addnDownpay,
          adjustedMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionC?.adjustedMonthlyPayment,
          initialMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionC?.initialMonthlyPayment,
          payDown: +finance?.finCost?.financing?.fundOptionC?.payDown,
          splitTerm: +finance?.finCost?.financing?.fundOptionC?.splitTerm,
          totalPaydown: +finance?.finCost?.financing?.fundOptionC?.totalPaydown,
        },
        fundOptionB: {
          comboFinance: finance?.finCost?.financing?.fundOptionB?.comboFinance,
          depSavings: +finance?.finCost?.financing?.fundOptionB?.depSavings,
          dollarPerKWh: +finance?.finCost?.financing?.fundOptionB?.dollarPerKWh,
          downPayment: +finance?.finCost?.financing?.fundOptionB?.downPayment,
          finAmount: +finance?.finCost?.financing?.fundOptionB?.finAmount,
          intrSavings: +finance?.finCost?.financing?.fundOptionB?.intrSavings,
          itc: {
            amount: +finance?.finCost?.financing?.fundOptionB?.ITCAmount,
            percentage: {
              value:
                +finance?.finCost?.financing?.fundOptionB?.ITCPercentage?.value,
              includeFee:
                finance?.finCost?.financing?.fundOptionB?.ITCPercentage
                  ?.includeFee,
              termInYear:
                finance?.finCost?.financing?.fundOptionB?.ITCPercentage
                  ?.termInYear,
            },
            enabled: finance?.finCost?.financing?.fundOptionB?.ITC,
          },
          lcoe: +finance?.finCost?.financing?.fundOptionB?.lcoe,
          monthlyPayments:
            +finance?.finCost?.financing?.fundOptionB?.monthlyPayments,
          personalMortgageInterestDeduction:
            finance?.finCost?.financing?.fundOptionB
              ?.PersonalMortgageInterestDeduction,
          showAddnlBonusOrDepreciation:
            finance?.finCost?.financing?.fundOptionB
              ?.showAddnlBonusOrDepreciation,
          showAddnlTaxSvgsOrDepreciation:
            finance?.finCost?.financing?.fundOptionB
              ?.showAddnlTaxSvgsOrDepreciation,
          srec: {
            amount: +finance?.finCost?.financing?.fundOptionB?.srec?.amount,
            period: +finance?.finCost?.financing?.fundOptionB?.srec?.period,
            name: finance?.finCost?.financing?.fundOptionB?.srec?.name,
          },
          utilityRateEscalator:
            finance?.finCost?.financing?.fundOptionB?.utilityRateEscalator,
          addnDownpay: +finance?.finCost?.financing?.fundOptionB?.addnDownpay,
          adjustedMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionB?.adjustedMonthlyPayment,
          initialMonthlyPayment:
            +finance?.finCost?.financing?.fundOptionB?.initialMonthlyPayment,
          payDown: +finance?.finCost?.financing?.fundOptionB?.payDown,
          splitTerm: +finance?.finCost?.financing?.fundOptionB?.splitTerm,
          totalPaydown: +finance?.finCost?.financing?.fundOptionB?.totalPaydown,
          bank: this.mapOldToNewBankOptions('fundOptionB', finance),
          combo: {
            amount: +finance?.finCost?.financing?.fundOptionB?.combo?.amount,
            usrCombo: finance?.finCost?.financing?.fundOptionB?.combo?.usrCombo,
            termInMonth:
              +finance?.finCost?.financing?.fundOptionB?.combo?.termInMonth,
          },
        },
        financingRequired: {
          fundOptionA:
            finance?.finCost?.financing?.financingRequired?.fundOptionA,
          fundOptionB:
            finance?.finCost?.financing?.financingRequired?.fundOptionB,
          fundOptionC:
            finance?.finCost?.financing?.financingRequired?.compareToCashOnly,
        },
      } as Financing,
      incentiveAndCredit: {
        dollarPerWatt: {
          cap: {
            amount:
              finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.dc_rebate_cap_amount,
            type: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.dc_rebate_cap_type,
          },
          tiers: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.dc_rebate_tiers?.length
            ? this.mapOldToNewIncentiveAndCreditTiers(
              finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.dc_rebate_tiers
            )
            : ({} as Tiers),
          term: +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.dc_rebate_term,
          total:
            +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.totalDollarPerWatt,
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.dc_rebate_name,
          yearWiseDivision:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.dollarPerWattYearly,
          type: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.rebateAcOrDcType,
        },
        feedInTariff: {
          cap: {
            amount:
              +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.feed_tariff_cap_amount,
          },
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.feed_tariff_name,
          term: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.feed_tariff_term,
          yearWiseDivision:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.feedTariffYearly,
          tiers: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.feed_tariff_tiers?.length
            ? this.mapOldToNewIncentiveAndCreditTiers(
              finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.feed_tariff_tiers
            )
            : ({} as Tiers),
          total:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.totalFeedTariff,
        },
        fixedAmount: {
          cap: {
            amount:
              +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.fa_rebate_cap_amount,
          },
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fa_rebate_name,
          term: +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fa_rebate_term,
          total:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.totalFixedAmount,
          tiers: {
            tier1: {
              amount: +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.fa_rebate_amount
            }
          }
        },
        other: {
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.other_rebate_name,
          tiers: {
            tier1: {
              amount: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.other_rebate_amount,
              maxValue: finance?.incentiveAndCredit?.other?.name
            },
          },
        },
        pbi: {
          cap: {
            amount:
              +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.fa_rebate_cap_amount,
          },
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fit_rebate_name,
          tiers: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fit_rebate_tiers?.length
            ? this.mapOldToNewIncentiveAndCreditTiers(
              finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.fit_rebate_tiers
            )
            : ({} as Tiers),
          term: +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fit_rebate_term,
          total:
            +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.totalPBI,
          yearWiseDivision:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.pbiYearly,
        },
        profile: finance?.finCost?.incentiveAndStateTaxCredit?.profileName,
        fourthRebate: {
          yearWiseDivision:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.fourthRebateYearly,
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fourth_rebate_name,
          term: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.fourth_rebate_term,
          tiers: {
            tier1: {
              amount: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits?.fourth_rebate_amount,
              name: 'tier1'
            }
          },
          total:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.totalFourthRebate,
        },
        salesTaxCredit: {
          cap: {
            amount:
              +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                ?.sales_tax_cap_value,
            type: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.salesTaxCapType,
          },
          name: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.sales_tax_fixed_name,
          term: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.sales_tax_fixed_term,
          tiers: {
            tier1: {
              amount:
                +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                  ?.sales_tax_fixed_amount,
              maxValue:
                finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
                  ?.sales_tax_annual_cap,
            },
          },
          yearWiseDivision:
            finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.stateTaxYearly,
          total:
            +finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
              ?.stateTaxCredit,
          type: finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.salesTaxType,
        },
        rebateId:
          finance?.finCost?.incentiveAndStateTaxCredit?.selectedCredits
            ?.rebateId,
        selectedPreFinance: {
          preFinanceDc: finance?.finCost?.incentiveAndStateTaxCredit?.selectedPreFinance?.pre_finance_dc,
          preFinanceFixedAmount: finance?.finCost?.incentiveAndStateTaxCredit?.selectedPreFinance?.pre_finance_fixed_amount,
          preFinanceTotalPrice: finance?.finCost?.incentiveAndStateTaxCredit?.selectedPreFinance?.pre_finance_total_price,
          preFinanceOtherRebate: finance?.finCost?.incentiveAndStateTaxCredit?.selectedPreFinance?.pre_finance_other_rebate
        }
      },
      proposalInfo: {
        customProposalName: finance?.proposal_info?.custom_proposal_name,
        proposalType: finance?.proposal_info?.proposal_type,
        customProposalTags: finance?.proposal_info?.custom_proposal_tags,
        notes: finance?.proposal_info?.notes,
      },
    };
  }

  mapOldToNewCostData(finance: any): CostData {
    const costData = {
      profile: finance?.finCost?.costData?.profile,
      salesPriceBreakdown: this.mapOldToNewFinanceSalesPriceBreakdown(finance),
      marginDetails: {
        totalCost: finance?.marginDetails?.totalCost,
        description: finance?.marginDetails?.description,
        price: finance?.marginDetails?.price,
        priceType: finance?.marginDetails?.priceType,
        selected: finance?.marginDetails?.isChecked,
        name: finance?.marginDetails?.name,
        quantity: finance?.marginDetails?.quantity,
      },
    }
    return finance?.operationAndMaintenanceDetails?.itemDetails?.length ? {
      ...costData,
      onm: {
        year1: finance?.operationAndMaintenanceDetails?.itemDetails?.length
          ? {
            description:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                ?.description,
            escalator:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                ?.escalator,
            price:
              finance?.operationAndMaintenanceDetails?.itemDetails[0].price,
            priceType:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                .priceType,
            quantity:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                .quantity,
            selected:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                .selected,
            term: +finance?.operationAndMaintenanceDetails?.itemDetails[0]
              .term,
            totalCost:
              finance?.operationAndMaintenanceDetails?.itemDetails[0]
                .totalCost,
          }
          : ({} as Year1),
      },
    } : costData;
  }

  mapOldToNewIncentiveAndCreditTiers(tiers: any): Tiers {
    return tiers.reduce((accumulator: any, next: any) => {
      return {
        ...accumulator,
        [next.tierName!]: {
          amount: +next?.amount,
          maxValue: next?.maxValue,
          name: next?.tierName,
        },
      };
    }, {});
  }

  mapOldToNewFinanceSalesPriceBreakdown(finance: any) {
    let salesPriceBreakdown: any[] = [];
    const isCostAdderDetailsAvailable = !!finance?.costAdderDetails && finance?.costAdderDetails?.itemDetails?.length;
    if (isCostAdderDetailsAvailable) {
      salesPriceBreakdown = [...finance?.costAdderDetails?.itemDetails];
    }
    if (Object.keys(finance?.batteryCostAdderDetails)?.length) {
      salesPriceBreakdown = [
        ...salesPriceBreakdown,
        finance?.batteryCostAdderDetails,
      ];
    }
    return salesPriceBreakdown;
  }
}
