import { Status } from "./simple.types";

export interface ChartConsumption {
  name: string;
  value: string;
  color?: string;
  order?: any;
  size?: any;
  colorLine?: any;
}

export interface CustomDatePayload {
  date: number;
  meter: string;
  crn: string;
  qrn: string;
}
export interface DynamicDateApiResponse {
  data: any;
  status: Status;
  error?: string;
}

export const toCustomDate = (res: any): DynamicDateApiResponse => ({
  data: !!res?.chartData?.length ? res?.chartData : [],
  status: Status.Success
})

export enum SolorToColor {
  'Pre-Solar' = '#C7C8C9',
  'Solar Generation' = '#FFE46C',
  'Charge' = '#73D0DF',
  'Discharge' = '#8AD07A',
  'Post-Solar' = '#010100'
}

export enum SolorToNewSolar {
  'Pre-Solar' = 'Pre-solar grid consumption',
  'Solar Generation' = 'Solar generation',
  'Charge' = 'Storage charge',
  'Discharge' = 'Storage discharge',
  'Post-Solar' = 'Post-solar grid consumption'
}

export enum SolorToId {
  'Pre-Solar' = 1,
  'Solar Generation' = 2,
  'Post-Solar' = 3,
  'Charge' = 4,
  'Discharge' = 5,
}

export enum MonthToNumber {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12
}

export const colors = ['Pre-Solar', 'Solar Generation', 'Charge', 'Discharge', 'Post-Solar'];
export const idToColor = ['rgba(175,176,178, 0.7)', 'rgba(255,214,29, 0.65)', 'rgba(0,169,196, 0.55)', 'rgba(74,207,144, 0.60)', 'rgba(0,0,0, 0.0)'];
export const idToLineColor = ['rgba(175,176,178, 0)', 'rgba(255,214,29, 0)', 'rgba(0,169,196, 0)', 'rgba(74,207,144, 0)', 'rgba(0, 0, 0, 1)'];
export const idToLegendColor = ['rgba(175,176,178, 0.7)', 'rgba(255,214,29, 0.65)', 'rgba(0,169,196, 0.55)', 'rgba(74,207,144, 0.60)', 'rgba(0, 0, 0, 1)'];
const generationColor = "255, 217, 118"
const consumptionColor = "189, 193, 196";
const importColor = "0,0,0";
const chargeColor = "53, 173, 142";
const dischargeColor = "118, 159, 239";
const exportColor = "113, 255, 127"
export const colorsSupernova = ['Pre-Solar', 'Solar Generation', 'Charge', 'Discharge', 'Grid Import', 'Grid Export'];
export const idToColorSupernova = [`rgba(${consumptionColor}, 0.7)`, `rgba(${generationColor}, 0.65)`, `rgba(${chargeColor}, 0.55)`, `rgba(${dischargeColor}, 0.60)`, `rgba(${importColor}, 0.0)`, `rgba(${exportColor}, 0.0)`];
export const idToLineColorSupernova = [`rgba(${consumptionColor}, 0)`, `rgba(${generationColor}, 0)`, `rgba(${chargeColor}, 0)`, `rgba(${dischargeColor}, 0)`, `rgba(${importColor}, 1)`, `rgba(${exportColor},1)`];
export const idToLegendColorSupernova = [`rgba(${consumptionColor}, 0.7)`, `rgba(${generationColor}, 0.65)`, `rgba(${chargeColor}, 0.55)`, `rgba(${dischargeColor}, 0.60)`, `rgba(${importColor}, 1)`, `rgba(${exportColor}, 0.0)`];
export enum SolorToColorSupernova {
  'Pre-Solar' = 'rgba(189, 193, 196, 1)',
  'Solar Generation' = 'rgba(255, 217, 118, 1)',
  'Charge' = 'rgba(53, 173, 142, 1)',
  'Discharge' = 'rgba(118, 159, 239, 1)',
  'Grid Import' = 'rgba(0, 0, 0, 0)',
  'Grid Export' = 'rgba(113, 255, 127,0)'
}
export enum SolorToColorSupernovaLine {
  'Pre-Solar' = 'rgba(189, 193, 196, 0)',
  'Solar Generation' = 'rgba(255, 217, 118, 0)',
  'Charge' = 'rgba(53, 173, 142, 0)',
  'Discharge' = 'rgba(118, 159, 239, 0)',
  'Grid Import' = 'rgba(0, 0, 0, 1)',
  'Grid Export' = 'rgba(113, 255, 127,1)'
}
export enum SolorToNewSolarSupernova {
  'Pre-Solar' = 'Current grid consumption',
  'Solar Generation' = 'Solar generation',
  'Charge' = 'Storage charge',
  'Discharge' = 'Storage discharge',
  'Grid Import' = 'Proposed grid consumption',
  'Grid Export' = 'Grid export'
}
export enum SolorToIdSupernova {
  'Pre-Solar' = 1,
  'Solar Generation' = 2,
  'Charge' = 3,
  'Discharge' = 4,
  'Grid Import' = 5,
  'Grid Export' = 6
}

export enum SolorToNewInfoSupernova {
  'Pre-Solar' = 'Pre-solar grid consumption',
  'Solar Generation' = 'Solar after self consumption',
  'Charge' = 'Storage charge',
  'Discharge' = 'Storage discharge',
  'Grid Import' = 'Grid Import',
  'Grid Export' = 'Grid Export'
}
export enum BarColors {
  'Demand Charge' = '#F2F2F2',
  'TOU' = '#CCCCCC',
  'Tier-5' = '#E0E0E0',
  'Tier-4' = '#E0E0E0',
  'Tier-3' = '#BFBFBF',
  'Tier-2' = '#999999',
  'Tier-1' = '#737373',
  'Consumption' = '#4D4D4D',
  'Tax' = '#474747',
  'Net Excess Generation' = '#3D3D3D',
  'Quantity' = '#333333',
  'Non-Bypassable Charges' = '#3D3D3D',
  'Minimum' = '#6B6B6B',
  'Fixed Charge' = '#1A1A1A',
  'Critical Peak' = '#D9D9D9',
  'On Peak' = '#CCCCCC',
  'Part Peak' = '#A6A6A6',
  'Off Peak' = '#666666',
}