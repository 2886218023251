import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';
import { AuthService } from '../../../helpers/auth.service';
import { SystemDesignService } from '../../system-design/system-design.service';
import { ConfigureModalComponent } from './configure-modal/configure-modal.component';
import { SettingsService } from '../../settings/settings.service';
import { NavigationControllerService } from '@design/services/navigation-controller.service';
import { Router } from '@angular/router';
import { RouteTrackerService } from '@design/services/route-tracker.service';
import { ImageCapturer } from '@design/containers/draw-area/threejs/services/image-capturing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent implements OnInit, OnDestroy {
  userInfo: any;
  menuItems = [
    // { id: 0, name: 'DASHBOARD', label: 'DASHBOARD', href: 'dashboard' },
    { id: 1, name: 'PROJECTS', label: 'PROJECTS', href: 'projects' },
    { id: 2, name: 'TASKS', label: 'TASKS', href: 'tasks' },
    { id: 3, name: 'MANAGE USER', label: 'MANAGE USER', href: 'manage' },
    { id: 4, name: 'SETTINGS', label: 'SETTINGS', href: 'settings' },
  ];
  logo = '';
  logoWidth = 70;
  logoHeight = 50;
  timeoutId!: NodeJS.Timeout;
  savingInProgress: boolean = false;
  subscriptions: Subscription = new Subscription();
  url: string = '';
  isLogout: boolean = false;

  constructor(
    private router: Router,
    public systemDesignService: SystemDesignService,
    private dialog: MatDialog,
    public authService: AuthService,
    private utils: Utils,
    private settingsService: SettingsService,
    private navigationControllerService: NavigationControllerService,
    private routeTrackerService: RouteTrackerService,
    private imageCaptureService: ImageCapturer

  ) {
    this.utils.countryCurr = localStorage.getItem('countryCurr') || '$';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if(this.timeoutId){
      clearInterval(this.timeoutId);
    }
  }

  getServicesOffered() {
    this.subscriptions.add(this.settingsService.getServicesOffered(['services_offered']).subscribe((res: Array<any> | any) => {
      this.settingsService.setServiceOffered(res);
      this.initMenus();
    }, (error: any) => {
      console.log(error);
    }));
  }
  
  ngOnInit(): void {
    this.getServicesOffered();
    this.getNavigationInfo();
  }

  getNavigationInfo() {
    this.subscriptions.add(this.navigationControllerService.canNavigate().subscribe((canNavigate: boolean) => {
      this.savingInProgress = !canNavigate;
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if(!this.savingInProgress){
          if(this.url){
            this.navigateMe(this.url);
          }else if(this.isLogout){
            this.logout();
          }
        }
      }, 2000) as any;
    }));
  }

  navigateMe(url: string) {
    if (this.routeTrackerService.getCurrentUrl() == '/design') {
      this.url = url;
      if (!this.savingInProgress) {
        this.url = '';
        this.authService.enableDesignLoader.next({ val: true, action: 'stop' });
        this.router.navigateByUrl(url);
      } else {
        this.authService.enableDesignLoader.next({ val: true, action: 'start', fromEvent: 'saveDesign' })
      }
    } else {
      this.router.navigateByUrl(url);
    }
  }
  
  initMenus() {

    if (this.authService.getItem('role') == 'employee') {
      this.menuItems = [
        // { id: 0, name: 'DASHBOARD', label: 'DASHBOARD', href: 'dashboard' },
        { id: 1, name: 'PROJECTS', label: 'PROJECTS', href: 'projects' },
        { id: 2, name: 'TASKS', label: 'TASKS', href: 'tasks' },
        { id: 3, name: 'SETTINGS', label: 'SETTINGS', href: 'settings' },
      ];
    } else {
      this.menuItems = [
        // { id: 0, name: 'DASHBOARD', label: 'DASHBOARD', href: 'dashboard' },
        { id: 1, name: 'PROJECTS', label: 'PROJECTS', href: 'projects' },
        { id: 2, name: 'TASKS', label: 'TASKS', href: 'tasks' },
        { id: 3, name: 'SETTINGS', label: 'SETTINGS', href: 'settings' },
        // { id: 5, name: 'PROFILE', label: 'PROFILE', href: 'profile'}
      ];
    }

    const showTasksMenu = this.authService.getItem('showTaskDashBoard');
    if (showTasksMenu && showTasksMenu === 'false') {
      this.menuItems = this.menuItems.filter((obj) => obj.name !== 'TASKS');
    }
    this.userInfo = this.authService.getItem('username');

    // Close the dropdown if the user clicks outside of it
    window.onclick = function (e: any) {
      if (!e.target.matches('.dropbtn')) {
        var myDropdown: any = document.getElementById('myDropdown');
        if (myDropdown) {
          if (myDropdown.classList.contains('show')) {
            myDropdown.classList.remove('show');
          }
        }
      }
    };

    this.subscriptions.add(
      this.systemDesignService.getTenantData().subscribe(
        (response: { imageId: any, mapProvider: string }) => {
          if(response?.mapProvider)
            this.authService.defMapProvider = response?.mapProvider;
          if(response.imageId) { // Condition to avoid 'undefined' campaign-images call for new quote
          this.subscriptions.add(
            this.systemDesignService.getLogo(response.imageId).subscribe(
              (data: string) => {
                this.loadLogo(data);
              },
              (error: any) => {
                this.logo = '../../../../assets/images/login/enact-logo-sm.jpg';
              }
            )
          );
          }
        },
        (error: any) => {
          this.logo = '../../../../assets/images/login/enact-logo-sm.jpg';
        }
      )
    );
  }
  loadLogo(data: any) {
    const that = this;
    const imgurl = 'data:image/jpg;base64,' + data;
    that.logo = 'data:image/jpg;base64,' + data;
    let img = new Image();
    img.src = imgurl;
    img.onload = function (event) {
      const loadedImage: any = event.currentTarget;
      let width = loadedImage.width;
      let height = loadedImage.height;
      let aspectRatio = width / height;
      if (height != 50) {
        that.logoHeight = 50;
        that.logoWidth = that.logoHeight * aspectRatio;
      }
    };
  }
  /* When the user clicks on the button, 
toggle between hiding and showing the dropdown content */
  myFunction() {
    var dropdown: any = document.getElementById('myDropdown');
    dropdown.classList.toggle('show');
  }

  callImageCapture() {
    if (this.routeTrackerService.getCurrentUrl() == '/design') {
      this.imageCaptureService.callImageCapture();
    }
  }

  logout(): void {
    if (this.routeTrackerService.getCurrentUrl() == '/design') {
      this.isLogout = true;
      if (!this.savingInProgress) {
        this.isLogout = false;
        this.authService.enableDesignLoader.next({ val: true, action: 'stop' });
        this.authService.logout();
      } else {
        this.authService.enableDesignLoader.next({ val: true, action: 'start', fromEvent: 'saveDesign' })
      }
    } else {
      this.authService.logout();
    }
  }
  showModal() {
    const dialogRef = this.dialog.open(ConfigureModalComponent, {
      width: '900px',
      height: '560px',
      panelClass: 'proposal-modal',
    });
  }
}
