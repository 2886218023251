<span class="dclose-button" (click)="onClosebtn()">
  <mat-icon>close</mat-icon>
</span>
<h1 *ngIf="data.title" mat-dialog-title class="dialog-title" [innerHTML]="data.title"></h1>
<div mat-dialog-content class="dialog-msg">
  <p [innerHTML]="data.message" style="color:#82868B;font-size:16px"></p>
</div>
<div mat-dialog-actions class="button-container">
  <button mat-button *ngIf="data.showNoBtn"  [ngClass]="{ 'button no-button-danger': (data.title.indexOf('Roof') >-1 || data.title.indexOf('map') >-1), 'button no-button':  (data.title.indexOf('Roof') <= -1 || data.title.indexOf('map') <= -1) }"  (click)="onNoClick()">{{'buttons.no' | translate }}</button>
  <button mat-button *ngIf="data.showYesBtn" [ngClass]="{ 'button yes-button-danger': (data.title.indexOf('Roof') >-1 || data.title.indexOf('map') >-1), 'button yes-button': (data.title.indexOf('Roof') <= -1 || data.title.indexOf('map') <= -1) }" (click)="onYesClick()">
    {{'buttons.yes' | translate }}
  </button>
</div>
