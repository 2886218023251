<div class="loader-overlay">
    <div class="inner-container">
      <img src="../../../../../assets/loader-img.gif" alt="">
      <div class="progress-border" *ngIf="fromEvent != undefined">
        <div class="bar-color" [ngStyle]="{'width': progress +'%'}" style="height:10px"></div>
      </div>
      <div *ngIf="fromEvent != undefined" class="loading-screen">{{
        (fromEvent == 'saveDesign' ? ('sysSavngChnges' | translate ) :
        (fromEvent == 'Design' || fromEvent == '') ? ('designLoader.sysDesignLoad' | translate) :
        ('designLoader.priceFinLoad' | translate)) }}
        <div style="width: 1rem;text-align: left;" class="loading-dots"></div>
      </div>
    </div>
  </div>