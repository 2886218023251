import { Vector3, Mesh } from 'three';

export class RoofTopology {
  // static _vert_id = 0;
  public static roofPatch: string[] = [];
  private static _patches: { [key: string]: string[] } = {};
  public static _vert_id_pos_map: { [key: string]: Mesh } = {};
  static EPSILON = 1e-1;
  static _vert_confirm_map: { [key: string]: boolean } = {};
  constructor() {}

  static addRoofPatch(roofVertices: Mesh[]) {
    this.roofPatch = roofVertices.map((vertex) => this.addVertex(vertex));

    // this._patches.push(this.roofPatch); // TODO: store all pathces in an key-value object using roofId as key

    // return this._patches.length - 1;
  }

  static addVertex(mesh: Mesh): string {
    let index = this.getVertexId(mesh);
    if (index !== undefined) return index;

    const cloneMesh = mesh.clone(); // Object.keys(this._vert_id_pos_map).length
    const uuid: string = cloneMesh.uuid;

    this._vert_id_pos_map[uuid] = mesh.clone();
    return uuid;
  }

  static getVertexId(mesh: Mesh, type: string | undefined = undefined) {
    const vertIdKeys = Object.keys(this._vert_id_pos_map);
    for (let i = 0; i < vertIdKeys.length; ++i) {
      const key = vertIdKeys[i];
      const pos1 = this._vert_id_pos_map[key];

      // Do not compare Z/height values.
      const p1 = mesh.position.clone().setZ(0);
      const p2 = pos1.position.clone().setZ(0);

      if (p1.distanceTo(p2) < this.EPSILON) {
        if (type) {
          if (pos1.userData.meshtype == type) {
            return key;
          }
          continue;
        }
        return key;
      }
    }
    return undefined;
  }

  static getPatch(): string[] {
    return this.roofPatch;
  }

  static removePatch() {
    // this needs to be verified before publishing to production
    this.roofPatch.forEach(id => {
      delete this._vert_id_pos_map[id];
      if (this._vert_confirm_map[id]) {
        delete this._vert_confirm_map[id]
      };
    })
    // this._vert_id = 0;
    this.roofPatch = [];
  }

  static isVertConfirmed(id: string): boolean {
    let val: boolean = this._vert_confirm_map[id] || false;
    // if(!val) {
    //     return false;
    // }
    // else
    return val;
  }

  static getVertexPos(index: string): Mesh {
    return this._vert_id_pos_map[index];
  }

  static setVertexPos(index: string, position: Mesh, overwrite: boolean) {
    const vert: Mesh = RoofTopology.getVertexPos(index);
    let pos = this._vert_id_pos_map[index];

    if (!pos) return false;

    if (!overwrite) return false;

    // vert.position.copy(position);
    this._vert_id_pos_map[index] = position;
    return true;
  }

  static markVertexConfirmed(id: string) {
    this._vert_confirm_map[id] = true;
  }

  static storePatch(rooId: string, roofPatch: string[]) {
    this._patches[rooId] = roofPatch
  }

  static getPatchById(roofId: string) {
    return this._patches[roofId]
  }
}
