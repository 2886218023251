import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { EnactTextCounterComponent } from './enact-text-counter.component';
import { NumbersOnlyDirective } from '@lib-ui/lib/common/directives/numbers-only.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [
        EnactTextCounterComponent,
        NumbersOnlyDirective
    ],
    imports: [
        CommonModule,
        MatRippleModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        TranslateModule
    ],
    exports: [
        EnactTextCounterComponent
    ]
})
export class EnactTextCounterModule { }