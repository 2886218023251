import { ChangeTypes } from '@design/utils/constants';
import { Edge } from './edge.model';
import { SystemDesignObject } from './system-design-object.abstract.model';
import { ConfigurationIds } from './ui-state.model';
import { Vertex } from './vertex.model';
// import { THREE } from 'three.js'

interface IRoofSection {
  id: string;
  name: string
  setback: number;
  setbackVisible: boolean;
  azimuth: number;
  pitch: number;
  parapit: number;
  height: number;
  color: string;
  defaultHeight: number;
  overlappingRoofs: string[];
  edgeIds: Array<Edge['id']>;
  vertexIds: Array<Vertex['id']>;
  changeType?: ChangeTypes;
}

export const flatRoofDefaults = {
  pitch: 0,
  panelTilt: 10,
  setbackFt: 4,
};

export class RoofSection extends SystemDesignObject implements IRoofSection {
  selectConfigId?: boolean | undefined;
  id: string;
  name: string;
  configurationId = ConfigurationIds.RoofSection;

  setback: number;
  setbackVisible: boolean;
  azimuth: number;
  pitch: number;
  parapit: number;
  height: number;
  color: string;
  area: number;
  defaultHeight: number;
  overlappingRoofs: string[];
  edgeIds: Array<Edge['id']>;
  vertexIds: Array<Vertex['id']>;

  gutterId: Edge['id'];
  ridgeId: Edge['id'];
  alignPanels: Edge['id'];
  changeType?: ChangeTypes;

  // obstacleIds: Array<Obstacle['id']>;

  constructor(data: Omit<RoofSection, 'configurationId'>) {
    super();
    this.id = data.id;
    this.name = data.name;
    this.area = data.area;
    this.setback = data.setback;
    this.setbackVisible = data.setbackVisible;
    this.azimuth = data.azimuth;
    this.pitch = data.pitch;
    this.parapit = data.parapit;
    this.height = data.height;
    this.overlappingRoofs = data.overlappingRoofs;
    this.edgeIds = data.edgeIds ?? [];
    this.vertexIds = data.vertexIds ?? [];
    this.color = data.color ?? '#008DAF';
    this.gutterId = data.gutterId;
    this.ridgeId = data.ridgeId;
    this.defaultHeight = data.defaultHeight;
    this.changeType = data.changeType;
    this.alignPanels = data.alignPanels;
  }
}
