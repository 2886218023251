import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import { round } from 'lodash';
import { Subscription, timer } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';

@Component({
  selector: 'enact-text-counter',
  templateUrl: './enact-text-counter.component.html',
  styleUrls: ['./enact-text-counter.component.scss']
})
export class EnactTextCounterComponent implements OnInit, OnDestroy {

  @Input()
  set value(value: number | string) {
    this._value = this.utils.numberFormat(value);
  }

  get value() {
    return this._value;
  }

  private _value!: number | string;

  @Input() step!: number;
  /**
   * @see https://lodash.com/docs/4.17.15#round
   */
  @Input() accuracy: number = 1;
  @Input() title!: string;
  @Input() unit!: string;
  @Input() disabled!: boolean;
  @Input() customStylesObj!: any;
  @Input() smallerVersion: boolean = false;
  @Input() minValue: number | undefined;
  @Input() maxValue: number | undefined;
  @Input() disableIncrement : boolean = false;
  @Input() disableTiltDecrement : boolean = false;
  @Input() applyWholeNumberOnly = false;

  @Output() valueChange = new EventEmitter<number>();
  disableTilt:boolean = false;

  enactTextCounterInputBox!: ElementRef<HTMLInputElement>
  @ViewChild('enactTextCounterInputBox')
  set function(value: ElementRef<HTMLInputElement>) {
    this.enactTextCounterInputBox = value;
    if(this.enactTextCounterInputBox.nativeElement.value === '--') return;
    if(!this.enactTextCounterInputBox.nativeElement.value) {
      this.enactTextCounterInputBox.nativeElement.value = "0";
    }
    this.value = +(this.utils.removeCommasCurrency(this.enactTextCounterInputBox.nativeElement.value).toFixed(2));
    this.roundValue(this.value);
    
  }
  subscription: Subscription = new Subscription();

  constructor(private utils: Utils) { }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  handleInputChange(operation: string) {
    let convertedVal = JSON.parse(JSON.stringify(this.value))
    let value = +this.utils.removeCommasCurrency(convertedVal)
    if (operation === "-") value -= this.step;
    else if (operation === "+") value += this.step;
    let roundedValue = this.roundValue(value);
    this.valueChange.emit(roundedValue);
    this.resetInputBoxToIncomingValue()
  }

  handleValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    let convertedVal = JSON.parse(JSON.stringify(value))
    let roundedValue = this.roundValue(+this.utils.removeCommasCurrency(convertedVal));
    (event.target as HTMLInputElement).value = this.utils.numberFormat(roundedValue) + '';
    this.valueChange.emit(roundedValue);
    this.resetInputBoxToIncomingValue()
  }

  handleKeyPress() {
    this.enactTextCounterInputBox.nativeElement.blur();
  }

  restrictValue(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === '-')
      event.preventDefault();
  }


  roundValue(value: number) {
    return round(value, this.accuracy);
  }

  resetInputBoxToIncomingValue() {
    
    const inputBox = this.enactTextCounterInputBox.nativeElement as HTMLInputElement
    this.subscription.add(timer(0).subscribe(()=>{
      inputBox.value = this.utils.numberFormat(this._value) + ''
    }))
  }

  ngOnInit(): void {
  
  }

}
