export enum GeneralCursors {
  NORMAL = 'normal',
  THREEDCLICKABLE = 'ThreeDClickable',
}

export enum RoofCursors {
    NORMALROOF = 'normalRoof',
    THREEDCLICKABLE = 'ThreeDClickable',
    INVALIDROOFCREATE = 'invalidRoofCreate',
    VALIDROOFDRAWING = 'validRoofDrawing'
}

export enum TreeCursors {
    NORMALTREE = 'normalTree',
    INVALIDTREECREATE = 'invalidTreeCreate',
    VALIDTREECREATE = 'validTreeCreate',
    VALIDTREEPASTE = 'validTreePaste',
    INVALIDTREEPASTE = 'invalidTreePaste',
    THREEDCLICKABLE = 'ThreeDClickable',
    TREEDRAGRESIZE = 'treeDragResize',
    TREEDEFAULT = 'treeDefault'
}

export enum ObstacleCursors {
    NORMALOBSTACLE = 'normalObstacle',
    VALIDOBSTACLEPASTE = 'validObstaclePaste',
    INVALIDOBSTACLEPASTE = 'invalidObstaclePaste',
    THREEDCLICKABLE = 'ThreeDClickable',
    VALIDTREECREATE = 'validTreeCreate',
    INVALIDTREECREATE = 'invalidTreeCreate',
    VALIDRECTPASTE = 'validRectPaste',
    INVALIDRECTPASTE = 'invalidRectPaste',
    VALIDCIRCLEPASTE = 'validCirclePaste',
    INVALIDCIRCLEPASTE = 'invalidCirclePaste',
    VALIDWALKPASTE = 'validWalkPaste',
    INVALIDFREEFORMCREATE = 'invalidFreefoofCreate',
    VALIDDRAWING = 'validDrawing',
    ROTATIONANCHORRECT = 'rotationAnchorRect',
    ROTATIONANCHORWALK = 'rotationAnchorWalk'
}

export enum PanelArrayCursors {
    NORMALPANEL = 'normalpanel',
    VALIDPANELARRAYADD = 'validPanelArrayAdd',
    INVALIDPANELARRAYADD = 'invalidPanelArrayAdd',
    INVALIDPANELDRAW = "invalidPanelDraw",
    INVALIDROOFCREATE = 'invalidRoofCreate',
    VALIDDRAWING = 'validDrawing',
    INVALIDSINGLEPANELADD = 'invalidSinglePanelAdd'
}
