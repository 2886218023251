<div class="title" mat-dialog-title>{{data?.header}}
  <div>
    <mat-icon aria-hidden="false" aria-label="Example home icon" (click)="closeModel()">close
    </mat-icon>
  </div>
</div>
<div mat-dialog-content class="dialog-content">
  <ng-container *ngIf="!data?.projectList.length; else other_content">
    <div class="no-data-found">No Data Available</div>
  </ng-container>
  <ng-template #other_content>
    <app-table [columns]="data?.columns" [data]="data?.projectList" [isPagination]="data?.isPagination"></app-table>
  </ng-template>
</div>