import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import { Observable, of} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {Utils} from '../../../helpers/utils';
import { AuthService } from 'src/app/helpers/auth.service';
import { CustomDatePayload, DynamicDateApiResponse, toCustomDate } from 'src/app/models/dynamic-date-chart.model';
import { catchError, map, tap } from "rxjs/operators";
import { Status } from 'src/app/models/simple.types';
import { QuotesDetailsApiPut } from 'src/app/services/api/quotes/details/_qrn.put';

@Injectable({
  providedIn: 'root'
})
export class SysresultsService {

  constructor(private http: HttpClient, private cookieService: CookieService, private authService: AuthService,
    private quoteApi: QuotesDetailsApiPut
    ) { }

  // getAnnualConsumption(): Observable<any> {
  //   const apiUrl = `${environment.mockApiUrl}annualConsumption`;
  //   return this.http.get<any>(apiUrl);
  // }
  // getAnnualGeneration(): Observable<any> {
  //   const apiUrl = `${environment.mockApiUrl}annualGeneration`;
  //   return this.http.get<any>(apiUrl);
  // }
  // getTieredAndBillData(): Observable<any> {
  //   const apiUrl = `${environment.mockApiUrl}tieredAndBillData`;
  //   return this.http.get<any>(apiUrl);
  // }

  getQuoteAsJSON() {
    const path = `quotes?quote_id=${this.authService.getItem('quote_id','localStorage')}
                        &source=${this.authService.getItem('tenant_id')}
                        &revision_number=1
                        &quote_status=any
                        &role=some&source_email=test@test.com&sessionId=${this.authService.getItem('sessionId').trim()}
                        &qrn=${this.authService.getItem('qrn','localStorage')}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.get(apiUrl);
  }
  getPVWattsPanelHourly(queryData:any){
    const apiUrl = `${Utils.getBackendBaseURL()}pvwattsPanels/hourly`;
    return this.http.post(apiUrl, queryData);
  }
  quoteMonthlyTiersPostSolar(formdata:any){
    const apiUrl = `${Utils.getBaseurl()}quotes/quoteMonthlyTiersPostSolar`;
    // /nact-backend/quotes/quoteMonthlyTiersPostSolar
    // quotes/monthly-tier-post-solar
    return this.http.post(apiUrl, formdata);
  }
  getPreAndPostSolarDayAverageData(totalNoOfMeter:any){
    const path = `lead/pre-post-solar-data/day-average?crn=${this.authService.getItem('crn','localStorage')}&total_meters=${totalNoOfMeter}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.get(apiUrl);
  }
  prePostSolarData(totalNoOfMeter: any, defaultMapOption: string) {
    const path = `lead/pre-post-solar-data?crn=${this.authService.getItem('crn','localStorage')}&total_meters=${totalNoOfMeter}&default_map_option=${defaultMapOption}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.get(apiUrl);
  }
  updateSysResultV1(quoteData:any) {
    return this.quoteApi.updateSystemResults(quoteData);
  }
  getCustomGeneration(accountId:any, isEditQuote:any, quoteId:any, revisionNo:any) {
    const path = `quotes/custom-generation-v1?account_id=${accountId}&isEditQuote=${isEditQuote}&quote_id=${quoteId}&rev_no=${revisionNo}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.get(apiUrl);
  }
  getCurrencyCode(){
      return this.http.get('assets/json/countryCodeToCurrencyCode.json');
  }
  getWeatherStationLatLng(){
    return this.http.get('assets/json/localeWeatherStationLatLng.json');
  }

  getCustomDateData(payload: CustomDatePayload): Observable<DynamicDateApiResponse> {
    return this.http.post(`${environment.newApiUrl}lead/load-analysis-custom`, payload)
      .pipe(
        map(toCustomDate),
        catchError((error) => of({ data: [], status: Status.Error, error: error } as DynamicDateApiResponse))
      )
  }
}
