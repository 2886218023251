import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromVertexActions } from './vertex.action';
import { VertexState } from './vertex.state';
import { Vertex } from '@design/models/vertex.model';

export const adapter: EntityAdapter<Vertex> = createEntityAdapter<Vertex>({ });

export const vertexInitialState: VertexState = adapter.getInitialState();

export const { selectAll } = adapter.getSelectors();

export const vertexReducer: ActionReducer<VertexState> = createReducer(
  vertexInitialState,
  on(fromVertexActions.AddMany, (state, data) => {
    return adapter.addMany(data.vertices, state);
  }),
  on(fromVertexActions.UpsertMany, (state, { vertices }) => {
    return adapter.upsertMany(vertices, state);
  }),
  on(fromVertexActions.DeleteMany, (state, { vertexIds }) => {
    const safeToDeleteIds: Vertex['id'][] = [];
    for (const id of vertexIds) {
      const vertex = state.entities[id]
      if(vertex && vertex.association.length === 0)
        safeToDeleteIds.push(id)
    }
    return adapter.removeMany(safeToDeleteIds, state);
  }),
);
