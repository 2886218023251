
import { UploadDetailsReadParams, uploadSuccess } from "@design/models/additional-tools.model";
import { SaveStatus } from "@design/models/save-status.model";
import { Update } from "@ngrx/entity";
import { createAction, props } from "@ngrx/store";

export namespace fromAdditionalToolsActions {

    // export const DrawEnergyComplete = createAction('[Additional Tool] initialize data');

    export const AddEnergy = createAction(
        '[Additional Tool] Energy selected',
        props<{uploadDetailsReadParams:UploadDetailsReadParams}>()
      );

      export const MetetLoaded = createAction(
        '[Additional Results] consumerObj MetetLoaded'
      );

      //getCPFRates
      export const getCPFRates = createAction(
        '[Additional Tools] getCPFRates loaded',
        props<{reqBody: any}>()
      );

      export const CPFRatesSuccess = createAction(
        '[Additional Tools Results] Update success',
        props<{resp:any}>()
      );
    
      export const CPFRatesFailure = createAction(
        '[Additional Tools Results] Update failed',
        props<{ error: any }>()
      );
      
      export const AdditionalToolsConfigurationChange = createAction(
        '[AdditionalTools Configuration] Update AdditionalTools configuration',
        props<{ resp: any }>()
      );

      export const AdditionalToolsConfigurationChangeOnReload = createAction(
        '[AdditionalTools Configuration] Update AdditionalTools configuration on reload',
        props<{ resp: any }>()
      );

      export const UpdateMeter = createAction(
        '[Engery Configuration] UpdateMeter',
        props<{ resp : Update<any> }>()
      );
      export const LoadPvRate = createAction(
        '[Engery Configuration] LoadPvRate',
        props<{ resp : Update<any> }>()
      );
      export const UpdatePvRate = createAction(
        '[Engery Configuration] UpdatePvRate',
        props<{ UpdatedPvRate : Update<any> }>()
      );
      
      export const UploadCustomGenFile = createAction(
        '[uploadCustomGenFile Results] Custom Gen File uploading',
        props<{uploadDetailsReadParams:FormData}>()
      );

      export const UploadCustomGenSuccess = createAction(
        '[UploadDetailsReadParams Results] Update success',
        props<{uploadSuccess:uploadSuccess}>()
      );
    
      export const UploadCustomGenFailure = createAction(
        '[UploadDetailsReadParams Results] File upload failed'
      );

      export const UploadCustomFail = createAction(
        '[UploadDetailsReadParams Results] File failed'
      );

      export const SetGeneration = createAction(
        '[AdditionalTools/Generation] Generation from Quote',
        props<{genData:any}>()
      );

      export const MonitoringFetchSuccess = createAction(
        '[AdditionalTools/monitoring] fetch monitoring Success',
        props<{ preferences: any }>()
      );

      export const MonitoringFetchFailure = createAction(
        '[AdditionalTools/monitoring] fetch monitoring Failure',
        props<{ error: any }>()
      );

      export const UpdateMonitoringSys = createAction(
        '[Engery Configuration] Monitoring System',
        props<{ selectedMonitoringSys : Update<any> }>()
      );
      export const SavePostPvRate = createAction(
        '[Engery Configuration] Save post pv rate',
        props<{triggerUndoRedoSnapshot?: boolean}>()
      ); 
      export const PanelDegredationChange = createAction(
        '[Engery Configuration] panel degradationChanged.calculate new bill'
      );

      export const setHardwareAPIStatus = createAction(
        '[SystemDesign] hardware API status',
        props<{ setHardwareAPIStatus: SaveStatus }>()
      );

      export const setquoteAPIStatus = createAction(
        '[SystemDesign] quote API status',
        props<{ quoteAPIStatus: SaveStatus }>()
      );

      export const setBackgroundImageAPIStatus = createAction(
        '[SystemDesign] backgroundImage API status',
        props<{ backgroundImageAPIStatus: SaveStatus }>()
      );

      export const setHouseViewImageAPIStatus = createAction(
        '[SystemDesign] houseViewImage API status',
        props<{ houseViewImageAPIStatus: SaveStatus }>()
      );

      export const SetFileInfo = createAction(
        '[Custom Generation] File Info setup',
        props<{ fileContent: any }>()
      );

      export const SetCustomGenInfo = createAction(
        '[Custom Generation] Generation Info setup',
        props<{ genInfo: any }>()
      );

      export const ResetCustomGeneration = createAction(
        '[Custom Generation] Reset custom generation',
      );

      export const ChangeCustomToHourly = createAction(
        '[Custom Generation] Change Custom To Hourly',
        props<{ triggerUndoRedoSnapshot?: boolean }>()
      );

      export const SetSimulateCustom = createAction(
        '[Custom Generation] Disable simlate report for custom',
        props<{disbaleFlag: boolean}>()
      );

      export const setMapLoadingStatus = createAction(
        '[SystemDesign] mapLoadingStatus',
        props<{ mapLoadingStatus: SaveStatus }>()
      );

      export const setSceneRestoreStatus = createAction(
        '[SystemDesign] sceneLoadingStatus',
        props<{ sceneRestoreStatus: SaveStatus }>()
      );
}