import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Design } from '@api/models/design.model';
import { Finance } from '@api/models/finance.model';
import { Hardware } from '@api/models/hardware.model';
import { QuoteV1 } from '@api/models/qoute.v1.model';
import { QuoteService } from '@api/services/quote.service';
import { keyBy } from 'lodash';
import { BehaviorSubject, Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelArrayTypeVO } from 'src/app/modules/system-design/panelconfig/inetrfaces/panel-array-type-vo';
import { SystemDesignService } from 'src/app/modules/system-design/system-design.service';

interface ReadParams {
  qrn: string;
}

@Injectable()
export class QuotesDetailsAPI {
  quote$ = new BehaviorSubject<QuoteV1 | null>(null);
  design$ = new BehaviorSubject<Design | null>(null);
  hardware$ = new BehaviorSubject<Hardware | null>(null);
  panelType$ = new BehaviorSubject<{ id: string, name: string, value: string }[] | null>(null);

  constructor(private readonly quote: QuoteService) { }

  read(params: ReadParams) {
    return forkJoin([
      this.quote.getQuote(params.qrn),
      this.quote.getQuoteDesign(params.qrn),
      this.quote.getQuoteHardware(params.qrn),
    ]).pipe(
      map(([quote, design, hardware]) => {
        this.quote$.next(quote.data);
        this.design$.next(design.data);
        this.hardware$.next(hardware.data);
        return this.mapDesignAndHardwareToDetails(
          params,
          quote.data,
          design.data,
          hardware.data
        );
      })
    );
  }

  getArrayTypeName(arrTypeName: string) {
    let panelsType = this.panelType$.getValue();
    if (panelsType)
      return arrTypeName ? panelsType?.filter(f => f.name == arrTypeName)[0]?.value : panelsType[0]?.value;
    else return '11'
  }

  getArrayTypeName1(arrTypeName: string) {
    const panelsType: any = this.panelType$.getValue();
    const arrTypeObj = panelsType?.filter((f: any) => f.name == arrTypeName) ?? [];
    if (arrTypeObj.length) {
      return arrTypeObj[0]?.name;
    }
    else return panelsType?.[0]?.name;
  }


  mapDesignAndHardwareToDetails(
    params: ReadParams,
    quote: QuoteV1 | any,
    design: Design,
    hardware: Hardware
  ) {
    return {
      address1: null,
      annualBatteryUse: quote?.systemResults?.annualBatteryUse,
      annul_electric_bill: quote?.systemResults?.annualElectricBill,
      array_type_name: this.getArrayTypeName1(hardware?.panel?.arrayType),
      array_type_value: this.getArrayTypeName(hardware?.panel?.arrayType),
      avoidedCost: quote?.systemResults?.avoidedCost,
      batteryConfig: {
        BatteryDegradation: hardware?.battery?.batteryDegradation,
        BatteryModelnumber: hardware?.battery?.batteryModelnumber,
        ChargeEfficiency: hardware?.battery?.chargeEfficiency,
        controlStrategy: hardware?.battery?.controlStrategy,
        coupling: hardware?.battery?.coupling,
        DischargeEfficiency: hardware?.battery?.dischargeEfficiency,
        inv_eff: hardware?.inverter?.efficiency,
        isSgip: hardware?.battery?.isSgip,
        ManufacturerName: hardware?.battery?.manufacturerName,
        MaxChargePower: hardware?.battery?.maxChargePower,
        MaxDepthOfDischarge: hardware?.battery?.maxDepthOfDischarge,
        MaxDischarge: hardware?.battery?.maxDischarge,
        NamePlateCapacity: hardware?.battery?.namePlateCapacity,
        numberOFBattery: hardware?.battery?.numberOFBattery,
        reserve: hardware?.battery?.reserve,
        totalAvaialbleCapacity: hardware?.battery?.totalAvailableCapacity,
        totalPowerOutput: hardware?.battery?.totalPowerOutput,
        totalRatedCapacity: hardware?.battery?.totalRatedCapacity,
        totalUsableCapacity: hardware?.battery?.totalUsableCapacity,
        unitCost: hardware?.battery?.unitCost,
        Description: null // not found
        
      },

      backgroundImageBound: {
        east: design?.backgroundImage?.bound?.east,
        north: design?.backgroundImage?.bound?.north,
        south: design?.backgroundImage?.bound?.south,
        west: design?.backgroundImage?.bound?.west,
      },

      backgroundImageId: design?.backgroundImage?.id,

      bill_type: null,
      city /** @property @missing */: null,
      combined_img: design?.combinedImage,
      costAdderDetails: null,
      current_annual_consumption: quote?.systemResults?.currentAnnualConsumption,
      custGenUpload: quote?.additionalChoices?.customGenUpload,
      custom_generation_format: quote?.additionalChoices?.customGenFormat,
      dayTimeConsumption: quote?.systemResults?.dayTimeConsumption,
      exportTariff: quote?.systemResults?.exportTariff,
      exportYear1: quote?.systemResults?.exportYear1,
      importYear1: quote?.systemResults?.importYear1,
      monthly_post_solar_consumption: quote?.systemResults?.monthlyPostSolarConsumption,
      selfConsumptionSolarOnly: quote?.systemResults?.selfConsumptionSolarOnly,
      selfConsumptionSolarPlusStorage: quote?.systemResults?.selfConsumptionSolarPlusStorage,
      solrad_annual: quote?.systemResults?.solradAnnual,
      solrad_monthly: quote?.systemResults?.solradMonthly,
      postSolarAnnualBillWithoutBattery: quote?.systemResults?.postSolarAnnualBillWithoutBattery,
      isNEM: quote?.systemResults?.isNEM,
      dc_ac_ratio: hardware?.inverter?.dcAcRatio,
      default_cover_image: null,
      derate: quote?.systemResults?.derate,
      emailTemplateId: null,
      energy_replacement: quote?.systemResults?.energyReplacement,
      solarGeneration_Monthly: quote?.systemResults?.solarGenerationMonthly,
      finCost /** @property {FinCost} finCost */: null,
      fitRebate: quote?.systemResults?.fitRebate,
      GACC_solar_energy_generation: null,
      img_url: design.imageUrl,
      IncomeTaxRebate: quote?.systemResults?.incomeTaxRebate,
      incr_bill_rate: quote?.systemResults?.billInflationRate,
      inv_eff: hardware?.inverter?.efficiency,

      /** @property {InverterDetail[]} inverterDetails */
      inverterDetails: this.mapInverterDetails(hardware?.inverter?.configured),

      inverter_model: null,
      inverter_model_number_value: null,
      keepOuts: design?.keepOuts,
      last_update /** @property @missing {number} last_update */: null,
      module_datasheetURL: hardware?.panel?.datasheetURL,
      module_logoURL: hardware?.panel?.logoURL,
      module_model_number: hardware?.panel?.modelNumber,
      module_notes: hardware?.panel?.notes,
      module_quantity: hardware?.panel?.quantity,
      module_select: hardware?.panel?.name,
      module_type: hardware?.panel?.moduleType,
      monitoring_system: hardware?.monitoringSystem?.name,
      new_annual_electric_bill: quote?.systemResults?.newAnnualElectricBill,
      operationAndMaintenanceDetails: null,
      panelDegradation: hardware?.panel?.degradation,
      panelDetails: design?.panel?.details?.length ? design?.panel?.details : [],
      panelShadingMap: design?.panel?.shadingMap,
      panels: this?.panelGroupsMapping(design, hardware?.panel),
      postPVName: quote?.systemResults?.postPVName,
      productionRatio: quote?.systemResults?.productionRatio,
      proposedSystemSizeAC: quote?.systemResults?.proposedSystemSizeAC,
      pv_sysytem_size_dc: quote?.systemResults?.pvSystemSizeDC,
      quote_type: quote?.quoteType,
      racking: null,
      rebate: quote?.systemResults?.rebate,
      savings25B: null,
      /** @property {number[]} selectedCostAdder Not found  */
      selectedCostAdder: [],
      selectedTool: design?.map?.selected,
      solar_energy_generation: quote?.systemResults?.solarGenerationAnnual,
      source_email: null,
      source_first_name: null,
      source_last_name: null,
      srecAutoCredits: '[]',
      state: null,
      tax_rate: null,
      utilityBillReplacement: quote?.systemResults?.utilityBillReplacement,
      utility_electric_rate_escalator: quote?.systemResults?.utilityRateEscalator,
      version: null,
      watts_per_module: hardware?.panel?.configuration?.length ? hardware?.panel?.configuration[0]?.wattsPerModule : null,
      zip: null, // mapping not defined
      '25yearPostSolarConsumption': quote?.systemResults?.year25PostSolarConsumption,
      type: null, // mapping not defined
      revision_number: quote?.revisionNumber, // mapping not defined
      qrn: params?.qrn,
      visibleMap: design?.map,
      roofOutlines: design?.roofOutlines
    };
  }

  mapInverterDetails(inverter: any) {
    return inverter?.map((i: any) => ({
      inverter_model: i?.manufacturer,
      inverter_model_number: i?.model,
      inverter_model_number_value: i?.description,
      PowerRating: i?.powerRating,
      unitCost: i?.unitCost,
      inverter_quantity: i?.quantity,
      utilized_capacity: i?.utilizedCapacity,
    }))
  }

  panelGroupsMapping(
    { panel }: Design,
    hardwarePanel: Hardware['panel']
  ) {
    const panelIds = panel?.groups?.map((p) => p.id);

    const panelGroupsHash = keyBy(panel?.groups, 'id');

    const hardwarePanelConfigHash = keyBy(hardwarePanel?.configuration, 'id');

    panelIds?.map((pId) => {
      if (!panelGroupsHash[pId].position) panelGroupsHash[pId].position = [];
    });

    const mapHardwarePanelConfig = (panelId: string) => {

      const config = hardwarePanelConfigHash[panelId];
      const panelConfig = panelGroupsHash[panelId];
      if (!config) return null;
      return {
        azimuth: config.azimuth,
        setback: config.setback,
        moduleId: config.moduleId,
        modelId: config.modelId,
        panelType: config?.panelType,
        panelDegradation: hardwarePanel?.degradation,
        panelOrientation: config?.orientation,
        arrayType: config?.arrayType,
        panelSize: config?.panelSize,
        panelWidth: config?.panelWidth,
        panelHeight: config?.panelHeight,
        panelTilt: config?.panelTilt,
        tiltType: config?.tiltType,
        gapY: config?.gapY,
        roofTilt: config?.roofTilt,
        shadingValue: config?.shadingValue,
        shadingType: config?.shadingType,
        watts: config?.wattsPerModule,
        gapX: config?.gapX,
        rackingManufacturer: config?.rackingManufacturer,
        rackingSystem: config?.rackingSystem,
        isEdgeSetback: config?.isEdgeSetback,
        path: panelConfig?.path,
        totalWatts: config?.totalWatts,
        numberOfPanels: config?.numberOfPanels,
        edgeSetbackArray: config?.edgeSetbackArray,
        noOfRows: config?.noOfRows,
        noOfColumns: config?.noOfColumns,
        frameSpace: config?.frameSpace,
        frameHeight: config?.frameHeight,
        clampSpace: config?.clampSpace,
        peakSpace: config?.peakSpace,
        azimuth2: config?.azimuth2,
        noOfPanelEast: config?.noOfPanelEast,
        noOfPanelWest: config?.noOfPanelWest ? config?.noOfPanelWest : 0,
      };
    };


    return panelIds?.map((panelId) => ({
      id: panelId,
      path: panelGroupsHash[panelId].path,
      configuration: mapHardwarePanelConfig(panelId),
      totalWatts: hardwarePanelConfigHash[panelId]?.totalWatts,
      numberOfPanels: hardwarePanelConfigHash[panelId]?.numberOfPanels,
      wattsPerModule: hardwarePanelConfigHash[panelId]?.wattsPerModule,
      panelsArr: panelGroupsHash[panelId]?.position?.map((pa) => ({
        id: pa?.id,
        draggable: pa?.draggable,
        visible: pa?.visible,
        path: pa?.path,
        map: pa?.map,
      })),
    }));
  }

  mapDesignHardwareAndFinanceToDetails(
    params: ReadParams,
    quote: QuoteV1,
    design: Design,
    hardware: Hardware,
    finance: Finance
  ) {
    return {
      ...this.mapDesignAndHardwareToDetails(params, quote, design, hardware),
      operationAndMaintenanceDetails: {
        profileName: finance.costData.profile,
        itemDetails: [
          {
            name: finance?.costData?.onm?.year1?.description,
            description: finance?.costData?.onm?.year1?.description,
            priceType: finance?.costData?.onm?.year1?.priceType,
            selected: finance?.costData?.onm?.year1?.selected,
            price: finance?.costData?.onm?.year1?.price,
            quantity: finance?.costData?.onm?.year1?.quantity,
            totalCost: finance?.costData?.onm?.year1?.totalCost,
            escalator: finance?.costData?.onm?.year1?.escalator,
            term: finance?.costData?.onm?.year1?.term,
          },
        ],
      },
    };
  }
}

