import { createAction, props } from '@ngrx/store';
import { Frame } from '@design/models/frame.model';

export namespace fromFrameActions {
  export const AddOne = createAction(
    '[Draw Area] add one frame',
    props<{ frame: Frame }>()
  );

  export const AddMany = createAction(
    '[Draw Area] add many frames',
    props<{ frames: Frame[] }>()
  );
  export const UpsertOne = createAction(
    '[Draw Area] upsert one frame',
    props<{ frame: Frame }>()
  );
  export const DeleteMany = createAction(
    '[Draw Area] delete many frames',
    props<{ frameIds: Frame['id'][] }>()
  );
  export const DeleteOne = createAction(
    '[Draw Area] delete one frame',
    props<{ frameId: Frame['id'] }>()
  );
  
  export const MarkStructuresAsModified = createAction(
    '[Frame] Update Frames',
    props<{ frameIds: string[], isDefaultStructure: boolean }>()
  );

  export const UpsertMany = createAction(
    '[Draw Area] upsert many frames',
    props<{ frames: Frame[] }>()
  );
  
}
