<div class="footer">
  <div class="footer-content" style="display: block;">
    <span class="bold primary font16"> <span >ENACT SYSTEMS INC. &copy; {{currentYear}}-{{currentYear + 1}}</span>
    </span> &nbsp; &nbsp; <span class="social-icons">
      <a href="https://twitter.com/enactsystems" target="_blank"><img src="../../../../assets/images/social/twitter.png"
          class="size27" /></a>
      <a href="https://www.facebook.com/ENACTSYSTEMS" target="_blank"><img 
          src="../../../../assets/images/social/facebook.png" style="height: 27px;" /></a>
      <a href="http://enact-systems.com/blog/" target="_blank"><img src="../../../../assets/images/social/rss.png"
          class="size27" /></a>
    </span>
  </div>
</div>
