import { Injectable } from '@angular/core';
import { QuoteService } from '@api/services/quote.service';
import { forkJoin } from 'rxjs';
import { QuoteDataVo } from 'src/app/modules/system-design/interfaces/quote-data-vo';
import { KeepoutVO } from 'src/app/modules/system-design/panelconfig/inetrfaces/keepout-vo';
import { QuotesDetailsAPI } from '../details';
import { merge, omit } from 'lodash';

export interface ReadParams {
  qrn: string;
}

interface QuoteDataV0 extends Omit<QuoteDataVo, 'keepOuts'> {
  keepOuts: KeepoutVO[];
}

/**
 * @todo Update the interfaces
 */

@Injectable({ providedIn: 'root' })
export class QuotesDetailsApiPut {
  constructor(
    private readonly quote: QuoteService,
    private readonly quoteDetailsRequest: QuotesDetailsAPI
  ) { }

  update(payload: QuoteDataV0) {
    return forkJoin([
      this.quote.updateQuoteSystemResults(this.mapDetailsToQuote(payload)),
      this.quote.updateQuoteDesign(this.mapDetailsToDesign(payload)),
      this.quote.updateQuoteHardware(this.mapDetailsToHardware(payload)),
    ]);
  }

  updateAll(payload: QuoteDataV0, isQuote = false, isDesign = false, isHardware = false, isFinance = false) {
    let urls = [];
    if (isQuote) urls.push(this.quote.updateQuoteSystemResults(this.mapDetailsToQuote(payload)))
    if (isDesign) urls.push(this.quote.updateQuoteDesign(this.mapDetailsToDesign(payload)))
    if (isHardware) urls.push(this.quote.updateQuoteHardware(this.mapDetailsToHardware(payload)))
    return forkJoin(urls);
  }



  updateHardware(payload: QuoteDataV0) {
    return this.quote.updateQuoteHardware(this.mapDetailsToHardware(payload));
  }

  updateSystemResults(payload: QuoteDataV0) {
    return this.quote.updateQuoteSystemResults(this.mapDetailsToQuote(payload));
  }

  updateDesign(payload: QuoteDataV0) {
    return this.quote.updateQuoteDesign(this.mapDetailsToDesign(payload));
  }

  mapDetailsToDesign(details: QuoteDataV0) {
    const payload =
    // merge(this.quoteDetailsRequest.design$.getValue(),
    {
      _id: 'string', // @todo find the correct replacement
      keepOuts: details?.keepOuts?.map((k) => ({
        type: k?.type,
        path: k?.path,
        height: k?.height,
        width: k?.width,
        rotateAngle: k?.rotateAngle,
        originalPath: k?.originalPath,
        length: k?.length,
        radius: k?.radius,
        setback: k?.setback,
        panelsCoveredbyKeepout: k?.panelsCoveredbyKeepout,
        id: k?.id,
        center: k?.center,
        shadowPath: k?.shadowPath
      })),
      roofOutlines: details?.roofOutlines?.map((r) => ({
        id: r.id,
        path: r.path,
        type: r.type,
        tilt: r.tilt,
        roofHeight: r.roofHeight,
        parapetHeight: r.parapetHeight,
      })),
      map: {
        zoom: details?.visibleMap?.zoom,
        center: details?.visibleMap?.center,
        maxZoomLimit: details?.visibleMap?.maxZoomLimit,
        /** @todo @missing */
        // selected: 'string',
        bounds: details?.visibleMap?.bounds,
        selected: details?.selectedTool,
      },
      panel: {
        details: details?.panelDetails,
        groups: details?.panels?.map((p) => ({
          id: p?.id,
          path: p?.path,
          panelOrientation: p?.configuration?.panelOrientation,
          position: p?.panelsArr?.length ? p?.panelsArr.map((pa) => ({
            id: pa?.id,
            draggable: pa?.draggable,
            visible: pa?.visible,
            path: pa?.path,
            map: pa?.map,
          })) : [],
        })),
        shadingMap: details?.panelShadingMap
      },
      imageUrl: details?.img_url,
      qrn: details.qrn,
    }
    // );
    return omit(payload, '_id');
  }

  mapDetailsToHardware(details: QuoteDataV0) {
    const payload =
    // merge(this.quoteDetailsRequest.hardware$.getValue(),
    {
      qrn: details.qrn,
      battery: details.batteryConfig
        ? {
          numberOFBattery: details?.batteryConfig?.numberOFBattery,
          manufacturerName: details?.batteryConfig?.ManufacturerName,
          unitCost: details?.batteryConfig?.unitCost,
          totalPowerOutput: details?.batteryConfig?.totalPowerOutput,
          maxDepthOfDischarge: details?.batteryConfig?.MaxDepthOfDischarge,
          maxChargePower: details?.batteryConfig?.MaxChargePower,
          maxDischarge: details?.batteryConfig?.MaxDischarge,
          batteryDegradation: details?.batteryConfig?.BatteryDegradation,
          controlStrategy: details?.batteryConfig?.controlStrategy,
          totalUsableCapacity: details?.batteryConfig?.totalUsableCapacity,
          namePlateCapacity: details?.batteryConfig?.NamePlateCapacity,
          coupling: details?.batteryConfig?.coupling,
          reserve: details?.batteryConfig?.reserve,
          batteryModelnumber: details?.batteryConfig?.BatteryModelnumber,
          chargeEfficiency: details?.batteryConfig?.ChargeEfficiency,
          isSgip: details?.batteryConfig?.isSgip,
          totalAvailableCapacity:
            details?.batteryConfig?.totalAvaialbleCapacity,
          totalRatedCapacity: details?.batteryConfig?.totalRatedCapacity,
          dischargeEfficiency: details?.batteryConfig?.DischargeEfficiency,
        }
        : {},
      inverter: {
        dcAcRatio: details?.dc_ac_ratio,
        /** @todo @missing efficiency  */
        efficiency: details?.inv_eff,
        configured: details?.inverterDetails?.length ? details?.inverterDetails.map((i) => ({
          model: i?.inverter_model_number,
          description: i?.inverter_model_number_value,
          powerRating: i?.PowerRating,
          unitCost: i?.unitCost,
          quantity: i?.inverter_quantity,
          utilizedCapacity: i?.utilized_capacity,
          manufacturer: i?.inverter_model,
        })) : [],

      },
      panel: {
        configuration: details?.panels?.map((p) => ({
          id: p?.id,
          panelWidth: p?.configuration?.panelWidth,
          panelHeight: p?.configuration?.panelHeight,
          tiltType: p?.configuration?.tiltType,
          shadingValue: p?.configuration?.shadingValue,
          // path: p?.path,
          orientation: p?.configuration?.panelOrientation,
          roofTilt: p?.configuration?.roofTilt,
          rackingSystem: p?.configuration?.rackingSystem,
          gapX: p?.configuration?.gapX,
          azimuth: p?.configuration?.azimuth,
          isEdgeSetback: p?.configuration?.isEdgeSetback,
          panelTilt: p?.configuration?.panelTilt,
          totalWatts: p?.totalWatts,
          rackingManufacturer: p?.configuration?.rackingManufacturer,
          numberOfPanels: p?.numberOfPanels,
          setback: p?.configuration?.setback,
          edgeSetbackArray: p?.configuration?.edgeSetbackArray,
          arrayType: p?.configuration?.arrayType,
          panelSize: p?.configuration?.panelSize,
          gapY: p?.configuration?.gapY,
          shadingType: p?.configuration?.shadingType,
          panelType: p?.configuration?.panelType,
          wattsPerModule: p?.configuration?.watts,
          degradation: p?.configuration?.panelDegradation,
          moduleId: p?.configuration?.moduleId,
          modelId: p?.configuration?.modelId,


        })),
        modelNumber: details?.module_model_number,
        quantity: details?.module_quantity,
        name: details?.module_select,
        unitCost: details?.module_unit_cost,
        degradation: details?.panelDegradation,
        arrayType: details?.array_type_name,
        moduleType: details?.module_type,
      },
      monitoringSystem: { name: details?.monitoring_system },
    }
    // );
    // return details.batteryConfig?.ManufacturerName ? omit(payload, '_id') : omit(payload, ['_id', 'battery'])

    return omit(payload, '_id');
  }

  mapDetailsToQuote(details: QuoteDataV0) {
    const payload = merge(this.quoteDetailsRequest.quote$.getValue(), {
      _id: 'string',
      qrn: details?.qrn,
      revisionNumber: details?.revision_number,
      systemResults: {
        utilityBillReplacement: details?.utilityBillReplacement,
        year25PostSolarConsumption: details['25yearPostSolarConsumption'],
        exportYear1: details?.exportYear1,
        importYear1: details?.importYear1,
        selfConsumptionSolarOnly: details?.selfConsumptionSolarOnly,
        solarGenerationMonthly: details?.solarGeneration_Monthly,
        solradAnnual: details?.solrad_annual,
        solradMonthly: details?.solrad_monthly,
        exportTariff: details?.exportTariff,
        monthlyPostSolarConsumption: details?.monthly_post_solar_consumption,
        annualBatteryUse: details?.annualBatteryUse,
        energyReplacement: details?.energy_replacement,
        dayTimeConsumption: details?.dayTimeConsumption,
        newAnnualElectricBill: details?.new_annual_electric_bill,
        productionRatio: details?.productionRatio,
        selfConsumptionSolarPlusStorage: details?.selfConsumptionSolarPlusStorage,
        solarGenerationAnnual: details?.solar_energy_generation,
        derate: details?.derate,
        proposedSystemSizeAC: details?.proposedSystemSizeAC,
        pvSystemSizeDC: details?.pv_sysytem_size_dc,
        currentAnnualConsumption: Number(details?.current_annual_consumption),
        avoidedCost: Number(details?.avoidedCost),
        annualElectricBill: Number(details?.annul_electric_bill),
        fitRebate: details?.fitRebate,
        rebate: details?.rebate,
        utilityRateEscalator: details?.utility_electric_rate_escalator,
        billInflationRate: details?.incr_bill_rate,
        incomeTaxRebate: details?.IncomeTaxRebate,
        postPVName: details?.postPVName,
        isNEM: details?.isNEM,

      },
      quoteId: details?.quote_id,
      quoteType: details?.quote_type,
      utilityRateEscalator: details?.utility_electric_rate_escalator,
      additionalChoices: {
        customGenUpload: details?.custGenUpload,
        customGenFormat: details?.custom_generation_format,
      }

    });
    return omit(payload, '_id');
  }
}
