import Util, { MeshUserData, PanelStoreData, constants, defaultPanelConfigObj, meshTypeEnum, renderOrders, storeIdPrefix, threeColors, vertexType } from "./Utility";
import { Vector3, Plane, Line, BufferGeometry, LineBasicMaterial, Mesh, Object3D, BoxGeometry, MeshBasicMaterial, Matrix4, Quaternion, BufferAttribute, DoubleSide, Box3, SphereGeometry, MeshPhysicalMaterial, Shape, Vector2, ShapeGeometry, Intersection, Group, PlaneGeometry, MathUtils, Texture, MeshStandardMaterial, Color, AxesHelper, Material, TextureLoader, InstancedMesh, SRGBColorSpace } from 'three';
import { fromRoofSectionSelectors } from "@design/store/roof-section";
import { RoofSection, flatRoofDefaults } from "@design/models/roof-section.model";
import { Vertex } from "@design/models/vertex.model";
import { Store } from "@ngrx/store";

import { DesignState } from "@design/store/design.reducer";
import { take } from "rxjs/operators";
import { fromVertexSelectors } from "@design/store/vertex";
import { Edge } from "@design/models/edge.model";
import { StoreUtil } from "./StoreUtil";
import { panelDims } from "./Utility";
import { fromObstacleSelectors } from "@design/store/obstacle";
import { Obstacle, ObstacleType } from "@design/models/obstacle.model";
import DebugUtil from "./DebugUtil";
import { openNotificationService } from "@lib-ui/lib/atomic/enact-alert-snack-bar/openNotification.service";
import { fromMetaDataActions, fromMetaDataSelectors } from "@design/store/meta-data";
import { ArrayAlignment, PanelArray } from "@design/models/panel-array.model";
import { Frame } from "@design/models/frame.model";
import { AuthService } from "src/app/helpers/auth.service";
import { PanelconfigService } from "src/app/modules/system-design/panelconfig/panelconfig.service";
import { PanelTypeVO } from "src/app/modules/system-design/panelconfig/inetrfaces/panel-type-vo";
import { Panel, PanelOrientation } from "@design/models/panel.model";
import { ConfigurationIds } from "@design/models/ui-state.model";
import { fromPanelActions } from "@design/store/panel";
import { fromPanelArraySelectors } from "@design/store/panel-array/panel-array.selector";
import { EngineService } from "../threejs.service";
import { NotificationType } from "@lib-ui/lib/atomic/enact-alert-snack-bar/notification.message";
import { fromUiActions } from "@design/store/ui";
import { fromPanelArrayActions } from "@design/store/panel-array";
import { PanelArrayCursors } from "./cursors/cursorHelper";
import { Edges } from "./Edges";
import { fromPanelSelectors } from "@design/store/panel/panel.selector";
import { fromFrameSelectors } from "@design/store/frame/frame.selector";
import { fromFrameActions } from "@design/store/frame";
import { TranslateService } from "@ngx-translate/core";
import { calculateAzimuth2 } from "src/app/helpers/utils";
import { Injectable } from "@angular/core";
import { forkJoin, Subscription } from "rxjs";
import { SubscriptionManager } from "../services/subscription-manager.service";
import CollisionUtil from "../utils/collision.util";
import { fromUndoRedoActions } from "@design/store/undo-redo/undo-redo.action";
import { InstancedMeshUtil, PanelTextureEnum } from "../utils/instanceMesh.util";
import { StructureGeometryService } from "./StructureGeometryService";

@Injectable({ providedIn: 'root' })
export class PanelGeometry {
    private offsetFromRoof: number = 0.1; // offset obstacle from toof for better visibility
    private defaultInterRowSpace = 1.64042 // value in feet
    private panelGroupObj: Object3D = new Object3D();
    private firstVertexMaterial: MeshBasicMaterial = new MeshBasicMaterial({ color: threeColors.firstVertex });
    public selectedBlock: Object3D | null = null;
    private currPoint: Vector3 = new Vector3();
    private prevPoint: Vector3 = new Vector3();
    private currentLine: Line | undefined;
    private drawEdgeList: Line[] = [];
    private drawPanelGeom: Mesh | undefined;
    private drawPanelShape: Shape | undefined;
    public tempVertex: Mesh = Util.createVertex(vertexType.spherical);
    public drawVertexList: Mesh[] = [];
    public fillActive: boolean = false;
    public drawActive: boolean = false;
    public singlePanelActive: boolean = false;
    private temporaryTransparentPanel?: Mesh;
    private allObstacles: Obstacle[] = [];
    private isSinglePanelRotated: boolean = false;
    private storeUtil: StoreUtil;
    // private storeRoof: RoofSection | undefined;
    localeToSolarTiltAngelObj: PanelTypeVO[] = [];

    private defaultPanelConfigObj: PanelArray = Object.assign({}, defaultPanelConfigObj);
    public edgeObj: Edges;
    private debugUtil: DebugUtil = new DebugUtil()
    private invalidSinglePanel: boolean;
    private cursorState!: PanelArrayCursors ;
    private subscription: Subscription = new Subscription();
    private loader = new TextureLoader();
    private panelTexture = this.loader.load(
        "./assets/threejs/Solar-panel-texture.png",
        texture => {
          texture.center = new Vector2(0.5, 0.5);
          texture.colorSpace = SRGBColorSpace;
        },
        (progress) => { },
        (error) => {
          console.error("unable to load panel texture", error);
        }

      );
      private panelWhiteTexture = this.loader.load("./assets/threejs/White-panel-texture.png", (texture) => {
        texture.center = new Vector2(0.5, 0.5);
        texture.colorSpace = SRGBColorSpace;
      },
        undefined,
        (error) => {
          throw error;
        }
      );
      private panelRedTexture = this.loader.load("./assets/threejs/Red-panel-texture.png", (texture) => {
        texture.center = new Vector2(0.5, 0.5);
        texture.colorSpace = SRGBColorSpace;
      },
        undefined,
        (error) => {
          throw error;
        }
      );
    defaultPanelArrayModule: PanelArray;

    constructor(
        private structureGeometryService: StructureGeometryService,
        private subscriptionManager: SubscriptionManager,
        private store: Store<DesignState>,
        private notificationService: openNotificationService,
        private authService: AuthService,
        private panelconfigService: PanelconfigService,
        private engServ: EngineService,
        private translate : TranslateService
    ) {
        this.subscriptionManager.getSubscription().subscribe((subscribe: any)=>{
            if(subscribe){
                this.subscription = new Subscription();
                this.initSubscriptions();
            }else{
                this.subscription.unsubscribe();
            }
        })
        this.storeUtil = new StoreUtil(this.store, this.notificationService, this.engServ, this.translate);
        InstancedMeshUtil.loadPanelTextures();
        this.edgeObj = new Edges();
    }

    initSubscriptions(){
        this.subscription.add(this.getAllObstacles());
        this.subscription.add(this.getDefaultModule());
        this.subscription.add(this.getLocaleToSolarTiltAngels());
    }

    getAllObstacles(): Subscription{
        return this.store
            .select(fromObstacleSelectors.allObstacles)
            .subscribe((v) => {
              this.allObstacles = JSON.parse(JSON.stringify(v));
            });
    }

    getDefaultModule(): Subscription{
        return this.store
        .select(fromMetaDataSelectors.getDefaultModule)
        .subscribe((metadata: PanelArray) => {

            if (metadata) {
                // update default values with the values from store
                this.defaultPanelArrayModule = metadata;
            } else {
                // // this happens typically on page load - because of the sequence of calls - the store gets updated after this constructuor is called
                console.error("Unable to identify default Panel Array module -(ignore if triggered on page load)")
            }
        });
    }

    updateModule(){
        Util.overrideObjWithNewValues(this.defaultPanelConfigObj, this.defaultPanelArrayModule);
        this.defaultPanelConfigObj.panelWidth = this.defaultPanelArrayModule.panelWidth ?? panelDims.WIDTH;
        this.defaultPanelConfigObj.panelLength = this.defaultPanelArrayModule.panelLength ?? panelDims.LENGTH;
    }

    activateFill() {
        this.fillActive = true;
        this.panelGroupObj = new Object3D();
        this.panelGroupObj.name = "panelFill_";
    }

    deactivateFill() {
        this.fillActive = false;
        this.defaultPanelConfigObj = Object.assign({}, defaultPanelConfigObj);
    }
    activateDraw() {
        this.drawActive = true;
        this.panelGroupObj = new Object3D();
        this.panelGroupObj.name = "panelDraw_";
    }

    deactivateDraw() {
        this.drawActive = false;
        this.defaultPanelConfigObj = Object.assign({}, defaultPanelConfigObj); // To do: test it
    }

    terminatePanelDraw() {
        this.panelGroupObj.removeFromParent();
        this.store.dispatch(fromUiActions.ClearSelection());

        this.deactivateDraw();
    }

    activateSinglePanel() {
        this.singlePanelActive = true;
        // Panel draw logic is changes to support single panels, Which need old panels to be redrawn.
        this.reDrawOldPanelsArrays();
    }

    reDrawOldPanelsArrays() {
        this.store.select(fromPanelArraySelectors.allPanelArrays).pipe(take(1)).subscribe(panelArrays => {
            if (panelArrays && panelArrays.length) {
                panelArrays.forEach((panelStore: PanelArray) => {
                    let panelArray = JSON.parse(JSON.stringify(panelStore));
                    let panelArrObject = this.engServ.scene.getObjectByName(panelStore.id);
                    if(panelArrObject && panelArrObject.children.length && ((panelArrObject.children[0].userData as MeshUserData).meshtype != meshTypeEnum.instancedMesh)){
                    //if(panelStore.panelTilt > 0){
                        this.store.dispatch(fromMetaDataActions.setChangeFieldName({ prevState: { field: "interrowspacing", value: 10 } }));
                        this.store.dispatch(fromPanelArrayActions.PanelArrayConfigurationChangeTry({ panelArray: panelArray }));
                    //}
                    }
                })
            }
        })
    }

    removeTemporaryPanel() {
      if (this.temporaryTransparentPanel) {
        (this.temporaryTransparentPanel?.material as MeshStandardMaterial).dispose();
        this.temporaryTransparentPanel?.removeFromParent();
      }
      this.engServ.scene.getObjectsByProperty('name',meshTypeEnum.temporaryPanelMesh).forEach(m=> m.removeFromParent());
      this.temporaryTransparentPanel = undefined;
    }

    deactivateSinglePanel() {
        this.singlePanelActive = false;
        this.removeTemporaryPanel();
    }

    terminateSinglePanel() {
        this.store.dispatch(fromUiActions.ClearSelection());
        this.deactivateSinglePanel();
    }

    getPanelMesh(instancedMesh: InstancedMesh) {
      let panelArrayId = instancedMesh.parent?.name;
      let panelConfig: PanelArray;
      this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId!])).pipe(take(1)).subscribe(arrayy=>{
        if(arrayy.length<=0) return;
        panelConfig = arrayy[0]!;
      })
      const matrix = new Matrix4();
      instancedMesh.getMatrixAt(0, matrix);
      let panelGeom = new PlaneGeometry( Util.getMeasurementFeetToPixel(panelConfig!.panelWidth),  Util.getMeasurementFeetToPixel(panelConfig!.panelLength));
      let panelMesh = new Mesh(panelGeom, new MeshStandardMaterial({transparent: true}));
      return {panelMesh, matrix};
    }

    createOrUpdateTemporaryPanel(roofPlane: Mesh, point: Vector3) {

        const roof = roofPlane?.parent;
        const instancedMesh = roof?.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
        if(!instancedMesh)
            return;
        const {panelMesh, matrix} = this.getPanelMesh(instancedMesh);
        panelMesh.geometry.applyMatrix4(matrix);
        this.removeTemporaryPanel();
        this.temporaryTransparentPanel = this.createSinglePanelMesh(panelMesh, roofPlane, point);

        // Start # To scale down temporary panel to fit in between closest panels.
        Util.scaleDownBufferGeo(this.temporaryTransparentPanel, Util.TEMP_PANEL_SCALE);
        // # End

        this.temporaryTransparentPanel.name = meshTypeEnum.temporaryPanelMesh;
        this.temporaryTransparentPanel.userData.meshtype = meshTypeEnum.temporaryPanelMesh;
        this.temporaryTransparentPanel.material = (this.temporaryTransparentPanel.material as MeshStandardMaterial).clone();
        (this.temporaryTransparentPanel.material as MeshStandardMaterial).map = this.panelWhiteTexture.clone();
        delete this.temporaryTransparentPanel.userData.enactStoreId;
        instancedMesh.parent?.add(this.temporaryTransparentPanel);
        this.temporaryTransparentPanel.visible = true;
    }

    overlappingHiddenPanels(instancedMesh: InstancedMesh, instanceId: number){

      let panelArrayId = instancedMesh.parent?.name;
      let panelConfig: PanelArray;
      this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId!])).pipe(take(1)).subscribe(arrayy=>{
        if(arrayy.length<=0) return;
        panelConfig = arrayy[0]!;
      })

      let panelGeom = new PlaneGeometry( Util.getMeasurementFeetToPixel(panelConfig!.panelWidth),  Util.getMeasurementFeetToPixel(panelConfig!.panelLength));
      let matrix = new Matrix4();
      instancedMesh.getMatrixAt(instanceId, matrix)
      let newAddedPanel = panelGeom.clone().applyMatrix4(matrix);


      let instancesToDelete: number[] = [];
        for (let i = 0; i < instancedMesh.count; i++) {
          if(i === instanceId) continue;
          let visiblity = InstancedMeshUtil.getVisibilityAt(instancedMesh, i);
          if(!visiblity){
            const matrix = new Matrix4();
            instancedMesh.getMatrixAt(i, matrix);
            let tempPanelGeom = panelGeom.clone();
            tempPanelGeom.applyMatrix4(matrix);
            if(Util.singlePanelOverlappingPanels(newAddedPanel, tempPanelGeom)){
              instancesToDelete.push(i);
            }
          }


        }
        return instancesToDelete;

    }

    isPanelPlacementValid(panelMesh: Mesh, roofPlaneIntersection: Intersection) {
      if (panelMesh && panelMesh.visible) {
        let roof: Mesh;
        if(roofPlaneIntersection.object.name.split('-').shift() === meshTypeEnum.panel){
            roof = roofPlaneIntersection.object.parent?.parent?.parent as Mesh;
        }else{
            const roofPlane = roofPlaneIntersection.object;
            roof = roofPlane.parent as Mesh;
        }

        if(roof){
            let storeRoof: RoofSection | undefined;
            this.store.select(fromRoofSectionSelectors.selectRoofById([roof.name]))
                .pipe(take(1))
                .subscribe((roofsection) => {
                    if (roofsection[0])
                        storeRoof = roofsection[0]!
                });
            if(!storeRoof) return;
            let roofPlane = roof.getObjectByName(meshTypeEnum.roofPlane) as Mesh;
            let roofNormal = Util.getMeshNormal(roofPlane);
            let allKeepoutPts = this.getAllKeepOutPointsOnRoof(storeRoof, roofNormal);
            let instancedMesh = roof.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
            let allPanelPoints = this.getAllPanelPoints(instancedMesh, false);
            // const panelArrayList = roof?.children.filter((child) => child.name.startsWith(storeIdPrefix.panelArray + "-")) ?? [];
            // const panelMeshes = panelArrayList.flatMap((panelArray) => panelArray.children[0]?.children ?? []) as Mesh[];
            Object.values(allPanelPoints).forEach((pm, i)=>{
                    allKeepoutPts.push(pm);
            })
            let isCollide = this.checkPanelCollisionWithKeepout(panelMesh.geometry, allKeepoutPts);
            if(isCollide) return false;
            if(storeRoof.setback > 0){
              const roofsetbackPts = roof.userData.roofsetbackPts; //inner setback points
              return !Util.singlePanelEdgeDetection( panelMesh, roofsetbackPts)
            }
            const roofVertices = Util.getMeshVertices(roofPlane, true);
            return !Util.singlePanelEdgeDetection( panelMesh, roofVertices)
        }else{
            return false;
        }
      }
      return false;
    }

    rotatePanelAlongCenter(mesh: Mesh, rotateFinal: boolean = false) {
        const roof = mesh.parent?.parent?.parent;
        if (!roof) return;
        const roofNormal = (roof.userData as MeshUserData).roofNormal ? new Vector3().copy((roof.userData as MeshUserData).roofNormal as Vector3) : undefined;
        if (!roofNormal) return;
        let centerForRotationLocal = new Vector3();
        let points = Util.getPointsFromGeometery(mesh.geometry);
        const bbox = new Box3().setFromPoints(points);
        bbox.getCenter(centerForRotationLocal);
        //translate to the original position
        mesh.geometry.translate(-centerForRotationLocal.x, -centerForRotationLocal.y, -centerForRotationLocal.z);
        //rotate
        this.flattenRotateFlush(mesh, roofNormal);
        //translate back to the applied position
        mesh.geometry.translate(centerForRotationLocal.x, centerForRotationLocal.y, centerForRotationLocal.z);
        mesh.updateMatrix();
    }

    checkForErorrs(roof: Mesh){
        if (!roof) {
            const message = this.translateMsg('roofNtFound');
            console.warn(message);
            return undefined;
        }
        const instancedMesh = roof.getObjectByName(meshTypeEnum.panelArray);
        if (!instancedMesh) {
            const message = this.translateMsg('roofHsntPanels');
            console.warn(message);
            return undefined;
        }

        if (this.invalidSinglePanel) {
            const message = this.translateMsg('invalidPlacemntForSnglePanel');
            console.warn(message);
            return undefined;
        }

        let storePanelArray: PanelArray;
        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roof.name)).pipe(take(1)).subscribe(panelArrs=>{
          if(panelArrs.length<=0) return;
          storePanelArray = panelArrs[0];
        })

        if (storePanelArray!.arrayType == '2') { //TODO: replace with east west
            this.notificationService.sendMessage({
                message: this.translateMsg('singlePanelEWError'),
                type: NotificationType.warning,
                positionClass: "toast-bottom-left",
                timeOut: 5000
            });
            const message = this.translateMsg('pnlPlcmntNotAlwd');
            console.warn(message);
            return undefined;
        }
        return true;
    }

    createSinglePanelMesh(closestPanelMesh: Mesh, roofPlane: Mesh, interSectPoint: Vector3){
        const mesh = closestPanelMesh.clone();
        mesh.geometry = closestPanelMesh.geometry.clone();
        const meshNormal = Util.getMeshNormal(mesh);
        if(meshNormal.z < 0){
            meshNormal.negate();
        }
        const roofNormal = Util.getMeshNormal(roofPlane);
        if(roofNormal.z < 0){
            roofNormal.negate();
        }
        mesh.updateMatrix();
        let point = Util.getMeshVertices(mesh)[3];
        let quaternionNeg = new Quaternion().setFromUnitVectors(meshNormal, roofNormal);
        mesh.geometry.translate(-point.x, -point.y, -point.z);
        mesh.geometry.applyQuaternion(quaternionNeg);
        mesh.geometry.translate(point.x, point.y, point.z);
        // Rotation and flush to roof
        if (this.isSinglePanelRotated) {
            this.singlePanelRotationAndFlush(mesh, roofNormal);
        }
        const translateVector =  new Vector3().copy(interSectPoint).sub(Util.getCenter(mesh, true));
        mesh.geometry.translate(translateVector.x, translateVector.y, 0);
        
        let pointTwo = Util.getMeshVertices(mesh, true)[this.isSinglePanelRotated ? 0 :3]; //after rotation the bottom vertex is different
        mesh.geometry.translate(-pointTwo.x, -pointTwo.y, -pointTwo.z);
        mesh.geometry.applyQuaternion(quaternionNeg.invert());
        mesh.geometry.translate(pointTwo.x, pointTwo.y, pointTwo.z);
        mesh.updateMatrix();
        mesh.updateMatrixWorld();

        let centerPrev = Util.getCenter(closestPanelMesh, true);
        let centerNow = Util.getCenter(mesh, true);

        let translate = new Vector3().copy(centerNow).sub(centerPrev);
        let h = -(translate.x*roofNormal.x + translate.y*roofNormal.y)/roofNormal.z-translate.z + 0.1;
        translate.z = translate.z+h;
        let matrix = new Matrix4().makeTranslation(translate);
        mesh.position.setZ(translate.z+5);
        mesh.updateMatrix();
        mesh.updateMatrixWorld();
        mesh.userData.matrix = matrix;

        return mesh;
    }

    addSinglePanel(roof: Mesh) {
        if(!this.checkForErorrs(roof)){
            return undefined;
        }
        if (this.invalidSinglePanel) {
            const message = this.translateMsg('invalidPlacemntForSnglePanel');
            console.warn(message);
            return undefined;
        }
        if(!this.temporaryTransparentPanel) return;
        let panelInstancedMesh = roof.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
        if(!panelInstancedMesh) return;
        const panelArrayId = panelInstancedMesh.parent!.name;
        let storePanelArray: PanelArray | undefined;
        this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId])).pipe(take(1)).subscribe((panelArrays)=>{
          if(!panelArrays || panelArrays.length<=0)return;
          storePanelArray = panelArrays[0];
        })
        if(!storePanelArray) return undefined;

        let {panelMesh, matrix} = this.getPanelMesh(panelInstancedMesh);
        let matrix1 = this.temporaryTransparentPanel.userData.matrix;
        if(this.isSinglePanelRotated){
          panelMesh.rotateOnAxis(new Vector3(0,0,1), Math.PI / 2);
        }
        panelMesh.applyMatrix4(matrix);
        panelMesh.applyMatrix4(matrix1);
        
        panelMesh.updateMatrix();
        panelMesh.updateMatrixWorld();
        InstancedMeshUtil.addInstance(panelInstancedMesh, panelMesh.matrix);
        let maxPillarId;
        this.store.select(fromFrameSelectors.selectFrameWithMaxPillarId(storePanelArray.frameIds)).pipe(take(1)).subscribe((val) => {
            maxPillarId = val;
        });
        if(maxPillarId == undefined || maxPillarId == -1) return undefined;

        this.structureGeometryService.addSinglePanelPillarInstance(roof, panelMesh, storePanelArray, maxPillarId);

        this.removeTemporaryPanel();
        let tempPanelObj = {
          name: Util.generateStoreId(storeIdPrefix.singlePanel),
          orientation:this.isSinglePanelRotated? PanelOrientation.landscape : PanelOrientation.portrait,
          instanceId: panelInstancedMesh.count-1
        }
        this.storeUtil.storeSinglePanel(tempPanelObj, panelArrayId, maxPillarId);
        // let storePanels = JSON.parse(JSON.stringify(storePanelArray));
        // // Deleting overlapped hidden panels (By Obstacles or Walkways)
        let instancesToDelete = this.overlappingHiddenPanels(panelInstancedMesh,panelInstancedMesh.count-1);
            this.store.select(fromPanelSelectors.selectPanelsByInstanceIds(instancesToDelete, storePanelArray!.roofId)).pipe(take(1)).subscribe((panels)=>{
              this.DeletePanels(panels.map(p=> p.id), storePanelArray!.roofId);
            })
        this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromPanelArrayActions.UpsertOne, notification: this.translateMsg('design.undoRedo.singlePanelAdd',false)}))
        return panelInstancedMesh;
    }


    flattenRotateFlush(mesh: Mesh, roofNormal: Vector3) {
        //Flat as per ground
        let quaternionNeg = new Quaternion().setFromUnitVectors(roofNormal, new Vector3(0, 0, 1));
        mesh.geometry.applyMatrix4(new Matrix4().makeRotationFromQuaternion(quaternionNeg));
        //Rotate 90 degrees
        let qrot = new Quaternion().setFromAxisAngle(new Vector3(0, 0, 1), Math.PI / 2);
        mesh.geometry.applyQuaternion(qrot);
        //Flush on roof
        var quaternion = new Quaternion().setFromUnitVectors(new Vector3(0, 0, 1), roofNormal);
        mesh.geometry.applyMatrix4(new Matrix4().makeRotationFromQuaternion(quaternion));
    }

    singlePanelRotationAndFlush(mesh: Mesh, roofNormal: Vector3) {
        let centerForRotationLocal = Util.getCenter(mesh, true);
        //translate to the original position
        mesh.geometry.translate(-centerForRotationLocal.x, -centerForRotationLocal.y, -centerForRotationLocal.z);
        if(this.isSinglePanelRotated){
            this.flattenRotateFlush(mesh, roofNormal);
        }
        //translate to the applied position
        mesh.geometry.translate(centerForRotationLocal.x, centerForRotationLocal.y, centerForRotationLocal.z);
        mesh.updateMatrix();
    }

    drawNewSinglePanelMesh(newMeshVertices: Vector3[]): Mesh {
        let mesh = Util.drawPolygon(newMeshVertices, meshTypeEnum.panelMesh);
        mesh.translateZ(0.1); // move panels by 0.1 units to disable flickering;
        mesh.material = new MeshStandardMaterial({
            map: this.panelTexture.clone(),
            side: DoubleSide,
            // color: threeColors.panelColor,
            transparent: true,
        });
        mesh.geometry.computeVertexNormals();
        mesh.renderOrder = 1;
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        return mesh;
    }


    rotateSinglePanel(panelId: string, orientation: PanelOrientation) {
        const panelMesh = this.engServ.scene.getObjectByName(panelId) as Mesh | undefined;
        if (!panelMesh) {
            console.error(`PanelGeometry.rotateSinglePanel: Panel ID ${panelId} not found in Scene.`);
            return;
        }
        const rotatePanelInStoreSubscription = this.store.select(fromPanelSelectors.selectPanelsByIds([ panelId ]))
            .pipe(take(1))
            .subscribe((panels) => {
                try {
                    const panel = panels[0];
                    if (!panel) {
                        throw new Error(`Panel ID ${panelId} not found in Store.`);
                    }
                    if (panel.orientation !== orientation) {
                        this.rotatePanelAlongCenter(panelMesh);
                        this.store.dispatch(fromPanelActions.UpsertOne({ panel: {
                            ...panel,
                            orientation: orientation,
                        } }));
                    }
                } catch (err: any) {
                    console.error('PanelGeometry.rotateSinglePanel: ' + err.message);
                } finally {
                    // rotatePanelInStoreSubscription.unsubscribe();
                }
            });
    }

    DeleteInstances(panelIds: string[], storePanelArr: PanelArray){
      let instancedMesh = this.engServ.scene
        .getObjectByName(storePanelArr.id)
        ?.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
      if (
        (instancedMesh.userData as MeshUserData).meshtype !=
        meshTypeEnum.instancedMesh
      )
        return;
      let panelsToBeDeleted: Panel[] | undefined;
      this.store
        .select(fromPanelSelectors.selectPanelsByIds(panelIds))
        .pipe(take(1))
        .subscribe((panels) => {
          if (panels && panels.length <= 0) return;
          panelsToBeDeleted = JSON.parse(JSON.stringify(panels));
        });
      if (!panelsToBeDeleted) return;
      let instanceIdToPanelId : {[key:number]: string}= {};
      let panelIdToInstance : {[key:string]: number}= {};

      panelsToBeDeleted.forEach((panel) =>{
        if(panel){
          instanceIdToPanelId[panel.instanceId] = panel.id;
          panelIdToInstance[panel.id] = panel.instanceId;
        }
      })
      let panelsToBeUpdatedInStore: {[key: number]:Panel} = {};
      panelIds.forEach((id) => {
        let instanceId = panelIdToInstance[id];
        InstancedMeshUtil.delete(instancedMesh, instanceId); //When we delete an instance the last instance instance id changes
        let lastInstanceId = instancedMesh.count;
        let lastIndexPanelId = instanceIdToPanelId[lastInstanceId];

        if (lastIndexPanelId) {
          delete panelIdToInstance[id];
          delete instanceIdToPanelId[lastInstanceId];
          if(lastIndexPanelId != id){
            panelIdToInstance[lastIndexPanelId] = instanceId;
            instanceIdToPanelId[instanceId] = lastIndexPanelId;
          }

        } else {
          if(panelsToBeUpdatedInStore[lastInstanceId]){
            panelsToBeUpdatedInStore[lastInstanceId].instanceId = instanceId;
            panelsToBeUpdatedInStore[instanceId] = panelsToBeUpdatedInStore[lastInstanceId];
            delete panelsToBeUpdatedInStore[lastInstanceId];
          }else{
            this.store
            .select(
              fromPanelSelectors.selectPanelsByInstanceIds(
                [lastInstanceId],
                storePanelArr!.roofId!
              )
            )
            .pipe(take(1))
            .subscribe((panels2) => {
              if (panels2.length <= 0) return;
              let lastPanel = JSON.parse(JSON.stringify(panels2[0]));
              lastPanel.instanceId = instanceId;
              panelsToBeUpdatedInStore[instanceId] = lastPanel;
            });

          }
          delete panelIdToInstance[id];
          delete instanceIdToPanelId[instanceId];

        }
      });
      this.store.dispatch(
        fromPanelActions.UpsertMany({ panels: Object.values(panelsToBeUpdatedInStore)})
      );

    }

    updateFrames(storeFrames: (Frame | undefined)[], currentPanelsToDelete: string[]) {
        const framesToDelete: Array<Frame> = [];
        const frameIdsToDelete: Array<Frame['id']> = [];
        const framesToUpdate: Array<Frame> = [];
        const newFrames = JSON.parse(JSON.stringify(storeFrames));
    
        newFrames.forEach((newFrame: Frame) => {
            if(newFrame) {
                let originalPanelCountInFrame = newFrame.panelIds.length;
                newFrame.panelIds = newFrame.panelIds.filter((id: string) => !currentPanelsToDelete.includes(id));
        
                if (newFrame.panelIds.length === 0) {
                    framesToDelete.push(newFrame);
                    frameIdsToDelete.push(newFrame.id);
                } else if(newFrame.panelIds.length !== originalPanelCountInFrame) {
                    framesToUpdate.push(newFrame);
                }
            }
        });
    
        return { framesToDelete,frameIdsToDelete, framesToUpdate };
    }

    updatePanels(storePanelArr: PanelArray, panelData: ( Panel | undefined)[], frameIdsToDelete: Array<Frame['id']>) {
        const newPanelArray: PanelArray = JSON.parse(JSON.stringify(storePanelArr));
        let noOfPanelEast = 0;
        let noOfPanelWest = 0;
        const isEastWest = newPanelArray.arrayType === '2';
    
        panelData.forEach((panel: Panel | undefined) => {
            const indexToRemove = newPanelArray.panelIds.indexOf(panel!.id);
            if (indexToRemove !== -1) {
                newPanelArray.panelIds.splice(indexToRemove, 1);
                if(panel!.visible){
                    if (isEastWest && panel?.azimuth === newPanelArray.azimuth2) {
                        noOfPanelWest--;
                    } else {
                        noOfPanelEast--;
                    }
                }                
            }
        });    
        if(frameIdsToDelete.length) {
            newPanelArray.frameIds = newPanelArray.frameIds.filter(id => !frameIdsToDelete.includes(id));
        }
        newPanelArray.totalAddedPanels = newPanelArray.panelIds.length;
        newPanelArray.numberOfPanels += noOfPanelEast + noOfPanelWest;
        newPanelArray.noOfPanelEast += noOfPanelEast;
        newPanelArray.noOfPanelWest = (newPanelArray.noOfPanelWest || 0) + noOfPanelWest;
    
        return { newPanelArray };
    }

    dispatchActions(framesToDelete: Array<Frame['id']>, framesToUpdate: Array<Frame>, currentPanelsToDelete: string[], newPanelArray: PanelArray) {
        if (framesToDelete.length) {
            this.store.dispatch(fromFrameActions.DeleteMany({ frameIds: framesToDelete }));
        }
        if (framesToUpdate.length) {
            this.store.dispatch(fromFrameActions.UpsertMany({ frames: framesToUpdate }));
        }
    
        this.store.dispatch(fromPanelActions.DeleteMany({ panelIds: currentPanelsToDelete }));
    
        if (newPanelArray.panelIds.length === 0) {
            this.store.dispatch(fromPanelArrayActions.PanelArrayDelete({ panelArray: newPanelArray }));
        } else {
            this.store.dispatch(fromPanelArrayActions.UpsertOne({ panelArray: newPanelArray }));
        }
    }

    DeletePanels(panelIds: string[], roofId?: string) {
        // Fetch all necessary data in a batch
        if(!panelIds.length) return;
        let selector: any = '';

        if(roofId) {
            selector = fromPanelArraySelectors.selectPanelArraysByRoofId(roofId);
        } else {
            selector = fromPanelArraySelectors.allPanelArrays;
        }

        this.store.select(selector).pipe(take(1))
            .subscribe(storePanelArrays => {
    
                storePanelArrays.forEach((storePanelArr: PanelArray) => {
                    const currentPanelsToDelete = panelIds.filter(id => storePanelArr.panelIds.includes(id));
                    if (currentPanelsToDelete.length === 0) return;

                    this.DeleteInstances(currentPanelsToDelete, storePanelArr);

                    forkJoin([
                        this.store.select(fromFrameSelectors.selectFramesByIds(storePanelArr.frameIds))
                        .pipe(take(1)),
                        this.store.select(fromPanelSelectors.selectPanelsByIds(currentPanelsToDelete))
                        .pipe(take(1)),
                        this.store.select(fromPanelSelectors.getPanelsByAssociation(storePanelArr.roofId))
                        .pipe(take(1))
                    ]).subscribe(([storeFrames, panelData, associatedPanels]) => {

                        let remainingVisiblePanels = associatedPanels.filter((p: any) => p && p.visible && !currentPanelsToDelete.includes(p.id)).length;

                        if (!remainingVisiblePanels) {
                            this.engServ.scene.getObjectByName(storePanelArr.id)?.removeFromParent();
                            this.store.dispatch(fromPanelActions.DeleteMany({ panelIds: storePanelArr.panelIds }));
                            this.store.dispatch(fromFrameActions.DeleteMany({ frameIds: storePanelArr.frameIds }));
                            this.store.dispatch(fromPanelArrayActions.PanelArrayDelete({ panelArray: storePanelArr }));
                            this.store.dispatch(fromUiActions.ClearSelection());
                            return;
                        }

                        const { framesToDelete, frameIdsToDelete,framesToUpdate } = this.updateFrames(storeFrames, currentPanelsToDelete);
                        let panelInstanceMesh = this.engServ.scene
                            .getObjectByName(storePanelArr.id)
                            ?.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
                        let pillarInstanceMesh = this.engServ.scene
                            .getObjectByName(storePanelArr.id)
                            ?.getObjectByName(meshTypeEnum.pillerArray) as InstancedMesh;
                        if (pillarInstanceMesh)
                        {
                        this.structureGeometryService.updatePillarsVisibility(pillarInstanceMesh, panelInstanceMesh, framesToUpdate);
                        this.structureGeometryService.updatePillarsVisibility(pillarInstanceMesh, panelInstanceMesh, framesToDelete);
                        }

                        const { newPanelArray } = this.updatePanels(storePanelArr, panelData, frameIdsToDelete);
                    
                        this.dispatchActions(frameIdsToDelete, framesToUpdate, currentPanelsToDelete, newPanelArray);
                    });
                });
            });
    }


    calculatePanelBySelection(selectedRoof: Object3D, panelConfig?: PanelArray): any {
        try {
            if (!panelConfig) {
                this.updateModule();
                panelConfig = Object.assign({},this.defaultPanelConfigObj);
            }
            if (this.drawActive) {
                panelConfig.isDrawToFill = true;
            }
            const roofId = selectedRoof.name;
            let isExisting = panelConfig.id != "";
            let isEastWest = panelConfig.arrayType == '2';
            if (!isExisting) {
                panelConfig.id = Util.generateStoreId(storeIdPrefix.panelArray);
                panelConfig.roofId = roofId;
                panelConfig.orientation = isEastWest ? PanelOrientation.landscape : PanelOrientation.portrait;
            }
            const existingpanelGroup = this.engServ.scene.getObjectByName(panelConfig.id);
            if (existingpanelGroup) {
                this.panelGroupObj = existingpanelGroup;
            }
            else {
                this.panelGroupObj.name = panelConfig.id;
                this.panelGroupObj.userData = new MeshUserData(panelConfig.id, meshTypeEnum.panelGroup);
            }

            // NOTE: In case of scene restoration, if the userData of a roof Object isn't instantiated, then creating new Vector3 acts as a fallback.
            const roofNormal = new Vector3().copy((selectedRoof.userData as MeshUserData).roofNormal as Vector3);
            if (roofNormal && roofNormal.z < 0.0) {
                roofNormal.negate()
            }
            let storeRoof = this.GetStoreRoofSection(roofId);

            if (!storeRoof || !roofNormal) {
                throw new Error("Unable to find Roof in store")
            }
            let isFlatRoof = storeRoof.pitch <= flatRoofDefaults.pitch;
            const roofPlane = selectedRoof.getObjectByName(meshTypeEnum.roofPlane) as Mesh;
            let roofPoints = this.GetVertsFromMesh(roofPlane);
            let roofConfirmedPlane = Util.getPlaneFromMesh(roofPlane);
            let panelArraySetback = Util.getMeasurementFeetToPixel(storeRoof.setback);
            const azimInitial = this.getInitialAzim(panelConfig);
            const frameHeightTall = panelConfig.frameHeight + panelConfig.panelLength*panelConfig.noOfRows*Math.sin(Util.convertDegreeToRadian(panelConfig.panelTilt));

            {
                if (!isExisting) {
                    if (isFlatRoof) {
                        if(panelConfig.arrayType == '0'){
                            panelConfig.arrayTypeName = 'Fixed (Open Rack)';
                            panelConfig.arrayType = '1';
                        }
                        panelConfig.panelTilt = flatRoofDefaults.panelTilt;
                    } else {
                        panelConfig.panelTilt = 0;
                    }
                    panelConfig.azimuth = azimInitial;
                    panelConfig.interrowspacing = this.calculateInterRowSpacing(panelConfig, storeRoof.pitch);
                    panelConfig.defaultInterrowspacing = panelConfig.interrowspacing;
                }

                const maxAllowedTilt = 90 - storeRoof.pitch;
                if (panelConfig.panelTilt >= maxAllowedTilt) {
                    // Panel cannnot be tilted more than 90 degree - including roof tilt
                    const message = `panel tilt cannot be more than ${maxAllowedTilt} for current roof`;
                    console.warn(message);
                    panelConfig.panelTilt = maxAllowedTilt;
                }
                panelConfig.setback = panelArraySetback;
                panelConfig.showEdgeSetback = storeRoof.setbackVisible;
                panelConfig.frameHeightTall = frameHeightTall;

            }
            const panelStoreData: PanelStoreData = {
                storeMetadata: panelConfig,
                roofPitch: storeRoof.pitch,
                alignment: ArrayAlignment.justify,
            }

            let polygonPoints = roofPoints;
            if (this.drawActive) {
                // if draw to fill, then use the points from the draw polygon
                polygonPoints = this.drawVertexList.map(m => m.position);
                // panelArraySetback = 0;
            } else if (panelConfig.isDrawToFill) {
                const panelArray = this.engServ.scene.getObjectByName(panelConfig.id);
                const drawMesh = panelArray?.getObjectByName(meshTypeEnum.drawPanelShape) as Mesh;
                if (drawMesh) {
                    polygonPoints = this.GetVertsFromMesh(drawMesh, false);
                }
            }
            const [edgeLeft, edgeRight] = isEastWest && isFlatRoof ? this.storeUtil.GetRoofRidgeVertices(storeRoof) : this.storeUtil.GetRoofGutterVertices(storeRoof);
            if (!edgeLeft || !edgeRight) {
                throw new Error("Unable to find gutter and ridge");
            }
            var edgeVector = new Vector3().subVectors(edgeRight, edgeLeft).normalize();
            this.debugUtil.DebugPanelGutterDir(this.panelGroupObj, edgeVector, edgeLeft);

            if (panelConfig.azimuth != azimInitial) {
                const azimuthChange = panelConfig.azimuth - azimInitial;
                const angleRad = MathUtils.degToRad(azimuthChange);
                edgeVector.applyAxisAngle(new Vector3(0, 0, -1), angleRad);
            }


            var { globalpanelFrameCorners: validPanelFrameCorner3D, globalToLocal, allKeepOutPointsOnRoof } = this.getValidPanelFrameCorners(polygonPoints, roofPoints, edgeLeft, edgeVector, roofNormal, panelArraySetback, edgeRight, storeRoof, panelConfig);
            if (this.checkIfDrawPanelsInSelectedArea(roofId, globalToLocal, polygonPoints, panelConfig.id)) {
                throw Error("Panel Already Exists")
            };

            if (validPanelFrameCorner3D.length <= 0) {
                this.notificationService.sendMessage({
                    message: this.translateMsg('UnableToFit'),
                    type: NotificationType.danger,
                    positionClass: "toast-bottom-left",
                    timeOut: 5000
                });
                throw new Error(this.translateMsg('UnableToFit'));
            }
            let roofArea = Util.calculateArea(roofPlane);
            let { panelsUserdataArray, frames, panelInstanceMesh , pillarInstanceMesh} = this.createPanelMeshes(validPanelFrameCorner3D, panelConfig, edgeVector, roofConfirmedPlane, roofArea);
            const invisibleIndices = this.invisiblePanelsOverlappingWithObstacles(panelConfig, panelInstanceMesh, allKeepOutPointsOnRoof);
            if(pillarInstanceMesh)
                {   
                    const toBeUpdatedFrameIds = new Set<string>();
                    invisibleIndices.forEach((index) => {
                        const panel = panelsUserdataArray[index];
                        if (panel && panel.frameId) {
                            toBeUpdatedFrameIds.add(panel.frameId);
                        }
                    });
                    const toBeUpdatedFrames : Frame[]= [];
                    frames.forEach(f => {
                        if(toBeUpdatedFrameIds.has(f.id)){
                            f.isDefaultStructure = false;
                            toBeUpdatedFrames.push(f)
                        }
                    });
                    this.structureGeometryService.updatePillarsVisibility(pillarInstanceMesh,panelInstanceMesh, toBeUpdatedFrames);
                }
            let eastWestCount;
            ({ eastWestCount, panelsUserdataArray } = this.createRawPanelsData(invisibleIndices, panelsUserdataArray, isEastWest, panelConfig));
            
            this.panelGroupObj.add(panelInstanceMesh);
            if(pillarInstanceMesh)
                this.panelGroupObj.add(pillarInstanceMesh);
            this.panelGroupObj.position.z = (Util.DEFAULT_PANEL_ARRAY_MARGIN + Util.getMeasurementFeetToPixel(panelConfig.frameHeight));
            this.panelGroupObj.updateMatrix();
            this.panelGroupObj.updateMatrixWorld();
            selectedRoof.add(this.panelGroupObj);

            let { shapeVertexIds, shapeEdgeIds } = this.getPanelArrayShape(storeRoof, isExisting, panelConfig, roofId);
            return { shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount };
        } catch (error: any) {
            if (this.drawActive) {
                this.terminatePanelDraw();
            }
            const message = `${this.translateMsg('addPnlsErr')} ` + error.message ?? "";
            console.warn(message);
            //console.error('AddPanels Error: ' + error.stack);
            return {shapeVertexIds: null, shapeEdgeIds: null, panelsUserdataArray: null, panelStoreData: null, frames: null, isExisting: null, eastWestCount: null};
        }
    }

    private createRawPanelsData(invisibleIndices: number[], panelsUserdataArray: MeshUserData[], isEastWest: boolean, panelConfig: PanelArray | undefined) {
        const invisibleIndicesSet = new Set(invisibleIndices);
        const modifiedPanelsData: Panel[] = [];
        const eastWestCount = {
            noOfPanelEast: 0,
            noOfPanelWest: 0
        };
        panelsUserdataArray.forEach((p, i) => {
            if (p.instanceId == undefined) return;
            if (invisibleIndicesSet.has(i)) {
                p.visible = false;
            } else {
                p.visible = true;
            }
            if (isEastWest && p?.azimuth == panelConfig?.azimuth2) {
                if (p.visible) eastWestCount.noOfPanelWest++;
            } else {
                if (p.visible) eastWestCount.noOfPanelEast++;
            }
            const modifiedPanel: Panel = {
                id: p.enactStoreId!,
                visible: p.visible!,
                instanceId: p.instanceId,
                configurationId: ConfigurationIds.SinglePanel,
                orientation: p.orientation ?? PanelOrientation.portrait,
                association: panelConfig?.roofId ?? '',
                frameId: p.frameId!,
                azimuth: p.azimuth!
            };
            modifiedPanelsData.push(modifiedPanel);
        });
        panelsUserdataArray = modifiedPanelsData as any[];
        return { eastWestCount, panelsUserdataArray };
    }

    private getPanelArrayShape(storeRoof: RoofSection, isExisting: boolean, panelConfig: PanelArray, roofId: string) {
        let shapeVertexIds = storeRoof.vertexIds;
        let shapeEdgeIds = storeRoof.edgeIds;

        if (isExisting) {
            shapeVertexIds = panelConfig.vertices ?? [];
            shapeEdgeIds = panelConfig.edges ?? [];
        } else if (this.drawActive) {
            const [boundaryVertices, boundaryEdges] = this.storeUtil.storeDrawPanelShape(this.drawVertexList, this.drawEdgeList, roofId);
            shapeVertexIds = (boundaryVertices as Vertex[]).map(v => v.id);
            shapeEdgeIds = (boundaryEdges as Edge[]).map(e => e.id);
        }
        return { shapeVertexIds, shapeEdgeIds };
    }

    

    /**
   * Add Panels to the selected roof or roof-shape drawn by user
   * @param {Object3D} selectedRoof - the Parent roof group Object which contains all Roof Geometry.
   * @param {PanelArray} panelConfig - config properties for the panels to be added.
   * @return true if panels are added successfully
   */
    AddPanelbySelection(selectedRoof: Object3D, panelConfig?: PanelArray, triggerURSnapshot?: boolean) {
        try {
            const {shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount} = this.calculatePanelBySelection(selectedRoof, panelConfig);
            if(shapeVertexIds && shapeEdgeIds && panelsUserdataArray && panelStoreData && frames && eastWestCount){
                const addToStore = this.storeUtil.storePanels(shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, eastWestCount, isExisting ? panelConfig : undefined,triggerURSnapshot);
                return addToStore ;
            }else{
                return false;
            }
        } catch (error: any) {
            if (this.drawActive) {
                this.terminatePanelDraw();
            }
            const message = `${this.translateMsg('addPnlsErr')} ` + error.message ?? "";
            console.warn(message);
            console.error('AddPanels Error: ' + error.stack);
            return false;
        }
    }

    AddMultiplePanelsbySelection(selectedRoof: Object3D, panelConfig?: PanelArray): any {
        try {
            const {shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount} = this.calculatePanelBySelection(selectedRoof, panelConfig);
            //this.storeUtil.storePanels(shapeVertexIds, shapeEdgeIds, panelArrayGroup, panelStoreData, frames, isExisting ? panelConfig : undefined);
            return {shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount};
        } catch (error: any) {
            if (this.drawActive) {
                this.terminatePanelDraw();
            }
            const message = `${this.translateMsg('addPnlsErr')} ` + error.message ?? "";
            console.warn(message);
            console.error('AddPanels Error: ' + error.stack);
            return false;

        }
    }

    getInitialAzim(panelConfig: PanelArray) {
        let storeRoof = this.GetStoreRoofSection(panelConfig.roofId);
        let roof = this.engServ.scene.getObjectByName(panelConfig.roofId);
        let roofPlane = roof?.getObjectByName(meshTypeEnum.roofPlane) as Mesh;
        if (!storeRoof || !roofPlane) return panelConfig.azimuth;
        let roofCenter = Util.getCenter(roofPlane);
        let isFlatRoof = storeRoof.pitch <= flatRoofDefaults.pitch;
        let isEastWest = panelConfig.arrayType == '2';
        let isFlushMount = panelConfig.arrayType == '0';
        if (!isFlatRoof && (isFlushMount)) return storeRoof.azimuth;
        if (!isFlatRoof && panelConfig.arrayType == '1' && panelConfig.panelTilt === 0) return storeRoof.azimuth;
        const [edgeLeft, edgeRight] = this.storeUtil.GetRoofRidgeVertices(storeRoof);
        if (!edgeLeft || !edgeRight) {
            throw new Error("Unable to find gutter and ridge");
        }
        const azimuthFromEdge = Util.GetAzimuthFromEdge(edgeLeft, edgeRight, roofCenter);
        return azimuthFromEdge;

    }

    createPanelMeshes(
        validPanelFrameCorner3D: Vector3[][], 
        panelConfig: PanelArray, 
        gutterVector: Vector3, 
        roofConfirmedPlane: Plane,
        roofArea: number,

    ) {
        let panelGeom = new PlaneGeometry( Util.getMeasurementFeetToPixel(panelConfig.panelWidth),  Util.getMeasurementFeetToPixel(panelConfig.panelLength));
        let maxPossiblePanels = this.calculateMaxPossiblePanels(panelGeom, roofArea, panelConfig.panelTilt);
        const frameHeight = Util.DEFAULT_PANEL_ARRAY_MARGIN + Util.getMeasurementFeetToPixel(panelConfig.frameHeight);

        const panelsUserdataArray: Array<MeshUserData> = [];
        let frames: Frame[] = [];
        let isEastWest = panelConfig.arrayType == '2';//TODO: change to east west
        let pillarInstanceMesh : InstancedMesh | undefined;
        if ((panelConfig.frameHeight > 0 || panelConfig.panelTilt > 0) && !isEastWest ) {
            pillarInstanceMesh = this.structureGeometryService.createPillarInstanceMesh(panelConfig, maxPossiblePanels, validPanelFrameCorner3D.length);
        }
        validPanelFrameCorner3D.forEach((panelFramePts, index) => {
            let panels = this.createPanelPointsFromFrame(panelFramePts, panelConfig);
            let z1panelIds:string[] = [];
            let z2panelIds:string[] = [];
            let frameGroup: Mesh<BufferGeometry, Material | Material[]>[] = [];
            const az1frameId = Util.generateStoreId(storeIdPrefix.frame);
            const az2frameId = Util.generateStoreId(storeIdPrefix.frame);
            panels.forEach((panelPts, i) => {
                const id = Util.generateStoreId(storeIdPrefix.singlePanel);
                const panelUserData = new MeshUserData(id, meshTypeEnum.panelMesh);
                if (isEastWest && i >= panels.length / 2) {
                    panelUserData.frameId = az2frameId;
                    panelUserData.azimuth = calculateAzimuth2(panelConfig.azimuth); //(panelConfig.azimuth + 180) % 360;
                    z2panelIds.push(id);
                } else {
                    panelUserData.frameId = az1frameId;
                    panelUserData.azimuth = panelConfig.azimuth;
                    z1panelIds.push(id);
                }
                panelUserData.orientation = panelConfig.orientation;
                let mesh = Util.drawPolygon(panelPts, meshTypeEnum.panelMesh);
                mesh.translateZ(0.1); // move panels by 0.1 units to disable flickering;
                mesh.material = new MeshStandardMaterial({
                    map: this.panelTexture.clone(),
                    side: DoubleSide,
                    transparent: true,
                });
                mesh.userData = panelUserData;
                mesh.name = id;
                frameGroup.push(mesh);
            });
            let tilttedFramePoints = this.tiltPanelFrame(frameGroup, panelFramePts, panelConfig.panelTilt, gutterVector, isEastWest);
            let frame: Frame = {
                id: frameGroup[frameGroup.length - 1]?.userData.frameId,
                panelIds: frameGroup[frameGroup.length - 1]?.userData.frameId == az1frameId ? z1panelIds : z2panelIds,
                panelArrayId: panelConfig.id,
                isDefaultStructure : true,
                pillarIds: [index*4, index*4+1, index*4+2, index*4+3]
            }
            frameGroup.forEach(p=>{
              let panelNormal = Util.getMeshNormal(p);
              if(panelNormal.z<0)panelNormal.negate();
              let panelVertices = Util.getMeshVertices(p);
              let panelAzim =  new Vector3().copy(panelVertices[2]).sub(panelVertices[3]).normalize();
              let panelCenter = Util.getCenter(p);

              let q1 = new Quaternion().setFromUnitVectors(new Vector3(0,0,1), panelNormal);
              let q2 = new Quaternion().setFromUnitVectors(new Vector3(1,0,0).applyQuaternion(q1), panelAzim);


              let tempObj = new Mesh();
              if(panelConfig.orientation == PanelOrientation.landscape){
                tempObj.rotateOnAxis(new Vector3(0,0,1), Math.PI / 2);
              }
              tempObj.applyQuaternion(q1);
              tempObj.applyQuaternion(q2);
              tempObj.position.add(panelCenter);
              tempObj.updateMatrix();
              tempObj.updateMatrixWorld();
              (p.userData as MeshUserData).matrix = tempObj.matrixWorld;
              panelsUserdataArray.push((p.userData as MeshUserData));
            })
            frames.push(frame);
            if(pillarInstanceMesh){
                let pillarBuffer = this.structureGeometryService.createPillarPositionAndHeightBuffer(tilttedFramePoints, roofConfirmedPlane, panelConfig.panelTilt + panelConfig.roofTilt);
                this.structureGeometryService.populatePillarInstanceMeshAtIndex(pillarInstanceMesh,pillarBuffer, frameHeight, index);
            }
        });

        let panelM = new MeshStandardMaterial({
            map: this.panelTexture.clone(),
            side: DoubleSide,
            transparent: true,
        });
        const panelsUserdataArrayLength = panelsUserdataArray.length;

        let panelInstanceMesh = new InstancedMesh(panelGeom, panelM, maxPossiblePanels); //Making count sufficiently large to accomodate single panels
        InstancedMeshUtil.init(panelInstanceMesh);
        InstancedMeshUtil.setPanelShader(panelInstanceMesh);
        panelInstanceMesh.name = meshTypeEnum.panelArray;
        (panelInstanceMesh.userData as MeshUserData).meshtype = meshTypeEnum.instancedMesh;
        (panelInstanceMesh.userData as MeshUserData).maxInstanceCount = panelInstanceMesh.count;

        panelsUserdataArray.forEach((p,i)=>{
          panelInstanceMesh.setMatrixAt( i, p.matrix as Matrix4);
          panelInstanceMesh.setColorAt( i, new Color(0xffffff));
          p.instanceId = i;
        });

        panelInstanceMesh.count = panelsUserdataArrayLength;
        panelInstanceMesh.renderOrder = renderOrders.panelArray;

        return { panelsUserdataArray, frames, panelInstanceMesh, pillarInstanceMesh};
    }

    calculateMaxPossiblePanels(panelGeom: PlaneGeometry, roofArea: number, panelTilt: number){
        let panelArea = Util.calculateArea(new Mesh(panelGeom));
        let projectedArea = panelArea * Math.cos(Util.convertDegreeToRadian(panelTilt));
        return Math.floor(roofArea / projectedArea);
    }
    createPanelPointsFromFrame(framePts: Vector3[], panelConfig: PanelArray){
        let isEastWest = panelConfig.arrayType == '2';//TODO: change to east west
        let isLandscape = panelConfig.orientation == PanelOrientation.landscape;
        let clampSpace = Util.getMeasurementFeetToPixel(panelConfig.clampSpace);
        let orientedPanelHeight = isLandscape ? Util.getMeasurementFeetToPixel(panelConfig.panelWidth) : Util.getMeasurementFeetToPixel(panelConfig.panelLength);
        let orientedPanelWidth = isLandscape ? Util.getMeasurementFeetToPixel(panelConfig.panelLength) : Util.getMeasurementFeetToPixel(panelConfig.panelWidth);
        let columnVector = framePts[1].clone().sub(framePts[0]).normalize();
        let rowVector = framePts[3].clone().sub(framePts[0]).normalize();
        let panelX = columnVector.clone().multiplyScalar(orientedPanelWidth)
        let panelY = rowVector.clone().multiplyScalar(orientedPanelHeight)
        let xDir =  columnVector.clone().multiplyScalar(orientedPanelWidth+clampSpace);
        let yDir =  rowVector.clone().multiplyScalar(orientedPanelHeight+clampSpace);
        let panels = [];
        for (let i = 0; i < panelConfig.noOfRows; i++) {
            for (let j = 0; j < panelConfig.noOfColumns; j++) {
                let a = framePts[0].clone().addScaledVector(xDir, j).addScaledVector(yDir, i);
                let b = a.clone().add(panelX);
                let c = b.clone().add(panelY);
                let d = a.clone().add(panelY);
                panels.push([a, b, c, d])
            }
        }
        if (isEastWest) {
            for (let i = 0; i < panelConfig.noOfRows; i++) {
                for (let j = 0; j < panelConfig.noOfColumns; j++) {
                    let a = framePts[3].clone().addScaledVector(xDir, j).addScaledVector(yDir, -i);
                    let b = a.clone().add(panelX);
                    let c = b.clone().sub(panelY);
                    let d = a.clone().sub(panelY);
                    panels.push([a, b, c, d])
                }
            }
        }
        return panels;
    }

    getPanelsFromRoof(selectedRoof: Object3D, allPanels: boolean) {
        const roofId = selectedRoof.name;
        const panelMeshes: Object3D[] = [];
        let isEastWest = false;

        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
            .pipe(take(1))
            .subscribe(roofPanelArrays => {
                for (const panelArray of roofPanelArrays) {
                    const panelArrayGroup = selectedRoof.getObjectByName(panelArray.id)?.getObjectByName(meshTypeEnum.panelArray);
                    if (!panelArrayGroup) continue;

                    // Collect panels based on the `allPanels` condition
                    panelArrayGroup.children.forEach(p => {
                        if (allPanels || !p.userData.removed) {
                            panelMeshes.push(p);
                        }
                    });

                    // Set isEastWest if any panelArray is of arrayType '2'
                    if (panelArray.arrayType === '2') {
                        isEastWest = true;
                    }
                }
            });

        return { panelMeshes, isEastWest };
    }


    updatePanelsOnObstacleOrRoofChanges(roofId: string) {
        const selectedRoof = this.engServ.scene.getObjectByName(roofId);
        const storeRoof = this.GetStoreRoofSection(roofId);

        if (!selectedRoof || !storeRoof) {
            console.error(`Unable to identify roof with id: ${roofId}`);
            return;
        }

        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
            .pipe(take(1))
            .subscribe(roofPanelArrays => {
                if(roofPanelArrays && roofPanelArrays.length){ // Only fetch data if there is any panelArray
                const [gutterLeft, gutterRight] = this.storeUtil.GetRoofGutterVertices(storeRoof!);
                if (gutterLeft && gutterRight) {
                    const roofNormal = new Vector3().copy((selectedRoof.userData as MeshUserData).roofNormal as Vector3);
                    let keepoutPoints = this.getAllKeepOutPointsOnRoof(storeRoof!, roofNormal);

                    roofPanelArrays.forEach(panelArray => {
                        const rootPanelsGroup = selectedRoof.getObjectByName(panelArray.id);
                        const panelInstanceMesh = rootPanelsGroup?.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
                        const pillarInstanceMesh = rootPanelsGroup?.getObjectByName(meshTypeEnum.pillerArray) as InstancedMesh;
                        const invisibleIndices = this.invisiblePanelsOverlappingWithObstacles(panelArray, panelInstanceMesh!, keepoutPoints); 
                        if (pillarInstanceMesh)                       
                            this.structureGeometryService.updatePillarsOnObstacleOrRoofChanges(panelArray, invisibleIndices, pillarInstanceMesh, panelInstanceMesh);       
                        this.updatePanelsVisibleStore(invisibleIndices, panelArray);
                        
                        // }
                    });
                }
                }
            })
    }

    updatePanelsVisibleStore(invinsibleIndices: number[], storePanelArray: PanelArray) {
        let isEastWest = storePanelArray.arrayType == '2';//TODO: change to east west
        let noOfPanelEast = 0;
        let noOfPanelWest = 0;
        let noOfPanels = 0;
        this.store.select(fromPanelSelectors.selectPanelsByIds(storePanelArray.panelIds)).pipe(take(1)).subscribe(storePanels => {
            let finalPanels: Panel[] = [];
            for (let i = 0; i < storePanels.length; i++) {
                if (storePanels[i]) {
                    let storePanel: Panel = JSON.parse(JSON.stringify(storePanels[i]));
                    if (invinsibleIndices.includes(storePanel.instanceId)) {
                        storePanel.visible = false;
                        finalPanels.push(storePanel);
                    } else if (!storePanel.visible) {
                        storePanel.visible = true;
                        finalPanels.push(storePanel);
                    }

                    if (storePanel.visible) {
                        if (isEastWest && storePanel.azimuth == storePanelArray.azimuth2) {
                            noOfPanelWest++;
                        } else {
                            noOfPanelEast++;
                        }
                        noOfPanels += 1;
                    }
                }
            }
            // Only update if there is any change
            if(finalPanels.length){
                storePanelArray = JSON.parse(JSON.stringify(storePanelArray));
                if(noOfPanels<1){
                    const id = storePanelArray.id;
                    const panelArrayParent = this.engServ.scene.getObjectByName(id);
                    panelArrayParent?.removeFromParent();
                    this.store.dispatch(fromPanelActions.DeleteMany({panelIds:storePanelArray.panelIds}));
                    this.store.dispatch(fromFrameActions.DeleteMany({frameIds:storePanelArray.frameIds}));
                    this.store.dispatch(fromPanelArrayActions.PanelArrayDelete({ panelArray: storePanelArray }));
                    this.store.dispatch(fromUiActions.ClearSelection());
                    this.notificationService.sendMessage({
                        message: this.translateMsg('panelArrDel'),
                        type: NotificationType.danger,
                        positionClass: "toast-bottom-left",
                        timeOut: 5000
                    });
                    return
                }
                storePanelArray.numberOfPanels = noOfPanels;
                storePanelArray.noOfPanelEast = isEastWest ? noOfPanelEast : noOfPanels;
                storePanelArray.noOfPanelWest = noOfPanelWest;
                this.store.dispatch(fromPanelActions.UpsertMany({ panels: finalPanels }))
                this.store.dispatch(fromPanelArrayActions.UpsertOne({ panelArray: storePanelArray}))

            }
        })
    }

    GetVertsFromMesh(mesh: Mesh, convertToWorld: boolean = true) {
        const roofPosArray = (mesh.geometry.attributes.position as BufferAttribute).array;
        const roofVerts: Vector3[] = [];

        for (let i = 0; i < roofPosArray.length; i += 3) {
            const vertexPt = new Vector3(roofPosArray[i], roofPosArray[i + 1], roofPosArray[i + 2]);
            if (convertToWorld) {
                mesh.localToWorld(vertexPt);
            }
            roofVerts.push(vertexPt);
        }
        return roofVerts;
    }

    transparentPanels(instanceMesh: InstancedMesh, transparent: Boolean) {
        InstancedMeshUtil.setMultipleTextures(instanceMesh, [],transparent?PanelTextureEnum.Transparent: PanelTextureEnum.Normal, true);
    }

    highlightPanels(panelIds: string[]) {

        this.store
          .select(fromPanelSelectors.selectPanelsByIds(panelIds))
          .pipe(take(1))
          .subscribe((panels) => {

            if (!panels || panels.length <= 0) return;
            panels = JSON.parse(JSON.stringify(panels));
            let groupedPanels = this.groupPanels(panels as Panel[]);
            for (let i = 0; i < Object.keys(groupedPanels).length; i++) {
              const associationId = Object.keys(groupedPanels)[i];
              let roofMesh = this.engServ.scene.getObjectByName(associationId) as Mesh;
              let instancedMesh = roofMesh.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
              const panelInstanceIds = [];

              for (let i = 0; i < groupedPanels[associationId].length; i++) {
                const instanceId = groupedPanels[associationId][i]?.instanceId;
                if (instanceId !== null && instanceId !== undefined) {
                  panelInstanceIds.push(instanceId);
                }
              }
              InstancedMeshUtil.setMultipleTextures(
                instancedMesh,
                panelInstanceIds,
                  PanelTextureEnum.Highlight
              );

            }


          });
    }

    groupPanels(panels: Panel[]) {
      return panels.reduce((groupedPanels: {[key:string]:Panel[]}, panel) => {
          const { association } = panel;
          if (!groupedPanels[association]) {
              groupedPanels[association] = [];
          }
          groupedPanels[association].push(panel);
          return groupedPanels;
      }, {});
  }

    hoverPanel(instanceId: number, panelArrayId: string) {
        let texture;
        let instancedMeshParent = this.engServ.scene.getObjectByName(panelArrayId);
        let instancedMesh = instancedMeshParent?.children[0] as InstancedMesh;

        texture = InstancedMeshUtil.getTextureAt(
            instancedMesh,
            (instanceId),
        )
        InstancedMeshUtil.setTextureAt(
            instancedMesh,
            (instanceId),
            PanelTextureEnum.Hover
        );

        return texture;
    }

    restorePanels(panelIds: string[], transparent: boolean = false) {
        this.store
        .select(fromPanelSelectors.selectPanelsByIds(panelIds))
        .pipe(take(1))
        .subscribe((panels) => {
          if (panels.length <= 0) return;

          panels = JSON.parse(JSON.stringify(panels));
          if (!panels || panels.length <= 0) return;
            panels = JSON.parse(JSON.stringify(panels));
            let groupedPanels = this.groupPanels(panels as Panel[]);
            for (let i = 0; i < Object.keys(groupedPanels).length; i++) {
              const associationId = Object.keys(groupedPanels)[i];
              let roofMesh = this.engServ.scene.getObjectByName(associationId) as Mesh;
              let instancedMesh = roofMesh.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
              const panelInstanceIds = [];

              for (let i = 0; i < groupedPanels[associationId].length; i++) {
                const instanceId = groupedPanels[associationId][i]?.instanceId;
                if (instanceId != undefined) {
                  panelInstanceIds.push(instanceId);
                }
              }
              InstancedMeshUtil.setMultipleTextures(
                instancedMesh,
                panelInstanceIds,
                transparent?PanelTextureEnum.Transparent: PanelTextureEnum.Normal
              );

            }
        });
    }
    togglePanels(selectedRoof: Object3D, show: boolean) {

        const roofId = selectedRoof.name;

        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
            .pipe(take(1))
            .subscribe(roofPanelArrays => {
                roofPanelArrays.forEach(panelArray => {
                    const rootPanelsGroup = selectedRoof.getObjectByName(panelArray.id);
                    const panelArrayGroup = rootPanelsGroup?.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
                    panelArrayGroup.visible = show;
                });
            })
    }

    panelConfigCalculator(existingPanelArray: PanelArray, fieldUpdated: string | undefined = undefined, roofId: string): any {
        const selectedRoof = this.engServ.scene.getObjectByName(roofId);
        if (!selectedRoof) {
            try {
                throw new Error("Unable to identify roof with id: " + roofId);
            } catch (err) {
                console.log(err);
                return;
            }
        }
        if (existingPanelArray == undefined) {
            this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
                .pipe(take(1))
                .subscribe(roofPanelArrays => {
                    roofPanelArrays.forEach(panelArray => {
                        existingPanelArray = panelArray;
                    });
                })
        }
        if (!existingPanelArray) return { selectedRoof: null, panelConfig: null };
        //use this function to update panel array from RHS
        const rootPanelsGroup = selectedRoof.getObjectByName(existingPanelArray.id);
        const panelArrayGroup = rootPanelsGroup?.getObjectByName(meshTypeEnum.panelArray);
        if (panelArrayGroup) {
            this.store.dispatch(fromPanelActions.DeleteMany({ panelIds: existingPanelArray.panelIds }));
            if (existingPanelArray.frameIds)
                this.store.dispatch(fromFrameActions.DeleteMany({ frameIds: existingPanelArray.frameIds }));
            panelArrayGroup.removeFromParent();


        }
        rootPanelsGroup?.clear();
        let panelConfig: PanelArray = JSON.parse(JSON.stringify(existingPanelArray));
        if (fieldUpdated !== 'azimuth') {
            panelConfig.azimuth = this.getInitialAzim(panelConfig);
            panelConfig.azimuth2 = calculateAzimuth2(panelConfig.azimuth);
        }

        if (fieldUpdated == 'arrayType') {
            panelConfig.orientation = panelConfig.arrayType == '2' ? PanelOrientation.landscape : PanelOrientation.portrait;
            panelConfig.interrowspacing = panelConfig.arrayType == '2' ? this.defaultInterRowSpace : this.calculateInterRowSpacing(panelConfig, panelConfig.roofTilt);
            panelConfig.defaultInterrowspacing = panelConfig.interrowspacing;
        }
        return { selectedRoof: selectedRoof, panelConfig: panelConfig };
    }

    recreateExistingMultiplePanelArrays(existingPanelArrays: PanelArray[], fieldUpdated: string | undefined = undefined, triggerURSnapshot?: boolean){
        let panelToUpdate: any = [];
        let panelsToBeDeleted: PanelArray[] = [];
        existingPanelArrays.forEach(panelarr=>{
            const {selectedRoof, panelConfig} = this.panelConfigCalculator(panelarr, fieldUpdated, panelarr['roofId']);
            let {shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount} = this.AddMultiplePanelsbySelection(selectedRoof, panelConfig);
            if(shapeVertexIds && shapeEdgeIds && panelsUserdataArray && panelStoreData && frames && isExisting && eastWestCount){
                panelToUpdate.push({panelArray: panelarr, shapeVertexIds, shapeEdgeIds, panelsUserdataArray, panelStoreData, frames, isExisting, eastWestCount});
            }else{
                panelsToBeDeleted.push(panelarr);
            }
        })
        this.storeUtil.updateMultiplePanelModule(panelToUpdate);

        panelsToBeDeleted.forEach(panelArr=>{
            Util.DeletePanelArrayfromStore(this.store, panelArr);
            this.store.dispatch(fromUiActions.ClearObjectsSelection(true));
            const message = this.translateMsg('delAllPnl');
            console.warn(message);
        });
        if (triggerURSnapshot == undefined || triggerURSnapshot == true) {
          this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromPanelArrayActions.UpsertOne, notification: this.translateMsg("Panel Array", false) +' '+ this.translateMsg('design.undoRedo.update',false)}))
        }

    }

    recreateExistingPanelArray(roofId: string, existingPanelArray: PanelArray | undefined = undefined, fieldUpdated: string | undefined = undefined, triggerURSnapshot?: boolean):boolean {
        // if (!existingPanelArray) return;  // throw new Error("No Existing Panel Array");
        const {selectedRoof, panelConfig} = this.panelConfigCalculator(existingPanelArray as any, fieldUpdated, roofId);
        if(selectedRoof && panelConfig){
            let isPanelAdded = this.AddPanelbySelection(selectedRoof, panelConfig, triggerURSnapshot);

            if (!isPanelAdded) {
                this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
                    .pipe(take(1))
                    .subscribe(roofPanels => {
                        roofPanels?.forEach(panelArr => {
                            Util.DeletePanelArrayfromStore(this.store, panelArr);
                        })
                    })
                this.store.dispatch(fromUiActions.ClearObjectsSelection(true));
                const message = this.translateMsg('delAllPnl');
                console.warn(message);

            }
            return isPanelAdded
        }
        return false
    }

    addDrawVertex(int: Intersection) {

        if (this.selectedBlock) {
            if (this.selectedBlock.name !== int.object.parent?.name) {
                // only allow adding points on same roof
                console.log("Please add vertex on " + this.selectedBlock.name)
                return;

            }
        }
        const point = int.point;
        if (Util.PointsTooClose(this.prevPoint, point)) {
            // this currently helps during double-click - to not create 2 vertices at same point
            console.log('Please click a point that is farther');
            return;
        }

        const vertex = this.tempVertex.clone();
        vertex.position.copy(point);

        const id = Util.generateStoreId(storeIdPrefix.vertex);
        const vertexData = new MeshUserData(id, meshTypeEnum.fittedVertex);
        vertex.userData = vertexData;
        vertex.name = id;

        this.drawVertexList.push(vertex);
        this.panelGroupObj.add(vertex);

        if (this.drawVertexList.length == 1) {
            vertex.material = this.firstVertexMaterial.clone();
            this.selectedBlock = int.object.parent;
            this.selectedBlock?.add(this.panelGroupObj);
        }

        if (this.currentLine) {
            const id = Util.generateStoreId(storeIdPrefix.edge);
            this.currentLine.name = id;

            const vertexData = new MeshUserData(id, meshTypeEnum.fittedEdge);
            this.currentLine.userData = vertexData;

            this.drawEdgeList.push(this.currentLine);
            // store previous line in array before updating the current line based on new vertex
            this.updateCurrline(point, int.object);
        }

        this.prevPoint = point.clone();
        this.currentLine = this.drawline(point, point);
    }

    closePanelDraw() {
        // this.currPoint = this.vertexList[0].position;
        const end = this.drawVertexList[0].clone().position;
        this.currentLine?.geometry.setFromPoints([this.prevPoint, end]);
        // this.addPanelDrawShape(this.drawVertexList);

        // Remove and readd method
        for (let i = this.panelGroupObj.children.length - 1; i >= 0; i--) {
            const child = this.panelGroupObj.children[i];

            // Check if the child is a mesh and has the specified name
            if (child instanceof Mesh && child.name === 'Edge') {
                // Remove the mesh from the group
                this.panelGroupObj.remove(child);
            }
        }

        // Iterate through the vertices array
        for (let i = 0; i < this.drawVertexList.length - 1; i++) {
            const startPoint = this.drawVertexList[i].clone().position;
            const endPoint = this.drawVertexList[i + 1].clone().position;

            // Create an edge and add it to the array
            const edge = this.edgeObj.draw(startPoint, endPoint,  new Color(threeColors.finalVertex));

            // Add the edge's cylinder mesh to the scene
            this.panelGroupObj.add(edge);
        }
        // Connect the last and first vertices to create a closed loop
        const startPoint = this.drawVertexList[this.drawVertexList.length - 1].clone().position;
        const endPoint = this.drawVertexList[0].clone().position;
        const edge = this.edgeObj.draw(startPoint, endPoint,  new Color(threeColors.finalVertex));
        //const edge = new Edges(startPoint, endPoint,  new Color(threeColors.finalVertex));
        this.panelGroupObj.add(edge);
        // this.drawline(this.prevPoint, this.currPoint);

        // this.prevPoint = this.vertexList[0].position;

        // this.extrudeShape();

        // this.drawActive = false;
        this.currentLine = undefined;

        (this.drawVertexList[0].material as MeshBasicMaterial).copy(this.drawVertexList[1].material as MeshBasicMaterial);

        this.generateDrawPanels();

        // return { fittedVertexList: this.fittedVertexList, roofObject: this.rt };
    }

    generateDrawPanels() {
        // Draw panel code below
        if (this.selectedBlock && this.drawPanelGeom) {

            this.AddPanelbySelection(this.selectedBlock);
        }
    }

    updateCurrline(point: Vector3, intersectMesh: Object3D) {
        const hoverMeshname = intersectMesh.name;
        if (this.drawActive && this.currentLine) {
            this.currPoint = point.clone();
            this.currentLine.geometry.setFromPoints([this.prevPoint, this.currPoint]);

            const currPoint = this.drawVertexList[0].clone();
            currPoint.position.copy(point);

            const isMouseOnfirstPt = this.drawVertexList[0].name == hoverMeshname;


            const roofVertices = [...this.drawVertexList];
            if (!isMouseOnfirstPt) {
                // if mouse is on first point, then don't need to add a new vertex
                roofVertices.push(currPoint);
            }
            if (roofVertices.length > 2) {
                this.addPanelDrawShape(roofVertices);
            }
        }
    }

    addPanelDrawShape(points: Mesh[]): void {
        this.drawPanelShape = new Shape(
            points.map((m) => new Vector2(m.position.x, m.position.y))
        );

        const shapeGeo = new ShapeGeometry(this.drawPanelShape);
        if (!this.drawPanelGeom) {
            const shapeMat = new MeshStandardMaterial({
                color: threeColors.drawShapeColor,
                transparent: true,
                opacity: 0.5,
            });
            this.drawPanelGeom = new Mesh(shapeGeo, shapeMat);
            this.drawPanelGeom.name = meshTypeEnum.drawPanelShape;

            this.panelGroupObj.add(this.drawPanelGeom);
        }
        // for (const [i, mesh] of points.entries()) {
        //   if (i == 0) {
        //     shape.moveTo(mesh.position.x, mesh.position.y);
        //   } else {
        //     shape.lineTo(mesh.position.x, mesh.position.y);
        //   }
        // }

        this.drawPanelGeom.geometry = shapeGeo;
        Util.AddHeightTo2dShape(this.drawPanelGeom, points);
        this.drawPanelGeom.position.setZ(constants.FLICKEROFFSET);
    }

    drawline(startPoint: Vector3, endPoint: Vector3): Line {
        const material = new LineBasicMaterial({ color: threeColors.wallColor });

        const geometry = new BufferGeometry().setFromPoints([
            startPoint,
            endPoint,
        ]);

        const line = new Line(geometry, material);
        line.name = 'LineMesh';
        this.currentLine = line;

        this.panelGroupObj.add(line);

        return line;
    }






    getValidPanelFrameCorners(polygonPoints: Vector3[], roofPoints: Vector3[], edgeLeftPoint: Vector3, gutterVector: Vector3, roofNormal: Vector3, roofSetback: number, edgeRightPoint: Vector3, storeRoof: RoofSection, panelConfig: PanelArray) {
        const roofGeo = this.engServ.scene.getObjectByName(storeRoof.id);
        if (!roofGeo) {
            throw new Error("getValidPanelCorners: cannot find selected roof in threejs");
        }


        // Calculate transformation matrix for converting 3D polygon points to 2D points on the roof surface. This we need to test
        let globalToLocal = Util.calculateRoofPlaneGlobalToLocalMatrix(edgeLeftPoint, gutterVector, roofNormal);
        let localToGlobal = globalToLocal.clone().invert();

        if (Util.debugThreejs) {

            const panelAxesHelper = new AxesHelper(20);
            panelAxesHelper.name = "DebugPanel AxesHelper";
            // panelAxesHelper.applyMatrix4(globalToLocal);
            this.panelGroupObj.add(panelAxesHelper)
        }

        // Use that transformation to calculate roof polygon points in 2D
        let polygonPoints2D = Util.convertPolygonPointsTo2D(polygonPoints, globalToLocal);
        // let roofPoints2D = Util.convertPolygonPointsTo2D(roofPoints, globalToLocal);
        const z_inverse = polygonPoints2D[0];
        // let roof2dptwithSetback = Util.setbackRoofPts(roofPoints2D, roofSetback, this.store, storeRoof);
        const roofSetbackPoints = roofSetback > 0 ? (roofGeo.userData as MeshUserData).roofsetbackPts : (roofGeo.userData?.roofsetbackPts ? roofGeo.userData.roofsetbackPts : roofPoints);
        if (!roofSetbackPoints) {
            // this can typically happen in old roof before (Nov 19, 2023), If this happens test it by creating a new roof
            throw new Error("getValidPanelCorners: roof userdata doesn't have setback points");
        }
        const roof2dptwithSetback = Util.convertPolygonPointsTo2D(roofSetbackPoints, globalToLocal);
        roof2dptwithSetback.push(roof2dptwithSetback[0])
        let panelArea2dPtsWithSetback = (panelConfig.isDrawToFill || roofSetback <= 0) ? polygonPoints2D : roof2dptwithSetback;

        const panelArrayCorners = panelArea2dPtsWithSetback; // polygonPoints2D
        let localGutterStart = edgeLeftPoint; //.clone().applyMatrix4(globalToLocal);
        let localGutterEnd = edgeRightPoint; //.clone().applyMatrix4(globalToLocal);

        // Calculate bounding box for those roof points
        let bbox = Util.calculateBoundingBox(panelArrayCorners);

        if (panelConfig.isDrawToFill) {
            // if draw to fill, then setback is not necessary, but the gutter needs to be moved to the bbox.min

            const localGutterStart2D = edgeLeftPoint.clone().applyMatrix4(globalToLocal);
            const localGutterEnd2D = edgeRightPoint.clone().applyMatrix4(globalToLocal);

            const gutterToBboxMinVector = new Vector3();
            gutterToBboxMinVector.subVectors(bbox.min, localGutterStart2D)
            // gutterToBboxMinVector.normalize();
            // const gutterStartToBboxMindist = localGutterStart2D.distanceTo(bbox.min);

            // const moveGutterVector = gutterToBboxMinVector.clone().multiplyScalar(gutterStartToBboxMindist)
            localGutterStart = localGutterStart2D.clone().add(gutterToBboxMinVector);
            localGutterEnd = localGutterEnd2D.clone().add(gutterToBboxMinVector);


        } else {

            let gutterStartIndex = -1;
            let gutterEndIndex = -1;

            polygonPoints.forEach((pt, i) => {
                // comparing distance below becuase when scene is restored, the BufferGeometryUtils.mergeVertices doesn't give exactly same points

                if (pt.distanceTo(edgeLeftPoint) < 0.01) {
                    gutterStartIndex = i
                }

                if (pt.distanceTo(edgeRightPoint) < 0.01) {
                    gutterEndIndex = i
                }

            });
            if (gutterStartIndex == -1 || gutterEndIndex == -1) {
                throw new Error("Unable to identify gutter after setback");
            }
            const setbackGutterStart = panelArea2dPtsWithSetback[gutterStartIndex];
            const setbackGutterEnd = panelArea2dPtsWithSetback[gutterEndIndex];

            localGutterStart = setbackGutterStart; //.clone().applyMatrix4(globalToLocal);
            localGutterEnd = setbackGutterEnd; //.clone().applyMatrix4(globalToLocal);
        }


        // // Calculate polygon area
        // commenting this here, but this can be used to validate the area calculation during in store
        // const roofAreaZ = ShapeUtils.area(polygonPoints2D);
        // const roofArea = ShapeUtils.area(polygonPoints2D.map(pt => new Vector2(pt.x, pt.y)));
        // console.log({ roofAreaZ, roofArea });

        // Fit panels in the entire bounding box and store panel corners in pairs of four
        let panelFrameCorners = this.fitPanelsInBoundingBox(bbox, panelArrayCorners, localGutterStart, localGutterEnd, panelConfig);

        let allKeepOutPointsOnRoof = this.getAllKeepOutPointsOnRoof(storeRoof, roofNormal);
        // Get 3D points to use for laying out panels in place of what we were getting from JSON file
        this.debugUtil.DebugPanelCalculations(this.panelGroupObj, panelFrameCorners, panelArrayCorners, allKeepOutPointsOnRoof)
        let validPanelFrameCorners = this.removePanelsOutsideRoof(panelFrameCorners, panelArrayCorners, roof2dptwithSetback);
        let globalpanelFrameCorners = this.convertToGlobal(validPanelFrameCorners, localToGlobal, z_inverse);

        return { globalpanelFrameCorners, globalToLocal, allKeepOutPointsOnRoof };

    }

    getObstacleSetbackVertices(obstacles: Obstacle[]) {
        // Map over each obstacle to get setback vertices
        const vertices: Vector3[][] = obstacles.map(o => {
            // Fetch the setback mesh for the current obstacle
            const obstObj = this.engServ.scene.getObjectByName(o.id) as Mesh;
            if (obstObj?.parent) {
                // Get the setback mesh from the parent
                let setback = obstObj.parent?.getObjectByName(meshTypeEnum.obstacleSetbackMesh) as Mesh;
                if (setback) {
                    // Get the vertices of the setback mesh
                    let obstVerticesLength = Util.getMeshVertices(obstObj).length;
                    const obstOnRoofVectors = obstObj ? Util.getMeshVertices(setback as Mesh, true) : [];
                    // Slice the vertices array in half
                    let firstHalf = obstOnRoofVectors;
                    if ((setback.geometry as any).parameters?.shapes?.holes?.length > 0 || (setback.geometry as any).parameters?.shapes[0]?.holes?.length > 0) {
                        firstHalf = obstOnRoofVectors.slice(0, -obstVerticesLength);
                        firstHalf.push(firstHalf[0])
                    }
                    return firstHalf;
                }
                return [];
            } else {
                return [];
            }
        })
        return vertices;
    }

    /**
     *
     * @returns a 2D array of polygons including obstacles and overlapping roofs for panel placements
     */
    getAllKeepOutPointsOnRoof(storeRoof: RoofSection, roofNormal: Vector3) {
        const roofObstacles = this.GetRoofStoreAssociatedObstacles(storeRoof.id);
        const obstVertices = this.GetObstacleVertices(roofObstacles)
        const obstSetbackVertices = this.getObstacleSetbackVertices(roofObstacles);
        const combinedVertices = obstVertices.concat(obstSetbackVertices);
        if (storeRoof.overlappingRoofs) {
            storeRoof.overlappingRoofs.forEach(oRoofId => {
                let oRoof = this.GetStoreRoofSection(oRoofId);
                if (oRoof) {
                    let maxVertexHeightofAssociatedRoof = Util.getMeasurementPixelToFeet(this.getMaxHeightVertexOfRoof(oRoof.id));
                    let maxVertexHeightofCurrentRoof = Util.getMeasurementPixelToFeet(this.getMaxHeightVertexOfRoof(storeRoof.id));
                    if (maxVertexHeightofAssociatedRoof >= maxVertexHeightofCurrentRoof) {
                        let oRoof = this.engServ.scene.getObjectByName(oRoofId) as Object3D;
                        if (oRoof) {
                            let oRoofPlane = oRoof.getObjectByName(meshTypeEnum.roofPlane) as Mesh;
                            let vertices = Util.getMeshVertices(oRoofPlane);
                            let projectedVertices = vertices.map(v => {
                                // let k = roofNormal.clone().dot(edgeLeftPoint.clone().sub(v)) / roofNormal.z
                                return new Vector3(v.x, v.y, v.z);
                            });
                            combinedVertices.push(projectedVertices);
                        }
                    }
                }
            })
        }
        return combinedVertices;
    }

    /**
     * return all visible panel instances points
     * @param instancedMesh
     * @returns
     */
    getAllPanelPoints(instancedMesh: InstancedMesh, allPanels: boolean = false) : {[key: string]: Vector3[]}{
        let panelArrayId = instancedMesh.parent?.name;
        let panelConfig: PanelArray | undefined;
        this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId!])).pipe(take(1)).subscribe(arrayy => {
            if (arrayy.length <= 0) return;
            panelConfig = arrayy[0]!;
        })
        if(!panelConfig) return {};
        let visibilityArray = InstancedMeshUtil.getVisibilityArray(instancedMesh);

        let panelPoints : {[key: string]: Vector3[]}= {};
        for (let i = 0; i < instancedMesh.count; i++) {
          let isVisibleInstance = allPanels || visibilityArray[i] ==1;
            if(isVisibleInstance){
              let panelGeom = new PlaneGeometry(Util.getMeasurementFeetToPixel(panelConfig.panelWidth), Util.getMeasurementFeetToPixel(panelConfig.panelLength));
              const matrix = new Matrix4();
              instancedMesh.getMatrixAt(i, matrix);
              panelGeom.applyMatrix4(matrix);
               let vertices =  Util.getGeometryVertices(panelGeom);
               [vertices[2], vertices[3]] = [vertices[3], vertices[2]]
                panelPoints[''+i.toString()]=vertices;
            }
        }
        return panelPoints;
    }

    getMaxHeightVertexOfRoof(name:string){
        let roofPlane = (this.engServ.scene.getObjectByName(name) as Object3D).getObjectByName(meshTypeEnum.roofPlane) as Mesh;
        let vertices = Util.getMeshVertices(roofPlane, true);
        let max = 0;
        vertices.forEach((v,i)=>{
            if(v.z > max){
                max = v.z;
            }
        })
        return max;
    }

    fitPanelsInBoundingBox(bbox: Box3, panelArrayCorners: Vector3[], gutterStartPoint: Vector3, gutterEndPoint: Vector3, panelConfig: PanelArray) {

        // const gap = panelDims.GAP;
        const isLandscape = (panelConfig.orientation == PanelOrientation.landscape);
        const alignType = panelConfig.alignment;
        const isEastWest = panelConfig.arrayType == '2';

        let panelWidthPx =Util.getMeasurementFeetToPixel(panelConfig.panelWidth) ;  // panelDims.WIDTH;
        let panelLengthPx = Util.getMeasurementFeetToPixel(panelConfig.panelLength);  // panelDims.LENGTH;
        let frameSpace = Util.getMeasurementFeetToPixel(panelConfig.frameSpace);
        let clampSpace = Util.getMeasurementFeetToPixel(panelConfig.clampSpace);
        let peakSpace = Util.getMeasurementFeetToPixel(panelConfig.peakSpace);

        const orientedFrameWidthPx = (isLandscape ? panelConfig.noOfColumns * panelLengthPx : panelConfig.noOfColumns * panelWidthPx) + (panelConfig.noOfColumns-1)*clampSpace; // this is the horizontal dimension
        const orientedFrameLengthPx = (isLandscape ? panelConfig.noOfRows * panelWidthPx : panelConfig.noOfRows * panelLengthPx) + (panelConfig.noOfRows-1)*clampSpace; // this is the vertical dimension

        const bboxMinGutterXDiffStart = bbox.min.x - gutterStartPoint.x;
        // const bboxMinGutterXDiffEnd = bbox.min.x - gutterEndPoint.x;

        // const bboxMinGutterDiff = Math.max(bboxMinGutterXDiffEnd, bboxMinGutterXDiffStart);
        if (bboxMinGutterXDiffStart < 0) {
            // if bbox min point is before the gutter point, then expand bbox min so that the gutterStart coincides with a panel's bottom left corner as starting point
            // this ensures that the first row of the panels is always included
            let remainderDistance = Math.abs(bboxMinGutterXDiffStart % orientedFrameWidthPx);
            bbox.min.x -= remainderDistance;
        }

        /**
        when a panel is tilted and inter-row spacing is 0, then gap between panels from top view should be 0
        * @param {number} projectedPanelFrameLength - the projected length of a tilted panel on the ground/roof.
        */
        const projectedPanelFrameLength = orientedFrameLengthPx * Math.cos(MathUtils.degToRad(panelConfig.panelTilt));

        let rowHeight = 0;
        if (isEastWest) {
            rowHeight = 2 * projectedPanelFrameLength + (Util.getMeasurementFeetToPixel(panelConfig.interrowspacing!) ?? 0) + peakSpace;
        } else {
            rowHeight = projectedPanelFrameLength + (Util.getMeasurementFeetToPixel(panelConfig.interrowspacing!) ?? 0);

        }
        const xIncr = orientedFrameWidthPx + frameSpace; // + gap;
        const yIncr = rowHeight; // + gap;

        if (xIncr <= 0 || yIncr <= 0) throw new Error("incorrect panelWidth or panelHeight");


        // // LOGIC to calculate panel based on alignment
        const bottomLineStart = gutterStartPoint.clone().setX(bbox.min.x);
        const bottomLineEnd = gutterEndPoint.clone().setX(bbox.max.x);

        const gutterToBboxDir = new Vector3();
        gutterToBboxDir.subVectors(bbox.min.clone().setZ(0), bottomLineStart.clone().setZ(0));

        // if the gutter start point and bbox min point are not the same, then move the gutter to start at bbob min
        bottomLineStart.add(gutterToBboxDir);
        bottomLineEnd.add(gutterToBboxDir);

        // extend the bottom line by 5 units to make sure it covers all the polygon
        bottomLineStart.x -= 5;
        bottomLineEnd.x += 5;

        //Commenting this code below because of ENV-6294

        // const { minPt, minPt2 } = Util.getBottom2Points(panelArrayCorners, "y");

        // if (minPt2) {
        //     // if minPt & minPt2 of panel array bounderies are in different y positions, it means the bottom line is not straight. So None of the bottom row panel will be valid.
        //     // So its better to move the "Y" of bottom start point to the minPt2.y

        //     bottomLineStart.setY(minPt2.y);
        //     bottomLineEnd.setY(minPt2.y);
        // }

        const bottomline = [bottomLineStart, bottomLineEnd];
        let firstRowStart = undefined;

        const inset = constants.DefaultEdgeThickness; // 0.1; // offset start so that the first row always is inside the panelArray boundary
        let yStart = bottomLineStart.y + inset;
        const yEnd = bbox.max.y - yIncr; // - inset;
        let panelCorners: Vector3[][] = [];

        for (let y = yStart; y < yEnd; y += yIncr) {
            const currRowTopLine = bottomline.map(pt => {
                pt.y = y;
                return pt.clone().setY(pt.y + projectedPanelFrameLength);
            });

            const topIntersectPts = this.checkRowIntersectionPts(panelArrayCorners, currRowTopLine);
            const bottomIntersectPts = this.checkRowIntersectionPts(panelArrayCorners, bottomline);

            let usableRowStartPt = topIntersectPts.minPt.x < bottomIntersectPts.minPt.x ? bottomIntersectPts.minPt : topIntersectPts.minPt;
            let usableRowEndPt = topIntersectPts.maxPt.x < bottomIntersectPts.maxPt.x ? topIntersectPts.maxPt : bottomIntersectPts.maxPt;

            usableRowStartPt.x += inset;
            usableRowEndPt.x -= inset;

            let usableRowDist = Math.abs(usableRowEndPt.x - usableRowStartPt.x);
            // usableRowDist = usableRowDist; // - (2 * inset); // the thickness of edges need to be removed from the total distance

            if (usableRowStartPt.x > usableRowEndPt.x || usableRowDist < orientedFrameWidthPx) {
                // if row width is less than panel width
                continue;
            }

            let remainderDistance = (usableRowDist % (orientedFrameWidthPx+frameSpace))%orientedFrameWidthPx + frameSpace;
            // let validRowDistance = usableRowDist - remainderDistance;

            // this is used to offset x starting point slightly so the first panel is always inside polygon;
            // this is useful because sometimes, the calulcation to check point in polygon is not 100% accurate;
            let xStartOffset = 0;

            switch (alignType) {
                case ArrayAlignment.left:
                    xStartOffset = 0.0000001;
                    break;

                case ArrayAlignment.right:
                    xStartOffset = remainderDistance - 0.0000001;
                    break;

                case ArrayAlignment.center:
                    // center alignment
                    xStartOffset = (remainderDistance / 2); // - 0.0000001;
                    break;

                case ArrayAlignment.justify:
                    // justify alignment
                    if (firstRowStart) {
                        // from 2nd row, caluclate the difference between first row start and curr row start and move curr starting pointt by the remainder
                        const currRowToFirstRowDiff = firstRowStart.x - usableRowStartPt.x;
                        const remainderJustify = currRowToFirstRowDiff % orientedFrameWidthPx;

                        xStartOffset = remainderJustify + 0.0000001;

                    } else {
                        firstRowStart = usableRowStartPt;
                        xStartOffset = 0.0000001;
                    }
                    break;

                default:
                    break;
            }

            const xStart = usableRowStartPt.x + xStartOffset; // + inset;
            const xEnd = usableRowEndPt.x - xIncr + frameSpace; // - inset;
            for (let x = xStart; x < xEnd; x += xIncr) {
                const panelPts = [
                    new Vector3(x, y, 0),
                    new Vector3(x + orientedFrameWidthPx, y, 0),
                    new Vector3(x + orientedFrameWidthPx, y + (isEastWest ? (2 * projectedPanelFrameLength + peakSpace) : projectedPanelFrameLength), 0),
                    new Vector3(x, y + (isEastWest ? (2 * projectedPanelFrameLength + peakSpace) : projectedPanelFrameLength), 0),
                ];

                panelCorners.push(panelPts);
            }

        }
        return panelCorners;
    }

    checkRowIntersectionPts(polygonPointsArray: Vector3[], gutter: Vector3[]) {
        const allIntersectionPts: Vector3[] = [];

        let max = -Infinity;
        let min = Infinity;
        let maxPt: Vector3 | undefined, minPt: Vector3 | undefined;
        polygonPointsArray.map((pt, i) => {
            const polygonLineStart = pt;
            const polygonLineEnd = i + 1 > polygonPointsArray.length - 1 ? polygonPointsArray[0] : polygonPointsArray[i + 1];

            const gutterStart = gutter[0];
            const gutterEnd = gutter[1];

            const intersect = CollisionUtil.intersectLines([polygonLineStart, polygonLineEnd], [gutterStart, gutterEnd])

            if (intersect) {
                allIntersectionPts.push(intersect);

                if (intersect.x > max) {
                    // if more than 2 intersections, then identify max Pt
                    maxPt = intersect;
                    max = intersect.x;
                }

                if (intersect.x < min) {
                    // if more than 2 intersections, then identify min Pt
                    minPt = intersect;
                    min = intersect.x;
                }
            }

        });

        if (!maxPt || !minPt) {
            throw new Error("Unable to intersect ");

        }

        // all intersection points could have more than 3 if the polygon has a concave shape
        return { minPt, maxPt };
    }

  removePanelsOutsideRoof(panelCorners: Vector3[][], polygonPoints: Vector3[], roof2dptwithSetback: Vector3[]) {
    let validPanelCorners: Vector3[][] = panelCorners.filter((panelPts, i) => {
      panelPts.push(panelPts[0]);
      let a = CollisionUtil.isPolygonInside(polygonPoints, panelPts);
      let b = CollisionUtil.isPolygonInside(roof2dptwithSetback, panelPts);
      return (a && b );
    });

    return validPanelCorners;
  }
    /**
     * @param panels
     * @param keepoutPoints2D keepoutpoints in local coordinates
     * @param globalToLocal
     * @param frames frames object from panel array
     *@returns make panels invisible which are overlapping with obstacles or other roofs
     */
    invisiblePanelsOverlappingWithObstacles(panelConfig: PanelArray, panelInstancedMesh:InstancedMesh, keepoutPoints: Vector3[][]) {
      let invisibleIndices: number[] = [];
      let visibleIndices: number[] = [];

      for (let i = 0; i < panelInstancedMesh.count; i++) {
        let mat = new Matrix4();
        panelInstancedMesh.getMatrixAt(i, mat);
        let panelGeom = new PlaneGeometry(
          Util.getMeasurementFeetToPixel(panelConfig.panelWidth),
          Util.getMeasurementFeetToPixel(panelConfig.panelLength)
        );
        panelGeom.applyMatrix4(mat);
        let isCollided = this.checkPanelCollisionWithKeepout(
          panelGeom,
          keepoutPoints
        );
        if (isCollided) {
          invisibleIndices.push(i);
        } else {
          visibleIndices.push(i);
        }
      }
        InstancedMeshUtil.setMultipleVisibilities(panelInstancedMesh,invisibleIndices, false);
        InstancedMeshUtil.setMultipleVisibilities(panelInstancedMesh,visibleIndices, true);
      return invisibleIndices;

    }
    /**
     *
     * @param panel
     * @param keepoutPoints
     * @returns whether the panel collides with the keepouts
     */
       checkPanelCollisionWithKeepout(panel: BufferGeometry, keepoutPoints: Vector3[][]): boolean {
        let panelVertices = Util.getGeometryVertices(panel);
        [panelVertices[2], panelVertices[3]] = [panelVertices[3], panelVertices[2]];
    
        if (keepoutPoints.length <= 0) return false;
    
        // Calculate AABB for the panel
        const panelAABB = this.calculateAABB(panelVertices);
    
        for (const keepOut of keepoutPoints) {
            // Calculate AABB for the keepout polygon
            const keepoutAABB = this.calculateAABB(keepOut);
    
            // Check if AABBs intersect
            if (!this.aabbIntersect(keepoutAABB, panelAABB)) {
                continue;
            }
    
            // Combined loop for checking vertices inside polygons and edge intersections
            for (let i = 0; i < panelVertices.length; i++) {
                const panelVertex = panelVertices[i];
                const nextPanelVertex = panelVertices[(i + 1) % panelVertices.length];
                const panelEdgePts = [panelVertex, nextPanelVertex];

                // Check if any panel vertex is inside the keepout polygon
                if (CollisionUtil.isPointInPolygon(keepOut, panelVertex, true)) {
                    return true;
                }

                for (let k = 0; k < keepOut.length; k++) {
                    const keepoutVertex = keepOut[k];
                    const nextKeepoutVertex = keepOut[(k + 1) % keepOut.length];
                    const keepoutEdgePts = [keepoutVertex, nextKeepoutVertex];

                    // Check if any keepout vertex is inside the panel polygon
                    if (CollisionUtil.isPointInPolygon(panelVertices, keepoutVertex, true)) {
                        return true;
                    }

                    // Check for edge intersections
                    if (CollisionUtil.intersectLines(panelEdgePts, keepoutEdgePts)) {
                        return true;
                    }
                }
            }
        }
    
        return false;
    }
    
    
    calculateAABB(points: Vector3[]): Box3 {
        const bbox = new Box3();
    
        for (const point of points) {
            bbox.expandByPoint(point);
        }
    
        return bbox;
    }
    
    aabbIntersect(aabb1: { min: Vector3, max: Vector3 }, aabb2: { min: Vector3, max: Vector3 }): boolean {
        return (
            aabb1.min.x <= aabb2.max.x && aabb1.max.x >= aabb2.min.x &&
            aabb1.min.y <= aabb2.max.y && aabb1.max.y >= aabb2.min.y
            // && aabb1.min.z <= aabb2.max.z && aabb1.max.z >= aabb2.min.z
        );
    }

    convertToGlobal(validPanelCorners: Vector3[][], localToGlobalMatrix: Matrix4, z_inverse: Vector3) {
        let validPanelCorners3D: Vector3[][] = []

        validPanelCorners.forEach(panel => {
            const globalPanelPts = panel.map(panelPt => {
                let point = panelPt.clone();
                point.add(new Vector3(0, 0, z_inverse.z + 0.1));
                //let validPanelCorner3D = point

                return point.applyMatrix4(localToGlobalMatrix);
            });

            validPanelCorners3D.push(globalPanelPts);
        });
        return validPanelCorners3D;
    }


    convertPanelsToLocal(globalPanelCorners: Vector3[][], globalToLocalMatrix: Matrix4, z_inverse: Vector3) {
        let localPanelCorners: Vector3[][] = []

        globalPanelCorners.forEach(panel => {
            const globalPanelPts = panel.map(panelPt => {
                let point = panelPt.clone();
                point.applyMatrix4(globalToLocalMatrix);
                point.sub(new Vector3(0, 0, z_inverse.z + 0.1));
                //let validPanelCorner3D = point

                return point;
            });

            localPanelCorners.push(globalPanelPts);
        });
        return localPanelCorners;
    }

    createVertex(type: number): Mesh {
        const sphereGeo = new SphereGeometry(8, 16, 8);
        const boxGeo = new BoxGeometry(10, 10, 10);
        const material = new MeshPhysicalMaterial({
            color: type == vertexType.spherical ? threeColors.finalVertex : threeColors.tempVertex,
            side: DoubleSide,
            transparent: true,
            opacity: 0.5,
        });

        const geometry = type == vertexType.spherical ? sphereGeo : boxGeo;
        var vertex = new Mesh(geometry, material);
        vertex.name = type == vertexType.spherical ? "RoofVertex" : "tempVertex";
        return vertex;
    }


    tiltPanelFrame(frameGroup: Mesh[], framePts: Vector3[], panelTiltDeg: number = 0, gutterVector: Vector3, isEastWest:boolean) {
        if (panelTiltDeg == 0) return framePts;

        let panelFrameRotationAxisVector = Util.GetVectorByPoints(framePts[0], framePts[1]);
        let panelFrameBottomPt = framePts[0];
        let panelFrameTopPt = framePts[3];
        let tiltAngleRad = Util.convertDegreeToRadian(panelTiltDeg);
        const panelAngleWithGutter = panelFrameRotationAxisVector.angleTo(gutterVector);
        if (panelAngleWithGutter == Math.PI) {
            tiltAngleRad = -tiltAngleRad;
        }
        frameGroup.forEach((panel, i) => {
            if (isEastWest) {
                if (i < frameGroup.length / 2) {
                  Util.rotateAroundWorldAxis(panel, panelFrameBottomPt, panelFrameRotationAxisVector, tiltAngleRad);
                }else{
                   Util.rotateAroundWorldAxis(panel, panelFrameTopPt, panelFrameRotationAxisVector, -tiltAngleRad);
                }
            } else {
              Util.rotateAroundWorldAxis(panel, panelFrameBottomPt, panelFrameRotationAxisVector, tiltAngleRad);
            }
        });
        let tiltedFramePoints = Util.rotatePointsAroundAxis(framePts,  panelFrameRotationAxisVector, tiltAngleRad, panelFrameBottomPt);
        return tiltedFramePoints;
    }

    getPanelBottomAxis(pts: Vector3[]) {

        const { minPt, minPt2 } = Util.getBottom2Points(pts);

        if (minPt2 && minPt) {
            const axisVector = new Vector3(minPt2.x - minPt.x, minPt2.y - minPt.y, minPt2.z - minPt.z);
            return { axisVector, minPt };
        } else {
            throw new Error("Unable to find axis of rotation of the panel");
        }
    }

    GetRoofStoreAssociatedObstacles(roofId: string) {
        let obstacles: Obstacle[] = [];
        this.store
            .select(fromObstacleSelectors.selectObstaclesByAssociatedRoofId(roofId))
            .pipe(take(1))
            .subscribe((storeObs) => {
                storeObs.map(o => {
                    o = JSON.parse(JSON.stringify(o));
                    if (o !== undefined) {
                        o.height = Util.getMeasurementFeetToPixel(o.height);
                        o.length = Util.getMeasurementFeetToPixel(o.length);
                        o.width = Util.getMeasurementFeetToPixel(o.width);
                        o.diameter = Util.getMeasurementFeetToPixel(o.diameter ? o.diameter : 0);
                        obstacles.push(o);
                    }
                })
            })

        return obstacles;
    }

    GetStoreRoofSection(roofId: string) {
        let roofSection!: RoofSection | undefined
        this.store
            .select(fromRoofSectionSelectors.selectRoofById([roofId]))
            .pipe(take(1))
            .subscribe((roofs) => {
                roofSection = roofs[0]
            })

        return roofSection
    }

    getStorePanelArray(roofId: string){
        let panelArray!: PanelArray
        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
                .pipe(take(1))
                .subscribe(roofPanelArrays => {
                    panelArray = roofPanelArrays[0]
                })
                return panelArray
    }

    GetPanelArrayBoundaryVertices(panelArray: PanelArray) {
        let vertices: Vector3[] = []
        this.store
            .select(fromVertexSelectors.selectVerticesByIds(panelArray.vertices ?? []))
            .pipe(take(1))
            .subscribe((verts) => {
                vertices = verts.map(v => new Vector3(v.x, v.y, v.z));
            })

        return vertices
    }
    GetObstacleVertices(obstacles: Obstacle[]) {
        const vertices: Vector3[][] = obstacles.map(o => {
            // let vectors: Vector3[] = [];

            // const obstRoof = this.engServ.scene.getObjectByName(o.roofId);
            // const roofPlane = (obstRoof?.userData as MeshUserData).roofConfirmedPlane
            const obstRootObj = this.engServ.scene.getObjectByName(o.id) as Mesh;
            // const obstMeshOnRoof = obstRootObj?.parent?.getObjectByName(meshTypeEnum.obstMeshFlushedOnRoof) as Mesh;

            // if (obstRootObj) {
            const ObstOnRoofVectors = obstRootObj ? Util.getMeshVertices(obstRootObj, true) : [];
            // }

            if (o.type == ObstacleType.rectangle || o.type == ObstacleType.walkways) {
                // swap 3rd and 4th vertices to make them clockwise
                // this is necessary to check if panel pt is in obstacle
                // https://stackoverflow.com/questions/22521982/check-if-point-is-inside-a-polygon/29915728#comment135270782_29915728
                const temp = ObstOnRoofVectors[2];
                ObstOnRoofVectors[2] = ObstOnRoofVectors[3];
                ObstOnRoofVectors[3] = temp;

            }


            // // commenting below coed because if the obstacle has height, then the vertices are not exactly on roof - which might cause issues
            // const vertexIds = o.vertexIds;
            // const obstHeight = Util.getMeasurementFeetToPixel(o.height);
            // this.store
            //     .select(fromVertexSelectors.selectVerticesByIds(vertexIds))
            //     .pipe(take(1))
            //     .subscribe((storeVerts) => {
            //         if (o.type == ObstacleType.circle) {
            //             const center = new Vector3(storeVerts[0].x, storeVerts[0].y, storeVerts[0].z);
            //             const circleBbox = new PlaneGeometry(o.diameter, o.diameter);
            //             var mat = new MeshBasicMaterial({ color: 0xff0000 });
            //             var circleBboxMesh = new Mesh(circleBbox, mat);
            //             circleBboxMesh.position.copy(center);

            //             circleBboxMesh.updateMatrix();
            //             const matrix = circleBboxMesh.matrix;
            //             const positionsBuffer = circleBboxMesh.geometry.getAttribute('position') as BufferAttribute;
            //             for (let i = 0; i < positionsBuffer.array.length / 3; i++) {
            //                 const point = new Vector3().fromBufferAttribute(positionsBuffer, i);
            //                 const vertMesh = Util.createVertex(vertexType.spherical);
            //                 vertMesh.name = Util.generateStoreId('vertex');
            //                 vertMesh.position.copy(point);

            //                 let worldPt = point.clone();
            //                 // worldPt.z -= obstHeight;
            //                 // if (roofPlane) {
            //                 // // projectPointOnPlane below is not working as expected
            //                 //     worldPt = Util.projectPointOnPlane(worldPt.clone(), roofPlane);
            //                 // }
            //                 worldPt.applyMatrix4(matrix);
            //                 vectors.push(worldPt)
            //             }
            //         } else {
            //             storeVerts.map(v => {
            //                 let pt = new Vector3(v.x, v.y, v.z - obstHeight);
            //                 // if (roofPlane) {
            //                 // // projectPointOnPlane below is not working as expected
            //                 //     pt = Util.projectPointOnPlane(pt.clone(), roofPlane);
            //                 // }
            //                 vectors.push(pt);
            //             })
            //         }

            //     })

            return ObstOnRoofVectors;  // vectors;
        })

        return vertices;

    }

    calculateInterRowSpacing(panelConfig: PanelArray, roofTilt: number) {
        let interrowspacing = 0;
        let panelTilt = panelConfig.panelTilt;
        if(panelConfig.arrayType == '2'){ //TODO: Change it to eastwest
            return this.defaultInterRowSpace;
        }
        if (panelTilt <= 0) {
            panelTilt = 0;
            interrowspacing = 0;
        } else if (panelTilt > 0) {
            const panelWidth = panelConfig.panelWidth;
            const panelLength = panelConfig.panelLength;

            const isLandscape = (panelConfig.orientation == PanelOrientation.landscape);

            let sumVal = 0;
            const totDegrees = 90;
            sumVal = Number(panelTilt) + Number(roofTilt);

            // ---- Calculate heightDiff based on panel Tilt ----
            let heightDiff = isLandscape ?panelConfig.noOfRows * panelWidth : panelConfig.noOfRows * panelLength; // default row height is the same as panel - if tilt is 0;

            // If panel is tilted, then the gap between the rows can be less than the panel height/width

            panelTilt = (sumVal <= 90) ? panelTilt : (totDegrees - roofTilt);
            panelTilt = MathUtils.degToRad(panelTilt);
            heightDiff = Math.sin(panelTilt) * (isLandscape ? panelConfig.noOfRows * panelWidth : panelConfig.noOfRows * panelLength);

            // ---- Use heightDiff to calculate min rowspacing so there's not shadow overlap using solarTilt ----
            let solarTiltAngel, panelRowHeight;
            let locale = this.authService.getItem('localeId', 'localStorage');
            if (!locale) {
                locale = 'en_US';
            }
            solarTiltAngel = this.localeToSolarTiltAngelObj[locale];
            if (!solarTiltAngel) {
                solarTiltAngel = 16;
            }
            //To add clamp space
            heightDiff = heightDiff + ((panelConfig.noOfRows - 1) * panelConfig.clampSpace);
            // heightDiff = heightDiff + (panelConfig.noOfRows == 1 ? panelConfig.noOfRows * panelConfig.clampSpace : (panelConfig.noOfRows - 1) * panelConfig.frameSpace);

            solarTiltAngel = Number(solarTiltAngel);
            solarTiltAngel = MathUtils.degToRad(solarTiltAngel);
            panelRowHeight = Math.abs(heightDiff / Math.tan(solarTiltAngel));
            interrowspacing = panelRowHeight - heightDiff;
        }
        return Util.getMeasurementPixelToFeet(interrowspacing);

    }

    getLocaleToSolarTiltAngels(): Subscription {
        return this.panelconfigService.getLocaleToTiltAngels().subscribe(
            (response) => {
                this.localeToSolarTiltAngelObj = response;
            },
            (error) => {
                console.log(
                    'Something went wrong while fetching locale to tilt angels list'
                );
            }
        )
    }

    changeCursor(intersects: Intersection[], isSinglePanelMode: boolean = false) {
        const nearestIntersect = intersects[0]?.object;
        this.invalidSinglePanel = false;
        this.cursorState = PanelArrayCursors.NORMALPANEL;
        if (!nearestIntersect) {
            this.cursorState = PanelArrayCursors.INVALIDSINGLEPANELADD;
            this.engServ.updatePanelArrayCursorState(this.cursorState);
            return;
        }
        const namePrefix = nearestIntersect.name.split('-').shift();
        switch (namePrefix) {
            case meshTypeEnum.roofPlane:
            case meshTypeEnum.panel:
            case storeIdPrefix.singlePanel:
            case meshTypeEnum.instancedMesh:

                if (!isSinglePanelMode)
                    this.cursorState = PanelArrayCursors.VALIDPANELARRAYADD;
                else
                    this.cursorState = PanelArrayCursors.NORMALPANEL;
                break;
            case meshTypeEnum.MapPlane:
            case meshTypeEnum.roofSetbackMesh:
            case meshTypeEnum.obstacleSetbackMesh:
            case 'tempVertex':
                this.cursorState = PanelArrayCursors.INVALIDPANELARRAYADD;
                break;
            default:
              this.cursorState = PanelArrayCursors.INVALIDPANELARRAYADD;
              let isHoveringOnPanelsWithSinglePanelMode = nearestIntersect.name == meshTypeEnum.panelArray && isSinglePanelMode;
              if(isHoveringOnPanelsWithSinglePanelMode){
                this.cursorState = PanelArrayCursors.NORMALPANEL;
              }
              break;

        }

        this.engServ.updatePanelArrayCursorState(this.cursorState);
    }

    updateSinglePanelWhileDrawing(intersects: Intersection[]) {
        intersects = intersects.filter(int => (int.object.name != meshTypeEnum.temporaryPanelMesh && !int.object.name.includes(meshTypeEnum.panel)))

        const intObj = intersects[0]?.object;
        let roofPlane;
        switch (true) {
            case intObj.name.includes(meshTypeEnum.roofPlane):
                roofPlane = intObj as Mesh;
                break;
            // case intObj.name.includes(meshTypeEnum.roofPlane):

            default:
              if (this.temporaryTransparentPanel) {
                  this.temporaryTransparentPanel.visible = false;
              }
              return
        }
        if (!roofPlane) return;
        this.isSinglePanelRotated = false;
      
        this.createOrUpdateTemporaryPanel(roofPlane, intersects[0].point);

        let isValidPlacement = this.isPanelPlacementValid(this.temporaryTransparentPanel!, intersects[0]);

        let isObstacleRaycasted = this.isObstacleRaycasted(intersects);

        if (!isValidPlacement || isObstacleRaycasted) {
            this.isSinglePanelRotated = true;
            if (roofPlane)
                this.createOrUpdateTemporaryPanel(roofPlane, intersects[0].point);
            let isValidPlacement = this.isPanelPlacementValid(this.temporaryTransparentPanel!, intersects[0]);
            let isObstacleRaycasted = this.isObstacleRaycasted(intersects);
            if (!isValidPlacement || isObstacleRaycasted) {
                this.isSinglePanelRotated = false;
                this.invalidSinglePanel = true;
                if (roofPlane)
                    this.createOrUpdateTemporaryPanel(roofPlane, intersects[0].point);
                if (this.temporaryTransparentPanel){
                    (this.temporaryTransparentPanel.material as MeshBasicMaterial).map = this.panelRedTexture.clone();
                }
            } else {
                this.invalidSinglePanel = false;
            }
        }

    }

    isObstacleRaycasted(intersects: Intersection[]) {
        return intersects.find(x => x.object.name.split('-').shift() === meshTypeEnum.ObstacleExtrudeCap
            || x.object.name.split('-').shift() == meshTypeEnum.obstacleRootGroup
            || x.object.name.split('-').shift() == meshTypeEnum.rectangleObstacle
            || x.object.name.split('-').shift() == meshTypeEnum.circleObstacle
            || x.object.name.split('-').shift() == meshTypeEnum.walkwaysObstacle);
    }

    updateRoofsSetbackVisbility(intersects: Intersection[], allRoofSections: RoofSection[]) {
        const roofPlane = intersects.find((intersect) => intersect.object.name === meshTypeEnum.roofPlane);
        if (roofPlane) {
            const selectedRoof = roofPlane.object.parent;
            if (selectedRoof) {
                const roofSetbackMesh = selectedRoof.getObjectByName(meshTypeEnum.roofSetbackMesh);
                if (roofSetbackMesh) roofSetbackMesh.visible = true;
            }
        } else {
            allRoofSections.forEach((roofSection) => {
                const roof = this.engServ.scene.getObjectByName(roofSection.id);
                if (!roof) return;
                const roofSetbackMesh = roof.getObjectByName(meshTypeEnum.roofSetbackMesh);
                if (roofSetbackMesh) roofSetbackMesh.visible = roofSection.setbackVisible;
            });
        }
    }

    isSetbacksRaycasted(intersects: Intersection[]) {
        return intersects.find(x => x.object.name.split('-').shift() == meshTypeEnum.roofSetbackMesh
            || x.object.name.split('-').shift() == meshTypeEnum.obstacleSetbackMesh);
    }

    ifRoofHasPanels(selectedRoofId: string) {
        let result = false;
        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(selectedRoofId))
            .pipe(take(1))
            .subscribe(roofPanelArrays => {
                result = roofPanelArrays.length > 0;
            })
        return result;
    }

    checkIfDrawPanelsInSelectedArea(roofId: string, globalToLocal: Matrix4, polygonPoints: Vector3[], panelArrayId: string) {
        let result = false;
        let polygonPoints2D = Util.convertPolygonPointsTo2D(polygonPoints, globalToLocal);
        this.store.select(fromPanelArraySelectors.selectPanelArraysByRoofId(roofId))
            .pipe(take(1))
            .subscribe(roofPanelArrays => {
                roofPanelArrays.forEach(r => {
                    if (r.id === panelArrayId) return;
                    let vertices = this.GetPanelArrayBoundaryVertices(r)
                    let existingPanels2D = Util.convertPolygonPointsTo2D(vertices, globalToLocal);
                    existingPanels2D.forEach(ev => {
                        if (CollisionUtil.isPointInPolygon(polygonPoints2D, ev)) {
                            result = true;
                            return;
                        };
                    })
                    if (!result) {
                        const edgeIntersects = polygonPoints2D.some((pPt, i) => {
                            if (i < polygonPoints2D.length - 1) {
                                const panelEdgePts = [pPt, polygonPoints2D[i + 1]]

                                const intersects = existingPanels2D.some((kPt, k) => {
                                    if (k < existingPanels2D.length - 1) {
                                        const keepoutEdgePts = [kPt, existingPanels2D[k + 1]];
                                        const edgeIntPts = CollisionUtil.intersectLines(panelEdgePts, keepoutEdgePts);
                                        if (edgeIntPts) return true;
                                    }

                                    return false;
                                })
                                return intersects;
                            }
                            return false;
                        })
                        result = edgeIntersects == true;
                    }
                });
            })
        return result;
    }


    convertNonInstancedToInstanced(panelArray: Group){
      let panelArrayId = panelArray.name;
      let storePanelArray: PanelArray | undefined;
      this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId!])).pipe((take(1))).subscribe(arrays=>{
        if(!arrays || arrays.length <= 0) return;
        storePanelArray = arrays[0];
      });
      if(!storePanelArray) return;
      let panelUserDataArr : MeshUserData[]= [];
      let panelM = new MeshStandardMaterial({
        map: new Texture(),
        side: DoubleSide,
        transparent: true,
      });
      let roofPlane = this.engServ.scene.getObjectByName(storePanelArray.roofId) as Mesh;
      let panelGeom = new PlaneGeometry( Util.getMeasurementFeetToPixel(storePanelArray!.panelWidth),  Util.getMeasurementFeetToPixel(storePanelArray!.panelLength));
      let maxPossiblePanels = this.calculateMaxPossiblePanels(panelGeom, Util.calculateArea(roofPlane), storePanelArray.panelTilt);

      let instancedMesh = new InstancedMesh(panelGeom, panelM, maxPossiblePanels); //Making count sufficiently large to accomodate single panels
      InstancedMeshUtil.init(instancedMesh);
      InstancedMeshUtil.setPanelShader(instancedMesh);
      instancedMesh.name = meshTypeEnum.panelArray;
      (instancedMesh.userData as MeshUserData).meshtype = meshTypeEnum.instancedMesh;
      (instancedMesh.userData as MeshUserData).maxInstanceCount = instancedMesh.count;

      (panelArray.children[0].children as Mesh[]).forEach((pM: Mesh, i:number)=>{
        // pM.position.setZ(0);
        let panelNormal = Util.getMeshNormal(pM);
        if(panelNormal.z<0)panelNormal.negate();
        let panelVertices = Util.getMeshVertices(pM, true);
        let panelAzim =  new Vector3().copy(panelVertices[0]).sub(panelVertices[1]).normalize();
        let panelCenter = Util.getCenter(pM);
        let q1 = new Quaternion().setFromUnitVectors(new Vector3(0,0,1), panelNormal);
        let q2 = new Quaternion().setFromUnitVectors(new Vector3(1,0,0).applyQuaternion(q1), panelAzim);


        let tempObj = new Mesh();
        if(storePanelArray?.orientation == PanelOrientation.landscape){
          tempObj.rotateOnAxis(new Vector3(0,0,1), Math.PI/2);
        }
        tempObj.applyQuaternion(q1);
        tempObj.applyQuaternion(q2);
        tempObj.position.add(panelCenter);
        tempObj.updateMatrix();
        tempObj.updateMatrixWorld();

        instancedMesh.setMatrixAt( i, tempObj.matrixWorld as Matrix4);
        instancedMesh.setColorAt( i, new Color(0xffffff));
        InstancedMeshUtil.setVisibilityAt(instancedMesh, i,pM.visible );
        let pU  = {
          matrix: tempObj.matrix,
          instanceId: i,
          enactStoreId: pM.name,
          visible: pM.visible,
          azimuth: pM.userData.azimuth,
          frameId: pM.userData.frameId,
          // ori
        }
        panelUserDataArr.push(pU as any);
      });
      instancedMesh.count = storePanelArray.totalAddedPanels;
      instancedMesh.renderOrder = renderOrders.panelArray;
      panelArray.clear();
      panelArray.add(instancedMesh);
      this.addInstacneIdsToOldPanelsStore(panelUserDataArr);
    }

    addInstacneIdsToOldPanelsStore(panelUserDataArr: MeshUserData[]) {
      let panelIds = panelUserDataArr.map((p) => p.enactStoreId!);
      this.store
        .select(fromPanelSelectors.selectPanelsByIds(panelIds))
        .pipe(take(1))
        .subscribe((storePanels) => {
          let newstorePanels: Panel[] = JSON.parse(JSON.stringify(storePanels));

          for (let i = 0; i < storePanels.length; i++) {
            newstorePanels[i].instanceId = panelUserDataArr.find(
              (x) => x.enactStoreId == storePanels[i]!.id
            )?.instanceId!;
          }
          this.store.dispatch(
            fromPanelActions.UpsertMany({ panels: newstorePanels })
          );
        });
    }

    translateMsg(val: string, isToasterMsg = true) {
        let translatedName = '';
        let toasterPrefix = '';
        if(isToasterMsg){
          toasterPrefix = 'toastrMsgs.';
        }

        this.translate.get( toasterPrefix + val).pipe(take(1)).subscribe(res => {
          translatedName = res;
        }).unsubscribe();
        return translatedName;
    }
}

