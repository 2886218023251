import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Utils } from 'src/app/helpers/utils';
import { ArchiveListResponse, toArchiveList } from 'src/app/models/archive-costing-profile.model';
import { Status } from 'src/app/models/simple.types';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../helpers/auth.service';
import { ServicesOfferedService } from '@api/services/services-offered.service';
import { PanelConfigurationService } from '@api/services/panel-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  onBatteryDetailView$ = new Subject<any>(); // Design page :: view detail :: more info about battery
  onPanelDetailView$ = new Subject<any>(); // Design page :: view detail :: more info about panel
  onInverterDetsilView$ = new Subject<any>(); // Design page :: view detail :: more info about inverter
  servicesOffered: any[] = [];
  constructor(
    private http: HttpClient, 
    private authService: AuthService,
    private servicesOfferedService: ServicesOfferedService,
    private panelConfigurationService: PanelConfigurationService,
  ) { }

  getUserData() {
    return this.http.get(`${environment.newApiUrl}user?account_id=${this.authService.getItem('account_id')}&email_id=${this.authService.getItem('email_id')}`);
  }

  setServiceOffered(services: any[]){
    this.servicesOffered = services;
  }

  getParentUserData(accountId: string, emailId: string) {
    return this.http.get(`${environment.newApiUrl}user?account_id=${accountId}&email_id=${emailId}`);
  }

  getModules(): Observable<any> {
    // return this.http.get(`assets/mockdata/project_columns.json`);
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/module/selected`, { params });
  }

  getMonitoringSystems(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/monitoring/selected`, { params });
  }

  getInverters(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/inverter/selected`, { params });
  }

  fatchAllModules(reqBody: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}hardware/module/fetch`, reqBody);
  }
  createCustomModule(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/module/selected/create`, reqBody);
  }
  updateSelectModule(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}hardware/module/selected`, reqBody);

  }
  addIntoSelectModule(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/module/selected`, reqBody);
  }
  removeFromSelectModule(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };

    return this.http.delete(`${environment.newApiUrl}hardware/module/selected`, options);
  }

  getAllBatteries(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/battery/fetch`, reqBody)
  }
  addIntoBattery(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/battery/selected`, reqBody)
  }
  removeFromSelectedBattery(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}hardware/battery/selected`, options)
  }

  getAllInverters(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/inverter/fetch`, reqBody)
  }
  createCustomInverter(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/inverter/selected/create`, reqBody);
  }
  updateSelectInverter(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}hardware/inverter/selected`, reqBody);
  }
  addIntoSelectedInverter(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/inverter/selected`, reqBody);
  }
  removeFromSelectedInverter(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}hardware/inverter/selected`, options)
  }
  getAllRackings(reqBody: any) {

    return this.http.post(`${environment.newApiUrl}hardware/racking/fetch`, reqBody)

  }
  getAllMonitorings(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/monitoring/fetch`, reqBody)
  }
  createCustomeMonitoring(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/monitoring/selected/create`, reqBody)

  }
  updateSelectMonitoring(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}hardware/monitoring/selected`, reqBody);

  }
  addIntoSelectedMonitoring(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/monitoring/selected`, reqBody)
  }
  removeFromSelectedMonitoring(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}hardware/monitoring/selected`, options)
  }

  getBatteries(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/battery/selected`, { params });
  }
  createCustomBattery(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}hardware/battery/selected/create`, reqBody);
  }
  updateSelectBateery(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}hardware/battery/selected`, reqBody);

  }

  getRackings(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/racking/selected`, { params });
  }
  createCustomRacking(reqBody: any) {
    this.panelConfigurationService.clearCache(); /// clearing cache while creating new cache
    return this.http.post(`${environment.newApiUrl}hardware/racking/selected/create`, reqBody);

  }
  updateSelectRacking(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}hardware/racking/selected`, reqBody);
  }
  addIntoSelectedRacking(reqBody: any) {

    return this.http.post(`${environment.newApiUrl}hardware/racking/selected`, reqBody)
  }
  removeFromSelectedRacking(reqBody: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}hardware/racking/selected`, options)
  }
  uploadTemplate(formData: any): Observable<any> {
    return this.http.post(`${Utils.getBaseurl()}documents/saveTemplate`, formData);
  }

  deleteTemplate(val: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
      template_name: val
    };
    return this.http.get(`${Utils.getBaseurl()}services/deleteCustomTemplate`, { params });
  }

  setTemplate(val: any) {
    const body: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      customDefaultProposalTemplate: val
    };
    return this.http.put(`${Utils.getBaseurl()}tenants/${this.authService.getItem('account_id')}`, body);
  }


  saveTemplate() {
    const body: any = {
      parentEmail: this.authService.getItem('parent'),
      pdfType: "custom",
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
    };
    return this.http.put(`${Utils.getBaseurl()}services/saveDefaultCustomTemplate`, body);
  }
  getContracts(): Observable<any> {

    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
      page: "1",
      size: "",
      sort_col_name: "last_update",
      sort_direction: "dsc",
      "[category]": 'contract',
      "[filter]": 'one'
    };
    return this.http.post(`${environment.newApiUrl}document/fetch`, params);
  }
  uploadDocuments(formData: any): Observable<any> {
    const apiUrl = `${Utils.getBackendBaseURL()}documents`;
    return this.http.post(apiUrl, formData);
  }

  deleteDocuments(doc_id: any): Observable<any> {
    return this.http.delete(`${environment.newApiUrl}document/${doc_id}`);
  }
  downloadFile(fileUrl: string): Observable<any> {
    return this.http.get(`${fileUrl}`, { responseType: 'blob', headers: { Accept: 'application/octet-stream', 'x-aws-enact-key': '5A5C7D4736176IOP497347SUAY72656174XTZ' } });
  }

  getCustomTemplates(): Observable<{ default_template: string, template: { name: string, value: string }[] }> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
    };
    return this.http.get<{ default_template: string, template: { name: string, value: string }[] }>(`${environment.newApiUrl}custom-template?account_id=${params.account_id}&email_id=${params.email_id}&role=${params.role}`);
  }

  deleteTemplates(temp: any): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
    };
    return this.http.delete(`${environment.newApiUrl}custom-template?account_id=${params.account_id}&email_id=${params.email_id}&role=${params.role}&template_name=${encodeURIComponent(temp)}`);
  }

  getGlobalTags() {
    return this.http.get(`${environment.newApiUrl}custom-template-data`);
  }

  getAllLoans(): Observable<any> {

    return this.http.get(`${environment.newApiUrl}loan/all`);
  }

  getSelectedLoans(): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),

    };
    return this.http.get(`${environment.newApiUrl}loan?account_id=${params.account_id}&email_id=${params.email_id}`);

  }

  getPageWiseIncentiveProfiles(reqBody: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}rebate/fetch`, reqBody)
  }
  getAllideSofIncentive() {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem('role')

    };
    return this.http.get(`${environment.newApiUrl}rebate?account_id=${params.account_id}&email_id=${params.email_id}&role=${params.role}`)
  }

  deleteLoanFromSelected(reqBody: any): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}loan`, options);

  }

  addLoanToSelected(reqBody: any): Observable<any> {


    return this.http.post(`${environment.newApiUrl}loan`, reqBody);

  }

  fetchFinances(reqBody: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}loan/fetch`, reqBody);
  }

  createCustomFinance(reqBody: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}loan/create`, reqBody);

  }

  updateFinance(reqBody: any): Observable<any> {

    return this.http.put(`${environment.newApiUrl}loan`, reqBody);

  }

  updateIncentive(reqBody: any): Observable<any> {

    return this.http.put(`${environment.newApiUrl}rebate/` + reqBody.rebateId, reqBody);

  }

  createCustomIncentive(reqBody: any): Observable<any> {

    return this.http.post(`${environment.newApiUrl}rebate`, reqBody);

  }

  deleteRebateProfile(obj: any): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })

    };

    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem('role')
    };

    return this.http.delete(`${environment.newApiUrl}rebate/` + obj.rebateId + `?account_id=${params.account_id}&email_id=${params.email_id}&role=${params.role}`);

  }

  costProfileList() {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem("role"),
    };
    return this.http.get(`${environment.newApiUrl}pricing/list`, { params });
  }
  getCostingData(profileName: string): Observable<any> {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      Profile_name: profileName,
    };
    return this.http.get(`${environment.newApiUrl}pricing`, { params });
  }
  getRackingType() {
    const params = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.panelConfigurationService.getRackingType(params);
  }
  setDefaultCostProfile(profile: string) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
      variableCosting: [profile]
    };
    return this.http.put(`${environment.newApiUrl}service`, params);
  }
  deleteCostProfile(profile: string) {
    const reqBody: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      role: this.authService.getItem("role"),
      Profile_name: profile
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody
    };
    return this.http.delete(`${environment.newApiUrl}pricing`, options);
  }
  getServicesOffered(list: any) {
    const account_id = this.authService.getItem('account_id');
    const email_id = this.authService.getItem('email_id');
    const parent = this.authService.getItem('parent');
    return this.servicesOfferedService.getServicesOffered({account_id, email_id, parent}); 
  }
  getServicesOfferedByParent(list: any) {
    const account_id = this.authService.getItem('account_id');
    const email_id = this.authService.getItem('parent'); // to get the parent only
    const parent = this.authService.getItem('parent');
    return this.servicesOfferedService.getServicesOffered({account_id, email_id, parent}); 
  }

  getServicesOfferedByUser(list: any) {
    return new Observable((observer) => {
      let userCostingProfileWithParent: any[] = [];
      let parentCostingProfile: any[] = [];
      let userCostingProfile: any[] = [];
      this.getServicesOffered(list).subscribe((res: any) => {
        userCostingProfileWithParent = res[0];
        if (userCostingProfileWithParent.length > 1) {
          this.getServicesOfferedByParent(list).subscribe((resp: any) => {
            parentCostingProfile = resp[0];
            userCostingProfile = userCostingProfileWithParent.filter((item: any) => parentCostingProfile.indexOf(item) < 0);
            observer.next(userCostingProfile[0]);
            observer.complete();
          });
        }
        else{
          observer.next(userCostingProfileWithParent[0]);
          observer.complete();
        }
      });
    });

  }

  updateOAndMEditableDuringSales(payload: any) {
    return this.http.put(`${environment.newApiUrl}sale-price-costing-column`, payload);
  }

  updateHideCostTable(value: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      "key": "hideCostingSetupColumn",
      "value": value
    };
    return this.http.put(`${environment.newApiUrl}sale-price-costing-column`, params);
  }
  updateHideSalePriceBreakDown(value: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      "key": "hideSalePriceBreakdown",
      "value": value
    };
    return this.http.put(`${environment.newApiUrl}sale-price-costing-column`, params);
  }
  updateSalesTax(value: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      "salesTax": value
    };
    return this.http.put(`${environment.newApiUrl}sale-price-costing-column`, params);
  }
  updateHideBaseMargin(value: any) {
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      "key": "hideBaseMargin",
      "value": value
    };
    return this.http.put(`${environment.newApiUrl}sale-price-costing-column`, params);
  }
  updateCostProfile(params: any) {//
    return this.http.put(`${environment.newApiUrl}pricing`, params);
  }
  createCostProfile(params: any) {//
    return this.http.post(`${environment.newApiUrl}pricing`, params);
  }
  getSalePriceAndCostingColumn() {
    const account_id = this.authService.getItem('account_id');
    return this.http.get(`${environment.newApiUrl}sale-price-costing-column?account_id=${account_id}`);
  }
  getAvailableLocale() {
    return this.http.get(`${environment.newApiUrl}locale?localeType=ui`);
  }
  updateLocale(params: any) {
    return this.http.post(`${environment.newApiUrl}locale`, params);
  }
  updateUserRecords(params: any) {
    return this.http.put(`${environment.newApiUrl}user/records`, params);
  }
  updateDefaultTemplate(reqBody: any) {
    return this.http.put(`${environment.newApiUrl}custom-template/default`, reqBody);
  }

  downloadPdf(fileUrl: string): Observable<any> {
    return this.http.get(`${fileUrl}`, { responseType: 'blob', headers: { Accept: 'application/octet-stream' } });
  }

  uploadCustomTemplates(formData: any): Observable<any> {
    return this.http.post(`${Utils.getBaseurl()}documents/saveTemplate`, formData);

  }

  addService(reqid: any): Observable<any> {
    return this.http.get(`${environment.newApiUrl}request?request_id=${reqid}`);
  }

  // Get archive profile list
  getArchiveProfileList(): Observable<ArchiveListResponse> {
    const url = `${environment.newApiUrl}pricing/archive?account_id=${this.authService.getItem('account_id')}&role=${this.authService.getItem('role')}`;
    return this.http.get(url)
      .pipe(
        map(toArchiveList),
        catchError((error) => of({ data: [], status: Status.Error, error: error } as ArchiveListResponse))
      )
  }
  // Archive specific profile list
  archiveCostProfile(payload: any) {
    return this.http.put(`${environment.newApiUrl}pricing/archive`, payload);
  }
   // Restore specific profile list
   restoreCostProfile(payload: any) {
    return this.http.put(`${environment.newApiUrl}pricing/archive`, payload);
  }

  updateUserPrefferedLang(lang: any) {
    let query = '';
    query += 'email_id=' + this.authService.getItem('email_id')
    query += '&account_id=' + this.authService.getItem('account_id')
    query += '&preferredLanguage=' + lang
    return this.http.put(`${environment.newApiUrl}user/language?${query}`,{});
  }

  updateDefaultMapOpt(map: string) {
    const mapOptUpdateBody = {
      mapProvider: map,
      account_id: this.authService.getItem('account_id')
    }
    return this.http.post(`${environment.newApiUrl}account/update-map-provider`, mapOptUpdateBody);
  }

}
