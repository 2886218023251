import { Inverter } from "@design/models/inverter.model";
import { PanelArray } from "@design/models/panel-array.model";
import { SimulationResults } from "@design/models/simulation.model";
import { SystemResults } from "@design/models/system-results.model";
import { createAction, props } from "@ngrx/store";



export namespace fromSimulationActions {

  export const UpdateSystemSize = createAction(
    '[Simulation Results] update system size',
    props<{ panelArray: PanelArray }>()
  );

  export const UpdateSystemSizeSuccess = createAction(
    '[Simulation Results] Update system size success',
    props<{ simulationResults: SimulationResults }>()
  );

  export const UpdateSystemSizeSuccessOnReload = createAction(
    '[Simulation Results] Update system size success UpdateSystemSizeSuccessOnReload',
    props<{ simulationResults: SimulationResults }>()
  );


  export const UpdateSystemSizeFailure = createAction(
    '[Simulation Results] Update system size failed',
  );
  export const UpdateStorage = createAction(
    '[Simulation Results] update storage',
    props<{ storage: number }>()
  );
  export const UpdateEnergyAndOffset = createAction(
    '[Simulation Results] update energy and offset',
    props<{ data: any }>()
  );
  export const PVWattsHourlyFailure = createAction(
    '[Simulation Results] update energy and offset failure',
    props<{ error: any }>()
  );
  export const CallApiForBills = createAction(
    '[Simulation Results] get results from quoteMonthlyTiersPostSolar '
  );
  export const CallApiForBillSuccess = createAction(
    '[Simulation Results] bill Api success',
    props<{ data: any }>()
  );
  export const CallApiForBillSuccessOnReload = createAction(
    '[Simulation Results] bill Api success on reload'
  );
  export const CallApiForBillFailure = createAction(
    '[Simulation Results] bill  Api failure',
    props<{ error: any }>()
  );
  export const GetConsumptionData = createAction(
    '[Simulation Results] get data for consumption chart'

  );
  export const GetConsumptionDataSuccess = createAction(
    '[Simulation Results] get data for consumption chart success',
    props<{ data: any }>()

  );
  export const GetConsumptionDataFailure = createAction(
    '[Simulation Results] get data for consumption chart failure',
    props<{ error: any }>()

  );
  export const GetGenerationData = createAction(
    '[Simulation Results] get data for generation chart'

  );
  export const GetGenerationDataSuccess = createAction(
    '[Simulation Results] get data for generation chart success',
    props<{ data: any }>()

  );
  export const GetGenerationDataFailure = createAction(
    '[Simulation Results] get data for generation chart failure',
    props<{ error: any }>()

  );
  export const SaveSimulationData = createAction(
    '[Simulation Results] save data to quote'

  );


  export const setPvWattsRunning = createAction(
    '[Simulation Results] transition state for pvWatts',
    props<{ pvWattsRunning: boolean }>()

  );

  export const checkSystemSize = createAction(
    '[Simulation Results] transition state for pvWatts to check system size',
    props<{ systemResults: SimulationResults }>()

  );

  export const checkSystemSizeOnReload = createAction(
    '[Simulation Results] transition state for pvWatts to check system size checkSystemSizeOnReload',
    props<{ systemResults: SimulationResults }>()

  );
  export const SystemSizeSame = createAction(
    '[Simulation Results] system size is same'

  );
  export const PostSolarBillCallRunning = createAction(
    '[Simulation Results] post solar bill api running'

  );

  export const CaptureSiteImage = createAction(
    '[Save Site Image] captue site image'
  );

  export const SaveSiteImage = createAction(
    '[Save Site Image] save site image',
    props<{ imageData: any }>()
  );

  export const SaveSiteImageSuccess = createAction(
    '[Save Site Image] save site image success',
  );
  
  export const SaveSiteImageError = createAction(
    '[Save Site Image] save site image failed',
    props<{ error: any }>()
  );

  export const Capture3DIsometricImage = createAction(
    '[Save 3DIsometric Image] captue 3DIsometric image',
  );

  export const Save3DIsometricImage = createAction(
    '[Save 3DIsometric Image] save 3DIsometric image',
    props<{ imageData: any, imageType: string }>()
  );

  export const Save3DIsometricSuccess = createAction(
    '[Save 3DIsometric Image] save 3DIsometric image success',
    props<{ imageType: any }>()
  );

  export const Save3DIsometricImageError = createAction(
    '[Save3DIsometric Image] save 3DIsometric image failed',
    props<{ error: any }>()
  );
  
  export const SystemNotReadyYet = createAction(
    '[Pv Watts Call] panel array or inverters are not loaded yet or is 0',
  ); 
  export const setSimulationState = createAction(
    '[Simulation State] simulation states',
    props<{ simulationStatus: any }>()
  ); 
  export const InverterQuantityUpdated = createAction(
    '[Inverter Update] inverter quantity updated'
   
  ); 
  export const RetrySaving = createAction(
    '[Simulation State] retry Saving simulation data'
    
  ); 
  export const ResetEvent = createAction(
    '[Simulation State] resetting and saving simulation data'
    
  );

  export const DownloadSimulationURL = createAction(
    '[Simulate Results] download excel URL',
  );

  export const DownloadSimulationCompleted = createAction(
    '[Simulate Results] download excel data completed'
  );

  export const ClearSimulationData = createAction(
    '[Clear Simulation State] clearing simulation data'
    
  ); 

  export const DownloadDXF = createAction(
    '[DXF File] download DXF file'
  );

  export const InitialiseSimulation = createAction(
    '[Simulation State] start simulation'
    
  );

  export const UpdateSimulationOnPanelReload = createAction(
    '[Simulation State] Update Simulation On Panel Reload',
    props<{ data: any }>()
  );

  export const WaitForLeadData = createAction(
    '[Simulation State] wait for lead data'
    
  );

  export const CustomGeneration = createAction(
    '[Simulation Results] custom generation choosen'
  );

  export const ResetSolarRad = createAction(
    '[Simulation Results] reset solar radiation'
  );

  export const OptimumIrradiance = createAction(
    '[Simulation Results] hourly for optimum irradiance',
    props<{ optPanelSolrad: any }>()
  );

}
