import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../auth.service';

@Pipe({
  name: 'numberLocaleFormat'
})
export class NumberLocaleFormatPipe implements PipeTransform {

  locale = 'en-US';
  decFracUpto = { maximumFractionDigits: 1 };

  constructor(private authService: AuthService) {
    this.locale = this.authService.getItem('localeId');
  }
  transform(number: any, decimalFracValue = 0, minFracValue = 0): unknown {
    if (isNaN(number)) {
      return number;
    }
    let options = { maximumFractionDigits: decimalFracValue, minimumFractionDigits: minFracValue }
    number = number == undefined ? 0 : Number(number);
    if (this.authService.getItem('localeNumberFormate')) {
      this.locale = this.authService.getItem('localeNumberFormate');
    }
    this.locale = this.locale?.replace('_', '-');

    const formattedNumber = new Intl.NumberFormat(this.locale, options).format(number);
    const firstNumberIndex = formattedNumber.search(/[0-9]/);
    // Intl.NumberFormat is not accepting special character in currency

    let curr = formattedNumber.substring(0, firstNumberIndex).replace(/BDS|Bds/g, 'BDS$');
    const numberFormatWithSpace = curr + ' ' + formattedNumber.substring(firstNumberIndex, formattedNumber.length);
    return numberFormatWithSpace.trim();

  }

}
