import { NgModule } from '@angular/core';
import { QuoteService } from './services/quote.service';
import { LeadService } from './services/lead.service';
import { ServicesOfferedService } from './services/services-offered.service';
import { UserPreferenceService } from './services/user-preference.service';
import { PanelConfigurationService } from './services/panel-configuration.service';
import { ApiManagementService } from './services/api-management.service';
import { FeatureFlagService } from './services/feature-flags.service';

@NgModule({
  imports: [],
  providers: [
    QuoteService, 
    LeadService, 
    ServicesOfferedService, 
    UserPreferenceService,
    PanelConfigurationService,
    ApiManagementService,
    FeatureFlagService
  ],
})
export class ApiManagementModule {}
