import { APIResponse, Status } from "./api-response.model";
import { QuoteDetail } from "./quote-list.model";

export interface QuoteNoteUpdateAPIRequest {
  quoteId: string;
  revisionNumber: number;
  qrn: string;
  crn: string;
  note?: string;
  quoteNotes?: string;
}

export const toUpdateQuoteNote = (res: any): APIResponse<QuoteDetail> => ({
  data: res,
  status: Status.Success,
});
