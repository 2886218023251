import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  override = false;
  constructor() { }
  show(override = false): void {
    this.override = override;
    this.loaderSubject.next({ show: true } as LoaderState);
  }
  hide(): void {
    if (this.override == false) {
      this.loaderSubject.next({ show: false } as LoaderState);
    }
  }
}
export interface LoaderState {
  show: boolean;
}
