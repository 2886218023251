import { RoofSection } from '@design/models/roof-section.model';
import { createAction, props } from '@ngrx/store';

export namespace fromRoofSectionActions {
  export const RoofSectionInitialize = createAction(
    '[RoofSection] initialize data'
  );
  export const DrawRoofComplete = createAction('[RoofSection] initialize data');

  export const RoofSectionAdd = createAction(
    '[Draw Area] add roof section',
    props<{ roof: RoofSection,  }>()
  );

  export const AddManyRoofsAndAutoSave = createAction(
    '[Draw Area] add many roof section and trigger auto-save',
    props<{ roofs: RoofSection[] }>()
  );

  export const AddManyRoofs = createAction(
    '[Draw Area] add many roof section',
    props<{ roofs: RoofSection[] }>()
  );

  export const RoofSectionBoundChange = createAction(
    '[Draw Area] Update roof section bounds',
    props<{ roof: RoofSection }>()
  );

  //Updating an roof from scene, call this function
  export const UpdateRoof = createAction(
    '[Draw Area] update one Roof',
    props<{ roof: RoofSection }>()
  );

  export const UpdateRoofSetbackVisiblity = createAction(
    '[Draw Area] update one Roof Visiblity',
    props<{ roof: RoofSection }>()
  );


  export const RoofSectionConfigurationChangeTry = createAction(
    '[Roof Section Configuration] Try update roof section configuration',
    props<{ roof: RoofSection}>()
  );


  export const RoofSectionDelete = createAction(
    '[Roof Section Configuration] Delete roof section configuration',
    props<{ roof: RoofSection}>()
  );
  export const SaveRoofDesign = createAction(
    '[Roof Section Configuration] Save roof section configuration');



  export const SaveDesignFailure = createAction(
    '[Roof Section] save roof data to quote failure',
    props<{ error: any  }>()
  );

  export const DragEvent = createAction(
    '[Draw Area] Roof getting dragged',
    props<{ showDragPopup: boolean }>()
  );

  export const UpsertOne = createAction(
    '[Roof Section] upsert one Roofsection',
    props<{ roof: RoofSection }>()
  );
}
