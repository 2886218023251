import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { NovaDialogConfig } from '../interfaces/nova-dialog-config.interface';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NovaDialogComponent } from '../components/nova-dialog/nova-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(private modalService: NgbModal, private matDialog: MatDialog, private translate : TranslateService) { }
  public confirm(
    title: string,
    message: string,
    btnOkText: string = this.translateMsg('confirmDialogueBtns.Ok'),
    btnCancelText: string = this.translateMsg('buttons.cancel'),
    dialogSize: 'sm' | 'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmDialogComponent, { windowClass: 'confirm-class' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return modalRef.result;
  }

  public confirmObs(
    title: string,
    message: string,
    btnOkText: string = this.translateMsg('confirmDialogueBtns.Ok'),
    btnCancelText: string = this.translateMsg('buttons.cancel'),
    dialogSize: 'sm' | 'lg' = 'sm'): Observable<boolean> {
    const modalRef = this.modalService.open(ConfirmDialogComponent, { windowClass: 'confirm-class' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;

    return from(modalRef.result);
  }

  public novaDialog(data: NovaDialogConfig, matDialogConfig?: MatDialogConfig) {
    const configData: NovaDialogConfig = {
      actionButtons: [this.translateMsg('buttons.ok')],
      closeButtonLabel: 'Cancel',
      primaryButtonIndex: 0,
      iconText: '',
      isDeleteConfirmationDialog: false,
      ...data
    };

    matDialogConfig = {
      data: configData,
      ...matDialogConfig
    };

    const dialogReference = this.matDialog.open(NovaDialogComponent, matDialogConfig);
    return dialogReference;
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get(val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }
}


