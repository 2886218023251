import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { LeadInfo } from "@api/models/lead-info.model";
import { Observable, isObservable, of, throwError } from "rxjs";
import { shareReplay, catchError, finalize } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class LeadService implements OnDestroy{
  private cache = new Map<string, Observable<any>>();
  private ongoingRequests = new Map<string, Observable<any>>();
  private http = inject(HttpClient)

  getLeadInfo(crn: string,headers?: any ,options: {disableCache:boolean} = {disableCache:false}): Observable<LeadInfo> {
    const url = `${environment.newApiUrl}lead/${crn}`;
    const cacheKey =  url;
    const disableCache = options.disableCache || false;
    
    const cached = this.cache.get(cacheKey);
    if (!disableCache && cached && isObservable(cached)) {
      return cached as Observable<LeadInfo>;
    }

    // Check if the request is already ongoing
    const ongoing = this.ongoingRequests.get(cacheKey);
    if (ongoing && isObservable(ongoing)) {
      return this.ongoingRequests.get(cacheKey) as Observable<LeadInfo>;
    }
    
    const request = this.http.get<LeadInfo>(url, {headers}).pipe(
      shareReplay(1),
      catchError((error) => {
        this.ongoingRequests.delete(url);
        return throwError(error);
      }),
      finalize(() => {this.ongoingRequests.delete(cacheKey);})
    );

    this.ongoingRequests.set(cacheKey, request);
    request.subscribe(response => {
      this.cache.set(cacheKey, of(response));
    });

    return request; 
  }

  clearCache(){
    this.cache.clear();
  }

  ngOnDestroy(){
    this.clearCache();
  }

}