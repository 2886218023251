import { MatDialog } from '@angular/material/dialog';
import { EnactConfirmationBoxComponent } from '@lib-ui/public-api';
import { take } from 'rxjs/operators';

export function openDialog(dialog: MatDialog, data: any, width: string = '512px', height: string = 'auto') {
    let showYesBtn = (data['showYesBtn'] === undefined || data['showYesBtn'] === null) ? true : data['showYesBtn'];
    let showNoBtn  = (data['showNoBtn'] === undefined || data['showNoBtn'] === null) ? true : data['showNoBtn'];
    
    data = {
        ...data,
        showYesBtn,
        showNoBtn
    };
    const dialogRef = dialog.open(EnactConfirmationBoxComponent, {
        width,
        height,
        data,
        autoFocus: false,
        hasBackdrop: true,
        disableClose: true,
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => { });
    return dialogRef;
}

export function openModal(dialog: MatDialog, component: any, data: any, width: string = 'auto', height: string = 'auto') {
    const dialogRef = dialog.open(component, {
        width,
        height,
        data,
        autoFocus: false,
        hasBackdrop: true,
        disableClose: true,
        backdropClass: 'my-backdrop'
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => { });
    return dialogRef;
}