import { APIResponse, Status } from "./api-response.model";
import { Design } from "./design.model";
import { Finance } from "./finance.model";
import { Hardware } from "./hardware.model";
import { QuoteStatus } from "./quote.model";
import { SystemResults } from "./system-result.model";

export interface SingleQuote {
  _id: string;
  qrn: string;
  revisionNumber: number;
  quoteId: string;
  quoteType: string;
  accountId: string;
  quoteStatus: QuoteStatus;
  crn: string;
  utilityRateEscalator: string;
  note: string;
  cashPurchaseAmount: string;
  pvSysytemSizeDc: any;
  systemResults: SystemResults;
  hardware: Hardware;
  finance: Finance;
  design: Design;
  configuration: any;
  createdAt: number;
  updatedAt: number;
}

export const toSingleQuote = (res: any): APIResponse<SingleQuote> => ({
  data: !!res ? res : {} as SingleQuote,
  status: Status.Success,
});