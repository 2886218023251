import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/modules/shared/loader/loader.service';
import { EngageAdminService } from '../engage-admin.service';
import { AuthService } from 'src/app/helpers/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { DateAdapter } from '@angular/material/core';
import { Subscription, timer } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';

@Component({
  selector: 'app-engage-admin',
  templateUrl: './engage-admin.component.html',
  styleUrls: ['./engage-admin.component.scss']
})
export class EngageAdminComponent implements OnInit, OnDestroy {

  control = new UntypedFormControl()
  size = 1
  focus: boolean = false
  maxDate = new Date()
  adminDetail: any

  selectedIntegration: any = {}
  componentsArr: any = []
  setSelectedQuote: any = []
  selectedQuoteQrn: any
  projectForm: UntypedFormGroup | any;
  emailForm: UntypedFormGroup | any;
  submitted = false;
  emailSubmitted = false;
  userEmails: any = [];
  systemTypesAndComponents: any = [];
  userEmail: any;
  selectedInterationTypes = [];
  integrationSources = [];
  selectedQuotes: any = [];
  selectedQuote: any = {};
  crn: any;
  account_id!: string;
  parent_account!: string;
  userIdentityObj: any;
  identityObj: any;
  ca_numberFromApi: any;
  engageAdminData: any;
  adminSiteName: any;
  systemTypes: any = [];
  sysT = [];
  installersInfo = [{ id: 1 }];
  cols = [
    { field: 'email', header: 'Email ID' },
    { field: 'userType', header: 'User Type' },
    { field: 'action', header: 'Action' },
  ];
  otherCols = [
    { field: 'email', header: 'Email ID' },
    { field: 'action', header: 'Action' },
  ];
  userTypes = [];
  components: any = [];
  connectors: any = {};
  projectDetailModel: any = {
    integrationSources: [[]],
    integrationSource: [],
    fields: [],
  };
  integrationDetailModel: any = {};
  public myForm!: UntypedFormGroup;
  selectedSystemType = "select";
  selectedSource = "select";
  subscription: Subscription = new Subscription();

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };
  selectedCombination: any[] = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private engageAdminService: EngageAdminService,
    private _location: Location,
    public datepipe: DatePipe,
    public route: ActivatedRoute,
    public cookieService: CookieService,
    private toasterService: ToastrService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>
  ) {
   
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {

    // Date picker will pick locale specific date
    let localeId = 'en_US';
    if (this.authService.getItem('localeNumberFormate')) {
      localeId = this.authService.getItem('localeNumberFormate');
    }
    localeId = localeId.replace('_', '-');
    localeId = Utils.checkEuropeanLocale(localeId);
    this.dateAdapter.setLocale(localeId);

    this.crn = localStorage.getItem('crn') ? localStorage.getItem('crn') : null;
    // this.details();
    // this.selectQuote();
    this.getSiteDetails();
    this.projectForm = this.formBuilder.group({
      project: this.formBuilder.group({
        siteName: ['', Validators.required],
        accountId: this.authService.getItem('account_id'),
        crn: [''],
        siteStartDate: ['', Validators.required],
        systemType: ['', Validators.required],
        latitude: [
          '',
          [
            Validators.required,
            Validators.maxLength(32),
            Validators.pattern(/\-?\d*\.?\d{1,2}/),
          ],
        ],
        longitude: [
          '',
          [
            Validators.required,
            Validators.maxLength(32),
            Validators.pattern(/\-?\d*\.?\d{1,2}/),
          ],
        ],
        qrn: ['', Validators.required],
      }),
      users: [[]],
      installers: this.formBuilder.array([this.initInstallers('')]),
      features: [[]],
      action: [''],
    });

    this.subscription.add(this.engageAdminService.getConsumer(this.crn).subscribe((res) => {
      let quoteObj = {
        crn: res.data.crn,
        revisionNo: res.data.revision_number,
        quoteId: res.data.quote_id
      }
      this.account_id = res?.data?.account_id
      this.getUserIdentity();
      this.getConnectors();
      // this.getConfiguration(quoteObj);
    }))

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      userType: ['', Validators.required],
    });
  }

  change(value: any) {
    console.log(value)
    this.control.setValue(value)
  }
  onFocus(element: any) {
    this.focus = true;
    this.subscription.add(timer(0).subscribe(()=>{
      element.focus()
    }))
  }


  back() {
    this._location.back();
  }

  getSystemTypes() {
    this.systemTypes = []

    this.subscription.add(this.engageAdminService.getSystemTypes().subscribe(
      (data) => {
        if (data.response === null) {
          this.translate.get('toastrMsgs.sysTypeNotCOonfig').pipe(take(1)).subscribe((res: any) => {
            this.toasterService.warning(res);
          })
          this.getSiteDetail();
        } else {
          this.systemTypesAndComponents = data.response;
          this.systemTypesAndComponents.map((res: any) => {
            this.systemTypes.push(res.systemType);
          });
          this.systemTypes = [...this.systemTypes];
          this.getSiteDetail();
        }
      }, (error) => {
        this.getSiteDetail();
      }
    ));
  }

  getUserTypes() {
    this.subscription.add(this.engageAdminService.getUserTypes().subscribe((data: any) => {
      this.userTypes = data;
    }, (error) => { }
    ));
  }

  getUserIdentity() {
    this.subscription.add(this.engageAdminService
      .getUserIdentity(this.account_id, this.parent_account)
      .subscribe((data) => {
        this.identityObj = data;
        this.userIdentityObj = this.identityObj.fields;
        var obj: any = {};
        this.userIdentityObj.forEach((field: any) => {
          if (field.type === 'email')
            obj[field.name] = ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]];
          else
            obj[field.name] = ['', [Validators.required]];

        });
        this.emailForm = this.formBuilder.group(obj);
        if (this.identityObj.title == 'CA Number') {
          this.emailForm.controls['ca_number'].setValue(this.ca_numberFromApi);
        }
      }));
  }

  getSiteDetail() {

    let obj = [{
      account_id: "ENACTSYSTE2",
      cash_purchase_amount: "128356",
      create: "1639740710079",
      crn: "S3A-RMY-CIS",
      emailTemplateId: "NA",
      financingOptionA: "CED001",
      financingOptionB: "CED001",
      financingRequired: { compareToCashOnly: true, fundOptionA: true, fundOptionB: true },
      last_update: 1639740857771,
      optionABankName: "Clean Energy Distribution, 20y, 5.99% ",
      optionBBankName: "Clean Energy Distribution, 20y, 5.99% ",
      option_A_finance_amount: 128356,
      option_B_finance_amount: 128356,
      quote_id: "ENACTSYSTE200843",
      quote_status: "Completed",
      quote_type: "R",
      revision_number: "1",
      termA: "0",
      termB: "0",
      version: "v2",
      _id: { $oid: localStorage.getItem('qrn') }
    }]

    let data: any = {}
    data.features = [{ "label": "Share Document", "name": "Share Documnet", "selected": false }]
    data.features = JSON.stringify(data.features)
    data.features = JSON.parse(data.features)
    this.projectForm.get('features').setValue(data.features);


    let res: any = {}
    res.componentsPerSiteType = this.components;
    let componentArr = res.component?.map((res: any) => res.name);
    this.ca_numberFromApi = res.siteIdentification;
    if (this.identityObj && this.identityObj.title == 'CA Number') {
      this.emailForm.patchValue({ ca_number: this.ca_numberFromApi });
    }
    let components = this.getArrayMutations(componentArr);
    if (this.connectors !== undefined) {
      components.map((res: any, i: any) => {
        if (this.connectors.map[res.join(' ')] != undefined) {
          this.projectDetailModel.integrationSources[i] = this.connectors.map[res.join(' ')];
        }
      });
    }
    this.subscription.add(this.engageAdminService.getAdminDetails(this.crn).subscribe((data: any) => {
      if (data.sitePresent === true) {
        this.adminDetail = data ? data : null
        data.project.siteStartDate = new Date(data.project.siteStartDate);
        delete data.project.thresholdYield;
        this.getConnectors();
        this.projectForm.get('users').setValue(data.users);
        this.projectForm.get('features').setValue(data.features);
        this.projectForm.get('project').setValue(data.project);
        let getQuoteLabel = this.selectedQuotes.filter((x: any) => x._id.$oid == data.project.qrn)
        this.selectedQuoteQrn = data.project.qrn
        this.projectForm.get('project').patchValue({
          qrn: getQuoteLabel[0]?.label
        })
        this.projectForm.get('project').patchValue({
          systemType: data.systemType.label
        })
        let index = 0;
        if (this.systemTypes.length !== 0) {
          this.systemTypes.map((res: any, i: any) => {
            if (data.project.systemType !== null) {
              // @ts-ignore: Object is possibly 'null'.
              if (res.systemTypeId === data.project.systemType.systemTypeId) {
                index = i;
              }
            }
          });
        }
        this.components = this.systemTypesAndComponents[index].components;
        if (data.installers.length !== 0) {
          this.setInstallers(data.installers);
        }
      } else {
        this.projectForm.get('project').patchValue({
          crn: this.crn,
        });
        if (data.features === null) {
          data.features = [
            {
              archived: false,
              featureId: 1,
              label: 'Share Documents',
              name: 'shareDocs',
              selected: false,
            },
          ];
        }
        this.projectForm.get('features').setValue(data.features);
      }
      this.subscription.add(this.engageAdminService.getSiteDetail(this.crn).subscribe(
        (sitedata: any) => {
        },
        (error) => { }
      ));
    },
      (error) => {


      }
    ));
  }

  setInstallers(data: any) {
    if (this.connectors != '{}') {
      let frmArray = this.projectForm.get('installers') as UntypedFormArray;
      frmArray.clear();
      frmArray.push(this.initInstallers(''));
      data.map((res: any, i: any) => {
        if (i > 0) {
          frmArray.push(this.initInstallers(''));
        }
        res.selectedComponentIds = res.component.map((com: any) => com.componentId);
        res.componentsPerSiteType = this.components;
        let componentArr = res.component.map((res: any) => res.name);
        this.ca_numberFromApi = res.siteIdentification;
        if (this.identityObj && this.identityObj.title == 'CA Number') {
          this.emailForm.patchValue({ ca_number: this.ca_numberFromApi });
        }
        let components = this.getArrayMutations(componentArr);
        if (this.connectors !== undefined) {
          components.map((res: any) => {
            if (this.connectors.map[res.join(' ')] != undefined) {
              this.projectDetailModel.integrationSources[i] = this.connectors.map[res.join(' ')];
            }
          });
        }
        this.projectDetailModel.integrationSources[i].map((integration: any) => {
          if (
            res.integrationSource.componentOptionId === integration.componentOptionId
          ) {
            res.integrationSource = integration;
            this.projectDetailModel.fields[i] = integration.fields;
          }
        });
      });
      this.projectForm.get('installers').setValue(data);
      this.integrationSourceValidation();
      data.map((dt: any, i: number) => {
        let label = dt.integrationSource.label
        this.installers.at(i).patchValue({
          integrationSource: label
        })
      })

    }
  }

  setChecked(data: any, value: any) {
    return data.includes(value.componentId);
  }

  getConnectors() {
    this.subscription.add(this.engageAdminService.getConnectors().subscribe((data) => {
      this.connectors = data;
    }, (error) => { }
    ));
  }

  selectQuote() {
    let selectedQuote = this.selectedQuotes.find((x: any) => x.label === this.projectForm.value.project.qrn);
    this.selectedQuoteQrn = selectedQuote?._id?.$oid;
  }

 
  ValidateEmail(mail: any) {
    return (/^\w+([+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail)) ? true : false
  }

  addUsers(formDirective: FormGroupDirective) {
    this.emailSubmitted = true;
    let valid = this.ValidateEmail(this.emailForm.value.user_identity)
    if (this.emailForm.value.user_identity) {
      this.emailForm.controls.user_identity.errors = valid ? null : { email: !valid }
    }
    if (this.emailForm.value.user_type) {
      this.emailForm.controls.user_type.errors = this.emailForm.value.user_type !== null &&
        this.emailForm.value.user_type.trim() !== "" ? null : { required: true }
    }
    this.emailForm.status = valid && this.emailForm.value.user_type !== null &&
      this.emailForm.value.user_type.trim() !== '' ? 'VALID' : 'INVALID'

    if (this.emailForm.valid) {
      if (this.userIdentityObj[0].label == 'Email Id') {
        let emailExist = this.projectForm.value.users.find(
          (ob: any) => ob['userName'] === this.emailForm.value.user_identity
        );
        if (emailExist === undefined) {
          let userTypeObj: any = {};
          userTypeObj['archived'] = false;
          if (this.emailForm.value.user_type == 'customer') {
            userTypeObj['label'] = 'Customer';
            userTypeObj['name'] = 'customer';
            userTypeObj['userTypeId'] = 2;
          } else {
            userTypeObj['label'] = 'Investor';
            userTypeObj['name'] = 'investor';
            userTypeObj['userTypeId'] = 1;
          }

          let user = {
            userName: this.emailForm.value.user_identity,
            userType: userTypeObj,
            userId: null,
            firstName: null,
            lastName: null,
            phone: null,
            authUserId: null,
            archived: false,
            active: true,
            isNew: true,
          };

          this.projectForm.value.users.push(user);
          formDirective.resetForm();
        } else {
          this.toasterService.warning(this.translateMsg('emailAlreadyadded'))
        }
      } else if (this.userIdentityObj[0].label == 'CA Number') {
        let user = {
          userName: this.emailForm.value.ca_number,
          userType: null,
          userId: null,
          firstName: null,
          lastName: null,
          phone: null,
          authUserId: null,
          archived: false,
          active: true,
          isNew: true,
        };
        this.projectForm.value.users.push(user);
      } else {
        let user = {
          userName: this.emailForm.value.user_identity,
          userType: null,
          userId: null,
          firstName: null,
          lastName: null,
          phone: null,
          authUserId: null,
          archived: false,
          active: true,
          isNew: true,
        };
        this.projectForm.value.users.push(user);
        formDirective.resetForm();
      }


      this.emailSubmitted = false;
    } else {
      return;
    }
  }

  initInstallers(integrationSource: any) {
    return this.formBuilder.group({
      siteComponentDetailsId: [null],
      componentsPerSiteType: [[]],
      component: [[]],
      selectedComponentIds: [[]],
      integrationSource: ['', Validators.required],
      siteIdentification: [''],
      apiKey: [''],
      userIdentification: [''],
      archived: [false],
      password: [''],
    });
  }

  selectIntegrationSource(data: any, index: any) {
    if (this.componentsArr.length == 0) {
      this.componentsArr.push(this.projectForm.value.installers)
    }
    let integrationSources = this.projectDetailModel.integrationSources[index].filter((x: any) => x.label === this.installers.value[index].integrationSource)
    this.installers.value[index].integrationSource = integrationSources[0]
    let fields = data.filter(
      (res: any) =>
        res.label === this.installers.value[index].integrationSource.label
    );
    this.projectDetailModel.fields[index] = fields[0].fields;
    let frmArray: any = this.projectForm.get('installers') as UntypedFormArray;
    if (this.installers.value[index].integrationSource.label === 'Solar Edge') {
      frmArray
        .at(index)
      ['controls'].apiKey.setValidators([Validators.required]);
      frmArray
        .at(index)
      ['controls'].siteIdentification.setValidators([Validators.required]);
      frmArray.at(index)['controls'].userIdentification.setErrors(null);
      frmArray.at(index)['controls'].userIdentification.clearValidators();
    } else if (
      this.installers.value[index].integrationSource.label === 'Enphase'
    ) {
      frmArray
        .at(index)
      ['controls'].apiKey.setValidators([Validators.required]);
      frmArray
        .at(index)
      ['controls'].userIdentification.setValidators([Validators.required]);
      frmArray
        .at(index)
      ['controls'].siteIdentification.setValidators([Validators.required]);
    } else if (
      this.installers.value[index].integrationSource.label === 'Locus'
    ) {
      frmArray
        .at(index)
      ['controls'].siteIdentification.setValidators([Validators.required]);
      frmArray.at(index)['controls'].apiKey.setErrors(null);
      frmArray.at(index)['controls'].userIdentification.setErrors(null);
      frmArray.at(index)['controls'].apiKey.clearValidators();
      frmArray.at(index)['controls'].userIdentification.clearValidators();
    } else if (
      this.installers.value[index].integrationSource.label === 'Solarman'
    ) {
      frmArray
        .at(index)
      ['controls'].siteIdentification.setValidators([Validators.required]);
      frmArray.at(index)['controls'].apiKey.setErrors(null);
      frmArray.at(index)['controls'].userIdentification.setErrors(null);
      frmArray.at(index)['controls'].apiKey.clearValidators();
      frmArray.at(index)['controls'].userIdentification.clearValidators();
    } else if (
      this.installers.value[index].integrationSource.label === 'BRPL'
    ) {
      frmArray.at(index)['controls'].apiKey.setErrors(null);
      frmArray
        .at(index)
      ['controls'].siteIdentification.setValidators([Validators.required]);
      frmArray.at(index)['controls'].userIdentification.setErrors(null);
      frmArray.at(index)['controls'].apiKey.clearValidators();
      frmArray.at(index)['controls'].userIdentification.clearValidators();
    } else {
      frmArray.at(index)['controls'].apiKey.setErrors(null);
      frmArray.at(index)['controls'].siteIdentification.setErrors(null);
      frmArray.at(index)['controls'].userIdentification.setErrors(null);
      frmArray.at(index)['controls'].apiKey.clearValidators();
      frmArray.at(index)['controls'].siteIdentification.clearValidators();
      frmArray.at(index)['controls'].userIdentification.clearValidators();
    }
    this.projectForm.value.installers[index].integrationSource = JSON.stringify(this.projectForm.value.installers[index].integrationSource)
    this.projectForm.value.installers[index].integrationSource = JSON.parse(this.projectForm.value.installers[index].integrationSource)
    this.projectForm.value.installers[index].integrationSource = this.installers.value[index].integrationSource;
    this.selectedIntegration = this.installers.value[index].integrationSource
    this.componentsArr.push(this.installers.value[index].integrationSource)

    this.projectDetailModel.integrationSource[index] = this.installers.value[index].integrationSource;
    console.warn(this.projectForm.value.installers)
  }

  integrationSourceValidation() {
    let frmArray: any = this.projectForm.get('installers') as UntypedFormArray;
    this.projectForm.get('installers').value.map((installers: any, index: any) => {
      if (
        this.installers.value[index].integrationSource.label === 'Solar Edge'
      ) {
        frmArray
          .at(index)
        ['controls'].apiKey.setValidators([Validators.required]);
        frmArray
          .at(index)
        ['controls'].siteIdentification.setValidators([Validators.required]);
        frmArray.at(index)['controls'].userIdentification.setErrors(null);
        frmArray.at(index)['controls'].userIdentification.clearValidators();
      } else if (
        this.installers.value[index].integrationSource.label === 'Enphase'
      ) {
        frmArray
          .at(index)
        ['controls'].apiKey.setValidators([Validators.required]);
        frmArray
          .at(index)
        ['controls'].userIdentification.setValidators([Validators.required]);
        frmArray
          .at(index)
        ['controls'].siteIdentification.setValidators([Validators.required]);
      } else if (
        this.installers.value[index].integrationSource.label === 'Locus'
      ) {
        frmArray
          .at(index)
        ['controls'].siteIdentification.setValidators([Validators.required]);
        frmArray.at(index)['controls'].apiKey.setErrors(null);
        frmArray.at(index)['controls'].userIdentification.setErrors(null);
        frmArray.at(index)['controls'].apiKey.clearValidators();
        frmArray.at(index)['controls'].userIdentification.clearValidators();
      } else if (
        this.installers.value[index].integrationSource.label === 'Solarman'
      ) {
        frmArray
          .at(index)
        ['controls'].siteIdentification.setValidators([Validators.required]);
        frmArray.at(index)['controls'].apiKey.setErrors(null);
        frmArray.at(index)['controls'].userIdentification.setErrors(null);
        frmArray.at(index)['controls'].apiKey.clearValidators();
        frmArray.at(index)['controls'].userIdentification.clearValidators();
      } else if (
        this.installers.value[index].integrationSource.label === 'BRPL'
      ) {
        frmArray.at(index)['controls'].apiKey.setErrors(null);
        frmArray
          .at(index)
        ['controls'].siteIdentification.setValidators([Validators.required]);
        frmArray.at(index)['controls'].userIdentification.setErrors(null);
        frmArray.at(index)['controls'].apiKey.clearValidators();
        frmArray.at(index)['controls'].userIdentification.clearValidators();
      } else {
        frmArray.at(index)['controls'].apiKey.setErrors(null);
        frmArray.at(index)['controls'].siteIdentification.setErrors(null);
        frmArray.at(index)['controls'].userIdentification.setErrors(null);
        frmArray.at(index)['controls'].apiKey.clearValidators();
        frmArray.at(index)['controls'].siteIdentification.clearValidators();
        frmArray.at(index)['controls'].userIdentification.clearValidators();
      }

      this.projectDetailModel.integrationSource[index] = this.installers.value[index].integrationSource;
    });
  }

  selectSystemType() {
    this.projectForm.value.installers.forEach((element: any, index: any) => {
      this.projectDetailModel.integrationSources[index] = [];
      this.projectDetailModel.integrationSource[index] = '';
    });
    let typeData = this.systemTypes.filter((x: any) => x.label === this.projectForm.value.project.systemType)

    this.setSelectedQuote = typeData
    this.projectForm.value.project.systemType = typeData[0]

    let index = this.systemTypes.indexOf(
      this.projectForm.get('project').value.systemType
    );
    this.components = this.systemTypesAndComponents[index].components;

    let frmArray: any = this.projectForm.get('installers') as UntypedFormArray;
    this.projectForm.get('installers').value.map((installer: any, i: any) => {
      if (installer.siteComponentDetailsId === null) {
        frmArray.at(i)['controls'].integrationSource.setErrors(null);
        frmArray.at(i)['controls'].integrationSource.clearValidators();
        frmArray.removeAt(i);
      } else {
        frmArray.at(i)['controls'].integrationSource.setErrors(null);
        frmArray.at(i)['controls'].integrationSource.clearValidators();
        (frmArray.at(i) as UntypedFormGroup).get('archived')?.patchValue('true');
      }
    });
    frmArray.push(this.initInstallers(''));
    this.components.map((res: any) => frmArray.value[
      this.projectForm.get('installers').value.length - 1
    ].componentsPerSiteType.push(res)
    );
    this.getConnectors();
  }

  removeUser(index: any) {
    if (this.projectForm.value.users[index].isNew === true) {
      this.projectForm.value.users.splice(index, 1);
    } else {
      this.projectForm.value.users[index].archived = true;
    }
  }

  removeIntegration(index: any) {
    let installers :any= this.projectForm.get('installers') as UntypedFormArray;
    if (installers.value[index].siteComponentDetailsId === null) {
      installers.at(index)['controls'].integrationSource.setErrors(null);
      installers.at(index)['controls'].integrationSource.clearValidators();
      this.installers.removeAt(index);
    } else {
      installers.at(index)['controls'].integrationSource.setErrors(null);
      installers.at(index)['controls'].integrationSource.clearValidators();
      (installers.at(index) as UntypedFormGroup).get('archived')?.patchValue('true');
    }
  }

  get installers(): UntypedFormArray {
    return this.projectForm?.get('installers') as UntypedFormArray;
  }

  get t() {
    return this.installers.get('options') as UntypedFormArray;
  }

  get users(): UntypedFormArray {
    return this.projectForm.get('users') as UntypedFormArray;
  }

  selectIntegrationFor(e: any, i: any) {

    // setTimeout(() => {
    //   const index = ["solar", "grid", "storage"]
    //   const temp = this.installers.controls[i].get('component')?.value?.map((m: any) => m.name)
    //   const indexedData = [] as any[]
    //   index.map(index => {
    //     if (temp.indexOf(index) > -1) {
    //       indexedData.push(index)
    //     }
    //   })
    //   if (this.selectedCombination.length == 0) {
    //     this.selectedCombination.push(indexedData && indexedData.length === 0 ? "" : indexedData && indexedData.length > 1 ? indexedData.join(' ') : indexedData[0])
    //   }
    //   else {
    //     this.selectedCombination[i] = indexedData && indexedData.length === 0 ? "" : indexedData && indexedData.length > 1 ? indexedData.join(' ') : indexedData[0]
    //   }

    // }, 1000);

    this.projectDetailModel.integrationSources[i] = [];
    this.projectDetailModel.fields[i] = [];
    this.projectDetailModel.integrationSource[i] = '';
    let installers = this.projectForm.get('installers') as UntypedFormArray;
    (installers.at(i) as UntypedFormGroup).get('integrationSource')?.patchValue('');
    if (e.target.checked) {
      this.connectors.components.map((res: any) => {
        if (res.name === e.target.value) {
          installers.value[i].component.push(res);
        }
      });
    } else {
      installers.value[i].component.forEach((item: any, index: any) => {
        if (item.name === e.target.value) {
          installers.value[i].component.splice(index, 1);
          return;
        }
      });
    }
    let componentArr = installers.value[i].component.map((res: any) => res.name);
    let components = this.getArrayMutations(componentArr);
    components.map((res: any) => {
      if (this.connectors.map[res.join(' ')] != undefined) {
        this.projectDetailModel.integrationSources[i] =
          this.connectors.map[res.join(' ')];
        this.projectDetailModel.fields[i] = [];
      }
    });
  }

  getArrayMutations(arr: any, perms: any = [], len = arr?.length) {
    if (len === 1) perms.push(arr.slice(0));

    for (let i = 0; i < len; i++) {
      this.getArrayMutations(arr, perms, len - 1);

      len % 2 // parity dependent adjacent elements swap
        ? ([arr[0], arr[len - 1]] = [arr[len - 1], arr[0]])
        : ([arr[i], arr[len - 1]] = [arr[len - 1], arr[i]]);
    }

    return perms;
  }

  selectFeatures(i: any) {
    this.projectForm.get('features').value[i].selected =
      !this.projectForm.get('features').value[i].selected;
  }

  addMore() {
    let control = this.projectForm.controls['installers'] as UntypedFormArray;
    control.push(this.initInstallers(''));
    this.components.map((res: any) =>
      control.value[control.value.length - 1].componentsPerSiteType.push(res)
    );
    this.installersInfo.push({ id: this.installersInfo.length + 1 });
  }

  get frm() {
    return this.projectForm.controls;
  }

  onFormSubmit() {
    // for (const iterator of this.projectForm.value.installers) {
    //   if (typeof (iterator.integrationSource) !== 'object') {
    //     let findIntegration = this.componentsArr.filter((x: any) => x?.label === iterator.integrationSource)
    //     if (findIntegration.length > 0) {
    //       iterator.integrationSource = findIntegration[0]
    //     }
    //   }
    // }

    if (this.projectForm.value.users.length === 0) {
      this.emailSubmitted = true;
    }
    this.submitted = true;
    let index = this.projectForm.value.installers.length - 1
    if (this.projectForm.invalid) {
      return;
    } else {
      if (typeof this.projectForm.get('project').value.qrn === 'object') {
        this.projectForm.get('project').value.qrn =
          this.projectForm.get('project').value.qrn._id.$oid;
      }
      this.projectForm.get('users').value.map((user: any) => {
        delete user.isNew;
      });

      // this.projectForm.value.installers[index].integrationSource = this.selectedIntegration

      this.projectForm.get('installers').value.map((res: any) => {
        delete res.integrationSource['accountId'];
        delete res.integrationSource['archived'];
        delete res.integrationSource['componentCombination'];
        delete res.integrationSource['label'];
        delete res.integrationSource['fields'];
        delete res.caNumber;
        delete res.componentsPerSiteType;
        delete res.selectedComponentIds;
      });
      this.projectForm.get('project').value.siteStartDate = new Date(this.projectForm.get('project').value.siteStartDate).toISOString();
    }
    this.projectForm.get('project').value.qrn = this.selectedQuoteQrn
    if (typeof this.projectForm.get('project').value.systemType !== 'object') {
      let systemType = this.systemTypes.filter((x: any) => x.label === this.projectForm.get('project').value.systemType)
      this.projectForm.get('project').value.systemType = systemType[0]
    }
    this.projectForm.get('project').value.systemType = this.setSelectedQuote[0] ? this.setSelectedQuote[0] : this.projectForm.get('project').value.systemType
    // if (this.projectForm.value.installers[index].integrationSource != {}) {
    //   this.projectForm.value.installers[index].integrationSource = { componentOptionId: this.selectedIntegration.componentOptionId }
    // }
    // this.projectForm.get('project').value.systemType.accountId=this.projectForm?.value?.project?.accountId

    let adminData = Object.assign({}, this.projectForm.value);
    // adminData.installers.forEach((a: any) => {
    //   this.adminDetail?.installers.forEach((md: any) => {
    //     if (a.siteComponentDetailsId == md.siteComponentDetailsId) {
    //       a.integrationSource = { componentOptionId: md.integrationSource.componentOptionId }
    //     }
    //   })

    // })

    adminData.installers.forEach((inst: any) => {
      let mapArr: any[] = [];
      inst.newArrVals = [];
      inst.newArrValName = ''
      let spaceAppend = '';
      const indexes = ["solar", "grid", "storage"]
      let nameFound = [];
      inst.component.forEach((comp: any, i: Number) => {
        if (!comp.newArrVals?.length) {
          mapArr.push(comp.name)
        }

      })
      indexes.forEach(idx => {
        nameFound = mapArr.filter((t: any) => t === idx)
        if (nameFound.length) {
          inst.newArrVals.push(idx)
        }
      })
      inst.newArrValName = inst.newArrVals.join(' ');
      if (inst.integrationSource?.componentOptionId)
        inst.integrationSource = inst.integrationSource;
      else
        inst.integrationSource = this.getComponentId(inst.newArrValName, inst.integrationSource)

    })

    // console.warn(adminData.installers)
    this.projectForm.value.installers.map((el: any) => {
      delete el.newArrValName;
      delete el.newArrVals;
    })
    this.projectForm.value.users.map((us: any) => {
      us.userName = us.userName.trim().toLowerCase();
    })

    this.subscription.add(this.engageAdminService.saveAdminData(this.projectForm.value).subscribe((data) => {
      this.translate.get('toastrMsgs.dataSubmitSuccess').pipe(take(1)).subscribe((res: any) => {
        this.toasterService.success(res);
      })
      // this.getSiteDetail();
      this.getConnectors();
      this.getSystemTypes();
    }, (error) => {
      this.translate.get('toastrMsgs.plzEnterAllRecords').pipe(take(1)).subscribe((res: any) => {
        this.toasterService.warning(res);
      })
    }
    ));
  }
  getComponentId(name: any, namefilter: string) {
    let compOptionIdObj = { componentOptionId: this.connectors.map[name]?.filter((el: any) => el.label === namefilter)[0].componentOptionId }
    if (!compOptionIdObj.componentOptionId)
      compOptionIdObj = { componentOptionId: this.connectors.map[name.split(' ').reverse().join(' ')]?.filter((el: any) => el.label === namefilter)[0].componentOptionId }
    return compOptionIdObj;
  }

 

  details() {
    let data: any;
    this.subscription.add(this.engageAdminService.engage_admin_details(data).subscribe((res: any) => {
    }));
  }

  getSiteDetails() {
    this.subscription.add(this.engageAdminService.engage_site_detail(this.crn).subscribe((res: any) => {
      this.engageAdminData = res.consumerObj
      this.projectForm.get('project').value.crn = this.engageAdminData.crn
      this.projectForm.get('project').value.qrn = this.engageAdminData._id.$oid
      // newly added for quotes============QUOTES============>
      this.selectedQuotes = res.quoteArray
      this.selectedQuotes.map((res: any) => {
        res.label = res.quote_id + '/' + res.revision_number + '/' + this.datepipe.transform(res.create, 'MM-dd-yyyy');
        if (res._id.$oid === this.projectForm.get('project').value.qrn) {
          this.projectForm.get('project').patchValue({ qrn: res, });
          this.selectQuote();
        }
      });
      this.getSystemTypes();

      // newly added quotes close=====end=====>
      this.projectForm.get('project').patchValue({
        siteName:
          this.engageAdminData.first_name +
          ' ' +
          this.engageAdminData.last_name,
        longitude: this.engageAdminData.longitude,
        latitude: this.engageAdminData.latitude,
      });
    }));
  }

  getSystemType() {
    this.subscription.add(this.engageAdminService.getSystemTypes().subscribe((res: any) => {
      console.log('system types===>', res);
    }));
  }

  getConfiguration(configObj: any) {
    this.subscription.add(this.engageAdminService.getQuoteDetails(configObj).subscribe((res: any) => {
      console.log('config====>', res);
    }));
  }
  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

  trimSpaces() {
    this.emailForm.get('user_identity').setValue(this.emailForm.controls.user_identity.value.trim())
  }

}
