<div class="modal-header-proposal">
  <button
  style="float: right;"
  mat-icon-button
  class="close-button"
  [mat-dialog-close]="true"
>
  <mat-icon>close</mat-icon>
</button>
  <h2 class="modal-title-proposal" style="margin-top: 10px !important;"> Upload Custom Template</h2>
</div>

<div mat-dialog-content>
  <form [formGroup]="uploadForm" class="upload-form">
    <div class="custom-file">
      <input type="file" class="custom-file-input" (change)="upload($event)">
     </div>
    <div>
      <mat-form-field appearance="outline" class="form-header" >
        <mat-label>{{'design.leftPanel.inverter.invDetails.descrp' | translate }}</mat-label>
      <textarea matInput formControlName="description"></textarea>
      <mat-error *ngIf="f.description.touched && f.description.invalid">
        <span *ngIf="f.description.errors?.required">Description is required.</span>
      </mat-error>
      </mat-form-field>
    </div>
    <div>
    
    <div *ngIf="ImageUpload" class="FileError">Please upload a document</div>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close mat-stroked-button style="border-color:#009fbd;padding: 5px;" color="primary" (click)="cancel()" type="submit">{{'buttons.cancel' | translate }}</button>
     <button  mat-button mat-raised-button color="primary" style="border-color:#009fbd;padding: 5px;" (click)="uploadDoc()" type="submit">Upload</button>
</div>

