import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromUiActions } from './ui.actions';
import { UiState } from './ui.state';

export const initialUiStoreState: UiState = {
  selectedConfigurationId: null,
  selectedObjectId: null,
  selectedToolId: null,
  selectedToolName:null,
  selectedToolDetails:null,
  shadingBtn:false,
  nextBtn:false,
  clearState: true,
  disableToolPanelsForCustomUpload: false
};

export const uiReducer: ActionReducer<UiState> = createReducer(
  initialUiStoreState,
  on(fromUiActions.SelectToolSuccess, (state, { id ,name}) => {
    return {
      ...state,
      selectedObjectId: null,
      selectedConfigurationId: null,
      selectedToolId: id,
      selectedToolName:name
    };
  }),
  on(fromUiActions.SelectToolDetails, (state, { toolDetails}) => {
    return {
      ...state,
      selectedObjectId: null,
      selectedConfigurationId: null,
      selectedToolId: toolDetails.id,
      selectedToolName:toolDetails.name,
      selectedToolDetails:toolDetails,
    };
  }),
  on(fromUiActions.SelectObjects, (state, { toolDetails}) => {
    return {
      ...state,
      // selectedObjectId: null,
      // selectedConfigurationId: null,
      // selectedToolId: null,
      // selectedToolName:toolDetails.name,
      selectedToolDetails:toolDetails,
    };
  }),
  on(
    fromUiActions.SelectDrawAreaObject,
    fromUiActions.SelectObject,
    (state, { systemDesignObject }) => {
      return {
        ...state,
        selectedObjectId: systemDesignObject?.id,
        selectedConfigurationId: systemDesignObject?.configurationId,
        selectedToolId: systemDesignObject?.selectConfigId ? systemDesignObject.configurationId: null,

      };
    }
  ),
  on(fromUiActions.ClearSelectionSuccess,(state)=>{
    return{
      ...state,
      selectedToolId:null,
      selectedObjectId:null,
      selectedConfigurationId:null,
      selectedToolName:null,
      selectedToolDetails:null,


    }
  }),
  on(fromUiActions.ClearSelectedToolsDetails,(state)=>{
    return{
      ...state,
      selectedToolDetails:null,
    }
  }),
  on(fromUiActions.ClearObjectsSelection,(state)=>{
    return{
      ...state,
      selectedToolId:null,
      selectedObjectId:null,
      selectedConfigurationId:null,
      selectedToolName:null,
      selectedToolDetails:null,


    }
  }),on(fromUiActions.setState,(state,{active})=>{
        return{
          ...state,
          shadingBtn:active,
          nextBtn:active
        }
      }),on(fromUiActions.updateClearState,(state,{clearState})=>{
        return{
          ...state,
          clearState
        }
      }) ,on(fromUiActions.setObjectId,(state,{id,selectedConfigurationId,toolId,name,toolDetails})=>{
        return{
          ...state,
          selectedObjectId : id,
          selectedConfigurationId:selectedConfigurationId,
          selectedToolId : toolId,
          selectedToolName :name,
          selectedToolDetails:toolDetails
        }
      }),
      on(fromUiActions.DisableToolPanelForNoCustomImage, (state, {disableFlag}) => {
        return {
            ...state,
            disableToolPanelsForCustomUpload: disableFlag
        }
      })
)
