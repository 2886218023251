import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-project-list-dialog',
  templateUrl: 'project-list-dialog-component.html',
  styleUrls: ['project-list-dialog-component.scss'],
})
export class ProjectListDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ProjectListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  closeModel(): void {
    this.dialogRef.close();
  }
}