<div *ngIf="!modalClosed" #graphmodel>
  <div class="upper-line">
     <label style="float: left;" for="Savings Analysis">{{'saveAnaylysis' | translate }}</label>
     <button type="button" class="close" aria-label="Close" (click)="closeModal('Cross click')">
        <span aria-hidden="true">×</span>
     </button>
  </div>
  <ul class="nav nav-tabs" id="myTab" #myTab role="tablist"  >
     <li *ngFor="let meter of metersColl; let idx = index" class="nav-item">
        <a class="nav-link" [ngClass]="{'active': idx == selectedMeter}" i18n id="nav-{{meter.id}}" data-toggle="tab"
         role="tab"  aria-selected="true" (click)="selectMeter(idx)">{{meter.name}}</a>
     </li>
  </ul>
  <div class="row">
    <div id="loadAnalysis"
      [ngStyle]="{'border-bottom': selectedGraph ==4 || selectedGraph ==5 || selectedGraph ==6 ? '3px solid #3faeef' : '3px solid #d6d6d6'}"
      style=" width:25%;text-align: center;flex: none;cursor:pointer;padding:0px;" (click)="setSelectedGraph(4)"
      *ngIf="storagePresent">
      <label class="enact-radio onhover"
        style="width: -webkit-fill-available;padding: 10px; margin-bottom:0px !important;">
        {{'design.toolBar.simulate.loadAnalysis' | translate }}
      </label>
    </div>
    <div id="kWhOffset" [style.width.%]="storagePresent ? 25 : 32"
      [ngStyle]="{'border-bottom': selectedGraph ==1 ? '3px solid #3faeef' : '3px solid #d6d6d6'}"
      style="flex: none;cursor:pointer;padding:0px;text-align: center;" (click)="setSelectedGraph(1)">
      <label class="enact-radio onhover"
        style="width: -webkit-fill-available;padding: 10px; margin-bottom:0px !important;display: inline-block;">
        {{'kwhOffsetVw' | translate }}
      </label>
    </div>
    <div id="kWhTiered" [style.width.%]="storagePresent ? 24 : 34"
      [ngStyle]="{'border-bottom': selectedGraph ==2 ? '3px solid #3faeef' : '3px solid #d6d6d6'}"
      style=" text-align: center; flex: none;cursor:pointer;padding:0px;" (click)="setSelectedGraph(2)">
      <label class="enact-radio onhover"
        style="width: -webkit-fill-available;padding: 10px; margin-bottom:0px !important;display: inline-block;">
        {{'kwhTierdVw' | translate }}
      </label>
    </div>
    <div id="billOffset" [style.width.%]="storagePresent ? 24 : 32"
      [ngStyle]="{'border-bottom': selectedGraph ==3 ? '3px solid #3faeef' : '3px solid #d6d6d6'}"
      style=" text-align: center; flex: none;cursor:pointer;padding:0px;" (click)="setSelectedGraph(3)">
      <label class="enact-radio onhover"
        style="width: -webkit-fill-available;padding: 10px; margin-bottom:0px !important;">
        {{'billOffsetVw' | translate }}
      </label>
    </div>
  </div>
  <div class="tab-content" id="nav-tab-graphContent">
    <div class="tab-pane" *ngFor="let meter of metersColl; let idx = index"  class="tab-pane fade " id="tab-{{meter.id}}" role="tabpanel"
        attr.aria-labelledby="nav-{{meter.id}}" [ngClass]="{'active show': idx == selectedMeter}" style="height: 100%;">
        <div class="graphWrapper" [hidden]="selectedGraph !== 1">
          <app-area-chart single='false' [data]='getFormattedData(meter.kwhOffsetObj)' [meterNumber]="meter.meterNumber" [selectedGraph]="selectedGraph" yLabel='kWh' header='Consumption vs Generation (kWh)' text='Generation Allocated: {{meter.kwhOffsetObj.percentOffset}}%'></app-area-chart>
        </div>
        <div  class="graphWrapper" [hidden]="selectedGraph !== 2">
          <app-stacked-chart [dataChanged]="false" projectDetails="false"[data]='formatGraphObj(meter.kwhTieredObj)' [parentElement]="graphmodel" [meterNumber]="meter.meterNumber" [selectedGraph]="2" yLabel='kWh' header='Pre and Post Solar Usage (kWh)' text='Generation Allocated: {{meter.kwhOffsetObj.percentOffset}}%'></app-stacked-chart>
        </div>
        <div [hidden]="selectedGraph !== 3" class="graphWrapper">
          <app-stacked-chart  [dataChanged]="false" projectDetails="false" [data]='formatGraphObj(meter.billOffsetObj)' [parentElement]="graphmodel" [meterNumber]="meter.meterNumber"  [selectedGraph]="3" yLabel='' header='Pre and Post Project Utility Bill ({{applyLocaleVar}})' text='Generation Allocated: {{meter.kwhOffsetObj.percentOffset}}%'></app-stacked-chart>
        </div>
        <div [hidden]="selectedGraph !== 4" id="d3ChartTypicalDay-{{meter.id}}" class="graphWrapper">
          <app-mixed-chart [data]='meter' [meterNumber]="meter.meterNumber" [parentElement]="graphmodel" yLabel='kW' header='' text='Generation Allocated: {{meter.kwhOffsetObj.percentOffset}}%'></app-mixed-chart>
        </div>
    </div>
  </div>
</div>