import { APIResponse, Status } from './api-response.model';
import { SystemResults } from './system-result.model';

export interface QuoteV1 {
  _id: string;
  qrn: string;
  revisionNumber: string;
  systemResults: SystemResults;
  quoteId: string;
  quoteType: string;
  utilityRateEscalator: string;
  hardware: { id: string };
  finance: { id: string };
  configuration: { id: string };
  design: { id: string };
  graphics: { id: string };
}

export type QuoteV1ApiResponse = APIResponse<QuoteV1>;

export const toQuoteV1 = (res: any): QuoteV1ApiResponse => ({
  data: res,
  status: Status.Success,
});
