import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserSessionObj } from './user-session';
import { Observable } from 'rxjs';
import { Utils } from 'src/app/helpers/utils';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  httpClientForAccount: HttpClient
  constructor(private router: Router, private http: HttpClient,
    private handler: HttpBackend) {
    this.httpClientForAccount = new HttpClient(this.handler);
  }

  authenticateUser(request: any): Observable<any> {
    const apiUrl = `${Utils.getBaseurl()}login`;
    const requestBody = {
      accountId: request.accountId,
      loginType: 'auth',
      userType: 'partner'
    };
    return this.http.post<UserSessionObj>(apiUrl, requestBody, {
      headers: {
        Authorization: 'Basic ' + btoa(`${request.emailId}:${request.password}`)
      }
    });
  }
  getAccessDetails(requestBody: any): Observable<any> {
    return this.http.get(`assets/mockdata/access_details.json`);
    // return this.http.get(`${environment.newApiUrl}access`,  {params: requestBody});
  }

  getAllAccounts(email?: any, cog_uName?: string, token?: string, isRubicon = false): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${token}`,
        'x-api-key': environment.newApiKey
      })
    };
    return isRubicon ?
      this.http.get(`${environment.newApiUrl}user/accounts?email_id=${email}&username=${cog_uName}`) :
      this.httpClientForAccount.get(`${environment.newApiUrl}user/accounts_v2?email_id=${email}&username=${cog_uName}`, httpOptions);
  }

  resetPassword(request: any): Observable<any> {

    const reqBody = {
      account_id: request.accountId,
      email_id: request.emailId,
      user_type: 'sp'
    };
    const apiUrl = `${environment.newApiUrl}utility/password`;
    return this.http.get(apiUrl, { params: reqBody });
  }
}
