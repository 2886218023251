import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { EnactIconModule } from '../../composite/enact-icon/enact-icon.module';
import { EnactNotifySbarComponent } from './enact-notify-sbar.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EnactNotifySbarComponent,],
  imports: [
    CommonModule,
    MatIconModule,
    EnactIconModule,
    FormsModule,
    TranslateModule
  ],
  exports: [
    EnactNotifySbarComponent,
    
  ]
})
export class EnactNotifySbarModule { }