import { PanelArray } from "@design/models/panel-array.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { ActionReducer, createReducer, on } from "@ngrx/store";
import { fromPanelArrayActions } from "./panel-array.action";
import { PanelArrayState } from "./panel-array.state";

export const adapter: EntityAdapter<PanelArray> =
  createEntityAdapter<PanelArray>();


  export const panelArrayInitialState: PanelArrayState = adapter.getInitialState();
  
  export const { selectAll: selectAllPanelArray } = adapter.getSelectors();

  export const panelArrayReducer: ActionReducer<PanelArrayState> =
  createReducer(
    panelArrayInitialState,
    on(fromPanelArrayActions.AddPanelArray, (state, data) => {
      return adapter.addOne(data.panelArray, state);
    }),
    on(fromPanelArrayActions.AddManyPanelArrays, (state, data) => {
      return adapter.addMany(data.panelArray, state);
    }),
    on(fromPanelArrayActions.UpsertMany, (state, { panelArray }) => {
      return adapter.upsertMany(panelArray, state);
    }),
    on(fromPanelArrayActions.UpsertManyModule, (state, { panelArray }) => {
      return adapter.upsertMany(panelArray, state);
    }),
    on(fromPanelArrayActions.SelectPanelArray,(state,{panelArray}) =>{
        return {...state , selectedPanelArrayId : panelArray.id}
    }),
    on(fromPanelArrayActions.PanelArrayDelete, (state, { panelArray }) => {
      return adapter.removeOne(panelArray.id, state)
    }),
    on(fromPanelArrayActions.PanelArrayConfigurationChangeSuccess,(state,{panelArray}) =>{
      return adapter.upsertOne(panelArray, state);
    }),on(fromPanelArrayActions.UpsertOne,(state,{panelArray}) =>{
      return adapter.upsertOne(panelArray, state);
    }),on(fromPanelArrayActions.DeleteAll,(state) =>{
      return adapter.removeAll(state);
    })
  );