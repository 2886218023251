//https://www.geeksforgeeks.org/lru-cache-implementation/
export class LRUCache {
  csize: number;
  dq: Array<any>;
  map: any;
  constructor(n: number, dq: Array<any> | null, map: any) {
    this.csize = n;
    if (dq && dq?.length) {
      this.dq = dq;
    } else {
      this.dq = [];
    }
    if(map && map.length){
      this.map = new Map(map);
    }else{
      this.map = new Map();
    }
  }

  refer(key: string) {
    let last;
    if (!this.map.has(key)) {
      if (this.dq.length === this.csize) {
        last = this.dq[this.dq.length - 1];
        this.dq.pop();
        this.map.delete(last);
        localStorage.setItem('heightCacheQueue', JSON.stringify(this.dq))
        localStorage.setItem('heightCacheMap', JSON.stringify(Array.from(this.map.entries())))
      }
    } else {
      this.dq.splice(this.dq.indexOf(key), 1);
    }

    this.dq.unshift(key);
    this.map.set(key, 0);
    localStorage.setItem('heightCacheQueue', JSON.stringify(this.dq))
    localStorage.setItem('heightCacheMap', JSON.stringify(Array.from(this.map.entries())))
    return last;
  }

  display() {
    console.log('recent used projects queue:', this.dq);
  }
}