<div style="width: 1440px; height: 750px; position: relative; background: white">
    <div style="left: 439.50px; top: 272.90px; position: absolute; text-align: right; color: black; font-size: 60px; font-family: Inter; font-weight: 600; line-height: 90px; word-wrap: break-word">401 - Unauthorized</div>
    <div style="left: 311px; top: 385px; position: absolute; text-align: right; color: #9CA8BE; font-size: 40px; font-family: Inter; font-weight: 535; line-height: 60px; word-wrap: break-word">You are not authorized to access this page</div>
    <div style="width: 112.31px; height: 127.39px; left: 119.43px; top: 551.72px; position: absolute; transform: rotate(-54.03deg); transform-origin: 0 0; opacity: 0.40">
      <div style="width: 35.88px; height: 34.75px; left: 74.97px; top: 54.42px; position: absolute; transform: rotate(-54.03deg); transform-origin: 0 0; background: #00A9C5"></div>
      <div style="width: 76.45px; height: 86.74px; left: 20.59px; top: -28.36px; position: absolute; transform: rotate(-54.03deg); transform-origin: 0 0; background: #00A9C5"></div>
      <div style="width: 91.52px; height: 67.24px; left: 58.51px; top: 16.77px; position: absolute; transform: rotate(-54.03deg); transform-origin: 0 0; background: #00A9C5"></div>
    </div>
    <div style="width: 106.51px; height: 120.81px; left: 1263.25px; top: 297.79px; position: absolute; transform: rotate(-173.61deg); transform-origin: 0 0; opacity: 0.30">
      <div style="width: 34.03px; height: 32.95px; left: 9.78px; top: -87.31px; position: absolute; transform: rotate(-173.61deg); transform-origin: 0 0; background: #FCD715"></div>
      <div style="width: 72.50px; height: 82.26px; left: -33.03px; top: -3.70px; position: absolute; transform: rotate(-173.61deg); transform-origin: 0 0; background: #FCD715"></div>
      <div style="width: 86.79px; height: 63.77px; left: -13.56px; top: -56.10px; position: absolute; transform: rotate(-173.61deg); transform-origin: 0 0; background: #FCD715"></div>
    </div>
    <img style="width: 121.06px; height: 53.73px; left: 36.01px; top: 29.23px; position: absolute" src="https://lh3.googleusercontent.com/d/1gfWCu4ARA1p9sbaoG1Mm4MtqRt7JPKhs=w1000?authuser=2"/>
  </div>