import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { IndexedDBService } from '@design/services/indexed-db.service';
import { Store } from '@ngrx/store';
import { DesignState } from '@design/store/design.reducer';
import { fromAdditionalToolsResultsSelectors } from '@design/store/additional-tools';
import { SaveStatus } from '@design/models/save-status.model';
import { catchError, map, startWith } from 'rxjs/operators';
import { fromUndoRedoActions } from '@design/store/undo-redo/undo-redo.action';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isFinanceFromProjectDetailsSub$ = new BehaviorSubject<boolean>(false);
  isActiveTabSub$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isActiveTab$ = this.isActiveTabSub$.asObservable();
  isFinanceFromProjectDetails$ = this.isFinanceFromProjectDetailsSub$.asObservable();
  enableDesignLoader = new BehaviorSubject<any>({})
  loaderStopped = '';
  numberOfCallsTriggered = 0;
  numberOfCallsCompleted = 0;
  isQuoteMonthlyCallComplete = false;
  defMapProvider = 'GOOGLEMAPS';
  saveSceneImg = false;
  private $userLoggedIn = new Subject<boolean>();
  designStorageState = DesignOnlyState.started;
   //idle state
   setUserLoggedIn(userLoggedIn: boolean) {
    this.$userLoggedIn.next(userLoggedIn);
  }
  getUserLoggedIn(): Observable<boolean> {
    return this.$userLoggedIn.asObservable();
  }

  idleState = 'Not started.';
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor(private cookieService: CookieService, private indexedDBService: IndexedDBService,
    private store: Store<DesignState>, private translate: TranslateService,  private http: HttpClient, private router: Router,
  ) { }
  getActiveTab(): Observable<number> {
    return this.isActiveTab$;
  }
  isFinanceFromProjectDetails(): Observable<boolean> {
    return this.isFinanceFromProjectDetails$;
  }
  setItem(keyName: string, value: any, storageType: string = 'localStorage'): void {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    if (storageType !== 'default') {
      if (storageType === 'localStorage') {
        localStorage.setItem(keyName, value);
      }
      if (storageType === 'sessionStorage') {
        sessionStorage.setItem(keyName, value);
      }
    } else {
      this.cookieService.set(keyName, value);
    }
  }
  getItem(
    keyName: string,
    storageType: string = 'localStorage',
    isObj: boolean = false
  ): any {
    let result: any;
    if (storageType !== 'default') {
      if (storageType === 'localStorage') {
        result = localStorage.getItem(keyName);
      }
      if (storageType === 'sessionStorage') {
        result = sessionStorage.getItem(keyName);
      }
    } else {
      result = this.cookieService.get(keyName);
    }
    if (isObj) {
      result = JSON.parse(result);
    }
    return result;
  }

  isLoggedIn(): boolean {
    let isLoggedIn = false;
    if (this.getItem('token','localStorage') && this.getItem('account_id','localStorage')) {
      isLoggedIn = true;
    }
    return isLoggedIn;
  }
  removeItem(keyName: string, storageType: string = 'default'): void {
    if (storageType !== 'default') {
      if (storageType === 'localStorage') {
        localStorage.removeItem(keyName);
      }
      if (storageType === 'sessionStorage') {
        sessionStorage.removeItem(keyName);
      }
    } else {
      this.cookieService.delete(keyName);
    }
  }
  removeAllItems(storageType: string = 'default'): void {
    if (storageType !== 'default') {
      if (storageType === 'localStorage') {
        localStorage.clear();
      }
      if (storageType === 'sessionStorage') {
        sessionStorage.clear();
      }
    } else {
      this.cookieService.deleteAll('/');
      this.cookieService.deleteAll('/projects');
    }
  }
  
  logout(storageType: string = 'default'): void {
    const lgurl: any = localStorage.getItem('redirectURL');
    this.removeAllItems(storageType);
    this.removeAllItems('localStorage');
    this.removeAllItems();
    this.cookieService.deleteAll();
    localStorage.setItem('redirectURL', lgurl); // window.location.origin
    if (lgurl) {
      localStorage.setItem('redirectURL', lgurl);
    }
    else {
      localStorage.setItem('redirectURL', window.location.origin);
    }
    const logOutLink = `${environment.authURL}logout?client_id=${environment.cognitoClientId}&logout_uri=${lgurl || window.location.origin}`;
    window.location.href = logOutLink;
  }
  

  setBaseAndDocUrl(): void {
    const docUrl = `${environment.newApiUrl}document/download?path=`;
    this.setItem('docUrl', docUrl);
  }
  setAllRequiredInfo(userSessionObj: any): void {
    const fields = [
      'fn',
      'ln',
      'tenant_id',
      'account_id',
      'sp_id',
      'sessionId',
      'user_type',
      'email_id',
      'user_zip',
      'resellerId',
      'roles',
      'parent',
      'role',
      'createTime',
      'account_role',
      'departmentKey',
      'localeId',
      'localeId_doc',
      'sampleDateFormat',
      'showTaskDashBoard',
      'defaultLanding',
    ];
    const fieldsSize = fields.length;
    const loggedinTime = new Date();
    this.setItem('loginTime', loggedinTime.getTime().toString());
    for (let j = 0; j < fieldsSize; j++) {
      this.setItem(fields[j], userSessionObj.session[fields[j]]);
    }
    const fieldsToCheckUndefined = [
      { field_name: 'localeId', field_value: 'en_US' },
      { field_name: 'sampleDateFormat', field_value: 'MM/dd/yyyy' },
      { field_name: 'defaultLanding', field_value: 'defaultLanding' },
    ];
    fieldsToCheckUndefined.forEach((fieldObj) => {
      if (this.getItem(fieldObj.field_name) === 'undefined') {
        this.setItem(fieldObj.field_name, fieldObj.field_value);
      }
    });
    if (userSessionObj.services_offered) {
      this.setItem('services_offered', userSessionObj.services_offered);
    }
    if (userSessionObj.parent) {
      this.setItem('parent', userSessionObj.parent);
    }
    this.setItem('mMeter', 'false');
  }

  public isSceneAndMapLoaded() {
    return combineLatest([
        this.store.select(fromAdditionalToolsResultsSelectors.mapLoadingStatus).pipe(startWith(SaveStatus.SAVED)),
        this.store.select(fromAdditionalToolsResultsSelectors.sceneRestoreStatus).pipe(startWith(SaveStatus.SAVED)),
    ]).pipe(
        map(([mapLoadingStatus, sceneRestoreStatus]) => {
            if (mapLoadingStatus == SaveStatus.SAVED && (sceneRestoreStatus == SaveStatus.SAVED || sceneRestoreStatus == SaveStatus.FAILED)) {
                console.log('Scene and Map are loaded!');
                this.store.dispatch(fromUndoRedoActions.ClearAllStacks());
                this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromUndoRedoActions.TriggerURSnapshot,notification: this.translateMsg('design.undoRedo.sceneAndMapLoaded') }));
                return true;
            } else {
              console.log(`Scene and Map are not loaded : mapLoadingStatus - ${mapLoadingStatus}, sceneRestoreStatus - ${sceneRestoreStatus}`);
                return false;
            }
        }),
        catchError((e) => {
            return of(false);
        })
    );
  }

  translateMsg(val: string, isToasterMsg = true) {
    let translatedName = '';
    let toasterPrefix = '';
    if(isToasterMsg){
      toasterPrefix = 'toastrMsgs.';
    }

    this.translate.get( toasterPrefix + val).subscribe(res => {
      translatedName = res;
    }).unsubscribe();
    return translatedName;
  }

 
  refreshToken(token: any): Observable<any> {
    return new Observable((observer) => {
      this.http
        .get(`${environment.authApiUrl}/csrf-token/`, {
          observe: 'response',
          withCredentials: true,
        })
        .subscribe(async (res: any) => {
          let headerProperty = await res.headers.get('CSRF-TOKEN');
          const httpOptions = {
            headers: new HttpHeaders({
              'X-CSRFToken': headerProperty,
            }),
            withCredentials: true,
          };
          this.http
            .post(
              `${environment.authApiUrl}/regenerate-token/`,
              { refresh_token: this.getItem('refreshToken') },
              httpOptions
            )
            .subscribe((resp: any) => {
              observer.next(resp);
              observer.complete();
            });
           
        });
    });
  }
  public collectFailedRequest(request: any): void {
    this.cachedRequests.push(request);
  }
  public retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
    this.cachedRequests.forEach((request) => {
      let payload = (request as any).payload;
      if (request.method == 'POST') {
        this.http.post(request.url, payload || '');
      } else if (request.method == 'PUT') {
        this.http.put(request.url, payload || '');
      } else if (request.method == 'DELETE') {
        this.http.delete(request.url, payload || '');
      } else if (request.method == 'GET') {
        this.http.get(request.url, payload || '');
      }
    });
    this.cachedRequests = [];
  }

  isDesignOnly(){
    let locValue = localStorage.getItem('design_only');
      if(this.isLoggedIn() && locValue == null){
        this.designStorageState = DesignOnlyState.manipulated;
        return undefined;
       }
      if(this.designStorageState == DesignOnlyState.in_progress){
        if(locValue == null ){
          this.designStorageState = DesignOnlyState.manipulated;
          return undefined;
        }
      }
      if(this.designStorageState == DesignOnlyState.started){
        if(locValue != null ){
          this.designStorageState = DesignOnlyState.manipulated;
        }
        return false;
      }
      
      
      if (locValue == 'qkshodgh'){
        return true;
      } else if (locValue == 'dshytocm'){
        return false;
      } else {
        this.designStorageState = DesignOnlyState.manipulated;
        return undefined
      }
  }

  moveToUnauthorized(){
    this.clearEverything();
    this.router.navigate(['/unauthorized']);
  }
  
  clearEverything(){
    this.removeAllItems('localStorage');
    this.removeAllItems('sessionStorage');
    this.removeAllItems();
    this.cookieService.deleteAll();
  }
}

export enum DesignOnlyState {
  manipulated,
  in_progress,
  started
}