import { NgModule } from '@angular/core';
import { EnactSlideToggleComponent } from './atomic/enact-slide-toggle/enact-slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EnactSlideToggleComponent],
  imports: [
    MatSlideToggleModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [EnactSlideToggleComponent],
})
export class LibUISharedModule { }