import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/helpers/auth.service';
import { Utils } from 'src/app/helpers/utils';
import { ProjectsService } from 'src/app/modules/projects/projects.service';
import { SystemDesignService } from 'src/app/modules/system-design/system-design.service';
@Component({
  selector: 'app-configure-modal',
  templateUrl: './configure-modal.component.html',
  styleUrls: ['./configure-modal.component.scss'],
})
export class ConfigureModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfigureModalComponent>,
    public router: Router,
  ) {}

  ngOnInit(): void {}
  proposalScreen() {
    this.dialogRef.close();
    this.router.navigate(['/proposal']);
  }
  costingScreen() {
    this.dialogRef.close();
    this.router.navigate(['/costing']);
  }
}
