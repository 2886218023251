import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { EnactIconModule } from '../../composite/enact-icon/enact-icon.module';
import { FormsModule } from '@angular/forms';
import { EnactTimePickerComponent } from './enact-time-picker.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [EnactTimePickerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    EnactIconModule,
    FormsModule,
    MatSelectModule,
    MatCheckboxModule, 
    TranslateModule
  ],
  exports: [
    EnactTimePickerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EnactTimePickerModule { }