import { Status } from "./simple.types";

export interface CostingProfile {
  account_id: string;
  update: Date;
  archived_on: Date;
  create: Date;
  Profile_name: string;
}
export interface ArchiveListResponse {
  data: CostingProfile[];
  status: Status;
  error?: string;
}

export const toArchiveList = (res: any): ArchiveListResponse => ({
  data: !!res?.length ? res : [],
  status: Status.Success
})

export const ARCHIVE_PROFILE_HEADER = [
  { id: 'Profile_name', name: 'Profile name' }, { id: 'create', name: 'Created on' },
  { id: 'archived_on', name: 'Archived on' }, { id: 'action', name: 'Action' }];
