import { Edge } from "./edge.model";
import { Frame } from "./frame.model";
import { Panel, PanelOrientation } from "./panel.model";
import { SystemDesignObject } from "./system-design-object.abstract.model";
import { ConfigurationIds } from "./ui-state.model";
import { Vertex } from "./vertex.model";

export enum ArrayAlignment{
  left="left",
  right = "right",
  justify = "justify", // change this to justify
  center = "center", // change this to center
}

// export interface PanelArrayGroup {
//   id: string;
//   panels:number;
//   paneltype:string;
//   efficiency:number;
//   arrayTilt:number;
//   azimuth:number;
// }
interface IPanelArray {
    id: string;
    roofId:string;
    panelWidth:number;
    panelLength:number;
    panelTilt:number;
    roofTilt:number;
    shadingValue?:number;
    shadingType?:string;
    path:string;
    panelModel:string;
    panelManufacturer:string;
    rackingSystem:string;
    rackingManufacturer:string;
    showEdgeSetback?:boolean;
    azimuth:number
    defaultInterrowspacing?:number;
    interrowspacing?:number;
    paneltype:string;
    degradation:number;
    panelSize:string;
    totalWatts:number;
    wattsPerModule:number;
    panelIds:Array<Panel['id']>;
    edges?:Array<Edge['id']>;
    vertices?:Array<Vertex['id']>;
    name:string;
    alignment:ArrayAlignment;
    orientation:PanelOrientation;
    numberOfPanels:number;
    totalAddedPanels:number;
    setback?:number;
    arrayType:string;
    isDrawToFill?: boolean;
    arrayTypeName:string;
    unitCost:string;
    moduleType:string;

    frameIds:Array<Frame['id']>;
    noOfRows: number;
    noOfColumns: number;
    frameSpace: number;
    frameHeight: number;
    frameHeightTall: number;
    clampSpace: number;
    peakSpace: number;
    azimuth2?: number;
    noOfPanelEast: number;
    noOfPanelWest?: number;
}

export class PanelArray extends SystemDesignObject implements IPanelArray {
    selectConfigId?: boolean | undefined;
    id: string;
    roofId:string;
    panelWidth:number;
    panelLength:number;
    panelTilt:number;
    roofTilt:number;
    totalTilt?:number;
    shadingValue?:number;
    shadingType?:string;
    path:string;
    panelModel:string;
    panelManufacturer:string;
    rackingSystem:string;
    rackingManufacturer:string;
    showEdgeSetback?:boolean;
    azimuth:number
    defaultInterrowspacing?:number;
    interrowspacing?:number;
    gapY?:number;
    paneltype:string;
    degradation:number;
    panelSize:string;
    totalWatts:number;
    wattsPerModule:number;
    panelIds:Array<Panel['id']>;
    edges?:Array<Edge['id']>;
    vertices?:Array<Vertex['id']>;
    name:string;
    alignment:ArrayAlignment;
    orientation:PanelOrientation;
    numberOfPanels:number;
    totalAddedPanels:number;
    setback?:number;
    arrayType:string;
    isDrawToFill?: boolean = false;
    arrayTypeName:string;
    unitCost:string;
    moduleType:string;
    configurationId = ConfigurationIds.PanelArray;
    frameIds: Array<Frame['id']>;
    noOfRows: number;
    noOfColumns: number;
    frameSpace: number;
    frameHeight: number;
    frameHeightTall: number;
    clampSpace: number;
    peakSpace: number;
    azimuth2?: number;
    noOfPanelEast: number;
    noOfPanelWest?: number;


  constructor(data: Omit<PanelArray, 'configurationId'>) {
    super();
    this.id = data.id;
    this.roofId = data.roofId;
    this.panelWidth =data.panelWidth;
    this.panelLength = data.panelLength;
    this.panelTilt = data.panelTilt;
    this.panelManufacturer = data.panelManufacturer;
    this.panelModel = data.panelModel;
    this.azimuth = data.azimuth;
    this.defaultInterrowspacing = data.defaultInterrowspacing;
    this.interrowspacing = data.interrowspacing;
    this.paneltype = data.paneltype;
    this.edges = data.edges ?? [];
    this.panelIds = data.panelIds ?? [];
    this.vertices = data.vertices ?? [];
    this.degradation = data.degradation;
    this.panelSize = data.panelSize;
    this.totalWatts = data.totalWatts;
    this.name = data.name;
    this.alignment= data.alignment;
    this.orientation = data.orientation;
    this.numberOfPanels = data.numberOfPanels;
    this.totalAddedPanels = data.totalAddedPanels;
    this.roofTilt = data.roofTilt;
    this.shadingValue = data.shadingValue;
    this.shadingType = data.shadingType;
    this.path = data.path;
    this.rackingManufacturer = data.rackingManufacturer;
    this.rackingSystem = data.rackingSystem;
    this.showEdgeSetback = data.showEdgeSetback;
    this.wattsPerModule = data.wattsPerModule;
    this.setback = data.setback;
    this.arrayType = data.arrayType??0;
    this.arrayTypeName = data.arrayTypeName;
    this.unitCost = data.unitCost;
    this.moduleType = data.moduleType ?? 0;
    this.isDrawToFill = data.isDrawToFill;
    this.frameIds = data.frameIds;
    this.noOfRows = data.noOfRows ?? 1;
    this.noOfColumns = data.noOfColumns ?? 1;
    this.frameSpace = data.frameSpace ?? 0;
    this.frameHeight = data.frameHeight ?? 0;
    this.frameHeightTall = data.frameHeightTall ?? 0;
    this.clampSpace = data.clampSpace ?? 0;
    this.peakSpace = data.peakSpace ?? 0;
    this.azimuth2 = data.azimuth2 ?? 0;
    this.noOfPanelEast = data.noOfPanelEast;
    this.noOfPanelWest = data.noOfPanelWest ?? 0;

  }

}
