<div class="add-scroll">
    <table mat-table mat-table [dataSource]=" dataSource" matSort class="width100 tb">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th class="hdr" mat-header-cell *matHeaderCellDef mat-sort-header> {{ column.header }} </th>
            <td mat-cell *matCellDef="let row"> {{column.cell(row)}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator [ngStyle]="{display: isPagination ? 'block' : 'none'}" class="mat-paginator-sticky" [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"
    showFirstLastButtons></mat-paginator>