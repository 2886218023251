import { ShadingResults, ShadingStatus } from "@design/models/shading.model";
import { EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { ShadingState } from "./shading.state";
import { fromShadingActions } from "./shading.action";
import { ActionReducer, createReducer, on } from "@ngrx/store";

export const adapter: EntityAdapter<ShadingResults> = createEntityAdapter<ShadingResults>();

export const shadingInitialState: ShadingState = adapter.getInitialState({
  shadingStatus: ShadingStatus.Outdated,
  defaultMinThreshold: 0,
  solarAccessThreshold: 0,
  allPanelsData: {},
  hoverPanelId: '',
  hoveredPanelData: {},
  panelsYearlyData: [],
  heatMapData: {},
  roofIrradianceList: [],
  isShadingEnabled: false
});

export const shadingReducer: ActionReducer<ShadingState> = createReducer(
  shadingInitialState,
  on(fromShadingActions.ChangeShadingStatus, (state, { shadingStatus }) => {
    return { ...state, shadingStatus: shadingStatus };
  }),
  on(fromShadingActions.ToggleShading, (state, { isShadingEnabled }) => {
    return { ...state, isShadingEnabled: isShadingEnabled };
  }),
  on(fromShadingActions.ChangeHeatMapData, (state, { heatMapData }) => {
    return { ...state, heatMapData: heatMapData };
  }),
  on(fromShadingActions.ChangeThreshold, (state, { solarAccessThreshold }) => {
    return { ...state, solarAccessThreshold: solarAccessThreshold };
  }),
  on(fromShadingActions.SetPanelsData, (state, { allPanelsData }) => {
    return { ...state, allPanelsData: allPanelsData };
  }),
  on(fromShadingActions.SetYearlyData, (state, { panelsYearlyData }) => {
    return { ...state, panelsYearlyData: panelsYearlyData };
  }),
  on(fromShadingActions.SetHoveredPanelId, (state, { hoverPanelId }) => {
    return { ...state, hoverPanelId: hoverPanelId };
  }),
  on(fromShadingActions.SetHoveredPanelData, (state, { hoveredPanelData }) => {
    return { ...state, hoveredPanelData: hoveredPanelData };
  }),
  on(fromShadingActions.DeletePanels, (state, { solarAccessThreshold }) => {
    return { ...state, solarAccessThreshold: solarAccessThreshold };
  }),
  on(fromShadingActions.ChangeDefaultMinThreshold, (state, { defaultMinThreshold }) => {
    return { ...state, defaultMinThreshold: defaultMinThreshold };
  }),
  on(fromShadingActions.SetRoofIrradiance, (state, { roofIrradianceList }) => {
    return { ...state, roofIrradianceList: roofIrradianceList };
  }),
  on(fromShadingActions.ClearShadingData, (state) => {
    return { ...state,
      defaultMinThreshold: 0,
      solarAccessThreshold: 0,
      allPanelsData: {},
      hoverPanelId: '',
      hoveredPanelData: {},
      panelsYearlyData: [],
      heatMapData: {}
    };
  })
);
