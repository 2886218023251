
  <div class="modal-header-proposal">
    <button
    style="float: right;"
    mat-icon-button
    class="close-button"
    [mat-dialog-close]="true"
  >
    <mat-icon>close</mat-icon>
  </button>
    <h2 class="modal-title-proposal" style="margin-top: 10px !important;"><i class="fa fa-cog"></i>  Configuration Menu</h2>
</div>
<div mat-dialog-content class="modal-body">
<div  class="row page-content">
    <h2 class="modal-title-proposal"  style="margin-top: 10px !important;"><i class="fa fa-tachometer" aria-hidden="true"></i> Hardware Setup</h2>
    <div class="btn-group btn-group-justified blocks">
        <a class="btn btn-enActgreen btn-lg padding10" id="module_setups" style="text-align: center;">
        <i class="glyphicon glyphicon-th-large"></i> Module Setup</a>
        <a class="btn btn-enActgreen btn-lg padding10" id="inverter_setups" style="text-align: center;">
        <i class="fas fa-tachometer-alt"></i> Inverter Setup</a>
        <a class="btn btn-enActgreen btn-lg padding10" id="battery_setups"  style="text-align: center;"
            >
        <i class="glyphicon glyphicon-tasks"></i> Battery Setup</a>
        </div>
        <div class="btn-group btn-group-justified blocks">
            <a class="btn btn-enActgreen btn-lg padding10" id="module_setups" style="text-align: center;">
            <i class="glyphicon glyphicon-th-large"></i> Racking</a>
            <a class="btn btn-enActgreen btn-lg padding10" id="inverter_setups" style="text-align: center;">
            <i class="fas fa-tachometer-alt"></i> Monitoring</a>
       
            </div>
    <h2 class="modal-title-proposal"  style="margin-top: 10px !important;"><i class="icon-file-alt"></i> Document Setup</h2>
    <div class="btn-group btn-group-justified blocks">
        <a class="btn btn-enActgreen btn-lg padding10" id="module_setups" style="text-align: center;" (click)="proposalScreen()">
        <i class="glyphicon glyphicon-th-large"></i> Proposal Setup</a>
        <a class="btn btn-enActgreen btn-lg padding10" id="inverter_setups" style="text-align: center;">
        <i class="fas fa-tachometer-alt"></i> Contract Setup</a>
   
        </div>
    <h2 class="modal-title-proposal"  style="margin-top: 10px !important;"><i class="fa fa-university"></i> Finance Setup</h2>
    <div class="btn-group btn-group-justified blocks">
        <a class="btn btn-enActgreen btn-lg padding10" id="module_setups" style="text-align: center;">
        <i class="glyphicon glyphicon-th-large"></i> Finance Setup</a>
        <a class="btn btn-enActgreen btn-lg padding10" id="inverter_setups" style="text-align: center;">
        <i class="fas fa-tachometer-alt"></i> Incentive Setup</a>
        <a class="btn btn-enActgreen btn-lg padding10" id="battery_setups"  style="text-align: center;" (click)="costingScreen()">
        <i class="glyphicon glyphicon-tasks"></i> Costing Setup</a>
        </div>
    <h2 class="modal-title-proposal"  style="margin-top: 10px !important;"><i class="fa fa-user"></i> General Setup</h2>
	<div class="col-sm-6 pl-4 mr-3 float-left"><br>
		<span class="navHeader">Unit of measurement :  </span>
		<span class="fontGrey"> (Feet)&nbsp;&nbsp;</span>
	      <label class="switch mb-2">
	        <input type="checkbox" id= "feetToMeterToggle">
	        <span class="slider round"></span>
	      </label>
		<span class="fontGrey">&nbsp;&nbsp;(Meters)</span>
		</div>
        <div class="col-sm-6 pl-4 mr-3 float-left"><br>
			<span class="navHeader">&nbsp;Default landing page :  </span>
			    <select id= "defaultLanding" >
		        <option id="projectsPage" value="projectsPage">Projects Dashboard</option>
				<option id="tasksPage" value="tasksPage">Tasks Dashboard</option>
		        <span class="slider round"></span>
		      </select>
			</div>
        <br/>
        <br/>
    <h2 class="modal-title-proposal"  style="margin-top: 10px !important;"> <i class="fa fa-language"></i> International</h2>
    <div class="btn-group btn-group-justified blocks">
       
        <a class="btn btn-enActgreen btn-lg padding10" id="battery_setups"  style="text-align: center;"
            >
        <i class="glyphicon glyphicon-tasks"></i> Locale Setup</a>
        </div>
</div>
</div>
