import
  {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
  } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions } from 'chart.js';
import { CookieService } from 'ngx-cookie-service';
import { Utils } from '../../../../../helpers/utils';
import { SystemDesignService } from '../../../system-design.service';
import { GraphImage } from '../../interfaces/graph-image';
import {LoaderService} from '../../../../shared/loader/loader.service';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthService } from 'src/app/helpers/auth.service';

@Component({
  selector: 'app-system-result-graphs',
  templateUrl: './system-result-graphs.component.html',
  styleUrls: ['./system-result-graphs.component.scss'],
})

export class SystemResultGraphsComponent implements OnInit, OnDestroy {
  @Input() public metersColl: any;
  // @Input() usedCurrency: String;
  @Output() graphRenderEvent: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChild('myTab') tabGroup;
  selectedMeter = 0;
  totalNumberOfGraphs: any;
  monthsCollection: any;
  graphOptions: any;
  selectedGraph: any;
  graphImage: Array<GraphImage> = [];
  graphDataVSdivIdMap = new Map();
  kWhHeader: String = 'Consumption vs Generation (kWh)';
  kwhXaxis: String = 'Jan-Dec';
  consumptionText: String = 'Consumption';
  generationText: String = 'Generation';
  billOffsetHeader: String = 'Pre and Post Project Utility Bill';
  billOffsetName = 'item.name';
  displayLegend: boolean = true;
  kwhTieredHeader: String = 'Pre and Post Solar Usage (kWh)';
  newData = true;
  dwnloadLink = '';
  widthOfContainerJune = 0;
  heightOfContainerJune = 0;
  widthOfContainerDec = 0;
  heightOfContainerDec = 0;
  widthOfContainerAvDay = 0;
  heightOfContainerAvDay = 0;
  storagePresent = false;
  applyLocaleVar = this.utils.countryCurr;
  modalClosed: boolean = false;
  // this is used to render the pv graphs in the first call so
  // that we can get theimage string to save for proposal and angular is desnot have onload event for div
  firstTimeInNgAfterViewChecked = true;
  mixedChartDataAPI: any = [];
  mixedChartLabels: any = [];
  chartMixedData: any = [];
  chartData = new Array<any>();
  chartLabel: any;
  lineLabel: any;
  kwhTieredObjChartData: any;
  chartOptions: ChartOptions = {
    responsive: true,
  };
  subscriptions: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private cookiesService: CookieService,
    private systemDesignService: SystemDesignService,
    private utils: Utils,
    private loaderService: LoaderService,
    private authService : AuthService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
  ngOnInit(): void {
    this.modalClosed = false;
    if (
      this.cookiesService.check('localeLang') &&
      this.cookiesService.get('localeLang') == 'ES'
    ) {
      this.kWhHeader = 'Consumo vs Generación (kWh)';
      this.consumptionText = 'Consumo';
      this.generationText = 'Generación';
      this.billOffsetHeader =
        'Factura de servicio público – Sin Energía Solar y Con Energía Solar';
      this.displayLegend = false;
      this.kwhTieredHeader = 'Consumo - Antes y Después Solar (kWh)';
    }
    this.monthsCollection = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    this.graphOptions = [
      { id: 1, name: 'kWh Offset View' },
      { id: 2, name: 'kWh Tiered View' },
      { id: 3, name: 'Bill Offset View' },
    ];
    const meterindex = +this.selectedMeter;
    if (this.metersColl?.[meterindex]?.['dayAverage']) {
      this.graphOptions = [
        { id: 1, name: 'kWh Offset View' },
        { id: 2, name: 'kWh Tiered View' },
        { id: 3, name: 'Bill Offset View' },
        { id: 4, name: 'Load Analysis (21-June)' },
        { id: 5, name: 'Load Analysis (21-Dec)' },
        { id: 6, name: 'Load Analysis (Typical Day)' },
      ];
      this.storagePresent = true;
    }
    this.selectedGraph = 4;
    this.graphImage = [];
    this.totalNumberOfGraphs =
      (this.metersColl?.length || 0) * (this.graphOptions?.length || 0);
    this.dwnloadLink = `${Utils.getBaseurl()}consumers/getPVOffsetData?crn=${this.authService.getItem('crn')}`;
  }

  getFormattedData(kwhTieredObj: any) {
    const hasKwhTieredObjConsumption = kwhTieredObj?.consumption;
    const hasKwhTieredObjGeneration = kwhTieredObj?.generation;
    if (hasKwhTieredObjConsumption) {
      kwhTieredObj.consumption = kwhTieredObj.consumption.map((value: number) =>
        Math.round(value)
      );
    }
    if (hasKwhTieredObjGeneration) {
      kwhTieredObj.generation = kwhTieredObj.generation.map((value: number) =>
        Math.round(value)
      );
    }
    return kwhTieredObj;
  }

  formatGraphObj(kwhTieredObj: any[]) {
    kwhTieredObj = kwhTieredObj.map((obj) => {
      obj.data = obj.data.map((value: number | null) => {
        if (value && !isNaN(value)) {
          return Math.round(value);
        }
        return value;
      });
      return obj;
    });
    return kwhTieredObj;
  }

  closeModal(reason: string) {
    const that = this;
    if(this.systemDesignService.numberOFGraphsNeeded<=0){
     this.close(reason)
    }else{
      this.subscriptions.add(interval(100)
      .pipe(takeWhile((value: any) => that.checkForUpdate(value)))
      .subscribe(
        (value: any) => that.showLoader(),
        (error: any) => that.close(reason),
        () => that.close(reason)
      ));
    }
  }
  close(reason:any){
    this.hideLoader();
    this.modalClosed = true;
    this.activeModal.close(reason);
  }
  checkForUpdate(value: number) {
    if (value > 30) {
      //wait for more then 7000secs to pass the api
      return false;
    }
    return (this.systemDesignService.numberOFGraphsNeeded>0);
    //return !this.systemDesignService.panelConfigUpdateDone;
  }
  selectMeter(id: any) {
    this.selectedMeter = id;
  }

  setSelectedGraph(val: any) {
    this.selectedGraph = val;
  }
}
