import { Status } from "./simple.types";

export interface RequestPayload {
  account_id: string,
  role: string,
  email_id: string,
  status: string,
  startTime: any,
  endTime: any,
  workFlowChanged: boolean,
  selectedWorkFlow: string,
  treeViewChanged: boolean,
  accountsAndEmailFromTreeView: any[],
}

export interface ProjectListGraph {
  account_id: string;
  address: string;
  company: string;
  c_email: string;
  c_fn: string;
  c_phone: string;
  city: string;
  create_ts: Date;
  crn: string;
  last_update: Date;
  sp_email: string;
  sp_fn: string;
  type: string;
  update_ts: string;
  create_i18n: string;
  lastupdate_i18n: string;
  update_i18n: string;
}

export interface ProjectListApiResponse {
  data: ProjectListGraph[];
  status: Status;
  error?: string;
}

export const toProjectList = (res: any): ProjectListApiResponse => ({
  data: res ? res.map((d: any) => ({
    account_id: d?.account_id ? d.account_id : '-',
    address: d?.addr1 ? d.addr1 : '-',
    company: d?.bn ? d.bn : '-',
    c_email: d?.c_email ? d.c_email : '-',
    c_fn: d?.c_fn ? d.c_fn : '-',
    c_phone: d?.c_phone ? d.c_phone : '-',
    city: d?.city ? d.city : '-',
    create_ts: d?.create_ts ? new Date(d.create_ts).toLocaleString() : '-',
    crn: d?.city ? d.city : '-',
    last_update: d?.last_update ? new Date(d.last_update).toLocaleString() : '-',
    sp_email: d?.sp_email ? d.sp_email : '-',
    sp_fn: d?.sp_fn ? d.sp_fn : '-',
    type: d?.type ? d.type : '-',
    update_ts: d?.update_ts ? d.update_ts : '-',
    create_i18n: d?.create_i18n ? d.create_i18n : '-',
    lastupdate_i18n: d?.lastupdate_i18n ? d.lastupdate_i18n : '-',
    update_i18n: d?.update_i18n ? d.update_i18n : '-',
  })) : [],
  status: Status.Success
})

export interface YearlyGraphData {
  consumption: any,
  time: number
}
export interface MonthQuaterGraphData {
  consumption: any;
  hour: number;
  month: string
}

export interface AverageData {
  yearlyGraphData: YearlyGraphData[];
  monthlyGraphData: MonthQuaterGraphData[];
  quaterGraphData: MonthQuaterGraphData[];
}