import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';
import { isUrlAcceptingSpecialHeaders } from '../utils';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authService: AuthService) { }


  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    if(request.url.includes(`${environment.webhookApi}trigger`)){
      return next.handle(request);
    }
    if (request.url.includes(environment.engage_apigateway_url)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'x-api-key': environment.engage_apigateway_key
        }
      });
    } else if (request.url.indexOf('/login') === -1 && request.url.indexOf('s3.amazonaws.com')===-1 && request.url.indexOf('s3-accelerate.amazonaws.com')===-1) {
      request = request.clone({
        setHeaders: {
          // Authorization: `Bearer ${this.authService.getItem('sessionId')}`,
          Authorization: `Bearer ${localStorage.getItem('token')}`,

          'x-api-key': environment.newApiKey
        }
      });

    }

    else if (request.url.indexOf('/login') != -1 || request.url.indexOf('s3-accelerate.amazonaws.com') != -1 || request.url.indexOf('s3.amazonaws.com') != -1 ) {
      request = request.clone({
        setHeaders: {
          'x-api-key': environment.newApiKey
        }
      });
    }
    else if (request.url.indexOf('/3d-object') != -1) {
      request = request.clone({
        setHeaders: {
          'x-api-key': environment.s3ApiKey
        }
      });

    }
    else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'x-api-key': environment.newApiKey
        }
      });
    }

    const userIdentity = `email:${this.authService.getItem('email_id')}:accountId:${this.authService.getItem('account_id')}:`;
    // Only broker, quote service and design service should get this header
    if (isUrlAcceptingSpecialHeaders((request.url))) {
      request = request.clone({
        setHeaders: {userIdentity}
      });
    }
    return next.handle(request);
    // awaiting for the api
    // return next.handle(request).pipe(catchError(err => { 
    //   if (err.status === 401) {
    //     this.authService.collectFailedRequest( request );
    //     return this.handle401Error(request, next);
    //   }
    //   const error = err.message || err.statusText;
    //   return throwError('Oops! something went wrong please try again later.');
    // }));

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.authService.getItem('refreshToken');
      if (token)
        return this.authService.refreshToken(token).pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            this.authService.setItem('token',token.AuthenticationResult.AccessToken);
            this.refreshTokenSubject.next(token.AuthenticationResult.AccessToken);
                  this.authService.retryFailedRequests();
            return next.handle(this.addTokenHeader(request, token.AuthenticationResult.AccessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            return throwError(err);
          })
        );
        else{
          this.authService.logout(); //if token not found
        }
    }
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
];
