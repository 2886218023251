
<div class="stack-chart-tooltip-container-inner" #stackChartReadoutelementdata [style.visibility]="showReadout ? 'visible':'hidden'"
id="readout" style="position:absolute;z-index: 1;top:0;" [style.left]="getreadoutPosXdata()">
<div class="tool-container">
  <div class="tool-head">{{yLabel == 'Consumption (kWh)' ? ('consumpKWH' |translate ) : ('prjtdCost' |translate )}} {{stackDataRendering['month']}}</div>
  <div class="tool-content" *ngFor="let sk  of stackKeys ;let i = index">
    <div class="tool-stacks">
      <div class="stack-col" [style.background] = "colors[i]"></div>
      <div>{{'project.prjTab.' + sk | translate}}</div>
    </div>
    <div class="tool-amnt">{{appendCurrSymbol(stackDataRendering[sk])}}</div>
  </div>
</div>
</div>

<p style="margin-left: 15px;">{{this.text}} </p><span style="margin:auto; display:table;">{{this.header}}</span>

<div *ngIf="selectedGraph" #chartContainer class="chart-container-stacked{{selectedGraph}}"
    style="height:100%; width: inherit; display: flex; justify-content: center;" id="analysis-graph">
</div>

<div *ngIf="!selectedGraph" #chartContainer class="chart-container-stacked" style="height:100%; width: inherit;display: flex; justify-content: center;"
    id="analysis-graph">
</div>