export interface Inverter {
  dcAcRatio: string;
  efficiency: string;
  addedCapacity: number;
  configured: InverterDetail[];
}

export interface InverterDetail {
  inverterModel: string;
  inverterModelNumber: string;
  inverterModelNumberValue: string;
  powerRating: string;
  unitCost: number;
  inverterQuantity: number;
  utilizedCapacity: number;
}

export const toInverterConfig = (res: any): Inverter => ({
  dcAcRatio: res?.dcAcRatio,
  efficiency: res?.efficiency,
  addedCapacity: res?.addedCapacity,
  configured: res?.configured?.length ? res.configured : []
})