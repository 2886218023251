export type presetColorsObject = {
  name: string,
  value: string
}

export const PRESET_COLORS_DEFAULT: presetColorsObject[] = [
  {
    name: 'Purple',
    value: '#4B0082',
  },
  {
    name: 'Yellow',
    value: '#EDFF24',
  }
];