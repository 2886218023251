import {
  BufferGeometry,
  InstancedBufferAttribute,
  Material,
  Matrix4,
  MeshStandardMaterial,
  SRGBColorSpace,
  TextureLoader,
  Vector2,
  WebGLProgramParametersWithUniforms,
  WebGLRenderer,
} from 'three';
import { InstancedMesh } from 'three';
import { MeshUserData } from '../helpers/Utility';

export const enum PanelTextureEnum {
  Normal = 0,
  Transparent = 1,
  Highlight = 2,
  Grey = 3,
  Hover = 4,
}
class InstancedMeshUtil extends InstancedMesh {
  static textures: any;

  static loadPanelTextures(){
    InstancedMeshUtil.textures = {
      // type: 'tv',
      value: [
        new TextureLoader().load(
          './assets/threejs/Solar-panel-texture.png',
          (texture) => {
            texture.center = new Vector2(0.5, 0.5);
            texture.colorSpace = SRGBColorSpace;
          }
        ),
        new TextureLoader().load(
          './assets/threejs/Solar_panel-transparent-texture.png',
          (texture) => {
            texture.center = new Vector2(0.5, 0.5);
            texture.colorSpace = SRGBColorSpace;
          }
        ),
        new TextureLoader().load(
          './assets/threejs/highlight_solar_panel.png',
          (texture) => {
            texture.center = new Vector2(0.5, 0.5);
            texture.colorSpace = SRGBColorSpace;
          }
        ),
        new TextureLoader().load(
          './assets/threejs/grey_panel.png',
          (texture) => {
            texture.center = new Vector2(0.5, 0.5);
            texture.colorSpace = SRGBColorSpace;
          }
        ),
        new TextureLoader().load(
          './assets/threejs/panel_hover.png',
          (texture) => {
            texture.center = new Vector2(0.5, 0.5);
            texture.colorSpace = SRGBColorSpace;
          }
        ),

      ],
    } ;

  }

  static init(instancedMesh: InstancedMesh){
    InstancedMeshUtil.setGeomAttr(instancedMesh);
  }

  static setGeomAttr(instancedMesh: InstancedMesh){
    // Create a visibility attribute for each instance, default to visible (1)
    const visibility = new Float32Array(instancedMesh.count).fill(1); // 1 = visible, 0 = invisible
    const textures = new Float32Array(instancedMesh.count).fill(PanelTextureEnum.Normal); // 1 = visible, 0 = invisible
    instancedMesh.geometry.setAttribute(
      'instanceVisibility',
      new InstancedBufferAttribute(visibility, 1)
    );
    instancedMesh.geometry.setAttribute(
      'textureIndex',
      new InstancedBufferAttribute(new Float32Array(textures), 1)
    );
  }
  static setShader(instancedMesh: InstancedMesh) {
    // Modify the shader to use the instanceVisibility attribute
    (instancedMesh.material as MeshStandardMaterial).onBeforeCompile = (parameters: WebGLProgramParametersWithUniforms, renderer: WebGLRenderer) => {

      parameters.vertexShader =
      `attribute float instanceVisibility;
      varying float vVisibility;
      ${parameters.vertexShader}`
        .replace(
          `#include <begin_vertex>`,
          `#include <begin_vertex>
           vVisibility = instanceVisibility;`
        )
        .replace(
          '#define STANDARD',
          `#define STANDARD
           varying vec3 vTint;`
        )
        .replace(
          '#include <common>',
          `#include <common>
          attribute vec3 tint;`
        )
        .replace(
          '#include <project_vertex>',
          `#include <project_vertex>
vTint = tint;`
        );

        parameters.fragmentShader = `
                varying float vVisibility;
                ${parameters.fragmentShader}
            `
        .replace(
          `#include <dithering_fragment>`,
          `if (vVisibility < 0.5) discard;
                #include <dithering_fragment>
                `
        )
        .replace(
          '#define STANDARD',
          `#define STANDARD
                      varying vec3 vTint;`
        )
    };
  }

  static setPanelShader(instancedMesh: InstancedMesh) {
    // Modify the shader to use the instanceVisibility attribute
    (instancedMesh.material as MeshStandardMaterial).onBeforeCompile = (parameters: WebGLProgramParametersWithUniforms, renderer: WebGLRenderer) => {


      let mapFrag = `col = texture2D(textures[0], vMapUv ) * step(-0.1, x) * step(x, 0.1);`;
      for (let i = 0; i < this.textures.value.length; i++) {
        mapFrag+=`col += texture2D(textures[${i}], vMapUv ) * step(${i-1}.9, x) * step(x, ${i}.1);`
      }

      parameters.uniforms.textures = InstancedMeshUtil.textures;

      parameters.vertexShader =
      `attribute float instanceVisibility;
varying float vVisibility;
${parameters.vertexShader}`
        .replace(
          `#include <begin_vertex>`,
          `#include <begin_vertex>
           vVisibility = instanceVisibility;`
        )
        .replace(
          '#define STANDARD',
          `#define STANDARD
           varying vec3 vTint;
           varying float vTextureIndex;`
        )
        .replace(
          '#include <common>',
          `#include <common>
attribute vec3 tint;
attribute float textureIndex;`
        )
        .replace(
          '#include <project_vertex>',
          `#include <project_vertex>
vTint = tint;
vTextureIndex=textureIndex;`
        );

        parameters.fragmentShader = `
                varying float vVisibility;
                ${parameters.fragmentShader}
            `
        .replace(
          `#include <dithering_fragment>`,
          `if (vVisibility < 0.5) discard;
                #include <dithering_fragment>
                `
        )
        .replace(
          '#define STANDARD',
          `#define STANDARD
                      uniform sampler2D textures[${InstancedMeshUtil.textures.value.length}];
                      varying vec3 vTint;
                      varying float vTextureIndex;`
        )
        .replace(
          '#include <map_fragment>',
          `
                  int texIdx = int(vTextureIndex);
                  float x = vTextureIndex;
                  vec4 col;
                  ${mapFrag}
                  diffuseColor *= col;
`        );
    };
  }

  getGeometryAt(instancedMesh: InstancedMesh, index: number){
  }

  /**
   * Sets the visibility of a specific instance.
   * @param {number} index - The index of the instance.
   * @param {boolean} isVisible - True to make the instance visible, false to hide it.
   */
  static getVisibilityAt(instancedMesh: InstancedMesh, index: number) {
    const visibilityArray = (
      instancedMesh.geometry.attributes
        .instanceVisibility as InstancedBufferAttribute
    ).array;
    const visibility = new Float32Array(visibilityArray.length);
    visibility.set(visibilityArray);
    return visibility[index] == 1 ? true : false;
  }

  static getVisibilityArray(instancedMesh: InstancedMesh) {
    const visibilityArray = (
      instancedMesh.geometry.attributes
        .instanceVisibility as InstancedBufferAttribute
    ).array;
    const visibility = new Float32Array(visibilityArray.length);
    visibility.set(visibilityArray);
    return visibility;
  }
  /**
   * Sets the visibility of a specific instance.
   * @param {number} index - The index of the instance.
   * @param {boolean} isVisible - True to make the instance visible, false to hide it.
   */
  static setVisibilityAt(
    instancedMesh: InstancedMesh,
    index: number,
    isVisible: boolean
  ) {
    const visibilityArray = (
      instancedMesh.geometry.attributes
        .instanceVisibility as InstancedBufferAttribute
    ).array;
    const visibility = new Float32Array(visibilityArray.length);
    visibility.set(visibilityArray);
    visibility[index] = isVisible ? 1 : 0;
    instancedMesh.geometry.setAttribute(
      'instanceVisibility',
      new InstancedBufferAttribute(visibility, 1)
    );
    instancedMesh.geometry.attributes.instanceVisibility.needsUpdate = true;
    instancedMesh.userData.visibility = visibility;
  }


  /**
   * Sets the visibility of multiple instances at once.
   * @param {Array<number>} indices - Array of indices of the instances.
   * @param {boolean} isVisible - True to make the instances visible, false to hide them.
   */
  static setMultipleVisibilities(
    instancedMesh: InstancedMesh,
    indices: Array<number>,
    isVisible: boolean,
    setAll?: Boolean
  ) {
    if (!instancedMesh || !instancedMesh.geometry) {
      console.error('InstancedMesh or its geometry is not defined');
      return;
    }

    const visibilityArray = (
      instancedMesh.geometry.attributes.instanceVisibility as InstancedBufferAttribute
    ).array;
    const visibility = new Float32Array(visibilityArray.length);
    visibility.set(visibilityArray);
    const visibilityValue = isVisible ? 1 : 0;

    if (setAll) {
      for (let i = 0; i < visibility.length; i++) {
        visibility[i] = visibilityValue;
      }
    } else {
      indices.forEach((index) => {
        visibility[index] = visibilityValue;
      });
    }

    instancedMesh.geometry.setAttribute(
      'instanceVisibility',
      new InstancedBufferAttribute(visibility, 1)
    );
    instancedMesh.geometry.attributes.instanceVisibility.needsUpdate = true;
    instancedMesh.userData.visibility = visibility;
  }

   /**
   * Sets the visibility of a specific instance.
   * @param {number} index - The index of the instance.
   * @param {boolean} isVisible - True to make the instance visible, false to hide it.
   */
   static setTextureAt(
    instancedMesh: InstancedMesh,
    index: number,
    panelTexture: PanelTextureEnum
  ) {
    const textureArray = (
      instancedMesh.geometry.attributes
        .textureIndex as InstancedBufferAttribute
    ).array;
    const texture = new Float32Array(textureArray.length);
    texture.set(textureArray);
    texture[index] = panelTexture;
    instancedMesh.geometry.setAttribute(
      'textureIndex',
      new InstancedBufferAttribute(texture, 1)
    );
    instancedMesh.geometry.attributes.textureIndex.needsUpdate = true;
    instancedMesh.userData.texture = texture;
  }

  static getTextureAt(instancedMesh: InstancedMesh, index: number): PanelTextureEnum | undefined {
    // Get the texture index array from the geometry attributes
    const textureArray = (instancedMesh.geometry.attributes.textureIndex as InstancedBufferAttribute).array;

    // Check if the index is within bounds of the texture array
    if (index < 0 || index >= textureArray.length) {
        console.error(`Index ${index} out of bounds for texture array`);
        return undefined;
    }

    // Retrieve the texture value at the specified index
    const textures = new Float32Array(textureArray.length);
    textures.set(textureArray);
    return textures[index];
}


  /**
   * Sets the visibility of multiple instances at once.
   * @param {Array<number>} indices - Array of indices of the instances.
   * @param {boolean} isVisible - True to make the instances visible, false to hide them.
   */
  static setMultipleTextures(
    instancedMesh: InstancedMesh,
    indices: Array<number>,
    panelTexture: PanelTextureEnum,
    setAll?: Boolean
  ) {
    const textureArray = (
      instancedMesh.geometry.attributes
        .textureIndex as InstancedBufferAttribute
    ).array;
    const texture = new Float32Array(textureArray.length);
    texture.set(textureArray);

    const textureValue = panelTexture;

    if (setAll) {
      for (let i = 0; i < texture.length; i++) {
        texture[i] = textureValue;
      }
    } else {
      // Update texture for each index in the array
      indices.forEach((index) => {
        texture[index] = textureValue;
      });
    }

    instancedMesh.geometry.setAttribute(
      'textureIndex',
      new InstancedBufferAttribute(texture, 1)
    );
    instancedMesh.geometry.attributes.textureIndex.needsUpdate = true;
    instancedMesh.userData.texture = texture;
  }

  static addInstance(instancedMesh: InstancedMesh, matrix: Matrix4){
    instancedMesh.count+=1;
    instancedMesh.setMatrixAt(instancedMesh.count-1, matrix);
    InstancedMeshUtil.setVisibilityAt(instancedMesh, instancedMesh.count-1, true);
    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBoundingSphere();
  }

  /**
   * Sets the visibility of a specific instance.
   * @param {number} id - The id of the instance.
   * @param {boolean} isVisible - True to make the instance visible, false to hide it.
   */
  static delete(instancedMesh: InstancedMesh, id: number) {
    let lastInd = instancedMesh.count - 1;
    let lastMatrix = new Matrix4();
    let curMatrix = new Matrix4();
    instancedMesh.getMatrixAt(lastInd, lastMatrix);
    instancedMesh.getMatrixAt(id, curMatrix);

    instancedMesh.setMatrixAt(lastInd, curMatrix);
    instancedMesh.setMatrixAt(id, lastMatrix);

    let visiblity = InstancedMeshUtil.getVisibilityAt(instancedMesh, lastInd);
    InstancedMeshUtil.setVisibilityAt(instancedMesh, id, visiblity);
    let texture = InstancedMeshUtil.getTextureAt(instancedMesh, lastInd);
    InstancedMeshUtil.setTextureAt(instancedMesh, id, texture ?? PanelTextureEnum.Normal);

    instancedMesh.count -= 1;
    instancedMesh.instanceMatrix.needsUpdate = true;
    instancedMesh.computeBoundingSphere();

  }

  static restoreInstanceMesh(instancedMesh: InstancedMesh){
    let maxInstanceCount = (instancedMesh.userData as MeshUserData)
      .maxInstanceCount;
    let currentCount = instancedMesh.count;
    instancedMesh.count = maxInstanceCount ?? instancedMesh.count;
    InstancedMeshUtil.init(instancedMesh);
    if (instancedMesh.userData.visibility) {
      let parsedVisb = instancedMesh.userData.visibility;
      let values = Object.values(parsedVisb) as Array<number>;
      const visibility = new Float32Array(values.length);
      visibility.set(values);
      instancedMesh.geometry.setAttribute(
        'instanceVisibility',
        new InstancedBufferAttribute(visibility, 1)
      );
      instancedMesh.count = currentCount;
    }
  }
}

export { InstancedMeshUtil };
