import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromEdgeActions } from './edge.action';
import { EdgeState } from './edge.state';
import { Edge } from '@design/models/edge.model';

export const adapter: EntityAdapter<Edge> = createEntityAdapter<Edge>();

export const edgeInitialState: EdgeState = adapter.getInitialState();

export const { selectAll } = adapter.getSelectors();

export const edgeReducer: ActionReducer<EdgeState> = createReducer(
  edgeInitialState,
  on(fromEdgeActions.AddMany, (state, data) => {
    return adapter.addMany(data.edges, state);
  }),
  on(fromEdgeActions.UpsertMany, (state, data) => {
    return adapter.upsertMany(data.edges, state);
  }),
  on(fromEdgeActions.DeleteMany, (state, { edgeIds }) => {
    
    const safeToDeleteIds: Edge['id'][] = [];
    for (const id of edgeIds) {
      const edge = state.entities[id]
      if(edge && edge.association.length === 0)
        safeToDeleteIds.push(id)
    }

    return adapter.removeMany(safeToDeleteIds, state);
  }),
  on(
    fromEdgeActions.EdgeConfigurationChange,
    (state, { edge }) => {
      return adapter.updateOne(
        {
          id: edge.id,
          changes: {
            ...edge,
          },
        },
        state
      );
    }
  ),
);

