import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '../utils';

@Pipe({
  name: 'localizedDate',
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private utils: Utils) {}

  transform(value: any, timeFlag: string = ''): any {
    return this.utils.localeSpecificDate(value, timeFlag);
      
  }
}