import { SimulationResults } from "@design/models/simulation.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { ActionReducer, createReducer, on } from "@ngrx/store";
import { fromSimulationActions } from "./simulation.action";
import { SimulationState } from "./simulation.state";
import { SimulationStatus } from "@design/models/shading.model";

export const adapter: EntityAdapter<SimulationResults> =
  createEntityAdapter<SimulationResults>();

  export const simulationResultsInitialState: SimulationState =
  adapter.getInitialState({
    id: null,
    system_size:0,
    storage:0,
    generation:0,
    offset:0,
    pre_solar_bill:0,
    post_solar_bill:0,
    bill_saving:0,
    monthlyGeneration:[],
    postPVAnnualConsumption: 0,
    postPVAnnualBillAmount: 0,
    batteryUages: 0,
    importYear1 : 0,
    exportYear1 :0,
    selfConsumptionSolarOnly : 0,
    selfConsumptionSolarPlusStorage : 0,
    postPVRateArr: [],
    solradAnnual:0,
    solradMonthly:[],
    pvWattsRunning:false,
    consumptionOverview: {data: [], error: null},
    generationOverview: {data: [], error: null},
    pvWattsErrorMsg:null,
    simulationStatus:SimulationStatus.Not_Started,
    optPanelSolrad: [],
    panelSolrad: []
  });

  export const simulationReducer: ActionReducer<SimulationState> =
  createReducer(
    simulationResultsInitialState,
    on(fromSimulationActions.UpdateSystemSizeSuccess,(state,{simulationResults}) =>{
        return {
          ...state,
          system_size:simulationResults.system_size,
        }
      }),on(fromSimulationActions.UpdateSystemSizeSuccessOnReload,(state,{simulationResults}) =>{
        return {
          ...state,
          system_size:simulationResults.system_size,
        }
      }),on(fromSimulationActions.UpdateStorage,(state,{storage})=>{
        return{
          ...state,
          storage:storage
        }
      }),on(fromSimulationActions.UpdateEnergyAndOffset,(state,{data}) =>{
        return{
          ...state,
          generation:data['annual_consumption'],
          offset: data['current_annual_consumption'] ? Math.ceil(
            (Number(data['annual_consumption']) /
              Number(data['current_annual_consumption'])) *
              100
          ) : 0,
          monthlyGeneration:data['ac_monthly'],
          solradAnnual:data['solrad_annual'],
          solradMonthly: data['solrad_monthly'],
          pvWattsRunning:false,
          pvWattsErrorMsg:null,
          panelSolrad: data['panels_solrad'],
          //system_size:data['system_size']
        }
      }),on(fromSimulationActions.OptimumIrradiance,(state,{optPanelSolrad}) =>{
        return{
          ...state,
          optPanelSolrad: optPanelSolrad
        }
      }),on(fromSimulationActions.CallApiForBillSuccess,(state,{data}) =>{
        return {
          ...state,
          postPVAnnualConsumption:data['postPVAnnualConsumption'],
          postPVAnnualBillAmount:data['postPVAnnualBillAmount'],
          batteryUages:data['batteryUages'],
          importYear1:data['importYear1'],
          exportYear1:data['exportYear1'],
          selfConsumptionSolarOnly:data['selfConsumptionSolarOnly'],
          selfConsumptionSolarPlusStorage:data['selfConsumptionSolarPlusStorage'],
          postPVRateArr:data['postPVRateArr']
          
        }
      }),on(fromSimulationActions.GetConsumptionDataSuccess,(state, {data}) =>{
        return{
          ...state,
          consumptionOverview: {data: data, error: null}
        }
      }),on(fromSimulationActions.GetGenerationDataSuccess,(state, {data}) =>{
        return{
          ...state,
          generationOverview: {data: data, error: null}
        }
      }),on(fromSimulationActions.GetGenerationDataFailure,(state, {error}) =>{
        return{
          ...state,
          generationOverview: {data: [], error: error}
        }
      }),on(fromSimulationActions.GetConsumptionDataFailure,(state, {error}) =>{
        return{
          ...state,
          consumptionOverview: {data: [], error: error}
        }
      }), on(fromSimulationActions.setPvWattsRunning,(state, {pvWattsRunning}) =>{
        return{
          ...state,
          pvWattsRunning:pvWattsRunning,
          pvWattsErrorMsg:null
        }
      }), on(fromSimulationActions.PVWattsHourlyFailure,(state, {error}) =>{
        return{
          ...state,
          pvWattsRunning:false,
          generation:0,
          offset:0,
          pvWattsErrorMsg:error
        
        }
      }), on(fromSimulationActions.setSimulationState,(state, {simulationStatus}) =>{
        return{
          ...state,
          simulationStatus: simulationStatus,
         
        
        }
      }), on(fromSimulationActions.ResetSolarRad,(state) =>{
        return{
          ...state,
          optPanelSolrad: [],
          panelSolrad: []
        }
      }), on(fromSimulationActions.SystemNotReadyYet,(state) =>{
        return{
          ...state,
          pvWattsRunning:false,
          generation:0,
          offset:0
        }
      }), on(fromSimulationActions.UpdateSimulationOnPanelReload,(state, {data}) =>{
        return{
          ...state,
          ...data,
        }
        }),on(fromSimulationActions.ClearSimulationData,(state)=>{
        return{
          ...state,
          id: null,
    system_size:0,
    storage:0,
    generation:0,
    offset:0,
    pre_solar_bill:0,
    post_solar_bill:0,
    bill_saving:0,
    monthlyGeneration:[],
    postPVAnnualConsumption: 0,
    postPVAnnualBillAmount: 0,
    batteryUages: 0,
    importYear1 : 0,
    exportYear1 :0,
    selfConsumptionSolarOnly : 0,
    selfConsumptionSolarPlusStorage : 0,
    postPVRateArr: [],
    solradAnnual:0,
    solradMonthly:[],
    pvWattsRunning:false,
    consumptionOverview: {data: [], error: null},
    generationOverview: {data: [], error: null},
    pvWattsErrorMsg:null,
    simulationStatus:SimulationStatus.Not_Started
        
    
        }
      }),
     

  );