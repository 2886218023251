import { RoofSection } from '@design/models/roof-section.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DESIGN_STORE_KEY, selectDesign } from '../design.selector';
import { Vertex } from '@design/models/vertex.model';
import { isNil } from 'lodash';
import { Panel } from '@design/models/panel.model';
import { PanelState } from './panel.state';
import { adapter } from './panel.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

export namespace fromPanelSelectors {
  const panelStateSelector = createSelector(
    selectDesign,
    (state) => state.panel
  );

  export const allpanels = createSelector(panelStateSelector, selectAll);
  export const allPanelTotal = createSelector(panelStateSelector, selectTotal);

  const selectPanels = createSelector(panelStateSelector, selectEntities);

  export const selectedSinglePanel = createSelector(selectDesign, (state) => {
    const selectedId = state.ui.selectedObjectId;
    if (!selectedId) {
      return null;
    }
    const entities = state.panel.entities;
    return entities[selectedId];
  });

  export const selectPanelsByIds = (ids: Panel['id'][]) =>
    createSelector(panelStateSelector, (state: PanelState) => {
      const entities = selectEntities(state);
      return ids.map((id: Panel['id']) => entities[id]);
    });
  export const selectPanelsByInstanceIds = (
    instanceIds: number[],
    association: string
  ) =>
    createSelector(allpanels, (panels: Panel[]) =>
      panels.filter(
        (p) => p.association === association && instanceIds.includes(p.instanceId)
      )
    );

  /**
   * Assuming this method is called using roof vertexIds as parameter.
   * Return ids that do not have any associations.
   */
  export const findSafeToDeletePanels = (ids: Panel['id'][]) =>
    createSelector(selectDesign, (state) => {
      const safeToDeleteIds: Panel['id'][] = [];
      for (const id of ids) {
        const panel = state.panel.entities[id]
        if(panel && panel.association.length === 0)
          safeToDeleteIds.push(id)
      }
      return safeToDeleteIds;
    });
    export const getPanelsByAssociation = (association: string) =>
  createSelector(allpanels, (panels: Panel[]) =>
  panels.filter(p=> p.association === association) );
}
