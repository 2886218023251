import { Injectable } from "@angular/core";
import { BatchAutoSaveService } from "./batch-auto-save.service";
import { BehaviorSubject, combineLatest, of, Subject, Subscription } from "rxjs";
import { catchError, map, startWith } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { DesignState } from "@design/store/design.reducer";
import { fromSimulationResultsSelectors } from "@design/store/simulation/simulation.selector";
import { fromAdditionalToolsResultsSelectors } from "@design/store/additional-tools";
import { SaveStatus } from "@design/models/save-status.model";
import { fromShadingSelectors } from "@design/store/shading";
import { ShadingStatus, SimulationStatus } from "@design/models/shading.model";

@Injectable({
    providedIn: 'root',
})
export class NavigationControllerService {
    public $isCompleted: BehaviorSubject<boolean> = new BehaviorSubject(false);

    canNavigate() {
        return this.$isCompleted.asObservable();
    }

    constructor(
        private batchAutoSaveStatus: BatchAutoSaveService,
        private store: Store<DesignState>
    ) { }

    public initNavigationSubscription() {
        return combineLatest([
            this.batchAutoSaveStatus.batchAutoSaveStatus().pipe(startWith(SaveStatus.SAVED)), // Design and 3d-object
            this.store.select(fromSimulationResultsSelectors.selectPVWattRunningStatus).pipe(startWith(false)), // hourly
            this.store.select(fromSimulationResultsSelectors.pvWattsFailure).pipe(startWith(null)), // hourly
            this.store.select(fromSimulationResultsSelectors.simulationAPIStatus).pipe(startWith(SimulationStatus.Not_Started)), // quoteMonthly 'SUCCESS'
            this.store.select(fromAdditionalToolsResultsSelectors.hardwareAPIStatus).pipe(startWith(SaveStatus.SAVED)), // hardware
            this.store.select(fromAdditionalToolsResultsSelectors.quoteAPIStatus).pipe(startWith(SaveStatus.SAVED)), // quote
            this.store.select(fromAdditionalToolsResultsSelectors.backgroundImageAPIStatus).pipe(startWith(SaveStatus.SAVED)), // saveBackgroundImage
            this.store.select(fromAdditionalToolsResultsSelectors.houseViewImageAPIStatus).pipe(startWith(SaveStatus.SAVED)), // saveHouseViewImage
            this.store.select(fromShadingSelectors.selectShadingStatus).pipe(startWith(null)) // shading
        ]).subscribe(([batchAutoSaveStatus, PVWattRunningStatus, pvWattsFailure, simulationAPIStatus, hardwareAPIStatus, quoteAPIStatus, backgroundImageAPIStatus, houseViewImageAPIStatus, selectShadingStatus]) => {
                // console.log('****************************************')
                // console.log('From Navigation => batchAutoSaveStatus :', batchAutoSaveStatus);
                // console.log('From Navigation => PVWattRunningStatus :', PVWattRunningStatus);
                // console.log('From Navigation => pvWattsFailure :', pvWattsFailure);
                // console.log('From Navigation => simulationAPIStatus :', simulationAPIStatus);
                // console.log('From Navigation => hardwareAPIStatus :', hardwareAPIStatus);
                // console.log('From Navigation => quoteAPIStatus :', quoteAPIStatus);
                // console.log('From Navigation => backgroundImageAPIStatus :', backgroundImageAPIStatus);
                // console.log('From Navigation => houseViewImageAPIStatus :', houseViewImageAPIStatus);
                // console.log('From Navigation => selectShadingStatus :', selectShadingStatus);
                if ((batchAutoSaveStatus == SaveStatus.SAVED || batchAutoSaveStatus == SaveStatus.CANCELLED) &&
                    PVWattRunningStatus == false &&
                    pvWattsFailure == null &&
                    (simulationAPIStatus == SimulationStatus.Success || simulationAPIStatus == SimulationStatus.Outdated || simulationAPIStatus == SimulationStatus.Not_Started) &&
                    hardwareAPIStatus == SaveStatus.SAVED &&
                    quoteAPIStatus == SaveStatus.SAVED &&
                    backgroundImageAPIStatus == SaveStatus.SAVED &&
                    houseViewImageAPIStatus == SaveStatus.SAVED &&
                    selectShadingStatus != ShadingStatus.Running) {
                    console.log('From NavigationControllerService => All APIs are finally saved');
                    this.$isCompleted.next(true);
                } else {
                    this.$isCompleted.next(false);
                }
            },
            catchError((e) => {
                return of(false);
            }));
    }

}