import { state } from "@angular/animations";
import { UploadDetailsReadParams, uploadSuccess } from "@design/models/additional-tools.model";
import { createEntityAdapter, EntityAdapter } from "@ngrx/entity";
import { ActionReducer, createReducer, on } from "@ngrx/store";
import { fromAdditionalToolsActions } from "./additional-tools.action";
import { AdditionalToolsState } from "./additional-tools.state";
import { SaveStatus } from "@design/models/save-status.model";


export const adapter: EntityAdapter<UploadDetailsReadParams> = createEntityAdapter<UploadDetailsReadParams>();

export const AdditionalToolsInitialState: AdditionalToolsState = adapter.getInitialState({
    totalGeneration : 0,
    selectedMeter:"Meter 1",
    systemLosses:14,
    utilityescalator:5,
    post_PV_Electric_rate:null,
    post_PV_Electric_rateId:null,
    selectedMonitoringSys:null,
    hardwareAPIStatus: SaveStatus.SAVED,
    quoteAPIStatus: SaveStatus.SAVED,
    backgroundImageAPIStatus: SaveStatus.SAVED,
    houseViewImageAPIStatus: SaveStatus.SAVED,
    customGenerationFileInfo: null,
    customGenInfo: null,
    disableReportForCustom: false,
    uploadError:null,
    isSceneRestored: SaveStatus.OUTDATED,
    isMapLoaded: SaveStatus.OUTDATED
});
export const { selectAll } = adapter.getSelectors();

export const additionalToolsReducer: ActionReducer<AdditionalToolsState> = createReducer(
    AdditionalToolsInitialState,
    on(fromAdditionalToolsActions.UploadCustomGenSuccess, (state, { uploadSuccess }) => {
        return {
            ...state,
            totalGeneration: uploadSuccess?.totalGeneration,
        }
    }),
    on(fromAdditionalToolsActions.SetGeneration, (state, { genData }) => {
      return {
          ...state,
          totalGeneration: genData,
      }
    }),
    on(fromAdditionalToolsActions.UploadCustomGenFailure, (state) => {
      return {
          ...state,
          uploadError: 'Something went wrong. Please try again!',
      }
    }),
    on(fromAdditionalToolsActions.CPFRatesSuccess, (state, { resp }) => {
        return {
            ...state,
            CPFRates: resp,
        }
    }),
    on(fromAdditionalToolsActions.MonitoringFetchSuccess, (state, { preferences }) => {
        return {
            ...state,
            monitoringResp: preferences?.monitoring,
        }
    }),
    on(
        fromAdditionalToolsActions.UpdateMeter,
        (state, { resp }) => {
            return {...state, selectedMeter:resp}
      }),
    on(
        fromAdditionalToolsActions.LoadPvRate,
        (state, { resp }) => {
            return adapter.updateOne(
                resp,
                state
              )
      }),
      on(
        fromAdditionalToolsActions.UpdatePvRate,
        (state, { UpdatedPvRate }) => {
            return {...state, UpdatedPvRate}
      }),
      on(
        fromAdditionalToolsActions.UpdateMonitoringSys,
        (state, { selectedMonitoringSys }) => {
            return {...state, selectedMonitoringSys}
      }),
      on(
        fromAdditionalToolsActions.AdditionalToolsConfigurationChange,
        (state, { resp }) => {
            return {...state, ...resp}
      }),
      on(
        fromAdditionalToolsActions.AdditionalToolsConfigurationChangeOnReload,
        (state, { resp }) => {
            return {...state, ...resp}
      }),
      on(fromAdditionalToolsActions.setHardwareAPIStatus, (state, { setHardwareAPIStatus }) => {
        return {
          ...state,
          hardwareAPIStatus: setHardwareAPIStatus,
        }
      }),
      on(fromAdditionalToolsActions.setquoteAPIStatus, (state, { quoteAPIStatus }) => {
        return {
          ...state,
          quoteAPIStatus: quoteAPIStatus,
        }
      }),
      on(fromAdditionalToolsActions.setBackgroundImageAPIStatus, (state, { backgroundImageAPIStatus }) => {
        return {
          ...state,
          backgroundImageAPIStatus: backgroundImageAPIStatus,
        }
      }),
      on(fromAdditionalToolsActions.setHouseViewImageAPIStatus, (state, { houseViewImageAPIStatus }) => {
        return {
          ...state,
          houseViewImageAPIStatus: houseViewImageAPIStatus,
        }
      }),
      on(fromAdditionalToolsActions.SetFileInfo, (state, { fileContent }) => {
        return {
          ...state,
          customGenerationFileInfo: fileContent,
        }
      }),
      on(fromAdditionalToolsActions.SetCustomGenInfo, (state, { genInfo }) => {
        return {
          ...state,
          customGenInfo: genInfo,
        }
      }),
      on(fromAdditionalToolsActions.ResetCustomGeneration, (state) => {
        return {
          ...state,
          customGenInfo: null,
          customGenerationFileInfo: {},
          totalGeneration: 0,
          uploadError: ''
        }
      }),
      on(fromAdditionalToolsActions.SetSimulateCustom, (state, { disbaleFlag }) => {
        return {
          ...state,
          disableReportForCustom: disbaleFlag,
        }
      }),

      on(fromAdditionalToolsActions.setMapLoadingStatus, (state, { mapLoadingStatus }) => {
        return {
          ...state,
          isMapLoaded: mapLoadingStatus,
        }
      }),

      on(fromAdditionalToolsActions.setSceneRestoreStatus, (state, { sceneRestoreStatus }) => {
        return {
          ...state,
          isSceneRestored: sceneRestoreStatus,
        }
      }),

);