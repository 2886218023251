import robustPointInPolygon from "robust-point-in-polygon";
import { Vector2, Vector3 } from "three";

export default class CollisionUtil {

  /**
   *
   * @param vertices1 vertices of 1st polygon
   * @param vertices2 vertices of 2nd polygon
   * @return true if two polygons intersect
   */
  static isEdgeIntersecting(vertices1: Vector2[] | Vector3[], vertices2: Vector2[] | Vector3[]) {
    let edges1 = this.edgesFromVertices(vertices1);
    let edges2 = this.edgesFromVertices(vertices2);
    const edgeIntersects = edges1.some((edge1: Vector3[] | Vector2[]) => {
      const intersects = edges2.some((edge2: Vector3[] | Vector2[]) => {
        const edgeIntPts = this.intersectLines(edge1, edge2);
        if (edgeIntPts) return true;
        return false;
      })
      return intersects;
    })
    return edgeIntersects;
  }

  static edgesFromVertices(vertices: any[]) {
    let edges = vertices.map((pt: Vector3 | Vector2, i: number) => {
      if (i < vertices.length - 1) {
        const edgePt: any = [pt, vertices[i + 1]];
        return edgePt;
      } else {
        return [pt, vertices[0]]
      }
    });
    return edges;
  }

  static intersectLines(line1: Vector3[] | Vector2[], line2: Vector3[] | Vector2[]) {
    // ref: https://stackoverflow.com/a/60368757/6908282

    const x1 = line1[0].x, y1 = line1[0].y, x2 = line1[1].x, y2 = line1[1].y;
    const x3 = line2[0].x, y3 = line2[0].y, x4 = line2[1].x, y4 = line2[1].y;

    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
      return false
    }

    const denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

    // Lines are parallel
    if (denominator === 0) {
      return false
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      return false
    }

    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return new Vector3(x, y, line1[0] instanceof Vector3 ? line1[0].z : 0);
  }

  static isPolygonInside(polygon1: Vector3[] | Vector2[], polygon2: any[]) {
    let isOutside = polygon2.find((pt: Vector2 | Vector3, i: number) => {
      return !this.isPointInPolygon(polygon1, pt)
    });
    if (isOutside) return false;
    return !this.isEdgeIntersecting(polygon1, polygon2);

  }
  static isPointInPolygon(polygon: Vector3[] | Vector2[], point: Vector3 | Vector2, includeOnPolygon: Boolean = true) {
    // // ref: https://stackoverflow.com/a/29915728/6908282
    // // use https://www.npmjs.com/package/robust-point-in-polygon instead for better result
    // // this also check if point is exactly on boundary

    //  method 1 - manual calculation

    // let x = pointOnRoof2D.x, y = pointOnRoof2D.y;
    // let inside = false;
    // for (let i = 0, j = roofPolygon2DPoints.length - 1; i < roofPolygon2DPoints.length; j = i++) {
    //   let xi = roofPolygon2DPoints[i].x, yi = roofPolygon2DPoints[i].y;
    //   let xj = roofPolygon2DPoints[j].x, yj = roofPolygon2DPoints[j].y;

    //   let intersect = ((yi > y) != (yj > y))
    //     && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    //   if (intersect) inside = !inside;
    // }

    // method 2 using robust-point-in-polygon npm package
    type Point = [number, number];
    const points: Point[] = [];
    polygon.forEach((pt: Vector3 | Vector2) => {
      points.push([pt.x, pt.y])
    });
    // roofPolygon2DPoints.map((pt: Vector3 | Vector2) => ([pt.x, pt.y] as Point));
    const point2D: Point = [point.x, point.y];
    const checkPt = robustPointInPolygon(points, point2D);

    const ptIsOnPolygon = checkPt == 0;
    const ptIsInPolygon = checkPt == -1;
    const ptIsOutsidePolygon = checkPt == 1;
    let validPoint;
    if (includeOnPolygon) {
      validPoint = ptIsInPolygon || ptIsOnPolygon;
    } else {
      validPoint = ptIsInPolygon;
    }

    return validPoint;  // inside;
  }

}
