import { Injectable } from '@angular/core';
import Canvg from 'canvg';
import * as $ from 'jquery';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AdditionalChoiceVO } from '../modules/system-design/additionalchoice/interfaces/additional-choice-vo';
import { CPFRate } from '../modules/system-design/additionalchoice/interfaces/cpfRates-vo';
import { AddedInverterVo } from '../modules/system-design/inverter/interfaces/added-inverter-vo';
import { InverterDetailsVo } from '../modules/system-design/inverter/interfaces/inverter-details-vo';
import { InverterDetailsForDbVo } from '../modules/system-design/inverter/interfaces/inverterDetailsForDb-vo';
import { PanelConfigVO } from '../modules/system-design/panelconfig/inetrfaces/panel-config-vo';
import { AuthService } from './auth.service';
import { ConfigurationIds } from '@design/models/ui-state.model';
import { PanelArray } from '@design/models/panel-array.model';
import { take } from 'rxjs/operators';

export enum DecimalFraction {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  Four = 4
}
interface Locales {
  [key: string]: { style: string; currency: string, currencyDisplay?: string };
}
interface LocaleRepo {
  [key: string]: string
}

@Injectable()
export class Utils {
  countryCurr: any;
  measurementUnit!: string;

  localeRepo :LocaleRepo = {
    'en-IN': '₹',
    'en-AE': 'AED',
    'en-PH': '₱',
    'en-TR': '₺',
    'en-AU': 'A$',
    'en-CA': 'C$',
    'en-US': '$',
    'en-SA': 'SAR',
    'en-GB': '£',
    'en-CO': '$',
    'en-PA': '$',
    'en-ZA': 'R',
    'en-BW': 'P',
    'en-CR': '$',
    'en-SG': 'S$',
    'en-TH': '฿',
    'en-VN': 'đ',
    'en-ID': 'Rp',
    'en-GR': '€',
    'en-KE': '$',
    'en-DE': '€',
    'en-PT': '€',//
    'en-CI': 'CFA',
    'en-KW': 'KWD',
    'en-NG': 'NGN',
    'en-CM': 'FCFA',
    'en-PK': 'Rs',
    'en-OM': 'OMR',
    'en-BB': 'BDS$',
    'en-MX': '$',
    'en-ES': '€',
    'en-GT': 'Q',
    'en-CL': '$',
    'en-PE': 'PEN',
    'en-EC': '$',
    'en-CZ': 'CZK',
    'en-IT': '€',
    'en-FR': '€',
    'en-PL': 'PLN',
    'en-JP': '￥',
    'en-IL': 'ILS',
    'en-NO': 'NOK',
    'en-BD': 'BDT',
    'en-IE': '€',
    'en-EG': 'EGP',
    'en-LY': 'LYD',
    'en-RO': 'RON',
    'en-AT': '€',
    'en-CH': 'CHF',
    'en-SE': 'SEK',
    'en-FI': '€',
    'en-JO': 'JOD',
    'en-NL': '€',
    'en-DK': 'KR',
    'en-BR': 'R$',
    'en-HU': 'Ft',
    'en-LU': '€',//Lexemburg
    'en-BE': '€',
    'en-SK': '€',
    'en-HT': '$' //Haiti

  };

  countryCurrName: any;
  countryCurrencyList: Locales = {
    'en-US': {
      style: 'currency',
      currency: 'USD',
    },
    'de-DE': {
      style: 'currency',
      currency: 'EUR',
    },
    'ja-JP': {
      style: 'currency',
      currency: 'JPY',
    },
    'en-IN': {
      style: 'currency',
      currency: 'INR',
    },
    'zh-CN': {
      style: 'currency',
      currency: 'CNY',
    },
    'ko-KR': {
      style: 'currency',
      currency: 'KRW',
    },
    'it-IT': {
      style: 'currency',
      currency: 'EUR',
    },
    'fr-FR': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-CA': {
      style: 'currency',
      currency: 'CAD',
    },
    'en-AE': {
      style: 'currency',
      currency: 'AED',
      currencyDisplay: 'name',
    },
    'en-PH': {
      style: 'currency',
      currency: 'PHP',
      currencyDisplay: 'name',
    },
    'en-TR': {
      style: 'currency',
      currency: 'TRY',
      currencyDisplay: 'name',
    },
    'en-AU': {
      style: 'currency',
      currency: 'AUD',
    },
    'en-SA': {
      style: 'currency',
      currency: 'SAR',
      currencyDisplay: 'name',
    },
    'en-GB': {
      style: 'currency',
      currency: 'GBP',
    },
    'en-CO': {
      style: 'currency',
      currency: 'COP',
    },
    'en-PA': {
      style: 'currency',
      currency: 'USD',
    },
    'en-ZA': {
      style: 'currency',
      currency: 'ZAR',
    },
    'en-BW': {
      style: 'currency',
      currency: 'BWP',
    },
    'en-CR': {
      style: 'currency',
      currency: 'USD',
    },
    'en-SG': {
      style: 'currency',
      currency: 'SGD',
    },
    'en-TH': {
      style: 'currency',
      currency: 'THB',
    },
    'en-ID': {
      style: 'currency',
      currency: 'IDR',
    },
    'en-VN': {
      style: 'currency',
      currency: 'VND',
    },
    'en-KE': {
      style: 'currency',
      currency: 'USD',
    },
    'en-GR': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-DE': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-PK': {
      style: 'currency',
      currency: 'PKR',
    },
    'en-OM': {
      style: 'currency',
      currency: 'OMR',
    },
    'en-CI': {
      style: 'currency',
      currency: 'CFA',
    },
    'en-KW': {
      style: 'currency',
      currency: 'KWD',
    },
    'en-NG': {
      style: 'currency',
      currency: 'NGN',
    },
    'en-CM': {
      style: 'currency',
      currency: 'XAF',
    },
    'en-BB': {
      style: 'currency',
      currency: 'BDS',
    },
    'en-MX': {
      style: 'currency',
      currency: 'USD',
    },
    'en-ES': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-GT': {
      style: 'currency',
      currency: 'GTQ',
    },
    'en-CL': {
      style: 'currency',
      currency: 'USD',
    },
    'en-PE': {
      style: 'currency',
      currency: 'PEN',
    },
    'en-EC': {
      style: 'currency',
      currency: 'USD',
    },
    'en-CZ': {
      style: 'currency',
      currency: 'CZK',
    },
    'en-IT': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-FR': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-PL': {
      style: 'currency',
      currency: 'PLN',
    },
    'en-JP': {
      style: 'currency',
      currency: 'JPY',
    },
    'en-IL': {
      style: 'currency',
      currency: 'ILS',
    },
    'en-NO': {
      style: 'currency',
      currency: 'NOK',
    },
    'en-BD': {
      style: 'currency',
      currency: 'BDT',
    },
    'en-IE': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-EG': {
      style: 'currency',
      currency: 'EGP',
    },
    'en-LY': {
      style: 'currency',
      currency: 'LYD',
    },
    'en-RO': {
      style: 'currency',
      currency: 'RON',
    },
    'en-AT': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-CH': {
      style: 'currency',
      currency: 'CHF',
    },
    'en-SE': {
      style: 'currency',
      currency: 'SEK',
    },
    'en-FI': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-JO': {
      style: 'currency',
      currency: 'JOD',
    },
    'en-NL': {
      style: 'currency',
      currency: 'EUR',
    },
    'en-DK': {
      style: 'currency',
      currency: 'DKK',
    },

    'pt-BR': {
      style: 'currency',
      currency: 'BRL',
    },
    
    'hu-HU': {
      style: 'currency',
      currency: 'HUF',
    },
    'lb-LU': {
      style: 'currency',
      currency: 'LUF',
    },
   
    'nl-BE': {
      style: 'currency',
      currency: 'EUR',
    },

    'sk-SK': {
      style: 'currency',
      currency: 'EUR',
    },

    'fr_HT': {
      style: 'currency',
      currency: 'USD',
    },
    
  };

  usTerritories = ['US', 'PR', 'AS', 'GU', 'MP', 'VI']; // US territories  
  genabilityEnableTerritories: string[] = ['en_US', 'en_MX']; // for enabling Genability

  commaCurrFormat: string[] = ["en-ID", "en-DE", "en-AT", "en-SE", "en-FI", "en-NL", "en-DK", "en-BE", "en-IT", "en-ES"];//keep adding new locale if these are comma format number
  static roofids: Array<any> = [];
  static consumptionSolarGraphImages: any[] = [];
  static dontCallAgain: any;
  static europeanLocales: Set<string> = new Set(['de-DE', 'es-ES', 'it-IT']);
  
  constructor(
    private cookieService: CookieService,
    private authService: AuthService
  ) { }

  // To display locale specific date & time
  public localeSpecificDate(value: any, timeFlag: string = '') {
    if (value) {
      let localeId = 'en_US';
      if (this.authService.getItem('localeNumberFormate')) {
        localeId = this.authService.getItem('localeNumberFormate');
      }
      localeId = localeId.replace('_', '-');
      localeId = Utils.checkEuropeanLocale(localeId);
      const date = new Date(value);
      const datestr = date.toLocaleDateString(localeId, {
        weekday: undefined,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
  
      if (timeFlag == 'enableTime') {
        const timeStr = date.toLocaleTimeString(localeId, {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
  
        return datestr + " " + timeStr;
      }
      return datestr;
    }
    return value;
  }

  public static checkEuropeanLocale(localeId: string) {
    // Date format for all european locales should be dd.mm.yyyy i.e., similar as german
    let locale = localeId;
    if (Utils.europeanLocales.has(localeId)) {
      locale = 'de-DE'; // purpose of setting german becoz original locale creating different date format
    }
    return locale;
  }

  public static getroofId() {
    let id = 'roof:' + new Date().getTime();
    let index = Utils.roofids.findIndex((rid) => rid == id);
    while (index != -1) {
      id = 'roof:' + new Date().getTime();
      index = Utils.roofids.findIndex((rid) => rid == id);
    }
    this.roofids.push(id);
    return id;
  }

  public static getPanels(quoteData: any) {
    if (quoteData != null && quoteData.hasOwnProperty('panels')) {
      if (typeof quoteData.panels === 'string') {
        quoteData.panels = JSON.parse(quoteData.panels);
      }
      return quoteData.panels;
    }
    return [];
  }

  public static getUploadedImageUrl(quoteData: any) {
    if (quoteData != null && quoteData.hasOwnProperty('uploaded_img_url')) {
      return quoteData.uploaded_img_url;
    }
    return null;
  }

  public static getSelectedTool(quoteData: any) {
    if (quoteData != null && quoteData.hasOwnProperty('selectedTool')) {
      return Number(quoteData.selectedTool);
    }
    return null;
  }

  public static getKeepOut(quoteData: any) {
    if (quoteData != null && quoteData.hasOwnProperty('keepOuts')) {
      if (typeof quoteData.keepOuts === 'string') {
        quoteData.keepOuts = JSON.parse(quoteData.keepOuts);
      }
      return quoteData.keepOuts;
    }
  }

  public static getRoofOutline(quoteData: any) {
    if (quoteData != null && quoteData.hasOwnProperty('roofOutlines')) {
      if (typeof quoteData.roofOutlines === 'string') {
        quoteData.roofOutlines = JSON.parse(quoteData.roofOutlines);
      }
      return quoteData.roofOutlines;
    }
  }

  public static getPanelConfigObject(
    quoteData: any,
    savedModule: any,
    rackingManufaturer: any
  ) {
    const panelConfig = {} as PanelConfigVO;

    if (quoteData.hasOwnProperty('panelDegradation')) {
      panelConfig.panelDegradation = Number(quoteData.panelDegradation);
    }
    const selectedModule = quoteData.module_select;
    const selectedModel = quoteData.module_model_number;
    if (quoteData.racking) {
      // added for older quote where there is racking added
      panelConfig.rackingManufacturer = quoteData.racking.trim();
      const index = rackingManufaturer.findIndex(
        (element: any) =>
          panelConfig.rackingManufacturer == element.rackingModelName
      );
      if (rackingManufaturer && index < 0) {
        panelConfig.rackingManufacturer =
          rackingManufaturer[0].rackingModelName;
      }
    } else {
      if (rackingManufaturer && rackingManufaturer.length > 0) {
        panelConfig.rackingManufacturer = rackingManufaturer[0].rackingModelName;
      } else {
        panelConfig.rackingManufacturer = '';
      }
    }
    // For edit quote set manufactuer, model,
    for (let index = 0; index < savedModule.length; index++) {
      if (savedModule[index].manufacturerName === selectedModule) {
        panelConfig.moduleId = savedModule[index].id;
        panelConfig.modelId = savedModule[index].models[0].id;
        panelConfig.watts = Number(savedModule[index].models[0].watts);
        if (!savedModule[index].models[0]?.moduleSize)
          panelConfig.panelSize = savedModule[index].models[0].modelDimension[0].moduleSize; // if saved module doesn't have module size in object then we get it from module dimension array from first index
        else
          panelConfig.panelSize = savedModule[index].models[0].moduleSize;
        panelConfig.panelType = this.mapTechnologytoType(savedModule[index].models[0].moduleType) + "";
        panelConfig.panelHeight = savedModule[index].models[0].modelDimension[0].height;
        panelConfig.panelWidth = savedModule[index].models[0].modelDimension[0].width;
        for (
          let modelIndex = 0;
          modelIndex < savedModule[index].models.length;
          modelIndex++
        ) {
          if (savedModule[index].models[modelIndex].name === selectedModel) {
            panelConfig.modelId = savedModule[index].models[modelIndex].id;
            panelConfig.watts = Number(savedModule[index].models[modelIndex].watts);
            if (!savedModule[index].models[modelIndex]?.moduleSize)
              panelConfig.panelSize = savedModule[index].models[modelIndex].modelDimension[0].moduleSize; // if saved module doesn't have module size in object then we get it from module dimension array from first index
            else
              panelConfig.panelSize = savedModule[index].models[modelIndex].moduleSize;
            panelConfig.panelType = this.mapTechnologytoType(savedModule[index].models[modelIndex].moduleType) + "";
            panelConfig.panelHeight = savedModule[index].models[modelIndex].modelDimension[0].height;
            panelConfig.panelWidth = savedModule[index].models[modelIndex].modelDimension[0].width;
          }
        }
      }
    }
    //setting defaults selected module not found
    if (!panelConfig.moduleId) {
      panelConfig.moduleId = savedModule[0].id;
      panelConfig.modelId = savedModule[0].models[0].id;
      panelConfig.watts = Number(savedModule[0].models[0].watts);
      if (!savedModule[0].models[0]?.moduleSize)
        panelConfig.panelSize = savedModule[0].models[0].modelDimension[0].moduleSize; // if saved module doesn't have module size in object then we get it from module dimension array from first index
      else
        panelConfig.panelSize = savedModule[0].models[0].moduleSize;
      panelConfig.panelType = this.mapTechnologytoType(savedModule[0].models[0].moduleType) + "";
      panelConfig.panelHeight = savedModule[0].models[0].modelDimension[0].height;
      panelConfig.panelWidth = savedModule[0].models[0].modelDimension[0].width;
    }
    return panelConfig;
  }
  public static mapTechnologytoType(technology: string) {
    if (!technology) {
      return 0;
    }
    //this is name of the type of modules which will be mapped on to three buckets (ENV-2832)
    const standard = ['polycrystalline', 'standard Crystalline', "multi-c-si"];
    const highPerformance = ['monocrystalline', 'high performance crystalline', "mono-c-si"];
    const thinFilm = ['cigs thin film', 'hit-si thin film', 'thin film']
    const lowercasetechnology = technology.toLowerCase();
    let paneltypeIndex = 0
    if (standard.includes(lowercasetechnology)) {
      paneltypeIndex = 0;
    } else if (highPerformance.includes(lowercasetechnology)) {
      paneltypeIndex = 1;
    } else if (thinFilm.includes(lowercasetechnology)) {
      paneltypeIndex = 2;
    }
    return paneltypeIndex;
  }
  public static getInvertors(quoteObj: any, savedInvertors: any) {
    const addedInvertors: Array<AddedInverterVo> = [];
    let count = 0;
    if (quoteObj != null && quoteObj.hasOwnProperty('inverterDetails')) {
      const invertersFromDb =
        quoteObj.inverterDetails as Array<InverterDetailsForDbVo>;
      invertersFromDb.forEach(function (invertorObj) {
        const invertor: AddedInverterVo = {};
        invertor.id = `inverterGrp:${count}`;
        count++;
        const manufactuerName = invertorObj.inverter_model;
        for (
          let invertorIndex = 0;
          invertorIndex < savedInvertors.length;
          invertorIndex++
        ) {
          const eachAddedInvertor = savedInvertors[invertorIndex];
          if (eachAddedInvertor.manufacturerName === manufactuerName) {
            invertor.manufacturerId = eachAddedInvertor.id;
            const selectedModel = invertorObj.inverter_model_number;
            const quantity = invertorObj.inverter_quantity;
            const utilizedCapicaity = invertorObj.utilized_capacity;
            const invertorModelArray = eachAddedInvertor.models;
            for (
              let modelIndex = 0;
              modelIndex < invertorModelArray.length;
              modelIndex++
            ) {
              const model = invertorModelArray[modelIndex];
              if (model.name === selectedModel) {
                invertor.modelId = model.id;
                invertor.powerRating = model.powerRating;
                invertor.quantity = quantity;
                invertor.utilizedCapacity = utilizedCapicaity;
                if (model.datasheetURL) {
                  invertor.datasheetURL = model.datasheetURL;
                }
                if (model.logoURL) {
                  invertor.logoURL = model.logoURL;
                }
                if (model.notes) {
                  invertor.notes = model.notes;
                }
                addedInvertors.push(invertor);
                break;
              }
            }
            break;
          }
        }
      });
    }
    return addedInvertors;
  }

  public static getInvertorObj(quoteData: any) {
    const invererDetailObj = {} as InverterDetailsVo;
    if (quoteData.hasOwnProperty('dc_ac_ratio')) {
      invererDetailObj.dcToAcRatio = Number(quoteData.dc_ac_ratio);
    }
    if (quoteData.hasOwnProperty('inv_eff')) {
      invererDetailObj.efficiency = Number(quoteData.inv_eff);
    }
    if (quoteData.hasOwnProperty('proposedSystemSizeAC')) {
      invererDetailObj.addedACCapacity = quoteData.proposedSystemSizeAC;
    }
    return invererDetailObj;
  }

  public static getAdditionalChoiceObj(quoteData: any) {
    const additonalChoiceObj = {} as AdditionalChoiceVO;
    if (quoteData.hasOwnProperty('defalutAddChoices')) {
      if (quoteData.defalutAddChoices !== ' ') {
        additonalChoiceObj.defalutAddChoices = quoteData.defalutAddChoices;
      }
    }
    if (quoteData.hasOwnProperty('monitoring_system')) {
      if (quoteData.monitoring_system !== ' ') {
        additonalChoiceObj.monitoringSystem = quoteData.monitoring_system;
      }
    }
    if (quoteData.hasOwnProperty('derate')) {
      additonalChoiceObj.sysLosses = Number(quoteData.derate);
    }
    if (quoteData.hasOwnProperty('utility_electric_rate_escalator')) {
      additonalChoiceObj.utilityEscalator = Number(
        quoteData.utility_electric_rate_escalator
      );
    }
    if (quoteData.hasOwnProperty('custGenUpload')) {
      additonalChoiceObj.useCustomGeneration = JSON.parse(
        quoteData.custGenUpload
      );
    }
    if (quoteData.hasOwnProperty('custom_generation_format')) {
      additonalChoiceObj.customGenerationType =
        quoteData.custom_generation_format;
    }
    if (quoteData.hasOwnProperty('isNEM')) {
      additonalChoiceObj.applyNetMetering = quoteData.custom_generation_format;
    }
    if (quoteData.hasOwnProperty('dayTimeConsumption')) {
      additonalChoiceObj.dayTimeConsumption = Number(
        quoteData.dayTimeConsumption
      );
    }
    if (quoteData.hasOwnProperty('exportTariff')) {
      additonalChoiceObj.exportTariff = Number(quoteData.exportTariff);
    }
    return additonalChoiceObj;
  }

  public static getCPFDetails(rateId: any, cpfArray: Array<CPFRate>): any {
    for (let index = 0; index < cpfArray.length; index++) {
      const cpf: CPFRate = cpfArray[index];
      if (cpf.rateId === rateId) {
        return cpf;
      }
    }
  }

  public static convertUnits(value: number, reqUnit: any, inpUnit: any) {
    if (reqUnit === inpUnit) {
      return Number(value).toFixed(2);
    } else if (inpUnit === 'm' && reqUnit === 'ft') {
      return Number(value / 0.3048).toFixed(2);
    } else if (inpUnit === 'ft' && reqUnit === 'm') {
      return Number(value * 0.3048).toFixed(2);
    } else {
      return;
    }
  }

  public static svgString2API(
    divId: any,
    width: any,
    height: any,
    format: any,
    name: any,
    meter: any,
    authService: AuthService,
    systemDesignService: any
  ) {
    const that = this;
    const svgString = this.exportSVGGraphsFromId(divId);
    format = format ? format : 'png';
    const imgsrc = svgString;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const image = new Image(1, 1);
    image.onload = function () {
      if (context) {
        context.clearRect(0, 0, width, height);
        context.beginPath();
        context.rect(0, 0, width, height);
        context.fillStyle = 'White';
        context.fill();
        context.drawImage(image, 0, 0, width, height);
      }
      const canvasdata = canvas.toDataURL('image/png');
      const re = new RegExp(`^data:image\\/(png|jpg);base64,`);
      const imageString = canvasdata.replace(re, '');
      that.saveGraphImage(
        imageString,
        meter,
        name,
        authService,
        systemDesignService
      );
    };
    image.src = imgsrc;
  }

  // save chart with font
  public static async svgStringtoAPI(
    divId: any,
    width: any,
    height: any,
    format: any,
    name: any,
    meter: any,
    authService: AuthService,
    systemDesignService: any,
    detailsGraph: boolean = false

  ) {

    const that = this;
    const svgString = await this.exportSVGGraphs(divId);
    format = format ? format : 'png';
    const imgsrc = svgString;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    const image = new Image(1, 1);
    image.onload = function () {
      if (context) {
        context.clearRect(0, 0, width, height);
        context.beginPath();
        context.rect(0, 0, width, height);
        context.fillStyle = 'White';
        context.fill();
        context.drawImage(image, 0, 0, width, height);
      }
      const canvasdata = canvas.toDataURL('image/png');
      const re = new RegExp(`^data:image\\/(png|jpg);base64,`);
      const imageString = canvasdata.replace(re, '');
      if (detailsGraph)
        that.saveGraphImageForProjectDetails(imageString, meter, name, authService, systemDesignService);
      else
        that.saveGraphImage(imageString, meter, name, authService, systemDesignService);
    };
    image.src = imgsrc;
  }

  public static read_Element(ParentNode: any, OrigData: any) {
    const ContainerElements = ['svg', 'g'];
    const RelevantStyles: any = {
      rect: ['fill', 'stroke', 'stroke-width'],
      path: ['fill', 'stroke', 'stroke-width'],
      circle: ['fill', 'stroke', 'stroke-width'],
      line: ['stroke', 'stroke-width', 'mix-blend-mode'],
      text: ['fill', 'font-size', 'text-anchor', 'font-family'],
      polygon: ['stroke', 'fill'],
    };

    const Children = ParentNode.childNodes;
    const OrigChildDat = OrigData.childNodes;

    for (let cd = 0; cd < Children.length; cd++) {
      const Child = Children[cd];

      const TagName = Child.tagName;
      if (ContainerElements.indexOf(TagName) != -1) {
        this.read_Element(Child, OrigChildDat[cd]);
      } else if (TagName in RelevantStyles) {
        const StyleDef = window.getComputedStyle(OrigChildDat[cd]);

        let StyleString = '';
        for (let st = 0; st < RelevantStyles[TagName].length; st++) {
          StyleString +=
            RelevantStyles[TagName][st] +
            ':' +
            StyleDef.getPropertyValue(RelevantStyles[TagName][st]) +
            '; ';
        }

        Child.setAttribute('style', StyleString);
      }
    }
  }

  public static exportSVGGraphsFromId(divId: any) {
    $('.mouse-over-effects').attr('display', 'none');
    let image64: any;
    if (divId) {
      const Elem: any = document.querySelector('#' + divId);
      const SVGElem: any = Elem.firstElementChild;
      // const SVGElem: any
      const oDOM = SVGElem.cloneNode(true);
      this.read_Element(oDOM, SVGElem);
      const data = new XMLSerializer().serializeToString(oDOM);
      const svg64 = btoa(unescape(encodeURIComponent(data)));;
      const b64start = 'data:image/svg+xml;base64,';
      image64 = b64start + svg64;
      $('.mouse-over-effects').attr('display', 'block');
    }
    return image64;
  }

  public static async exportSVGGraphs(divId: any) {
    $('.mouse-over-effects').attr('display', 'none');
    const SVGElem: any = window.document.querySelector('#' + divId);
    const svg: any = SVGElem?.firstElementChild;
    const oDOM: any = svg?.cloneNode(true).outerHTML;
    var canvas = document.createElement('canvas');
    let context: any = canvas.getContext('2d');
    let renderedCanvas = await Canvg.from(context, oDOM);
    renderedCanvas.start();
    $('.mouse-over-effects').attr('display', 'block');
    return canvas.toDataURL('image/png');
  }

  public static saveGraphImage(
    imageString: any,
    meter: any,
    name: any,
    authService: AuthService,
    systemDesignService: any
  ) {
    const imageDetails: any = {
      meter_number: meter,
      image_type: name,
      quote_id: authService.getItem('quote_id', 'localStorage'),
      revision_number: Number(authService.getItem('revision_number', 'localStorage')),
      image_string: imageString,
    };
    systemDesignService.uploadGraphImages(imageDetails).pipe(take(1)).subscribe(
      (response: any) => {
        const res = response;
      },
      (error: any) => {
        console.log(
          'Something went wrong while fetching shadow for the keepout'
        );
      }
    );
  }


  public static saveGraphImageForProjectDetails(
    imageString: any,
    meter: any,
    name: any,
    authService: AuthService,
    systemDesignService: any
  ) {
    const imageDetails: any = {
      image_type: name,
      image_string: imageString,
    };
    let existingObj = this.consumptionSolarGraphImages.some(obj => obj.image_type === imageDetails.image_type);
    if (!existingObj)
      this.consumptionSolarGraphImages.push(imageDetails)
    if (this.consumptionSolarGraphImages.length == 5)
      this.callSaveImageAPI(systemDesignService)
  }

  public static callSaveImageAPI(systemDesignService: any) {
    let index = 0
    this.consumptionSolarGraphImages.forEach((imgD: any) => {
      systemDesignService.uploadGraphImages(imgD).pipe(take(1)).subscribe((response: any) => {
        const res = response;
        index++;
        if (index >= 5) {
          this.consumptionSolarGraphImages = [];
          index = 0;
          this.dontCallAgain = true;
        }
      }, (error: any) => {
        index++;
        if (index >= 5) {
          this.consumptionSolarGraphImages = [];
          index = 0;
          this.dontCallAgain = true;
        }
        console.log('Something went wrong while saving the graph');
      });
    })
  }

  public getAccountid() {
    let accountid = '';
    if (
      this.authService.getItem('leadOwnerAccntId') &&
      this.authService.getItem('leadOwnerAccntId') !== 'undefined'
    ) {
      accountid = this.authService.getItem('leadOwnerAccntId');
    } else {
      accountid = this.authService.getItem('account_id');
    }
    return accountid;
  }

  public getEmailId() {
    let emailId = '';
    if (
      this.authService.getItem('leadOwnerEmailId') &&
      this.authService.getItem('leadOwnerEmailId') !== 'undefined'
    ) {
      emailId = this.authService.getItem('leadOwnerEmailId');
    } else {
      emailId = this.authService.getItem('email_id');
    }
    return emailId;
  }
  public getCookies(name: string) {
    let cookie = null;
    if (this.cookieService.check(name)) {
      cookie = this.authService.getItem(name);
    }
    return cookie;
  }

  public static getBaseurl() {
      return `${environment.apiUrl}nact-backend/`;
  }
  
  public static getBackendBaseURL() {
    //if (window.location.origin.indexOf('local.') > -1) {
    //  return `http://local.enact-systems.com:8080/nact-backend/`;
    //}
    //return `${environment.apiUrl}nact-backend/`;
    return this.getBaseurl();
  }

  public static getnactUiBaseUrl() {
    //  if (window.location.origin.indexOf('local.') > -1) {
    //   return `${environment.nactUiBaseUrl}`;
    // }
    return `${window.location.origin}/nact-client/`;
    //return this.getBackendBaseURL()
  }

  public static getDocUrl() {
    var origin = window.location.origin;
    var docUrl;
    if (origin) {
      var nameSplit = origin.split('.');
      // var firstnameArray = nameSplit.shift().split("-");
      // var firstname = firstnameArray[0]+'-docs.';
      // var secondName = nameSplit.join(".");
      // docUrl = `${firstname}${secondName}/`;
    }
    return docUrl;
  }

  public callFormDataLeadQwner(obj: any) {
    if (
      this.getCookies('leadOwnerAccntId') &&
      this.getCookies('leadOwnerAccntId') != 'undefined'
    ) {
      $.extend(obj, {
        account_id: this.getCookies('leadOwnerAccntId'),
      });
    } else {
      $.extend(obj, {
        account_id: this.authService.getItem('account_id'),
      });
    }
    if (
      this.getCookies('leadOwnerEmailId') &&
      this.getCookies('leadOwnerEmailId') != 'undefined'
    ) {
      $.extend(obj, {
        email_id: this.getCookies('leadOwnerEmailId'),
      });
    } else {
      $.extend(obj, {
        email_id: this.authService.getItem('email_id'),
      });
    }
    return obj;
  }
  
  removeCommaFormatted(val: any) {
    return parseFloat(val.replace(/,/g, ''));
  }
  
  removeCommasCurrency(value: String) {
    const localeID = this.authService.getItem('localeId').replace('_', '-');
    const localeNew = 'en' + localeID.substring(2)
    if (!value) return 0
    let str: string = value?.toString();
    let commaDotRegex = /[^a-z^A-Z 0-9 . -]/g;
    let commaFormatReplace = /[^\d.-]/g;
    // let lastZeroReg= '.*[,\.]0+$';
    let lastZerosReg = /.*,0+$/;
    if (this.commaCurrFormat.includes(localeNew)) {
      if (lastZerosReg.test(str))
        str = str.replace(',00', '');
      commaDotRegex = /[^a-z^A-Z 0-9 , -]/g;
      commaFormatReplace = /[^\d,-]/g
    }
    else if (str.indexOf('.') > -1) {
      lastZerosReg = /.*.0+$/;
      if (lastZerosReg.test(str))
        str = str.replace('.00', '');
    }
    var localeList = ['en-US', 'en-IN'];
    if (localeList.indexOf(localeNew) == -1) {
      str = str.replace(commaFormatReplace, '');
    }
    const returnvalue = parseFloat(
      str.replace(commaDotRegex, '').replace('nbsp', '').replace(',', '.')
    );
    return returnvalue;
  }
  

  CommaFormatted(num: any) {
    if (isNaN(num)) return num;
    var minus = '';
    if (num < 0) {
      minus = '-';
    }
    var delimiter = ','; // replace comma if desired
    var d = '';
    if (num % 1 !== 0) {
      let a = num.toString().split('.', 2);
      d = a[1];
      num = parseInt(a[0]);
    }
    num = Math.abs(num);
    let n = new String(num);
    let a = [];
    while (n.length > 3) {
      var nn = n.substr(n.length - 3);
      a.unshift(nn);
      n = n.substr(0, n.length - 3);
    }
    if (n.length > 0) {
      a.unshift(n);
    }
    n = a.join(delimiter);
    if (d.length < 1) {
      num = n;
    } else {
      num = n + '.' + d;
    }
    num = minus + num;
    return num;
  }

  checkForRole() {
    if (
      this.authService.getItem('role') != 'installerAdmin' &&
      this.authService.getItem('role') != 'installer'
    ) {
      var locale = this.authService.getItem('localeId');
      if (!locale) return
      locale = locale?.split('_').join('-') == 'undefined' ? 'en-US' : locale?.split('_').join('-');
      const localeNew = 'en' + locale.substring(2);
      this.countryCurr = this.localeRepo[localeNew];
      this.countryCurrName = this.countryCurrencyList[locale];
    } else {
      this.countryCurr = '$';
      this.countryCurrName = {
        style: 'currency',
        currency: 'USD',
      };;
    }

    return this.countryCurr;
  }

  errorLog(errorObj: any) {
    var res = errorObj.responseJSON;
    console.error(errorObj);
  }
 
  numberFormat(number: any, ...options: any) {
    if (isNaN(number)) {
      return number;
    }
    if (options.length && typeof options[0] === 'object') {
      options = { ...options[0] };
    } else if (options.length && typeof options[0] === 'number') {
      options = { maximumFractionDigits: options[0] }  // Use default formatting options for numbers
    } else {
      // If no options or invalid options, fallback to default
      options = '';
    }
    number = number == undefined ? 0 : number;
    let locale = 'en_US';
    if (this.authService.getItem('localeNumberFormate')) {
      locale = this.authService.getItem('localeNumberFormate');
    }
    locale = locale?.replace('_', '-');

    const formattedNumber = new Intl.NumberFormat(locale, options).format(number);
    const firstNumberIndex = formattedNumber.search(/[0-9]/);
    // Intl.NumberFormat is not accepting special character in currency

    let curr = formattedNumber.substring(0, firstNumberIndex).replace(/BDS|Bds/g, 'BDS$');
    const numberFormatWithSpace = curr + ' ' + formattedNumber.substring(firstNumberIndex, formattedNumber.length);
    return numberFormatWithSpace.trim();
  }

  setMeasurementUnit(unit: string) {
    if (!unit) {
      throw new Error('Unable to set measurement unit. Unit not found.');
    }
    this.measurementUnit = unit;
  }

  convertMeterToFeet(value: number) {
    try {
      let val = (+value / 0.3048).toFixed(2);
      return Number(val)
    } catch (error) {
      console.error('Unable to convert meter to feet');
      return value;
    }
  }

  convertFeetToMeter(value: number) {
    try {
      let val = (+value * 0.3048).toFixed(2);
      return Number(val)
    } catch (error) {
      console.error('Unable to convert feet to meter');
      return value;
    }
  }
  extractNumber(fieldVal: any) {
    fieldVal = `${fieldVal}`.replace(/,/, '');
    var val = 0;
    val = parseFloat(fieldVal);
    if (isNaN(val)) val = 0;
    return val;
  }
  loginRubiconURL() {
    const rcredirectURL = `https://${environment.rubiconURL}/account`;
    let url = `${environment.urls.cognito_base_url}identity_provider=${environment.urls.cognito_identity_provider}&redirect_uri=${rcredirectURL}&response_type=TOKEN&client_id=${environment.urls.cognito_client_id}&scope=email openid`
    return url;
  }
  isDealerAdmin(): boolean {
    return this.authService.getItem('role').toLowerCase() === 'franchisee';
  }
  isDealerEmployee() {
    return this.authService.getItem('role').toLowerCase() === 'employee';
  }

   removeLeadingZero(str: any) {
    return typeof str === 'number' ? str.toString().replace(/\.00$/, '') : str.replace(/\.00$/, '');
  }
}

export const uniquesList = (arr: any, key1: string, key2: string) => Object.values(
  arr.reduce((a: any, c: any) => {
    a[c[key1] + '|' + c[key2]] = c;
    return a
  }, {}));

export const mapToAcoountAndEmailId = (arr: any) => arr.map((item: any) => ({ accountId: item.accountId, emailId: item.emailId, }));

export const hasTreeViewChanged = (updatedList: any[], defaultList: any[]) => updatedList.length < defaultList.length;

export const getDay = (month: number, day: number, year = new Date().getFullYear()) => new Date(year, month, day);

export const feetToMeter = (value: any): number =>
  +(+value * 0.3048).toFixed(2);

export const feetToInch = (value: any): number => +(+value * 12).toFixed(2);

export const feetToMiliMeter = (value: any): number =>
  +(+value * 304.8).toFixed(2);

export const meterToFeet = (value: any): number =>
  +(+value / 0.3048).toFixed(2);

export const inchToFeet = (value: any): number => +(+value / 12).toFixed(2);

export const miliMeterToFeet = (value: any): number =>
  +(+value / 304.8).toFixed(2);

export const wattTokiloWatt = (value: number): number => +(value / 1000);

export const kiloWattToMegaWatt = (value: number): number => +(value / 1000);

export const wattToMegaWatt = (value: number): number => +(value / (1000*1000));

export const calculateGridIndependence = (solarGeneration: number, solarPlusStorage: number, totalConsumption: number): number => {
  return +((((solarGeneration * solarPlusStorage) / 100) / totalConsumption) * 100).toFixed(2);
};


export const convertGenerationUnit = (generation: number, returnUnitOnly: boolean = false): string => {
  const {value, unit} = convertUnit(generation, 'GENERATION');
  let generationUnit = value.toFixed(1);
  if(returnUnitOnly) {
    return unit + 'h';
  }
  generationUnit = `${generationUnit} ${unit}h`;
  return generationUnit;
}

export const convertSystemSizeUnit = (systemSize: number, returnUnitOnly: boolean = false): string => {
  const {value, unit} = convertUnit(systemSize, 'SIZE');
  let systemSizeUnit = value.toFixed(1);
  if(returnUnitOnly) {
    return unit + 'p';
  }
  systemSizeUnit += unit + 'p';
  return systemSizeUnit;
}

export const convertUnit = (value: number, systemUnit: string): any => {
  if(systemUnit === 'SIZE') {
    if(wattTokiloWatt(value) > 999.9) {
      return {value: wattToMegaWatt(value), unit: 'MW'};
    } else {
      return {value: wattTokiloWatt(value), unit: 'kW'};
    }
  } else {
    if(value > 999.9) {
      return {value: kiloWattToMegaWatt(value), unit: 'MW'};
    } else {
      return {value: value, unit: 'kW'};
    }
  }
}

export const calculateAvoidedCostEnergy = (preBill: number, postBill: number, generation: number): number => {
  const diffPreAndPostBill = preBill - postBill;
  const billSavingPercentage = (diffPreAndPostBill / preBill) * 100;
  return +((diffPreAndPostBill / generation).toFixed(2));
}

export const calculateSolaProdRatio = (generation: number, systemSize: number): number => {
  return +((generation / systemSize).toFixed(2));
}

export const NonDisableMenuOptions = [ConfigurationIds.Battery, ConfigurationIds.Inverter, ConfigurationIds.Energy];

export const calculateHourlyPanelData = (panelArray: PanelArray, noOfPanels: number, azimuth: number): string => {
  const rowShading = 0;
  const rowTilt =
    Number(panelArray.roofTilt) +
    Number(panelArray.panelTilt);
  const arrayType = panelArray.arrayType;
  const panelDetail = '{' + noOfPanels + ',' + azimuth + ',' + rowTilt + ',' + rowShading + ',' + arrayType + ',}';
  return panelDetail;
}

export const calculateOptimunHourlyPanelData = (panelArray: PanelArray, noOfPanels: number, lat: any): string => {
  const rowShading = 0;
  let rowTilt = 0;
  let azimuth = 0;
  if(lat > 0) {
    rowTilt = +(Number(lat)).toFixed(1);
    azimuth = 180;
  } else {
    rowTilt = +(Math.abs(Number(lat))).toFixed(1);
    azimuth = 0;
  }
  const arrayType = panelArray.arrayType;
  const panelDetail = '{' + noOfPanels + ',' + azimuth + ',' + rowTilt + ',' + rowShading + ',' + arrayType + ',}';
  return panelDetail;
}

export const calculateAzimuth2 = (angle: number): number =>{
  return (angle + 180) % 360 == 0 ? 360 : (angle + 180) % 360;
}

export const isUrlAcceptingSpecialHeaders = (url: string): boolean => {
  // 3 backend services allow Datadog related headers and userIdentity headers, others will throw cors errors
  return [environment.newApiUrl,
    environment.newQuoteAPIEndpoint,
    environment.newSiteDataApiEndpoint,
    environment.s3baseurl,
    Utils.getBaseurl(),
  ].some(baseUrl => url.includes(baseUrl));
}

export function base64ToArrayBuffer(base64: any): ArrayBuffer {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export function arrayBufferToBase64(buffer: ArrayBuffer): any {
  var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}


export function percentageConverter(value: number | string = 0): number {
  // If the value is a string, attempt to parse it as a number
  if (typeof value === 'string') {
      value = parseFloat(value);

      // If parsing fails, return 0
      if (isNaN(value)) {
          return 0;
      }
  }

  // Ensure the value is a valid number after parsing
  if (typeof value !== 'number') {
      return 0;
  }

  //// handled floating point precision error
  // Multiply by 100 to shift the decimal places
  const multiplied = value * 100;

  // Floor to remove extra decimal precision
  let floored = Math.floor(multiplied * 100) / 100;

  // Check if the difference between floored and actual is less than 0.01
  if (multiplied - floored > 0.009) {
      floored = Math.round(multiplied * 100) / 100;
  }

  // Return the result
  return floored;
}
