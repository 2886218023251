import { createSelector } from "@ngrx/store";
import { selectDesign } from "../design.selector";
import { DesignState } from "../design.reducer";

export namespace fromShadingSelectors {

  export const selectShadingStatus = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.shadingStatus
  );
  export const selectShadingState = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.isShadingEnabled
  );

  export const selectHeatMapData = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.heatMapData
  );

  export const selectAllPanelsData = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.allPanelsData
  );

  export const selectPanelsYearlyData = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.panelsYearlyData
  );

  export const selectSolarThreshold = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.solarAccessThreshold
  );

  export const selectHoveredPanelId = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.hoverPanelId
  );

  export const selectHoveredPanelData = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.hoveredPanelData
  );

  export const selectDefaultMinThreshold = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.defaultMinThreshold
  );

  export const selectRoofIrradiance = createSelector(
    selectDesign,
    (state: DesignState) => state.shading?.roofIrradianceList
  );
}
