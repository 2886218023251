import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PRESET_COLORS_DEFAULT, presetColorsObject }  from '../../common/types/enactColorpick';

@Component({
  selector: 'enact-colorpicker',
  templateUrl: './enact-colorpicker.component.html',
  styleUrls: ['./enact-colorpicker.component.scss']
})
export class EnactColorpickerComponent implements OnInit {

  @Input() presetLabelName!: string;
  @Input() selectedColor!: presetColorsObject;
  @Input() enactPresetColors: presetColorsObject[] = PRESET_COLORS_DEFAULT

  @Output() valueChange = new EventEmitter<presetColorsObject>();

  enabledPopUp = false;
  
  constructor() { }

  ngOnInit(): void { }

  onChangeColor(value:any) {
    this.selectedColor = value;
    this.valueChange.emit(value)
  }

  onColorPopUp($event:any) {
    $event.stopPropagation();
    this.enabledPopUp = !this.enabledPopUp;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event:any) {
    this.enabledPopUp = false;
  }
}