import { PanelArray } from '@design/models/panel-array.model';
import { SystemDesignObject } from '@design/models/system-design-object.abstract.model';
import { ToolPanelItem } from '@design/models/tools-panel.model';
import {
  ToolSelectionIds,
  ConfigurationIds,
  ToolNames,
} from '@design/models/ui-state.model';
import { createAction, props } from '@ngrx/store';

type SelectObjectProps = { systemDesignObject: SystemDesignObject };

export namespace fromUiActions {
  export const SelectTool = createAction(
    '[ToolsPanel] select tool',
    props<{ id: ToolSelectionIds | ConfigurationIds , name:ToolNames}>()
  );
  export const SelectToolSuccess = createAction(
    '[ToolsPanel] select tool success',
    props<{ id: ToolSelectionIds | ConfigurationIds , name:ToolNames}>()
  );
  export const SelectDrawAreaObject = createAction(
    '[DrawArea] select object',
    props<SelectObjectProps>()
  );
  export const SelectObject = createAction(
    '[RightPanel] select object',
    props<SelectObjectProps>()
  );
  export const ClearSelectedToolsDetails = createAction(
    '[RightPanel] clear select tool details'
  );
  export const SelectToolDetails = createAction(
    '[ToolsPanel] SelectTool Details Success',
    props<{ toolDetails: ToolPanelItem }>()
  );
  export const SelectObjects = createAction(
    '[ToolsPanel] Selected 3D Objects Success',
    props<{ toolDetails: ToolPanelItem }>()
  );
  export const SelectPanelArray = createAction(
    '[RightPanel] select panel array',
    props<{panelArray:PanelArray}>()
  );
  export const ClearSelection = createAction('[LeftPanel] clear selection',  (shouldClear: boolean = true) => ({ shouldClear }));
  export const ClearObjectsSelection = createAction('[3D] clear object selection',  (shouldClear: boolean = true) => ({ shouldClear }));
  export const ClearSelectionSuccess = createAction('[LeftPanel] clear selection success');
  export const ClearSelectionSuccessWithoutUpdate = createAction('[LeftPanel] clear selection success without update');
  export const RightPanelTemplateUpdate = createAction(
    '[RightPanelComponentLoaderDirective] update template',
    props<SelectObjectProps>()
  );
  export const setState = createAction('[UI state] change buttons state',props<{ active:boolean}>());

  export const updateClearState = createAction('[UI state] update clear stare',props<{ clearState:boolean}>());
  
  export const setObjectId = createAction('[UI state] update the object id',props<{ id:any,selectedConfigurationId:any,toolId:any,name:any,toolDetails:any}>());
  
  export const DisableToolPanelForNoCustomImage = createAction(
    '[[ToolsPanel] Disable tool panels if no custom image uploaded',
    props<{disableFlag: boolean}>()
  )


}
