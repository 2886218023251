import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'src/app/helpers/auth.service';

@Pipe({
  name: 'loginStatus',
  pure: true
})
export class LoginStatusPipe implements PipeTransform {

    constructor(private authService: AuthService) { }

    transform(value: any): boolean {
        const isLoggedIn = this.authService.isLoggedIn();
        return isLoggedIn;
    }

}