import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy, inject } from "@angular/core";
import { Observable, Subject, isObservable, of, throwError } from "rxjs";
import { catchError, finalize, shareReplay, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class ServicesOfferedService implements OnDestroy{

  private cache = new Map<string, Observable<any>>();
  private ongoingRequests = new Map<string, Observable<any>>();
  private http = inject(HttpClient);

  getServicesOffered<T>(data:{account_id: string, email_id: string, parent: string, list?:string}, options: {disableCache:boolean} = {disableCache:false}): Observable<T> {
    if(!data.list){
      data.list= 'services_offered';
    }
    const url = `${environment.newApiUrl}service?account_id=${data.account_id}&email_id=${data.email_id}&list=${data.list}&parent=${data.parent}`
    const cacheKey =  url;
    const disableCache = options.disableCache || false;
    
    const cached = this.cache.get(cacheKey);
    if (!disableCache && cached && isObservable(cached)) {
      return cached as Observable<T>;
    }

    // Check if the request is already ongoing
    const ongoing = this.ongoingRequests.get(cacheKey);
    if (ongoing && isObservable(ongoing)) {
      return this.ongoingRequests.get(cacheKey) as Observable<T>;
    }
    
    const request = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError((error) => {
        this.ongoingRequests.delete(url);
        return throwError(error);
      }),
      finalize(() => {this.ongoingRequests.delete(cacheKey);})
    );

    this.ongoingRequests.set(cacheKey, request);
    request.subscribe(response => {
      this.cache.set(cacheKey, of(response));
    });

    return request;  
  }

  clearCache(){
    this.cache.clear();
  }

  ngOnDestroy(){
    this.clearCache();
  }
}