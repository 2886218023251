import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { ProjectsService } from '../projects.service';
import { Utils } from 'src/app/helpers/utils';

@Component({
  selector: 'app-web-view',
  templateUrl: './web-view.component.html',
  styleUrls: ['./web-view.component.scss']
})
export class WebViewComponent implements OnInit {

  constructor(private routeParams: ActivatedRoute,public projectsService: ProjectsService) { }

  public path :any;
  pdfSrc!: string;
  data:any;
  customerName:any;
  quoteid:any;
  ngOnInit(): void {
    this.downLoadFile();
  }
  downLoadFile(){
    this.routeParams.params.subscribe((params:any) => {
      
    this.path = (params['path']);
    this.quoteid=params['id']
    this.customerName=params['name']
    let fileUrl =
    `${Utils.getBackendBaseURL()}documents/downloadfile?path=reports/`+this.path;
      
    this.projectsService.downloadFile(fileUrl).subscribe((res:any) => {
    this.data = res;
      this.pdfSrc = URL.createObjectURL(res);
    });

    });
    this.routeParams.queryParams.subscribe((params:any) => {
      this.quoteid=params['id'];
      this.customerName=params['name'];
      });
    
    
  }

  downloadPdf(){
    if(this.customerName && this.quoteid){
      const filename = `${this.customerName}_${this.quoteid}.pdf`;
      saveAs(this.data, filename);
    }else{
      saveAs(this.data, this.path);
    }
  }


}
