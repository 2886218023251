import { CookieService } from 'ngx-cookie-service';
import { hasTreeViewChanged, mapToAcoountAndEmailId, uniquesList, Utils } from 'src/app/helpers/utils';

import { NestedTreeControl } from '@angular/cdk/tree';
import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { AuthService } from '../../helpers/auth.service';
import { ProjectsService } from '../projects/projects.service';
import { DashboardLineChartComponent } from '../shared/charts/dashboard-line-chart/dashboard-line-chart.component';
import { PieChartComponent } from '../shared/charts/pie-chart/pie-chart.component';
import { ExampleHeaderComponent } from './example-header/example-header.component';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProjectListDialogComponent } from '../shared/project-list-dialog/project-list-dialog-component';
import { ProjectListGraph, RequestPayload } from 'src/app/models/sub-graph.model';
import { isError, isSuccess } from 'src/app/models/simple.types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PROJECT_LIST_COLUMNS } from '../shared/table/table.model';
import { ToastrService } from 'ngx-toastr';
import { RecentQuote } from '@api/models/quote-recent.model';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

interface VehicleNode {
  display: string;
  id?: number;
  ChildrenItems?: VehicleNode[];
  selected?: boolean;
  indeterminate?: boolean;
  parent?: VehicleNode;
  account_id?: string;
  email_id?: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit, OnDestroy {
  readonly ExampleHeaderComponent = ExampleHeaderComponent;
  public treeControl = new NestedTreeControl<VehicleNode>(
    (node) => node.ChildrenItems
  );
  public dataSource = new MatTreeNestedDataSource<VehicleNode>();
  @ViewChild('addr1') public searchElementRef: ElementRef | any;
  @ViewChild('picker') datePicker: MatDatepicker<Date> | any;

  isTouchUIActivated = false;
  oldAppUrl = '';
  pageName = 'corporate.html#page/sp_index';
  finalMembersData: any = [];
  teamMembers: any;
  selectMemberData: any;
  accountId: any;
  userRole: any;
  emailId: any;
  showButton: any;
  userParent: any;
  orgLogo: any;
  allAccount: any = [];
  projectList: any = [];
  recentQuotes!: RecentQuote[];
  workflowdata: any = [];
  selectedWorkflow: any = 'All_Workflows';
  allComplete: boolean = false;
  showAllAccout: any = [];
  StartDate: any = '';
  EndDate: any = '';
  selctionWay: any = 'count';
  daterange: any = '11-05-2020';
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  subscriptions = new Subscription();

  show: boolean = false;
  dateStart: any;
  dateEnd: any;
  lineChartLoad: number = 0;
  userInfo: any;
  finalList: any = [];
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private projectsService: ProjectsService,
    public utils: Utils,
    private authService: AuthService,
    private pieComponent: PieChartComponent,
    private LineChart: DashboardLineChartComponent,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private translate : TranslateService
  ) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.userInfo = this.authService.getItem('username');
    this.subscriptions.add(this.projectsService.onPieChartClick$.subscribe(status => this.onPieChartClick(status)));
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
  hasChild = (_: number, node: VehicleNode) =>
    !!node.ChildrenItems && node.ChildrenItems.length > 0;

  setParent(node: VehicleNode, parent: any) {
    this.treeControl.expand(node);
    node.parent = parent;
    if (node.ChildrenItems) {
      node.ChildrenItems.forEach((childNode) => {
        this.setParent(childNode, node);
      });
    }
  }

  onChange() {
    this.datePicker.open();
    this.show = true;
  }

  checkAllParents(node: VehicleNode) {
    if (node.parent) {
      const descendants = this.treeControl.getDescendants(node.parent);
      node.parent.selected = descendants.every((child) => child.selected);
      node.parent.indeterminate = descendants.some((child) => child.selected);
      this.checkAllParents(node.parent);
    }
  }

  itemToggle(checked: boolean, node: VehicleNode) {
    this.selectedWorkflow = 'All_Workflows'
    this.projectsService.selectedWorkflow = this.selectedWorkflow;
    this.projectsService.checked = checked
    node.selected = checked;
    if (!!node.ChildrenItems && node.ChildrenItems?.length) {
      node.ChildrenItems.forEach((child) => {
        this.itemToggle(checked, child);
      });
    } else {
      this.checkAllParents(node);
    }
  }

  onFilterChange() {
    let result = this.dataSource.data.reduce(
      (acc: any[], node: VehicleNode) =>
        acc.concat(this.treeControl
          .getDescendants(node)
          .filter(descendant => descendant.selected)
          .map(descendant => ({ accountId: descendant.account_id, emailId: descendant.email_id })))
      , [] as any);
    this.finalList = uniquesList(result, 'accountId', 'emailId');
    this.callWorkflowAndStatus();
  }

  callWorkflowAndStatus() {
    this.getWorkflow(true);
    this.pieComponent.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      this.StartDate,
      this.EndDate
    );
    this.LineChart.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      this.StartDate,
      this.EndDate
    );
  }

  ngOnInit(): void {
    this.getTeamMembers();
    this.getRecentQuotes();
    this.getListOfProjects();

    let createDate: any = localStorage.getItem('createtime');
    let startDay: any = new Date(parseInt(createDate)).toString();
    startDay = new Date(startDay);

    let today = new Date();
    this.StartDate = startDay.getTime();
    this.EndDate = today.getTime();
    this.range.patchValue({
      start: startDay,
      end: today,
    });
  }

  @HostListener('window:message', ['$event']) goToSystemDesignPage(
    $event: MessageEvent
  ) {
    // TODO: Need to check if message came from own domain only then entertain that message
    if ($event.data.name === 'LeadDetails') {
      const sentData = $event.data.data;
      const crn = sentData.crn;
      this.router.navigate(['projects', 'details'], { queryParams: { crn } });
    }
  }
  getTeamMembers() {
    this.subscriptions.add(
      this.projectsService.getTeamMembers().subscribe(
        (resp: any) => {
          this.teamMembers = resp;

          this.teamMembers.forEach((element: any) => {
            element.show = true;
            element.selected = true;
            this.allAccount.push({
              accountId: element.account_id,
              emailId: element.email_id,
            });
            this.showAllAccout.push({
              accountId: element.account_id,
              emailId: element.email_id,
              name: element.display,
            });
            element.ChildrenItems.forEach((data: any) => {
              data.selected = true;
              this.allAccount.push({
                accountId: data.account_id,
                emailId: data.email_id,
              });
              this.showAllAccout.push({
                accountId: data.account_id,
                emailId: data.email_id,
                name: data.display,
              });
              if (data.ChildrenItems) {
                data.ChildrenItems.forEach((child: any) => {
                  child.selected = true;
                  child.show = true;
                  this.allAccount.push({
                    accountId: child.account_id,
                    emailId: child.email_id,
                  });
                  this.showAllAccout.push({
                    accountId: child.account_id,
                    emailId: child.email_id,
                    name: child.display,
                  });
                });
              }
            });
          });
          this.dataSource.data = this.teamMembers;
          this.finalList = [...uniquesList(this.showAllAccout, 'accountId', 'emailId')];
          this.allAccount = [...uniquesList(this.allAccount, 'accountId', 'emailId')];
          this.projectsService.defaultAccountCount = this.finalList;
          Object.keys(this.dataSource.data).forEach((key) => {
            return this.setParent(this.dataSource.data[key], null);
          });
          this.getWorkflow();
          this.finalMembersData = this.teamMembers;
        },
        (err: any) => {
          this.teamMembers = [];
          this.finalMembersData = [];
        }
      )
    );
  }

  getRecentQuotes() {
    this.subscriptions.add(
      // this.projectsService.getRecentQuotes().subscribe(
      //   (resp: any) => {
      //     this.recentQuotes = resp;
      //   },
      //   (err: any) => {}
      // )
      this.projectsService.getRecentQuotesV2().subscribe((res) => isSuccess(res.status) ? this.recentQuotes = res.data : this.toastr.error(res.error))
    );
  }

  getWorkflow(isFilterSelection = false) {
    const reqBody: any = {
      accountId: this.authService.getItem('account_id'),
      role: this.authService.getItem('user_type'),
      emailId: this.authService.getItem('email_id'),
      allAccounts: !isFilterSelection ? this.allAccount : this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
    };

    this.subscriptions.add(
      this.projectsService.getWorkflow(reqBody).subscribe(
        (resp: any) => {
          this.workflowdata = resp;
        },
        (err: any) => {}
      )
    );
  }

  getListOfProjects(): void {
    this.projectList = [];
    let reqBody: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id'),
      page: 1,
      size: 5,
      sort_col_name: 'last_update',
      sort_direction: 'dsc',
      filters: [],
    };
    this.subscriptions.add(
      this.projectsService.getListOfProjects(reqBody).subscribe((resp: any) => {
        this.projectList = resp.projects;
      })
    );
  }

  onSelect(item: any) {
    this.accountId = item.account_id;
    this.emailId = item.email_id;
    this.userRole = item.role;
    this.authService.setItem('manage_account_id', this.accountId);
    this.authService.setItem('manage_email_id', this.emailId);
    this.authService.setItem('manage_role', this.userRole);
  }

  searchFunction(ev: any) {
    let filteredTreeData: any;
    let filterText = ev.target.value;
    if (filterText) {
      function filter(array: any[], text: string | RegExp) {
        const getChildren = (
          result: any,
          object: { display: string; ChildrenItems: any[] }
        ) => {
          var re = new RegExp(text, 'gi');
          if (object.display.match(re)) {
            result.push(object);
            return result;
          }
          if (Array.isArray(object.ChildrenItems)) {
            const ChildrenItems = object.ChildrenItems.reduce(getChildren, []);
            if (ChildrenItems.length) result.push({ ...object, ChildrenItems });
          }

          return result;
        };
        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.teamMembers, filterText);
      // this.treeControl.expand(filteredTreeData);
    } else {
      // Return the initial tree
      filteredTreeData = this.teamMembers;
    }
    const data = filteredTreeData;
    this.dataSource.data = filteredTreeData;
    Object.keys(this.dataSource.data).forEach((key) => {
      return this.setParent(this.dataSource.data[key], null);
    });
    // this.treeControl.expandAll();
  }

  toggleTeamMemberVertical() {
    $('#teamMembrsVertical').hide();
    $('#colTwoForExpand').show('slow');
    $('.panelClass').css('margin-left', '18%');
    $('#main-container-div,#example,#mageUsersCreate,#main-container-div')
      .removeClass('col-sm-12')
      .addClass('col-sm-10')
      .css('margin-left', '0px');
  }
  toggldeTeamMember() {
    $('#colTwoForExpand').hide('slow');
    $('#main-container-div,#example,#mageUsersCreate,#main-container-div')
      .removeClass('col-sm-10')
      .addClass('col-sm-12')
      .css('margin-left', '21px');
    $('.panelClass').css('margin-left', '4%');
    $('#teamMembrsVertical').show();
  }
  showMember(team: any, val: any, type: any) {
    if (type == 'parent') {
      this.teamMembers.forEach((element: any) => {
        if (team == element.display) {
          element.show = val;
        }
      });
    } else {
      this.teamMembers.forEach((element: any) => {
        element.ChildrenItems.forEach((child: any) => {
          if (team == child.display) {
            child.show = val;
          }
        });
      });
    }

    this.finalMembersData = this.teamMembers;
  }
  filter(arr: any, s: any) {
    return JSON.parse(JSON.stringify(arr)).filter((f: any) => {
      if (f.ChildrenItems?.length) {
        f.ChildrenItems = f.ChildrenItems.filter((ff: any) => {
          if (ff.ChildrenItems?.length) {
            ff.ChildrenItems = ff.ChildrenItems.filter((fff: any) => {
              return (
                fff.ChildrenItems?.length ||
                fff?.display?.toLowerCase().includes(s)
              );
            });
          }
          return (
            ff.ChildrenItems?.length || ff?.display?.toLowerCase().includes(s)
          );
        });
      }
      return f.ChildrenItems?.length || f?.display?.toLowerCase().includes(s);
    });
  }
  act_dept() {
    $('#deptdiv').css('visibility', 'visible');
    $('#departmentKey').val('');
    $('#departmentKey').prop('disabled', false);
    $('#departmentKey').css('visibility', 'visible');
  }
  deact_dept() {
    $('#deptdiv').css('visibility', 'hidden');
    $('#departmentKey').val('');
    $('#departmentKey').prop('disabled', true);
    $('#departmentKey').css('visibility', 'hidden');
  }
  setLogoImage(formData: any) {
    var url = `${environment.newApiUrl}campaign-images/`;

    this.subscriptions.add(
      this.projectsService.readImage(formData).subscribe(
        (res: any) => {
          if (typeof res.imageId != 'undefined') {
            this.projectsService
              .getLogo(res.imageId).pipe(take(1))
              .subscribe((data: string) => {
                $('#set_logo').html(
                  "<img id='theImg' height='36px' style='' src='data:image/jpg;base64," +
                    data +
                    "'/>"
                );
              });
          } else {
            $('#set_logo').text('No logo available');
          }
        },
        function (error: any) {}
      )
    );
  }
  onTabChangedGraph(ev: any) {}

  quoteData(data: any) {
    let fileUrl =
      `${Utils.getBackendBaseURL()}documents/downloadfile?path=reports/` +
      data.crn +
      '_' +
      data.quoteId +
      '_' +
      data.revisionNumber +
      '.pdf';

    this.subscriptions.add(
      this.projectsService.downloadFile(fileUrl).subscribe((res) => {
        var file = new Blob([res], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      })
    );
  }
  workflowChange(val: any) {
    this.projectsService.selectedWorkflow = val.value === 'All_Workflows' ? val.value : val?.value?.account_id + ':' + val?.value?.workflowName + ':' + val?.value?.email_id;
    this.pieComponent.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      this.StartDate,
      this.EndDate
    );
    this.LineChart.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      this.StartDate,
      this.EndDate
    );
  }
  updateAllComplete() {
    this.allComplete =
      this.teamMembers.ChildrenItems != null &&
      this.teamMembers.ChildrenItems.every(
        (t: { completed: any }) => t.completed
      );
  }

  ByChange(val: any) {
    let data = val.value;
    this.pieComponent.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      new Date(this.range.get('start')?.value).getTime(),
      new Date(this.range.get('end')?.value).getTime()
    );
    this.LineChart.getStatusAndSource(
      this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
      this.selctionWay,
      new Date(this.range.get('start')?.value).getTime(),
      new Date(this.range.get('end')?.value).getTime()
    );
  }
  dateRangeChange(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    this.dateStart = new Date(dateRangeStart.value).getTime();
    this.dateEnd = new Date(dateRangeEnd.value).getTime();
    if (this.dateStart && this.dateEnd) {
      this.pieComponent.getStatusAndSource(
        this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
        this.selctionWay,
        this.dateStart,
        this.dateEnd
      );
      this.LineChart.getStatusAndSource(
        this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
        this.selctionWay,
        this.dateStart,
        this.dateEnd
      );
    }
  }

  tabClick(ev: any) {
    if (ev.index == '1') {
      this.lineChartLoad += 1;
    }
  }

  onPieChartClick(selectedProject: string) {
    const payload: RequestPayload = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('user_type'),
      email_id: this.authService.getItem('email_id'),
      status: selectedProject,
      startTime: new Date(this.range.get('start')?.value).getTime(),
      endTime: new Date(this.range.get('end')?.value).getTime(),
      workFlowChanged: this.projectsService.selectedWorkflow !== 'All_Workflows',
      selectedWorkFlow: this.projectsService.selectedWorkflow,
      treeViewChanged: hasTreeViewChanged(this.finalList, this.projectsService.defaultAccountCount),
      accountsAndEmailFromTreeView: this.finalList?.length ? mapToAcoountAndEmailId(this.finalList) : [],
    };
    this.subscriptions.add(
      this.projectsService.getProjectList(payload)
        .subscribe(response =>
          isError(response.status) ? this.showError(response.error) : this.openModel(selectedProject, response.data))
          )
  }

  openModel(selectedProject: string, projectList: ProjectListGraph[]) {
    const dialogRef = this.dialog.open(ProjectListDialogComponent, {
      height: !projectList.length ? '200px': 'auto',
      data: { header: selectedProject, projectList: projectList, columns: PROJECT_LIST_COLUMNS,isPagination:true}
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(() =>console.log('The dialog was closed'));
  }

  showError(error: any) {
    let snackMsg: any;
    this.translate.get(['toastrMsgs.pleaseTryLater','buttons.ok']).pipe(take(1)).subscribe((res: any) => {
      snackMsg = res;
    })
    let sb = this.snackBar.open(snackMsg['toastrMsgs.pleaseTryLater'], snackMsg['buttons.ok'].toUpperCase(), {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['light'],
    });
    sb.onAction().pipe(take(1)).subscribe(() => {
      sb.dismiss();
    });
  }
}
