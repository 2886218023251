//engage admin
import { CommonModule, DatePipe, LOCATION_INITIALIZED } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, Injector, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EngageAdminComponent } from './engage-admin/engage-admin/engage-admin.component';
import { NovaDialogComponent } from './helpers/components/nova-dialog/nova-dialog.component';
import { LoaderInterceptorService } from './helpers/interceptors/loader.interceptor';
import { TokenInterceptor } from './helpers/interceptors/token.interceptor';
import { LocaleService } from './helpers/locale.service';
import { SafePipe } from './helpers/pipes/safepipe';
import { Utils } from './helpers/utils';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { CustomRangePanelComponent } from './modules/dashboard/example-header/custom-range-panel.component';
import { ExampleHeaderComponent } from './modules/dashboard/example-header/example-header.component';
import { BarChartComponent } from './modules/graphs/bar-chart/bar-chart.component';
import { LoginComponent } from './modules/login/login.component';
import {
  SharedModule,
} from './modules/shared/shared.module';
import { RoofService } from './modules/system-design/roof/roof.service';
import { AccountComponent } from './modules/account/account.component';
import { MatSelectFilterModule } from 'mat-select-filter';
import { ApiManagementModule } from '@api/api-management.module';
import { QuotesDetailsAPI } from './services/api/quotes/details';
import { QuotesFinanceAPI } from './services/api/quotes/finance';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { DesignLoaderComponent } from '@design/design-loader/design-loader.component';
import { ApplicationInitializerFactory, HttpLoaderFactory } from './translation-config';
import { LoginStatusPipe } from './modules/shared/pipes/login-status.pipe';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { UnauthorizedComponent } from './modules/login/unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SafePipe,
    BarChartComponent,
    DashboardComponent,
    EngageAdminComponent,
    ExampleHeaderComponent,
    CustomRangePanelComponent,
    NovaDialogComponent,
    AccountComponent,
    DesignLoaderComponent,
    LoginStatusPipe,
    UnauthorizedComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    FlexLayoutModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      closeButton: true,
      positionClass: 'toast-top-center',
      timeOut: 5000,
      preventDuplicates: true,
      resetTimeoutOnDuplicate:true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSkeletonLoaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatRippleModule,
    MatDialogModule,
    MatSelectFilterModule,
    ApiManagementModule,
    CommonModule,
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
          maxAge: 100, // Retains last 100 states
          //logOnly: isDevMode(), // Restrict extension to log-only mode
       }),
    EffectsModule.forRoot([]),
    NgIdleKeepaliveModule.forRoot(),

  ],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: (localeService: { locale: string }) => localeService.locale,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ApplicationInitializerFactory,
      deps: [TranslateService,Injector],
      multi: true
    },
    Utils,
    DatePipe,
    RoofService,
    QuotesDetailsAPI,
    QuotesFinanceAPI
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}