import { Expose, Transform } from 'class-transformer';

export enum BatteryControlStrategy {
  SelfConsumption = 'self-consumption',
  TimeBasedDischarge = 'time-based-discharge',
}

export enum BatteryCoupling {
  AC = 'AC',
  DC = 'DC',
}

export class Battery {
  recentlyUsed: boolean = false;
  id!: string;
  description!: string;
  Description!: string;
  notes!: string;
  unitCost!: string;
  lastUpdate!: string;
  totalRatedCapacity!: number;
  coupling!: BatteryCoupling;
  reserve!: number;
  totalAvailableCapacity!: number;
  totalUsableCapacity!: number;
  totalPowerOutput!: number;
  isSgip!: boolean;
  controlStrategy!: BatteryControlStrategy;
  dischargeIntervals: any[] = [];
  @Transform(({ obj }) => obj.quantity || obj.numberOFBattery)
  @Expose({ name: 'quantity' })
  numberOFBattery!: number;
  @Transform(({ obj }) => obj.name || obj.batteryModelnumber || obj.BatteryModelnumber)
  @Expose({ name: 'name' })
  batteryModelnumber!: string;
  @Transform(({ obj }) => obj.manufacturerName || obj.ManufacturerName)
  @Expose({ name: 'manufacturerName' })
  manufacturerName!: string;
  @Transform(({ obj }) => obj?.Config?.NamePlateCapacity)
  @Expose({ name: 'NamePlateCapacity' })
  namePlateCapacity!: string;
  @Transform(({ obj }) => obj.BatteryDegradation || obj.batteryDegradation)
  @Expose({ name: 'BatteryDegradation' })
  batteryDegradation?: number;
  @Expose({ name: 'ChargeEfficiency' })
  chargeEfficiency?: number;
  @Expose({ name: 'DischargeEfficiency' })
  dischargeEfficiency?: number;
  @Transform(({ obj }) => obj.MaxChargePower || obj.maxChargePower)
  @Expose({ name: 'MaxChargePower' })
  maxChargePower!: number;
  @Expose({ name: 'MaxDepthOfDischarge' })
  maxDepthOfDischarge?: number;
  @Expose({ name: 'MaxDischarge' })
  maxDischarge?: number;
  @Expose({ name: 'datasheetURL' })
  datasheetUrl!: string;
  @Expose({ name: 'logoURL' })
  logoUrl!: string;
}

export const getBatteryCapacity = (battery:Battery|any ) => {
  const namePlateCapacity = battery.Config.NamePlateCapacity;
  const numberOfBattery = battery.numberOFBattery || 1;
  const reserve = battery.reserve;
  const maxDepthOfDischarge:any = battery.Config.MaxDepthOfDischarge?battery.Config.MaxDepthOfDischarge:100;
  const totalRatedCapacity = +((battery.Config.NamePlateCapacity*numberOfBattery).toFixed(2));
  const totalUsableCapacity = +((+namePlateCapacity*(+maxDepthOfDischarge/100.0) * numberOfBattery).toFixed(2)) 
  const totalAvailableCapacity = +((+totalUsableCapacity*(1.0-reserve/100.0)).toFixed(2));
  const totalPowerOutput = +((battery.Config.MaxChargePower).toFixed(2));
  return{totalAvailableCapacity,totalUsableCapacity,totalPowerOutput,totalRatedCapacity};
}
