import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PanelConfigurationService } from '@api/services/panel-configuration.service';
import { ArrayTypes } from '@design/models/arrayType.model';
import { PanelArray } from '@design/models/panel-array.model';
import { PanelTypes } from '@design/models/panelType.model';
import { QuoteDesign } from '@design/models/quote-design.model';
import { QuoteHardware } from '@design/models/quote-hardware';
import { Quote } from '@design/models/quote.model';
import { BehaviorSubject, EMPTY, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/helpers/auth.service';
import { Utils } from 'src/app/helpers/utils';
import { environment } from 'src/environments/environment';
export interface QuotesDetailsReadParams {
  crn: string;
  quoteId: string;
  revisionNumber: number;
  qrn:string;
}

@Injectable({ providedIn: 'root' })
export class QuotesDetailsApi {
  private readonly endpoint = 'quotes/details';
  private getUploadLink$?: Subject<void>;
  private updateQuoteHardware$?: Subject<void>;
  private updateQuoteDesign$?: Subject<void>;
  private saveDataToS3$?: Subject<void>;
  private projectDetails$ = new Subject<any>();
  private updateQuote$?: Subject<void>;
  private dataFromDesign = {
    systemSize:0,
    generation:0,
    quote_id :null,
    revision_number:null
  }
  private dataForProjectDetails : Subject<any> = new Subject<any>(); 

  constructor(
    private http: HttpClient, private authService: AuthService,
    private panelConfigurationService: PanelConfigurationService
  ) {}


  fetchQuote(readParams: QuotesDetailsReadParams){
   
    return this.http.get<Quote>(`${environment.newQuoteAPIEndpoint}quote/getQuoteByQrn/${readParams.qrn}`);

  }
  fetchQuoteHardware(quote: Quote){
    return this.http.get<QuoteHardware>(`${environment.newQuoteAPIEndpoint}quote/hardware/${quote.qrn}`);
  }
  fetchQuoteDesign(quote: Quote){
    return this.http.get<QuoteDesign>(`${environment.newQuoteAPIEndpoint}quote/design/${quote.qrn}`);
  }

  postQuoteData(quote: Quote){
    return this.http.post<Quote>(`${environment.newQuoteAPIEndpoint}quote`,quote);
  }

  read(readParams: QuotesDetailsReadParams) {
    let params = new HttpParams();

    if (readParams.crn) {
      params = params.append('crn', readParams.crn);
    }

    if (readParams.quoteId) {
      params = params.append('quote_id', readParams.quoteId);
    }

    if (readParams.revisionNumber) {
      params = params.append(
        'revision_number',
        readParams.revisionNumber.toString()
      );
    }

    return this.http.get<Quote>(`${environment.newApiUrl}` + this.endpoint, {
      params,
    });
  }

  calculateSize(panelArr :PanelArray){
    return (panelArr.noOfPanelEast + (panelArr.noOfPanelWest ? panelArr.noOfPanelWest : 0)) * panelArr.wattsPerModule;
  }

  updateQuoteHardware(updatedHardware: any){
    updatedHardware = {...updatedHardware, qrn: this.authService.getItem('qrn', 'localStorage')};
    return this.http.put(`${environment.newQuoteAPIEndpoint}quote/hardware`, updatedHardware);
  }

  updateQuoteDesign(design:any){
    

    design = {...design, qrn: this.authService.getItem('qrn', 'localStorage')};
    return this.http.put(`${environment.newQuoteAPIEndpoint}quote/design`, design);
    
  }

  updateQuote(quote:any){

   

    quote = {...quote, qrn: this.authService.getItem('qrn', 'localStorage')};
    return this.http.put(`${environment.newQuoteAPIEndpoint}quote`, quote);
  }

  getUploadLink(model:any):Observable<any>{
    /// cancel previous api call
    this.getUploadLink$?.next();
    this.getUploadLink$?.complete();
    this.getUploadLink$ = new Subject<void>();

    let qrn={
      qrn:this.authService.getItem('qrn', 'localStorage')
    }

    return this.http.post(`${environment.s3baseurl}site/3d-object`,qrn).pipe(
      takeUntil(this.getUploadLink$),
      map((res: any) => {
        const data = {...res.data};
        data['model']=model;
        return data;
      })
    );
  }

  saveDataToS3(responseFromApi: any): Observable<any> {
    /// cancel previous api call
    this.saveDataToS3$?.next();
    this.saveDataToS3$?.complete();
    this.saveDataToS3$ = new Subject<void>();

    const form = new FormData();
    form.append('key', responseFromApi.fields.key);
    form.append('AWSAccessKeyId', responseFromApi.fields.AWSAccessKeyId);
    form.append('x-amz-security-token', responseFromApi.fields['x-amz-security-token']);
    form.append('policy', responseFromApi.fields.policy);
    form.append('signature', responseFromApi.fields.signature);
    const blob = new Blob([JSON.stringify(responseFromApi.model)], { type: 'application/plain' });
    const tmpFile = new File([blob], 'data_object_example.json');

    form.append('file', tmpFile);

    return this.http.post(responseFromApi.url, form).pipe(
      takeUntil(this.saveDataToS3$)
    );
  }

  getObjectFromS3():Observable<any>{

    let qrn=this.authService.getItem('qrn', 'localStorage')
     
     return this.http.get(`${environment.s3baseurl}site/3d-object?qrn=`+qrn)

  }

  getModules():Observable<any>{
    return this.http.get(`${environment.newApiUrl}hardware/module/selected-v2?account_id=`+this.authService.getItem("account_id")+`&email_id=`+this.authService.getItem("email_id"))
  }
  getPVWattsHourly(obj:any,currentConsumption:any,system_size:any):Observable<any>{
    const apiUrl = `${Utils.getBackendBaseURL()}pvwattsPanels/hourly`;
    return this.http.post(apiUrl, obj).pipe(
      map((res: any) => {
        if(!res.rc){
          const data = {...res};
          data['current_annual_consumption']=currentConsumption;
          data['system_size']=system_size;
          this.dataFromDesign.systemSize = system_size;
          this.dataFromDesign.generation = data.annual_consumption;
          this.dataFromDesign.quote_id =this.authService.getItem('quote_id');
          this.dataFromDesign.revision_number = this.authService.getItem('revision_number');
          this.dataForProjectDetails.next(this.dataFromDesign);
          return data;
        }else{
          throw throwError(res);  
        }
        
      })
    );

  }

  getHourlyClone(obj:any,currentConsumption:any,system_size:any):Observable<any>{
    const apiUrl = `${Utils.getBackendBaseURL()}pvwattsPanels/hourly-clone`;
    return this.http.post(apiUrl, obj).pipe(
      map((res: any) => {
        if(!res.rc){
          const data = {...res};
          data['current_annual_consumption']=currentConsumption;
          data['system_size']=system_size;
          this.dataFromDesign.systemSize = system_size;
          this.dataFromDesign.generation = data.annual_consumption;
          this.dataFromDesign.quote_id =this.authService.getItem('quote_id');
          this.dataFromDesign.revision_number = this.authService.getItem('revision_number');
          this.dataForProjectDetails.next(this.dataFromDesign);
          return data;
        }else{
          throw throwError(res);  
        }
        
      })
    );

  }

  getPanelArrayTypes(): Observable<Array<ArrayTypes>> {
    return this.http.get<Array<ArrayTypes>>('assets/json/panelArrayTypes.json');
  }

  getPanelTypes(): Observable<Array<PanelTypes>> {
    return this.http.get<Array<PanelTypes>>('assets/json/panelTypes.json');
  }
  getRackingList():Observable<any>{
    const params: any = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.http.get(`${environment.newApiUrl}hardware/racking/selected`, { params });
  }
  getRackingTypes():Observable<any>{
    const params = {
      account_id: this.authService.getItem('account_id'),
      email_id: this.authService.getItem('email_id')
    };
    return this.panelConfigurationService.getRackingType(params, { disableCache: true })
  }

  getSelectedModuleDetails(selectedModuleDetails: any) {
    const account_id = this.authService.getItem('account_id');
    const email_id = this.authService.getItem('email_id');
    return this.http.get(`${environment.newApiUrl}hardware/module/details?account_id=${account_id}&ManufacturerName=${selectedModuleDetails?.ManufacturerName}&ModuleModelNumber=${encodeURIComponent(selectedModuleDetails?.ModuleModelNumber)}&email_id=${email_id}`)
      .pipe(
        map((res: any) => res),
        catchError((error) => of({ error: error?.error?.msg || 'Something went wrong, Please try again' })
        )
      );
  }

  getIrridaanceData(queryData: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}nact-backend/pvwattsPanels/irridance`;
    return this.http.post(apiUrl, queryData);
  }

  getCheckCoverageAPIHasDSM(site_information :any,mapOption:string){
    let siteInformation = {site_information:site_information, crn: localStorage.getItem('crn'), map_option:mapOption}
    return this.http.put(`${environment.newSiteDataApiEndpoint}site/additional-info`, siteInformation);
  }

  getDataFromDesign = this.dataForProjectDetails.asObservable();
}
