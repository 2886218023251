import { APIResponse, Status } from './api-response.model';
import { QuoteStatus, QuoteType } from './quote.model';

export interface RecentQuote {
  quoteType: QuoteType;
  accountId: string;
  createdAt: number;
  lastUpdate: number;
  quoteId: string;
  revisionNumber: number;
  quoteStatus: QuoteStatus;
  crn: string;
  firstName: string;
  lastName: string;
}

export const toRecentQuotes = (
  recentQuotes: any
): APIResponse<RecentQuote[]> => ({
  data: recentQuotes?.length ? recentQuotes : [],
  status: Status.Success,
});

export interface RecentQuoteAPIRequest {
  accountId: string;
  role: string;
  count: number;
}
