import { BoxGeometry, Group, InstancedMesh, Matrix4, Mesh, MeshStandardMaterial, Object3D, Plane, PlaneGeometry, Quaternion, Raycaster, Vector3 } from "three";
import Util, { constants, meshTypeEnum, MeshUserData, renderOrders, threeColors } from "./Utility";
import { Injectable } from "@angular/core";
import { InstancedMeshUtil } from "../utils/instanceMesh.util";
import { PanelArray } from "@design/models/panel-array.model";
import { Store } from "@ngrx/store";
import { DesignState } from "@design/store/design.reducer";
import { fromFrameActions } from "@design/store/frame";
import { fromFrameSelectors } from "@design/store/frame/frame.selector";
import { fromPanelSelectors } from "@design/store/panel/panel.selector";
import { take } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { Frame } from "@design/models/frame.model";
import { fromPanelArraySelectors } from "@design/store/panel-array/panel-array.selector";

@Injectable({ providedIn: 'root' })
export class StructureGeometryService {
    public zFactor = constants.PillarOffset;

    constructor(
        private store: Store<DesignState>,
    ) {
    }

    createPillar(): { pillarGeometry: BoxGeometry, pillarMaterial: MeshStandardMaterial } {
        const pillarSize = Util.getMeasurementFeetToPixel(constants.DefaultPillarSize);
        const pillarHeight = 1;
        const pillarGeometry = new BoxGeometry(pillarSize, pillarSize, pillarHeight);
        const pillarMaterial = new MeshStandardMaterial({ color: threeColors.pillarColor });
        return { pillarGeometry, pillarMaterial };
    }

    createPillarInstanceMesh(panelConfig: PanelArray, maxPossiblePanels: number, noOfFrames: number): InstancedMesh {
        const { pillarGeometry, pillarMaterial } = this.createPillar();
        const instanceCount = maxPossiblePanels * constants.DefaultNoOfPanelsInFrame / (panelConfig.noOfColumns * panelConfig.noOfRows);
        const pillarInstanceMesh = new InstancedMesh(pillarGeometry, pillarMaterial, instanceCount);

        InstancedMeshUtil.init(pillarInstanceMesh);
        InstancedMeshUtil.setShader(pillarInstanceMesh);

        pillarInstanceMesh.name = meshTypeEnum.pillerArray;
        (pillarInstanceMesh.userData as MeshUserData).meshtype = meshTypeEnum.instancedMesh;
        (pillarInstanceMesh.userData as MeshUserData).maxInstanceCount = pillarInstanceMesh.count;
        pillarInstanceMesh.count = noOfFrames * constants.DefaultNoOfPanelsInFrame;
        pillarInstanceMesh.renderOrder = renderOrders.pillarArray;
        return pillarInstanceMesh;
    }

    populatePillarInstanceMeshAtIndex(pillarInstanceMesh: InstancedMesh,pillarBuffer: Float32Array, frameHeight: number, index: number): void {
            for (let i = 0; i < 4; i++) {
                const position = new Vector3(pillarBuffer[i*4], pillarBuffer[i * 4 + 1], pillarBuffer[i * 4 + 2] - frameHeight / 2);
                const height = pillarBuffer[i * 4 + 3] + frameHeight;
                const matrix = new Matrix4().makeTranslation(position.x, position.y, position.z).scale(new Vector3(1, 1, height));
                pillarInstanceMesh.setMatrixAt(index*4 + i, matrix);
            }
    }

    createPillarPositionAndHeightBuffer(framePoints: Vector3[], roofConfirmedPlane: Plane, totalTilt: number): Float32Array {
        const buffer = new Float32Array(16);
        const frameCenter = new Vector3().copy(framePoints[0]).add(framePoints[1]).add(framePoints[2]).add(framePoints[3]).multiplyScalar(0.25);
        const pillarSize = Util.getMeasurementFeetToPixel(0.3);

        for (let i = 0; i < 4; i++) {
            let point = framePoints[i];
            const directionVector = new Vector3().copy(frameCenter).sub(point).normalize();
            point.add(directionVector.multiplyScalar(pillarSize));

            const projectedPoint = Util.projectPointOnPlane(point, roofConfirmedPlane);
            const position = Util.getPointInBetweenByPerc(point, projectedPoint);
            const height = point.distanceTo(projectedPoint) - this.zFactor - Math.tan(Util.convertDegreeToRadian(totalTilt)) * pillarSize;

            buffer.set([position.x, position.y, position.z - this.zFactor, height], i * 4);
        }
        return buffer;
    }

    addSinglePanelPillarInstance(roof: Object3D, panel: Mesh, storePanelArray: PanelArray, maxPillarIndex: number): void {
        const pillarInstanceMesh = roof.getObjectByName(meshTypeEnum.pillerArray) as InstancedMesh;
        if (!pillarInstanceMesh) return;

        const roofPlane = roof.getObjectByName(meshTypeEnum.roofPlane) as Mesh;
        const roofPoints = Util.getMeshVertices(roofPlane);
        const confirmedRoofPlane = new Plane().setFromCoplanarPoints(roofPoints[0], roofPoints[1], roofPoints[2]);
        const panelPoints = Util.getMeshVertices(panel, true);
        const buffer = this.createPillarPositionAndHeightBuffer(panelPoints, confirmedRoofPlane, storePanelArray.panelTilt + storePanelArray.roofTilt);
        const frameHeight = Util.DEFAULT_PANEL_ARRAY_MARGIN + Util.getMeasurementFeetToPixel(storePanelArray.frameHeight);

        this.addInstancesToMesh(pillarInstanceMesh, buffer, frameHeight, maxPillarIndex + 1);
    }

    private addInstancesToMesh(pillarInstanceMesh: InstancedMesh, buffer: Float32Array, frameHeight: number, index: number): void {
        for (let i = 0; i < 4; i++) {
            const position = new Vector3(buffer[i * 4 + 0], buffer[i * 4 + 1], buffer[i * 4 + 2] - frameHeight / 2);
            const height = buffer[i * 4 + 3] + frameHeight;
            const matrix = new Matrix4().makeTranslation(position.x, position.y, position.z).scale(new Vector3(1, 1, height));
            if (index && index + i < pillarInstanceMesh.count) {
                pillarInstanceMesh.setMatrixAt(index + i, matrix);
                pillarInstanceMesh.instanceMatrix.needsUpdate = true;
                InstancedMeshUtil.setVisibilityAt(pillarInstanceMesh, index + i, true);
            } else {
                InstancedMeshUtil.addInstance(pillarInstanceMesh, matrix);
            }
        }
    }

    //This logic can be used if we need to update pillar locations
    // findCornerPanelIndexOfFrame(panelConfig: PanelArray, i: number, invisibleIndices: number[]) {
    //     const noOfMaxPanelsInAFrame = panelConfig.noOfColumns * panelConfig.noOfRows;
    //     let bottomLeftPanelIndex = i * noOfMaxPanelsInAFrame;
    //     let topRightanelIndex = i * noOfMaxPanelsInAFrame + panelConfig.noOfColumns * panelConfig.noOfRows - 1;
    //     while (bottomLeftPanelIndex <= i * noOfMaxPanelsInAFrame + panelConfig.noOfColumns * panelConfig.noOfRows - 1) {
    //         if (invisibleIndices.includes(bottomLeftPanelIndex)) {
    //             bottomLeftPanelIndex++;
    //         } else {
    //             break;
    //         }
    //     }
    //     if (bottomLeftPanelIndex > i * noOfMaxPanelsInAFrame + panelConfig.noOfColumns * panelConfig.noOfRows - 1)
    //         return { bottomLeftPanelIndex: -1, bottomRightPanelIndex: -1, topRightanelIndex: -1, topLeftPanelIndex: -1 };

    //     let bottomRightPanelIndex = bottomLeftPanelIndex + panelConfig.noOfColumns - 1 - (bottomLeftPanelIndex - i * noOfMaxPanelsInAFrame) % panelConfig.noOfColumns;
    //     while (bottomRightPanelIndex > bottomLeftPanelIndex) {
    //         if (invisibleIndices.includes(bottomRightPanelIndex)) {
    //             bottomRightPanelIndex--;
    //         } else {
    //             break;
    //         }
    //     }
    //     while (topRightanelIndex > bottomLeftPanelIndex) {
    //         if (invisibleIndices.includes(topRightanelIndex)) {
    //             topRightanelIndex--;
    //         } else {
    //             break;
    //         }

    //     }
    //     let topLeftPanelIndex = topRightanelIndex - (topRightanelIndex - i * noOfMaxPanelsInAFrame) % panelConfig.noOfColumns;
    //     while (topLeftPanelIndex < topRightanelIndex) {
    //         if (invisibleIndices.includes(topLeftPanelIndex)) {
    //             topLeftPanelIndex++;
    //         } else {
    //             break;
    //         }
    //     }

    //     return { bottomLeftPanelIndex, bottomRightPanelIndex, topRightanelIndex, topLeftPanelIndex };
    // }

    updatePillarsOnObstacleOrRoofChanges(panelArray: PanelArray, invisibleIndices: number[], pillarInstanceMesh: InstancedMesh, panelInstanceMesh: InstancedMesh) {
        this.store.select(fromPanelSelectors.selectPanelsByInstanceIds(invisibleIndices, panelArray.roofId)).pipe(take(1)).subscribe(panels => {
            const toBeUpdatedFrameIds = new Set<string>();
            panels.forEach(panel => {
                if (panel) {
                    toBeUpdatedFrameIds.add(panel.frameId);
                }
            });

            forkJoin([
                this.store.select(fromFrameSelectors.selectFramesByIds(Array.from(toBeUpdatedFrameIds)))
                    .pipe(take(1)),
                this.store.select(fromFrameSelectors.selectUpdatedFrames(panelArray.frameIds))
                    .pipe(take(1))
            ]).subscribe(([toBeUpdatedFrames, toBeRestoredFrames]) => {
                const toBeRestoredFrameIds = new Set(toBeRestoredFrames.filter(Boolean).map(frame => frame!.id));
                if (pillarInstanceMesh) {
                    this.updatePillarsVisibility(pillarInstanceMesh, panelInstanceMesh, toBeUpdatedFrames);
                    this.updatePillarsVisibility(pillarInstanceMesh, panelInstanceMesh, toBeRestoredFrames);
                }
                this.store.dispatch(fromFrameActions.MarkStructuresAsModified({ frameIds: Array.from(toBeUpdatedFrameIds), isDefaultStructure: false }));
                this.store.dispatch(fromFrameActions.MarkStructuresAsModified({ frameIds: Array.from(toBeRestoredFrameIds), isDefaultStructure: true }));

            });


        })
    }

    updatePillarsVisibility(pillarInstanceMesh: InstancedMesh, panelInstanceMesh: InstancedMesh, toBeUpdatedFrames: (Frame | undefined)[]): void {
        const raycaster = new Raycaster();
        toBeUpdatedFrames.forEach((frame) => {
            if (frame) {
                for (let j = 0; j < frame.pillarIds.length; j++) {
                    const pI = frame.pillarIds[j];
                    const matrix = new Matrix4();
                    pillarInstanceMesh.getMatrixAt(pI, matrix);

                    const position = new Vector3();
                    matrix.decompose(position, new Quaternion(), new Vector3());

                    raycaster.set(position, new Vector3(0, 0, 1));
                    const intersects = raycaster.intersectObject(panelInstanceMesh);

                    const isVisible: boolean = intersects.length > 0 && intersects[0].instanceId != undefined && intersects[0].object.name == meshTypeEnum.panelArray && InstancedMeshUtil.getVisibilityAt(panelInstanceMesh, intersects[0].instanceId);
                    InstancedMeshUtil.setVisibilityAt(pillarInstanceMesh, pI, isVisible);
                }
            }

        });

        pillarInstanceMesh.instanceMatrix.needsUpdate = true;
    }

    //These functions can be used if need to create pillars for old panel array
    // createPillarsForOldPanelArray(panelGroup: Group, roofPlane: Mesh){
    //     let panelArray = panelGroup.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
    //     let roofConfirmedPlane = Util.getPlaneFromMesh(roofPlane);
    //     this.store.select(fromPanelArraySelectors.selectPanelArraysByIds([panelGroup.name])).pipe(take(1)).subscribe(panelArrays => {
    //         if (panelArrays[0]) {
    //             forkJoin([
    //                 this.store.select(fromFrameSelectors.selectFramesByIds(panelArrays[0].frameIds)).pipe(take(1)),
    //                 this.store.select(fromPanelSelectors.selectPanelsByIds(panelArrays[0].panelIds)).pipe(take(1))
    //             ])
    //             .subscribe(([frames, panels]) => {
    //                     frames = JSON.parse(JSON.stringify(frames));
    //                     let panelIdToInstanceId = new Map<string, number>();
    //                     panels.forEach((panel) => {
    //                         if(panel)
    //                             panelIdToInstanceId.set(panel.id, panel.instanceId);
    //                     });
    //                     let pillarBufferArray : Float32Array[]= [];
    //                     frames.forEach((frame,i) => {
    //                         if(frame){
    //                             frame.instanceIndex = i;
    //                             let framePoints:Vector3[] = [];
    //                             let panelIds = frame?.panelIds;
    //                             panelIds.forEach(pId=>{
    //                                 let panelGeom = new PlaneGeometry( Util.getMeasurementFeetToPixel(panelArrays[0]!.panelWidth),  Util.getMeasurementFeetToPixel(panelArrays[0]!.panelLength));
    //                                 let instanceId = panelIdToInstanceId.get(pId);
    //                                 let matrix = new Matrix4();
    //                                 panelArray.getMatrixAt(instanceId!, matrix);
    //                                 panelGeom.applyMatrix4(matrix);
    //                                 let panelPoints = Util.getPointsFromGeometery(panelGeom);
    //                                 framePoints = framePoints.concat(panelPoints);
    //                             });
    //                             let convexHull = this.convexHull(framePoints);
    //                             let buffer = this.createPillarPositionAndHeightBuffer(convexHull, roofConfirmedPlane, panelArrays[0]!.roofTilt + panelArrays[0]!.panelTilt);
    //                             pillarBufferArray.push(buffer);
    //                         }
    //                     });
    //                 let pillarArray = this.createPillarInstanceMesh(pillarBufferArray, panelArrays[0]!);
    //                 panelGroup.add(pillarArray);
    //                 const validFrames = frames.filter((frame): frame is Frame => frame !== undefined);
    //                 this.store.dispatch(fromFrameActions.UpsertMany({ frames: validFrames }));
    //             });
    //         }
    //     });
    // }
    // Function to compute the cross product of two vectors
    // cross(o: Vector3, a: Vector3, b:Vector3) {
    //     const oa = new Vector3(a.x - o.x, a.y - o.y);
    //     const ob = new Vector3(b.x - o.x, b.y - o.y);
    //     return oa.x * ob.y - oa.y * ob.x;
    // }

    // // Function to compute the convex hull using the Monotone Chain algorithm
    // convexHull(points: Vector3[]) {
    //     // Sort points lexicographically (by x, then by y)
    //     points.sort((a, b) => a.x - b.x || a.y - b.y);

    //     // Build the lower hull
    //     const lower = [];
    //     for (const point of points) {
    //         while (lower.length >= 2 && this.cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0) {
    //             lower.pop();
    //         }
    //         lower.push(point);
    //     }

    //     // Build the upper hull
    //     const upper = [];
    //     for (let i = points.length - 1; i >= 0; i--) {
    //         const point = points[i];
    //         while (upper.length >= 2 && this.cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0) {
    //             upper.pop();
    //         }
    //         upper.push(point);
    //     }

    //     // Remove the last point of each half because it's repeated at the beginning of the other half
    //     upper.pop();
    //     lower.pop();

    //     // Combine lower and upper hulls
    //     return lower.concat(upper);
    // }
}