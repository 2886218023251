import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Utils } from '../helpers/utils';
import { AuthService } from '../helpers/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EngageAdminService {
  constructor(private cookieService: CookieService, private http: HttpClient,
    private authService : AuthService) {}

  getSystemTypes(): Observable<any> {
    const path='/system-types?account_id='
    const apiUrl=`${environment.engage_apigateway_url}${path}${this.authService.getItem('account_id')}`
    return this.http.get(apiUrl);
  }

  getUserTypes(): Observable<any> {
    const path = `engage/user-type`;
    const apiUrl = `${Utils.getBaseurl()}${path}`;
    return this.http.get(apiUrl);
  }

  getSiteDetail(crn : any): Observable<any> {

    const path = `site?crn=${crn}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    // https://java11api.enact-systems.com/site?crn=VPA-JY5-OMX
    // https://java11.enact-systems.com/site?crn=VPA-JY5-OMX
    console.log('api calling=======url===>==============>',apiUrl);
    return this.http.get(apiUrl);
  }

  getQuoteDetails(quoteObj : any): Observable<any> {
    // https://java11api.enact-systems.com/quotes/details?crn=VPA-JY5-OMX&quote_id=ENACTSYSTE200682&revision_number=1
    const path = `quotes/details?crn=${quoteObj.crn}&quote_id=${quoteObj.quoteId}&revision_number=${quoteObj.revisionNo}`;
    const apiUrl = `${environment.newApiUrl}${path}`;
    return this.http.get(apiUrl);
  }

  getConnectors(): Observable<any> {
    const path = `/component-options?account_id=${this.authService.getItem('account_id')}`;
    const apiUrl = `${environment.engage_apigateway_url}${path}`;
    return this.http.get(apiUrl);
  }
  getUsers(crn : any): Observable<any> {
    const path = `engage/users?crn=${crn}`;
    const apiUrl = `${Utils.getBaseurl()}${path}`;
    return this.http.get(apiUrl);
  }

  getAdminDetails(crn : any): Observable<any> {
    //
    const path = `/site/details?crn=${crn}`;
    const apiUrl = `${environment.engage_apigateway_url}${path}`;
    return this.http.get(apiUrl);
  }

  saveAdminData(body : any): Observable<any> {
     const path = `engage/admin-details`;
    const apiUrl=`${environment.newApiUrl}${path}`
    return this.http.post(apiUrl, body);
  }

  getUserIdentity(account_id : any, parent_account : any): Observable<any> {
    const path =
      `/account-preferences?account_id=` +
      account_id +
      `&parent_account=` +
      parent_account;
    const apiUrl = `${environment.engage_apigateway_url}${path}`;

    return this.http.get(apiUrl);
  }

  getConsumer(crn : any): Observable<any> {
    const path = `lead/`;
    const apiUrl = `${environment.newApiUrl}${path}` + crn;
    return this.http.get(apiUrl);
  }

  engage_admin_details(data : any) {
    return this.http.post(`${environment.newApiUrl}engage/admin-details`, data);
  }
  engage_site_detail(crn : any) {
    return this.http.get(`${environment.newApiUrl}site?crn=${crn}`);
  }
}
