export interface Panel {
  datasheetURL: string;
  logoURL: string;
  modelNumber: string;
  notes: string;
  quantity: string;
  name: string;
  moduleType: string;
  unitCost: number;
  degradation: string;
  configuration: PanelConfig[],
  arrayType: string,
  panelType: any
}

export interface PanelConfig {
  id: string;
  azimuth: number;
  setback: number;
  isEdgeSetback: boolean;
  edgeSetbackArray: number[],
  orientation: string;
  arrayType: string;
  panelSize: string;
  panelWidth: number;
  panelHeight: number;
  panelTilt: number;
  tiltType: number;
  gapY: number;
  roofTilt: number;
  shadingValue: number;
  shadingType: string;
  rackingSystem: string;
  rackingManufacturer: string;
  watts: number;
  gapX: number;
  totalWatts: number;
  numberOfPanels: number;
  wattsPerModule: number;
  path: string;
  panelDegradation: number;
  panelType: string;
  modelId: string;
  moduleId: string;
  // new
  noOfRows: number;
  noOfColumns: number;
  frameSpace: number;
  frameHeight: number;
  clampSpace: number;
  peakSpace: number;
  azimuth2?: number;
  noOfPanelEast: number;
  noOfPanelWest?: number;
}

export const toPanelConfig = (res: any): Panel => ({
  datasheetURL: res?.datasheetURL,
  logoURL: res?.logoURL,
  modelNumber: res?.modelNumber,
  notes: res?.notes,
  quantity: res?.quantity,
  name: res?.name,
  moduleType: res?.moduleType,
  unitCost: res?.unitCost,
  degradation: res?.degradation,
  configuration: res?.configuration?.length ? res.configuration : [],
  arrayType: res?.arrayType,
  panelType: res?.panelType
  
})