import { Component, OnInit } from '@angular/core';
import { EnactIcons } from '@lib-ui/lib/common/types/enactIcons';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { openDialog, openModal } from '@lib-ui/lib/common/utils/openDialog';
import { openNotificationService } from '@lib-ui/lib/atomic/enact-alert-snack-bar/openNotification.service';
import { TimeBasedDischargeGraphComponent } from '@design/containers/graphs/time-based-discharge-graph/time-based-discharge-graph.component';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';



@Component({
  selector: 'app-test-tools',
  templateUrl: './test-tools.component.html',
  styleUrls: ['./test-tools.component.scss']
})
export class TestToolsComponent implements OnInit {
  favoriteSeason = '';
  seasons: string[] = ['Winter', 'Spring', 'Summer', 'Autumn'];
  checked = true;
  //start slide-toggle & Label
  isSlideChecked: boolean = true;
  isSlideChecked2: boolean = true;
  //End slide-toggle & Label

  isShowNotify: boolean = true;
  notifyTMsg: string =  'Tree 1 copied';
  icon2: EnactIcons = "spherical-icon";
  notifyCloseIcon: boolean = false;
  notifyCancelBtn: boolean = true;
  notifyYesBtn: boolean = false;
  selected: boolean = false;
  disabled: boolean = true;
  helpText: string = "This is sample help text";
  icon: EnactIcons = "pen-icon";
  btnText: string = "Free Form";
  isListView: boolean = true;

  roofIcon: EnactIcons = "roof";

  value: number = 25;
  accuracy: number = 2;
  step: number = 1;
  title: string = "Base Height";
  unit: string = "Feet";

  tileValue: number = 1.85;
  tileUnit: string = "kWP";
  tileEntity: string = "size";


  // Colorpicker
  defaultColor = {
    name: 'Blue',
    value: '#3F7CF6',
  };
  cpPresetLabelName: string = "Select Color";
  colors = [
    {
      name: 'Sky Blue',
      value: '#4AE5FF',
    },
    {
      name: 'Blue',
      value: '#3F7CF6',
    },
    {
      name: 'Pink',
      value: '#E8007F',
    },
    {
      name: 'Green',
      value: '#00D35E',
    },
  ];
  // ALERT NOTIFICATION
  notfiyIcon: EnactIcons = "notification-icon";
  notifyMsg = this.translateWord('azimuthCanBeBtwnZeroNtxty');
  notifyTimeOut = 100000;
  notifyType = 'danger';
  positionClass = 'toast-bottom-left';
// ALERT NOTIFICATION

  constructor(public dialog: MatDialog,private translate : TranslateService,
    private notificationService: openNotificationService) { }

  openDialogConsumer() {
    const data = { title: this.translateMsg('delApiKey'), message: this.translateMsg('useBlockChnFeat') };
    const dialogRef = openDialog(this.dialog, data);

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result) console.log("YES was clicked");
      else console.log("NO was clicked");

      console.log(result);
    });
  }

  handleValueChange(value: number) {
    console.log(value);
  }

  handleCompositeButtonClick() {
    this.selected = !this.selected;
  }
  translateWord(val: any) {
    let translatedName = ''
    this.translate.get('design.leftPanel.selectedTools.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName
  }
  ngOnInit(): void {
  }

  rbtValueChanged(event: any) {
    console.log('valueChanged grop---', event);
  }

  showCustomComponent() {

    const sendNotifyMSG = this.notifyMsg;
    const sendNotifyType = this.notifyType;
    const sendPositionClass = this.positionClass;
    const timeOut = this.notifyTimeOut ? this.notifyTimeOut : 6000;

    this.notificationService.sendMessage({
      message: sendNotifyMSG,
      type: sendNotifyType,
      positionClass: sendPositionClass,
      timeOut:timeOut
    });
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

}
