import { createSelector } from '@ngrx/store';
import { DesignState } from '../design.reducer';
import { selectDesign } from '../design.selector';

const uiStateSelector = createSelector(
  selectDesign,
  (state: DesignState) => state.ui
);

export namespace fromUiSelectors {
  export const selectedUiState = uiStateSelector;
  
  export const selectedToolId = createSelector(
    uiStateSelector,
    (state) => state.selectedToolId
  );
  export const selectedObjectId = createSelector(
    uiStateSelector,
    (state) => state.selectedObjectId
  );
  export const selectedConfigurationId = createSelector(
    uiStateSelector,
    (state) => state.selectedConfigurationId
  );
  export const selectedToolName = createSelector(
    uiStateSelector,
    (state) => state.selectedToolName
  );
  export const selectedToolDetails = createSelector(
    uiStateSelector,
    (state) => state.selectedToolDetails
  );

  export const getClearStateFlag = createSelector(
    uiStateSelector,
    (state) => state.clearState
  );
  
  export const getDisableToolPanelsSelector = createSelector(
    uiStateSelector,
    (state) => state.disableToolPanelsForCustomUpload
  );
  
}
