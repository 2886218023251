import { Battery, toBatteryConfig } from './battery.model';
import { Inverter, toInverterConfig } from './inverter.model';
import { MonitorSystem, toMonitorSystemConfig } from './monitor-system.model';
import { Panel, toPanelConfig } from './panel.model';
import { APIResponse, Status } from './api-response.model';

export interface Hardware {
  _id: string;
  qrn: string;
  battery: Battery;
  inverter: Inverter;
  panel: Panel;
  monitoringSystem: MonitorSystem;
}

export const toHardwareConfig = (res: any): Hardware => ({
  _id: res?._id,
  qrn: res?.qrn,
  battery: res?.battery ? toBatteryConfig(res.battery) : ({} as Battery),
  inverter: res?.inverter ? toInverterConfig(res.inverter) : ({} as Inverter),
  panel: res?.panel ? toPanelConfig(res.panel) : ({} as Panel),
  monitoringSystem: res?.monitoringSystem
    ? toMonitorSystemConfig(res.monitoringSystem)
    : ({} as MonitorSystem),
});

export const toQuoteHardware = (res: any): APIResponse<Hardware> => ({
  data: toHardwareConfig(res),
  status: Status.Success,
});
