import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { RoofSection } from '@design/models/roof-section.model';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromRoofSectionActions } from './roof-section.action';
import { RoofSectionState } from './roof-section.state';

export const adapter: EntityAdapter<RoofSection> =
  createEntityAdapter<RoofSection>();

export const roofSectionInitialState: RoofSectionState =
  adapter.getInitialState({
    selectedRoofSectionId: null,
    showDragPopup: false,
  });

export const { selectAll: selectAllRoofSection } = adapter.getSelectors();

export const roofSectionReducer: ActionReducer<RoofSectionState> =
  createReducer(
    roofSectionInitialState,
    on(fromRoofSectionActions.RoofSectionAdd, (state, data) => {
      return adapter.addOne(data.roof, state);
    }),
    on(fromRoofSectionActions.AddManyRoofsAndAutoSave,(state,data) =>{
      return adapter.upsertMany(data.roofs,state);
    }),
    on(fromRoofSectionActions.AddManyRoofs,(state,data) =>{
      return adapter.addMany(data.roofs,state);
    }),
    on(
      fromRoofSectionActions.RoofSectionBoundChange,
      (state, { roof: { id, area, vertexIds, edgeIds } }) => {
        return adapter.updateOne(
          {
            id,
            changes: { area, vertexIds, edgeIds },
          },
          state
        );
      }
    ),
    on(fromRoofSectionActions.DragEvent, (state, { showDragPopup }) => {
      return {
        ...state,
        showDragPopup: showDragPopup
      }
    }),
    on(fromRoofSectionActions.RoofSectionDelete, (state, { roof }) => {
      return adapter.removeOne(roof.id, state);
    }),
    on(fromRoofSectionActions.UpsertOne, (state, { roof }) => {
      return adapter.upsertOne(roof, state);
    }),
    on(fromRoofSectionActions.UpdateRoof, (state, { roof }) => {
      return adapter.updateOne(
        {
          id: roof.id,
          changes: {
            ...roof,
          },
        },
        state
      );
    }
    ),
    on(fromRoofSectionActions.UpdateRoofSetbackVisiblity, (state, { roof }) => {
      return adapter.updateOne(
        {
          id: roof.id,
          changes: {
            ...roof,
          },
        },
        state
      );
    }
    ),
  );
