import { Box3, BufferAttribute, BufferGeometry, DataTexture, DirectionalLight, DoubleSide, Event, Group, LinearFilter, Material, Mesh, MeshPhongMaterial, MeshStandardMaterial, NearestFilter, Object3D, OrthographicCamera, Quaternion, RGBAFormat, Scene, Sprite, SpriteMaterial, Texture, Vector2, Vector3, WebGLRenderTarget, WebGLRenderer,InstancedMesh } from "three";
import Util, { TreeObjects, meshTypeEnum } from "./Utility";
import * as d3 from 'd3';
import { PanelGeometry } from "./panelGeometry";
import * as THREE from 'three';
import { DesignState } from "@design/store/design.reducer";
import { Store } from "@ngrx/store";
import { fromRoofSectionSelectors } from "@design/store/roof-section";
import { switchMap, map, take, takeUntil } from "rxjs/operators";
import { Observable, Subject, Subscription, forkJoin, of } from "rxjs";
import { fromVertexSelectors } from "@design/store/vertex";
import { fromMetaDataSelectors } from "@design/store/meta-data";
import { fromShadingActions, fromShadingSelectors } from "@design/store/shading";
import { fromObstacleSelectors } from "@design/store/obstacle";
import { fromPanelArraySelectors } from "@design/store/panel-array/panel-array.selector";
import { AuthService } from "src/app/helpers/auth.service";
import { ShadingStatus } from "@design/models/shading.model";
import { NotificationType } from "@lib-ui/lib/atomic/enact-alert-snack-bar/notification.message";
import { openNotificationService } from "@lib-ui/lib/atomic/enact-alert-snack-bar/openNotification.service";
import { TranslateService } from "@ngx-translate/core";
import { Injectable } from "@angular/core";
import { SubscriptionManager } from "../services/subscription-manager.service";
import { EngineService } from "../threejs.service";
import { fromPanelArrayActions } from "@design/store/panel-array";
import { fromSimulationActions } from "@design/store/simulation";
import { fromUndoRedoActions } from "@design/store/undo-redo/undo-redo.action";
import { fromUiActions } from "@design/store/ui";
import { fromPanelSelectors } from "@design/store/panel/panel.selector";
import { InstancedMeshUtil, PanelTextureEnum } from "../utils/instanceMesh.util";

declare var getJD: any;
declare var calcTimeJulianCent: any;
declare var calcAzEl: any;
declare var calcSunriseSetUTC: any;
const ts = require("@mapbox/timespace");

var configs = {
    CANVAS_HEIGHT: 2000,
    CANVAS_WIDTH: 2000,
    CAMERA_NEAR: 0.1,
    LAT: 37.71688314718149,
    LON: -121.87104505953252,
    UTCOFFSET: -7
}

var colorScale = d3
    .scaleSequential(d3.interpolateInferno)
    .domain([0, 100]);

@Injectable({ providedIn: 'root' })
export class Shader {
    private latitude: number = configs.LAT;
    private longitude: number = configs.LON;
    private utcOffset: number = configs.UTCOFFSET;
    private orthoCamera!: OrthographicCamera;
    private target!: WebGLRenderTarget;
    private directionalLight: DirectionalLight = new DirectionalLight(0xffffff, 10 * Math.PI);
    public radianceMaterialsTextures: any = {};
    public height = configs.CANVAS_HEIGHT;
    public width = configs.CANVAS_WIDTH;
  

    mapPlane: Mesh | undefined;
    isRadianceVisible = false;
    private sunPositionArray!: Vector3[][];
    panelShadeData!: Map<string, { monthly: any[]; yearly: any[] }>;
    panelCords: Map<string, Vector3[]> | undefined;
    hoverPanel: { id: string | undefined, texture: PanelTextureEnum | undefined, instanceId: number | undefined,  panelArrayId:  string | undefined} = {
        id: undefined,
        texture: undefined,
        instanceId: undefined,
        panelArrayId: undefined
    };
    
    t!: number;
    roofPanelProperties: Map<string, {
        panelCoords: Map<string, Vector3[]> | undefined;
        maxObj: {
            monthly: number[];
            yearly: number[];
        } | undefined;
        radianceUv: number[] | undefined;
    }> = new Map();
    subscription: Subscription;
    enactShaderWorker: Worker;
    
    constructor(private engServ: EngineService, private subscriptionManager: SubscriptionManager,
        private store: Store<DesignState>, private panelGeometry: PanelGeometry, private authService: AuthService,
        private notificationService: openNotificationService, private translate : TranslateService){

        this.subscriptionManager.getSubscription().subscribe((subscribe: any)=>{
            if(subscribe){
                this.subscription = new Subscription();
                //this.init();
                this.enactShaderWorker = new Worker(new URL('../enact-shader.worker.ts', import.meta.url), { type: 'module' });

            }else{
                this.enactShaderWorker.terminate();
                this.subscription.unsubscribe();
            }
        })
        this.store = store;
    }

    init() {
        this.setupRenderTarget();
        this.setupOrtho();
        this.setupDirectionalLight();
        this.getLatLongFromStore();
        this.addWorkerListener();
    }
  

    private getSunLightPosition(date: Date) {
        var month = date.getMonth() + 1;
        var mins = date.getHours() * 60 + date.getMinutes() + date.getSeconds() / 60.0
        var julianday = getJD(date.getFullYear(), month, date.getDate());
        var total = julianday + mins / 1440.0 - this.utcOffset / 24.0
        var T = calcTimeJulianCent(total)
        var azel = calcAzEl(T, mins, this.latitude, this.longitude, this.utcOffset);
        let radius = 5000;
        let azimuth = (90 - azel.azimuth) * (Math.PI / 180);
        let elevation = azel.elevation * (Math.PI / 180);
        let x = (radius * (Math.cos(elevation))) * (Math.cos(azimuth));
        let y = (radius * (Math.cos(elevation))) * (Math.sin(azimuth));
        let z = radius * (Math.sin(elevation))
        return new Vector3(x, y, z)
    }

    private getLatLongFromStore() {
        this.latitude = Number(this.authService.getItem('c_lat'));
        this.longitude = Number(this.authService.getItem('c_long'));
        var point = [this.longitude, this.latitude];
        var timestamp = Date.now();
        var time = ts.getFuzzyLocalTimeFromPoint(timestamp, point);
        this.utcOffset = (time._offset) / 60;
    }

    private setupRenderTarget() {

        if (this.target) this.target.dispose();
        // const format = parseFloat(String(DepthFormat));
        // const type = parseFloat(String(UnsignedShortType));
        let pixelSize = 2;
        let rows = Math.floor(this.height / pixelSize);
        let columns = Math.floor(this.width / pixelSize);

        this.target = new WebGLRenderTarget(columns, rows);
        this.target.texture.minFilter = NearestFilter;
        this.target.texture.magFilter = NearestFilter;
        this.target.stencilBuffer = false;
        
    }
    private setupOrtho() {

        if (!this.orthoCamera) {
            this.orthoCamera = new OrthographicCamera(-this.width / 2, this.width / 2, this.height / 2, -this.height / 2, 0, 2000);
            this.engServ.scene.add(this.orthoCamera);
            // const helper = new CameraHelper(this.orthoCamera);
            // this.orthoCamera.add(helper);
            this.orthoCamera.position.set(0, 0, 10);
            this.adjustOrthoCamera();
        }
    }

    private setupDirectionalLight() {
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.camera.near = 0; // default
        this.directionalLight.shadow.camera.far = 7000;
        this.directionalLight.shadow.camera.left = -4000; // Adjust based on scene size
        this.directionalLight.shadow.camera.right = 4000;
        this.directionalLight.shadow.camera.top = 4000;
        this.directionalLight.shadow.camera.bottom = -4000;
        this.directionalLight.shadow.mapSize.width = 7000;
        this.directionalLight.shadow.mapSize.height = 7000;
        this.directionalLight.shadow.bias = -0.000009;
    }
    private adjustOrthoCamera() {
        this.orthoCamera = new OrthographicCamera(-this.width / 2, this.width / 2, this.height / 2, -this.height / 2, 0, 2000);
        let group = new Group();
        for (let child of this.engServ.scene.children) {
            if (child && (child.name.includes('roof') || child.name.includes('Tree'))) {
                group.add(child.clone());
            }
        }
        var center = new Vector3(1000, -1000, 0),
            size = new Vector3,
            box = new Box3().setFromObject(group);
        box.getSize(size);

        // let hypotneus = Math.sqrt(Math.pow(size.x, 2) + Math.pow(size.y, 2));
        this.orthoCamera.position.copy(center).addScaledVector(new Vector3(0, 0, 1), box.max.z);
        // let orthoGutter = new Vector3(0, 1, 0);
        // let gutteredge = Util.getGutterEdgeFromStore(roofId, this.store)

        this.orthoCamera.lookAt(center);
        this.orthoCamera.rotateZ(-Math.PI/2)
        // orthoGutter.applyQuaternion(this.orthoCamera.quaternion);

        // let quaternion = new Quaternion().setFromUnitVectors(orthoGutter, new Vector3(gutteredge?.x, gutteredge?.y, 0).normalize());
        // this.orthoCamera.applyQuaternion(quaternion);

        this.orthoCamera.zoom = this.width / 2000;
        this.orthoCamera.updateProjectionMatrix();
        this.orthoCamera.updateMatrixWorld();
    }

    private adjustDirectionalLight(position: Vector3) {
        this.directionalLight.position.set(
            position.x,
            position.y,
            position.z);

    }

    simulate() {
        this.t = new Date().getTime();
        this.hoverPanel = { id: undefined, texture: undefined, instanceId: undefined, panelArrayId: undefined}
        this.store
            .select(fromRoofSectionSelectors.allRoofSections)
            .pipe(take(1))
            .subscribe((v) => {
                let roofList: Object3D[] = [];
                for (let i = 0; i < v.length; i++) {
                    let objs = this.engServ.scene.getObjectsByProperty('name', v[i].id);
                    let roof;
                    objs.forEach(obj => {
                        if (obj.type == 'Object3D') {
                            roof = obj as THREE.Object3D;
                        }
                    })
                    if (roof) { roofList.push(roof) };
                }

                const d = new Date();
                let sp = this.sunPositionArrayFromYear(d.getFullYear());
                this.sunPositionArray = sp.sunPositionArray;
                this.prepareForSimulate(roofList);

                this.engServ.renderer.setRenderTarget(this.target);
                let monthlyBuffers = this.simulation();
                this.panelShadeData = new Map();
                this.roofPanelProperties = new Map();
                for (let i = 0; i < roofList.length; i++) {
                    if (roofList[i]) {
                        this.roofPanelProperties[roofList[i]!.name] = this.getRoofPanelProperties(roofList[i]!);
                    }
                }
                this.enactShaderWorker.postMessage({
                    message: 'shading',
                    data: {
                        monthlyBuffers, columns: this.target.width, rows: this.target.height, roofPanelProperties: JSON.parse(JSON.stringify(this.roofPanelProperties))
                    },

                })
                this.engServ.renderer.setRenderTarget(null);
                this.recoverSceneAfterSimulate(roofList)


            });

    }
    private prepareForSimulate(roofList: string | any[]) {
        for (let child of this.engServ.scene.children) {
            if (child.name.includes('Tree')) {
                child.children.forEach((c) => {
                    ((c as Mesh).material as MeshStandardMaterial).opacity = 0.01;
                    ((c as Mesh).material as MeshStandardMaterial).colorWrite = false;
                    ((c as Mesh).material as MeshStandardMaterial).depthWrite = false;
                })
                // group.add(child.clone())
            }
        }
        this.toggleObstacleGroups(false);
        this.toggleEdgeGroups(false);
        for (let i = 0; i < roofList.length; i++) {
            if (roofList[i]) {
                this.panelGeometry.togglePanels(roofList[i], false);
            }
        }
        // this.setRoofsWhite(roofList);
        this.adjustOrthoCamera();
        this.engServ.scene.getObjectsByProperty('name', 'Anchor').forEach(a=>a.removeFromParent())
        this.engServ.scene.add(this.directionalLight);
    }

    private setRoofsWhite(roofList: string | any[]) {
        for (let i = 0; i < roofList.length; i++) {
            let roofPlane = (this.engServ.scene.getObjectByName(roofList[i].name) as Object3D).getObjectByName(meshTypeEnum.roofPlane) as Mesh;
            let rmaterial = new MeshStandardMaterial({
                side: DoubleSide,
                color: 0xffffff
            });
            roofPlane.material = rmaterial;
            roofPlane.geometry.computeVertexNormals();
        }
    }

    private recoverSceneAfterSimulate(roofList: string | any[]) {
        this.engServ.scene.remove(this.directionalLight);
        for (let child of this.engServ.scene.children) {
            if (child.name.includes('Tree')) {
                child.children.forEach((c) => {
                    ((c as Mesh).material as MeshStandardMaterial).opacity = 1;
                    ((c as Mesh).material as MeshStandardMaterial).colorWrite = true;
                    ((c as Mesh).material as MeshStandardMaterial).depthWrite = true;
                })
                // group.add(child.clone())
            }
        }
        this.toggleEdgeGroups(true);
        this.toggleObstacleGroups(true);
        for (let i = 0; i < roofList.length; i++) {
            if (roofList[i]) {
                this.engServ.renderer.clear();
                this.panelGeometry.togglePanels(roofList[i], true);
            }
        }
    }

    private endSimulate() {
        let shadeData = Array.from(this.panelShadeData.values());
        this.store.dispatch(fromShadingActions.SetPanelsData(
            { allPanelsData: shadeData }
        ));
        // Set shading as finished
        this.store.dispatch(fromShadingActions.ChangeHeatMapData(
            { heatMapData: { heatMapStatus: true, btnClicked: false } }));
        this.store.dispatch(fromShadingActions.ChangeShadingStatus(
            { shadingStatus: ShadingStatus.Complete_Success }));
        this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromUndoRedoActions.Shading, notification: this.translateMsg('design.undoRedo.shading',false)}));

        /// Trigger hardware api save when simulation completed to save the panel array shading loss
        this.store.dispatch(fromPanelArrayActions.SaveHardwareDetailsOnly());
        this.notificationService.sendMessage({
            message: this.translateMsg('shadSmlationComp'),
            type: NotificationType.success,
            positionClass: 'toast-bottom-left',
            timeOut: 5000
        });
        console.log("Shading Time:", new Date().getTime() - this.t);
    }

    private simulation() {
        let monthlyBuffers = [];
        for (let i = 0; i < this.sunPositionArray.length; i++) {
            let hourlyBuffers = [];
            for (let j = 0; j < this.sunPositionArray[i].length; j++) {
                this.adjustDirectionalLight(this.sunPositionArray[i][j]);
                this.engServ.renderer.render(this.engServ.scene, this.orthoCamera);
                let actualBuffer = new Uint8ClampedArray(this.target.width * this.target.height * 4);
                this.engServ.renderer.readRenderTargetPixels(this.target, 0, 0, this.target.width, this.target.height, actualBuffer);
                // this.imagedata_to_image(new ImageData(actualBuffer, this.target.width, this.target.height))
                hourlyBuffers.push(actualBuffer)
                this.engServ.renderer.clear();
            }
            let monthlyBuffer: Uint8ClampedArray = this.addBuffers(hourlyBuffers, true)
            monthlyBuffers.push(monthlyBuffer);
        }
        this.isRadianceVisible = true;
        return monthlyBuffers;
    }

    private getRoofPanelProperties(roof: Object3D) {
        let panelCoords, maxObj;
        var fittedRoof: Mesh | undefined = undefined;
        for (let m of roof.children) {
            if (m.name === meshTypeEnum.roofPlane)
                fittedRoof = m as Mesh;
        }
        if (fittedRoof && fittedRoof.parent?.name) {
            let roofId = fittedRoof.parent?.name;
            fittedRoof.geometry.computeVertexNormals();
            let roofMesh = this.engServ.scene.getObjectByName(roofId!) as Mesh;
            let instancedMesh = roofMesh.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
            if (instancedMesh) {
                let panelMesh = this.panelGeometry.getAllPanelPoints(instancedMesh, false);
                panelCoords = this.getPanelProjectedCoordinates(panelMesh, this.target.width, this.target.height, instancedMesh.parent?.name!);
                this.panelGeometry.transparentPanels(instancedMesh, true);
            }

            maxObj = this.getMax(fittedRoof);
            // radianceUv = this.createRadianceUv(fittedRoof);

        }
        return {
            panelCoords, maxObj
        }
    }

    private addWorkerListener() {
        this.enactShaderWorker.addEventListener('message', (e) => {
            if (e.data.message == 'ShadingComplete') {
                let { yearlyBuffer, roofPanelShadingData }: {
                    yearlyBuffer: Uint8ClampedArray, roofPanelShadingData: Map<string, { panelShadingData: { monthly: any[], yearly: any[] }, radianceUv: number[] | undefined }>
                }
                    = e.data.data;
                let yearlyTextureBuffer = this.createTextureBuffer(yearlyBuffer, this.target.height, this.target.width);
                // this.imagedata_to_image(new ImageData(yearlyTextureBuffer, this.target.width, this.target.height))
                this.radianceMaterialsTextures ={}
                for (let [roofId, value] of roofPanelShadingData) {
                    // console.log(roofPanelShadingData.get(roofId));

                    this.setupRoofRadianceMaterial(roofId, yearlyTextureBuffer, this.target.width, this.target.height);
                    this.panelShadeData.set(roofId, value.panelShadingData);
                }
                this.endSimulate();

            }
        })
    }
    private setupRoofRadianceMaterial(roofId: string, buffer: BufferSource | null | undefined, columns: number | undefined, rows: number | undefined) {
        const texture = new DataTexture(buffer, columns, rows, RGBAFormat);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.needsUpdate = true;
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        let roofPlane = (this.engServ.scene.getObjectByName(roofId) as Object3D).getObjectByName(meshTypeEnum.roofPlane) as Mesh;
        let rmaterial = new MeshPhongMaterial({
            side: DoubleSide,
            transparent: true,
            map: texture
        });
        this.radianceMaterialsTextures[roofId] = { texture: texture }
        roofPlane.material = rmaterial;
    }

    private addBuffers(buffers: Uint8ClampedArray[], monthly: boolean = false): Uint8ClampedArray {
        let rows = this.target.height;
        let columns = this.target.width;

        let result = new Uint8ClampedArray(rows * columns * 4).fill(0);
        // let actualBuffer = new Uint8ClampedArray(this.target.width * this.target.height * 4);

        // this.engServ.renderer.readRenderTargetPixels(this.target, 0, 0, this.target.width, this.target.height, actualBuffer);
        // this.imagedata_to_image(new ImageData(actualBuffer, this.target.width, this.target.height))

        for (let i = 0; i < rows; i++) {
            for (let j = 0; j < columns; j++) {
                let sum = 0;
                for (let k = 0; k < buffers.length; k++) {
                    let r = buffers[k][(i * columns + j) * 4];
                    let g = buffers[k][(i * columns + j) * 4 + 1];
                    let b = buffers[k][(i * columns + j) * 4 + 2];
                    let a = buffers[k][(i * columns + j) * 4 + 3];
                    if (monthly) {

                        if (r === 0 && g === 0 && b === 0) {
                            // sum += 0;
                        } else {
                            sum += 255;
                        }
                    } else {
                        sum += r;
                    }

                }
                result[(i * columns + j) * 4] = Math.floor(sum / buffers.length)
                result[(i * columns + j) * 4 + 1] = Math.floor(sum / buffers.length)
                result[(i * columns + j) * 4 + 2] = Math.floor(sum / buffers.length)
                result[(i * columns + j) * 4 + 3] = 255

                // let x = j * pixelSize + Math.floor(pixelSize / 2);
                // let y = i * pixelSize + Math.floor(pixelSize / 2);

            }
        }
        return result;
    }
    /**
     * create panel projected coordinates according to texture size,
     * It is independent of target depixelate size
     * @param panels
     * @param columns width of the texture map
     * @param rows length of the texture map
     * @returns
     */
    private getPanelProjectedCoordinates(
        panels: {[key: string]: Vector3[]},
        columns: number,
        rows: number,
        panelArrayId: string
    ): Map<string, Vector3[]> {

        let panelCoords: Map<string, Vector3[]> = new Map();
        let panelIdCache: { [key: number]: string } = {};  // Cache panel IDs by index

        if (panels && Object.keys(panels).length > 0) {
            // Retrieve the panel arrays in bulk synchronously
            this.store
              .select(
                fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId])
              )
              .pipe(take(1))
              .subscribe((panelArrays) => {
                if (panelArrays.length <= 0) return;
                const panelArray = panelArrays[0];


                // Retrieve the panel data in bulk (panel IDs)
                this.store
                  .select(
                    fromPanelSelectors.selectPanelsByInstanceIds(
                      Object.keys(panels).map((v)=> Number.parseInt(v)), // Get all indices of the panels
                      panelArray?.roofId!
                    )
                  )
                  .pipe(take(1))
                  .subscribe((panelsData) => {
                    panelsData.forEach((panel) => {
                      if (panel) {
                          panelIdCache[panel.instanceId] = panel.id;
                      }
                  });
                    for (let i = 0; i < Object.keys(panels).length; i++) {
                      const instanceId = Object.keys(panels)[i];

                      const panelVertices = panels[instanceId];
                      let result = panelVertices.map((vertex: Vector3) => {
                        // Assuming getProjectedCoordinates is synchronous or not causing delay
                        return this.getProjectedCoordinates(
                          vertex.clone(),
                          columns,
                          rows
                        );
                      });

                      // Store the result in the panelCoords map
                      panelCoords[panelIdCache[Number.parseInt(instanceId)]] = result;
                    }
                  });
              });
        }

        return panelCoords;
    }


    private sunPositionArrayFromYear(year: number) {
        let sunPositionArray: Vector3[][] = [];
        let minSunTime = 0;
        let sunriseArray = [];
        let monthlySunTime = [];
        for (let month = 1; month <= 12; month++) {
            let date = new Date(year, month - 1, 1, 1, 0, 0);
            // console.log("Month:",month)
            var julianday = getJD(date.getFullYear(), month, date.getDate());
            let sunriseTime = Math.ceil(calcSunriseSetUTC(1, julianday, this.latitude, this.longitude) / 60.0 + this.utcOffset); //in hours
            let sunsetTime =  Math.floor(calcSunriseSetUTC(0, julianday, this.latitude, this.longitude) / 60.0 + this.utcOffset);
            let suntime = (sunsetTime - sunriseTime); // in hours
            // console.log("sunrise:",sunriseTime)
            // console.log("sunset:",sunsetTime);
            monthlySunTime.push(suntime);
            minSunTime = Math.max(minSunTime, suntime);
            sunriseArray.push(Math.floor(sunriseTime));
        }
        for (let month = 1; month <= 12; month++) {
            let dayArray: Vector3[] = [];
            let date = new Date(year, month - 1, 1, 1, 0, 0);
            var julianday = getJD(date.getFullYear(), month, date.getDate());
            for (let i = 0; i < monthlySunTime[month-1]; i++) {
                let hour = sunriseArray[month - 1] + i + 1
                let timestamp = new Date(year, month, 1, hour, 0, 0);
                let sunPosition = this.getSunLightPosition(timestamp)
                if (sunPosition.z < 10) {
                    sunPosition.z = 10;
                }
                dayArray.push(sunPosition);
            }
            sunPositionArray.push(dayArray);
        }
        return { sunPositionArray, sunriseArray };
    }
    private getMax(fittedRoof: Mesh) {
        let roofNormal = Util.getMeshNormal(fittedRoof);
        if (roofNormal.z < 0) {
            roofNormal.multiplyScalar(-1);
        }
        let maxPixelObj: {
            monthly: number[],
            yearly: number[]
        } = {
            monthly: [],
            yearly: []
        }
        let yearlysum = 0;
        let totaltDays = 0;
        for (let i = 0; i < this.sunPositionArray.length; i++) {
            let sum = 0;
            for (let j = 0; j < this.sunPositionArray[i].length; j++) {
                let angle = roofNormal.angleTo(this.sunPositionArray[i][j]);
                if (angle < Math.PI / 2 && angle > -Math.PI / 2) {
                    sum += 1;
                }
                totaltDays++;
            }
            yearlysum += sum;
            maxPixelObj.monthly.push(sum * 255 / this.sunPositionArray[i].length);
        }
        maxPixelObj.yearly.push(yearlysum * 255 / (totaltDays));
        return maxPixelObj;
    }
    private createTextureBuffer(buffer: Uint8ClampedArray, rows: number, columns: number) {
        let textureBuffer = new Uint8ClampedArray(rows * columns * 4);
        for (let i = 0; i < rows * columns; i = i + 1) {
            let spec = Util.hexToRgb(colorScale(buffer[i * 4]*100/255));
            if (spec) {
                textureBuffer[4 * i] = spec.r;
                textureBuffer[4 * i + 1] = spec.g;
                textureBuffer[4 * i + 2] = spec.b;
                textureBuffer[4 * i + 3] = 255;
            } else {
                textureBuffer[4 * i + 3] = 255;
            }

        }
        return textureBuffer;
    }

    /**
     * independent of texture size
     * @param v0
     * @param width width of the desired screen
     * @param height height of the desired screen
     * @returns
     */
    private getProjectedCoordinates(v0: Vector3, width: number, height: number) {
        var widthHalf = width / 2, heightHalf = height / 2;

        var pos = v0.clone();
        pos.project(this.orthoCamera);
        pos.x = (pos.x * widthHalf) + widthHalf;
        pos.y = (pos.y * heightHalf) + heightHalf;
        return pos;
    }

    imagedata_to_image(imagedata: ImageData) {
        var canvas = document.createElement('canvas');
        canvas.id = "temporary"
        canvas.style.position = 'absolute';
        canvas.style.top = '0';
        canvas.style.left = '0';
        canvas.style.zIndex = '1000';
        canvas.style.transform = 'scaleX(-1)'
        var ctx = canvas.getContext('2d');
        if (ctx) {
            canvas.width = imagedata.width;
            canvas.height = imagedata.height;
            ctx.putImageData(imagedata, 0, 0);
            document.body.append(canvas);

            var image = new Image();
            image.src = canvas.toDataURL(); // Optimization 89ms
            return image;
        }
        return;

    }

    convertImageDataToBase64(imagedata: ImageData) {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = imagedata.width;
        canvas.height = imagedata.height;
        if (ctx) {
            ctx.putImageData(imagedata, 0, 0);
            var base64Image = canvas.toDataURL(); // Optimization 89ms
            return base64Image;
        }
        return
    }

    toggleRadiance(radiance: boolean) {
        this.store
            .select(fromRoofSectionSelectors.allRoofSections)
            .pipe(take(1))
            .subscribe((v) => {
                let roofList = v.flatMap((r) => {
                    let objs = this.engServ.scene.getObjectsByProperty('name', r.id);
                    let roofobj: Object3D;
                    objs.forEach(obj => {
                        if (obj.type == 'Object3D') {
                            roofobj = obj as Object3D;

                        }
                    })
                    return roofobj!;
                })
                for (let roof of roofList) {
                    if (roof) {
                        let fittedRoof
                        for (let m of roof.children) {
                            if (m.name === meshTypeEnum.roofPlane)
                                fittedRoof = m as Mesh;
                        }
                        if (fittedRoof && fittedRoof.parent?.name) {
                            let roofId = fittedRoof.parent?.name
                            // let {panelMeshes, isEastWest} = this.panelGeometry.getPanelsFromRoof(roof, false);
                            let roofMesh = this.engServ.scene.getObjectByName(roofId!) as Mesh;
                            let instancedMesh = roofMesh.getObjectByName(meshTypeEnum.panelArray) as InstancedMesh;
                            if (radiance && Object.keys(this.radianceMaterialsTextures).length != 0) {
                                if(this.radianceMaterialsTextures[roofId]?.texture){
                                    fittedRoof.material = new MeshPhongMaterial({
                                      side: DoubleSide,
                                      transparent: true,
                                      map: this.radianceMaterialsTextures[roofId]?.texture
                                    });
                                }
                            } else {
                                Util.applyUVMap(fittedRoof, this.mapPlane, this.engServ.lowQualityTexture)
                            }
                            if (instancedMesh) {
                                this.panelGeometry.transparentPanels(instancedMesh, radiance);
                            }
                        }
                    }
                }
            })
        this.isRadianceVisible = radiance;

    }
    onPanelHover(instanceId: number | undefined, panelArrayId: string | null) {
        if (!this.isRadianceVisible) return;
        if (this.hoverPanel.instanceId === undefined && instanceId === null)
          return;
        if (this.hoverPanel.instanceId != instanceId) {
          let instancedMeshParent = this.engServ.scene.getObjectByName(
            this.hoverPanel.panelArrayId!
          );
          let instancedMesh = instancedMeshParent?.children[0] as InstancedMesh;
          if (instancedMesh)
            InstancedMeshUtil.setTextureAt(
              instancedMesh,
              this.hoverPanel.instanceId!,
              this.hoverPanel.texture!
            );
          if (instanceId != undefined && panelArrayId) {
            this.store
              .select(
                fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId])
              )
              .pipe(take(1))
              .subscribe((panelArrays) => {
                if (panelArrays.length <= 0) return;
                this.store
                  .select(
                    fromPanelSelectors.selectPanelsByInstanceIds(
                      [instanceId],
                      panelArrays[0]?.roofId!
                    )
                  )
                  .pipe(take(1))
                  .subscribe((panels) => {
                    if (panels.length <= 0) return;
                    let panelId = panels[0].id;
                    this.store.dispatch(
                      fromShadingActions.SetHoveredPanelId({
                        hoverPanelId: panelId,
                      })
                    );
                    this.hoverPanel.texture = this.panelGeometry.hoverPanel(
                      instanceId,
                      panelArrayId
                    );
                  });
              });
            this.hoverPanel.instanceId = instanceId;
            this.hoverPanel.panelArrayId = panelArrayId;
          } else {
            this.store.dispatch(
                fromShadingActions.SetHoveredPanelId({ hoverPanelId: '' })
              );
            this.hoverPanel = { id: undefined, texture: undefined, instanceId: undefined, panelArrayId: undefined }
        }
        }
    }   

    private toggleEdgeGroups(show: boolean) {
        let edgeGroups = this.engServ.scene.getObjectsByProperty('name', meshTypeEnum.edgeGroup);
        edgeGroups.forEach(eg => {
            eg.visible = show;
        });
        let edges = this.engServ.scene.getObjectsByProperty('name', meshTypeEnum.edge);
        edges.forEach(eg => {
            eg.visible = show;
        })

    }


    private toggleObstacleGroups(show: boolean) {
        let obstGroups = this.engServ.scene.getObjectsByProperty('name', meshTypeEnum.obstacleRootGroup) as Group[];
        obstGroups.forEach(oG => {
            let extrCap = oG.getObjectByName(meshTypeEnum.ObstacleExtrudeCap) as Mesh;
            if (extrCap && show) {
                ((extrCap as Mesh).material as MeshStandardMaterial).opacity = 1;
                ((extrCap as Mesh).material as MeshStandardMaterial).colorWrite = true;
                ((extrCap as Mesh).material as MeshStandardMaterial).depthWrite = true;
            } else if (extrCap && !show) {
                ((extrCap as Mesh).material as MeshStandardMaterial).opacity = 0.01;
                ((extrCap as Mesh).material as MeshStandardMaterial).colorWrite = false;
                ((extrCap as Mesh).material as MeshStandardMaterial).depthWrite = false;
            }
            let wallMeshes = oG.getObjectsByProperty('name', 'wallMesh') as Mesh[];
            wallMeshes.forEach((wM: Mesh) => {
                if (show) {
                    ((wM as Mesh).material as MeshStandardMaterial).opacity = 1;
                    ((wM as Mesh).material as MeshStandardMaterial).colorWrite = true;
                    ((wM as Mesh).material as MeshStandardMaterial).depthWrite = true;
                    let lines = wM.children;
                    lines.forEach(line => { line.visible = true })
                } else {
                    ((wM as Mesh).material as MeshStandardMaterial).opacity = 0.01;
                    ((wM as Mesh).material as MeshStandardMaterial).colorWrite = false;
                    ((wM as Mesh).material as MeshStandardMaterial).depthWrite = false;
                    let lines = wM.children;
                    lines.forEach(line => { line.visible = false })

                }
            });
            let children = oG.children;
            children.forEach(c => {
                if (c.type == 'Mesh' && !c.name.includes('ObstacleSetbackMesh')) {
                    if (show) {
                        ((c as Mesh).material as MeshStandardMaterial).opacity = 1;
                        ((c as Mesh).material as MeshStandardMaterial).colorWrite = true;
                        ((c as Mesh).material as MeshStandardMaterial).depthWrite = true;
                    } else {
                        ((c as Mesh).material as MeshStandardMaterial).opacity = 0.01;
                        ((c as Mesh).material as MeshStandardMaterial).colorWrite = false;
                        ((c as Mesh).material as MeshStandardMaterial).depthWrite = false;
                    }
                }
            })

        })
    }


    clearShader(){
        this.panelShadeData = new Map();
        this.radianceMaterialsTextures ={}
        this.isRadianceVisible = false;
        this.hoverPanel = { id: undefined, texture: undefined, instanceId: undefined, panelArrayId: undefined }
        this.roofPanelProperties = new Map();
        //this.mapPlane = undefined;

    }

    translateMsg(val: string, isToasterMsg = true) {
        let translatedName = '';
        let toasterPrefix = '';
        if(isToasterMsg){
          toasterPrefix = 'toastrMsgs.';
        }

        this.translate.get( toasterPrefix + val).pipe(take(1)).subscribe(res => {
          translatedName = res;
        }).unsubscribe();
        return translatedName;
    }

}
