import { createSelector } from '@ngrx/store';
import { selectDesign } from '../design.selector';
import { adapter } from './additional-tools.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
    adapter.getSelectors();

export namespace fromAdditionalToolsResultsSelectors {

    export const additionalToolsResultsStateSelector = createSelector(
        selectDesign,
        (state) => state.additionalTools
    );

    export const getCPFRatesSelector = createSelector(
        selectDesign,
        (state) => state.additionalTools
    );

    export const getMonitoringSystemsSelector = createSelector(
        selectDesign,
        (state) => state.additionalTools
    );
    export const getselectedMonitoringSystemsSelector = createSelector(
        selectDesign,
        (state) => state?.additionalTools?.selectedMonitoringSys
    );

    export const getSelectedMonitoringSystemSelector = createSelector(
        selectDesign,
        (state) => state?.additionalTools?.selectedMonitoringSys ? state?.additionalTools?.selectedMonitoringSys : state?.additionalTools?.monitoringResp?.[0],
    );

    export const getSelectedMonitoringRespSelector = createSelector(
        selectDesign,
        (state) => state?.additionalTools?.monitoringResp,
    );

    export const hardwareAPIStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.hardwareAPIStatus,
    );

    export const quoteAPIStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.quoteAPIStatus,
    );

    export const backgroundImageAPIStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.backgroundImageAPIStatus,
    );

    export const houseViewImageAPIStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.houseViewImageAPIStatus,
    );

    export const customFile = createSelector(
        selectDesign,
        (state) => state?.additionalTools.customGenerationFileInfo,
    );

    export const SimulateCustomFlag = createSelector(
        selectDesign,
        (state) => state?.additionalTools.disableReportForCustom,
    );

    export const mapLoadingStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.isMapLoaded,
    );

    export const sceneRestoreStatus = createSelector(
        selectDesign,
        (state) => state?.additionalTools.isSceneRestored,
    );
}
