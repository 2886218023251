export interface ColumnOption {
  columnDef: string;
  header: string;
  cell: any;
}

export const TABLE_PAGE_SIZE_OPTIONS: number[] = [5, 10, 20, 50];
export const DEFAULT_PAGE_SIZE: number = 5;

export const PROJECT_LIST_COLUMNS: ColumnOption[] = [
  { columnDef: "c_fn", header: "Customer", cell: (element: any) => `${element.c_fn}` },
  { columnDef: "sp_fn", header: "Project Owner", cell: (element: any) => `${element.sp_fn}` },
  { columnDef: "company", header: "Company", cell: (element: any) => `${element.company}` },
  { columnDef: "address", header: "Street Address", cell: (element: any) => `${element.address}` },
  { columnDef: "city", header: "City, State", cell: (element: any) => `${element.city}` },
  { columnDef: "c_phone", header: "Phone Number", cell: (element: any) => `${element.c_phone}` },
  { columnDef: "c_email", header: "Email", cell: (element: any) => `${element.c_email}` },
  { columnDef: "last_update", header: "Last Update", cell: (element: any) => `${element.last_update}` },
  { columnDef: "create_ts", header: "Created Date", cell: (element: any) => `${element.create_ts}` },
  { columnDef: "type", header: "Type", cell: (element: any) => `${element.type}` }
]

export const DOCUSIGNSTATUS_LIST_COLUMNS: ColumnOption[] = [
  { columnDef: "name", header: "Name", cell: (element: any) => `${element.name}` },
  { columnDef: "email", header: "Email Address", cell: (element: any) => `${element.email}` },
  { columnDef: "status", header: "Status", cell: (element: any) => `${element.status}` }
]