import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { EnactAlertSnackBarComponent } from './enact-alert-snack-bar.component';
import { EnactIconModule } from '../../composite/enact-icon/enact-icon.module';
@NgModule({
  declarations: [EnactAlertSnackBarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    EnactIconModule,
  ],
  exports: [
    EnactAlertSnackBarComponent,
  ]
})
export class EnactAlertSnackBarModule { }
