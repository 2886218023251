import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIResponse, Status } from '@api/models/api-response.model';
import {
  RecentQuote,
  RecentQuoteAPIRequest,
  toRecentQuotes,
} from '@api/models/quote-recent.model';
import {
  QuoteDetail,
  QuoteList,
  QuoteListAPIRequest,
  toQuoteList,
} from '@api/models/quote-list.model';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { toQuoteDesign, Design } from '../models/design.model';
import { Hardware, toQuoteHardware } from '../models/hardware.model';
import {
  QuoteAPIResponse,
  DeleteQuoteAPIResponse,
  EditCompletedQuoteAPIRequest,
  toQuote,
} from '../models/quote.model';
import {
  QuoteNoteUpdateAPIRequest,
  toUpdateQuoteNote,
} from '@api/models/quote-note-update.model';
import { Finance, toQuoteFinance } from '@api/models/finance.model';
import {
  toQuoteV1,
  QuoteV1ApiResponse,
  QuoteV1,
} from '@api/models/qoute.v1.model';
import { Dictionary } from 'lodash';
import { SingleQuote, toSingleQuote } from '@api/models/single-quote.model';

@Injectable()
export class QuoteService {
  constructor(private http: HttpClient) {}

  getCompleteQuote(payload: any): Observable<APIResponse<SingleQuote>> {
    return this.http
      .get(
        `${environment.newQuoteAPIEndpoint}quote/singleQuote?quoteId=${payload?.quoteId}&revisionNumber=${payload?.revisionNumber}&crn=${payload?.crn}`,
        payload
      )
      .pipe(
        map(toSingleQuote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<SingleQuote>)
        )
      );
  }

  createQuote(payload: any, headers?: HttpHeaders): Observable<APIResponse<QuoteAPIResponse>> {
    return this.http
      .post(`${environment.newQuoteAPIEndpoint}quote`, payload, {headers})
      .pipe(
        map(toQuote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteAPIResponse>)
        )
      );
  }

  deleteQuote(qrn: string): Observable<APIResponse<DeleteQuoteAPIResponse>> {
    return this.http
      .delete(`${environment.newQuoteAPIEndpoint}quote/${qrn}`)
      .pipe(
        map((res: any) => ({ data: res, status: Status.Success })),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<DeleteQuoteAPIResponse>)
        )
      );
  }

  getQuoteHardware(qrn: string): Observable<APIResponse<Hardware>> {
    return this.http
      .get(`${environment.newQuoteAPIEndpoint}quote/hardware/${qrn}`)
      .pipe(
        map(toQuoteHardware),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<Hardware>)
        )
      );
  }

  getQuoteDesign(qrn: string) {
    return this.http
      .get(`${environment.newQuoteAPIEndpoint}quote/design/${qrn}`)
      .pipe(
        map(toQuoteDesign),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<Design>)
        )
      );
  }

  getQuoteFinance(qrn: string): Observable<APIResponse<Finance>> {
    return this.http
      .get(`${environment.newQuoteAPIEndpoint}quote/finance/${qrn}`)
      .pipe(
        map(toQuoteFinance),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<Finance>)
        )
      );
  }

  getRecentQuotes(
    payload: RecentQuoteAPIRequest
  ): Observable<APIResponse<RecentQuote[]>> {
    return this.http
      .get(
        `${environment.newQuoteAPIEndpoint}quote/recent?account_id=${payload.accountId}&role=${payload.role}&count=${payload.count}`
      )
      .pipe(
        map(toRecentQuotes),
        catchError((error) =>
          of({
            data: [],
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<RecentQuote[]>)
        )
      );
  }

  getQuote(qrn: string) {
    return this.http
      .get(`${environment.newQuoteAPIEndpoint}quote/getQuoteByQrn/${qrn}`)
      .pipe(
        map(toQuoteV1),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error,
          } as APIResponse<QuoteV1>)
        )
      );
  }

  getQuoteList(
    payload: QuoteListAPIRequest
  ): Observable<APIResponse<QuoteList>> {
    let url = `${environment.newQuoteAPIEndpoint}quote/list?crn=${payload.crn}`;
    url =
      payload.page && payload.size
        ? `${url}&page=${payload.page}&size=${payload.size}`
        : url;
    return this.http.get(url).pipe(
      map(toQuoteList),
      catchError((error) =>
        of({
          data: {},
          status: Status.Error,
          error: error?.error?.msg,
        } as APIResponse<QuoteList>)
      )
    );
  }

  getCompletedQuoteList(
    payload: QuoteListAPIRequest
  ): Observable<APIResponse<QuoteList>> {
    return this.http
      .get(
        `${environment.newQuoteAPIEndpoint}quote/list?crn=${payload.crn}&quoteStatus=${payload.quoteStatus}`
      )
      .pipe(
        map(toQuoteList),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteList>)
        )
      );
  }

  updateQuoteNote(
    payload: QuoteNoteUpdateAPIRequest
  ): Observable<APIResponse<QuoteDetail>> {
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote`, payload)
      .pipe(
        map(toUpdateQuoteNote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteDetail>)
        )
      );
  }

  updateFinanceNote(payload: any): Observable<APIResponse<any>> {
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote/finance`, payload)
      .pipe(
        map((res) => ({ data: res, status: Status.Success })),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<any>)
        )
      );
  }

  updateCompletedQuote(
    payload: EditCompletedQuoteAPIRequest,
    headers?: HttpHeaders
  ): Observable<APIResponse<QuoteAPIResponse>> {
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote`, payload, {headers})
      .pipe(
        map(toQuote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteAPIResponse>)
        )
      );
  }

  updateQuoteFinance(payload: Finance): Observable<APIResponse<Finance>> {
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote/finance`, payload)
      .pipe(
        map(toQuoteFinance),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<Finance>)
        )
      );
  }

  updateQuoteDesign(payload: Record<any, unknown>) {
    const qrn = payload.qrn;
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote/design`, payload)
      .pipe(
        map(toQuote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteAPIResponse>)
        )
      );
  }

  updateQuoteHardware(payload: Dictionary<any>) {
    const qrn = payload.qrn;
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote/hardware`, payload)
      .pipe(
        map(toQuote),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as APIResponse<QuoteAPIResponse>)
        )
      );
  }

  updateQuoteSystemResults(
    payload: Dictionary<any>
  ): Observable<QuoteV1ApiResponse> {
    return this.http
      .put(`${environment.newQuoteAPIEndpoint}quote`, payload)
      .pipe(
        map(toQuoteV1),
        catchError((error) =>
          of({
            data: {},
            status: Status.Error,
            error: error?.error?.msg,
          } as QuoteV1ApiResponse)
        )
      );
  }
}
