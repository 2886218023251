import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { SystemDesignComponent } from './modules/system-design/system-design.component';
import { AuthGuardService as AuthGuard } from './helpers/guards/auth-guard.service';
import { ProfileComponent } from './modules/shared/profile/profile.component';
import { ProposalModalComponent } from './modules/settings/proposal-modal/proposal-modal.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { EngageAdminComponent } from './engage-admin/engage-admin/engage-admin.component';
import { CostingSetupComponent } from './modules/settings/costing-setup/costing-setup.component';
import { WebViewComponent } from './modules/projects/web-view/web-view.component';
import { AccountComponent } from './modules/account/account.component';
import { TestToolsComponent } from '@design/test-tools/test-tools.component';
import { UnauthorizedComponent } from './modules/login/unauthorized/unauthorized.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'account', component: AccountComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'tasks',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'setting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'systemdesign',
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/system-design/system-design.module').then(m => m.SystemDesignModule)
  },
  // {
  //   path: 'systemdesign',
  //   component: SystemDesignComponent,
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'engage',
    component: EngageAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'proposal',
    component: ProposalModalComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'costing',
    component: CostingSetupComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'web/reports/:path',
    component: WebViewComponent

  },
  {
    path: 'test-tools',
   component:TestToolsComponent
  },
  {
    path: 'design',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../projects/design/src/app/design/design.module').then(m => m.DesignModule)
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  { path: '**', redirectTo: '/projects' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
