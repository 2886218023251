
import { createSelector } from '@ngrx/store';
import { selectDesign } from '../design.selector';
import { adapter } from './simulation.reducer';
import { DesignState } from '../design.reducer';
import { SimulationState } from './simulation.state';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();


export namespace fromSimulationResultsSelectors {
  export const simulationResultStateSelector = createSelector(
    selectDesign,
    (state: DesignState) => state.simulationResults
  );

  export const selectSystemSize = createSelector(
    selectDesign,
    (state: DesignState) => state.simulationResults.system_size
  );

  export const selectPVWattRunningStatus = createSelector(
    selectDesign,
    (state: DesignState) => state?.simulationResults.pvWattsRunning
  );

  export const selectConsumptionOverview = createSelector(
    simulationResultStateSelector,
    (state: SimulationState) => state?.consumptionOverview
  );

  export const selectGenerationOverview = createSelector(
    simulationResultStateSelector,
    (state: SimulationState) => state?.generationOverview
  );

  export const pvWattsFailure = createSelector(
    selectDesign,
    (state: DesignState) => state.simulationResults.pvWattsErrorMsg
  );

  export const simulationAPIStatus = createSelector(
    selectDesign,
    (state: DesignState) => state.simulationResults?.simulationStatus
  );

}
