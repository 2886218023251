<div class="col-sm-12">
  <div class="dashboard-title">
    <div class="page-header">
      <h1>
        {{ userInfo }}
        <span id="dashBoardlnfn"></span>
        <small><i class="ace-icon fa fa-angle-double-right"></i> &nbsp;{{'dashboard.overViewNStats' | translate }}</small>
      </h1>
    </div>

    <div>
      <mat-form-field appearance="fill">
        <mat-select [(ngModel)]="selectedWorkflow" (selectionChange)="workflowChange($event)">
          <mat-option value="All_Workflows">{{'allWfl' | translate }}</mat-option>
          <mat-option *ngFor="let data of workflowdata" [value]="data"
            >{{ data.workflowName }}({{ data.bn }})</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-select
          (selectionChange)="ByChange($event)"
          [(ngModel)]="selctionWay"
        >
          <mat-option value="count">{{'dashboard.byCount' | translate }}</mat-option>
          <mat-option value="amount">{{'dashboard.byAmount' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{'dashboard.enterDtRange' | translate }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="{{'dashboard.startDt' | translate }}"
            #dateRangeStart
            (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="{{'dashboard.endDt' | translate }}"
            #dateRangeEnd
            (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker
          #picker
          [calendarHeaderComponent]="ExampleHeaderComponent"
          [touchUi]="isTouchUIActivated"
        ></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
</div>
<div class="col-sm-12">
  <div class="space-4"></div>
  <div id="teamMembersDropdrown">
    <div id="teamMembrsVertical" style="position: fixed; display: none">
      <div class="vertical-text" (click)="toggleTeamMemberVertical()">
        {{'settings.userSetup.teamMember' | translate }}
      </div>
      <i
        class="glyicon fa fa-angle-right toggleTeamMemberVertical"
        (click)="toggleTeamMemberVertical()"
        style="cursor: pointer"
      ></i>
    </div>
    <div class="col-sm-2 corporateDashboard-list" id="colTwoForExpand">
      <h3 id="teamMembers" style="font-size: 16px">
        <span
          class="fa fa-angle-left"
          (click)="toggldeTeamMember()"
          style="cursor: pointer"
        ></span>
        &nbsp;{{'settings.userSetup.teamMember' | translate }}
      </h3>
      <div id="custom-search-input">
        <div class="input-group col-md-12">
          <input
            type="text"
            class="form-control input-sm text-place-intent"
            id="search-term"
            placeholder="{{'buttons.search' | translate | lowercase}}"
            (keyup)="searchFunction($event)"
          />
          <span class="input-group-btn"
            ><button class="btn btn-sm" type="button">
              <i class="fa fa-search"></i></button
          ></span>
        </div>
      </div>
      <div
        id="corporatesDashboard-list"
        style="
          color: #333;
          font-size: 13px;
          line-height: 1.6;
          font-family: 'Roboto' !important;
        "
      >
        <div id="treeview" class="alignment_teammember">
          <mat-tree
            [dataSource]="dataSource"
            [treeControl]="treeControl"
            class="example-tree"
          >
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
              <li class="mat-tree-node">
                <mat-checkbox
                  class="checklist-leaf-node"
                  (change)="itemToggle($event.checked, node);onFilterChange()"
                  [checked]="node.selected"
                  >{{ node.display }}</mat-checkbox
                >
              </li>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
              <li>
                <div class="mat-tree-node">
                  <button
                    mat-icon-button
                    matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.display"
                    [attr.aria-expanded]="true"
                  >
                    <mat-icon class="mat-icon-rtl-mirror">
                      {{
                        treeControl.isExpanded(node)
                          ? "expand_more"
                          : "chevron_right"
                      }}
                    </mat-icon>
                  </button>
                  <mat-checkbox
                    [checked]="node.selected"
                    [indeterminate]="node.indeterminate && !node.selected"
                    (change)="itemToggle($event.checked, node);onFilterChange()"
                    >{{ node.display }}</mat-checkbox
                  >
                </div>
                <ul
                  [class.example-tree-invisible]="!treeControl.isExpanded(node)"
                >
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </li>
            </mat-nested-tree-node>
          </mat-tree>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-10" id="example">
    <div class="modal-content">
      <div>
        <mat-card>
          <div class="position-relative" id="project-charts">
            <mat-tab-group
              class="group"
              (selectedTabChange)="onTabChangedGraph($event)"
            >
              <mat-tab label="{{'dashboard.homePage' | translate }}">
                <mat-tab-group
                  mat-align-tabs="end"
                  (selectedTabChange)="tabClick($event)"
                >
                  <mat-tab label="{{'dashboard.pie' | translate}}">
                    <div class="padd20">
                      <app-pie-chart
                        [byWay]="selctionWay"
                        [dates]="range"
                        [workflow]="selectedWorkflow"
                      ></app-pie-chart>
                    </div>
                  </mat-tab>
                  <mat-tab label="{{'dashboard.area' | translate}}">
                    <div class="padd20">
                      <app-dashboard-line-chart
                        [lineChartLoad]="lineChartLoad"
                        [byWay]="selctionWay"
                        [dates]="range"
                        [workflow]="selectedWorkflow"
                      ></app-dashboard-line-chart>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </mat-tab>
              <mat-tab label="{{'dashboard.recentPr' | translate }}">
                <div class="padd20">
                  <div>
                    <p>{{'dashboard.recentlyCrPrjs' | translate }}</p>
                    <table>
                      <tr>
                        <th>{{'dashboard.prNamesCaps' | translate }}</th>
                        <th>{{'dashboard.locCaps' | translate }}</th>
                        <th>{{'project.prjtList.prjColumns.EMAIL' | translate }}</th>
                      </tr>
                      <tr *ngFor="let data of projectList">
                        <td
                          routerLink="/projects/details"
                          [queryParams]="{ crn: data.crn }"
                          class="rowLink"
                        >
                          {{ data.first_name }}
                        </td>
                        <td>{{ data.city }}{{ data.state }}</td>
                        <td>{{ data.email }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="padd20">
                  <div>
                    <p>{{'dashboard.recentlyQuotePrjs' | translate }}</p>
                    <table>
                      <tr>
                        <th>{{'dashboard.prNamesCaps' | translate }}</th>
                        <th>{{'dashboard.quoteId' | translate }}</th>
                        <th>{{'settings.docSetup.proposal' | translate }}</th>
                      </tr>
                      <tr *ngFor="let data of recentQuotes">
                        <td
                          routerLink="/projects/details"
                          [queryParams]="{ crn: data.crn }"
                          class="rowLink"
                        >
                          {{ data.firstName }} {{ data.lastName }}
                        </td>
                        <td>{{ data.quoteId }}</td>
                        <td *ngIf="data?.quoteStatus?.toLowerCase() === 'completed'" class="rowLink" (click)="quoteData(data)">
                          {{'dashboard.viewProposal' | translate }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </mat-card>
      </div>
      <div class="space-8"></div>
    </div>
  </div>
</div>
