import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnactIconComponent } from './enact-icon.component';

@NgModule({
  declarations: [
    EnactIconComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EnactIconComponent
  ]
})
export class EnactIconModule { }