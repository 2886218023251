import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/helpers/auth.service';
import { environment } from 'src/environments/environment';
import { Utils } from 'src/app/helpers/utils';
import { LeadService } from '@api/services/lead.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectWorkflowService {
  workFLowdata: any
  stageNames: any = []
  userSeedprofile: any = ''
  workflowNamePass: any = ''
  userList = new BehaviorSubject("");

  workFlowDetailsData: any = []
  // https://java11api.enact-systems.com/workflow
  updateData = new BehaviorSubject<boolean>(false);
  updateLeads = new BehaviorSubject<boolean>(false)
  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private leadService: LeadService,
  ) { }

  getLeadInfo(crn: string): Observable<any> {
    return this.leadService.getLeadInfo(crn);
  }

  updateLead(obj: any): Observable<any> {
    /// getLeadInfo method using cache. so clearing cache when update the lead info
    this.leadService.clearCache();
    return this.http.put(`${environment.newApiUrl}lead`, obj);
  }

  createTemplate(reqBody: any) {
    reqBody.account_id = this.authService.getItem('account_id')
    reqBody.role = this.authService.getItem('role')
    return this.http.get(`${environment.newApiUrl}email-template?account_id=${reqBody.account_id}&role=${reqBody.role}`);
    // https://java11api.enact-systems.com/email-template
  }

  getAllEmailTemplate() {
    let obj: any = {
      account_id: this.authService.getItem('account_id'),
      role: this.authService.getItem('role')
    }
    return this.http.get(`${environment.newApiUrl}email-template?account_id=${obj.account_id}&role=${obj.role}`);
  }

  updateTemplate(templateBody: any, templateId: any) {
    return this.http.put(`${environment.newApiUrl}email-template/${templateId}`, templateBody)
  }

  uploadTemplateFile(file: any) {
    return this.http.post(`${environment.newApiUrl}workflow/file`, file);
  }

  sendMail(mailData: any) {
    return this.http.post(`${environment.newApiUrl}email/send`, mailData);
  }

  sendMailWithAttachments(mailData: any) {
    return this.http.post(`${environment.newApiUrl}email/send/attachment`, mailData);

  }

  // sendMailWithAttachmentsNew(mailData: any) {
  //   return this.http.post(`${environment.newApiUrl}email/send/sendMail`, mailData);

  // }


  deleteTemplate(templateId: any) {
    return this.http.delete(`${environment.newApiUrl}email-template/${templateId}`)
  }

  updateWorkFlow(reqBody: any) {
    reqBody['tenantId'] = this.authService.getItem('sp_id')
    return this.http.put(`${environment.newApiUrl}workflow`, reqBody)
  }

  getWorkFlowDetails(reqBody: any) {
    return this.http.post(`${environment.newApiUrl}workflow`, reqBody)
  }

  getAllTaskOwners(reqBody: any) {
    return this.http.get(`${environment.newApiUrl}account/members/hierarchy?account_id=${reqBody.account_id}&departmentKey=${reqBody.departmentKey}`)
  }


  deleteWorkTask(reqBody: any) {
    // const header: HttpHeaders = new HttpHeaders()
    // header.append('Content-Type', 'application/json; charset=UTF-8')
    // header.append('x-api-key', environment.newApiKey)
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody,

    };
    return this.http.delete(`${environment.newApiUrl}workflow/task`, options)
  }

  getListOfQuotes(reqData: any): Observable<any> {
    const header: HttpHeaders = new HttpHeaders()
    return this.http.post(`${environment.newApiUrl}quotes/fetch`, reqData,);
  }

  deleteWorkFlowUser(reqBody: any) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: reqBody,

    };
    return this.http.delete(`${environment.newApiUrl}workflow/user-workflow`, options)
  }
  createWorkFlowUser(reqBody: any) {

    return this.http.post(`${environment.newApiUrl}workflow/user-workflow`, reqBody)
  }


  // api to upload the document file on project workflow -- Rishikant 
  uploadFile(reqFormBody: any) {
    return this.http.post(`${Utils.getBackendBaseURL()}file/workflowFileUpload`, reqFormBody, { responseType: 'text' })
  }
  getQuoteDetails(quote_id:any){
    return this.http.get(`${environment.newApiUrl}quotes/`+ quote_id)
  }

}
