import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarVerticalIndicatorComponent } from './progress-bar-vertical-indicator.component';
import { EnactIconModule } from '../enact-icon/enact-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';


@NgModule({
  declarations: [
    ProgressBarVerticalIndicatorComponent
  ],
  imports: [
    CommonModule,
    EnactIconModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    ProgressBarVerticalIndicatorComponent
  ]
})
export class ProgressBarVerticalIndicatorModule { }