
<label [attr.for]="inputId" class="enact-radio-label" #label>
  <!-- The actual 'radio' part of the control. -->
  <span class="enact-radio-container">
    <span class="enact-radio-outer-circle" [ngClass]="{'enact-checked':((checked && !disabled) || value == internalValue)}"></span>
    <span class="enact-radio-inner-circle"></span>
    <input #input class="enact-radio-input" type="radio"
        [id]="inputId"
        [checked]="checked"
        [disabled]="disabled"
        [tabIndex]="tabIndex"
        [attr.name]="name"
        [attr.value]="value"
        [required]="required"
        [attr.aria-label]="ariaLabel"
        [attr.aria-labelledby]="ariaLabelledby"
        [attr.aria-describedby]="ariaDescribedby"
        [name]="name"
        [value]="value"
        [ngModel]="internalValue"
        (ngModelChange)="onModelChange($event)">
  </span>

  <!-- The label content for radio control. -->
  <span class="enact-radio-label-content" [class.enact-radio-label-before]="labelPosition == 'before'">
    <!-- Add an invisible span so JAWS can read the label -->
    <span style="display:none">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
