import { Injectable, OnDestroy } from "@angular/core";
import { LeadService } from "./lead.service";
import { PanelConfigurationService } from "./panel-configuration.service";
import { QuoteService } from "./quote.service";
import { ServicesOfferedService } from "./services-offered.service";
import { UserPreferenceService } from "./user-preference.service";

@Injectable()
export class ApiManagementService implements OnDestroy {

  constructor(
    private leadService: LeadService,
    private panelConfigurationService: PanelConfigurationService,
    private servicesOfferedService: ServicesOfferedService,
    private userPreferenceService: UserPreferenceService
  ){}


  clearAllApiCache(){
    this.leadService.clearCache();
    this.panelConfigurationService.clearCache();
    this.servicesOfferedService.clearCache();
    this.userPreferenceService.clearCache();
  }

  ngOnDestroy(){
    this.clearAllApiCache();
  }
}