import { Component, ContentChildren, Directive, InjectionToken, OnInit, QueryList, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatRadioButton, _MatRadioButtonBase, _MatRadioGroupBase, } from '@angular/material/radio';

export const ENT_RADIO_GROUP =
  new InjectionToken<_MatRadioGroupBase<_MatRadioButtonBase>>('EntRadioGroup');

export const ENT_RADIO_GROUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EntRadioGroup),
  multi: true
};

@Directive({
  selector: 'ent-radio-group',
  exportAs: 'entRadioGroup',
  providers: [
    ENT_RADIO_GROUP_CONTROL_VALUE_ACCESSOR,
    { provide: ENT_RADIO_GROUP, useExisting: EntRadioGroup },
  ],
  host: {
    'role': 'radiogroup',
    'class': 'ent-radio-group',
  },
})

export class EntRadioGroup extends _MatRadioGroupBase<EnactRadioButtonComponent> {
  @ContentChildren(forwardRef(() => MatRadioButton), { descendants: true })
  _radios!: QueryList<EnactRadioButtonComponent>;
}

@Component({
  selector: 'enact-radiobutton',
  templateUrl: './enact-radio-button.component.html',
  styleUrls: ['./enact-radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EnactRadioButtonComponent),
      multi: true
    }],
    host: {
      'class': 'enact-radio-button',
      '[class.enact-radio-checked]': 'checked',
      '[class.enact-radio-disabled]': 'disabled',
      '[attr.tabindex]': 'null',
      '[attr.id]': 'id',
    }
})

export class EnactRadioButtonComponent extends MatRadioButton implements OnInit, ControlValueAccessor {

  model: any;
  onTouched: any;
  onChange: any;
  internalValue: any;

  onModelChange(value: any) {
    this.checked = value;
    this.onTouched(value);
    this.onChange(value);
  }

  writeValue(obj: any): void {
    this.internalValue = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    // console.log('-----RadiobuttonComponent extends MatRadioButton--->>>', this.inputId)
  }

}