import { createAction, props } from "@ngrx/store";

export namespace fromShadingActions {

    export const SetIrradianceOnLoad= createAction(
        '[Shading Configuration] set irradance data on load'
    );

    export const ChangeShadingStatus = createAction(
        '[Shading Configuration] change shading status',
        props<{ shadingStatus: string}>()
    );
    export const ToggleShading = createAction(
        '[Shading Configuration] toggle shading',
        props<{ isShadingEnabled: boolean}>()
    );

    export const ChangeHeatMapData = createAction(
        '[Shading Configuration] change heat map data',
        props<{ heatMapData: any}>()
    );

    export const ChangeThreshold = createAction(
        '[Shading Configuration] change threshold',
        props<{ solarAccessThreshold: number}>()
    );

    export const DeletePanels = createAction(
        '[Shading Configuration] delete panels',
        props<{ solarAccessThreshold: number}>()
    );

    export const SetPanelsData = createAction(
        '[Shading Configuration] set panels data',
        props<{ allPanelsData: any}>()
    );

    export const SetYearlyData = createAction(
        '[Shading Configuration] set yearly data',
        props<{ panelsYearlyData: any}>()
    );

    export const SetHoveredPanelId = createAction(
        '[Shading Configuration] hovered panel id',
        props<{ hoverPanelId: string}>()
    );

    export const SetHoveredPanelData = createAction(
        '[Shading Configuration] hovered panel data',
        props<{ hoveredPanelData: any}>()
    );

    export const GetIrradianceDataSuccess = createAction(
        '[Shading Configuration] get irradiance data success',
        props<{ response: any}>()
    );

    export const GetIrradianceDataFailure = createAction(
        '[Shading Configuration] get irradiance data failure',
        props<{ error: any}>()
    );

    export const ChangeDefaultMinThreshold = createAction(
        '[Shading Configuration] change default min threshold',
        props<{ defaultMinThreshold: number}>()
    );

    export const ClearShadingData = createAction(
        '[Shading Configuration] clear shading data'
    );

    export const SetRoofIrradiance = createAction(
        '[Shading Configuration] set roof irradiance',
        props<{ roofIrradianceList: any}>()
    );

    export const OptimumHourly = createAction(
        '[Shading Configuration] optimun hourly call',
        props<{ optimumFlag: boolean}>()
    );
}
