import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { EnactColoredTileComponent } from './enact-colored-tile.component';

@NgModule({
    declarations: [
        EnactColoredTileComponent
    ],
    imports: [
        MatRippleModule,
        CommonModule,
    ],
    exports: [
        EnactColoredTileComponent
    ]
})
export class EnactColoredTileModule { }