export interface Preferences {
    measurementUnit: string;
}

export enum MeasurementUnits {
    SQFT = 'Sqft',
    SQMT = 'Sqmt',
    FEET = 'Feet',
    METER = 'Metres',
    FT = 'ft',
    MT = 'm'
}

export enum HeightTxt{
    manualHeight="Height is set manually",
    autoHeight="Height is detected automatically",
    ResetDefault="Reset to default",
    defaultHeight="Default Roof Height",
    autoHeightDetectedDSM="Detected automatically",
    manualHeightDSM="Set manually",
    manualHeightDetectDSM="Detect automatically",
}

export enum HeightTxtTree{
    manualHeight="Set manually",
    autoHeight="Default Tree Height",
    ResetDefault="Reset to default",
}

export enum AzimuthTxt{
    disabled="It is a flat roof",
    nonDisabled="Azimuth is set to be the same as roof section.",
}