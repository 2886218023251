import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfileService } from './profile.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LocaleService } from '../../../helpers/locale.service';
import { AuthService } from '../../../helpers/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  moduleName = 'PROFILE';
  countriesList: any[] = [];
  profileForm: UntypedFormGroup = new UntypedFormGroup({});
  subscriptions: Subscription = new Subscription();

  constructor(
    private profileService: ProfileService,
    private localeService: LocaleService,
    private fb: UntypedFormBuilder,
    private authService: AuthService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    const localeId = this.authService.getItem('localeId');
    this.getCountriesList();
    this.initProfileForm(localeId);
  }
  initProfileForm(localeId: string = 'en_US'): void {
    this.profileForm = this.fb.group({
      locale: [localeId, Validators.required],
    });
  }
  get f(): any {
    return this.profileForm.controls;
  }
  getCountriesList(): void {
    this.subscriptions.add(
      this.profileService.getListOfCountries().subscribe((resp: any) => {
        this.countriesList = [];
        this.countriesList = resp;
      })
    );
  }
  onLocaleSelection(): void {
    if (!this.profileForm.valid) {
      return;
    }
    const selectedCountry = this.countriesList.find(
      (country: any) => country.country_locale === this.profileForm.value.locale
    );
    if (selectedCountry) {
      /// Found duplicate value in cookie localeId. 
      /// Remove localeId before set it.
      this.authService.removeItem('localeId');
      this.authService.setItem('localeId', selectedCountry.country_locale);
      this.localeService.registerLanguage(selectedCountry.language_code);
      this.localeService.registerCulture(selectedCountry.country_locale);
    }
  }
}
