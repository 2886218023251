import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EnactIcons } from '@lib-ui/lib/common/types/enactIcons';
import { ProgressBarInput } from '@lib-ui/lib/common/types/progressBarInput';
import { reverse } from 'lodash';

@Component({
  selector: 'lib-progress-bar-vertical-indicator',
  templateUrl: './progress-bar-vertical-indicator.component.html',
  styleUrls: ['./progress-bar-vertical-indicator.component.scss']
})
export class ProgressBarVerticalIndicatorComponent implements OnInit, OnChanges {

  @Input() progressBarData!: ProgressBarInput;
  @Input() progressReverse=false;
  @Output() overLimitCapacity = new EventEmitter<boolean>();

  constructor() {}

  lineLength = 0;
  overLimit = false;
  barProgressLength = 0;

  capacityDiff:number|''='';
  barStatus:string='';

  ngOnInit(): void {
    this.progressBarData.addedCapacityLabel = this.progressBarData.addedCapacityLabel || 'Added AC Cap';
    this.progressBarData.targetCapacityLabel = this.progressBarData.targetCapacityLabel || 'Target AC Cap';
    this.progressBarData.hideIndicator = this.progressBarData.hideIndicator || false;
    this.progressBarData.hideDiffrenceText = this.progressBarData.hideDiffrenceText || false;
    this.calculateProgress();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateProgress();
  }


  calculateProgress(){
    // this function will decide length of progressBar and length of verticel line denoting target power
    let {addedCapacity, targetCapacity, capacityUnit} = this.progressBarData;

    if(this.progressReverse){
      const temp = addedCapacity;
      addedCapacity = targetCapacity;
      targetCapacity = temp;
    }
    if(addedCapacity <= targetCapacity){
      this.overLimit = false;
      this.lineLength = 99.7;
      this.capacityDiff = Math.round(((targetCapacity - addedCapacity) + Number.EPSILON) * 100) / 100 ;
      this.barStatus = ' Remaining';
    }else{
      this.overLimit = true;
      this.capacityDiff = Math.round(((addedCapacity - targetCapacity) + Number.EPSILON) * 100) / 100 ;
      this.lineLength = Math.round(100 * ((99.7 * targetCapacity) / addedCapacity)) / 100;
      this.barStatus = ' Exceeded';
    }

    // This is to check if addedCapacity is less than 10% of required capacity 
    if(addedCapacity >= (+(Math.round((targetCapacity * .9 )* 100) / 100)?.toFixed(2)) && addedCapacity <= (+(Math.round((targetCapacity * 1.1 )* 100) / 100)?.toFixed(2))) {
      this.overLimit = false;
    }else{
      this.overLimit = true;
    }

    //progressbarlength logic
    if(addedCapacity > targetCapacity){
      this.barProgressLength = 100;
    }else{
      this.barProgressLength = Math.round(100 * (100 * (addedCapacity / targetCapacity)))/ 100;
    }
    if(this.progressReverse){

    }

    this.overLimitCapacity.emit(this.overLimit);
  }

  

}

