import { Vector3, Vector2, Plane, BufferGeometry, PlaneHelper, Object3D, BoxGeometry, MeshBasicMaterial, BufferAttribute, DoubleSide, Mesh, Shape, ShapeGeometry, MeshStandardMaterial, Color, LineDashedMaterial, Line, Scene, Quaternion, LineBasicMaterial, LineSegments, EdgesGeometry, Group, SphereGeometry, MathUtils, ArrowHelper, Triangle, Float32BufferAttribute, MeshPhongMaterial, Matrix4, Box3, Ray, Box3Helper, TorusGeometry, PointsMaterial, Points, ShapeUtils, InstancedMesh, Texture, CompressedTexture} from 'three';
import { uniqueId as _uniqId } from 'lodash';
import robustPointInPolygon from 'robust-point-in-polygon';
import * as stats from "stats-lite"

import { generateUniqueId } from '@design/utils/generateUnique';
import { TreesType } from '../../../../models/trees.model';
import { ObstacleType } from '@design/models/obstacle.model';
import { PRESET_COLORS_DEFAULT } from '@lib-ui/lib/common/types/enactColorpick';
import { ArrayAlignment, PanelArray } from '@design/models/panel-array.model';
import { PanelOrientation } from '@design/models/panel.model';
import { Store } from '@ngrx/store';
import { DesignState } from '@design/store/design.reducer';
import { Edge } from '@design/models/edge.model';
import { RoofSection } from '@design/models/roof-section.model';
import { Vertex } from '@design/models/vertex.model';
import { fromEdgeSelectors } from '@design/store/edge';
import { fromRoofSectionSelectors } from '@design/store/roof-section';
import { fromVertexSelectors } from '@design/store/vertex';
import PolygonOffsetter from './PolygonOffsetter';
import { CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { OrbitControls } from './OrbitControls';
import { fromPanelActions } from '@design/store/panel';
import { fromPanelArrayActions } from '@design/store/panel-array';
import { take } from 'rxjs/operators';
import { MeasurementUnits } from '@design/models/preferences.model';
import { fromFrameActions } from '@design/store/frame';
import { fixedFragmentShader, fixedVertexShader } from "../hotfix/meshlineHotfix";
import { PlaneFitting } from './planeFitting';
import { ConfigurationIds } from '../../../../models/ui-state.model';
import { SelectedInverter } from '@design/models/inverter.model';
import CollisionUtil from '../utils/collision.util';
import { openDialog } from '@lib-ui/lib/common/utils/openDialog';
import { MatDialog } from '@angular/material/dialog';
import { NumberLocaleFormatPipe } from 'src/app/helpers/pipes/number-format.pipe';
const { MeshLine, MeshLineMaterial } = require('three.meshline');


export default class Util {
  static siteConfig:any=null
  static heightsArray: any = null;
  static measurementUnit: MeasurementUnits;
  static lengthMeterToFeet: number = 3.28084;
  static areaMeterToFeet: number = 10.76391042;
  static areaFeetToMeter: number = 0.09290304;
  static DEFAULTUP = new Vector3(0, 0, 1);
  static TEMP_PANEL_SCALE = 1.04;//1.03;//0.95;
  static DEFAULTROOFARROWURL = './assets/threejs/roof-direction.png';
  static DEFAULT_PANEL_ARRAY_MARGIN = 0; // 5; 5 units is used to move the panels by 5 units to avoid going inside roof
  
  static getHeight(pt: Vector3, heightsArray?: any,) {
    try {

      if (Util.nonDsm) {
        // for non-DSM project, return the same height as the point as a temporary fix right now
        return pt.z;
      }

      if (!heightsArray) {
        heightsArray = this.heightsArray;
      }
      if (!this.siteConfig?.meterPerPixel || Number.isNaN(this.siteConfig?.meterPerPixel)) {
        throw new Error("Meter Per Pixel could not be found");

      }
      var pixelPerMt1 = 1 / this.siteConfig?.meterPerPixel;//this.MapData().pixelPerMt1;  // 2000 / 110; // Old one is 25
      var groundDistance = heightsArray.minmax[0][0];//this.MapData(heightsArray).groundDistance;  // 0; //this.heightsArray.min
      var x = Math.floor(pt.x);
      var y = Math.abs(Math.floor(pt.y));
      //console.log(x)
      //console.log(y_abs)

      //var height = heightsArray.heights[y_abs][x]
      var height = heightsArray.heights[x][y];
      height = height - groundDistance;
      // console.log('Height is ');
      // console.log(height);
      return height * pixelPerMt1;

    } catch (error: any) {
      if (!heightsArray) {
        throw new Error("Heights array is not loaded");
      }
      throw new Error(error.message);

    }

  }

  static getClosestStandardPitchAngleDeg(degAngleToCheck: number) {
    const closestPitch = standardRoofPitches.reduce((prev, curr) => Math.abs(curr - degAngleToCheck) < Math.abs(prev - degAngleToCheck) ? curr : prev);

    return closestPitch;
  }

  static getAreaPixelToFeet(threejsArea: number): number {
    let areaInFeet = threejsArea * Math.pow(this.siteConfig?.meterPerPixel, 2) * Util.areaMeterToFeet;
    return Math.round(areaInFeet * 100) / 100;
  }

  static getAreaFeetToPixel(ftArea: number): number {
    let areaInFeet = ftArea * Util.areaFeetToMeter;
    return Math.round(areaInFeet * 100) / 100;
  }

  static getMeasurementPixelToFeet(length: number): number {
    let lengthInFeet = length * this.siteConfig?.meterPerPixel * Util.lengthMeterToFeet;
    return Math.round(lengthInFeet * 100) / 100;
  }

  static getFeetToMeter(length: number): number {
    let lengthInMeter = length / Util.lengthMeterToFeet;
    return Math.round(lengthInMeter * 100) / 100;
  }

  static getMeterToFeet(length: number): number {
    let lengthInFeet = length * Util.lengthMeterToFeet;
    return Math.round(lengthInFeet * 100) / 100;
  }
  static getAreaFeetSqToMeterSq(length: number): number {
    const feetsq = Math.pow(Util.lengthMeterToFeet,2);
    let lengthInMeter = length / feetsq;
    return Math.round(lengthInMeter * 100) / 100;
  }

  static getAreaMeterSqToFeetSq(area:number):number{
    const feetsq = Math.pow(Util.lengthMeterToFeet,2);
    let areaInMeterSq = area * feetsq;
    return Math.round(areaInMeterSq * 100) / 100;
  }

  static getMeasurementStoreToThreejs(length: number): number {

    if (Util.measurementUnit == MeasurementUnits.FT) {
      return Util.getMeasurementFeetToPixel(length);
    } else {
      return Util.getMeasurementMeterToPixel(length);
    }

  }

  static getMeasurementFeetToPixel(length: number): number {
    let lengthInPixel = (length / Util.lengthMeterToFeet) / this.siteConfig?.meterPerPixel;
    return lengthInPixel;
  }

  static getMeasurementMeterToPixel(length: number): number {
    let lengthInPixel = length / this.siteConfig?.meterPerPixel;
    return lengthInPixel;
  }


  static CompareObjects(a: Object, b: Object) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  static PointsAreExactlySame(pointA: Vector3, pointB: Vector3) {
    // if the distance between 2 points < 0.01, it means they are exactly same
    return pointA.distanceTo(pointB) < 0.01;
  }

  static PointsTooClose(pointA: Vector3, pointB: Vector3) {
    return pointA.distanceTo(pointB) < 5;
  }

  static GetFlatShape(flatVertices: Vector2[], meshName: string): Mesh {
    const shape = new Shape(flatVertices);
    const shapeGeom = new ShapeGeometry(shape);
    const mesh = new Mesh(
      shapeGeom,
      new MeshStandardMaterial({
        color: threeColors.roofColor,
        side: DoubleSide,
      })
    );
    mesh.name = meshName;;

    return mesh;
  }
  /*static siteConfig:any;
  static MapData(heightsArray:any={minmax:[[0][0]]} , siteConfig:any=null) {

    const map1000 = {
      image: "./assets/threejs/TrueOrtho1.png",
      size: 1000,
      pixelPerMt1: 25,
      groundDistance: 128,
      heightsJson: "assets/json/jsonObject2.json",
    }

    const map2000 = {
      image: "./assets/threejs/1.png",
      size: 2000,
      pixelPerMt1: 2000 / 110, // TODO: this needs to be dynamically fetched from height API
      groundDistance: heightsArray.minmax[0][0],
      heightsJson: "assets/json/heightArray.json",
    }
    let mapToUse = map2000;
    if(siteConfig){
      const mapCustom = {
        image: "",
        size: siteConfig.size.width,
        pixelPerMt1: siteConfig.meterPerPixel, // TODO: this needs to be dynamically fetched from height API
        groundDistance: heightsArray.minmax[0][0],
        heightsJson: "",
      }
      mapToUse = mapCustom
    }

    return mapToUse;
  }*/

  static AddPlaneHelper(plane: Plane, rootObj: Object3D) {
    const helper = new PlaneHelper(plane, 3000, 0xffff00);
    helper.position.z = 100;
    rootObj.add(helper);
  }

  /**
   * debugThreejs is used to enable debug features in js scene.
   * As of today, its used to:
   * - render final fitted Plane in scene,
   * - generateDebugId in Redux store to generate simple id
   */
  static debugThreejs: boolean = false;
  static nonDsm: boolean = false;

  static generateDebugId(prefix: string) {
    return _uniqId(`${prefix}-`);
  }

  static generateStoreId(prefix: string) {
    let id = generateUniqueId(prefix);
    if (Util.debugThreejs) {
      id = Util.generateDebugId(prefix);
    }

    return id;
  }

  static drawPolygon(pts: Vector3[], meshName: string = "") {

    // const { min, max } = new Box3().setFromPoints(pts);
    // let geo = new BufferGeometry();
    // geo.setAttribute('position', new BufferAttribute(new Float32Array([
    //     min.x, max.y, min.z,
    //     max.x, max.y, max.z,
    //     min.x, min.y, min.z,
    //     max.x, min.y, max.z
    // ]), 3));
    // geo.setIndex([0, 2, 1, 2, 3, 1]);

    const [v1, v2, v3, v4] = pts;
    const geo = new BufferGeometry();
    const vertices = new Float32Array([
      v1.x, v1.y, v1.z,
      v2.x, v2.y, v2.z,
      v3.x, v3.y, v3.z,
      v4.x, v4.y, v4.z,

    ]);

    const uvs = new Float32Array(
      // uvs is necessary to add texture in buffferGeometry
      // this is necessary for panelGeometry
      // ref: https://discourse.threejs.org/t/three-js-buffergeometry-texture/30562/2
      [
        0.0, 0.0,
        1.0, 0.0,
        1.0, 1.0,
        0.0, 1.0
      ])

    const quad_indices = new Uint32Array([
      // indices is necessary to add texture in buffferGeometry
      // this is necessary for panelGeometry
      0, 1, 2, 0, 2, 3
    ]);


    // itemSize = 3 because there are 3 values (components) per vertex
    geo.setAttribute('position', new BufferAttribute(vertices, 3));
    geo.setAttribute('uv', new BufferAttribute(uvs, 2));
    geo.setIndex(new BufferAttribute(quad_indices, 1));
    geo.computeVertexNormals();   //0x808080
    const color = () => {
      switch (meshName) {
        case meshTypeEnum.wall:
          return threeColors.wallColor;

        case meshTypeEnum.panelMesh:
          return threeColors.panelColor

        default:
          return "gray"
      }
    }
    const mat = new MeshStandardMaterial({ color: color(), side: DoubleSide });
    const mesh = new Mesh(geo, mat);
    mesh.name = meshName;
    //mesh.receiveShadow = true;
    //mesh.castShadow = true;
    return mesh;
  }

  /**
* Checks if the obstacle is completely inside the roof polygon.
* @param obstacle - The obstacle vertices.
* @param roofVertices - The vertices of the roof polygon.
* @returns True if any part of the obstacle is outside the roof, otherwise false.
*/
  static checkObstacleOutsideRoof(obstVertices: Vector3[], roofVertices: Vector3[]): boolean {
    // return obstVertices.some((oV) => !Util.isPointInPolygon(roofVertices, oV));
    return obstVertices.some((oV) => !CollisionUtil.isPointInPolygon(roofVertices, oV));
  }

  static getMeshNormal(mesh: Mesh) {
    // ref: https://discourse.threejs.org/t/how-to-get-the-normal-of-a-face-triangle-buffergeometry/23768/5
    let pos = (mesh.geometry as BufferGeometry).getAttribute('position') as BufferAttribute;
    let idx = (mesh.geometry as BufferGeometry).getIndex() as BufferAttribute;

    let tri = new Triangle(); // for re-use
    let a = new Vector3(),
      b = new Vector3(),
      c = new Vector3(); // for re-use
    let normalVec = new Vector3();

    // for (let f = 0; f < 2; f++) {
    //   let idxBase = f * 3;
    a.fromBufferAttribute(pos, 0);
    mesh.localToWorld(a);
    b.fromBufferAttribute(pos, 1);
    mesh.localToWorld(b);
    c.fromBufferAttribute(pos, 2);
    mesh.localToWorld(c);

    tri.set(a, b, c);
    tri.getNormal(normalVec);
    //otherstuff
    return normalVec.normalize();
    // }

    // return undefined
  }

  /**
    * Extracts unique vertices from the edges of the meshes in the group.
    * @param group - The group containing cylinder edge meshes.
    * @returns An array of unique vertices.
    */
  static getVerticesFromEdgeGroup(group: Group): Vector3[] {
    const vertices: Vector3[] = [];

    group.children.forEach((child) => {
      if (child instanceof Mesh) {
        const geometry = child.geometry;
        const positionAttribute = geometry.attributes.position;
        const matrix = child.matrixWorld;

        // Assuming the first and last vertices represent the edges
        const edgeVerticesIndices = [0, positionAttribute.count - 1];

        edgeVerticesIndices.forEach((index) => {
          const vertex = new Vector3().fromBufferAttribute(positionAttribute, index);
          vertex.applyMatrix4(matrix);
          vertices.push(vertex);
        });
      }
    });

    // Remove duplicates by rounding to two decimal places
    const uniqueVertices = vertices.filter((v, i, a) =>
      a.findIndex(t =>
        (Math.round(t.x * 100) / 100 === Math.round(v.x * 100) / 100) &&
        (Math.round(t.y * 100) / 100 === Math.round(v.y * 100) / 100)
      ) === i
    );

    return uniqueVertices;
  }

  static getAxisAndAngelFromQuaternion(q: Quaternion) {
    const angle = 2 * Math.acos(q.w);
    var s;
    if (1 - q.w * q.w < 0.000001) {
      // test to avoid divide by zero, s is always positive due to sqrt
      // if s close to zero then direction of axis not important
      // http://www.euclideanspace.com/maths/geometry/rotations/conversions/quaternionToAngle/
      s = 1;
    } else {
      s = Math.sqrt(1 - q.w * q.w);
    }
    return { axis: new Vector3(q.x/s, q.y/s, q.z/s), angle };
  }
  static renderArrowHelper(parentObject: Object3D, dirVec: Vector3, origin: Vector3, renderAlways: Boolean = this.debugThreejs) {
    if (renderAlways) {
      const arrowLength = 100;
      const arrowColor = 0x00ff00;
      const arrowHelper = new ArrowHelper(dirVec, origin, arrowLength, arrowColor);
      arrowHelper.name = "debugArrowHelper"
      parentObject.add(arrowHelper);
    }
  }

  static calculateArea(mesh: Mesh | undefined) {
    // ref: https://doc.babylonjs.com/toolsAndResources/utilities/area
    // alternative method is to use local coordinates of roof to calculate area using ShapeUtils.area - check in panelGeometry `ShapeUtils.area`
    if (!mesh) {
      return 0.0;
    }
    var ar = 0.0;

    var indices = mesh.geometry.getIndex()?.array ?? [];
    var nbFaces = indices.length / 3;

    for (let i = 0; i < nbFaces; i++) {
      ar = ar + this.facetArea(mesh, i);
    }
    return ar;
  }

  static facetArea = function (mesh: Mesh, faceId: number) {

    var indices = mesh.geometry.getIndex()?.array ?? [];

    if (!mesh || !indices) {
      return 0.0;
    }
    var nbFaces = indices.length / 3;
    if (faceId < 0 || faceId > nbFaces) {
      return 0.0;
    }
    var positions = (mesh.geometry.getAttribute('position') as BufferAttribute).array;
    var v1x = 0.0;
    var v1y = 0.0;
    var v1z = 0.0;
    var v2x = 0.0;
    var v2y = 0.0;
    var v2z = 0.0;
    var crossx = 0.0;
    var crossy = 0.0;
    var crossz = 0.0;
    var ar = 0.0;
    var i1 = 0;
    var i2 = 0;
    var i3 = 0;

    i1 = indices[faceId * 3];
    i2 = indices[faceId * 3 + 1];
    i3 = indices[faceId * 3 + 2];
    v1x = positions[i1 * 3] - positions[i2 * 3];
    v1y = positions[i1 * 3 + 1] - positions[i2 * 3 + 1];
    v1z = positions[i1 * 3 + 2] - positions[i2 * 3 + 2];
    v2x = positions[i3 * 3] - positions[i2 * 3];
    v2y = positions[i3 * 3 + 1] - positions[i2 * 3 + 1];
    v2z = positions[i3 * 3 + 2] - positions[i2 * 3 + 2];
    crossx = v1y * v2z - v1z * v2y;
    crossy = v1z * v2x - v1x * v2z;
    crossz = v1x * v2y - v1y * v2x;

    return Math.sqrt(crossx * crossx + crossy * crossy + crossz * crossz) * 0.5;
  }

  static isPointInPolygon(roofPolygon2DPoints: Vector3[] | Vector2[], pointOnRoof2D: Vector3 | Vector2, includeOnPolygon:Boolean=true) {
    // // ref: https://stackoverflow.com/a/29915728/6908282
    // // use https://www.npmjs.com/package/robust-point-in-polygon instead for better result
    // // this also check if point is exactly on boundary

    //  method 1 - manual calculation

    // let x = pointOnRoof2D.x, y = pointOnRoof2D.y;
    // let inside = false;
    // for (let i = 0, j = roofPolygon2DPoints.length - 1; i < roofPolygon2DPoints.length; j = i++) {
    //   let xi = roofPolygon2DPoints[i].x, yi = roofPolygon2DPoints[i].y;
    //   let xj = roofPolygon2DPoints[j].x, yj = roofPolygon2DPoints[j].y;

    //   let intersect = ((yi > y) != (yj > y))
    //     && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    //   if (intersect) inside = !inside;
    // }

    // method 2 using robust-point-in-polygon npm package
    type Point = [number, number];
    const polygon: Point[] = [];
    roofPolygon2DPoints.forEach((pt: Vector3 | Vector2) => {
      polygon.push([pt.x, pt.y])
    });
    // roofPolygon2DPoints.map((pt: Vector3 | Vector2) => ([pt.x, pt.y] as Point));
    const point: Point = [pointOnRoof2D.x, pointOnRoof2D.y];
    const checkPt = robustPointInPolygon(polygon, point);

    const ptIsOnPolygon = checkPt == 0;
    const ptIsInPolygon = checkPt == -1;
    const ptIsOutsidePolygon = checkPt == 1;
    let validPoint;
    if (includeOnPolygon) {
      validPoint = ptIsInPolygon || ptIsOnPolygon;
    } else {
      validPoint = ptIsInPolygon;
    }

    return validPoint;  // inside;
  }

  /**
   * convert angle in degree to radians
   */
  static convertDegreeToRadian(degree: number) {
    // const degToRad= (degree * (Math.PI / 180))
    return MathUtils.degToRad(degree);
  }

  static convertRadiansToDegrees(radians: number) {
    return MathUtils.radToDeg(radians);
  }

  static singlePanelEdgeDetection(panel: Object3D, setbackOrRoof: Vector3[]) {
    let tempPanelVertices = this.getMeshVertices(panel as any, true);
    let tempPanelVertices2D = tempPanelVertices.map(
      (m) => new Vector2(m.x, m.y)
    );
    let setbackOrRoof2D = setbackOrRoof.map((m) => new Vector2(m.x, m.y));
      setbackOrRoof2D.push(setbackOrRoof2D[0]);
      let res = CollisionUtil.isEdgeIntersecting(
        setbackOrRoof2D,
        tempPanelVertices2D
      );
    return res;
  }

  static singlePanelOverlappingPanels(obj1: BufferGeometry, obj2: BufferGeometry) {
    if(obj1 && obj2){
      let panelVertices = this.getGeometryVertices(obj1);
      let panelVertices2D = panelVertices.map((m) => new Vector2(m.x, m.y));
      let tempPanelVertices = this.getGeometryVertices(obj2);
      let tempPanelVertices2D = tempPanelVertices.map((m) => new Vector2(m.x, m.y));

      let res = CollisionUtil.isEdgeIntersecting(panelVertices2D, tempPanelVertices2D);
      if(res){
        return obj1;
      }else{
        return null;
      }
    }
    return false;
  }

  static edgesFromVertices(vertices: any[]) {
    let edges = vertices.map((pt: Vector3 | Vector2, i: number) => {
      if (i < vertices.length - 1) {
        const edgePt: any = [pt, vertices[i + 1]];
        return edgePt;
      } else {
        return [pt, vertices[0]]
      }
    });
    return edges;
  }

  static rotateAroundWorldAxis(obj: Mesh, point: Vector3, axis: Vector3, radAngle: number) {
    // rotate object around axis in world space (the axis passes through point)
    // axis is assumed to be normalized
    // assumes object does not have a rotated parent
    var q = new Quaternion();
    q.setFromAxisAngle(axis, radAngle);
    obj.geometry.translate(-point.x, -point.y, -point.z);
    obj.geometry.applyQuaternion(q);
    obj.geometry.translate(point.x, point.y, point.z);
    return;
  }

  static getPlaneFromMesh(mesh: Mesh){
    let points = this.getMeshVertices(mesh);
    let plane = new Plane().setFromCoplanarPoints(points[0], points[1], points[2]);
    if(plane.constant<0){
        plane.negate();
    }
    return plane;
  }
  
  /**
   * Rotates points around an axis in world space (the axis passes through a point).
   * Assumes the axis is normalized and the object does not have a rotated parent.
   * 
   * @param points - Array of points to rotate
   * @param axis - Axis to rotate around (assumed to be normalized)
   * @param radAngle - Angle in radians to rotate
   * @param point - Point through which the axis passes
   * @returns Array of rotated points
   */
  static rotatePointsAroundAxis(points: Vector3[], axis: Vector3, radAngle: number, point: Vector3): Vector3[] {
    const quaternion = new Quaternion();
    quaternion.setFromAxisAngle(axis, radAngle);
    const rotatedPoints: Vector3[] = [];

    points.forEach(p => {
      const rotatedPoint = p.clone().sub(point).applyQuaternion(quaternion).add(point);
      rotatedPoints.push(rotatedPoint);
    });

    return rotatedPoints;
  }

  static AddHeightTo2dShape(mesh: Mesh, vertexMeshes: Object3D[] | number, offset: number = 0) {
    // TODO: this is also used in planefitting, create a Utility method
    // const bbox = new THREE.Box3().setFromObject(mesh);
    // const size = new THREE.Vector3();
    // bbox.getSize(size);
    // const vec3 = new THREE.Vector3(); // temp vector
    const attPos = mesh.geometry.attributes.position as BufferAttribute;
    // const attUv = mesh.geometry.attributes.uv as THREE.BufferAttribute;
    // for (let i = 0; i < attPos.count; i++) {
    //   vec3.fromBufferAttribute(attPos, i);
    //   attUv.setXY(
    //     i,
    //     (vec3.x - bbox.min.x) / size.x,
    //     (vec3.y - bbox.min.y) / size.y
    //   );
    // }

    // turn vectors' values to a typed array

    let bufferPoints: number[];

    let numVerts;

    if (typeof vertexMeshes === "number") {
      bufferPoints = [];

      for (let i = 0; i < attPos.array.length / 3; i++) {
        const pt = new Vector3().fromBufferAttribute(attPos, i).clone();
        pt.setZ(vertexMeshes);

        bufferPoints.push(pt.x, pt.y, pt.z + offset)
      }
      numVerts = attPos.array.length / 3;
    } else {
      const pts = vertexMeshes.map((v, i) => {
        const pos = v.position.clone();

        if (pos.x == 0 && pos.y == 0 && pos.z == 0) throw new Error("one of the roof vertices is at origin (offset out of bounds)");
        return pos;
      });
      const firstPtInMesh = new Vector3().fromBufferAttribute(attPos, 0).clone().setZ(0);
      const lastPt = pts[pts.length - 1].clone().setZ(0);
      const isPtsReversed = Util.PointsAreExactlySame(firstPtInMesh, lastPt);
      if (isPtsReversed) {
        // if the first point in mesh is the same as the last point in vertex, that means that the vertices are stored in reverse order
        pts.reverse()
      }
      numVerts = vertexMeshes.length;
      bufferPoints = pts.slice(0).flatMap(v => {
        return [v.x, v.y, v.z + offset];
      });
    }
    const F32A = new Float32Array(bufferPoints);
    // attPos.set(F32A);
    mesh.geometry.attributes.position = new BufferAttribute(F32A, 3);

    // mesh.position.copy(new Vector3());
    mesh.position.setZ(0); // This is useful when a mesh is translated from RHS, but this method updates the geometry attributes

    this.addBufferJSON(mesh)

    if (numVerts !== bufferPoints.length / 3) {
      console.error("Addheight to Shape Error: new shape has more points that intended")
    }
    return { numVerts, bufferPoints }
  }

  static addBufferJSON(mesh: Mesh) {
    const bufferGeo = mesh.geometry;
    const jsonGeo = bufferGeo.toNonIndexed().toJSON();

    (mesh.userData as MeshUserData).bufferGeoJSON = jsonGeo;
  }

  static extrudeMesh(vertArray: Vector3[], type: string = "", height: number = 0) {
    const wallGroup = new Group();
    wallGroup.name = "walls";

    const vertices = vertArray.slice(0); // shallow copy vertArray to not change original array
    vertices.push(vertArray[0]);
    for (let i = 0; i < vertices.length - 1; i++) {

      const v1 = vertices[i].clone();
      const v2 = vertices[i + 1].clone();
      const v3 = height ==0 ? v2.clone(): v2.clone().setZ(height);
      const v4 =  height ==0 ? v3.clone():v1.clone().setZ(height);

      const wall: Mesh = Util.drawPolygon([v1, v2, v3, v4], meshTypeEnum.wall);
      wall.material = new MeshStandardMaterial({ color: threeColors.wallColor, side: DoubleSide })
      wall.castShadow = true;
      if (type !== ObstacleType.circle) {
        this.generateMeshEdges(wall);
      }
      wall.castShadow = true;

      wallGroup.add(wall);

    }

    // if (height > 0) {
    //   // if height is 0, then no need to generate a plane since it won't be visible
    //   const capVerts = vertArray.slice(0, 4).map(pt => pt.clone().setZ(height));
    //   const extrudeCap: THREE.Mesh = Util.drawPolygon(capVerts, "extrudeCap");
    //   wallGroup.add(extrudeCap);
    // }

    return wallGroup;
  }


  static generateMeshEdges(mesh: Mesh) {
    // Remove old edge if it exists
    let oldEdges = mesh.getObjectByName(meshTypeEnum.edges2d);
    if (oldEdges) {
      mesh.remove(oldEdges);
    }

    // Add new edge
    let edgesGeometry = new EdgesGeometry(mesh.geometry);
    let edges = new LineSegments(edgesGeometry, new LineBasicMaterial({
      color: threeColors.wallEdgeColor
    }));
    edges.name = meshTypeEnum.edges2d;
    edges.computeLineDistances();
    mesh.add(edges);
  }

  static getCenter(mesh: Mesh, worldPt: boolean = false) {
    const bbox = new Box3().setFromObject(mesh, true);
    const center = bbox.getCenter(new Vector3());
    if (worldPt) {
      center.applyMatrix4(mesh.matrixWorld);
    }
    return center;
  }

  static getMeshVertices(mesh: Mesh, worldPt: boolean = false) {
    // ref: https://discourse.threejs.org/t/solved-geometry-vertices-is-undefined/3133/2
    const positionsBuffer = mesh.geometry.getAttribute('position') as BufferAttribute;
    const meshClone = mesh.clone();
    meshClone.updateMatrix();
    const meshcloneMatrix = meshClone.matrixWorld;
    // const indexBuffer = mesh.geometry.getIndex();

    const pts: Vector3[] = [];
    for (let i = 0; i < positionsBuffer.count; i++) {
      const point = new Vector3().fromBufferAttribute(positionsBuffer, i);
      if (worldPt) {
        point.applyMatrix4(meshcloneMatrix);
      }
      pts.push(point);
    }

    return pts;
  }
  static getGeometryVertices(geometry: BufferGeometry) {
    // ref: https://discourse.threejs.org/t/solved-geometry-vertices-is-undefined/3133/2
    const positionsBuffer = geometry.getAttribute('position') as BufferAttribute;
    const pts: Vector3[] = [];
    for (let i = 0; i < positionsBuffer.count; i++) {
      const point = new Vector3().fromBufferAttribute(positionsBuffer, i);
      pts.push(point);
    }

    return pts;
  }

  static lineEqFromPoints(a: Vector3 | Vector2, b: Vector3 | Vector2) {
    var m = (b.y - a.y) / (b.x - a.x); // slope
    var c = (b.x * a.y - a.x * b.y) / (b.x - a.x);
    return { m, c }
  }

  static pointDistFromLine(a: Vector3 | Vector2, line: { m: number, c: number }) {
    let perpendicular = { m: -1 / line.m, c: a.y +(1 / line.m) * a.x }
    let intersect = this.getIntersectionPoint(line, perpendicular);
    let intersectVector = new Vector2(intersect.x, intersect.y);
    let distance = new Vector2(a.x, a.y).distanceTo(intersectVector);
    return { distance, intersectVector }
  }

  static getIntersectionPoint(line1: { m: number, c: number }, line2: { m: number, c: number }) {
    const x = (line2.c - line1.c) / (line1.m - line2.m);
    const y = line1.m * x + line1.c;
    return { x, y };
  }

  static addMeshPositionToGeometry(mesh: Mesh, localCoords: Vector3) {
    const positionAttr = (mesh.geometry as BufferGeometry).getAttribute('position');
    for (let i = 0; i < positionAttr['array'].length; i += 3) {
      positionAttr['array'][i] += localCoords.x; // Add mesh's X position
      positionAttr['array'][i + 1] += localCoords.y; // Add mesh's Y position
      positionAttr['array'][i + 2] += localCoords.z;
    }
    positionAttr.needsUpdate = true;
  }

  static projectPointOnPlane(point: Vector3, plane: Plane, z: number = 0) {
    let target = new Vector3();

    // Ray intersect
    const ray = new Ray(point.clone().setZ(z), Util.DEFAULTUP);
    const projectedPoint = ray.intersectPlane(plane, target);

    let targetProj = new Vector3();
    plane.projectPoint(point, targetProj);

    if (projectedPoint == null) {
      plane.projectPoint(point, target);
    }

    return target;
  }

  static exportSceneToJSON(scene: Scene, cameraControls: OrbitControls) {
    const exportScene = new Scene();
    exportScene.name = 'ExportScene';

    // Update the world matrix
    scene.traverse((object) => {
      if (object instanceof Mesh) {
        object.updateMatrixWorld(true);
      }
    });

    const filterItemsByName = scene.children.filter((child) => !['gridHelper', meshTypeEnum.MapPlane, meshTypeEnum.groundPlane, 'ambLight', meshTypeEnum.tempTextAnnotation, meshTypeEnum.orthographicCamera, meshTypeEnum.perspectiveCamera, 'Anchor', meshTypeEnum.edges2d].includes(child.name));
    const filterItemsByType = filterItemsByName.filter(child => !["", meshTypeEnum.orthographicCamera, meshTypeEnum.perspectiveCamera].includes(child.type));

    const cloneFilteredItems = filterItemsByType.map(c => c.clone(true));

    exportScene.add(...cloneFilteredItems);

    // NOTE: Post processing to remove EdgesGeometry since it is not supported by ObjectLoader
    let edges2d = exportScene.getObjectsByProperty('name', meshTypeEnum.edges2d);
    edges2d.forEach(edge => edge.removeFromParent());

    const orbitControlsData = new OrbitControlsData(cameraControls.object.position.clone(), cameraControls.target.clone(), cameraControls.object.zoom);

    exportScene.userData.orbitControlsData = orbitControlsData;

    const exportJSON = exportScene.toJSON() as any;
    // NOTE: Post processing to remove EdgesGeometry since it is not supported by ObjectLoader
    exportJSON.geometries = exportJSON.geometries?.filter((geo: any) => !['EdgesGeometry'].includes(geo.type));
    exportJSON.images = (exportJSON.images as Array<any>)?.slice(1);
    exportJSON.textures = (exportJSON.textures as Array<any>)?.slice(1);
    return exportJSON;
  }

  static deletePreExistingText(id: string) {
    let label = document.getElementById(id);
    label?.remove();
  }

  static reGenerateCSS3DObjects(obj: CSS3DObject, name: string, numberLocaleFormatPipe: NumberLocaleFormatPipe) {
    // let label = document.getElementById(name);
    // label?.remove();
    const textDiv = document.createElement('label');
    textDiv.id = name;
    let value = obj.userData.value?.split(' ')[0];
    textDiv.textContent = numberLocaleFormatPipe.transform(value,2,0) as string +' '+ obj.userData.value.split(' ')[1];
    textDiv.style.color = 'black';
    textDiv.style.background = 'white';
    textDiv.style.fontFamily = 'inter-regular';
    textDiv.style.paddingLeft = '3px'//'2.14px';
    textDiv.style.paddingRight = '3px'//'2.14px';
    textDiv.style.lineHeight = '12px';//'6px';
    textDiv.style.fontSize = '9.5px'//'5px';
    textDiv.style.fontWeight = '400';

    const textLabel = new CSS3DObject(textDiv);
    const newPosition = new Vector3(obj.position.x, obj.position.y, 50);
    textLabel.position.copy(newPosition);
    textLabel.name = name;
    textLabel.userData = {value: obj.userData.value}
    textLabel.visible = false;//obj.visible;
    // Angle rotation check if required
    if(obj.userData.hasOwnProperty('angle')){
      textLabel.userData['angle'] = obj.userData.angle;
      textLabel.setRotationFromAxisAngle(Util.DEFAULTUP, obj.userData.angle);
    }
    //TODO: Remove this translate part in next release
    if(obj.userData.hasOwnProperty('translate')){
      textLabel.userData['translate'] = obj.userData.translate;
      textLabel.translateY(obj.userData.translate);
    }
        // https://enactsystems.atlassian.net/browse/ENV-5936
    textLabel.element.style.pointerEvents = 'none';
    return textLabel;
  }

  static createVertex(type: number, dia: number = constants.VERTEXDIA): Mesh {
    const boxSize = dia - 1;

    const sphereGeo = new SphereGeometry(dia / 2, 16, 8);
    const material = new MeshBasicMaterial({
      color: type == vertexType.spherical ? threeColors.finalVertex : threeColors.tempVertex,
      side: DoubleSide,
      // transparent: true,
      // opacity: 0.5,
    });

    const geometry = sphereGeo;  // type == vertexType.spherical ? sphereGeo : boxGeo;
    var vertex = new Mesh(geometry, material);
    vertex.name = type == vertexType.spherical ? "RoofVertex" : "tempVertex";
    return vertex;
  }
  static createDonutVertex(): Mesh {
    const torusGeo = new TorusGeometry(constants.VERTEXDIA/2-0.5, 0.5, 8);
    const material = new MeshBasicMaterial({
      color: threeColors.finalVertex,
      side: DoubleSide,
    });

    var vertex = new Mesh(torusGeo, material);
    return vertex;
  }
  static getPointInBetweenByPerc(pointA: Vector3, pointB: Vector3, percentage: number = 50) {

    const percent = percentage / 100;

    let dir = pointB.clone().sub(pointA);
    const len = dir.length();
    const halfDir = dir.normalize().multiplyScalar(len * percent);
    return pointA.clone().add(halfDir);

  }

  static getCenterPoint(mesh: Mesh | Group) {
    var boundingBox = new Box3();
    //var geometry = mesh.geometry;
    if(mesh instanceof Mesh){
      mesh.geometry.computeBoundingBox();
      boundingBox.copy( mesh.geometry.boundingBox as Box3 );
    }
    if(mesh instanceof InstancedMesh){
      mesh.computeBoundingBox();
      boundingBox.copy( mesh.boundingBox as Box3 );
    }
    if(mesh instanceof Group){
      boundingBox.setFromObject(mesh)
    }

    mesh.updateMatrixWorld( true );
    boundingBox.applyMatrix4( mesh.matrixWorld );
    var center = new Vector3();
    boundingBox.getCenter( center )
    mesh.localToWorld( center )

    //geometry.computeBoundingBox();
    //var center = new Vector3();
    //if(geometry.boundingBox)
    //geometry.boundingBox.getCenter( center );
    // mesh.localToWorld( center );
    return center;
  }

  static calculateAzimuth(confirmedPlane: Plane | undefined, gstartVertPos: Vector3, endVertPos: Vector3, roofCenter: Vector3, pitch: number) {
  let azimuthDeg: number = 0;
  if (pitch == 0) {

  azimuthDeg = Util.GetAzimuthFromEdge(gstartVertPos, endVertPos, roofCenter);
  } else if (confirmedPlane) {
  let n = confirmedPlane?.normal;
  if (n.z < 0) {
  n.multiplyScalar(-1)
  }
      // azimuth: https://discourse.threejs.org/t/how-to-calculate-the-azimuth-of-two-vectors/24022/17
  // http://blog.patrikstas.com/2015/11/05/what-is-difference-between-atan-and-atan2/
  const angle = Math.atan2(n.y, n.x);
  const positiveAngle = (angle + Math.PI * 2.0) % (Math.PI * 2.0); //https://stackoverflow.com/a/76432860/6908282

  // positiveAtan2 is in anti-clockwise direction and starts from X-Axis.
  // Below code converts it to clock-wise and starts from Y-Axis
  let yAxisAzimuth = Math.PI / 2 - positiveAngle;
  if (yAxisAzimuth < 0) {
  yAxisAzimuth = Math.PI * 2 + yAxisAzimuth;
  }
      azimuthDeg = Math.round(MathUtils.radToDeg(yAxisAzimuth));


  // // azimuth -= Math.PI * 0.5;
  // if (azimuth < Math.PI / 2) {
  //     azimuth = Math.PI / 2 - azimuth;
  // }
  // else if (azimuth < 0) {
  //     azimuth = Math.PI / 2 - azimuth;
  // } else if (azimuth > Math.PI / 2) {
  //     azimuth = Math.PI * 2 - azimuth + Math.PI / 2;
  // }
  // // azimuth += azimuth < 0 ? Math.PI * 2 : 0;

  // console.log("azimuthAngle: " + MathUtils.radToDeg(azimuth));
  // azimuth += azimuth < 0 ? Math.PI * 2 : 0;


  }

  return azimuthDeg;
  }

  static calculatePitch(confirmedPlane: Plane) {
    let n = confirmedPlane.clone()?.normal;
    if (n.z < 0) {
      n.multiplyScalar(-1)
    }
    let pitch = n.angleTo(Util.DEFAULTUP); // normal angle with vertical z axis
    // return Math.round(MathUtils.radToDeg(pitch));
    const degRad = MathUtils.radToDeg(pitch);
    return Math.round(degRad * 100) / 100;
  }

  static GetAzimuthFromEdge(edgeStart: Vector3, edgeEnd: Vector3, roofCenter: Vector3) {
    // const edgeVector = Util.GetVectorByPoints(edgeStart, edgeEnd);
    // edgeVector.setZ(0);

    let a = edgeStart.clone().sub(roofCenter);
    let b = edgeEnd.clone().sub(roofCenter);
    var m = (b.y - a.y) / (b.x - a.x); // slope
    var c = (b.x * a.y - a.x * b.y) / (b.x - a.x);
    let m1 = -1 / m;
    let intersection = new Vector3(c / (m1 - m), m1 * c / (m1 - m), 0).normalize();

    // edgeVector is always perpendicular to the azimuth vector, so rotate it by 90 degrees
    // edgeVector.applyAxisAngle(new Vector3(0, 0, 1), -Math.PI / 2);

    let azimuthDeg = Util.counterCloswiseVectorAngle(intersection, new Vector3(0, 1, 0));
    azimuthDeg = Math.round(azimuthDeg < 0 ? 360 + azimuthDeg : azimuthDeg);

    return azimuthDeg;
  }

  static GetVectorByPoints(fromVector: Vector3, toVector: Vector3) {
    // ref: https://stackoverflow.com/a/40489763
    const vector = new Vector3();
    vector.subVectors(toVector, fromVector).normalize();

    return vector;
  }

  static ObstacleMaterial() {
    return new MeshBasicMaterial({
      color: threeColors.obstacleColor,
      side: DoubleSide,
      transparent: true,
      opacity: 0.5,
    });
  }

  static TreeMaterial() {
    return new MeshBasicMaterial({
      color: threeColors.tree_colour_new,
      side: DoubleSide,
      // transparent: true,
      // opacity: 0.9,
    });
  }

  static TreeTrunkMaterial() {
    return new MeshBasicMaterial({
      color: threeColors.treeStemColor,
      // side: DoubleSide,
      // transparent: true,
      // opacity: 0.9,
    });
  }

  static TreeTorusMaterial() {
    return new MeshBasicMaterial({
      color: threeColors.finalVertex,
      // side: DoubleSide,
      // transparent: true,
      // opacity: 0.5,
    });
  }

  static checkVectorsParallel(v1: Vector3, v2: Vector3) {
    // ref: https://stackoverflow.com/a/7572668/6908282

    const dotProduct = Math.abs(v1.dot(v2) / (v1.length() * v2.length()));

    return dotProduct > 1 - Number.EPSILON;
  }

  static counterCloswiseVectorAngle(v1: Vector3, v2: Vector3) {
    // ref: https://discourse.threejs.org/t/counterclockwise-angle-between-two-3d-vectors/35662/7
    return ((v1.clone().cross(v2).z < 0 ? -1 : 1) * Math.acos(v1.dot(v2)) * 180 / Math.PI);

  }
  static overrideObjWithNewValues(prevObj: any, newObj: any) {
    // ref: https://stackoverflow.com/a/62864451/6908282
    // const res = {};
    Object.keys(prevObj)
      .forEach(k => {
        const finalProp = (newObj.hasOwnProperty(k) ? newObj[k] : prevObj[k]);

        prevObj[k] = finalProp;
        // res[k] = finalProp;

      });
    // return res;
  }

  static getPointsFromGeometery(geometry: BufferGeometry) {
    let points: Vector3[] = [];
    let position: BufferAttribute = geometry.attributes.position as BufferAttribute;
    for (let vi = 0; vi < position.array.length; vi += 3) {
      let v0 = new Vector3(position.array[vi], position.array[vi + 1], position.array[vi + 2]);
      points.push(v0);
    }
    return points;
  }

  /**
 * Applies a UV map to the given mesh using the provided mapPlane.
 *
 * @param {Mesh} mesh - The mesh to which the UV map will be applied.
 * @param {Mesh | undefined} mapPlane - The mesh that provides the texture map and parent size.
 */
  static applyUVMap(mesh: Mesh, mapPlane: Mesh | undefined, texture?: Texture): void {
    if (!mapPlane) {
      console.warn('Invalid mapPlane provided.');
      return;
    } else if (!mesh) {
      console.warn('Invalid mesh provided.');
      return;
    }

    try {
      // Set the material with the map from mapPlane
      const mapPlaneMaterial = mapPlane.material as MeshPhongMaterial;
      mesh.material = new MeshPhongMaterial({
        side: DoubleSide,
        transparent: true,
        map: texture
      });

      // Define custom property for the material
      mesh.material.defines = mesh.material.defines || {};
      mesh.material.defines.CUSTOM = "";

      // Get world points of the mesh
      const meshWorldPts = Util.getMeshVertices(mesh, true);

      // Calculate bounding box and UV mapping parameters
      const bbox = new Box3().setFromObject(mapPlane);
      const offset = new Vector2(-bbox.min.x, -bbox.min.y);
      const range = new Vector2(bbox.max.x - bbox.min.x, bbox.max.y - bbox.min.y);

      // Calculate UV coordinates
      const quadUV: number[] = [];
      meshWorldPts.forEach(pt => {
        quadUV.push((pt.x + offset.x) / range.x);
        quadUV.push((pt.y + offset.y) / range.y);
      });

      // Apply UV coordinates to the mesh geometry
      mesh.geometry.setAttribute('uv', new BufferAttribute(new Float32Array(quadUV), 2));
      mesh.geometry.computeVertexNormals();

    } catch (error) {
      console.error('Error computing UV: ', error);
    }
  }

  static getObjectsByUserDataProperty(object: Object3D, name: string, value: any) {
    // https://discourse.threejs.org/t/getobject-by-any-custom-property-present-in-userdata-of-object/3378/3

    const meshes: Object3D[] = [];
    object.traverse((node) => {
      if (node.userData[name] === value) {
        meshes.push(node);
      }
    });
    return meshes;
  }

  static getGutterEdgeFromStore(roofId: string, store:Store<DesignState>) {
    let roof: RoofSection | undefined;
    let gutterEdge: Edge | undefined;
    let startVertex: Vertex | undefined;
    let endVertex: Vertex | undefined;
    store.select(fromRoofSectionSelectors.selectRoofById([roofId])).pipe(take(1)).subscribe((r) => roof = r[0]);
    if (roof) {
      if(roof.pitch > 0){
        // For tilted roof align obstacles to gutter edge of roof.
        store.select(fromEdgeSelectors.selectEdgesByIds([roof.gutterId])).pipe(take(1)).subscribe((e) => { gutterEdge = e[0] })
      }else{
        // For flat roof align obstacles to first edge of roof.
        store.select(fromEdgeSelectors.selectEdgesByIds([roof.edgeIds[0]])).pipe(take(1)).subscribe((e) => { gutterEdge = e[0] })
      }
    }
    if (gutterEdge) {
      store.select(fromVertexSelectors.selectVerticesByIds([gutterEdge.startVertexId])).pipe(take(1)).subscribe((sv) => { startVertex = sv[0] })
      store.select(fromVertexSelectors.selectVerticesByIds([gutterEdge?.endVertexId!])).pipe(take(1)).subscribe((ev) => { endVertex = ev[0] })
    }
    if (startVertex && endVertex) {
      return {
        x: startVertex.x - endVertex.x,
        y: startVertex.y - endVertex.y,
        z: startVertex.z - endVertex.z,
      }
    }
    return;
  }
  static applyRectRotation(mesh: Mesh, edge: Vector3) {
    const points = [];
    let positionArray = (mesh.geometry.attributes.position as BufferAttribute).array
    for (var i = 0; i < positionArray.length / 3; i++) {
      points.push(new Vector3(positionArray[i * 3], positionArray[i * 3 + 1], positionArray[i * 3 + 2]))
    }
    const u = new Vector3().copy(points[1]).sub(points[0]).normalize();
    const v = new Vector3().copy(points[0]).sub(points[2]).normalize();
    let finalQuaternion;
    if (Math.abs(u.dot(edge)) < Math.abs(v.dot(edge))) {
      if (u.dot(edge) < 0) {
        finalQuaternion = new Quaternion().setFromUnitVectors(u, edge.multiplyScalar(-1));
        mesh.geometry.applyQuaternion(finalQuaternion);
      } else {
        finalQuaternion = new Quaternion().setFromUnitVectors(u, edge);
        mesh.geometry.applyQuaternion(finalQuaternion);
      }

    } else {
      if (v.dot(edge) < 0) {
        finalQuaternion = new Quaternion().setFromUnitVectors(v, edge.multiplyScalar(-1));
        mesh.geometry.applyQuaternion(finalQuaternion);
      } else {
        finalQuaternion = new Quaternion().setFromUnitVectors(v, edge);
        mesh.geometry.applyQuaternion(finalQuaternion);
      }
    }
    return finalQuaternion;
  }
  static applyRectRotationToGeometry(geometry: BufferGeometry, edge: Vector3) {
    const points = [];
    let positionArray = (geometry.attributes.position as BufferAttribute).array
    for (var i = 0; i < positionArray.length / 3; i++) {
      points.push(new Vector3(positionArray[i * 3], positionArray[i * 3 + 1], positionArray[i * 3 + 2]))
    }
    const u = new Vector3().copy(points[1]).sub(points[0]).normalize();
    const v = new Vector3().copy(points[0]).sub(points[2]).normalize();
    let finalQuaternion;
    if (Math.abs(u.dot(edge)) < Math.abs(v.dot(edge))) {
      if (u.dot(edge) < 0) {
        finalQuaternion = new Quaternion().setFromUnitVectors(u, edge.multiplyScalar(-1));
        geometry.applyQuaternion(finalQuaternion);
      } else {
        finalQuaternion = new Quaternion().setFromUnitVectors(u, edge);
        geometry.applyQuaternion(finalQuaternion);
      }

    } else {
      if (v.dot(edge) < 0) {
        finalQuaternion = new Quaternion().setFromUnitVectors(v, edge.multiplyScalar(-1));
        geometry.applyQuaternion(finalQuaternion);
      } else {
        finalQuaternion = new Quaternion().setFromUnitVectors(v, edge);
        geometry.applyQuaternion(finalQuaternion);
      }
    }
    return finalQuaternion;
  }


  static getGutterLeftRightPts(gutterStart: Vector3, gutterEnd: Vector3, center: Vector3) {
    // refer ENV-5390 jira ticket for the graphical representation of below logic
    // reference: https://enactsystems.atlassian.net/browse/ENV-5390

    let gutterLeftPt = gutterStart.clone();
    let gutterRightPt = gutterEnd.clone();

    let gutterStartRel = gutterStart.clone().sub(center);
    let gutterEndRel = gutterEnd.clone().sub(center);

    let cross = new Vector3().crossVectors(gutterStartRel, gutterEndRel);
    if(cross.z <0){
      gutterLeftPt = gutterEnd.clone();
      gutterRightPt = gutterStart.clone();
    }

    return { gutterLeftPt, gutterRightPt }
  }

  static calculateRoofPlaneGlobalToLocalMatrix(gutterStartPoint: Vector3, gutterVector: Vector3, roofNormal: Vector3) {
    let localOrigin = gutterStartPoint.clone();
    let xAxis = gutterVector.normalize().clone();
    let zAxis = roofNormal.normalize().clone();
    // var arrowLength = 100;
    // var arrowColor = 0x00ff00;
    // let arrowHelper = new ArrowHelper( zAxis,localOrigin, arrowLength, arrowColor );
    // roof.add(arrowHelper)

    // var arrowLength1 = 100;
    // var arrowColor1 = 0xff0000;
    // let arrowHelper1 = new ArrowHelper( xAxis,localOrigin, arrowLength1, arrowColor1 );
    // roof.add(arrowHelper1)
    return this.calculateGlobalToLocalMatrix(localOrigin, xAxis, zAxis);
  }

  private static calculateGlobalToLocalMatrix(localOrigin: Vector3, xAxis: Vector3, zAxis: Vector3) {
    let yAxis = new Vector3();
    yAxis.crossVectors(zAxis, xAxis);
    yAxis.normalize();

    // if (xAxis.x < 0) xAxis.multiplyScalar(-1);
    // if (yAxis.y < 0) yAxis.multiplyScalar(-1);

    // var arrowLength1 = 100;
    // var arrowColor1 = 0x0000ff;
    // let arrowHelper1 = new ArrowHelper( yAxis,localOrigin, arrowLength1, arrowColor1 );
    // roof.add(arrowHelper1)

    // const geometry = new BoxGeometry(10, 10, 10);
    // const material = new MeshBasicMaterial({ color: 0x00ff00 });
    // const cube = new Mesh(geometry, material);
    // cube.position.x = localOrigin.x
    // cube.position.y = localOrigin.y
    // cube.position.z = localOrigin.z
    // cube.matrixAutoUpdate = true
    // scene.add( cube );


    //let translation = new Matrix4().makeTranslation(-localOrigin.x, -localOrigin.y, -localOrigin.z);
    let translation = new Matrix4().makeTranslation(localOrigin.x, localOrigin.y, localOrigin.z);
    let rotation = new Matrix4();
    rotation.makeBasis(xAxis, yAxis, zAxis)

    const quaternion = new Quaternion();
    quaternion.setFromRotationMatrix(rotation)
    // cube.applyQuaternion(quaternion)
    /*
    rotation.set(xAxis.x, yAxis.x, zAxis.x, 0,
        xAxis.y, yAxis.y, zAxis.y, 0,
        xAxis.z, yAxis.z, zAxis.z, 0,
        0, 0, 0, 1);
        */
    let localToGlobal = new Matrix4();
    localToGlobal.multiplyMatrices(translation, rotation);
    //globalToLocal = cube.matrix.clone()
    // const localToGlobalMatrix = globalToLocal.clone();
    //cube.matrix = localToGlobalMatrix.clone()
    //cube.matrixWorld = localToGlobalMatrix.clone()
    //cube.matrixWorldNeedsUpdate = false
    //cube.updateMatrix()
    let globalToLocal = localToGlobal.clone().invert();
    return globalToLocal;
  }

  static getWorldPosition(mesh: Mesh): Vector3 {
    const boundingBox = new Box3().setFromObject(mesh, true);
    const center = new Vector3();

    boundingBox.getCenter(center);

    // Convert the center from local to world coordinates
    mesh.localToWorld(center);

    return center;
  }

  static scaleDownBufferGeo(mesh: Mesh, scaleFactor: number){
    let centerForRotationLocal = new Vector3();
    let points = Util.getPointsFromGeometery(mesh.geometry);
    const bbox = new Box3().setFromPoints(points);
    const meshNormal = Util.getMeshNormal(mesh);
    const quater = new Quaternion().setFromUnitVectors(meshNormal, Util.DEFAULTUP);
    bbox.getCenter(centerForRotationLocal);
    mesh.geometry.translate(-centerForRotationLocal.x, -centerForRotationLocal.y, -centerForRotationLocal.z);
    mesh.geometry.applyQuaternion(quater);
    mesh.geometry.scale(scaleFactor, scaleFactor, 1)
    mesh.geometry.applyQuaternion(quater.invert());

    mesh.geometry.translate(centerForRotationLocal.x, centerForRotationLocal.y, centerForRotationLocal.z);
    mesh.updateMatrix();
  }

  static getRelativeConditionalPosition(referenceObject: Mesh, worldPosition: Vector3,  scene: Scene, plane: 'xy' | 'xyz' = 'xy') {
    let originPosVert = Util.getMeshVertices(referenceObject);
    // Initialize a THREE.Vector3 to store the sum of all vertices
    let sum = new Vector3(0, 0, 0);
    // Add each vertex to the sum
    for (let i = 0; i < originPosVert.length; i++) {
        sum.add(originPosVert[i]);
    }
    // Divide by the number of vertices to get the average
    let referencePosition = sum.divideScalar(originPosVert.length);
    // Keep the original z-coordinate
    referencePosition.z = referenceObject.position.z;


    //const referencePosition = this.getWorldPosition(referenceObject);
    if (plane === 'xy') {
      referencePosition.setZ(0);
    }
    const distance = referencePosition.distanceTo(worldPosition);
    const direction = new Vector3().subVectors(worldPosition, referencePosition).normalize();
    if (plane === 'xy') {
      direction.setZ(0);
    }
    const relativePosition = referenceObject.position.clone().add(direction.clone().multiplyScalar(distance));

    return relativePosition;
  }

  static getRelativePosition(referenceObject: Mesh, worldPosition: Vector3, plane: 'xy' | 'xyz' = 'xy') {
    referenceObject.geometry.computeBoundingBox();
    const bbox = new Box3().copy( referenceObject.geometry.boundingBox! );

    const center = bbox.getCenter(new Vector3());
    const referencePosition = center.clone() //this.getCenter(referenceObject);
    // if (plane === 'xy') {
    //   referencePosition.setZ(0);
    // }
    // const distance = referencePosition.distanceTo(worldPosition);
    // const direction = new Vector3().subVectors(worldPosition, referencePosition).normalize();
    // if (plane === 'xy') {
    //   direction.setZ(0);
    // }
    // const relativePosition = referenceObject.position.clone().add(direction.clone().multiplyScalar(distance));
    // return relativePosition;

    return worldPosition.clone().sub(referencePosition);
  }

  static convertPolygonPointsTo2D(polygonPoints3D: Vector3[], globalToLocal: Matrix4) {
    let polygonPoints2D = [];
    for (let i = 0; i < polygonPoints3D.length; i++) {
      let point3D = polygonPoints3D[i].clone();
      let point2D = point3D.clone().applyMatrix4(globalToLocal);
      //point2D.set(point3D.x, point3D.y,0);
      // point2D.set(point3D.x, point3D.y, point3D.z);
      polygonPoints2D.push(point2D);
    }
    return polygonPoints2D;
  }

  static setbackRoofPts(roofVertices: Mesh[], storeEdges: Edge[], roofPoints2D:Vector3[], outside: boolean = false) {
    const vertexArr = roofVertices.map(v => v.position)
    const vertIds: { [key: string]: { setback: number, position: Vector3 } } = {};
    storeEdges.forEach(e => {
      const vertPos = roofVertices.find(v => v.name == e.startVertexId);
      if (vertPos) {
        const position = vertPos.position.clone();
        // sometimes the setback is being stored as object by default, so checking `typeof e.setback == "number"`
        // using roofStoresetback as a backup, but in an ideal state that scenario will never be met - since changing roof setback from RHS should over-write all edges setback

        // use a minimum of 0.001 setback - if not setback geometry will not be accurate with 0 edge setback
        const setback = ((typeof e.setback == "number") && e.setback > 0) ? Util.getMeasurementFeetToPixel(e.setback) : 0.001; // roofStoreSetback;
        vertIds[e.startVertexId] = { setback, position };
      }
    })


    const listOfpolygonPoints2D = [];
    const offsetDis = [];
    for (let i = 0; i < roofPoints2D.length; i++) {
      let vector3D = vertexArr[i];
      let vertSetback: number = 0;

      for (const [vertId, vertData] of Object.entries(vertIds)) {
        const dist = vector3D.distanceTo(vertData.position);

        if (dist <= Number.EPSILON) {
          vertSetback = vertData.setback;
        }
      }

      let vector2D = new Vector2(roofPoints2D[i].x, roofPoints2D[i].y);
      listOfpolygonPoints2D.push(vector2D);
      offsetDis.push(vertSetback);
    }
    let po = new PolygonOffsetter();
    let offsetedPoly = po.offset(listOfpolygonPoints2D, offsetDis, roofPoints2D, outside);

    let newPolygon3D = offsetedPoly; // offsetedPoly.map((pt, i) => new Vector3(pt.x, pt.y, roofPoints2D[i].z));

    return newPolygon3D;
  }

  static getSetbackfromVertices(vertexArr: Vector3[], setbacks: number[], roofPoints2D:Vector3[], outside: boolean = false): Vector3[] {
      const vertIds: { [key: string]: { setback: number, position: Vector3 } } = {};
      for(let i = 0; i < vertexArr.length; i++){
        const position = vertexArr[i].clone();
        const setback = ((typeof setbacks[i] == "number") && setbacks[i] > 0) ? Util.getMeasurementFeetToPixel(setbacks[i]) : 0.001; // roofStoreSetback;
        vertIds[i] = { setback, position };
      }

      const listOfpolygonPoints2D = [];
      const offsetDis = [];
      for (let i = 0; i < roofPoints2D.length; i++) {
        let vector3D = vertexArr[i];
        let vertSetback: number = 0;

        for (const [vertId, vertData] of Object.entries(vertIds)) {
          const dist = vector3D.distanceTo(vertData.position);

          if (dist <= Number.EPSILON) {
            vertSetback = vertData.setback;
          }
        }

        let vector2D = new Vector2(roofPoints2D[i].x, roofPoints2D[i].y);
        listOfpolygonPoints2D.push(vector2D);
        offsetDis.push(vertSetback);
      }
      let po = new PolygonOffsetter();
    let offsetedPoly = po.offset(listOfpolygonPoints2D, offsetDis, roofPoints2D, outside);

    let newPolygon3D = offsetedPoly;  // offsetedPoly.map((pt, i) => new Vector3(pt.x, pt.y, roofPoints2D[i].z));

      return newPolygon3D;
    }

  static calculateBoundingBox(polygon2DPoints: Vector3[]) {

    const geoPoints = new BufferGeometry();
    const pts = polygon2DPoints.flatMap(pt => [pt.x, pt.y, pt.z]);
    const ptsBuffer = new BufferAttribute(new Float32Array(pts), 3);
    geoPoints.setAttribute("position", ptsBuffer)

    const bbox2 = new Box3().setFromBufferAttribute(ptsBuffer);
    const bbox = new Box3().setFromPoints(polygon2DPoints);

    // let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;
    // for (let i = 0; i < polygon2DPoints.length; i++) {
    //     let point = polygon2DPoints[i];
    //     if (point.x < minX) {
    //         minX = point.x;
    //     }
    //     if (point.x > maxX) {
    //         maxX = point.x;
    //     }
    //     if (point.y < minY) {
    //         minY = point.y;
    //     }
    //     if (point.y > maxY) {
    //         maxY = point.y;
    //     }
    // }
    // bbox.min.x -= 2
    // bbox.min.y -= 2
    // bbox.max.x += 2
    // bbox.max.x += 2
    return bbox;
  }
  static hexToRgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  static DeletePanelArrayfromStore(store: Store<DesignState>, storePanelArray: PanelArray) {

    store.dispatch(fromPanelActions.DeleteMany({ panelIds: storePanelArray.panelIds }));
    store.dispatch(fromFrameActions.DeleteMany({ frameIds: storePanelArray.frameIds }));

    store.dispatch(fromPanelArrayActions.PanelArrayDelete({ panelArray: storePanelArray }));
  }

  static snapToNearestAngle(snappingAngles: number[], threshold: number, point1: Vector3, point2: Vector3, point3: Vector3, alongLine: Vector3 | undefined = undefined) {
    point1 = point1.clone().setZ(0);
    point2 = point2.clone().setZ(0);
    point3 = point3.clone().setZ(0);

    // Calculate the vectors representing the two lines
    const line1 = new Vector3().copy(point1).sub(point2);
    const line2 = new Vector3().copy(point3).sub(point2);

    // Calculate the angle in radians
    const angleInRadians = line1.angleTo(line2);

    // Convert the angle to degrees
    const initialAngle = MathUtils.radToDeg(angleInRadians);

    // Calculate the distance between point2 and point3
    const initialDistance = point2.distanceTo(point3);

    // Find the nearest snapping angle to the initial angle
    let nearestSnapAngle = null;
    let minAngleDiff = Infinity;

    // If points are too close
    if (initialDistance<=constants.VERTEXDIA/2) {
      return {
        snappingAngle: nearestSnapAngle,
        snappingPosition: point3,
        line1,
        line2,
      }
    }

    for (const snapAngle of snappingAngles) {
      const angleDiff = Math.abs(initialAngle - snapAngle);
      if (angleDiff < minAngleDiff && angleDiff <= threshold) {
        minAngleDiff = angleDiff;
        nearestSnapAngle = snapAngle;
      }
    }

    if (nearestSnapAngle !== null) {
      const positiveDirection = line1.clone().normalize();
      const negativeDirection = line1.clone().normalize();
      const snappingAngleInRadians = MathUtils.degToRad(nearestSnapAngle);

      positiveDirection.applyAxisAngle(new Vector3(0, 0, 1), snappingAngleInRadians);
      negativeDirection.applyAxisAngle(new Vector3(0, 0, 1), -snappingAngleInRadians);

      let endPoint = point2.clone().add(positiveDirection.clone().setLength(initialDistance));
      let complement = point2.clone().add(negativeDirection.clone().setLength(initialDistance));
      if (alongLine) {
        // point2.x+positiveDirection.x*a = point3.x+alongLine.x*b;
        // point2.y+positiveDirection.y*a = point3.y+alongLine.y*b;
        // if(alongLine.clone().cross(line2).z > 0.1){
          if(Math.abs(positiveDirection.x*alongLine.y-positiveDirection.y*alongLine.x) >0.01 && Math.abs(negativeDirection.x*alongLine.y-negativeDirection.y*alongLine.x) >0.01){
          let a1 = (point3.x*alongLine.y - point3.y*alongLine.x - (alongLine.y*point2.x-alongLine.x*point2.y))/(positiveDirection.x*alongLine.y-positiveDirection.y*alongLine.x);
          let a2 = (point3.x*alongLine.y - point3.y*alongLine.x - (alongLine.y*point2.x-alongLine.x*point2.y))/(negativeDirection.x*alongLine.y-negativeDirection.y*alongLine.x);
          endPoint = point2.clone().addScaledVector(positiveDirection.clone(), Math.abs(a1))
          complement = point2.clone().addScaledVector(negativeDirection.clone(), Math.abs(a2))
        }
      }
      const endPointDistance = endPoint.distanceTo(point3);
      const complementDistance = complement.distanceTo(point3);
      if (endPointDistance < complementDistance) {
        return {
          snappingAngle: nearestSnapAngle,
          snappingPosition: endPoint,
          line1,
          line2: new Vector3().copy(endPoint).sub(point2),
        };
      }

      return {
        snappingAngle: -nearestSnapAngle,
        snappingPosition: complement,
        line1,
        line2: new Vector3().copy(complement).sub(point2),
      };
    }

    return {
      snappingAngle: nearestSnapAngle,
      snappingPosition: point3,
      line1,
      line2,
    }
  }

  static createAngleSnappingIndicator(line1: Vector3, line2: Vector3, origin: Vector3, angle: number, size: number, name: string , color = threeColors.guideline){
    // 1. Find pointA on line 1
    const pointA = new Vector3();
    pointA.copy(line1).normalize().multiplyScalar(size).add(origin);

    // 2. Find pointB on line 2
    const pointB = new Vector3();
    pointB.copy(line2).normalize().multiplyScalar(size).add(origin);

    let lineObject;

    switch (Math.abs(angle)) {
      case 90:
        // 3. Find a vector parallel to line 2 and intersecting pointA
        const line3 = new Vector3();
        line3.copy(line2).normalize();

        // 4. Find pointC on line 3
        const pointC = new Vector3();
        pointC.copy(line3).multiplyScalar(size).add(pointA);

        // 5. Create a line object with the three given points and the user-input color
        // const geometry = new BufferGeometry().setFromPoints();
        // Due to limitations of the OpenGL Core Profile with the WebGL renderer on most platforms linewidth will always be 1 regardless of the set value.
        // const material = new LineBasicMaterial({ color, linewidth: 3 });

        lineObject =this.createZoomFreeLine([pointA, pointC, pointB],color)
        break;
      default:
        // Calculate the angles between the start and end points and the origin
        const startAngle = Math.atan2(pointA.y - origin.y, pointA.x - origin.x);
        const endAngle = Math.atan2(pointB.y - origin.y, pointB.x - origin.x);

        const segments = 32;

        const vertices: Vector3[] = [];

        // Calculate the angle step for each segment
        let diffAngle = endAngle - startAngle;
        if (Math.abs(diffAngle) > Math.PI) {
          const factor = diffAngle < 0 ? -1 : 1;
          diffAngle = -factor * (2 * Math.PI - factor * diffAngle);
        }

        const angleStep = diffAngle / segments;

        // Create the vertices for the arc
        for (let i = 0; i <= segments; i++) {
          const angle = startAngle + i * angleStep;
          const x = origin.x + size * Math.cos(angle);
          const y = origin.y + size * Math.sin(angle);
          vertices.push(new Vector3(x, y, origin.z));
        }

        // Create an empty geometry to store the arc
        lineObject =this.createZoomFreeLine(vertices,color)

    }
    lineObject.position.z = 150;
    lineObject.name = name;

    return lineObject;
  }

  static createGuideline(line: Vector3, origin: Vector3, name: string, color = threeColors.guideline) {
    const geometry = new BufferGeometry().setFromPoints([origin.clone().addScaledVector(line.clone().normalize(), -2000), origin.clone().addScaledVector(line.clone().normalize(), 2000)]);
    const lineG = new MeshLine();
    lineG.setGeometry(geometry);
    const material = new MeshLineMaterial({
      color: color,
      lineWidth: constants.DefaultRoofDrawingEdgeThickness,
      resolution: new Vector2(window.innerWidth, window.innerHeight),
      depthWrite: false,
      depthTest: false,
      transparent: true,
      sizeAttenuation: false,
      dashArray: 0.002,
      dashRatio: 0.33,
    });
    material.vertexShader = fixedVertexShader;
    material.fragmentShader = fixedFragmentShader
    var mesh = new Mesh(lineG, material);
    mesh.position.z = 150;
    mesh.name = name;
    return mesh;
  }

  /**
   *
   * @param points
   * @param color
   * @returns return a line Mesh which zoom indpendent
   */
  static createZoomFreeLine(points: Vector3[], color: Color) {
    const geometry = new BufferGeometry().setFromPoints(points);
    const lineG = new MeshLine();
    lineG.setGeometry(geometry);
    const material = new MeshLineMaterial({
      color: new Color(0x4B0082),
      lineWidth: constants.DefaultRoofDrawingEdgeThickness,
      resolution: new Vector2(window.innerWidth, window.innerHeight),
      depthWrite: false,
      depthTest: false,
      transparent: true,
      sizeAttenuation: false,

    });

    material.vertexShader = fixedVertexShader;
    material.fragmentShader = fixedFragmentShader
    var mesh = new Mesh(lineG, material);

    return mesh;
  }

  /**
   *
   * @param pts - points array to check for min and second min point
   * @param axis - Axis to check.
   * @returns bottom most and second bottom most points in provided axis
   */
  static getBottom2Points(pts: Vector3[], axis: "z" | "y" = "z") {
    // get the lowest and 2nd lowest point in z and use them to create vector axis
    let min = Infinity, secondMin = Infinity;
    let minPt, minPt2;
    for (var i = 0; i < pts.length; i++) {
      // ref: find 1st and second smallest numbers - https://stackoverflow.com/a/47318105/6908282
      if (pts[i][axis] < min) {
        secondMin = min;
        minPt2 = minPt?.clone();

        min = pts[i][axis];
        minPt = pts[i].clone();
      } else if (pts[i][axis] <= secondMin) {
        secondMin = pts[i][axis];
        minPt2 = pts[i].clone();
      }
    }

    return { minPt, minPt2 }
  }

  static calcPolygonArea(vertices: Vector2[]) {
    var total = 0;

    for (var i = 0, l = vertices.length; i < l; i++) {
      var addX = vertices[i].x;
      var addY = vertices[i == vertices.length - 1 ? 0 : i + 1].y;
      var subX = vertices[i == vertices.length - 1 ? 0 : i + 1].x;
      var subY = vertices[i].y;

      total += (addX * addY * 0.5);
      total -= (subX * subY * 0.5);
    }

    return Math.abs(total);
  }
  static calcScaleAcctoZoom(orbitControls: OrbitControls, finalScale: number =7) {
    let max = orbitControls.maxZoom;
    let min = orbitControls.minZoom;
    let a = (finalScale - 1) * max * min / (max - min);
    let b = (max - finalScale * min) / (max - min);
    return a / orbitControls.object.zoom + b;
  }

  static getPointsAroundMedian(points: Vector3[], percentage: number = 0.8) {

    // Step 1: Calculate the median Z value
    const zValues = points.map(point => point.z);
    const sortedZValues = zValues.slice().sort((a, b) => a - b);
    const medianZ = sortedZValues[Math.floor(sortedZValues.length / 2)];

    // Step 2: Sort the points based on their distance to the median Z value
    const sortedPoints = points.slice().sort((a, b) => Math.abs(a.z - medianZ) - Math.abs(b.z - medianZ));

    // Step 3: Select the top 80% of points
    const numPointsToSelect = Math.ceil(points.length * percentage);
    const selectedPoints = sortedPoints.slice(0, numPointsToSelect);

    // Now 'selectedPoints' contains the top 80% of points closest to the median Z value
    // console.log(selectedPoints);

    const allZValues = points.map(v => v.z);
    const selectedZValues = selectedPoints.map(p => p.z);
    const selectedMaxZ = parseInt(Math.max(...selectedZValues).toFixed());
    const selectedMinZ = parseInt(Math.min(...selectedZValues).toFixed());
    const pointsAroundMedian: SamplePtsStats = {
      allMaxValue: Math.max(...allZValues),
      allMinValue: Math.min(...allZValues),
      allMeanAvg: stats.mean(allZValues),
      allMedianValue: stats.median(allZValues),
      allModeValue: stats.mode(allZValues),
      groupedPoints: {
        "medianPoints": {
          points: selectedPoints,
          count: selectedPoints.length,
          range: [selectedMinZ, selectedMaxZ],
          maxValue: selectedMaxZ,
          minValue: selectedMinZ,
          meanAvgValue: stats.mean(zValues),
          medianValue: stats.median(zValues),
          modeValue: stats.mode(zValues)
        }
      }
    };

    return pointsAroundMedian

  }

  static groupPointsintoRange(arr: Vector3[], range: number) {
    // ref: https://stackoverflow.com/a/28431102/6908282
    const r: { [key: number]: Vector3[] } = {};
    arr.forEach(function (x) {
      var y = Math.floor(x.z / range);
      r[y] = (r[y] || []).concat(x);
    });
    const allZValues = arr.map(v => v.z)
    const ptsGrp: SamplePtsStats = {
      allMaxValue: Math.max(...allZValues),
      allMinValue: Math.min(...allZValues),
      allMeanAvg: stats.mean(allZValues),
      allMedianValue: stats.median(allZValues),
      allModeValue: stats.mode(allZValues),
      groupedPoints: {}
    };
    Object.keys(r).map(function (y) {
      const key = parseInt(y);
      const range = key * 100 + "-" + (key + 1) * 100;
      const zValues: number[] = r[y].map((v: Vector3) => v.z);
      if (ptsGrp.groupedPoints) {
        ptsGrp.groupedPoints[range] = {
          points: r[y],
          count: r[y].length,
          range: [key * 100, (key + 1) * 100],
          maxValue: Math.max(...zValues),
          minValue: Math.min(...zValues),
          meanAvgValue: stats.mean(zValues),
          medianValue: stats.median(zValues),
          modeValue: stats.mode(zValues)
        }
      }
      return r[y];
    });

    return ptsGrp;
  }

  static errorDialog(err: string, dialog: MatDialog, errorMSg: string = '') {
    const data = {
      showYesBtn: false,
      showNoBtn: false,
      title: errorMSg,
      message: err,
    };
    openDialog(dialog, data);
  }
  // Can be removed along with the functions above, used for memory calculation
  // Function to calculate memory used by geometry
  static getGeometryMemory(geometry: BufferGeometry): number {
    let memoryUsage = 0;

    // For each attribute (position, normal, uv, etc.), calculate memory usage
    geometry.attributes.position && (memoryUsage += geometry.attributes.position.count * 3 * 4); // 3 floats per vertex for position
    geometry.attributes.normal && (memoryUsage += geometry.attributes.normal.count * 3 * 4); // 3 floats per vertex for normals
    geometry.attributes.uv && (memoryUsage += geometry.attributes.uv.count * 2 * 4); // 2 floats per vertex for UVs

    // If the geometry has indices, we need to account for them too
    if (geometry.index) {
      // Assuming 16-bit indices for smaller meshes
      memoryUsage += geometry.index.count * 2; // 2 bytes per index (16-bit)
    }

    return memoryUsage; // in bytes
  }

  // Function to calculate memory used by texture
  static getTextureMemory(texture: Texture): number {
    const width = texture.image.width;
    const height = texture.image.height;
    const channels = texture instanceof CompressedTexture ? 1 : 4; // Assuming RGBA (4 channels) unless it's a compressed texture
    const bytesPerChannel = 1; // 1 byte per channel (8-bit)

    return width * height * channels * bytesPerChannel; // in bytes
  }

  // Function to calculate total memory used by geometries and textures in a Three.js scene
  static calculateSceneMemory(scene: Scene): { geometriesMemory: number, texturesMemory: number } {
    let totalGeometryMemory = 0;
    let totalTextureMemory = 0;
    const textureSet = new Set<Texture>();

    scene.traverse((object: Object3D) => {
      // Calculate memory for geometries (if present)
      if (object instanceof Mesh) {
        const geometryMemory = this.getGeometryMemory(object.geometry);
        totalGeometryMemory += geometryMemory;

        // Calculate memory for textures (if present)
        if (object.material && object.material.map) {
          const texture = object.material.map;
          if (!textureSet.has(texture)) {
            const textureMemory = this.getTextureMemory(texture);
            totalTextureMemory += textureMemory;
            textureSet.add(texture);
          }
        }
      }
    });

    return {
      geometriesMemory: totalGeometryMemory,
      texturesMemory: totalTextureMemory
    };
  }
}

export type SamplePtsStats = {
  allMaxValue: number,
  allMinValue: number,
  allMeanAvg: number,
  allMedianValue: number,
  allModeValue: number,
  groupedPoints: { [key: string]: samplePts }
}

export type samplePts = {
  points: Vector3[],
  count: number,
  range: number[],
  maxValue: number,
  minValue: number,
  meanAvgValue: number,
  medianValue: number,
  modeValue: number,
}

export const standardRoofPitches = [0, 4.5, 9.5, 14, 18.5, 22.5, 26.5, 30.5, 33.75, 37, 40, 42.5, 45, 47.5, 49.5, 51.5, 56, 60, 63]; // ref: https://inspectapedia.com/roof/Roof_Slope_Table.php

export function generateTextId(id: string){
  return id + 'Text';
}

export const enum vertexType {
  temp,
  spherical,
}

export const enum storeIdPrefix {
  panelArray = "PanelArr",
  singlePanel = "panel",
  vertex = "vertex",
  edge = "edge",
  roof = "roof",
  obstacle = "Obstacle",
  frame = "frame",
  invertor = 'Inverter',
  tree = 'Tree'
}

export class OrbitControlsData {
  /**
   *
   */
  constructor(public position: Vector3, public target: Vector3, public zoom: number) {

  }
}

export const enum meshTypeEnum {
  orthographicCamera = "OrthographicCamera",
  perspectiveCamera = "PerspectiveCamera",
  edges2d = "edges2d",
  groundPlane = "Ground_Plane",
  default = "default",
  fittedVertex = "fittedVertex",
  fittedEdge = "fittedEdge",
  edge = 'Edge',
  fittedRoof = "fittedRoof",
  roofSetbackMesh = "roofSetbackMesh",
  roofPlane = "roofPlane",
  roofShape = "RoofShape",
  tree = "tree",
  treeTop = "treeTop",
  treeTrunk = "treeTop",
  treeSquare = "treeSquare",
  obstMeshFlushedOnRoof = "obstMeshFlushedOnRoof",
  rectangleObstacle = "rectangleObstacle",
  walkwaysObstacle = "walkwaysObstacle",
  circleObstacle = "circleObstacle",
  freeFormObstacle = 'free-formObstacle',
  freeformObstShape = "freeformObstShape",
  panelGroup = "panelGroup",
  panelMesh = "panelMesh",
  panel = "panel",
  instancedMesh = "instancedMesh",
  temporaryPanelMesh = "temporaryPanelMesh",
  drawPanelShape = "DrawPanelShape",
  panelArray = "panelArray",
  pillerArray = "pillerArray",
  ObstacleExtrudeCap = "ObstacleExtrudeCap",
  obstacleVertexGroup = "ObstVertexMeshes",
  obstacleSetbackMesh = "ObstacleSetbackMesh",
  MapPlane = "MapPlane",
  wall = "wallMesh",
  line = "lineMesh",
  lineGroup = "lineGroup",
  tempLineGroup = "tempLineGroup",
  edgeGroup = "edgeGroup",
  vertGroup = "vertGroup",
  wallGroup = "WallGroup",
  panelDrawVertex = "panelDrawVertex",
  obstacleRootGroup = "obstacleRootGroup",
  obstacleExtrudeWalls = "ObstacleExtrudeWalls",
  tempTextAnnotation = 'tempText',
  css3DLabelRendererDiv = "css3DLabelRendererDiv",
  hideAllDimensions = "hideAllDimensions",
  tempTextAnnotationFree = 'tempTextFree',
  textAnnotationRoof = 'roofAnnotationText',
  textAnnotationObst = 'textAnnotationObst',
  textAnnotationTree = 'textAnnotationTree',
  tempTextAnnotationRect = 'tempTextAnnotationRect',
  textAnnotationFreeForm = 'textAnnotationFreeForm',
  tempTextAnnotationCircle = 'tempTextAnnotationCircle',
  tempTextAnnotationWalkway = 'tempTextAnnotationWalkway',
  textAnnotationGroup = 'textAnnotationGroup',
  textAnnotationRoofTemp = 'roofAnnotation',
  tempEndLineVertex = 'tempEndLineVertex',
  previewEdge = 'previewEdge',
  previewEdgeGroup = 'previewEdgeGroup',
  guidesGroup = 'guidesGroup',
  guideLine1 = 'snapping-angle-guideline',
  guideLine2 = 'final-line-guideline',
  snapAngleIndicator = 'snapping-angle-indicator',
  assumeAngleIndicator = 'assume-angle-indicator'
}

export const enum renderOrders {
  obstacles = 10,
  panelArray = 99,
  pillarArray = 99,
  vertex = 10,
  preview = 1000,
  sprite = 1

}

export const enum textTypeId{
  width = "width",
  length = "length",
}

export class MeshUserData {
  public is2DScene: boolean = false;
  public meshtype: string = meshTypeEnum.default;
  public enactStoreId: string | undefined;
  public addedToStore: boolean = false;
  public roofNormal: Vector3 | undefined;
  public roofsetbackPts?: Vector3[]
  public roofConfirmedPlane: Plane | undefined;
  public obstRotation: number | undefined;
  public obstDia: number | undefined;
  public obstHeight: number | undefined;
  public obstLength: number | undefined;
  public obstWidth: number | undefined;
  public panelArrayOrientation: PanelOrientation | undefined;
  public panelTilt: number | undefined
  public bufferGeoJSON: any;
  public defaultHeight: number | undefined;
  public frameId: string | undefined;
  public azimuth: number | undefined;
  public instanceId: number | undefined;
  public matrix: Matrix4 | undefined;
  public visible: boolean | undefined;
  public orientation: PanelOrientation | undefined;
  public maxInstanceCount: number | undefined;

  /**
 * Custom user data for final fitted meshes - vertices, edges & roof.
 * @constructor call this class once the enact ID of the mesh is generated in StoreUtil module.
 * @param {string} storeId - this is the enact id generated using generateStoreId.
 * @param {string} meshType - this is an Enum to identify if its a fittedVertex, fittedEdge or fittedRoof.
 */
  constructor(storeId: string, meshType: string) {
    this.enactStoreId = storeId;
    this.meshtype = meshType;
  }
}

export const enum nonDsm {
  minZ = 5,
  maxZ = 1000,
}
export const enum raycastEnum {
  default = "all",
  panelFillDraw = "panelFill/Draw",
  treeDraw = "treeDraw/Select"
}
const colorNames = {
  white: new Color('white'), //0xffffff,
  black: new Color('black'), //0xffffff,
  yellow: new Color('yellow'), //0xffff00,
  skyblue: new Color(0x4AE5FF),
  blue: new Color('blue'), //0x0000ff
  navyBlue: new Color("#08369F"),
  cyan: new Color('#00C0DE'),
  brightGreen: new Color(0x33ff33),
  lightBlue: new Color('#00CFE8'),
  firstVertexGreen: new Color(0x3AF336),
  wallEdgeGray: new Color(0x7F878B),
  wallFillColor: new Color(0xC9CDCF),
  walkwaysOrange: new Color('#FF9F43'),
  darkGray: new Color('rgb(15, 13, 15)'),
  orange: new Color(0xEF8202),
  gray: new Color(0x808080),
  purple: new Color(0x584dbe),
  panelColor: new Color("#08369F"),
brown: new Color('rgba(80, 55, 37, 1)'),
  green: new Color(0x00b200),
  darkgreen: new Color('rgba(61, 121, 88, 0.7)'),
  lightgreen: new Color ('rgba(2, 166, 76, 1)'),
  greenblue: new Color('rgba(60, 239, 140, 1)'),
  selectedEdge: new Color('#FFFFFF'),
  mustard: new Color('#ccc429')
}

export const threeColors = {
  black: colorNames.black,
  tempVertex: colorNames.white,
  firstVertex: colorNames.firstVertexGreen,
  finalVertex: colorNames.white,
  wallEdgeColor: colorNames.wallEdgeGray,
  groundColor: colorNames.darkGray,
  wallColor: colorNames.wallFillColor,
  pillarColor: colorNames.gray,
  roofColor: new Color(PRESET_COLORS_DEFAULT[0].value), // colorNames.purple
  obstacleColor: colorNames.yellow,
  obstacleEdge: colorNames.lightBlue ,
  drawShapeColor: colorNames.black,
  edgeColor: colorNames.white,
  walkwaysEdge: colorNames.walkwaysOrange,
  panelColor: colorNames.navyBlue,
  treeLeavesColor: colorNames.green,
  treeStemColor: colorNames.brown,
  tree_colour_new: colorNames.lightgreen,
  stem_colour: colorNames.brown,
  tree_outline_new: colorNames.white,
  tree_outline_normal: colorNames.greenblue,
  hoverOutlineColor: colorNames.white,
  hoverOutlineHiddenColor: colorNames.brown,
  selectOutlineColor: colorNames.blue,
  selectOutlineHiddenColor: colorNames.purple,
  selectedEdgeColor: colorNames.selectedEdge,
  setback: colorNames.mustard,
  guideline: colorNames.cyan
}
export const enum TreeObjects {
  TREELINE = 'TreeLine',
  TEMPTREE = 'TempTree',
  TEMPTBASE = 'TempTBase',
  TREECENTER = 'treeCenter',
  TREE = 'Tree',
  TREEBASE = 'TreeBase',
  TREETYPE = 'TreeType',
  WHOLETREE = 'WholeTree',
  WHITEBORDER = 'WhiteBorder',
  WHITETEMPBORDER = 'WhiteTempBorder',
  TEXT = 'TextAnnotation',
  PLANESQUARE='TreePlaneSquare',
  TREEVERTEX = 'treeVertex'
}

export const MAPPLANE = 'MapPlane'; // TODO: replace this with the one in `constants` variable instead
export const line_material = new LineDashedMaterial({
  color: 0xf7e9e9,
  linewidth: 2,
  scale: 1,
  dashSize: 2,
  gapSize: 1,
});

export const box_geometry = new BoxGeometry(4, 4, 4);

export enum panelDims {
  WIDTH = 3.22, // this is in ft
  LENGTH = 5.31, // this is in ft
  GAP = 0,
}

export interface EdgeInterface {
  edge: Mesh,
  startVertex: Mesh,
  endVertex: Mesh,
  angle: number
}

export type PanelStoreData = {
  storeMetadata: PanelArray,
  roofPitch: number,
  alignment: ArrayAlignment,
}
export type ObstacleData = {
  id: string,
  roofId: string,
  name?: string
  height: number;
  length: number;
  defaultHeight: number;
  width: number;
  rotation: number;
  vertices: Mesh[],
  diameter?: number,
  roofAssociation?:Array<string>;
  setback?: number;
  setbackVisible?: boolean;
} & (circleObstacle | obstacleWithEdge)


type circleObstacle = {
  type: ObstacleType.circle;
  diameter: number
}

type obstacleWithEdge = {
  type: ObstacleType.rectangle | ObstacleType.freeForm | ObstacleType.walkways;
  edges: Mesh[];
}
export function getTreesType(type: string) {
  switch (type) {
    case 'spherical':
      return TreesType.spherical;
    case 'conical':
      return TreesType.conical;
    case 'trunk':
      return TreesType.trunk;
    default:
      return TreesType.spherical;
  }
}

export function resetTempText(textObj: CSS3DObject): void{
  textObj.position.set(0,0,0);
}

export enum constants {
  VERTEXDIA = 6, // 7
  // MAPPLANE = 'MapPlane',
  FLICKEROFFSET = 0.1,
  NonDSMDefaultRoofHeightFt = 10, // 10 ft default non-DSM roof height
  DefaultObstacleHeightFt = 2, // 2 ft default non-DSM obstacle height
  DefaultTreeHeightFt = 25,
  DefaultEdgeThickness = 0.4,
  DefaultTempLineHeight = 1496, //less than the camera height
  DefaultObstacleSetback = 4,
  DefaultRoofDrawingEdgeThickness = 12*DefaultEdgeThickness,
  DefaultPillarSize = 0.3, //feet
  PillarOffset = 0.1,
  DefaultNoOfPanelsInFrame =4
}

export enum fieldNames {
  PANELTILT = "panelTilt",
  ORIENTATION = "orientation",
}
export enum notificationMessages {
  HEIGHTSETTREE = 'height is set automatically',
  CRETETREE = 'has been added successfully',
  COPYTREE = 'Tree has been copied successfully',
  COPYOBSTACLE = 'Obstacle has been copied successfully',
  MOVETREE = 'has been moved successfully',
  MOVEOBSTACLE = 'has been moved successfully',
  DELETETREE = 'has been deleted',
  HEIGHTUPDATED = 'height is updated successfully',
  HEIGHTRESET = 'height is set to default successfully',
  DIAMETERUPDATED = 'diameter is updated successfully',
  NAMEUPDATED = 'name is changed to',
  CREATESUCCESS = ' - has been created successfully',
  UPDATESUCCESS = ' - has been updated successfully',
}

export function getTrunkRadius(sphereRadius: number): number{
  return ((10/100) * sphereRadius);
}

export type RadianceMaps = {yearData: Array<any>, material: MeshBasicMaterial, uv: Array<number> }
export type PanelShadingData = { monthly: any[], yearly: any[] };

export const panelArrayFields = [
  "panelManufacturer",
  "panelTilt",
  "alignment",
  "orientation",
  "azimuth",
  "interrowspacing",
  "rows",
  "frameHeight",
  "columns",
  "peakSpace",
  "frameSpace",
  "clampSpace",
  "arrayType"]

export const defaultPanelConfigObj: PanelArray = {
  id: "",
  roofId: "",
  azimuth: 180,
  setback: 0,
  showEdgeSetback: false,
  // isEdgeSetback: false,
  // edgeSetbackArray: [],
  // moduleId: '1',
  // modelId: '1',
  paneltype: '0',
  degradation: 0.5,
  orientation: PanelOrientation.portrait,
  arrayType: '0',
  isDrawToFill: false,
  arrayTypeName: 'Fixed (Open Rack)',
  panelSize: '',
  panelWidth: panelDims.WIDTH,
  panelLength: panelDims.LENGTH,
  panelTilt: Util.debugThreejs ? 30 : 0, // use 30 degree while debugging
  // tiltType: 1,
  roofTilt: 0,
  shadingValue: 0,
  shadingType: 'annual',
  rackingSystem: '',
  rackingManufacturer: '',
  // watts: 0,

  path: "",
  panelIds: [],
  panelModel: "",
  panelManufacturer: "",
  // showEdgeSetback? : "",
  defaultInterrowspacing: -1,
  interrowspacing: -1,
  totalWatts: 1,
  wattsPerModule: 1,
  // panelIds:Array<Panel['id']>,
  // edges?:Array<Edge['id']>,
  // vertices?:Array<Vertex['id']>,
  name: "",
  alignment: ArrayAlignment.justify,
  numberOfPanels: 1,
  totalAddedPanels: 1,
  unitCost: "1",
  moduleType: "1",
  configurationId: ConfigurationIds.PanelArray,
  noOfRows: 1,
  noOfColumns: 1,
  frameSpace: 0,
  clampSpace: 0,
  peakSpace: 0,
  frameHeight: 0,
  frameHeightTall: 0,
  frameIds: [],
  noOfPanelEast: 1,
  noOfPanelWest: 0

}

export type flattenGutterParams = {
  flattenGutter: false;
  fitplane?: undefined
} | {
  flattenGutter: true,
  fitplane: PlaneFitting
}

export function choseDefaultInverter(data: SelectedInverter[]) {
  // Check if there are any recently used inverters else use the latest one

  const sortedInverters = [...data].sort((a, b) => {
    if (a.recentlyUsed && !b.recentlyUsed) {
      return -1; // a comes before b
    }
    if (!a.recentlyUsed && b.recentlyUsed) {
      return 1; // b comes before a
    }
    return 0; // no change in order
  });


  let defaultInverter = {
    ...sortedInverters[0],
    id: sortedInverters[0].inverterId,
    quantity: 1,
    utilizedCapacity: parseFloat((+sortedInverters[0].powerRating).toFixed(2)) / 1000,
    configurationId: ConfigurationIds.Inverter,
    description: sortedInverters[0].description,
  };
return defaultInverter;
}


