import { Frame } from "./frame.model";
import { PanelArray } from "./panel-array.model";
import { SystemDesignObject } from "./system-design-object.abstract.model";
import { ConfigurationIds } from "./ui-state.model";
import { Vertex } from "./vertex.model";

export enum PanelOrientation{
  landscape="landscape",
  portrait="portrait"
}

interface IPanel {
  id: string;
  instanceId: number;
  visible:boolean;
  orientation:PanelOrientation;
  vertexIds?: Array<Vertex['id']>;
  association: string;
  frameId: Frame['id'];
  azimuth: number;
}

export class Panel extends SystemDesignObject implements IPanel {
  selectConfigId?: boolean | undefined;
  id: string;
  instanceId: number;
  visible: boolean;
  orientation: PanelOrientation = PanelOrientation.portrait;
  configurationId: ConfigurationIds = ConfigurationIds.SinglePanel;
  vertexIds?: Array<Vertex['id']>;
  association: string;
  frameId: Frame['id'];
  azimuth: number;



  constructor(data: Omit<Panel, 'configurationId'>) {
    super();
    this.id = data.id;
    this.instanceId = data.instanceId;
    this.visible = data.visible;
    this.orientation = data.orientation;
    this.vertexIds = data.vertexIds ?? [];
    this.association = data.association ?? '';
    this.frameId = data.frameId;
    this.azimuth = data.azimuth;
  }
}
