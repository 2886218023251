import { ActionReducer, createReducer, on } from '@ngrx/store';
import { fromMetaDataActions } from './meta-data.action';
import { MetaDataState } from './meta-data.state';
import { plainToInstance } from 'class-transformer';
import { Battery } from '@design/models/battery.model';
import { Inverter } from '@design/models/inverter.model';
import { Maps } from '@design/models/ui-state.model';

export const initialMetaDataState: MetaDataState = {
  quote: null,
  design:null,
  hardware:null,
  heightArray: null,
  leadDetails:null,
  selectedInverters:[],
  selectedBatteries:[],
  preferences: null,
  defaultMap:Maps.None,
  quoteSelectedMap:undefined,
  defaultModuleSetup:null,
  recentModules:[],
  otherModules:[],
  arrayTypes:[],
  panelTypes:[],
  availableRacking:[],
  rackingTypes:[],
  prevState:{
    field: '',
    value: null
  },
  s3url:null,
  visibleMap:null,
  autoSave3Dmodel:false,
  weatherStationLatLngJson:null,
  
    
};

export const metaDataReducer: ActionReducer<MetaDataState> = createReducer(
  initialMetaDataState,
  on(fromMetaDataActions.QuoteDetailsFetchSuccess, (state, { quote }) => {
    return{
      ...state,
      quote:quote
    }
  }),
  on(fromMetaDataActions.HeightArrayFetchSuccess, (state, { heightArray }) => {
    return { ...state, 
      heightArray:heightArray };
  }),
  on(fromMetaDataActions.ConsumerDetailsFetchSuccess, (state, { lead }) => {
    return { ...state, 
      leadDetails:lead };
  }),
  on(fromMetaDataActions.UpdateleadDetails, (state, { lead }) => {
    return { ...state, 
      leadDetails:lead };
  }),
  on(fromMetaDataActions.UpdateDefaultMap, (state, { lead }) => {
    return { ...state,
      defaultMap: lead.data.defaultMapOption ? lead.data.defaultMapOption: Maps.None };
  }),
  on(fromMetaDataActions.SelectedInvertersFetchSuccess, (state, { selectedInverters }) => {
    return { ...state,
      selectedInverters: selectedInverters && selectedInverters.map(element => {
        return plainToInstance(Inverter, element);
      })
    }
  }),
  on(fromMetaDataActions.SelectedBatteriesFetchSuccess, (state, { selectedBatteries }) => {
    return { ...state,
      selectedBatteries: selectedBatteries && selectedBatteries.map(element => {
        return plainToInstance(Battery, element);
      })
    }
  }),
  on(fromMetaDataActions.MetaDataPreferencesFetchSuccess, (state, { preferences }) => {
    return { ...state, 
      preferences:preferences };
  }),
  on(fromMetaDataActions.QuoteDesignFetchSuccess,(state,{design})=>{
    return { ...state, 
      design:design };

  }),
  on(fromMetaDataActions.UpdateQuoteMap, (state, { quoteSelectedMap }) => {
    return { ...state,
      quoteSelectedMap : quoteSelectedMap };
  }),
  on(fromMetaDataActions.QuoteHardwareFetchSuccess,(state,{hardware})=>{
    return { ...state, 
      hardware:hardware };

  }),

  on(fromMetaDataActions.SaveInvertersSuccess,(state: any, {inverter} ) => {
    return { 
      ...state, 
      hardware: {
        ...state.hardware,
        inverter: inverter
      }};

  }),

  on(fromMetaDataActions.SaveBatterySuccess,(state: any, {battery} ) => {
    return { 
      ...state, 
      hardware: {
        ...state.hardware,
        battery: battery
      }};

  }),
  on(fromMetaDataActions.SaveSimulationDataSuccess,(state: any, {quote} ) => {
    return{
      ...state,
      quote: quote  
    }

  }),
  on(fromMetaDataActions.SaveObstacleSuccess,(state: any ) => {
    return{
      ...state    
    }

  }),
  on(fromMetaDataActions.StoreDefaultModule,(state,{data})=>{
    return{
      ...state,
      defaultModuleSetup:data
    }
  }),
  on(fromMetaDataActions.StoreAllModules,(state,{modules})=>{
    let recentModules,otherModules = [];
    if(modules.recent && modules.recent.length >0){
      recentModules = modules.recent
    }
    if(modules.data && modules.data.length >0){
      otherModules = modules.data
    }
    return{
      ...state,
      recentModules:recentModules,
      otherModules:otherModules
    }
  }),
  on(fromMetaDataActions.StoreArrayTypes,(state,{arrayTypes})=>{
    return{
      ...state,
      arrayTypes:arrayTypes
    }
  }),
  on(fromMetaDataActions.StorePanelTypes,(state,{panelTypes})=>{
    return{
      ...state,
      panelTypes:panelTypes
    }
  }),
  on(fromMetaDataActions.StoreAllRacking,(state,{rackingList})=>{
    return{
      ...state,
      availableRacking:rackingList
    }
  }),
  on(fromMetaDataActions.StoreRackingTypes,(state,{rackingTypes})=>{
    return{
      ...state,
      rackingTypes:rackingTypes
    }
  }),
  on(fromMetaDataActions.SaveDesignSuccess,(state)=>{
    return{
      ...state    
    }
  }),
  on(fromMetaDataActions.SavePanelArraysHardwareSuccess,(state)=>{
    return{
      ...state    
    }
  }), 
  
    on(fromMetaDataActions.setChangeFieldName,(state,{prevState}) =>{
      
      return {
        ...state,
        prevState : prevState

      }
    }),on(fromMetaDataActions.SaveMonitoringSysToHardwareSuccess,(state: any, {monitoringSystem} ) => {
      return{
        ...state,
        hardware: {
          ...state.hardware,
          monitoringSystem: monitoringSystem
        } 
      }
  
    }),
    on(fromMetaDataActions.saveSelectedMapInfo, (state, { map }) => {
      return {
        ...state,
          visibleMap: {
            ...state.visibleMap,
            selected: map
          } as any };
    }),
    on(fromMetaDataActions.Save3dModelSuccess,(state)=>{
      return {
    ...state,
    autoSave3Dmodel:false
    }
      }),
      on(fromMetaDataActions.SaveLink,(state,{s3url})=>{
        return {
      ...state,
      s3url:s3url
      }
        }), on(fromMetaDataActions.saveMapInfo,(state,{visibleMap})=>{
          return {
        ...state,
        visibleMap:visibleMap
        }
      }),on(fromMetaDataActions.GettingModelSuccess,(state,{s3url})=>{
        return {
      ...state,
      s3url:s3url.data
      }
        }),
        on(fromMetaDataActions.UpdateAutoSave3DSelector,(state,{autoSave3Dmodel})=>{
          return{
            ...state,
            autoSave3Dmodel:autoSave3Dmodel
          }
        }),on(fromMetaDataActions.ClearMetaData,(state)=>{
          return{
            ...state,
            quote: null,
            design:null,
            hardware:null,
            heightArray: null,
            leadDetails:null,
            selectedInverters:[],
            selectedBatteries:[],
            preferences: null,
            defaultMap:Maps.None,
            quoteSelectedMap:undefined,
            defaultModuleSetup:null,
            recentModules:[],
            otherModules:[],
            arrayTypes:[],
            panelTypes:[],
            availableRacking:[],
            rackingTypes:[],
            prevState:{
              field: '',
              value: null
            },
            s3url:null,
            visibleMap:null,
            autoSave3Dmodel:false,
            weatherStationLatLngJson:null,
          
      
          }
        }),
        
        on(fromMetaDataActions.WeatherStationLatLongFetched,(state,{data})=>{
          return{
            ...state,
            weatherStationLatLngJson:data
            
          }
        }),
        
        on(fromMetaDataActions.markSceneStatus,(state: any, data ) => {
          return { 
            ...state, 
            design: {
              ...state.design,
              isNewQuote: data.isNewQuote
            }};
      
        }),
 
);
