<ul class="menu-list width100">
  <li><img [ngStyle]="{'width.px': logoWidth, 'height.px': logoHeight}" *ngIf="logo" [src]="logo" /></li>
  <li *ngFor="let item of menuItems" appAmfaAccess moduleName="{{ item.name }}" forceCheckLevel="MODULE">
    <!-- <a [routerLink]="item.href" routerLinkActive="active" class="nav-text">{{ item.label }}</a> -->
    <a routerLinkActive="active" class="nav-text" (click)="callImageCapture();navigateMe(item.href)">{{'menuLabels.'+
      item.label | translate}}</a>
  </li>
  <li class="user-logo">
    <span mat-icon-button aria-label="user">
      <span class="user-name">{{ userInfo }}</span>
      <!-- <mat-icon class="user-avtar">person_outline</mat-icon> -->
      <mat-icon class="user-expand" [matMenuTriggerFor]="menu">expand_more</mat-icon>
    </span>
  </li>
</ul>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="callImageCapture();logout()">{{'logout' | translate}}</button>
</mat-menu>