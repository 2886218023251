import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { HeightImageService } from '@design/services/height-image-data.service';
import { Store } from '@ngrx/store';
import { fromAdditionalToolsActions } from '@design/store/additional-tools';
import { SaveStatus } from '@design/models/save-status.model';
import { DesignState } from '@design/store/design.reducer';
import { QuoteApiRequest } from '@api/models/quote.model';
import { AuthService } from 'src/app/helpers/auth.service';
import { SystemDesignService } from '../../system-design/system-design.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isSuccess } from 'src/app/models/simple.types';
import { ProjectWorkflowService } from '../../projects/project-workflow/project-workflow.service';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { Observable, of, race, Subject, timer } from 'rxjs';
import { catchError, switchMap, take, takeUntil, timeout } from 'rxjs/operators';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UserPreferenceService } from '@api/services/user-preference.service';

@Injectable({
  providedIn: 'root'
})

export class LoginDesignService {
  consumer: any;
  financeEnabled: boolean | undefined = undefined;
  designStorageState = DesignOnlyState.started;
  
  constructor(
    public heightImageService: HeightImageService,
    private store: Store<DesignState>,
    private authService: AuthService,
    private systemDesignService: SystemDesignService,
    public projectWorkflowService: ProjectWorkflowService,
    private mapsAPILoader: MapsAPILoader,
    public router: Router,
    private http: HttpClient,
    private userPreferenceService: UserPreferenceService
  ) {

  }
  
  async loginToDesign(params: any) {
    const res = await this.loadLeadInfo(params.crn);
    this.consumer = res.data;
    this.authService.setItem('crn', params.crn);
    this.authService.setItem('design_only', 'qkshodgh');
    this.authService.setItem('totalMeters', + res?.data?.c_number_meter);
    this.getSystemDesign(this.consumer);
  }



  async validateExternalLoginParams(params: any) {
    let key = params?.access_key
    let account_id = params?.account_id
    let crn = params?.crn
    let redirect_url = params?.redirect_url
    if (!key || !account_id || !crn || !redirect_url) {
      return "InvalidData";
    }

    let data = await this.authenticate(key, account_id)
    if ('email_id' in data) {
      this.authService.setItem('backToCrmUrl', redirect_url)
      return data;
    }
    return 'Unauthorized';

  }

  async getSystemDesign(consumer: any) {
    let defaultMapOption = ('defaultMapOption' in consumer) ? consumer['defaultMapOption'] : 'GOOGLESOLAR'
    let checkHasDSM = (('hasDSM' in consumer) ? consumer['hasDSM'] : true).toString();
    let designQuote = ('datQuote' in consumer) ? consumer['datQuote'] : ''
    let mode = (designQuote === '') ? 'create' : 'edit'

    if (true) {
      const payload: QuoteApiRequest = {
        crn: this.consumer?.crn,
        quoteType: this.consumer?.type,
        accountId: this.authService.getItem('account_id')
      }

      if (mode == 'create') {
        this.store.dispatch(fromAdditionalToolsActions.setMapLoadingStatus({ mapLoadingStatus: SaveStatus.OUTDATED }));
        this.heightImageService.checkHeightDataStatus(defaultMapOption, checkHasDSM);
        this.systemDesignService.createQuoteV2(payload, new HttpHeaders()).pipe(take(1)).subscribe((res) => {
          if (isSuccess(res.status) && Object.keys(res?.data).length) {
            const qrn = res?.data?.['qrn'];
            this.systemDesignService.changeActivePage('create');
            this.authService.setItem('qrn', qrn);
            this.authService.setItem('quote_id', res?.data?.['quoteId']);
            this.authService.setItem('revision_number', res?.data?.['revisionNumber']);
            this.authService.setItem('qrn', qrn);
            this.authService.setItem('quote_id', res?.data?.['quoteId']);
            this.authService.setItem('revision_number', res?.data?.['revisionNumber']);
            this.authService.setItem('quote_status', res?.data?.['quoteStatus']);
            this.authService.setItem('quote_status', res?.data?.['quoteStatus']);

            let reqBodyConsumer = { 'datQuote': qrn, crn: consumer.crn }
            this.http.put(`${environment.newApiUrl}lead`, reqBodyConsumer).pipe(take(1))
              .subscribe((resp: any) => {
                this.loadSystemDesign();

              });

          } else {
            //this.toastr.error(res.error);
          }

        }, (error) => {

        });

      } else {
        this.authService.setItem('qrn', designQuote);

        this.http.get(`${environment.newQuoteAPIEndpoint}quote/getQuoteByQrn/${designQuote}`).pipe(take(1))
          .subscribe(
            (response: any) => {
              this.systemDesignService.changeActivePage('edit');
              this.authService.setItem('qrn', response.qrn);
              this.authService.setItem('quote_id', response.quoteId);
              this.authService.setItem('revision_number', response.revisionNumber);
              this.authService.setItem('quote_lat', consumer?.latitude);
              this.authService.setItem('quote_long', consumer?.longitude);
              this.authService.setItem('quote_status', response['quote_status']);
              this.loadSystemDesign();
            },
            (error: any) => {
            }
          );
      }
    }
  }

  loadLeadInfo(crn: string):Promise<any> {
    return this.projectWorkflowService.getLeadInfo(crn).pipe(take(1)).toPromise();
  }

  loadSystemDesign() {
    const that = this;
    if (this.consumer.latitude != undefined && this.consumer.longitude != undefined) {
      this.authService.setItem("c_lat", this.consumer.latitude);
      this.authService.setItem("c_long", this.consumer.longitude);
      this.authService.setItem("quote_lat", this.consumer.latitude);
      this.authService.setItem("quote_long", this.consumer.longitude);
      if (!!this.consumer.defaultMapOption) {
        this.authService.isActiveTabSub$.next(0); // On edit quote click setting design tab enable in design page
        this.router.navigate(['/design']);
      } else {
        this.router.navigate(['/systemdesign']);
      }
    } else {

      const address = this.consumer.address + ", " + this.consumer.zip_code;
      this.mapsAPILoader.load().then(() => {
        let geocoder: any = new google.maps.Geocoder;
        let that = this;
        geocoder.geocode({ 'address': address }, function (results: any, status: any) {
          if (results[0]) {
            //do not REMOVE c_lat and c_long. Its being used in PVwatts for design
            that.authService.setItem("c_lat", results[0].geometry.location.lat());
            that.authService.setItem("c_long", results[0].geometry.location.lng());
            that.authService.setItem("quote_lat", results[0].geometry.location.lat());
            that.authService.setItem("quote_long", results[0].geometry.location.lng());
            if (!!that.consumer.defaultMapOption) {
              that.authService.isActiveTabSub$.next(0); // On edit quote click setting design tab enable in design page
              that.router.navigate(['/design']);
            } else {
              that.router.navigate(['/systemdesign']);
            }
          } else {
          }
        });
      });
    }
  }

  getCsrfToken(): Promise<any> {
    let url = `${environment.authApiUrl}/csrf-token/`
    let csrfResponse = this.http.get(url, { observe: 'response', withCredentials: true });
    return csrfResponse.toPromise()
  }

  authenticateKey(csrfToken: string, requestBody: any): Promise<any> {
    let url = `${environment.authApiUrl}/authenticate-key/`
    const headers = new HttpHeaders({
      'X-CSRFToken': csrfToken,
      'content-type': 'application/json'
    });
    let res = this.http.post(url, requestBody, { headers: headers, withCredentials: true });
    return res.toPromise()
  }

  async authenticate(access_key: string, account_id: string): Promise<any> {

    let res = await this.getCsrfToken()

    let requestBody = {
      "access_key": access_key,
      "account_id": account_id
    }

    let token = res.headers.get('CSRF-TOKEN') as string

    try {
      let responseAuth = await this.authenticateKey(token, requestBody);
      return responseAuth
    } catch (err) {
      return err;
    }
  }

  triggerWebhook(): Promise<any> {
    const apiUrl = `${environment.webhookApi}trigger/`
    const crn = this.authService.getItem('crn')
    const quoteId = this.authService.getItem('quote_id') + '/' + this.authService.getItem('revision_number')
    const account = this.authService.getItem('account_id')
    let payload = {
      "event_type": "design.created",
      "account_id": account,
      "request_body": { "project_id": crn, "quote_id": quoteId, "crn": crn }
    }
    let options = {
      headers: new HttpHeaders({
        'x-api-key': 'Xhha9E78OzxxseT1KrGJavUZazeoOp24SkBYBON6'
      }),
    }

    return this.executeObservableWithTimeout(this.http.post(apiUrl, payload, options), 10000);
  }

  async backToCrm(storageType: string = 'default'): Promise<void> {
    let crmUrl = this.authService.getItem("backToCrmUrl")
    const webhookResult = await this.triggerWebhook();
    console.log(webhookResult);
    this.authService.clearEverything();
    window.location.href = crmUrl;
  }

  async executeObservableWithTimeout(toExecute: Observable<any>, timeout:number): Promise<any> {
    const httpPromise = toExecute.toPromise();
    const timeoutPromise = new Promise((resolve) =>
      setTimeout(() => resolve({ error: 'Timeout occurred' }), timeout)
    );
    return Promise.race([httpPromise, timeoutPromise]);
  }

  async isFinanceEnabled(){
    if(this.financeEnabled === undefined){
      const params = {
        account_id: this.authService.getItem('account_id'),
        email_id: this.authService.getItem('email_id'),
        role: this.authService.getItem('role')
      };
      const data = await this.userPreferenceService.getUserPreference(params).pipe(take(1)).toPromise();
      try{
        if(data.preferencesObj.login_to_design?.finance?.enabled){
          this.financeEnabled = true;
        } else {
          this.financeEnabled = false;
        }
      } catch(e){
        this.financeEnabled = false;
      }
      
      return this.financeEnabled;
    } else return this.financeEnabled;
    
  }

  isLoggedInToDesign(){
    let locValue = this.authService.getItem('design_only', 'localStorage');
      if(this.authService.isLoggedIn() && locValue == null){
        this.designStorageState = DesignOnlyState.manipulated;
        return undefined;
       }
      if(this.designStorageState == DesignOnlyState.in_progress){
        if(locValue == null ){
          this.designStorageState = DesignOnlyState.manipulated;
          return undefined;
        }
      }
      if(this.designStorageState == DesignOnlyState.started){
        if(locValue != null ){
          this.designStorageState = DesignOnlyState.manipulated;
        }
        return false;
      }
      
      
      if (locValue == 'qkshodgh'){
        return true;
      } else if (locValue == 'dshytocm'){
        return false;
      } else {
        this.designStorageState = DesignOnlyState.manipulated;
        return undefined
      }
  }
}

export enum DesignOnlyState {
  manipulated,
  in_progress,
  started
}