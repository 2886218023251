import * as THREE from 'three';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/helpers/auth.service';
import { GeneralCursors, ObstacleCursors, PanelArrayCursors, RoofCursors, TreeCursors } from './helpers/cursors/cursorHelper';
import { RoofSection } from '@design/models/roof-section.model';
import { strFromU8, decompressSync } from 'fflate';
import axios from 'axios';
import { Panel } from '@design/models/panel.model';

export const parseToJson = (bin: any) => {
  return strFromU8(decompressSync(new Uint8Array(bin)));
}
/* gzip json ::  json */
export const getHeightJSON = async (url: string) => {
  let inflatedJSON = {};
  try {
    const { data } = await axios.get(url, { responseType: 'arraybuffer', decompress: true });
    // parse json
    inflatedJSON = JSON.parse(parseToJson(data))
  } catch (error) {
    console.error('could not fetch gzip json', error);
  }
  return inflatedJSON;
};

@Injectable({ providedIn: 'root' })
export class EngineService implements OnDestroy {
  private canvas: HTMLCanvasElement | any;
  private camera: THREE.PerspectiveCamera | any;
  private light: THREE.AmbientLight | any;

  private cube: THREE.Mesh | any;
  public lowQualityTexture!: THREE.Texture;
  private frameId: number | any = null;
  public scene: THREE.Scene;
  public renderer: THREE.WebGLRenderer | any;
  public constructor(private authService :AuthService ) {}
  $mouseDown: Subject<boolean> = new Subject<boolean>();
  updateMouseDownState(event: boolean){
    this.$mouseDown.next(event);
  }

  isMouseDown(){
    return this.$mouseDown.asObservable();
  }

  $camType: BehaviorSubject<string> = new BehaviorSubject<string>('2D');
  updateCamType(event: string){
    this.$camType.next(event);
  }

  getCamType(){
    return this.$camType.asObservable();
  }

  $focusOutOfDesign: Subject<boolean> = new Subject<boolean>;
  updateFocusOut(value: boolean){
    this.$focusOutOfDesign.next(value);
  }

  isFocusOut(){
    return this.$focusOutOfDesign.asObservable();
  }

  $roofSelected: Subject<string> = new Subject<string>();
  updateRoofSelectedState(event: string){
    this.$roofSelected.next(event);
  }

  getRoofSelectedState(){
    return this.$roofSelected.asObservable();
  }

  $treeCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateTreeStatus(event: boolean){
    this.$treeCompleted.next(event);
  }

  getTreeStatus(){
    return this.$treeCompleted.asObservable();
  }

  $treeMoveStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  updateTreeMove(event: boolean){
    this.$treeMoveStarted.next(event);
  }

  getTreeMove(){
    return this.$treeMoveStarted.asObservable();
  }

  $toggleOrbit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  toggleOrbit(event: boolean){
    this.$toggleOrbit.next(event);
  }

  getOrbitValue(){
    return this.$toggleOrbit.asObservable();
  }

  $treeCursorState: Subject<TreeCursors> = new Subject<TreeCursors>();
  updateTreeCursorState(event: TreeCursors){
    this.$treeCursorState.next(event);
  }

  getTreeCursorState(){
    return this.$treeCursorState.asObservable();
  }

  $obstacleCursorState: Subject<ObstacleCursors> = new Subject<ObstacleCursors>();
  updateObstacleCursorState(event: ObstacleCursors){
    this.$obstacleCursorState.next(event);
  }

  getObstacleCursorState(){
    return this.$obstacleCursorState.asObservable();
  }

  $generalCursorState: Subject<GeneralCursors> = new Subject<GeneralCursors>();
  updateGeneralCursorState(event: GeneralCursors){
    this.$generalCursorState.next(event);
  }

  getgeneralCursorState(){
    return this.$generalCursorState.asObservable();
  }

  $freeFormRoofCursorState: Subject<RoofCursors> = new Subject<RoofCursors>();
  updateFreeFormRoofCursorState(event: RoofCursors){
    this.$freeFormRoofCursorState.next(event);
  }

  getFreeFormRoofCursorState(){
    return this.$freeFormRoofCursorState.asObservable();
  }


  $panelArrayCursorState: Subject<PanelArrayCursors> = new Subject<PanelArrayCursors>();
  updatePanelArrayCursorState(event: PanelArrayCursors){
    this.$panelArrayCursorState.next(event);
  }

  getPanelArrayCursorState(){
    return this.$panelArrayCursorState.asObservable();
  }

  $multiSelectedPanels: Subject<string[]> = new Subject<string[]>();
  updateMultiSelectedPanels(event: string[]){
    this.$multiSelectedPanels.next(event);
  }

  getMultiSelectedPanels(){
    return this.$multiSelectedPanels.asObservable();
  }

  public ngOnDestroy(): void {
    if (this.frameId != null) {
      cancelAnimationFrame(this.frameId);
    }
    if (this.renderer != null) {
      this.renderer.dispose();
      this.renderer = null;
      this.canvas = null;
    }
  }

  public getGoogleMap(heightDataWorker: Worker, rgbtifFlag: boolean = false) {
    const crn = this.authService.getItem('crn');
    let url = '';
    if (rgbtifFlag)
      url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=GOOGLEMAPS&resource_type=image&rgbtif=${rgbtifFlag}`; // to fetch  google solar image
    else
      url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=GOOGLEMAPS&resource_type=image`; //to fetch  google maps image
    const token = `Bearer ${localStorage.getItem('token')}`;
    heightDataWorker.postMessage({ action: 'download-google-map-image',  url, token });
  }

  public getNearMap(heightDataWorker: Worker) {
    const crn = this.authService.getItem('crn');
    const url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=NEARMAPS&resource_type=image`;
    const token = `Bearer ${localStorage.getItem('token')}`;
    heightDataWorker.postMessage({ action: 'download-near-map-image',  url, token });
  }
  // For local testing purposes
  // getheightdetails(url:any) {
  //   //todo remove before production
  //   url = 'assets/json/heightArray.json';
  //   return this.http.get(url);//Util.MapData().heightsJson);
  // }

  public getMapHeight(heightDataWorker: Worker, hasDsmData = '', defaultMapOption = ''): void {
    const crn = this.authService.getItem('crn');
    let url = '';
    if (hasDsmData.toString() == 'false' && defaultMapOption === 'GOOGLEMAPS')
      url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=GOOGLEMAPS&resource_type=height`; // Height data for google maps - will be empty
    else if (hasDsmData.toString() == 'true' && defaultMapOption === 'GOOGLEMAPS')
      url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=GOOGLEMAPS&resource_type=height&rgbtif=${hasDsmData}`; // Height data for google Solar
    else
      url = `${environment.newSiteDataApiEndpoint}site/additional-info?crn=${crn}&map_option=NEARMAPS&resource_type=height`;/// Height data for Near maps

      const token = `Bearer ${localStorage.getItem('token')}`;
      heightDataWorker.postMessage({ action: 'download-height-data',  url, token });
  }

}
