import { Vertex } from '@design/models/vertex.model';
import { RoofSection } from '@design/models/roof-section.model';
import { createAction, props } from '@ngrx/store';

export namespace fromVertexActions {
  export const AddOne = createAction(
    '[Draw Area] add one vertex',
    props<{ vertex: Vertex }>()
  );

  export const AddMany = createAction(
    '[Draw Area] add many vertices',
    props<{ vertices: Vertex[] }>()
  );

  export const UpsertMany = createAction(
    '[Draw Area] upsert many vertices',
    props<{ vertices: Vertex[] }>()
  );

  export const DeleteMany = createAction(
    '[Draw Area] delete many vertices',
    props<{ vertexIds: Vertex['id'][] }>()
  );
}
