<div class="design-wrapper">
   
    <div class="row" >
      <div class="row-height">
        <div class="col-md-2 col-height" id="switcTemplate">
          <!-- <button type="button" (click)="changeButton('System')"  id="enactDefaultTemplateBtn" class="btn btn-md btn-info hollow_blue_btn"  [ngClass]="{'active':this.showSystem}">System</button> -->
          <button type="button" (click)="changeButton('Custom')"  id="customTemplateBtn" class="btn btn-md btn-info hollow_blue_btn" [ngClass]="{'active':this.showCustom}">Custom</button>
        </div>
          <div class="col-md-8 text-center col-height">
            <div class="inside inside-full-height">
                  <h2>Proposal Setup</h2>
            </div>
          </div>
        </div>
    </div>
  <div id="enactTemplateDiv" class="row" *ngIf='this.showSystem'>
    <div class="row-height">
      <div class="col-md-3 col-height">
        <div class="inside inside-full-height">
              <div class="leftTemplateContent">
                <blockquote>
              <small id="smallDiv">
                Current Template : <a data-type="select" id="setCurrentTemplate" ></a>
              </small>
              <button  id="PDFtemplateUpdate" class="btn btn-sm btn-warning PDFtemplateUpdate" type="button">Update</button>
              <button id="PDFtemplateDelete" class="btn btn-sm btn-danger" type="button">Delete</button>
              <button id="PDFtemplateDefaultForCurrent" class="btn btn-sm" style="" type="button">Set Default</button>
            </blockquote>
          <h5>Save current settings as : </h5>
               <div class="row settingsRowInput">
            <input type="text" id="templateName" placeholder="Template Name" class="col-sm-8" />
            <button id="PDFtemplateSave" class="btn btn-primary btn-sm col-sm-3" type="button">{{'saveCap' | translate  }} </button>
          </div>
            <div class="alert alert-danger errorTemplateSave">
             <strong>Error!</strong> Please enter template name
          </div>
          <div class="alert alert-success successTemplateSave">
             <strong>Success!</strong>
          </div>
              <div class="sidebar-nav">
              <div class="navbar navbar-default pdfTemplateNavBar" role="navigation">
                <div class="sidebar-navbar-collapse">
                  <ul class="nav navbar-nav nav-pills">
                      <li class="pdfTemplateSideMenuItem active">
                    <a id="sideMenuTitle">
                      <span class="menu-text">Title
                      </span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSummary">
                      <span class="menu-text">Summary</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSystem">
                      <span class="menu-text">System</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuUtilityGraph">
                      <span class="menu-text">Utility Usage and Savings Graphs</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuCurrentElecBill">
                      <span class="menu-text">Current Bill Table</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuNewElecBill">
                      <span class="menu-text">New Bill Table</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuOptionACash">
                      <span class="menu-text">Financing Option Cashflow</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuOptionBCash">
                      <span class="menu-text">Financing Option Cashflow</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuCashOnly">
                      <span class="menu-text">Cash Only Cashflow</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSocialImpact">
                      <span class="menu-text">Environmental Impacts</span>
                    </a>
                </li>
                  </ul>
                </div><!--/.nav-collapse -->
              </div>
             </div>
              </div>
          </div>
      </div>
      <div class="col-md-9 col-height">
        <div class="col-md-12 col">
          <div class="tabbable">
                <ul id="myTab" class="nav nav-tabs">
              <li class="active">
                <a href="#preview" data-toggle="tab">
                  <i class="fas fa-sync-alt"></i>
                  Preview
                </a>
              </li>
              <li>
                <a href="#settings" data-toggle="tab">
                  <i class="fas fa-pen-square"></i>
                  Settings
                </a>
              </li>
              <li>
                <p class="pull-right inline">
                  <button class="btn btn-sm btn-default col-sm-5 previewWebTemplate" type="button">Setup WebView Email</button>
                  <button id="previewTemplate" class="btn btn-sm btn-primary col-sm-3" type="button">Preview PDF</button>
                       <span class="label label-lg label-warning arrowed" id="prevButton">Prev</span>
                  <span class="label label-lg label-warning arrowed-right" id="nextButton" >Next</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
            <div class="content" id="pdfTemplateContainer">
              <!-- dynamic pdf template content goes in here -->
            </div>
      </div>
    </div>
  </div>
  <div id="customTemplateDiv" class="row customContentDiv marginTop10" *ngIf='this.showCustom' >
    <div class="row-height">
      <div class="col-md-3 col-sm-5 col-height">
        <div class="inside inside-full-height">
              <div class="leftTemplateContent">
                 
                <blockquote>
                    <small id="smallDiv">
                      Current Template :  <mat-form-field appearance="fill" >
                        <mat-label>Custom proposal template</mat-label>
                        <mat-select (selectionChange)="onTemplateChange($event)" [formControl]="custom_template">
                          <mat-option *ngFor="let template of templatesObj" [value]="template.value">
                            {{template.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </small>
              <div>
              <button  id="CustomUpload"class="btn btn-xs btn-warning " (click)='uploadModal()' data-toggle="modal" data-target="#customProposalUpload" type="button">Upload</button>
              <!-- This is will be included soon
               <button id="CustomtemplateUpdate" class="btn btn-sm btn-info"  data-toggle="modal" data-target="#customProposalUpload" type="button">Update</button>
               -->
               <button id="CustomtemplateDelete" (click)="delete()"class="btn btn-xs btn-danger" type="button">Delete</button>
              <button id="CustomtemplateDefaultForCurrent" (click)="setTemplate()" class="btn btn-xs btn-success" type="button">Set Default</button>
              <button id="CustomTemplateDownload" (click)="downloadFile()" type="button" class="btn btn-xs btn-primary"><i class="fa fa-download"></i></button>
              </div>
              <div class="alert alert-danger errorTemplateSave">
                <strong>Error!</strong> Please enter template name
              </div>
              <div class="alert alert-success successTemplateSave">
                <strong>Success!</strong>
              </div>
            </blockquote>
          <div class="sidebar-nav" style="margin: 14px">
              <div class="navbar navbar-default pdfTemplateNavBar" role="navigation">
                <div class="sidebar-navbar-collapse">
                  <ul class="nav navbar-nav nav-pills">
                      <li class="pdfTemplateSideMenuItem active">
                    <a id="sideMenuTitle">
                      <span class="menu-text">Page 1
                      </span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSummary">
                      <span class="menu-text">Page 2</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSystem">
                      <span class="menu-text">Page 3</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuUtilityGraph">
                      <span class="menu-text">Page 4</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuCurrentElecBill">
                      <span class="menu-text">Page 5</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuNewElecBill">
                      <span class="menu-text">Page 6</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuOptionACash">
                      <span class="menu-text">Page 7</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuOptionBCash">
                      <span class="menu-text">Page 8</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuCashOnly">
                      <span class="menu-text">Page 9</span>
                    </a>
                </li>
                <li class="pdfTemplateSideMenuItem">
                    <a id="sideMenuSocialImpact">
                      <span class="menu-text">Page 10</span>
                    </a>
                </li>
                  </ul>
                </div><!--/.nav-collapse -->
              </div>
             </div>
              </div>
               </div>
      </div>
      <div class="col-md-9 col-sm-7 col-height">
        <div class="inside inside-full-height">
              <div class="customContentDiv" id="custompdfTemplateContainer">
                <div class="col-md-12 col">
                  <div class="tabbable">
                    <ul class="nav nav-tabs">
                      <li [ngClass]="{'active':this.displayType == 'Preview'}">
                        <a   data-target="#openTemp" data-toggle="tab" (click)="setType('Preview')">
                          <i class="green ace-icon fa fa-pencil-square-o bigger-120"></i>
                          <p style="font-weight:500;font-size:16px">{{'design.toolBar.price&Fin.proposal.preview' | translate }}</p>
                        </a>
                      </li>
                      <li  class="customTemp " [ngClass]="{'active':this.displayType == 'Settings'}">
                        <a  class="customTemp" data-toggle="tab"  (click)="setType('Settings')">
                          <i class="green ace-icon fa fa-refresh bigger-120"></i>
                          <p style="font-weight:500;font-size:16px">Settings</p>
                        </a>
                      </li>
                      <li [ngClass]="{'active':this.displayType == 'Preview PDF'}">
                        <a data-toggle="tab" (click)="setType('Preview PDF')">
                          <i class="green ace-icon fa fa-pencil-square-o bigger-120" ></i>
                          <p style="font-weight:500;font-size:16px">Preview PDF</p>
                        </a>
                      </li>
                      <li class="pull-right">
                        <p>
                          <button class="btn btn-sm btn-default col-sm-5 previewWebTemplate" type="button">Setup WebView Email</button>
                        </p>
                      </li>
                      </ul>
                    <div class="tab-content">
                      <div class="tab-pane fade " [ngClass]="{'in active':this.displayType == 'Preview PDF'}" id="preview1" *ngIf="this.displayType =='Preview PDF'">
                        <div class="disabledScreen">
                          <h1 class="disabledBigText">Page Disabled</h1>
                                <p>Please go to settings to enable this page.</p>
                        </div>
                        <div class="row page-content" style="padding: 0px !important;">
                                  <div class="center-div col-md-12"> 
                                      <div class="overlay">
                                      </div> 
                                           <div class="col-md-12">
                                        <div id="preview_pdf_tag" style="height:600px;">
                                          <embed [src]="previewSrc1" type="application/pdf" height="100%" width="100%"/>
                                   
                                        </div>
                                        
                                      </div>
                                      <div class="col-md-12" style="margin-top:20px;">
                                          <div class="footer-container">
                                              <div class="col-md-8">
                                                  <span class="left-subfooter">Q#: <span class="quoteNo"></span> Proposal Valid until: <span class="proposalValidTill"></span></span>
                                              </div>
                                              <div class="col-md-4" style="float: left !important;">
                                                  <span class="right-subfooter">Page 1</span>     
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> 
                      </div>
                      <div class="tab-pane fade " [ngClass]="{'in active':this.displayType == 'Settings'}" id="info" *ngIf="this.displayType =='Settings'">
                        <div class="row page-content" style="padding: 0px !important;">
                        
                          <div class="col-md-12">
                             <div class="col-md-6">
                            <!-- 	Image sliding -->
                              <div class="container" style="height:100%;">
                                <br>
                                <div id="myCarousel" class="carousel slide" data-interval="false" data-ride="carousel">
                                 <!--  Indicators
                                  <ol class="carousel-indicators">
                                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                                    <li data-target="#myCarousel" data-slide-to="1"></li>
                                    <li data-target="#myCarousel" data-slide-to="2"></li>
                                    <li data-target="#myCarousel" data-slide-to="3"></li>
                                  </ol>
                              
                                  Wrapper for slides -->
                                  <div class="carousel-inner" role="listbox">
                                    <div class="item active">
                                      <img src="../../../../assets/images/tag_home.png" class="img-responsive"  alt="Back" width="500" height="500" style="height: 350px;" />
                                    </div>
                              
                                    <div class="item">
                                      <img src="../../../../assets/images/tag_proposedPanel.png" class="img-responsive"  alt="tag_proposedPanel" width="500" height="700" style=" height: 350px;"/>
                   
                                    </div>
                                  
                                    <div class="item">
                                      <img src="../../../../assets/images/tag_cashFlow.png" class="img-responsive"  alt="tag_cashFlow" width="500" height="700"style=" height: 350px;"/>
                   
                                    </div>
                              
                                    <div class="item">
                                      <img src="../../../../assets/images/tag_cashFlow1.png" class="img-responsive"  alt="tag_cashFlow1" width="500" height="500" style=" height: 350px;"/>
                                       </div>
                                  </div>
                              
                                  <a class="left carousel-control"  (click)="prev()" role="button" data-slide="prev">
                                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                  <a class="right carousel-control"  (click)="next()"  role="button" data-slide="next">
                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="container" style="height:100%;">
                                <br>
                                <div id="myCarouse2" class="carousel slide" data-interval="false" data-ride="carousel">
                                 <!--  Indicators
                                  <ol class="carousel-indicators">
                                    <li data-target="#myCarouse2" data-slide-to="0" class="active"></li>
                                    <li data-target="#myCarouse2" data-slide-to="1"></li>
                                    <li data-target="#myCarouse2" data-slide-to="2"></li>
                                    <li data-target="#myCarouse2" data-slide-to="3"></li>
                                  </ol>
                              
                                  Wrapper for slides -->
                                  <div class="carousel-inner" role="listbox">
                                    <div class="item active">
                                      <img src="../../../../assets/images/home.png" class="img-responsive"  alt="Home" width="500" height="500" style="height: 350px;"/>
                                    </div>
                              
                                    <div class="item">
                                      <img src="../../../../assets/images/proposePanel.png" class="img-responsive"  alt="proposePanel" width="500" height="500" style="height: 350px;" />
                                    </div>
                                  
                                    <div class="item" style="height: 350px;">
                                      <img src="../../../../assets/images/cashFlow.png" class="img-responsive"  alt="cashFlow" width="500" height="500"  style="height: 350px;"/>
                                    </div>
                              
                                    <div class="item">
                                      <img src="../../../../assets/images/cashFlow1.png" class="img-responsive"  alt="cashFlow1" width="500" height="500" style="height: 350px;" />
                                    </div>
                                  </div>
                              
                                  <a class="left carousel-control" role="button" data-slide="prev">
                                    <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                  <a class="right carousel-control"  role="button" data-slide="next">
                                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </div>
                              </div>
                            </div> 
                          </div>
                          <hr>
                          <div class="overlay">
                                      </div> 
                          <div class="col-md-12">
                            <ul class = "nav nav-tabs">
                               <li class="customTemp" [ngClass]="{'active':this.settingsType == 'lead'}"><a (click)="setSettings('lead')" data-toggle = "tab"><p style="font-weight:500;font-size:16px">Lead Information</p></a></li>
                               <li  [ngClass]="{'active':this.settingsType == 'system'}">
                                  <a  class="customTemp" data-toggle = "tab" (click)="setSettings('system')" >
                                     <p style="font-weight:500;font-size:16px">System Layout</p>
                                  </a>
                               </li>
                               
                               <li [ngClass]="{'active':this.settingsType == 'finance'}"><a (click)="setSettings('finance')"  data-toggle = "tab"><p style="font-weight:500;font-size:16px">Finance Information</p></a></li>
                              
                               <li [ngClass]="{'active':this.settingsType == 'user'}"><a  (click)="setSettings('user')"  data-toggle = "tab"><p style="font-weight:500;font-size:16px">Account Information</p></a></li>
                              
                            </ul>
                
                            <div id = "myTabContent" class = "tab-content">
                            
                               <div class = "tab-pane fade " id = "system" [ngClass]="{'in active':this.settingsType == 'system'}">
                              s    
                              </div>
                               
                               <div class = "tab-pane fade" id = "finance" [ngClass]="{'in active':this.settingsType == 'finance'}">
                               
                                 f
                               </div>
                               
                               
                               <div class = "tab-pane fade" id = "user" [ngClass]="{'in active':this.settingsType == 'user'}">
                              u 
                              </div>
                               
                               <div class = "tab-pane" id = "lead" [ngClass]="{'in active':this.settingsType == 'lead'}">
                                l
                              </div>
                               
                            </div>
                
                          </div>
                          
                          
                              </div> 
                      </div>
                      <div class="tab-pane fade" [ngClass]="{'in active':this.displayType == 'Preview'}" id="openTemp" *ngIf="this.displayType =='Preview'">
                        <div class="row page-content" style="padding: 0px !important;">
                                  <div id="embed_prev_pdf1" class="col-md-12" style="height:600px;">
                                    <embed [src]="previewSrc" type="application/pdf" height="100%" width="100%"/>
                                   </div> 
                                    
                              </div>
                         </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
    </div>
  </div>
  </div>

















 