import { APIResponse, Status } from './api-response.model';

export interface Finance {
  financing: Financing;
  capitalCost: CapitalCost;
  taxRate: number;
  costData: CostData;
  incentiveAndCredit: IncentiveAndCredit;
  qrn: string;
  _id?: string;
  proposalInfo: ProposalInfo;
}

export interface Financing {
  fundOptionA: FundOption;
  fundOptionB: FundOption;
  fundOptionC: FundOption;
  financingRequired: {
    fundOptionA: boolean;
    fundOptionB: boolean;
    fundOptionC: boolean;
  };
}

export interface FundOption {
  comboFinance: boolean;
  depSavings: number;
  dollarPerKWh: number;
  downPayment: number;
  finAmount: number;
  intrSavings: number;
  itc: Itc;
  lcoe: number;
  monthlyPayments: number;
  personalMortgageInterestDeduction: boolean;
  showAddnlBonusOrDepreciation: boolean;
  showAddnlTaxSvgsOrDepreciation: boolean;
  srec: Srec;
  utilityRateEscalator: string;
  addnDownpay: number;
  adjustedMonthlyPayment: number;
  initialMonthlyPayment: number;
  payDown: number;
  splitTerm: number;
  totalPaydown: number;
  bank?: Bank;
  combo?: Combo;
}

export interface Bank {
  name: string;
  options: Options;
  isAvailable: boolean;
  planID: string;
}

export interface Options {
  contractorFees: ContractorFees;
  customerFees: CustomerFees;
  PPA: Ppa;
  interestOnly: InterestOnly;
  loanMaxLimit: number;
  leaseFees: LeaseFees;
  loanMinLimit: number;
  term: number;
  yearlyPayments: number[];
  apr: number;
  minLoanTerm?: number;
  maxLoanterm?: number;
  bankname?: string;
  projectType?: string;
  loantype?: string;
  fico?: { check: string; min: number; max: number };
  feeType?: string;
  fee?: number;
  isCombo?: boolean;
  type?: string;
  active?: boolean;
}

export interface ContractorFees {
  type: string;
  value: number;
}

export interface CustomerFees {
  value: string;
  type: string;
  amount?: number;
}

export interface Ppa {
  price: number;
  escalator: number;
}

export interface InterestOnly {
  amount: number;
  terms: string;
}

export interface LeaseFees {
  value: number;
  type: string;
}

export interface Itc {
  amount: number;
  percentage: Percentage;
  enabled: boolean;
}

export interface Percentage {
  value: number;
  includeFee: boolean;
  termInYear?: number;
}

export interface Combo {
  amount: number;
  usrCombo: boolean;
  termInMonth: number;
}

export interface Srec {
  amount: number;
  period: number;
  name: string;
}
export interface CapitalCost {
  systemCost: SystemCost;
  effectivePrice: number;
  cashDiscount: CashDiscount;
  taxRate: number;
  taxRateType: string;
  salesTax: number;
  stateSales: StateSales;
}

export interface SystemCost {
  total: number;
  calculated: number;
  cost: number;
}

export interface CashDiscount {
  type: string;
  value: number;
}

export interface StateSales {
  tax: number;
  applied: boolean;
  amount: number;
}

export interface CostData {
  profile: string;
  onm?: Onm;
  premium ?:any;
  salesPriceBreakdown: SalesPriceBreakdown[];
  marginDetails : MarginDetails
}

export interface MarginDetails {
  totalCost?: string;
  description?: string;
  price?: string;
  priceType?: string;
  selected?: boolean;
  name?: string;
  quantity?: number;
}

export interface Onm {
  year1: Year1;
}

export interface Year1 {
  description: string;
  escalator: string;
  price: string;
  priceType: string;
  quantity: string;
  selected: boolean;
  term: number;
  totalCost: any;
}

export interface SalesPriceBreakdown {
  description: string;
  priceType: string;
  quantity: string;
  selected: boolean;
  price: number;
  totalCost: any;
  name: string;
}

export interface SalesPriceKeyBreakdown {
  [key: string]: SalesPriceBreakdown;
}

export interface IncentiveAndCredit {
  dollarPerWatt: DollarPerWatt;
  feedInTariff: FeedInTariff;
  fixedAmount: FixedAmount;
  other: Other;
  pbi: Pbi;
  profile: string;
  fourthRebate: FourthRebate;
  salesTaxCredit?: SalesTaxCredit;
  rebateId: string;
  selectedPreFinance?: SelectedPreFinance;
}

export interface SelectedPreFinance {
  preFinanceDc: boolean;
  preFinanceFixedAmount: boolean;
  preFinanceTotalPrice: boolean;
  preFinanceOtherRebate: boolean;
}

export interface DollarPerWatt {
  cap: Cap;
  name: string;
  term: number;
  yearWiseDivision: number[];
  tiers: Tiers;
  total?: number;
  type?: string;
}

export interface Cap {
  amount?: number;
  type?: string;
}

export interface FeedInTariff {
  cap: Cap;
  name: string;
  term: string;
  yearWiseDivision: number[];
  tiers: Tiers;
  total?: number;
}

export interface FixedAmount {
  cap: Cap;
  name: string;
  total: number;
  term: number;
  tiers?: { tier1: Tier1 };
}

export interface Other {
  name: string;
  tiers: { tier1: Tier1 };
}

export interface Tiers {
  tier1: Tier1;
  tier2: Tier1;
  tier3: Tier1;
}

export interface Tier1 {
  amount: number;
  maxValue?: string;
  name?: string;
}

export interface Pbi {
  cap: Cap;
  name: string;
  term: number;
  yearWiseDivision: number[];
  tiers: Tiers;
  total: number;
}

export interface FourthRebate {
  yearWiseDivision: number[];
  name: string;
  term: number;
  tiers: { tier1: Tier1 };
  total: number;
}

export interface SalesTaxCredit {
  cap: Cap;
  name: string;
  term: string;
  tiers: { tier1: Tier1 };
  yearWiseDivision?: number[];
  total: number;
  type: string;
}
export interface ProposalInfo {
  customProposalName: string;
  proposalType: string;
  customProposalTags: any;
  notes: string;
}

export const toQuoteFinance = (res: any): APIResponse<Finance> => ({
  data: {
    _id: res?._id,
    financing: res?.financing ? res?.financing : null,
    capitalCost: res?.capitalCost ? res?.capitalCost : null,
    taxRate: res?.taxRate ? res?.taxRate : null,
    costData: res?.costData ? res?.costData : null,
    incentiveAndCredit: res?.incentiveAndCredit
      ? res?.incentiveAndCredit
      : null,
    qrn: res?.financing,
    proposalInfo: !!res?.proposalInfo
      ? res?.proposalInfo
      : ({} as ProposalInfo),
  },
  status: Status.Success,
});
