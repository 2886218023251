import { Directive, Input, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { SettingsService } from 'src/app/modules/settings/settings.service';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[appAmfaAccess]'
})
export class AccessControlDirective implements OnInit {
  @Input('appName') appName = 'ENACT';
  @Input('moduleName') moduleName?: string;
  @Input('functionName') functionName?: string;
  @Input('actionName') actionName?: string;
  @Input('accessType') accessType?= 'hide';
  @Input('forceCheckLevel') forceCheckLevel?= '';
  @Input('className') className = 'disabled';

  accessObj: any = null;
  accessControls: any = null;
  constructor(private elementRef: ElementRef,
    private renderer: Renderer2, private authService: AuthService, private settingServices: SettingsService) { }
  ngOnInit(): void {
    this.accessObj = this.authService.getItem('accessObj', 'localStorage', true);
    if (this.accessObj) {
      this.accessControls = this.accessObj[this.appName];
      // this.elementRef.nativeElement.style.display = 'none';
      if (this.settingServices.servicesOffered && this.settingServices.servicesOffered.length > 0) {
        if (this.settingServices.servicesOffered[0].includes("taskDashboard")) {
          this.elementRef.nativeElement.style.display = 'inline-block';
        }
      }
      this.checkAccess();
    }
  }
  checkAccess(): void {
    const access = this.findAccountSecurity();
    switch (this.accessType) {
      case 'hide': {
        if (this.settingServices.servicesOffered && this.settingServices.servicesOffered.length > 0) {
          if (this.settingServices.servicesOffered[0].includes("taskDashboard")) {
            this.elementRef.nativeElement.style.display = 'inline-block';
          }
        }
        else
          this.elementRef.nativeElement.style.display = access == true ? 'inline-block' : 'none';
        break;
      }
      case 'remove': {
        if (!access) {
          this.elementRef.nativeElement.remove();
        }
        break;
      }
      case 'class': {
        this.renderer.removeClass(this.elementRef.nativeElement, this.className);
        if (!access) {
          this.renderer.addClass(this.elementRef.nativeElement, this.className);
        }
        break;
      }
      default: {
        this.elementRef.nativeElement.disabled = !access;
        break;
      }
    }
  }
  findKey(objectData: any, keyName: any): any {
    for (const key in objectData) {
      if (key === keyName) {
        return objectData[key];
      }
    }
  }
  findAccountSecurity(): boolean {
    // tslint:disable-next-line:one-variable-per-declaration
    let functionData, moduleData, actionData = null, accessType = false;

    if (Object.keys(this.accessControls).length === 0 && this.accessControls.constructor === Object) {
      // Which means have a full access of an application
      accessType = true;
      return accessType;
    }
    moduleData = this.findKey(this.accessControls, this.moduleName);
    if (moduleData && Object.keys(moduleData).length === 0 && this.accessControls.constructor === Object) {
      // Which means have a full access of a module
      accessType = true;
      return accessType;
    }
    if (moduleData && this.forceCheckLevel && this.forceCheckLevel.toLowerCase() === 'module') {
      // This is used for header section where if header module is present and has some functions but still we want to show header
      accessType = true;
      return accessType;
    }
    functionData = this.findKey(moduleData, this.functionName);
    if (functionData && Object.keys(functionData).length === 0 && this.accessControls.constructor === Object) {
      // Which means have a full access of a function
      accessType = true;
      return accessType;
    }
    if (functionData && this.forceCheckLevel && this.forceCheckLevel.toLowerCase() === 'function') {
      // This is used for header section where if header function is present and has some actions but still we want to show header
      accessType = true;
      return accessType;
    }
    if (this.moduleName && this.functionName && this.actionName) {
      // Check access at action level
      actionData = this.findKey(functionData, this.actionName);
      if (!actionData) {
        accessType = false;
      } else {
        accessType = true;
      }
    }
    return accessType;
  }
}
