import { Expose, Transform } from 'class-transformer';
import { SystemDesignObject } from './system-design-object.abstract.model';
import { ConfigurationIds } from './ui-state.model';

export interface IQuoteInverter {
  dcAcRatio: number;
  efficiency: number;
  addedCapacity: number;
  configured: Inverter[];
}

export class SelectedInverter {
  recentlyUsed: boolean = false;
  inverterId!: string;
  powerRating!: string;
  notes!: string;
  description!: string;
  unitCost!: number;
  manufacturer!: string;
  model!: string;
}

export class Inverter extends SystemDesignObject {
  id!: string;
  inverterId!: string;
  selectConfigId?: boolean | undefined;
  InverterModelnumber?: string | undefined;
  configurationId!: ConfigurationIds.Inverter;
  @Transform(({ obj }) => obj.description || obj.Description)
  @Expose({ name: 'description' })
  description!: string;
  logo?: string | undefined;
  cecEffeciency?: string| undefined;
  file?: string| undefined;
  notes!: string;
  @Transform(({ obj }) => obj.ManufacturerName || obj.manufacturer)
  @Expose({ name: 'manufacturer' })
  manufacturer!: string;
  @Transform(({ obj }) => obj.InverterModelnumber || obj.model)
  @Expose({ name: 'model' })
  model!: string;
  @Transform(({ obj }) => obj.powerRating || obj.PowerRating)
  @Expose({ name: 'powerRating' })
  powerRating!: string;
  unitCost!: number;
  quantity!: number;
  utilizedCapacity!: number;
  recentlyUsed: boolean = false;
}
