export interface Battery {
  namePlateCapacity: number;
  maxChargePower: number;
  maxDischarge: number;
  maxDepthOfDischarge: number,
  chargeEfficiency: number,
  dischargeEfficiency: number,
  batteryDegradation: number,
  numberOFBattery: number,
  coupling: string,
  reserve: number,
  controlStrategy: string,
  isSgip: boolean,
  manufacturerName: number,
  batteryModelnumber: string,
  unitCost: number,
  totalAvailableCapacity: number,
  totalPowerOutput: number,
  totalRatedCapacity: number,
  totalUsableCapacity: number
}

export const toBatteryConfig = (res: Battery): Battery => ({
  namePlateCapacity: res?.namePlateCapacity,
  maxChargePower: res?.maxChargePower,
  maxDischarge: res?.maxDischarge,
  maxDepthOfDischarge: res?.chargeEfficiency,
  chargeEfficiency: res?.chargeEfficiency,
  dischargeEfficiency: res?.dischargeEfficiency,
  batteryDegradation: res?.batteryDegradation,
  numberOFBattery: res?.numberOFBattery,
  coupling: res?.coupling,
  reserve: res?.reserve,
  controlStrategy: res?.controlStrategy,
  isSgip: res?.isSgip,
  manufacturerName: res?.manufacturerName,
  batteryModelnumber: res?.batteryModelnumber,
  unitCost: res?.unitCost,
  totalAvailableCapacity: res?.totalAvailableCapacity,
  totalPowerOutput: res?.totalPowerOutput,
  totalRatedCapacity: res?.totalRatedCapacity,
  totalUsableCapacity: res?.totalUsableCapacity
})