import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LibUiComponentComponent } from './lib-ui-component.component';
import { FormsModule } from '@angular/forms';

// atomic component
import { EnactCheckBoxComponent } from './atomic/enact-checkbox/enact-checkbox.component';
import { MatRadioModule } from '@angular/material/radio';
import { EntRadioGroup, EnactRadioButtonComponent } from './atomic/enact-radio-button/enact-radio-button.component';
import { EnactColorpickerComponent } from './atomic/enact-colorpicker/enact-colorpicker.component';
import { EnactAlertSnackBarModule } from './atomic/enact-alert-snack-bar/enact-alert-snack-bar.module';

import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { EnactCompositeButtonModule } from './composite/enact-composite-button/enact-composite-button.module';
import { EnactIconModule } from './composite/enact-icon/enact-icon.module';
import { ProgressBarVerticalIndicatorModule } from './composite/progress-bar-vertical-indicator/progress-bar-vertical-indicator.module';
import { HelpModule } from './composite/help/help.module';
import { EnactTextCounterModule } from './composite/enact-text-counter/enact-text-counter.module';
import { CommonModule } from '@angular/common';
import { EnactColoredTileModule } from './composite/enact-colored-tile/enact-colored-tile.module';
import { EnactConfirmationBoxModule } from './composite/enact-confirmation-box/enact-confirmation-box.module';
import { EnactNotifySbarModule } from './atomic/enact-notify-sbar/enact-notify-sbar.module';
import { EnactReserveBarModule } from './composite/enact-reserve-bar/enact-reserve-bar.module';
import { EnactToggleButtonGroupModule } from './composite/enact-toggle-button-group/enact-toggle-button-group.module';
import { EnactListItemModule } from './composite/enact-list-item/enact-list-item.module';
import { EnactTimePickerModule } from './atomic/enact-time-picker/enact-time-picker.module';
// import { EnactMonthPickerModule } from './atomic/enact-month-picker/enact-month-picker.module';
import { MonthsTimeGridModule } from './composite/months-time-grid/months-time-grid.module';
import { LibUISharedModule } from './lib-ui-shared.module';


// import { MatIconModule } from '@angular/material/icon';

const MaterialComponents = [
  MatInputModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  // MatIconModule,
];

@NgModule({
  declarations: [
    LibUiComponentComponent,
    EnactCheckBoxComponent,
    EnactRadioButtonComponent,
    EntRadioGroup,
    EnactColorpickerComponent,
  ],
  imports: [
    MaterialComponents,
    FormsModule,
    MatRadioModule,
    FormsModule,
    CommonModule,
    LibUISharedModule
  ],
  exports: [
    LibUiComponentComponent,
    EnactCheckBoxComponent,
    EntRadioGroup,
    EnactRadioButtonComponent,
    EnactColorpickerComponent,
    EnactCompositeButtonModule,
    LibUISharedModule,
    HelpModule,
    EnactIconModule,
    EnactTextCounterModule,
    EnactColoredTileModule,
    EnactConfirmationBoxModule,
    EnactAlertSnackBarModule,
    EnactNotifySbarModule,
    ProgressBarVerticalIndicatorModule,
    EnactReserveBarModule,
    EnactToggleButtonGroupModule,
    EnactListItemModule,
    EnactTimePickerModule,
    // EnactMonthPickerModule,
    MonthsTimeGridModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LibUiComponentModule { }
