import { Status } from 'src/app/models/simple.types';
import { APIResponse } from './api-response.model';

export interface Design {
  _id: string;
  combinedImage: string;
  keepOuts: KeepOut[];
  roofOutlines: RoofOutlines[];
  map: VisualMap;
  backgroundImage: { bound: Bounds; id: string };
  panel: {
    groups: PanelGroups[];
    details: string[];
    shadingMap: {
      0: Shading;
    }
  }
  imageUrl: string;
}

export interface KeepOut {
  id: string;
  type: number;
  path: string;
  height: string;
  shadowPath: any[];
  setback: number;
  length: string;
  width: string;
  rotateAngle: string;
  originalPath: string;
  radius: string;
  center: string;
  panelsCoveredbyKeepout: any[];
  panelsCoveredbyShadow: any[];
}

export interface RoofOutlines {
  id: string;
  path: string;
  type: string;
  tilt: string;
  roofHeight: string;
  parapetHeight: number;
}

export interface VisualMap {
  selected: string;
  bounds: Bounds;
  zoom: number;
  center: { lat: number; lng: number };
  restriction: { latLngBounds: Bounds; strictBounds: boolean };
  maxZoomLimit: number;
}

export interface Bounds {
  south: number;
  west: number;
  north: number;
  east: number;
}

export interface PanelGroups {
  id: string;
  path: string;
  panelOrientation: string;
  position: PanelsArray[];
}

export interface Shading {
  type: string;
  annualValue: number;
  monthly: any;
}

export interface PanelsArray {
  id: string;
  draggable: boolean;
  visible: boolean;
  path: string;
  map: boolean;
}

export const toDesignConfig = (res: any): Design => ({
  _id: res?._id,
  combinedImage: res?.combinedImage,
  keepOuts: res?.keepOuts?.length ? res?.keepOuts : ([] as KeepOut[]),
  roofOutlines: res?.roofOutlines?.length
    ? res.roofOutlines
    : ({} as RoofOutlines[]),
  map: res?.map ? res.map : ({} as VisualMap),
  backgroundImage: res?.backgroundImage
    ? res?.backgroundImage
    : ({} as { bound: Bounds; id: string }),
  panel: {
    groups: res?.panel?.groups?.length
      ? res.panel.groups
      : ([] as PanelGroups[]),
    details: res?.panel?.details?.length ? res?.panel?.details : [],
    shadingMap: res?.panel?.shadingMap ? res?.panel?.shadingMap : {}
  },
  imageUrl: res?.imageUrl
});



export type DesignAPIResponse = APIResponse<Design>;

export const toQuoteDesign = (res: any): DesignAPIResponse => ({
  data: toDesignConfig(res),
  status: Status.Success,
});
