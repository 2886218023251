<ng-container *ngIf="{
    isDealer: isDealer$ | async,
    isDealerEmployee: isDealerEmployee$ | async,
    showArchive: showArchive$ | async,
    archiveList: archiveList$| async
} as data">
    <div class="top-outer-wrapper">
        <mat-card *ngIf="!data?.showArchive" class="nopadding">
            <div class="costing-header">
                <h2 class="width100 padding20 marginbotton0">{{'settings.costingSetup.costSetupSm' | translate }}</h2>
                <div class="view-archive" (click)="viewArchive()">{{'settings.costingSetup.viewArchivedPf' | translate }}</div>
            </div>
             <div *ngIf="createProfileStarted || copyProfile">
                <div fxLayout="row" class="width100 padding20" fxLayoutAlign="start center">
                    <div fxFlex="50" class="font18 header-position" style="display:inline-block">
                        <mat-form-field appearance="fill">
                            <mat-label>{{'settings.finSetup.PrName' | translate }}</mat-label>
                            <input matInput [(ngModel)]="newProfileName">
                        </mat-form-field>
                    </div>
                    <div *ngIf="!setupRange" fxFlex="20" style="display:inline-block;">
                        <button [disabled]="copyProfile && costingSetupForm?.invalid" mat-raised-button color="primary" (click)="setNewCostProileName()">
                            <mat-icon>done</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!setupRange" fxFlex="20" style="display:inline-block;margin-left: 10px;">
                        <button mat-raised-button color="warn" (click)="cancelCreateProfile()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="setupRange" class="padding20">

                    <table #rangetable mat-table id="rangeTable" [dataSource]="rangeTableData"
                        class=" mat-elevation-z8 ">
                        <ng-container matColumnDef="range">
                            <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"
                                style="width:50px;padding-left: 20px;min-width: 80px"> {{'settings.costingSetup.range' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class=""
                                style="width:50px;padding-left: 20px;min-width: 80px; padding-top: 0.5em;">
                                {{element.range}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="minimum">
                            <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"
                                style="width:100px;padding-left: 20px;min-width: 80px"> {{'settings.costingSetup.minKw' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class=""
                                style="width:50px;padding-left: 20px;min-width: 80px">
                                <mat-form-field floatLabel="never" style="width:100%;padding-left: 0;">
                                    <input type="text" matInput placeholder="{{'limit' | translate }}" [(ngModel)]="element.min" disabled>
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="maximum">
                            <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"
                                style="width:100px;padding-left: 20px;min-width: 80px"> {{'settings.costingSetup.maxKw' | translate }} </th>
                            <td mat-cell *matCellDef="let element" class=""
                                style="width:100px;padding-left: 20px;min-width: 80px">
                                <mat-form-field floatLabel="never" style="width:100%;padding-left: 0;"
                                    appearance="fill">
                                    <input type="text" matInput placeholder="{{'limit' | translate }}" [max]="MAX_RANGE"
                                        [(ngModel)]="element.max">
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="add">
                            <th mat-header-cell *matHeaderCellDef class="font-roboto-bold"
                                style="width:100px;padding-left: 20px;min-width: 80px"> </th>
                            <td mat-cell *matCellDef="let element" class=""
                                style="width:100px;padding-left: 20px;min-width: 80px">
                                <button *ngIf="element.last" mat-raised-button color="primary" (click)="addRangeRow()">
                                    {{'settings.costingSetup.addRow' | translate }}
                                </button>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="rangeTableDisplayColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: rangeTableDisplayColumns;"> </tr>
                        <ng-container matColumnDef="resetbutton">
                            <td mat-footer-cell *matFooterCellDef colspan="4">
                                <div style="float:right">
                                    <button mat-raised-button color="primary" (click)="resetRangeTable()">
                                        {{'buttons.reset' | translate }}
                                    </button>
                                    <button style="margin:10px" mat-raised-button color="primary"
                                        (click)="applyRangeTable()">
                                        {{'buttons.apply' | translate }}
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-footer-row *matFooterRowDef="['resetbutton']"></tr>
                    </table>

                </div>
            </div>
            <div *ngIf="!createProfileStarted">
                <div fxLayout="row" class="paddingleft20" *ngIf="!copyProfile" fxLayoutAlign="start center">
                    <div class="font18 header-position" style="display:inline-block">
                        <mat-form-field appearance="fill">
                            <mat-label>{{'settings.costingSetup.costPr' | translate }}:</mat-label>
                            <mat-select (selectionChange)="changeProfile($event)" [(ngModel)]="selectedProfile">
                                <mat-option *ngFor="let data of costingProfiles"
                                    [value]="data.Profile_name">{{data.Profile_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="!isSelectedDefault" fxFlex="30" style="display:inline-block">
                        <button mat-button (click)="setAsDefault()">
                            {{'settings.docSetup.setAsDefault' | translate }}
                        </button>
                    </div>
                </div>
                <div fxLayout="row" class="paddleftright20" *ngIf="!copyProfile" fxLayoutAlign="right">
                    <div fxLayout="row" class="align-right">
                        <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                            <button mat-raised-button color="primary" (click)="createNewProfile()">
                                {{'settings.costingSetup.createNwPf' | translate | titlecase }}
                            </button>
                        </div>
                        <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                            <button mat-raised-button color="primary" (click)="copyAsNewProfile()">
                                {{'settings.costingSetup.copyAsNewPf' | translate }}
                            </button>
                        </div>
                        <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                            <button mat-raised-button color="primary" [disabled]="costingSetupForm?.invalid"
                                (click)="saveCurrentProfile()">
                                {{'settings.costingSetup.svCostingPf' | translate }}
                            </button>
                        </div>
                        <div fxFlex="30" style="display:inline-block;" class="paddingleft20">
                            <button mat-raised-button color="warn" (click)="archiveCurrentProfile()">
                              <img class="archive-icon" src="../../../../assets/images/archive.png"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="padding20">
                    <div fxLayout="row" class="padding0" fxLayoutAlign="right">

                        <div fxFlex="30" fxLayout="row" class="align-right">
                            <div *ngIf="hasSaleMargin" fxFlex="30" style="float:left;display:inline;">
                                <label class="lbl-field">{{'settings.costingSetup.minMargin' | translate }}</label>
                                <input class="margintop10 marginInputs" [(ngModel)]="minimumMargin"
                                    (change)="updateMinimumMargin()" type="text" [min]="0" matInput />%

                            </div>
                            <div *ngIf="isOAndM?.displayOnCostingSetup" fxFlex="30" style="display:inline-block"
                                class="paddingleft20">
                                <mat-checkbox
                                    style="font-family: 'sans-regular' !important;font: 400 14 px / 20 px 'sans-regular' !important;font-size: 12px;font-weight: 500;text-transform:  important;color: #000;"
                                    [(ngModel)]="isOAndM.value" (ngModelChange)="onOAndMChange($event)">
                                    {{'settings.costingSetup.O&MEdit' | translate }}
                                </mat-checkbox>
                            </div>
                            <div *ngIf="!data?.isDealer && !data?.isDealerEmployee" fxFlex="30"
                                style="display:inline-block" class="paddingleft20">
                                <mat-checkbox
                                    style="font-family: 'sans-regular' !important;font: 400 14 px / 20 px 'sans-regular' !important;font-size: 12px;font-weight: 500;text-transform:  important;color: #000;"
                                    [(ngModel)]="visibleToDealer" (change)="updateVisbleToDealer(visibleToDealer)">
                                    {{'settings.costingSetup.visibleDealer' | translate }}
                                </mat-checkbox>
                            </div>
                            <ng-container *ngIf="!data?.isDealerEmployee">
                                <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                                    <mat-checkbox
                                        style="font-family: 'sans-regular' !important;font: 400 14 px / 20 px 'sans-regular' !important;font-size: 12px;font-weight: 500;text-transform:  important;color: #000;"
                                        [(ngModel)]="hideCalualtedValue"
                                        (change)="updateHideCalualteValue(hideCalualtedValue)">
                                        {{'settings.costingSetup.hideCalVal' | translate }}
                                    </mat-checkbox>
                                </div>
                                <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                                    <mat-checkbox
                                        style="font-family: 'sans-regular' !important;font: 400 14 px / 20 px 'sans-regular' !important;font-size: 12px;font-weight: 500;text-transform:  important;color: #000;"
                                        [(ngModel)]="hideSalePriceBreakdown"
                                        (change)="updateHideSalePriceBreakdown(hideSalePriceBreakdown)">
                                        {{'settings.costingSetup.hideSlPrBreakdown' | translate }}
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                            <div *ngIf="hasSaleMargin" fxFlex="30" style="display:inline-block" class="paddingleft20">
                                <mat-checkbox
                                    style="font-family: 'sans-regular' !important;font: 400 14 px / 20 px 'sans-regular' !important;font-size: 12px;font-weight: 500;text-transform:  important;color: #000;"
                                    [(ngModel)]="hideMargin" (change)="updateHideMargin(hideMargin)">
                                    {{'settings.costingSetup.hideMargin' | translate }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow-x: scroll;" class="costSetupTablediv">
                    <form #costingSetupForm="ngForm" (ngSubmit)="costingSetupForm.form.valid" novalidate>
                        <table #costtable mat-table id="costSetupTable" [dataSource]="costData"
                            class="width100 mat-elevation-z8 ">
                            <!---->
                            <ng-container matColumnDef="delete" class="abc padding0" sticky>
                                <th mat-header-cell *matHeaderCellDef class=" padding0"></th>
                                <td mat-cell *matCellDef="let element" class=" padding0">
                                    <button mat-icon-button color="warn" *ngIf="element.delete"
                                        (click)="deleteRow(element)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </ng-container>
                            <!--name row-->
                            <ng-container matColumnDef="item" sticky>
                                <th style="padding-left: 0.5em;" mat-header-cell *matHeaderCellDef
                                    class="font-roboto-bold "> {{'design.toolBar.price&Fin.breakDown.item' | translate }} </th>
                                <td mat-cell *matCellDef="let element">
                                    <span style="padding-left: 0.5em;" *ngIf="!element.delete">{{element.name}}</span>
                                    <mat-form-field *ngIf="element.delete" floatLabel="never"
                                        style="width:100%;padding-left: 0;" appearance="fill">
                                        <input type="text" matInput placeholder="Adder"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="element.name"
                                            (click)=nameChange($event,element)>
                                    </mat-form-field>
                                </td>

                            </ng-container>

                            <ng-container matColumnDef="select">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="selectAll"
                                        (change)="selectAllAdders($event)">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-checkbox [ngModelOptions]="{standalone: true}" [(ngModel)]="element.select"
                                        [disabled]="element.delete == false"
                                        (change)="setAdderSelected($event,element)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"> {{'design.leftPanel.inverter.invDetails.descrp' | translate }} </th>
                                <td mat-cell *matCellDef="let element" class="table-column-100">
                                    <mat-form-field floatLabel="never" style="width:100%;padding-left: 0;"
                                        appearance="fill">
                                        <input type="text" matInput placeholder="{{'design.leftPanel.inverter.invDetails.descrp' | translate }}"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="element.description"
                                            (change)=descriptionChange($event,element)>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="type">
                                <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"> {{'settings.hrdwareSetup.type' | translate }} </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-select (selectionChange)="setRowType($event,element)"
                                        class=" align-drpdown select-profile" [ngModelOptions]="{standalone: true}"
                                        [(ngModel)]="element.type">
                                        <mat-option *ngFor="let type of element.selectItem" [value]="type">
                                            <span>{{type}}</span>
                                        </mat-option>
                                    </mat-select>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="minMarkup">
                                <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"> {{'settings.costingSetup.minMarkUp' | translate }} </th>
                                <td mat-cell *matCellDef="let element ;let rowIdx = index;">
                                    <mat-form-field style="width:100%" appearance="fill"
                                        *ngIf="element.minMarkup!='N/A'">
                                        <input style="width:80px" type="text" #minMarkup="ngModel" name="txtName{{rowIdx}}" matInput 
                                        [(ngModel)]="element.minMarkup" (change)="minMarkupChange($event,element)" [min]="0" required>
                                    </mat-form-field>
                                    <mat-form-field style="width:100%" appearance="fill" *ngIf="element.minMarkup=='N/A'">
                                        <input style="width:80px" matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="element.minMarkup" (change)=minMarkupChange($event,element) disabled>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let range of ranges;let i = index" matColumnDef="{{ranges[i].index}}">
                                <th mat-header-cell *matHeaderCellDef class=" font-roboto-bold"
                                    style="width:5%;padding-left: 1em;max-width: 80px;"> {{ranges[i].name}} (kW) </th>
                                <td mat-cell *matCellDef="let element; let rowIdx = index;" class="table-column-100"
                                    style="width:5%;padding-left: 1em;max-width: 80px">
                                    <mat-form-field style="width:100%" appearance="fill">
                                        <input name="txtNameRange{{rowIdx}}{{i}}{{range.index}}" #kw="ngModel" matInput  [ngModel]="element[range.index]" 
                                         type="text" (change)="changeRangeValue($event,element,range.index)" [min]="0" required>
                                    </mat-form-field>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
                        </table>
                    </form>

                </div>
                <div class="paddingleft20">
                    <button mat-raised-button color="primary" (click)="addCostAdder()">
                        {{'settings.costingSetup.addaRow' | translate }}
                    </button>
                </div>
            </div>

            <div class="'padding20 margintop25">
                <h3 class="width100 paddingleft20 marginbotton0 font-roboto-bold">{{'settings.costingSetup.saleTaxSetup' | translate }}</h3>
                <div fxLayout="row" class="paddingleft20" fxLayoutAlign="right">
                    <div fxFlex="30" style="display:inline-block">
                        <label class="lbl-field">{{'settings.costingSetup.saleTaxBasedOn' | translate }}: </label>
                        <input class="margintop10 marginInputs" [(ngModel)]="salesTaxSetup.value" (change)="formNumberLocaleFormat()" type="text"
                            [min]="0" matInput />
                    </div>
                    <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                        <mat-select class=" align-drpdown select-profile" style="width: 10em;"
                            [(ngModel)]="salesTaxSetup.type">
                            <mat-option value="percentOfSysPrice">
                                <span>{{'settings.costingSetup.%SysPrice' | translate }}</span>
                            </mat-option>
                            <mat-option value="perWattPrice">
                                <span>{{applyLocaleVar}}/{{'settings.finSetup.watts' | translate }}</span>
                            </mat-option>
                        </mat-select>
                    </div>
                    <div fxFlex="30" style="display:inline-block" class="paddingleft20">
                        <button mat-raised-button color="primary" (click)="saveSalesTaxSetup()">
                            {{'settings.costingSetup.saveSaleTaxSetup' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </mat-card>
        <mat-card *ngIf="!!data?.showArchive" class="nopadding">
            <app-archive-view [archiveList]="data.archiveList" (closeArchiveView)="showArchive$.next(false)"
            (onActionChange)="onActionChange($event)"></app-archive-view>
        </mat-card>
    </div>
</ng-container>