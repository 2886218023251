import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NotificationMessage, NotificationType } from './notification.message';
import { ToastrService } from 'ngx-toastr';
import { EnactAlertSnackBarComponent } from './enact-alert-snack-bar.component';

@Injectable({
    providedIn: 'root'
})
export class openNotificationService {

    private notificationSubject: Subject<NotificationMessage> = new Subject<NotificationMessage>();

    sendMessage(message: NotificationMessage) {
        this.notificationSubject.next(message);
    }

    constructor(private toastrService: ToastrService) {
        this.listenForMessages();
    }

    listenForMessages() {
        this.notificationSubject.subscribe(message => {
            switch (message.type) {
                case NotificationType.success:
                    this.toastrService.success(message.message,'',{positionClass: message.positionClass,timeOut:message.timeOut,toastComponent: EnactAlertSnackBarComponent,toastClass: "toast-icon custom-toast-success",titleClass:message.type});
                    break;
                case NotificationType.info:
                    this.toastrService.info(message.message,'',{positionClass: message.positionClass,timeOut:message.timeOut,toastComponent: EnactAlertSnackBarComponent,toastClass: "toast-icon custom-toast-error",titleClass:message.type});
                    break;
                case NotificationType.danger:    
                case NotificationType.error:
                    this.toastrService.error(message.message,'',{positionClass: message.positionClass,timeOut:message.timeOut,toastComponent: EnactAlertSnackBarComponent,toastClass: "toast-icon custom-toast-error",titleClass:message.type});
                    break;
                case NotificationType.warning:
                    this.toastrService.warning(message.message,'',{positionClass: message.positionClass,timeOut:message.timeOut,toastComponent: EnactAlertSnackBarComponent,toastClass: "toast-icon custom-toast-warning",titleClass:message.type});
                    break;
                default:
                case NotificationType.success:
                    this.toastrService.info(message.message,'', {positionClass: message.positionClass,timeOut:message.timeOut,toastComponent:EnactAlertSnackBarComponent,toastClass: "toast-icon custom-toast-success",titleClass:'success'});
                    break;
            }
        }, err => {
            console.log('Error when processing toastr message');
        });
    }
}