import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ActionReducer, createReducer, on } from '@ngrx/store';
import { Panel } from '@design/models/panel.model';
import { Obstacle } from '@design/models/obstacle.model';
import { fromObstacleActions } from './obstacle.action';
import { ObstacleState } from './obstacle.state';

export const adapter: EntityAdapter<Obstacle> = createEntityAdapter<Obstacle>({ });

export const ObstacleInitialState: ObstacleState = adapter.getInitialState();

export const { selectAll } = adapter.getSelectors();

export const obstacleReducer: ActionReducer<ObstacleState> = createReducer(
  ObstacleInitialState,
  on(fromObstacleActions.AddObstacle, (state, data) => {
    return adapter.addOne(data.obstacle, state);
  }),
  // on(fromObstacleActions.CopyObstacle, (state, data) => {
  //   return adapter.addOne(data.obstacle, state);
  // }),
  on(fromObstacleActions.SelectObstacle, (state, { obstacle }) => {
    return { ...state, selectedRoofSectionId: obstacle?.id };
  }), on(fromObstacleActions.AddManyObstacles, (state, data) => {
    return adapter.addMany(data.obstacles, state);
}),
  on(
    fromObstacleActions.ObstacleConfigurationChange,
    (state, { obstacle }) => {
      return adapter.updateOne(
        {
          id: obstacle.id,
          changes: {
            ...obstacle,
          },
        },
        state
      );
    }
  ),
  on(fromObstacleActions.UpdateObstacle, (state, { obstacle }) => {
    return adapter.updateOne(
      {
        id: obstacle.id,
        changes: {
          ...obstacle,
        },
      },
      state
    );
  }
  ),
  on(fromObstacleActions.UpdateObstacleSetbackVisibility, (state, { obstacle }) => {
    return adapter.updateOne(
      {
        id: obstacle.id,
        changes: {
          ...obstacle,
        },
      },
      state
    );
  }
  ),
  on(
    fromObstacleActions.ObstacleBoundChange,
    (state, { obstacle: { id, vertexIds, edgeIds } }) => {
      return adapter.updateOne(
        {
          id,
          changes: { vertexIds, edgeIds },
        },
        state
      );
    }
  ),
  on(fromObstacleActions.DeleteObstacle, (state, { obstacle }) => {
    return adapter.removeOne(obstacle.id, state);
  }),
  on(fromObstacleActions.DeleteObstacleFromStore, (state, { obstacle }) => {
    return adapter.removeOne(obstacle.id, state);
  })
);
