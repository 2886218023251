import { random } from 'lodash';

import { Store } from '@ngrx/store';
import { DesignState } from '@design/store/design.reducer';
import { Edge } from '@design/models/edge.model';
import { Vertex } from '@design/models/vertex.model';
import { ConfigurationIds } from '@design/models/ui-state.model';
import { RoofSection, flatRoofDefaults } from '@design/models/roof-section.model';
import { Obstacle, ObstacleType } from '@design/models/obstacle.model';
import { PRESET_COLORS_DEFAULT } from '@lib-ui/lib/common/types/enactColorpick';
import { fromRoofSectionActions, fromRoofSectionSelectors } from '@design/store/roof-section';
import { fromEdgeActions, fromEdgeSelectors } from '@design/store/edge';
import { fromVertexActions, fromVertexSelectors } from '@design/store/vertex';
import { fromObstacleActions, fromObstacleSelectors } from '@design/store/obstacle';
import { fromUiActions } from '@design/store/ui';
import { fromTreesActions } from '@design/store/trees';
import { take } from 'rxjs/operators';
import { Vector3, MathUtils, BufferGeometry, Mesh, Line, Object3D, InstancedMesh } from 'three';

import Util, { MeshUserData, meshTypeEnum, ObstacleData, panelDims, PanelStoreData, notificationMessages, EdgeInterface, constants, storeIdPrefix } from './Utility';
import { RoofTopology } from './RoofTopology';
import { RoofGeometry } from './roofGeometry';
import { fromPanelActions } from '@design/store/panel';
import { Panel, PanelOrientation } from '@design/models/panel.model';
import { fromPanelArrayActions } from '@design/store/panel-array';
import { PanelArray } from '@design/models/panel-array.model';
import { generateUniqueId } from '@design/utils/generateUnique';
import { openNotificationService } from '@lib-ui/lib/atomic/enact-alert-snack-bar/openNotification.service';
import { fromMetaDataSelectors } from '@design/store/meta-data';
import { fromPanelArraySelectors } from '@design/store/panel-array/panel-array.selector';
import { EngineService } from '../threejs.service';
import { fromPanelSelectors } from '@design/store/panel/panel.selector';
import { fromFrameActions } from '@design/store/frame';
import { Frame } from '@design/models/frame.model';
import { TranslateService } from '@ngx-translate/core';
import { fromUndoRedoActions } from '@design/store/undo-redo/undo-redo.action';

export class StoreUtil {
    store: Store<DesignState>;
    constructor(store: Store<DesignState>, private notificationService: openNotificationService,
        private engServ: EngineService,
        private translate : TranslateService
        ) {
        this.store = store;
    }
    storeRoofSection(roofId: string, roofGeometry: RoofGeometry, allStoreVerts: Vertex[], allSotreEdges: Edge[], overlappingRoofs: string[], gutterEdge: EdgeInterface, ridgeEdge: EdgeInterface, pitchDeg: number) {
        // vertexList.push(vertexList[0]); // copy the first vertex at the end to close loop
        // this code is referenced from the roofSectionCreated() function in draw-area.component.ts
        // roofId = roofId;
        // this.rt.name = roofId;

        let allStoreRoofs: RoofSection[] = [];
        this.store
            .select(fromRoofSectionSelectors.allRoofSections)
            .pipe(take(1))
            .subscribe((v) => allStoreRoofs = v)

        const vertexList = roofGeometry.fittedVertexList;
        const lineList = roofGeometry.fittedLineList;
        const confirmedPlane = roofGeometry.fitPlane?.confirmedPlane;
        const roofMesh = roofGeometry.fittedRoof;
        const roofCenter = Util.getCenterPoint(roofMesh!);

        const roofArea = Util.calculateArea(roofMesh);
        const height = Math.min(...vertexList.map(m => m.position).map(pt => pt.z))

        let azimuthDeg = Util.calculateAzimuth(confirmedPlane, gutterEdge.startVertex.position, gutterEdge.endVertex.position, roofCenter, pitchDeg);
        const defaultSetback = pitchDeg == flatRoofDefaults.pitch ? flatRoofDefaults.setbackFt : 1.5;

        const vertices: Vertex[] = []
        const storeVertsMap = new Map(allStoreVerts.map(v => [v.id, v]));
        vertexList.forEach(vert => {
            const userData = vert.userData as MeshUserData

            if (!userData.enactStoreId) {
                return
            }

            if (userData.addedToStore) {
                const prevVert = storeVertsMap.get(userData.enactStoreId);
                if (prevVert) {
                    prevVert.association.push(roofId);                
                    vertices.push({ ...prevVert });
                }

            }
            else {

                const pos = vert.position;
                const id = userData.enactStoreId;

                const vertex: Vertex = {
                    id: id,
                    x: pos.x,
                    y: pos.y,
                    z: pos.z,
                    association: [roofId],
                }

                const topoVertexInd = RoofTopology.getVertexId(vert);
                if (topoVertexInd) {
                    RoofTopology.setVertexPos(topoVertexInd, vert, true)
                }
                userData.addedToStore = true;
                vertices.push(vertex);
            }
        })

        const edges: Edge[] = []
        const edgeMap = new Map(allSotreEdges.map(edge => [edge.id, edge]));
        lineList.forEach((line, i) => {
            const userData = line.edge.userData as MeshUserData

            if (!userData.enactStoreId) {
                return
            }

            const id = userData.enactStoreId;

            if (userData.addedToStore && userData.enactStoreId) {
                // if it already added to store then add the current roof to Edge association array

                const prevEdge = edgeMap.get(id);
                if (prevEdge) {
                    prevEdge.association.push(roofId);                
                    edges.push({ ...prevEdge });
                }

            }
            else {
                let translatedName = '';
                this.translate.get('Edge').pipe(take(1)).subscribe(res => {
                    translatedName = res;
                })
                const edge: Edge = {
                    id: id,
                    name: `${translatedName} ${i + 1}`,
                    color: PRESET_COLORS_DEFAULT[0].value,
                    configurationId: ConfigurationIds.Edge,
                    startVertexId: line.startVertex.name,
                    endVertexId: line.endVertex.name,
                    setback: defaultSetback,
                    setbackVisible: false,
                    association: [roofId],
                };

                userData.addedToStore = true;
                edges.push(edge)
            }

        });

        const storeGutter = edges.filter(e => e.id == gutterEdge?.edge.name)[0];
        storeGutter.setback = pitchDeg == flatRoofDefaults.pitch ? flatRoofDefaults.setbackFt : 0; // set gutter setback to 0 for tilted roofs
        const storeRidge = edges.filter(e => e.id == ridgeEdge?.edge.name)[0];

        let translatedName = '';
        this.translate.get('Roof section').pipe(take(1)).subscribe(res => {
            translatedName = res;
        })
        if(allStoreRoofs.length > 0){
            let nameSplit = allStoreRoofs[allStoreRoofs.length -1].name .split(" ");
            var lastRoofNameNumber = parseInt(nameSplit[nameSplit.length -1].trim());
        }else{
            lastRoofNameNumber = allStoreRoofs.length;
        }
        const newRoofSection = new RoofSection({
            area: Util.getAreaPixelToFeet(roofArea),
            setback: defaultSetback,  // Util.getMeasurementPixelToFeet(5), // default 0 - 15 is for testing panel setback
            setbackVisible: true,
            azimuth: azimuthDeg, // angle between z and roof normal
            pitch: pitchDeg, // angle with ground
            parapit: pitchDeg == 0 ? random(1, 100) : 0, // parapit is disabled if pitch is enabled
            height: Util.getMeasurementPixelToFeet(height), // bottom roof part elevation
            color: PRESET_COLORS_DEFAULT[0].value,
            overlappingRoofs: overlappingRoofs,
            edgeIds: edges.map((e) => e.id),
            vertexIds: vertices.map((v) => v.id),
            id: roofId,
            defaultHeight: Util.getMeasurementPixelToFeet(height),
            name: `${translatedName} ` + (lastRoofNameNumber + 1).toString(),
            gutterId: storeGutter.id,
            ridgeId: storeRidge.id,
            alignPanels: storeGutter.id,
        });


        this.store.dispatch(fromEdgeActions.AddMany({ edges: edges }));

        this.store.dispatch(fromVertexActions.AddMany({ vertices: vertices }));

        //update overlapping roofs
        // overlappingRoofs.forEach(oRoofId => {
        //     this.store.select(fromRoofSectionSelectors.selectRoofById([oRoofId])).pipe(take(1)).subscribe(rs => {
        //         if (rs[0]!) {
        //             let rscopy = JSON.parse(JSON.stringify(rs[0]));
        //             let temproofs: string[] = []
        //             if (rscopy.overlappingRoofs) {
        //                 temproofs = [...rscopy.overlappingRoofs] as string[];
        //             }
        //             temproofs.push(roofId);
        //             rscopy.overlappingRoofs = temproofs
        //             this.store.dispatch(fromRoofSectionActions.RoofSectionConfigurationChange({ roof: rscopy }));

        //         }
        //     })
        // })

        // clear tool selction..
        this.store.dispatch(fromUiActions.ClearSelection());

        this.engServ.updateRoofSelectedState(newRoofSection.id);
        // select the created roof..

        this.notificationService.sendMessage({
            message: newRoofSection.name + this.translateMsg(notificationMessages.CREATESUCCESS),
            type: "success",
            positionClass: "toast-bottom-left",
            timeOut: 5000
        })

        return newRoofSection;
    }

    storeDrawPanelShape(vertexList: Mesh[], lineList: Line[], roofId: string) {
        // TODO: store the draw shape as RoofSection and use that roofId
        const boundaryVertices: Vertex[] = [];
        vertexList.forEach(vert => {
            const userData = vert.userData as MeshUserData

            if (!userData.enactStoreId) {
                return
            }
            const pos = vert.position;
            const id = userData.enactStoreId;

            const vertex: Vertex = {
                id: id,
                x: pos.x,
                y: pos.y,
                z: pos.z,
                association: [roofId],
            }

            const topoVertexInd = RoofTopology.getVertexId(vert);
            if (topoVertexInd) {
                RoofTopology.setVertexPos(topoVertexInd, vert, true)
            }
            (vert.userData as MeshUserData).addedToStore = true;
            boundaryVertices.push(vertex);
        })

        const boundaryEdges: Edge[] = []
        lineList.forEach((line, i) => {
            const userData = line.userData as MeshUserData

            if (!userData.enactStoreId) {
                return
            }
            let translatedName = '';
            this.translate.get('Edge').pipe(take(1)).subscribe(res => {
                translatedName = res;
            })
            const id = userData.enactStoreId;
            const edge: Edge = {
                id: id,
                configurationId: ConfigurationIds.Edge,
                startVertexId: boundaryVertices[i].id,
                endVertexId: boundaryVertices[i + 1]?.id ?? boundaryVertices[0].id,
                setback: 0,
                setbackVisible: false,
                color: PRESET_COLORS_DEFAULT[0].value,
                name: `${translatedName} ${i}`,
                association: [roofId],
            };

            userData.addedToStore = true;
            boundaryEdges.push(edge)

        });

        this.store.dispatch(fromVertexActions.AddMany({ vertices: boundaryVertices }));
        this.store.dispatch(fromEdgeActions.AddMany({ edges: boundaryEdges }));


        return [boundaryVertices, boundaryEdges]
    }

    updateMultiplePanelModule(panelArrayObject: Array<any>, _triggerURSnapshot?: boolean){
        let panelsToBeAdded: any = [];
        let framesToBeAdded: any = [];
        let panelArraysToBeUpdated: any = [];
        panelArrayObject.forEach(pArr=>{
                let isEastWest = pArr.panelArray.arrayType == '2';//TODO: change to east west

                const {noOfPanelEast, noOfPanelWest} = pArr.eastWestCount;
                const panels: Panel[] = pArr.panelsUserdataArray;
                const numberofPanel = panels.length;
                const totalWatts = numberofPanel & pArr.panelArray.wattsPerModule;
                const interrowSpacing = pArr.panelArray.interrowspacing ?? (pArr.panelArray.orientation == PanelOrientation.landscape ? pArr.panelArray.panelWidth : pArr.panelArray.panelLength);
                const defaultInterrowspacing = pArr.panelArray.defaultInterrowspacing ?? (pArr.panelArray.orientation == PanelOrientation.landscape ? pArr.panelArray.panelWidth : pArr.panelArray.panelLength);
                let translatedName = '';
                this.translate.get('Panel Array').pipe(take(1)).subscribe(res => {
                    translatedName = res;
                })
                let noOfPanels = panels.filter(p=>p.visible).length;
                const panelArray: PanelArray = new PanelArray({
                    ...pArr.panelArray,
                    roofTilt: pArr.panelStoreData.roofPitch,
                    interrowspacing: Math.round(interrowSpacing * 100) / 100,
                    defaultInterrowspacing: Math.round(defaultInterrowspacing * 100) / 100,
                    panelIds: panels.map((p) => p.id),
                    edges: pArr.shapeEdgeIds,
                    vertices: pArr.shapeVertexIds,
                    //name: pArr.existingPanelArray ? pArr.existingPanelArray.name : `${translatedName} - ` + allPanels.length + 1,
                    totalAddedPanels: panels.length,
                    numberOfPanels: noOfPanels,
                    frameIds: pArr.frames.map((frame: any)=> frame.id),
                    noOfPanelEast: isEastWest ? noOfPanelEast : noOfPanels,
                    noOfPanelWest: noOfPanelWest,
                    totalWatts: totalWatts,
                });
                framesToBeAdded.push(...pArr.frames);
                panelsToBeAdded.push(...panels);
                panelArraysToBeUpdated.push(panelArray);
        })
        if(panelsToBeAdded && panelsToBeAdded.length > 0){
            this.store.dispatch(fromPanelActions.AddMany({ panels: panelsToBeAdded }));
        }
        if(framesToBeAdded && framesToBeAdded.length > 0){
            this.store.dispatch(fromFrameActions.AddMany({ frames: framesToBeAdded }));
        }
        if(panelArraysToBeUpdated && panelArraysToBeUpdated.length > 0){
            this.store.dispatch(fromPanelArrayActions.UpsertManyModule({ panelArray: panelArraysToBeUpdated }));
        }
    }

    storePanels(shapeVertexIds: string[], shapeEdgeIds: string[], panelsUserdataArray: Array<Panel>, panelStoreData: PanelStoreData, frames: Array<any>, eastWestCount: any, existingPanelArray?: PanelArray, triggerURSnapshot?: boolean) {

        // const panelMeshes = panelArrayGroup.children as Mesh[];
        const metadata = panelStoreData.storeMetadata;

        metadata.panelLength = Number(metadata.panelLength);
        metadata.panelWidth = Number(metadata.panelWidth);
        metadata.interrowspacing = Number(metadata.interrowspacing ?? 0);
        metadata.defaultInterrowspacing = Number(metadata.defaultInterrowspacing ?? 0);
        let hasPanelAdded = false
        this.store.select(fromPanelArraySelectors.allPanelArrays)
            .pipe(take(1))
            .subscribe(allPanels => {
                let isEastWest = metadata.arrayType == '2';//TODO: change to east west
                let degradation = 0.5;
                const {noOfPanelEast, noOfPanelWest} = eastWestCount;
                const panels: Panel[] = panelsUserdataArray;
                const numberofPanel = panels.length;
                metadata.totalWatts = numberofPanel & metadata.wattsPerModule;
                const totalWatt = numberofPanel * 300;
                const interrowSpacing = metadata.interrowspacing ?? (metadata.orientation == PanelOrientation.landscape ? metadata.panelWidth : metadata.panelLength);
                const defaultInterrowspacing = metadata.defaultInterrowspacing ?? (metadata.orientation == PanelOrientation.landscape ? metadata.panelWidth : metadata.panelLength);
                let translatedName = ''
                this.translate.get('Panel Array').pipe(take(1)).subscribe(res => {
                    translatedName = res;
                });
                if(allPanels.length > 0){
                    let nameSplit = allPanels[allPanels.length -1].name .split(" ");
                    var lastPanelNameNumbering = parseInt(nameSplit[nameSplit.length -1].trim());
                    degradation = allPanels[0].degradation;
                }else{
                     lastPanelNameNumbering = allPanels.length;
                }
                let noOfPanels = panels.filter(p=>p.visible).length;
                const panelArray: PanelArray = new PanelArray({
                    ...metadata,
                    roofTilt: panelStoreData.roofPitch,
                    interrowspacing: Math.round(interrowSpacing * 100) / 100,
                    defaultInterrowspacing: Math.round(defaultInterrowspacing * 100) / 100,
                    panelIds: panels.map((p) => p.id),
                    edges: shapeEdgeIds,
                    vertices: shapeVertexIds,
                    name: existingPanelArray ? existingPanelArray.name : `${translatedName} - ` + (lastPanelNameNumbering + 1).toString(),
                    totalAddedPanels: panels.length,
                    numberOfPanels: noOfPanels,
                    frameIds: frames.map(frame=> frame.id),
                    noOfPanelEast: isEastWest ? noOfPanelEast : noOfPanels,
                    noOfPanelWest: noOfPanelWest,
                    degradation:degradation
                });
                if(!noOfPanels){
                    this.notificationService.sendMessage({
                        message: this.translateMsg('panelArrDel'),
                        type: 'danger',
                        positionClass: "toast-bottom-left",
                        timeOut: 5000
                      });
                    return
                }

                //frames
                this.store.dispatch(fromPanelActions.AddMany({ panels }));
                this.store.dispatch(fromFrameActions.AddMany({frames: frames}))
                if (existingPanelArray) {
                    this.store.dispatch(fromPanelArrayActions.UpsertOne({ panelArray}));
                    if(triggerURSnapshot == undefined || triggerURSnapshot)
                      this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromPanelArrayActions.AddPanelArray, notification: panelArray.name+ ' '+ this.translateMsg('design.undoRedo.update', false)}))
                } else {
                    this.store.dispatch(fromPanelArrayActions.AddPanelArray({ panelArray }));
                    this.store.dispatch(fromUiActions.ClearSelection());

                    this.store.dispatch(
                        fromUiActions.SelectObject({ systemDesignObject: panelArray })
                    );
                    if(triggerURSnapshot == undefined || triggerURSnapshot)
                      this.store.dispatch(fromUndoRedoActions.TriggerURSnapshot({action: fromPanelArrayActions.AddPanelArray, notification: panelArray.name+ ' '+ this.translateMsg('design.undoRedo.add',false)}))
                }
                hasPanelAdded = true
                return
            })
        return hasPanelAdded

    }

    storeSinglePanel(tempPanelObj: any, panelArrayId: string, maxPillarId: number) {
        let obj: { panel?: Panel, panelArray?: PanelArray } = {};
        const singlePanelSubscription = this.store
            .select(fromPanelArraySelectors.selectPanelArraysByIds([panelArrayId]))
            .pipe(take(1))
            .subscribe((panelArray: (PanelArray | undefined)[]) => {
                if (!(panelArray && panelArray.length && panelArray[0] != undefined)) {
                  console.error(
                    `StoreUtil.storeSinglePanel: Panel Array ID: ${panelArrayId} not found in store. Cannot add Single Panel to Store.`
                  );
                  return;
                } else {
                    // Add new frame for single panel
                    const frame: Frame = {
                        id: generateUniqueId(storeIdPrefix.frame),
                        panelIds: [tempPanelObj.name],
                        panelArrayId: panelArray[0].id,
                        isDefaultStructure: true,
                        pillarIds: [maxPillarId+1, maxPillarId+2, maxPillarId+3, maxPillarId+4]
                    }
                    this.store.dispatch(fromFrameActions.AddOne({ frame }));

                    // !NOTE: panelArray is readonly here hence prop update won't work

                    const panel = new Panel({
                        id: tempPanelObj.name,
                        visible: true,
                        instanceId: tempPanelObj.instanceId,
                        orientation: tempPanelObj.orientation ?? panelArray[0].orientation,
                        association: panelArray[0].roofId,
                        selectConfigId: true,
                        frameId: frame.id,
                        azimuth: panelArray[0].azimuth
                    });
                    this.store.dispatch(fromPanelActions.AddOne({ panel }));
                    obj.panel = panel;

                    // Updating panel array for single panel
                    const updatedPanelArray: PanelArray = JSON.parse(JSON.stringify(panelArray[0]));
                    updatedPanelArray.panelIds.push(panel.id);
                    updatedPanelArray.frameIds.push(frame.id);
                    updatedPanelArray.numberOfPanels++;
                    updatedPanelArray.noOfPanelEast++;
                    updatedPanelArray.totalAddedPanels++;

                    obj.panelArray = updatedPanelArray;
                    this.store.dispatch(
                        fromPanelArrayActions.UpsertOne({
                            panelArray: updatedPanelArray
                        })
                    );
                }
            });
        return obj;
    }

    getPanels(panelIds: string[]) {
        let panels: Panel[] = [];
        this.store.select(fromPanelSelectors.selectPanelsByIds(panelIds)).pipe(take(1)).subscribe(ps => {
            panels = ps.flatMap(p => {
                if (p) return p;
                else return [];
            })
        })
        return panels;
    }

    storeObstacle(data: ObstacleData) {
        const roofId = data.roofId;

        let allObstacles: Obstacle[] = [];
        this.store
            .select(fromObstacleSelectors.allObstacles)
            .pipe(take(1))
            .subscribe((v) => {
                allObstacles = v;
                return allObstacles;
            })

        let ObsType = ''
        if (data.type == ObstacleType.walkways) {

            ObsType = 'Walkway';
        }
        else {

            ObsType = 'Obstacle';
        }

        this.translate.get(ObsType).pipe(take(1)).subscribe(res => {
            ObsType = res;
        })

        const allObstaclesLength = allObstacles.length;
        if(allObstaclesLength > 0){
            let nameSplit = allObstacles[allObstaclesLength -1].name .split(" ");
            var lastObstacleNameNumber = parseInt(nameSplit[nameSplit.length - 1].trim());
        }else{
            lastObstacleNameNumber = allObstaclesLength;
        }

        const obstName =  ObsType + " " + (lastObstacleNameNumber + 1);

        const newobstacle = new Obstacle({
            ...data,

            // configurationId: configId,
            name: obstName, // TODO update this to the actual name of obstacle
            roofId: roofId,
            rotation: Math.round(data.rotation),
            vertexIds: [],
            setback: data.setback ?? 0,
            setbackVisible: data.setbackVisible ?? false,
        })

        if (data.type = ObstacleType.circle) {
            newobstacle.diameter = data.diameter;
        }

        this.store.dispatch(fromObstacleActions.AddObstacle({ obstacle: newobstacle }));

        // select the created obstacle..
        this.store.dispatch(
            fromUiActions.SelectObject({ systemDesignObject: newobstacle })
        );

        this.notificationService.sendMessage({
            message: newobstacle.name + this.translateMsg(notificationMessages.CREATESUCCESS),
            type: "success",
            positionClass: "toast-bottom-left",
            timeOut: 5000
        })

        return newobstacle;

    }

    updateStoreObstacle(data: ObstacleData, fromRHS: boolean = false) {
        this.store
            .select(fromObstacleSelectors.selectObstacleById(data.id))
            .pipe(take(1))
            .subscribe((o) => {
                if (o) {
                    const storeObst: Obstacle = JSON.parse(JSON.stringify(o));
                    const updateObstacle: Obstacle = {
                        ...storeObst,
                        length: data.length,
                        width: data.width,
                        height: data.height,
                        rotation: data.rotation,
                        roofAssociation: data.roofAssociation,
                        setback: data.setback!,
                        defaultHeight: data.defaultHeight,
                        roofId: data.roofId
                    }
                    if(!fromRHS){
                        const updateObstacleClone = JSON.parse(JSON.stringify(updateObstacle))
                        this.store.dispatch(fromObstacleActions.UpdateObstacle({ obstacle: updateObstacleClone, triggerURSnapshot: false }))
                    };

                    this.notificationService.sendMessage({
                        message: updateObstacle.name + this.translateMsg(notificationMessages.UPDATESUCCESS),
                        type: "success",
                        positionClass: "toast-bottom-left",
                        timeOut: 5000
                    })
                }
            })
    }

    CheckEdgeInStore(start: Vector3, end: Vector3): Edge | undefined {
        // const pos = edge.geometry.attributes.position
        // const start = new Vector3(pos[0], pos[1], pos[1])
        // const end = new Vector3(pos[2], pos[3], pos[4])

        let startId: string | undefined;
        let endId: string | undefined;
        let prevEdge: Edge | undefined;

        this.store
            .select(fromVertexSelectors.selectVertexByPositon(start))
            .pipe(take(1))
            .subscribe((v) => startId = v?.id)

        this.store
            .select(fromVertexSelectors.selectVertexByPositon(end))
            .pipe(take(1))
            .subscribe((v) => endId = v?.id)

        if (startId && endId) {
            this.store
                .select(fromEdgeSelectors.selectEdgesByStartEndVertex(startId, endId))
                .pipe(take(1))
                .subscribe((v) => prevEdge = v)
        }

        return prevEdge;
    }

    GetRoofGutterVertices(storeRoof: RoofSection) {
        let gutterEdge: Edge | undefined;
        let edgeId = storeRoof.alignPanels? storeRoof.alignPanels : storeRoof.gutterId;
        this.store
            .select(fromEdgeSelectors.selectEdgesByIds([edgeId]))
            .pipe(take(1))
            .subscribe((v) => gutterEdge = v[0])

        let startVertex: Vertex | undefined
        let endVertex: Vertex | undefined

        if (gutterEdge) {

            this.store
                .select(fromVertexSelectors.selectVerticesByIds([gutterEdge.startVertexId]))
                .pipe(take(1))
                .subscribe((v) => startVertex = v[0]);


            this.store
                .select(fromVertexSelectors.selectVerticesByIds([gutterEdge.endVertexId]))
                .pipe(take(1))
                .subscribe((v) => endVertex = v[0]);
        }

        let gutterStart: Vector3 | undefined;
        let gutterEnd: Vector3 | undefined;
        if (startVertex && endVertex) {
            let a = new Vector3(
                startVertex.x,
                startVertex.y,
                startVertex.z
            )
            let b = new Vector3(
                endVertex.x,
                endVertex.y,
                endVertex.z
            )
            let roofMesh = this.engServ.scene.getObjectByName(storeRoof.id) as Mesh;
            let roofCenter = Util.getCenter(roofMesh);
            let points = Util.getGutterLeftRightPts(a, b, roofCenter);
            gutterStart = points.gutterLeftPt;
            gutterEnd = points.gutterRightPt;

        }
        return [gutterStart, gutterEnd];
    }

    translateMsg(val: string, isToasterMsg = true) {
        let translatedName = '';
        let toasterPrefix = '';
        if(isToasterMsg){
          toasterPrefix = 'toastrMsgs.';
        }

        this.translate.get( toasterPrefix + val).pipe(take(1)).subscribe(res => {
          translatedName = res;
        }).unsubscribe();
        return translatedName;
    }

    GetRoofRidgeVertices(storeRoof: RoofSection) {
        let ridgeEdge: Edge | undefined;
        let edgeId = storeRoof.alignPanels? storeRoof.alignPanels : storeRoof.ridgeId;
        this.store
            .select(fromEdgeSelectors.selectEdgesByIds([edgeId]))
            .pipe(take(1))
            .subscribe((v) => ridgeEdge = v[0])

        let startVertex: Vertex | undefined
        let endVertex: Vertex | undefined

        if (ridgeEdge) {

            this.store
                .select(fromVertexSelectors.selectVerticesByIds([ridgeEdge.startVertexId]))
                .pipe(take(1))
                .subscribe((v) => startVertex = v[0]);


            this.store
                .select(fromVertexSelectors.selectVerticesByIds([ridgeEdge.endVertexId]))
                .pipe(take(1))
                .subscribe((v) => endVertex = v[0]);
        }

        let ridgeStart: Vector3 | undefined;
        let ridgeEnd: Vector3 | undefined;
        if (startVertex && endVertex) {
            let a = new Vector3(
                startVertex.x,
                startVertex.y,
                startVertex.z
            )
            let b = new Vector3(
                endVertex.x,
                endVertex.y,
                endVertex.z
            )
            let roof = this.engServ.scene.getObjectByName(storeRoof.id);
            let confirmedPlane = (roof?.userData as MeshUserData).roofConfirmedPlane;
            let roofCenter = Util.getCenter(roof?.getObjectByName(meshTypeEnum.roofPlane) as Mesh);
            let points = Util.getGutterLeftRightPts(a, b, roofCenter);
            ridgeStart = points.gutterLeftPt;
            ridgeEnd = points.gutterRightPt;

        }
        return [ridgeStart, ridgeEnd];
    }
}
