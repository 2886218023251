import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTable, MatTableDataSource, _MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { take } from "rxjs/operators";
import { ConfirmDialogService } from "src/app/helpers/confirm-dialog/confirm-dialog.service";
import { NovaDialogConfig } from "src/app/helpers/interfaces/nova-dialog-config.interface";
import { CostingProfile, ARCHIVE_PROFILE_HEADER } from "src/app/models/archive-costing-profile.model";

@Component({
  selector: "app-archive-view",
  templateUrl: "./archive-view.component.html",
  styleUrls: ["./archive-view.component.scss"]
})
export class ArchiveViewComponent implements AfterViewInit {
  @Input() columns = ARCHIVE_PROFILE_HEADER;
  @Input() archiveList!: CostingProfile[];
  @Output() closeArchiveView = new EventEmitter<boolean>();
  @Output() onActionChange = new EventEmitter<CostingProfile>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) matTable!: MatTable<any>;
  dataSource: MatTableDataSource<any> | any;
  displayedColumns: string[] = [];
  searchOnFocus = false;
  searchText = '';
  constructor( private confirmationService: ConfirmDialogService, private translate : TranslateService) {}
  onArchiveSearchChange(serachText: string) {
    this.dataSource.filter = serachText.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  onActionClick(event: any) {
    const dialogConfig: NovaDialogConfig = {
      title: this.translateMsg('restoreCostPf'),
      message: this.translateMsg('sureRestoreCstngPfl'),
      actionButtons: ['Yes'],
      closeButtonLabel: 'No',
      isDeleteConfirmationDialog: false,
      iconText: 'restore',
      isRestore: true
    }
    const dialogRef1 = this.confirmationService.novaDialog(dialogConfig);
    dialogRef1.afterClosed().subscribe(result => {
      if(result === 'Yes') this.onActionChange.emit(event)
    });
  }

  ngAfterViewInit() {
    this.init();
  }
  init() {
    this.displayedColumns = this.columns.map(c => c.id);
    this.dataSource = new _MatTableDataSource(this.archiveList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.sort?.sort({ disableClear: false, id: 'archived_on', start: 'desc' })
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get('toastrMsgs.' + val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

}