import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';
import { BarColors } from 'src/app/models/dynamic-date-chart.model';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { Utils } from 'src/app/helpers/utils';
import { AuthService } from 'src/app/helpers/auth.service';
export const DEFAULT_CONFIG = { top: 10, right: 30, bottom: 80, left: 80 }

interface StackedChart {
  tier1: any;
  tier2: any;
}

@Component({
  selector: 'app-stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class StackedChartComponent implements OnInit,AfterContentChecked {
  @Input() data: StackedChart[] | any;
  @Input() dataChanged: any;
  @Input() yLabel: any;
  @Input() snColors = 'false';
  @Input() negativeAxis = false;
  @Input() header: any;
  @Input() text: any;
  @Input() projectDetails: any;
  @Input() meterNumber: any;
  @Input() selectedGraph: any;
  @Input() parentElement: any;
  @Input() config: any = DEFAULT_CONFIG;
  @ViewChild('chartContainer') myChartContainer: ElementRef | any;
  stackKeys: any = [];
  @ViewChild('stackChartReadoutelementdata', { static: true }) stackChartReadoutelementdata: ElementRef | any;

  w: number = 800;
  h: number = 425;
  margin = this.config;
  width = this.w - this.margin.left - this.margin.right;
  height = this.h - this.margin.top - this.margin.bottom;
  private divWidth = 0;
  private divHeight = 0;

  private y: any;
  private z: any;
  private x0: any;
  private x1: any;
  private svg: any;
  private stack: any;
  private chart: any;
  private layersBarArea: any;
  private layersBar: any;
  private xAxis: any;
  private yAxis: any;
  private stackedSeries: any;
  numberTicksY: any = 9;
   colors: any = [];
  xAxisLabels: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  formattedData: any = [];
  preSolarData: any = [];
  postSolarData: any = [];
  grouped = false;
  lagendXtranslate = 0;
  @Input() events: any;
  subscriptions = new Subscription();
  readoutPosX=0;
  showReadout =false;
  stackDataRendering: any[]=[];
  locale = 'en-US';

  constructor(
    private container: ElementRef,
    private renderer: Renderer2,
    private translate : TranslateService,
    private ref: ChangeDetectorRef,
    private utils: Utils,
    private authService: AuthService
  ) { 
    this.locale = this.authService.getItem('localeId');
  }

  ngOnInit() {
    if (this.projectDetails == 'false') {
      this.doSomething();
    }
  }

  doSomething() {
    const that = this;
    this.initData().then((data: any) => {
      this.draw(data);
    });
  }

  draw(data: any) {
    let newData:any[] = [];
    let newObj :any;
    for (const [key, value] of Object.entries(BarColors)) {
      newObj = data.filter((a: any) => a.name == key);
      if(newObj.length){
        newData.push(...newData, ...newObj)

      }
    }
    newData.reverse();

    if (this.projectDetails == 'false') {
      this.w = screen.width - (screen.width * 10) / 100;
      this.h = 400;
      this.margin = this.config;
      this.width = this.w - this.margin.left - this.margin.right;
      this.height = this.h - this.margin.top - this.margin.bottom;
    } else {
      var offsetWidth: any =
        document.getElementById('project-charts')?.offsetWidth;
      this.w = offsetWidth - (offsetWidth * 10) / 100;
      this.h = 400;
      this.margin = this.config;
      this.width = this.w - this.margin.left - this.margin.right;
      this.height = this.h - this.margin.top - this.margin.bottom;
    }
    this.width = (!this.width || isNaN(this.width)) ? 0 : this.width;
    this.height = (!this.height || isNaN(this.height)) ? 0 : this.height;
    let obj = {};
    this.stackKeys = [];
    this.colors = [];
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].visibleInLegend) {
        if (this.stackKeys.includes(newData[i].name)) {
        } else {
          this.stackKeys.push(newData[i].name);
          this.colors.push(this.snColors == 'true' ? BarColors[newData[i].name.trim()] : newData[i].color );
        }
      }
    }

    this.preSolarData = [];
    this.postSolarData = [];
    for (let k = 0; k < this.xAxisLabels.length; k++) {
      this.preSolarData.push({ month: this.translateMsg('project.prjTab.months.' + this.xAxisLabels[k]) });
      this.postSolarData.push({ month: this.translateMsg('project.prjTab.months.' + this.xAxisLabels[k]) });
    }

    if (this.stackKeys.length > 0 && this.preSolarData.length > 0) {
      for (let k = 0; k < this.preSolarData.length; k++) {
        for (let m = 0; m < this.stackKeys.length; m++) {
          newData.forEach((element: any) => {
            if (element.stack == 'Pre-Solar') {
              if (element.name == this.stackKeys[m]) {
                this.preSolarData[k][this.stackKeys[m]] = element.data[k];
                this.preSolarData[k].stack = element.stack;
              }
            } else if (element.stack == 'Post-Solar') {
              this.grouped = true;
              if (element.name == this.stackKeys[m]) {
                this.postSolarData[k][this.stackKeys[m]] = element.data[k];
                this.postSolarData[k].stack = element.stack;
              }
            }
          });
        }
      }

      if (this.selectedGraph == 2 || this.selectedGraph == 3) {
        this.initalizeChart();
      } else if (this.projectDetails == 'true') {
        this.initalizeChart();
      } else if (!this.projectDetails && !document.getElementById('analysis-graph')?.getElementsByTagName('svg').length) {
        this.initalizeChart();
      }
    }
  }
  ngOnDestroy() { }

  initData(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (this.data) {
        resolve(this.data);
      } else {
        let timer: any = null;

        timer = setInterval(() => {
          if (this.data) {
            if (timer) {
              clearInterval(timer);
              resolve(this.data);
            }
          }
        }, 300);
      }
    });
  }

  initalizeChart() {
    this.renderer.setProperty(this.myChartContainer.nativeElement, 'innerHTML', '');
    this.formattedData = this.preSolarData;
    if (this.grouped) {
      this.postSolarData.forEach((element: any) => {
        this.formattedData.push(element);
      });
    }

    if (this.formattedData.length > 0) {
      this.stack = d3.stack().keys(this.stackKeys).offset(d3.stackOffsetDiverging)
      this.initScales();
      this.initSvg();
      this.createStack(this.formattedData);
    }
  }

  private initScales() {
    this.x0 = d3.scaleBand().rangeRound([0, this.width]).padding(0.1);
    this.x1 = d3.scaleBand().padding(0.05);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.z = d3.scaleOrdinal().range(this.colors);
  }

  ngAfterViewChecked() {
    if (this.myChartContainer.nativeElement.offsetHeight != this.divHeight || this.divWidth != this.myChartContainer.nativeElement.offsetWidth) {
      this.divWidth = this.myChartContainer.nativeElement.offsetWidth;
      this.divHeight = this.myChartContainer.nativeElement.offsetHeight;
      let tempHeight = this.divHeight;
      if (this.divHeight < 400) {
        tempHeight = 400;
      }
      const parentHeight = this.parentElement?.offsetHeight;
      if (parentHeight * .92 < tempHeight) {
        tempHeight = parentHeight * .92;
      }


      this.w = this.divWidth;
      if (this.w <= 0) {
        const parentWidth = this.parentElement?.offsetWidth;
        this.w = parentWidth;
      }
      this.h = tempHeight;
      this.width = this.w - this.margin.left - this.margin.right;
      this.height = this.h - this.margin.top - this.margin.bottom;
      this.width = (!this.width || isNaN(this.width)) ? 0 : this.width;
      this.height = (!this.height || isNaN(this.height)) ? 0 : this.height;

      this.initalizeChart();
    }
  }

  private initSvg() {
    this.lagendXtranslate = this.width / 2 + this.margin.left + this.margin.right
    if (this.selectedGraph == 2) {
      this.svg = d3
        .select(this.container.nativeElement)
        .select('.chart-container-stacked' + this.selectedGraph)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('class', 'tiered-chart')
        .attr('width', this.w)
        .attr('height', this.h);
      this.lagendXtranslate = this.width / 2 + this.margin.left
    } else if (this.selectedGraph == 3) {
      this.svg = d3
        .select(this.container.nativeElement)
        .select('.chart-container-stacked' + this.selectedGraph)
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('class', 'billOffset-chart')
        .attr('width', this.w)
        .attr('height', this.h);
      this.lagendXtranslate = this.width / 2 + this.margin.left
    } else {
      this.svg = d3
        .select(this.container.nativeElement)
        .select('.chart-container-stacked')
        .append('svg')
        .attr('preserveAspectRatio', 'xMinYMin meet')
        .attr('class', 'chart')
        .attr('width', this.w)
        .attr('height', this.h);
    }

    this.chart = this.svg
      .append('g')
      .classed('chart-contents', true)
      .attr(
        'transform',
        'translate(' + this.margin.left + ',' + this.margin.top + ')'
      );
  }

  translateMsg(val: any) {
    let translatedName = ''
    this.translate.get(val).pipe(take(1)).subscribe(res => {
      translatedName = res;
    })
    return translatedName;
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
}

  private createStack(stackData: any) {
    this.stackedSeries = this.stack(stackData);
    this.drawChart(this.stackedSeries);
  }

  private drawChart(data: any) {
    let stackKeysTranslated = this.stackKeys.map((a: any) => a = this.translateMsg('project.prjTab.' + a))
    this.x0.domain(
      this.formattedData.map((d: any) => {
        return d.month;
      })
    );
    this.x1.domain(
      this.formattedData.map(function (d: any) {
        return d.stack;
      })
    ).rangeRound([0, this.x0.bandwidth()]).padding(0.2);
    let yMax: any = d3.max(this.stackedSeries, function (d: any) {
      return d3.max(d, (d: any) => {
        return d[1];
      });
    });
    let yMin: any = d3.min(this.stackedSeries, function (d: any) {
      return d3.min(d, (d: any) => {
        return d[0];
      });
    });
    const yRange = yMax + (100 - yMax % 100);
    const yStackMin = Math.abs(yMin) + (100 - Math.abs(yMin) % 100)
    this.y.domain([((this.snColors == 'true' && this.negativeAxis)  ? -yStackMin : 0), yRange]);
    var yAxisGrid = d3
      .axisLeft(this.y)
      .tickSize(-this.width)
      .ticks(9)
      .tickFormat((d: any) => {
        if (d <= 999) return d;
        else if (d > 999 && d < 1000000)
          return d / 1000 + 'K';
        else if (d >= 1000000) return d / 1000000 + 'M';
        else return d;
      })
      .tickSizeOuter(0);
    this.yAxis = this.chart
      .append('g')
      .classed('gridLine', true)
      .attr('class', 'grid-line')
      .attr('transform', 'translate(0,0)')
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .call(yAxisGrid);
    if (this.snColors == 'true') {
      this.yAxis.select(".domain")
        .attr("stroke-width", "0")
    }

    this.chart
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - 45)
      .attr('x', 0 - this.height / 2 - 5)
      .style('text-anchor', 'middle')
      .style('stroke', 'none')
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .classed('axis-title', true)
      .text(this.yLabel);
    let that = this;
    this.xAxis = this.chart
      .append('g')
      .classed('x-axis', true)
      .attr('transform', 'translate(0,' + this.height + ')')
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .call(d3.axisBottom(this.x0));
      this.xAxis.select(".domain")
      .attr("stroke-width","0")
    this.chart
      .append('text')
      .attr('y', this.height + 43)
      .attr('x', this.width / 2)
      .classed('axis-title', true)
      .attr('font-family', 'Roboto')
      .style('font-size', '14px')
      .style('stroke', 'none')
      .text(this.translateMsg('project.prjTab.months.Jan - Dec'));
      /* Add line into SVG */
    const xTransform = 60
    const yZero = this.y(0) + 10;
    this.svg.append('line')
      .attr('x1', xTransform)
      .attr('x2', this.width + xTransform)
      .attr('y1', yZero)
      .attr('y2', yZero)
      .style('stroke', '#000');
  
    this.layersBarArea = this.chart.append('g').classed('layers', true);
    this.layersBar = this.layersBarArea
      .selectAll('.layer')
      .data(data)
      .enter()
      .append('g')
      .classed('layer', true)
      .style('fill', (d: any, i: any) => {
        return this.colors[i];
      })
      .style('fill-opacity', 1)
      .style('stroke-opacity', 1);
    this.layersBar
      .selectAll('rect')
      .data((d: any) => {
        return d;
      })
      .enter()
      .append('rect')
      .attr('id', (d: any, i: any) => {
        let id = '';
        if (that.stackKeys.length > 1) {
          for (let j = 0; j < that.stackKeys.length; j++) {
            if (d[1] - d[0] == d.data[that.stackKeys[j]]) {
              id += d.data.month + '-' + that.stackKeys[j];
            }
          }
        } else if (that.stackKeys.length == 1) {
          id += d.data.month + '-' + that.stackKeys[0];
        }
        return id;
      })
      .attr('rx', 15)
      .attr('ry', 0)
      .attr('transform', function (d: any) {
        return 'translate(' + that.x0(d.data.month) + ',0)';
      })
      .attr('x', (d: any, i: any) => {
        return this.x1(d.data.stack);
      })
      .attr('width', this.x1.bandwidth())
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      })
      .style('margin', '10px')
      .attr('y', (d: any, i: any) => {
        return this.y(d[1]);
      })
      .transition() // <---- Here is the transition
      .duration(2000) // 2 seconds
      .attr('y', (d: any) => {
        return this.y(d[1]);
      })
      .attr('height', (d: any, i: any) => {
        return this.y(d[0]) - this.y(d[1]);
      });

    this.layersBar.on('mousemove', function (event: any, d: any, i: any) {
      that.stackDataRendering = [];
      var coords = d3.pointer(event);
      var xPosition = coords[0];
      var yPosition = coords[1] - 20;
      const mouse = d3.pointer(event);
      if(that.snColors == 'true')
          that.showReadout = true;
      that.readoutPosX = mouse[0];
      let stack = event.srcElement.__data__.data.stack;
      var text;
      if (that.grouped) {
        if (that.yLabel == 'Charges ($)') {
          text = stack + ' - ' + that.translateMsg('project.prjTab.' + d.key) + '  :  $ ' + (that.utils.numberFormat(event.srcElement.__data__[1] - event.srcElement.__data__[0]));
          that.stackDataRendering = event.srcElement.__data__.data;
        } else {
          text = stack + ' - ' + that.translateMsg('project.prjTab.' + d.key) + '  :  ' + (that.utils.numberFormat(event.srcElement.__data__[1] - event.srcElement.__data__[0]));
          that.stackDataRendering = event.srcElement.__data__.data;
        }
      } else {
        if (that.yLabel == 'Charges ($)') {
          text = that.translateMsg('project.prjTab.' + d.key) + '  : ' + that.appendCurrSymbol(event.srcElement.__data__.data[d.key]);
          that.stackDataRendering = event.srcElement.__data__.data;

        } else {
          text = that.translateMsg('project.prjTab.' + d.key) + '  :  ' + (that.utils.numberFormat(event.srcElement.__data__[1] - event.srcElement.__data__[0]));
          that.stackDataRendering = event.srcElement.__data__.data;

        }
      }
      let val = isNaN(
        event.srcElement.__data__[1] - event.srcElement.__data__[0]
      );
      if (!val && that.snColors == 'false') {
        tooltip.attr('transform', 'translate(' + xPosition + ',' + yPosition + ')');
        tooltip.select('text').text(text);
      }
    });
    this.layersBar.on('mouseover', function (event: any) {
      that.showReadout = false;
      tooltip.style('display', 'inline-block');
      if (event.srcElement.id !== '') {
        d3.select('#' + event.srcElement.id)
          .style('fill-opacity', 0.5)
          .style('stroke-opacity', 0.5);
      }
    });
    this.layersBar.on('mouseout', function (event: any) {
      that.showReadout = false;
      tooltip.style('display', 'none');
      if (event.srcElement.id !== '') {
        d3.select('#' + event.srcElement.id)
          .style('fill-opacity', 1)
          .style('stroke-opacity', 1);
      }
    });
    // Prep the tooltip bits, initial display is hidden
    var tooltip = this.svg
      .append('g')
      .attr('class', 'text')
      .style('display', 'none');
    tooltip.append('rect').attr('fill', 'white').style('opacity', 0.5);
    tooltip
      .append('text')
      .attr('x', 15)
      .attr('dy', '1.2em')
      .style('text-anchor', 'revert')
      .attr('font-size', '14px')
      .attr('font-family', 'Roboto')
      .attr('font-weight', 'bold');
    //Legend related code
    const newcanvas = document.createElement('canvas');
    const ctx: any = newcanvas.getContext('2d');
    ctx.font = '14px Roboto';
    const spacing = stackKeysTranslated.map((item:any) => ctx.measureText(item).width + 30)
    const colorScale = d3
      .scaleOrdinal()
      .domain(stackKeysTranslated)
      .range(this.colors);
    this.svg
      .append('g')
      .attr(
        'transform',
        `translate(${(this.lagendXtranslate|| 0)},${(this.height || 0) + 65})`
        // `translate(${this.width + 10}, 100)`,
      )
      .call(this.colorLegend, {
        colorScale: colorScale,
        circleRadius: 14,
        spacing: spacing,
        textOffset: 22,
        lineOffset: 25,
      });

    $(document).ready(function () {
      $('.chart-container-stacked').on('touchstart', function (e) {
      }).trigger("touchstart");
    })
  }

  colorLegend(selection: any, props: any) {
    const {
      colorScale,
      circleRadius,
      spacing,
      textOffset,
      lineOffset,
    } = props;
    var totalSpace = 0;
    spacing.forEach((item: any) => totalSpace += item);
    const stX = -(totalSpace / 2);
    var stY = 0;
    stY = lineOffset / 2;
    const groups = selection.selectAll('g').data(colorScale.domain());
    const groupEnter = groups.enter().append('g').attr('class', 'tick');
    groupEnter.merge(groups).attr('transform', (d: any, i: any) => {
      let xpos = 0;
      for (let j = 0; j < i; j++) {
        xpos += spacing[j];
      }
      return `translate(${stX + xpos},0)`;
    });
    groups.exit().remove();
    groupEnter
      .append('rect')
      .merge(groups.select('rect'))
      .attr('width', circleRadius)
      .attr('height', circleRadius)
      .attr('id', (d: any) => d + 'l')
      .attr('fill', (d: any) => colorScale(d))
      .on('click', function (d: any) {
        const currentOpacity: any = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
      });

    groupEnter
      .append('text')
      .merge(groups.select('text'))
      .attr('id', (d: any) => d + 'lt')
      .text((d: any) => d)
      .attr('dy', '0.1em')
      .attr('x', textOffset)
      .attr('y', (circleRadius / 4) * 3)
      .style('font-size', '14px')
      .attr('font-family', 'Roboto')
      .on('click', function (d: any) {
        const currentOpacity: any = d3.selectAll('#' + d).style('opacity');
        // Change the opacity: from 0 to 1 or from 1 to 0
        d3.selectAll('#' + d)
          .transition()
          .style('opacity', currentOpacity > 0 ? 0 : 1);
        d3.selectAll('#' + d + 'l')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
        d3.selectAll('#' + d + 'lt')
          .transition()
          .style('opacity', currentOpacity > 0 ? 0.6 : 1);
      });
  }

  getreadoutPosY(){
    const e2height=this.stackChartReadoutelementdata.nativeElement.offsetHeight;
    const pos = 0-e2height;
    const y = `${pos}px`;
    return y
  }


  
  getreadoutPosX(){
    const e2width =this.stackChartReadoutelementdata.nativeElement.offsetWidth;
    const xpos = this.readoutPosX + (e2width/2)
    const x = `${xpos}px`;
    return x
  }

  getreadoutPosXdata(){
    const e2width = this.stackChartReadoutelementdata.nativeElement.offsetWidth;
    let xpos = this.readoutPosX - (e2width / 2) + this.margin.left - this.margin.right;
    if ((xpos + e2width) > (this.w + 50)) {
      const delta = (this.w + this.margin.right) - (xpos + e2width);
      xpos = xpos + delta;
    }
    if (xpos < -50) {
      xpos = -50;
    }
    const x = `${xpos}px`;
    return x
  }

  appendCurrSymbol(val: any) {
    let curSymbol = '$';
    if (this.yLabel == 'Consumption (kWh)')
      curSymbol = ''
    let newVal: any
    if (val < 0)
      newVal = val?.toString().replace('-', `- ${curSymbol}`) ?? '0';
    else
      newVal = +val ? (`${curSymbol}` + this.utils.numberFormat(val)) : '0'
    return newVal
  }
}
