import {  createSelector } from '@ngrx/store';
import { DesignState } from '../design.reducer';
import { selectDesign } from '../design.selector';

export namespace fromMetaDataSelectors {
  export const selectQuote = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.quote
  );
  export const quotePanelArrays = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.hardware?.panel
  );
  export const selectQuoteDesign = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.design
  );
  export const selectHeightArray = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.heightArray
  );
  export const selectLead = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.leadDetails
  );
  export const selectSelectedInverters = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData?.selectedInverters
  );
  export const selectSelectedBatteries = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData?.selectedBatteries
  );
  export const selectPreference = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.preferences
  );
  export const selectQuoteInverters = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.hardware?.inverter
  );
  export const selectQuoteBattery = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.hardware?.battery
  );
  export const getSelectedMap = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.quoteSelectedMap
  );
  export const getDefaultMap = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.defaultMap
  );

  export const getSiteData = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData?.leadDetails?.data?.site_information
  )

  export const getDefaultModule = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.defaultModuleSetup
  )

  export const getRecentModules = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.recentModules
  )

  export const getOtherModules = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.otherModules
  )
  export const getRackingList = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.availableRacking
  )
  export const getRackingTypes = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.rackingTypes
  )
  export const getArrayTypes = createSelector(
    selectDesign,
    (state:DesignState) => state.metaData.arrayTypes
  )
  export const getChangedfield = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.prevState
  );
  export const selectCurrentAnnualConsumption = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.leadDetails?.data?.current_annual_consumption
  );
  export const selectQuoteSysResults = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData?.quote?.systemResults
  );
  export const selectQuoteSysResultsStatus = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData?.quote?.systemResults ? (state.metaData?.quote?.systemResults as any)?.simulationStatus : undefined
  );
  export const selectCurrentAnnualBill = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.leadDetails?.data?.annual_electric_bill
  );
  export const getTotalNumberOfMeters = createSelector(
    selectLead,
    (selectLead: any) => +selectLead?.data?.c_number_meter,
  );
  export const s3uploadLink = createSelector(
    selectDesign,
    (state) => state.metaData.s3url
  ); 
  export const mapCameraInfo = createSelector(
    selectDesign,
    (state) => state.metaData.visibleMap
  ); 
  export const mapInfo = createSelector(
    selectDesign,
    (state) => state.metaData.visibleMap?.selected
  ); 
  export const selectAutoSave3DScene = createSelector(
    selectDesign,
    (state) => state.metaData.autoSave3Dmodel
  ); 
  export const selectWeatherLatLong = createSelector(
    selectDesign,
    (state) => state.metaData.weatherStationLatLngJson
  );

  export const quoteMonitoringSystem = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData?.hardware?.monitoringSystem
  );

  export const selectQuoteDesignSelectedMap = createSelector(
    selectDesign,
    (state: DesignState) => state.metaData.design?.['map']?.['selected']
  );

  export const isNewQuote = createSelector(
    selectDesign,
    (state: DesignState) => {
      if((state.metaData.design?.['map']?.['selected'] || state.metaData.design?.['edges']?.length || state.metaData.design?.['roofOutlines']?.length || state.metaData.design?.['vertices']?.length) || !state.metaData.design?.['isNewQuote'] ){
        return false;
      }
      return true;
    }
  );
}
