import { ArrowHelper, AxesHelper, Group, Mesh, MeshBasicMaterial, Object3D, SphereGeometry, Vector3 } from "three";
import Util, { threeColors } from "./Utility";

export default class DebugUtil {
    private pointSphere: Mesh = this.CreatePointSphere();

    constructor() {

    }

    DebugPanelCalculations(panelGroup: Object3D, panelPts: Vector3[][], roofPt: Vector3[], keepoutPts: Vector3[][]) {
        const prevGroup = panelGroup.getObjectByName("Debug Panel Calculations");
        prevGroup?.removeFromParent();
        if (Util.debugThreejs) {
            const debugObjGroup = new Group();
            debugObjGroup.name = "Debug Panel Calculations"
            panelGroup.add(debugObjGroup);

            const allPanelPts = panelPts.flat()
            const allKeepoutPts = keepoutPts.flat()

            const panelPtMat = (this.pointSphere.material as MeshBasicMaterial).clone()
            const roofPtMat = (this.pointSphere.material as MeshBasicMaterial).clone()
            const keepoutPtMat = (this.pointSphere.material as MeshBasicMaterial).clone()

            panelPtMat.color.set(threeColors.panelColor)
            roofPtMat.color.set(threeColors.roofColor)
            keepoutPtMat.color.set(threeColors.obstacleColor)

            allPanelPts.map(pt => {
                const sphere = this.pointSphere.clone();
                sphere.position.copy(pt.clone().setZ(0));
                sphere.position.setZ(0);
                sphere.material = panelPtMat
                debugObjGroup.add(sphere)
            })

            roofPt.map(pt => {
                const sphere = this.pointSphere.clone();
                sphere.scale.set(2, 2, 2);
                sphere.position.copy(pt.clone().setZ(0));
                sphere.material = roofPtMat
                debugObjGroup.add(sphere);
            })

            const axesHelper = new AxesHelper(50);
            axesHelper.position.copy(roofPt[0].clone().setZ(0));
            debugObjGroup.add(axesHelper);

            allKeepoutPts.map(pt => {
                const sphere = this.pointSphere.clone(); 
                sphere.position.copy(pt.clone().setZ(0));
                sphere.material = keepoutPtMat
                debugObjGroup.add(sphere)
            })
        }

    }

    DebugPanelGutterDir(panelGroup: Object3D, gutterVector: Vector3, gutterStart: Vector3) {
        if (Util.debugThreejs) {
            var arrowLength = 1000;
            var arrowColor = 0x00ff00;
            let arrowHelper = new ArrowHelper(gutterVector, gutterStart, arrowLength, arrowColor);
            panelGroup.add(arrowHelper)
        }
    }

    CreatePointSphere() {
        // TODO: you can use THREE.Points instead: https://threejs.org/examples/?q=poin#webgl_interactive_points
        const geo = new SphereGeometry(2, 16, 16);
        const mat = new MeshBasicMaterial({
            color: threeColors.tempVertex,
            transparent: true,
            opacity: 0.5,
        });

        const mesh = new Mesh(geo, mat);
        mesh.name = "Debug Point Sphere"

        return mesh
    }

    static DebugObstacleDiagnol(start: Vector3 | undefined, end: Vector3 | undefined, rect: Mesh | undefined) {
        if (Util.debugThreejs && start && rect) {
            var arrowLength = 100;
            var arrowColor = 0x00ff00;
            const diagonalDir = new Vector3();
            diagonalDir.subVectors(end!, start).normalize();

            const endVector = new ArrowHelper(diagonalDir, rect.position, arrowLength, arrowColor, 10, 10);
            return endVector;
        }
        return;
    }

}