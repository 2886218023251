import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginService } from '../login/login.service';
import jwt_decode from 'jwt-decode';
import { Utils } from '../../helpers/utils';
import { IndexedDBService } from '@design/services/indexed-db.service';
import { LocaleService } from 'src/app/helpers/locale.service';
import { Subscription } from 'rxjs';

interface Params {
  email_id: string,
  token: string,
  refreshToken: string
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})


export class AccountComponent implements OnInit {

  accountsForm: UntypedFormGroup = new UntypedFormGroup({});
  submittedAcc!: boolean;

  allAccounts: any[] = []
  allAccountsVars: any[] = []
  errorResponse!: boolean;
  paramsObj?: Params
  getEmailObj: any;
  rubiconUrl: any[];
  isAccountGreaterThanOne = false;
  localLanguage = 'en_US';
  subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private LoginService: LoginService,
    private router: Router,
    private utils: Utils,
    private indexedDBService: IndexedDBService,
    private localeService: LocaleService
  ) {
    this.subscription.add(this.route.queryParams.subscribe((params) => {
      let prm: any = params
      this.paramsObj = prm
      if (this.paramsObj?.email_id) {
        this.setToken();
        this.getAllAssociatedAcc(this.paramsObj?.email_id)
        localStorage.setItem('token', this.paramsObj.token);
        localStorage.setItem('redirectURL', window.location.origin);
      }
    }))


    this.rubiconUrl = this.router.url.split('&')
    if (this.rubiconUrl.length == 4 && this.router.url.includes('/account#access_token')) {
      this.getEmailObj = this.getDecodedAccessToken(this.rubiconUrl[1].substr(9))
      let encoded = encodeURI(this.getEmailObj['cognito:username']);
      this.getAllAssociatedAcc(this.getEmailObj.email, encoded, true);

    }

  }

  ngOnInit(): void {
    this.accountsForm = this.fb.group({
      accountId: ['', Validators.required],
    });
  }

  get ac(): any { return this.accountsForm.controls; }

  getAllAssociatedAcc(email: string, cognitoUname: string = '', isRubicon = false) {
    this.subscription.add(this.LoginService.getAllAccounts(email, cognitoUname, this.paramsObj?.token, isRubicon).subscribe({
      next: res => {
        res = isRubicon ? res.map((item: any) => ({ account_id: item})) : res;
        res.sort((a: any, b: any) => a.account_id.localeCompare(b.account_id))
        this.allAccounts = res;
        this.allAccountsVars = this.allAccounts.slice()
        if (this.allAccounts.length == 1) {
          this.isAccountGreaterThanOne = false;
          const account = this.allAccounts[0];
          if (this.shouldRedirect(account)) {
            this.redirectToSecondary(account?.account_id);
          } else {
            this.setToken();
            this.router.navigate(['/login'], {
              queryParams: { email_id: this.paramsObj?.email_id ?? this.getEmailObj.email, account: account.account_id, origin: window.location.origin, token: localStorage.getItem('token'), prefLang: this.localLanguage },
            });
          }
        } else {
          this.isAccountGreaterThanOne = true;
        }

      },
      error: (error: any) => {
        if (error.status = 401)
          this.backTosignIn()
        // this.loaderService.hideLoader();
        // this.clearForm();
      }
    }));
  }

  shouldRedirect(account: any) {
    return !!!account?.isQuoteEnabled;
  }

  redirectToSecondary(accountId: string) {
    const emailId = this.paramsObj?.email_id ?? this.getEmailObj.email;
    return window.open(`${environment.loginNewQuoteRedirectURL}?email_id=${emailId}&account=${accountId}&token=${localStorage.getItem('token')}&origin=${window.location.origin}&prefLang=${this.localLanguage}`, '_self');
  }

  onSelectAcc(): void {
    this.submittedAcc = true;
    const account = this.accountsForm.value?.accountId;
    if (this.shouldRedirect(account)) {
      this.redirectToSecondary(account.account_id);
      return;
    }
    this.setToken()
    if (this.accountsForm.invalid) {
      return;
    }
    this.router.navigate(['/login'], {
      queryParams: { email_id: this.paramsObj?.email_id ?? this.getEmailObj.email, account: account.account_id, origin: window.location.origin, token: localStorage.getItem('token'),prefLang: this.localLanguage},
    });

  }

  setToken() {
    localStorage.setItem('token', (this.paramsObj && this.paramsObj.token) ?? this.rubiconUrl[0].substr(22));
    localStorage.setItem('refreshToken', (this.paramsObj && this.paramsObj.refreshToken) ?? this.rubiconUrl[0].substr(22));

  }
  backTosignIn() {
    if (window.location.origin.indexOf(environment.rubiconURL) >= 0) {
      const url = this.utils.loginRubiconURL();
      window.open(url, '_self')
    } else {
      window.open(`${environment.redirect_uri}?redirectURL=${localStorage.getItem('redirectURL')}`, '_self')
    }

  }
  // decode jwt token
  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  onAccountChange(event:any) {
    const local = event?.value?.language ?? 'en_US'; // preferred lang need to be binded
    this.localLanguage = local;
    this.localeService.registerLanguage(this.localLanguage); // setting language code to en-US for now

  }

}
